<script lang="ts" setup>
// Vue & Packages
import { computed, ref } from 'vue';

import Modal from '@/components/Common/modals/Modal.vue';
import OnClickOutsideWithPortals from '@/components/Common/OnClickOutsideWithPortals';
import Popper from '@/components/Common/Popper.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';

type option = { label: string | number; value: string | number };

// Props
const props = defineProps<{
	options: option[];
	selected: number | string | option;
	hideOptions?: boolean | undefined;
}>();

// Data
const visible = ref(false);

// Emits
const emit = defineEmits(['update']);

// Computeds
const isMix = computed(() => Array.isArray(props.selected) && props.selected.length > 1);

const label = computed(() =>
	isMix.value ? 'Mix' : typeof props.selected === 'object' ? props.selected.label : props.selected
);

// Methods
const onSelectOption = (option: string | number) => {
	emit('update', option);
	visible.value = false;
};

const onToggleVisibility = () => {
	visible.value = !visible.value;
};

const { isMobile } = useDeviceInfo();
</script>

<template>
	<OnClickOutsideWithPortals class="relative" @trigger="visible = false">
		<slot :on-toggle-visibility="onToggleVisibility" :value="label">
			<button
				data-testid="dropdown"
				class="relative flex h-10 w-full cursor-pointer items-center rounded bg-gray-900 pl-3 pr-4 text-sm text-gray-100 hover:text-gray-700 focus:outline-none lg:hover:text-white"
				:class="containerClasses"
				@click.stop="onToggleVisibility"
			>
				{{ label }}
				<SvgIcon name="arrow" class="absolute top-3 right-3 h-4 w-4 fill-current" />
			</button>
		</slot>

		<!-- Dropdown-->
		<Popper v-if="!hideOptions && visible && !isMobile" placement="bottom-start">
			<div
				class="mt-1 max-h-64 w-full overflow-auto rounded bg-white py-1 pb-1 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100"
			>
				<button
					v-for="option in options"
					:key="option.value"
					data-testid="dropdown-option"
					class="w-full px-3 py-1.5 text-sm hover:bg-gray-100/10 hover:text-gray-800"
					:class="[selected].includes(option.value) ? 'bg-gray-100/10 font-bold text-gray-800' : 'text-gray-500'"
					@click.stop="onSelectOption(option.value)"
				>
					<span class="flex items-baseline whitespace-nowrap">
						{{ option.label }}
					</span>
				</button>
			</div>
		</Popper>

		<Modal v-if="visible && isMobile && !hideOptions" to="body" open>
			<div class="my-auto flex w-64 flex-col items-center rounded-lg bg-gray-800 py-2 text-white">
				<slot name="title"></slot>
				<div
					class="h-[33vh] w-full overflow-y-scroll rounded bg-gray-800 p-2 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
				>
					<button
						v-for="option in options"
						:key="option.value"
						data-testid="dropdown-option"
						class="w-full px-3 py-2 text-sm hover:bg-gray-800 hover:text-white"
						:class="[selected].includes(option.value) ? 'bg-gray-800 text-white' : 'text-gray-100'"
						@click.stop="onSelectOption(option.value)"
					>
						<span class="flex items-baseline justify-center whitespace-nowrap">
							{{ option.label }}
						</span>
					</button>
				</div>
			</div>
		</Modal>
	</OnClickOutsideWithPortals>
</template>
