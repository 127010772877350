<script lang="ts" setup>
// Vue
import { ref, toRef } from 'vue';

// Classes
import { Text } from '@/Classes/Text';
import OnClickOutsideWithPortals from '@/components/Common/OnClickOutsideWithPortals';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import TextCurvePanel from '@/components/selection/panels/TextCurve.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useMainStore } from '@/stores/store';

// Props
const props = defineProps<{ element: Text }>();
const element = toRef(props, 'element');
const store = useMainStore();
const scale = toRef(store, 'scale');
// Using composables

// Data
const isOpen = ref(false);

const reset = () => {
	isOpen.value = false;

	element.value.curvedProperties.arc = null;
};
const { isCircleText } = useCircleTypeInfo(element, scale);
</script>

<template>
	<OnClickOutsideWithPortals @trigger="isOpen = false">
		<div
			v-show="isOpen"
			ref="textShadowContainer"
			class="fixed bottom-16 left-0 z-10 w-full rounded-tl-2xl rounded-tr-2xl bg-gray-700 px-4 pt-6 pb-4 text-left"
		>
			<div class="-mt-3 mb-4 flex justify-between">
				<button class="flex items-center text-xs text-gray-100 hover:text-white" @click="reset">
					<SvgIcon name="reload" class="mr-2 h-3 w-3" />
					Reset
				</button>
				<button
					class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
					@click="isOpen = false"
				>
					<SvgIcon name="cross" class="h-3 w-3" />
				</button>
			</div>
			<TextCurvePanel v-if="isOpen" :element="element" @close="isOpen = !isOpen" />
		</div>

		<button
			class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-8 lg:w-8 lg:justify-center"
			:class="{
				'text-white': isCircleText,
				'text-gray-100': !isCircleText,
			}"
			tooltip="Text shadow"
			tooltip-position="top"
			@click="isOpen = !isOpen"
		>
			<span
				v-if="isCircleText"
				class="absolute top-0 right-2 h-2 w-2 rounded-full border border-blue-400 bg-blue-500"
			></span>
			<SvgIcon name="curved" class="h-5 w-5 lg:h-4 lg:w-4" />
			<p class="text-2xs lg:hidden">Curved</p>
		</button>
	</OnClickOutsideWithPortals>
</template>
