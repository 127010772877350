// Vue & Packages
import { AfterFetchContext, useFetch, UseFetchOptions, UseFetchReturn, watchWithFilter } from '@vueuse/core';
import { computed, Ref, ref, watch } from 'vue';

// Composables
import { useEditorApiFetch } from '@/composables/api/useEditorApiFetch';
// Types
import {
	ArtboardApi,
	FlaticonCategoryApi,
	FlaticonElementApi,
	ImageApi,
	MaskApi,
	ProjectDataApi,
	StickersCategoryApi,
	StickersElementApi,
	StorysetApi,
	TemplateApi,
	TemplateApiData,
} from '@/Types/apiClient';
import { Font } from '@/Types/types';

// Methods
export const getArtboards = async () => {
	if (window.preloadArtboards) {
		return window.preloadArtboards;
	}
	const { data } = await useEditorApiFetch('artboards').json();

	return data.value;
};

export const getFlaticon = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<FlaticonCategoryApi[] | FlaticonElementApi[]> => {
	const concatenatedData = ref<FlaticonCategoryApi[] | FlaticonElementApi[]>([]);

	useChangeUrlEndpoint(url, 'flaticon', concatenatedData);
	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => useFlaticonResponseMapper(ctx, concatenatedData, url),
	});
};

export const getFonts = (options: UseFetchOptions = {}): UseFetchReturn<Font[]> => {
	return useEditorApiFetch('fonts', options).json();
};

export const getImages = (url: Ref<string>, options: UseFetchOptions = {}): UseFetchReturn<ImageApi[] | any> => {
	const concatenatedData = ref<ImageApi[] | any>([]);

	useChangeUrlEndpoint(url, 'images', concatenatedData);
	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => useImageResponseMapper(ctx, concatenatedData, url),
	})
		.get()
		.json();
};

export const getProject = (slug: string, options: UseFetchOptions = {}): UseFetchReturn<ProjectDataApi> => {
	return useEditorApiFetch(`vectors/${slug}`, options).json();
};

export const getStorysets = (url: Ref<string>, options: UseFetchOptions = {}): UseFetchReturn<StorysetApi[]> => {
	const concatenatedData = ref<StorysetApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useFetch(url, {
		...options,
		afterFetch: (ctx) => useStorysetsResponseMapper(ctx, concatenatedData, url),
	});
};

export const getSvg = (url: string, options: UseFetchOptions = {}): UseFetchReturn<string> => {
	return useFetch(url, options);
};

export const getTemplates = (url: Ref<string>, options: UseFetchOptions = {}): UseFetchReturn<TemplateApi> => {
	const concatenatedData = ref<TemplateApiData[]>([]);

	useChangeUrlEndpoint(url, 'category', concatenatedData);
	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => useTemplatesResponseMapper(ctx, concatenatedData),
	}).json();
};

export const _getMasks = (url: Ref<string>, options: UseFetchOptions = {}): UseFetchReturn<MaskApi[]> => {
	const concatenatedData = ref<MaskApi[]>([]);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => useTemplatesResponseMapper(ctx, concatenatedData),
	})
		.get()
		.json();
};

export const getMasks = (url: Ref<string>, options: UseFetchOptions = {}): UseFetchReturn<MaskApi[]> => {
	const concatenatedData = ref<MaskApi[]>([]);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => useTemplatesResponseMapper(ctx, concatenatedData),
	});
};

export const getStickersCategories = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<StickersCategoryApi[]> => {
	const concatenatedData = ref<StickersCategoryApi[]>([]);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => useTemplatesResponseMapper(ctx, concatenatedData),
	});
};

export const getStickersElements = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<StickersElementApi[]> => {
	const concatenatedData = ref<StickersElementApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => useFlaticonResponseMapper(ctx, concatenatedData, url),
	});
};

export const getAutocompletedWords = (url: Ref<string> | string, options: UseFetchOptions = {}) => {
	return useEditorApiFetch(url, {
		...options,
	})
		.get()
		.json();
};

// Api response mappers
const useTemplatesResponseMapper = (ctx: AfterFetchContext, concatenatedData: Ref<any[]>) => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data.data);

	// Fixed api context
	ctx.data.data = concatenatedData.value;
	ctx.data.links.next = ctx.data.links.next.replace(import.meta.env.VITE_APP_API_PATH, '');

	return ctx;
};

const useFlaticonResponseMapper = (ctx: AfterFetchContext, concatenatedData: Ref<any[]>, url: Ref<string>) => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data);

	// Set pagination regarding page param in url
	ctx.data = {
		data: concatenatedData.value,
		links: {
			next: useSetNextPageInURL(url, ctx.data).value,
		},
	};

	// Fixed api context
	return ctx;
};

const useImageResponseMapper = (ctx: AfterFetchContext, concatenatedData: Ref<ImageApi[]>, url: Ref<string>) => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = [...concatenatedData.value, ...ctx.data.images];

	// Images api returns nextPageId:number instead of links[prev, next] as the other cases
	// so we need to set it correctly
	ctx.data = {
		data: concatenatedData.value,
		links: {
			next: useSetNextPageInURL(url, ctx.data.images).value,
		},
	};

	// Fixed api context
	return ctx;
};

const useStorysetsResponseMapper = (ctx: AfterFetchContext, concatenatedData: Ref<StorysetApi[]>, url: Ref<string>) => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data.data);

	// Set pagination regarding page param in url
	ctx.data.data = concatenatedData.value;

	if (ctx.data.data.length === 0) {
		ctx.data.links.next = '';
		return ctx;
	}

	const fullUrl = new URL(url.value);
	const currentPage = fullUrl.searchParams.get('page') || '0';
	const nextPage = currentPage ? parseInt(currentPage) + 1 : 1;
	fullUrl.searchParams.set('page', nextPage.toString());
	const nextUrl = fullUrl.toString();
	ctx.data.links.next = nextUrl;

	// Fixed api context
	return ctx;
};

// Change url params hooks
const useChangeUrlEndpoint = (url: Ref<string>, keyword: string, concatenatedData: Ref<any[]>) => {
	const endpoint = computed(() =>
		url.value.includes(keyword) ? url.value.split(`${keyword}/`)[1].split('?')[0] : null
	);

	watch(endpoint, (newVal, oldVal) => {
		// Reset data on modify image provider
		if (newVal && newVal !== oldVal) {
			concatenatedData.value = [];
		}
	});
};

const useEmptyDataOnUrlParamChange = (url: Ref<string>, concatenatedData: Ref<any[]>) => {
	const computedUrlParams = computed(() => {
		const params = new URLSearchParams(url.value.split('?')[1]);

		return {
			q: params.get('q'),
			query: params.get('query'),
			style: params.get('style'),
			tagsId: params.get('tagsId'),
			color: params.get('color'),
		};
	});

	watch(computedUrlParams, (newVal, oldVal) => {
		// Reset data on modify search query param
		if (
			newVal.q !== oldVal.q ||
			newVal.query !== oldVal.query ||
			newVal.style !== oldVal.style ||
			newVal.tagsId !== oldVal.tagsId ||
			newVal.color !== oldVal.color
		) {
			concatenatedData.value = [];
		}
	});
};

const useSetNextPageInURL = (url: Ref<string>, chunk: any[], clean = true) => {
	const nextUrlRef = ref('');

	if (!chunk.length) return nextUrlRef;

	const fullUrl = new URL(import.meta.env.VITE_APP_API_PATH + url.value);
	const currentPage = fullUrl.searchParams.get('page') || '1';
	const nextPage = currentPage ? parseInt(currentPage) + 1 : 2;
	fullUrl.searchParams.set('page', nextPage.toString());
	const nextUrl = clean ? fullUrl.toString().replace(import.meta.env.VITE_APP_API_PATH, '') : fullUrl.toString();
	nextUrlRef.value = nextUrl;

	return nextUrlRef;
};
