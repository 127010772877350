<script setup lang="ts">
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { ref } from 'vue';

// Api
import { getMasks } from '@/api/DataApiClient';
// Classes
import Image from '@/Classes/Image';
import Mask from '@/Classes/Mask';
import ActivableItem from '@/components/Common/ActivableItem.vue';
// Components
import InfiniteLoading from '@/components/Common/InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
// Composables
// Store
import { useMainStore } from '@/stores/store';
// Types
import { MaskApi } from '@/Types/apiClient';
import { ElementsPanelTypes } from '@/Types/types';

const store = useMainStore();
const temporalRef = ref(Image.createDefault());
const usingElementOrchestrator = useElementOrchestrator(temporalRef);
const { addElement } = useActivePage();

const emit = defineEmits(['elementAdded']);

// Constants
const ELEMENT_URL_CONFIG = {
	LIMIT: 100,
	PAGE: 1,
};

// Data
const url = ref(`elements/category/1741?limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}`);

// Methods
const addMask = async (maskApi: MaskApi) => {
	const image = await Image.fromUrl('https://wepik.com/svg/mask-placeholder.svg');
	const mask = await Mask.fromApi(maskApi);
	image.setMask(mask);
	temporalRef.value = image;

	addElement(image);
	usingElementOrchestrator.value.setupInPage();

	store.setSelection(image, false);

	Bugsnag.leaveBreadcrumb(`Add mask to canvas, ${image.type}-${image.id}; Mask api id: ${image.mask.id}`);
	emit('elementAdded');
};
</script>

<template>
	<div data-testid="add-elements-panel" class="flex h-full flex-col">
		<InfiniteLoading
			data-testid="container-items"
			:source="url"
			:fetch="getMasks"
			container-classes="grid grid-cols-5 gap-6"
		>
			<template #item="{ item }">
				<InsertableElement
					:data="item"
					:type="ElementsPanelTypes.ImageMasks"
					:draggable="true"
					class="flex"
					@click="addMask(item)"
				>
					<ActivableItem :active="item.active">
						<img v-if="item" :src="item.mask.element" class="h-10 w-10 object-contain" />
					</ActivableItem>
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
