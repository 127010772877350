import { Filter } from '@/Classes/Filter';
import { GradientColor } from '@/Classes/GradientColor';
import { GradientOverlay, SolidOverlay } from '@/Classes/Overlay';
import { SolidColor } from '@/Classes/SolidColor';

export class FilterPresetEmpty extends Filter {
	constructor() {
		super();
		this.name = 'empty';
	}
}

export class FilterPresetGrayscale extends Filter {
	constructor() {
		super(null, null, null, null, 100);
		this.name = 'Grayscale';
	}
}

export class FilterPreset1977 extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(243, 106, 188, 0.3), 'screen');
		super(110, 110, 130, null, null, null, null, null, overlay);
		this.name = '1977';
	}
}

export class FilterEarlyBird extends Filter {
	constructor() {
		const gradient = new GradientColor('radial', 0, [
			{ r: 208, g: 186, b: 142, a: 255, offset: 20 },
			{ r: 29, g: 2, b: 16, a: 255, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'overlay');
		super(90, 100, 100, 20, null, null, null, null, overlay);
		this.name = 'Early Bird';
	}
}

export class FilterAden extends Filter {
	constructor() {
		const gradient = new GradientColor('linear', 0, [
			{ r: 66, g: 10, b: 14, a: 51, offset: 1 },
			{ r: 66, g: 10, b: 14, a: 0, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'darken');
		super(90, 120, 85, null, null, null, 20, null, overlay);
		this.name = 'Aden';
	}
}

export class FilterAmaro extends Filter {
	constructor() {
		super(90, 110, 150, null, null, null, -10, null);
		this.name = 'Amaro';
	}
}

export class FilterBrannan extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(161, 44, 199, 0.31), 'lighten');
		super(140, 100, 100, 50, null, null, null, null, overlay);
		this.name = 'Brannan';
	}
}

export class FilterBrooklyn extends Filter {
	constructor() {
		const gradient = new GradientColor('radial', 0, [
			{ r: 168, g: 223, b: 193, a: 102, offset: 1 },
			{ r: 183, g: 196, b: 200, a: 51, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'overlay');
		super(90, 110, 100, null, null, null, null, null, overlay);
		this.name = 'Brooklyn';
	}
}

export class FilterClarendon extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(127, 187, 227, 0.2), 'overlay');
		super(120, 100, 125, null, null, null, null, null, overlay);
		this.name = 'Clarendon';
	}
}

export class FilterGingham extends Filter {
	constructor() {
		const gradient = new GradientColor('linear', 0, [
			{ r: 66, g: 10, b: 14, a: 51, offset: 1 },
			{ r: 0, g: 0, b: 0, a: 0, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'darken');
		super(100, 105, 100, null, null, null, 350, null, overlay);
		this.name = 'Gingham';
	}
}

export class FilterHudson extends Filter {
	constructor() {
		const gradient = new GradientColor('radial', 0, [
			{ r: 255, g: 177, b: 166, a: 255, offset: 50 },
			{ r: 52, g: 33, b: 52, a: 255, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'multiply', 50);
		super(90, 120, 110, null, null, null, null, null, overlay);
		this.name = 'Hudson';
	}
}

export class FilterInkwell extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(0, 0, 0, 0), 'normal');
		super(110, 110, 100, 30, 100, null, null, null, overlay);
		this.name = 'Inkwell';
	}
}

export class FilterLofi extends Filter {
	constructor() {
		const gradient = new GradientColor('radial', 0, [
			{ r: 0, g: 0, b: 0, a: 0, offset: 70 },
			{ r: 34, g: 34, b: 34, a: 255, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'multiply');
		super(150, 100, 110, null, null, null, null, null, overlay);
		this.name = 'Lofi';
	}
}

export class FilterMaven extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(3, 230, 26, 0.2), 'hue');
		super(95, 95, 150, 25, null, null, null, null, overlay);
		this.name = 'Maven';
	}
}

export class FilterPerpetua extends Filter {
	constructor() {
		const gradient = new GradientColor('linear', 270, [
			{ r: 0, g: 91, b: 154, a: 255, offset: 1 },
			{ r: 61, g: 193, b: 230, a: 0, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'soft-light', 50);
		super(100, 100, 100, null, null, null, null, null, overlay);
		this.name = 'Perpetua';
	}
}

export class FilterReyes extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(173, 205, 239, 1), 'soft-light', 50);
		super(85, 110, 75, 22, null, null, null, null, overlay);
		this.name = 'Reyes';
	}
}

export class FilterStinson extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(240, 149, 128, 0.2), 'soft-light');
		super(75, 115, 85, null, null, null, null, null, overlay);
		this.name = 'Stinson';
	}
}

export class FilterToaster extends Filter {
	constructor() {
		const gradient = new GradientColor('radial', 0, [
			{ r: 15, g: 78, b: 128, a: 255, offset: 1 },
			{ r: 59, g: 0, b: 59, a: 255, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'screen', 50);
		super(150, 90, 100, null, null, null, null, null, overlay);
		this.name = 'Toaster';
	}
}

export class FilterWalden extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(204, 68, 0, 1), 'screen', 30);
		super(100, 110, 160, 30, null, null, 350, null, overlay);
		this.name = 'Walden';
	}
}

export class FilterValencia extends Filter {
	constructor() {
		const overlay = new SolidOverlay(new SolidColor(58, 3, 57, 1), 'exclusion', 50);
		super(108, 108, 100, 8, null, null, null, null, overlay);
		this.name = 'Valencia';
	}
}

export class FilterXpro2 extends Filter {
	constructor() {
		const gradient = new GradientColor('radial', 0, [
			{ r: 244, g: 231, b: 230, a: 255, offset: 40 },
			{ r: 43, g: 42, b: 161, a: 153, offset: 100 },
		]);
		const overlay = new GradientOverlay(gradient, 'color-burn', 50);
		super(100, 100, 100, 30, null, null, null, null, overlay);
		this.name = 'Xpro2';
	}
}

export const presetTypes = (): Filter[] => [
	new FilterPresetGrayscale(),
	new FilterPreset1977(),
	new FilterEarlyBird(),
	new FilterAden(),
	new FilterAmaro(),
	new FilterBrannan(),
	new FilterBrooklyn(),
	new FilterClarendon(),
	new FilterGingham(),
	new FilterHudson(),
	new FilterInkwell(),
	new FilterLofi(),
	new FilterMaven(),
	new FilterPerpetua(),
	new FilterReyes(),
	new FilterStinson(),
	new FilterToaster(),
	new FilterWalden(),
	new FilterValencia(),
	new FilterXpro2(),
];
