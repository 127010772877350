<script lang="ts" setup>
// Vue
import { computed, InputHTMLAttributes, toRef } from 'vue';

// Classes
import Element from '@/Classes/Element';
import { useBugsnag } from '@/composables/useBugsnag';
import MathTools from '@/utils/MathTools';

// Constants
const INPUT_CONFIG = {
	MAX: 1,
	MIN: 0,
	STEP: 0.01,
	TYPE: 'range',
};

// Props
const props = defineProps<{
	element: Element;
	isMalfunction?: boolean; // ?
	opacity: number;
}>();
const element = toRef(props, 'element');
// Computeds
const opacityPercentage = computed(() => props.opacity * 100);

const opacityPercentageLabel = computed(() => Math.round(opacityPercentage.value)); // ?

// Methods
const { breadScrumbWithDebounce } = useBugsnag(element);
const onInput = (e: Event) => {
	const value = MathTools.clamp((e.target as InputHTMLAttributes).value, INPUT_CONFIG.MIN, INPUT_CONFIG.MAX);
	props.element.setOpacity(value);
	breadScrumbWithDebounce('opacity');
};
</script>

<template>
	<div>
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Opacity</h4>
		<div class="mb-6 flex items-center" :class="{ malfunction: isMalfunction }" data-testid="opacity-input">
			<div class="relative flex w-full items-center">
				<input
					class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
					:max="INPUT_CONFIG.MAX"
					:min="INPUT_CONFIG.MIN"
					:step="INPUT_CONFIG.STEP"
					:type="INPUT_CONFIG.TYPE"
					:value="opacity"
					@input="onInput"
				/>

				<span
					class="pointer-events-none absolute top-0 left-0 h-[2px] rounded-full"
					data-testid="opacityInput"
					:class="isMalfunction ? 'opacity-malfunction-bar' : 'bg-blue-500'"
					:style="{
						width: `${opacityPercentage}%`,
					}"
				/>
			</div>

			<span class="ml-2 w-8 text-xs text-gray-100">{{ opacityPercentageLabel }}%</span>
		</div>
	</div>
</template>
<style lang="sass">
.input-range::-webkit-slider-thumb
	@apply w-3 h-3 bg-blue-500 appearance-none rounded-full cursor-ew-resize
</style>
