<script lang="ts" setup>
// Vue
import { ref, toRef } from 'vue';

// Classes
import Image from '@/Classes/Image';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Components
import PhotosPanel from '@/components/panels/photos/PhotosPanel.vue';
// Composables
import { useImage } from '@/composables/element/image/useImage';
// Types
import { ImageApi } from '@/Types/apiClient';

// Props
const props = defineProps<{ element: Image }>();
const element = toRef(props, 'element');
const { replace } = useImage(element);

// Data
const replacing = ref(false);

// Methods
const onCloseModal = () => (replacing.value = false);

const replaceImage = async (image: ImageApi) => {
	await replace(image);
	replacing.value = false;
};
</script>

<template>
	<div>
		<div>
			<button
				tooltip="Replace"
				tooltip-position="top"
				data-button-importance="normal"
				data-testid="replace-img"
				class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
				@click="replacing = true"
			>
				<SvgIcon name="replace" class="h-4 w-4 lg:scale-90" />
				<span class="button-label text-2xs lg:mt-0 lg:ml-2 lg:text-sm" data-button-label>Replace</span>
			</button>
		</div>

		<Modal
			v-if="replacing"
			content-classes="h-[70vh] lg:h-[90vh] y-8 rounded w-11/12 lg:w-auto lg:max-w-lg lg:min-w-lg relative bg-white max-h-full py-4 lg:py-5 px-4 lg:px-6"
			open
			@close="replacing = false"
		>
			<PhotosPanel data-testid="replace-img-modal" :modal="true" @closeModal="onCloseModal" @selection="replaceImage" />
		</Modal>
	</div>
</template>
