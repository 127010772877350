<script lang="ts" setup>
// Vue
import { toRef } from 'vue';

import { Shape } from '@/Classes/Shape';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import FlipMenu from '@/components/selection/panels/FlipMenu.vue';
import OrderMenu from '@/components/selection/panels/OrderMenu.vue';
import PositionMenu from '@/components/selection/panels/PositionMenu.vue';
import OpacitySlider from '@/components/selection/toolbars/OpacitySlider.vue';
import TransformMenu from '@/components/selection/toolbars/TransformMenu.vue';
import { useShapeColors } from '@/composables/element/shape/useShapeColors';
import { useGroup } from '@/composables/group/useGroup';
import { useMainStore } from '@/stores/store';

const store = useMainStore();

const props = defineProps<{ element: Shape }>();

const element = toRef(props, 'element');

const { colors, updateColor } = useShapeColors(element);
const { isGrouped } = useGroup(element);

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">Edit shape</h2>
		<button
			class="text-gray-100 hover:text-white focus:outline-none"
			data-testid="close-panel"
			@click="onCloseEditPanel"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Edition</h4>
		<div class="mb-6 w-1/2">
			<FlipMenu :inline-label="true" :element="element" />
		</div>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="true" />

		<OpacitySlider :element="element" :opacity="element.opacity" />

		<!-- Colors -->
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Colors</h4>

		<div class="grid grid-cols-6 gap-2">
			<ColorPicker
				v-for="color in colors"
				:key="color.toCssString"
				:color="color"
				class="h-10 w-10"
				@change="(newColor) => updateColor(color, newColor)"
			/>
		</div>
	</div>
</template>
