<script lang="ts" setup>
import { ref, computed } from 'vue';

import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';

const { isMac } = useDeviceInfo();

const open = ref(false);
const commandKey = computed(() => (isMac.value ? '⌘' : 'Ctrl'));
</script>
<template>
	<button
		class="pointer-events-auto mr-2 flex h-10 w-10 items-center justify-center rounded bg-gray-800 text-gray-100 hover:text-white focus:outline-none"
		@click="open = true"
	>
		<SvgIcon class="h-5 w-5 fill-current" name="keyboard"></SvgIcon>
	</button>

	<Modal :open="open">
		<div class="relative flex w-screen max-w-lg overflow-hidden rounded">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded-sm bg-white p-1 hover:bg-gray-100/25"
				@click="open = false"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>
			<div class="flex w-full flex-col bg-white p-8 text-center">
				<h1 class="mb-4 text-center text-2xl font-semibold text-gray-800">Keyboard shortcuts</h1>
				<div class="mt-6 flex items-center justify-center">
					<ul class="w-full">
						<li class="mb-3 flex items-center text-gray-600">
							<div class="flex w-1/2 items-center pr-2">
								<span
									class="ml-auto flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>{{ isMac ? '⌫' : 'Del' }}</span
								>
							</div>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Delete selected element</span>
						</li>
						<li class="mb-3 flex items-center text-gray-600">
							<div class="flex w-1/2 items-center pr-2">
								<span
									class="ml-auto flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>Esc</span
								>
							</div>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Deselect any element</span>
						</li>
						<li class="mb-3 flex items-center text-gray-600">
							<div class="flex w-1/2 items-center justify-end pr-2">
								<span
									class="mr-2 flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>{{ commandKey }}</span
								>
								<span
									class="flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>C</span
								>
							</div>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Copy selected element</span>
						</li>
						<li class="mb-3 flex items-center text-gray-600">
							<div class="flex w-1/2 items-center justify-end pr-2">
								<span
									class="mr-2 flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>{{ commandKey }}</span
								>
								<span
									class="flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>V</span
								>
							</div>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Paste selected element</span>
						</li>
						<li class="mb-3 flex items-center text-gray-600">
							<div class="flex w-1/2 items-center justify-end pr-2">
								<span
									class="mr-2 flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>{{ commandKey }}</span
								>
								<span
									class="flex h-8 w-8 items-center justify-center rounded bg-gray-100/25 font-semibold text-gray-600"
									>Z</span
								>
							</div>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Undo action</span>
						</li>
						<li class="mb-3 flex items-center text-gray-600">
							<div
								class="flex w-1/2 items-center justify-end pr-2"
								v-html="
									isMac
										? `<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center mr-2'>⌘</span>
							<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center mr-2'>⇧</span>
							<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center'>V</span>`
										: `<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center mr-2'>Ctrl</span>
							<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center'>Y</span>`
								"
							/>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Redo action</span>
						</li>
						<li class="mb-3 flex items-center text-gray-600">
							<div
								class="flex w-1/2 items-center justify-end pr-2"
								v-html="
									isMac
										? `<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center mr-2'>⌘</span>
							<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center'>+</span>`
										: `<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center mr-2'>Ctrl</span>
							<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center'>+</span>`
								"
							/>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Zoom in</span>
						</li>
						<li class="flex items-center text-gray-600">
							<div
								class="flex w-1/2 items-center justify-end pr-2"
								v-html="
									isMac
										? `<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center mr-2'>⌘</span>
							<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center'>-</span>`
										: `<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center mr-2'>Ctrl</span>
							<span class='font-semibold text-gray-600 bg-gray-100/25 rounded w-8 h-8 flex items-center justify-center'>-</span>`
								"
							/>
							<span class="w-1/2 whitespace-nowrap pl-2 text-left">Zoom out</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</Modal>
</template>
