<script setup lang="ts">
import { Sketch } from '@ckpack/vue-color';
import { cloneDeep } from 'lodash-es';
import { ref, shallowRef } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
import ColorPickerOpacity from '@/components/Common/Color/ColorPickerOpacity.vue';
import ColorsPalette from '@/components/Common/Color/ColorsPalette.vue';
import RotationGradient from '@/components/Common/Color/RotationGradient.vue';
import StopsGradientPanel from '@/components/Common/Color/StopsGradientPanel.vue';
import TypeGradient from '@/components/Common/Color/TypeGradient.vue';
import EyeDrop from '@/components/Common/EyeDrop.vue';
import { useMountedState } from '@/composables/useMountedState';
import { Color, StopGradient } from '@/Types/colorsTypes';
import GAnalytics from '@/utils/GAnalytics';

const props = defineProps<{ gradient: GradientColor; hideAlpha?: boolean }>();
const emit = defineEmits<{ (e: 'change', gradient: Color): void }>();

const stopSelected = shallowRef<StopGradient>(props.gradient.stops[0]);
const sketchModel = ref(props.gradient.convertToSolidColor());

const selectStop = (stop: StopGradient) => {
	stopSelected.value = stop;
	sketchModel.value = stop;
};

const changeStop = (data: SolidColor | StopGradient) => {
	const color = data instanceof SolidColor ? data : SolidColor.fromObject(data);
	sketchModel.value = color;
	const oldStop = { ...stopSelected.value };
	stopSelected.value = { ...stopSelected.value, ...color.toObject() };

	const gradient = cloneDeep(props.gradient);
	gradient.updateStop(oldStop, stopSelected.value);

	GAnalytics.track('click', 'Button', `edit-gradient`, null);
	emit('change', gradient);
};

const ready = useMountedState();
</script>

<template>
	<div class="custom-picker">
		<Sketch
			:model-value="sketchModel"
			:preset-colors="[]"
			@update:model-value="($event: any) => changeStop($event.rgba)"
		/>
		<Teleport v-if="ready" to=".vc-sketch">
			<div class="relative flex w-full flex-col items-center pb-4 pt-1 lg:border-gray-700 lg:pb-3">
				<StopsGradientPanel :gradient="gradient" :selected="stopSelected" @select="selectStop" @change="changeStop" />
				<div class="hidden h-6 w-full justify-between lg:flex">
					<TypeGradient :gradient="gradient" />
					<RotationGradient :gradient="gradient" />
				</div>
			</div>
		</Teleport>
		<Teleport v-if="ready" to=".vc-sketch-controls">
			<EyeDrop @change="($event) =>  changeStop($event as SolidColor)" />
		</Teleport>
		<ColorPickerOpacity
			v-if="!hideAlpha && ready"
			:color="SolidColor.fromObject(stopSelected)"
			@change="($event) => changeStop($event as SolidColor)"
		/>
		<ColorsPalette v-if="ready" :selected="gradient" @change="($event) => changeStop($event as SolidColor)" />
	</div>
</template>
