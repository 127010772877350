<script lang="ts" setup>
// Composables
import { toRef } from 'vue';

// Classes
import Line from '@/Classes/Line';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useLine } from '@/composables/element/line/useLine';

// Props
const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');
const { updateLinecap, updateDasharray } = useLine(element);

const dashArrayList = Line.defaultDasharrays();

const toggleLinecap = () => {
	const newValue = element.value.linecap === 'butt' ? 'round' : 'butt';
	updateLinecap(newValue);
};
</script>

<template>
	<div class="mb-6">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Style</h4>
		<div data-testid="line-style-btns" class="button-group update-position-div mb-4 flex flex-1">
			<div v-for="(dashArrayValue, index) in dashArrayList" class="mr-px flex-1">
				<button
					class="flex h-8 w-full items-center justify-center px-3"
					:class="{
						'rounded-tl rounded-bl': index === 0,
						'rounded-tr rounded-br': index === dashArrayList.length - 1,
						'bg-gray-600 text-white': element.dasharray.toString() === dashArrayValue.toString(),
						'bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white':
							element.dasharray.toString() !== dashArrayValue.toString(),
					}"
					@click="() => updateDasharray(dashArrayValue)"
				>
					<SvgIcon :name="'border-dasharray-' + index" class="h-6 w-6" />
				</button>
			</div>
		</div>
		<button
			class="relative flex h-4 w-4 cursor-pointer items-center justify-center rounded-sm border text-white"
			data-testid="button-keep-proportion"
			:class="{
				'border-gray-600 bg-transparent': element.linecap !== 'round',
				'border-blue-500 bg-blue-500 ': element.linecap === 'round',
			}"
			@click="toggleLinecap"
		>
			<SvgIcon v-if="element.linecap === 'round'" name="check" class="h-3 w-3 fill-current"></SvgIcon>

			<label
				data-testid="keep-proportion"
				class="absolute top-0 left-0 ml-5 flex h-4 items-center whitespace-nowrap text-xs text-gray-100"
				>Rounded</label
			>
		</button>
	</div>
</template>
