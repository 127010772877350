import { computed, Ref, ref } from 'vue';

import { Text } from '@/Classes/Text';
import { TextEffects } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';
import { TEXT_EFFECTS_PRESETS } from '@/utils/TextEffectsPresets';
import TextTools from '@/utils/TextTools';

export const useTextEffects = (element: Ref<Text>) => {
	const currentEffect = ref<TextEffects>(TextEffects.None);

	const color = computed(() => element.value.color);

	// Colored effects are main text color depending effects
	const hasColoredEffect = computed(() => hasEcho.value || hasNeon.value);

	const hasCurved = computed(() => currentEffect.value === TextEffects.Curve);

	const hasEcho = computed(
		() =>
			TextTools.textShadowToCssString(TEXT_EFFECTS_PRESETS.ECHO(color.value)) ===
			TextTools.textShadowToCssString(element.value.textShadow)
	);

	const hasEffect = computed(
		() =>
			hasCurved.value ||
			hasEcho.value ||
			hasNeon.value ||
			hasOutline.value ||
			hasGlitch.value ||
			(hasShadow.value && !hasMultiShadow.value) ||
			hasSticker.value
	);

	const hasGlitch = computed(
		() =>
			TextTools.textShadowToCssString(TEXT_EFFECTS_PRESETS.GLITCH()) ===
			TextTools.textShadowToCssString(element.value.textShadow)
	);

	const hasMultiShadow = computed(() => element.value.textShadow.length > 1);

	const hasNeon = computed(
		() =>
			TextTools.textShadowToCssString(TEXT_EFFECTS_PRESETS.NEON(color.value)) ===
			TextTools.textShadowToCssString(element.value.textShadow)
	);

	const hasOutline = computed(() => !!element.value.outline.width);

	const hasPreset = computed(() => hasEcho.value || hasNeon.value || hasGlitch.value || hasSticker.value);

	const hasShadow = computed(() =>
		element.value.textShadow.some((ts) => ts.angle || ts.blur || ts.distance || ts.opacity)
	);

	const hasSticker = computed(() => {
		const { OUTLINE, SHADOWS } = TEXT_EFFECTS_PRESETS.STICKER();

		const isShadowMatch =
			TextTools.textShadowToCssString(SHADOWS) === TextTools.textShadowToCssString(element.value.textShadow);

		const isOutlineMatch =
			OUTLINE.color.toCssString() === element.value.outline.color.toCssString() &&
			OUTLINE.width === element.value.outline.width &&
			OUTLINE.unit === element.value.outline.unit;

		return isShadowMatch && isOutlineMatch;
	});

	const isOutlineEm = computed(() => element.value.outline.unit === 'em');

	const isTextShadowEm = computed(() => element.value.textShadow.some((ts) => ts.unit === 'em'));

	const textEffectHandler = async (effect: TextEffects) => {
		const resetEffects = [TextEffects.Echo, TextEffects.Glitch, TextEffects.Neon, TextEffects.Sticker].includes(effect);

		// Toggle effect
		currentEffect.value = effect === currentEffect.value ? TextEffects.None : effect;

		if (resetEffects) {
			element.value.outline = Text.defaults().outline;
			element.value.textShadow = Text.defaults().textShadow;
		}

		if ([TextEffects.Echo, TextEffects.Glitch, TextEffects.Neon, TextEffects.Sticker].includes(currentEffect.value)) {
			applyTextEffect(currentEffect.value);
		}

		GAnalytics.track('select', 'button', `style-text-${currentEffect.value}`, null);
	};

	const applyTextEffect = (effect: TextEffects) => {
		if (effect === TextEffects.Echo) applyEchoEffect();
		if (effect === TextEffects.Glitch) applyGlitchEffect();
		if (effect === TextEffects.Neon) applyNeonEffect();
		if (effect === TextEffects.Sticker) applyStickerEffect();
	};

	const applyEchoEffect = () => {
		element.value.textShadow = TEXT_EFFECTS_PRESETS.ECHO(element.value.color);
	};

	const applyGlitchEffect = () => {
		element.value.textShadow = TEXT_EFFECTS_PRESETS.GLITCH();
	};

	const applyNeonEffect = () => {
		element.value.textShadow = TEXT_EFFECTS_PRESETS.NEON(color.value);
	};

	const applyStickerEffect = () => {
		element.value.outline = TEXT_EFFECTS_PRESETS.STICKER().OUTLINE;
		element.value.textShadow = TEXT_EFFECTS_PRESETS.STICKER().SHADOWS;
	};

	const resetEffect = (effect: TextEffects) => {
		currentEffect.value = TextEffects.None;

		if (effect === TextEffects.Curve) {
			element.value.curvedProperties.arc = null;
		}

		if (effect === TextEffects.Outline) {
			element.value.outline.width = 0;
		}

		if ([TextEffects.Echo, TextEffects.Glitch, TextEffects.Neon, TextEffects.Shadow].includes(effect)) {
			element.value.textShadow = Text.defaults().textShadow;
		}

		if (effect === TextEffects.Sticker) {
			element.value.outline = Text.defaults().outline;
			element.value.textShadow = Text.defaults().textShadow;
		}
	};

	const refreshEffect = (effect: TextEffects) => {
		if (effect === TextEffects.Echo) applyEchoEffect();
		if (effect === TextEffects.Neon) applyNeonEffect();
	};

	return {
		currentEffect,
		hasColoredEffect,
		hasCurved,
		hasEcho,
		hasEffect,
		hasGlitch,
		hasMultiShadow,
		hasNeon,
		hasOutline,
		hasPreset,
		hasShadow,
		hasSticker,
		isOutlineEm,
		isTextShadowEm,
		textEffectHandler,
		resetEffect,
		refreshEffect,
	};
};
