import Element from '@/Classes/Element';
import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
import { Color } from '@/Types/colorsTypes';
import { FontStyle, FontWeight, TextAlign, TextTransform } from '@/Types/text';
import {
	CurvedProperties,
	Flip,
	Position,
	SerializedClass,
	Size,
	TextOutlineProperties,
	TextShadowProperties,
} from '@/Types/types';

type LegacyTextValues = {
	shadowAngle: number;
	shadowColor: SolidColor;
	shadowOpacity: number;
	shadowDistance: number;
	shadowBlur: number;
	borderColor: SolidColor;
	borderWidth: number;
};

export class Text extends Element {
	type: 'text' = 'text';
	content: string;
	fontFamily: string;
	fontWeight: FontWeight;
	fontStyle: FontStyle;
	fontSize: number;
	lineHeight: number;
	letterSpacing: number;
	textAlign: TextAlign;
	outline: TextOutlineProperties;
	color: Color;
	colors: Color[];
	textTransform: TextTransform;
	scale: number;
	textShadow: TextShadowProperties[];
	listStyle: string;
	link: string[];
	curvedProperties: CurvedProperties;

	protected constructor(
		// Element
		metadata: object,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number,
		// Text
		content: string,
		fontFamily: string,
		fontWeight: FontWeight,
		fontStyle: FontStyle,
		fontSize: number,
		lineHeight: number,
		letterSpacing: number,
		textAlign: TextAlign,
		outline: TextOutlineProperties,
		color: Color,
		colors: Color[],
		textTransform: TextTransform,
		scale: number,
		textShadow: TextShadowProperties[] | [],
		listStyle: string,
		link: string[],
		curvedProperties: CurvedProperties
	) {
		super(metadata, size, position, rotation, flip, group, locked, keepProportions, opacity);

		this.content = content;
		this.fontFamily = fontFamily;
		this.fontWeight = fontWeight;
		this.fontStyle = fontStyle;
		this.fontSize = fontSize;
		this.lineHeight = lineHeight;
		this.letterSpacing = letterSpacing;
		this.textAlign = textAlign;
		this.outline = outline;
		this.color = color;
		this.colors = colors;
		this.textTransform = textTransform;
		this.scale = scale;
		this.textShadow = textShadow;
		this.listStyle = listStyle;
		this.link = link;
		this.curvedProperties = curvedProperties;
	}

	static defaults() {
		const blackColor = SolidColor.black();

		return {
			// Element
			metadata: {},
			size: { height: 0, width: 0 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: true,
			opacity: 1,
			// Text
			content: 'Text',
			fontFamily: 'Montserrat',
			fontWeight: 400 as FontWeight,
			fontStyle: 'normal' as FontStyle,
			fontSize: 16,
			lineHeight: 1.2,
			letterSpacing: 0,
			textAlign: 'center' as TextAlign,
			outline: {
				color: SolidColor.white(),
				width: 0,
			},
			color: blackColor,
			colors: [blackColor],
			textTransform: '' as TextTransform,
			scale: 1,
			textShadow: [] as TextShadowProperties[],
			listStyle: '',
			link: [''],
			arc: null,
			minArc: null,
			transformCurve: null,
			curvedProperties: {
				arc: null,
				minArc: 0,
				transformCurve: 0,
			},
		};
	}

	static create(content: string, config?: Partial<Text>): Text {
		const defaults = Text.defaults();

		return new Text(
			// Element
			config?.metadata || defaults.metadata,
			config?.size || defaults.size,
			config?.position || defaults.position,
			config?.rotation || defaults.rotation,
			config?.flip || defaults.flip,
			config?.group || defaults.group,
			config?.locked || defaults.locked,
			config?.keepProportions || defaults.keepProportions,
			config?.opacity || defaults.opacity,
			// Text
			content,
			config?.fontFamily || defaults.fontFamily,
			config?.fontWeight || defaults.fontWeight,
			config?.fontStyle || defaults.fontStyle,
			config?.fontSize || defaults.fontSize,
			config?.lineHeight || defaults.lineHeight,
			config?.letterSpacing || defaults.letterSpacing,
			config?.textAlign || defaults.textAlign,
			config?.outline || defaults.outline,
			config?.color || defaults.color,
			config?.colors || defaults.colors,
			config?.textTransform || defaults.textTransform,
			config?.scale || defaults.scale,
			config?.textShadow || defaults.textShadow,
			config?.listStyle || defaults.listStyle,
			config?.link || defaults.link,
			config?.curvedProperties || defaults.curvedProperties
		);
	}

	static unserialize(data: SerializedClass<Text> & LegacyTextValues): Text {
		const defaults = Text.defaults();

		const {
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// Text
			content,
			fontFamily,
			fontWeight,
			fontStyle,
			fontSize,
			lineHeight,
			letterSpacing,
			textAlign,
			color,
			colors,
			textTransform,
			scale,
			listStyle,
			link,
			curvedProperties,
		} = data;

		const fixedTextTransform = Text.unserializeTextTransform(textTransform);

		// Gradients have id (and more) but Solids only have color channels (r,g,b,a)
		const fixedOutline = Text.unserializeOutline(data);

		let fixedColor: Color = defaults.color;
		if (color) {
			fixedColor = 'stops' in color ? GradientColor.unserialize(color) : SolidColor.unserialize(color);
		}

		const fixedTextShadow = Text.unserializeTextShadow(data);

		// los arrays de la serialización vienen a veces como objetos y no sabemos por que
		const fixedLink = Array.isArray(link) ? link : (Object.values(link as any) as string[]);

		const fixedArrayColors = Array.isArray(colors)
			? colors.map((c) => ('stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)))
			: Object.values(colors).map((c) => ('stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)));

		if (fixedArrayColors.length === 1 && fixedArrayColors[0].toCssString() !== fixedColor.toCssString()) {
			fixedColor = fixedArrayColors[0];
		}

		let fontFamilyFixed = fontFamily;
		// durante un día se almacenaron las fuentes con comillas
		if (fontFamilyFixed && fontFamilyFixed.startsWith('"')) {
			fontFamilyFixed = fontFamilyFixed.replaceAll('"', '');
		}

		let fixedSize = size;

		if (size && !size.height) {
			// hay textos que no tienen alto por un bug en el editor
			fixedSize = {
				width: size.width,
				height: fontSize || 30,
			};
		}

		const elem = new Text(
			// Element
			metadata || defaults.metadata,
			fixedSize || defaults.size,
			position || defaults.position,
			rotation !== undefined ? rotation : defaults.rotation,
			flip || defaults.flip,
			group || defaults.group,
			locked !== undefined ? locked : defaults.locked,
			keepProportions !== undefined ? keepProportions : defaults.keepProportions,
			opacity !== undefined ? opacity : defaults.opacity,
			// Text
			content || defaults.content,
			fontFamilyFixed || defaults.fontFamily,
			fontWeight || defaults.fontWeight,
			fontStyle || defaults.fontStyle,
			fontSize !== undefined ? fontSize : defaults.fontSize,
			lineHeight !== undefined ? Math.max(0.1, lineHeight) : defaults.lineHeight,
			letterSpacing !== undefined ? letterSpacing : defaults.letterSpacing,
			textAlign || defaults.textAlign,
			fixedOutline,
			fixedColor,
			fixedArrayColors || defaults.colors,
			fixedTextTransform || defaults.textTransform,
			scale !== undefined ? scale : defaults.scale,
			fixedTextShadow,
			listStyle || defaults.listStyle,
			fixedLink || defaults.link,
			curvedProperties || defaults.curvedProperties
		);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	protected static unserializeTextTransform(textTransform: TextTransform | undefined) {
		return ['', 'lowercase', 'uppercase', 'capitalize'].includes(textTransform as string) ? textTransform : '';
	}

	protected static unserializeOutline(data: SerializedClass<Text> & LegacyTextValues): TextOutlineProperties {
		const { color, width } = Text.defaults().outline;

		let fixedOutlineColor: Color = color;

		// Support legacy data structure
		if (data.borderColor) {
			fixedOutlineColor =
				'stops' in data.borderColor
					? GradientColor.unserialize(data.borderColor)
					: SolidColor.unserialize(data.borderColor);
		}

		// Support new data structure
		if (data.outline?.color) {
			fixedOutlineColor =
				'stops' in data.outline.color
					? GradientColor.unserialize(data.outline.color)
					: SolidColor.unserialize(data.outline.color);
		}

		return {
			color: fixedOutlineColor,
			width: data.borderWidth || data.outline?.width || width,
			unit: data.outline?.unit || 'px',
		};
	}

	protected static unserializeTextShadow(data: SerializedClass<Text> & LegacyTextValues) {
		let fixedTextShadow = Text.defaults().textShadow;

		// Support legacy data structure
		if (Object.keys(data).includes('shadowAngle')) {
			fixedTextShadow = [
				{
					angle: typeof data.shadowAngle === 'string' ? parseFloat(data.shadowAngle) : data.shadowAngle,
					blur: typeof data.shadowBlur === 'string' ? parseFloat(data.shadowBlur) : data.shadowBlur,
					color:
						'stops' in data.shadowColor
							? GradientColor.unserialize(data.shadowColor)
							: SolidColor.unserialize(data.shadowColor),
					distance: typeof data.shadowDistance === 'string' ? parseFloat(data.shadowDistance) : data.shadowDistance,
					opacity: typeof data.shadowOpacity === 'string' ? parseFloat(data.shadowOpacity) : data.shadowOpacity,
				},
			];
		}

		// Support new data structure
		if (data.textShadow) {
			fixedTextShadow = Array.isArray(data.textShadow)
				? data.textShadow.map((ts) => ({ ...ts, color: SolidColor.unserialize(ts.color) }))
				: Object.values(data.textShadow).map((ts) => ({ ...ts, color: SolidColor.unserialize(ts.color) }));
		}

		return fixedTextShadow;
	}

	setScale(scale: number) {
		this.scale = scale;
	}

	public static createDefault(merge = {}) {
		let defaults = Text.defaults();
		defaults = { ...defaults, ...merge };

		return new Text(
			// Element
			defaults.metadata,
			defaults.size,
			defaults.position,
			defaults.rotation,
			defaults.flip,
			defaults.group,
			defaults.locked,
			defaults.keepProportions,
			defaults.opacity,
			// Text
			defaults.content,
			defaults.fontFamily,
			defaults.fontWeight,
			defaults.fontStyle,
			defaults.fontSize,
			defaults.lineHeight,
			defaults.letterSpacing,
			defaults.textAlign,
			defaults.outline,
			defaults.color,
			defaults.colors,
			defaults.textTransform,
			defaults.scale,
			defaults.textShadow,
			defaults.listStyle,
			defaults.link,
			defaults.curvedProperties
		);
	}

	updateColor(newColor: Color) {
		newColor.id = this.color.id;
		this.color = newColor;
	}
}
