import imageCompression from 'browser-image-compression';

import { Size } from '@/Types/types';

class ImageTools {
	static getRealImageSize(url: string): Promise<Size> {
		const promise = new Promise<Size>((resolve) => {
			const image: HTMLImageElement = document.createElement('img');

			image.addEventListener('load', () => {
				resolve(ImageTools.sizeInDom(image));
			});

			image.src = url;

			if (image.complete) {
				resolve(ImageTools.sizeInDom(image));
			}
		});

		return promise;
	}

	static sizeInDom(image: HTMLImageElement): Size {
		image.style.maxWidth = 'inherit';
		document.body.insertAdjacentElement('beforeend', image);
		const { width, height } = image;
		document.body.removeChild(image);
		return { width, height };
	}

	static allPageImagesLoaded(): Promise<any> {
		return Promise.all(
			Array.from(document.images)
				.filter((img) => !img.complete)
				.map(
					(img) =>
						new Promise((resolve) => {
							img.onload = img.onerror = resolve;
						})
				)
		);
	}

	static dataURItoBlob = (dataURI) => {
		let byteString;

		if (dataURI.split(',')[0].indexOf('base64') >= 0) {
			byteString = atob(dataURI.split(',')[1]);
		} else {
			byteString = unescape(dataURI.split(',')[1]);
		}

		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	};

	static blobToBase64 = (blob) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);

		return new Promise((resolve) => {
			reader.onloadend = () => {
				resolve(reader.result);
			};
		});
	};

	static compressImage = async (imageURL: string) => {
		const options = {
			maxSizeMB: 2,
			maxWidthOrHeight: 2000,
			useWebWorker: false,
		};

		const imageFile = this.dataURItoBlob(imageURL);
		const blob = await imageCompression(imageFile as File, options);
		const base64 = await this.blobToBase64(blob);

		return base64;
	};

	static loadImg(url: string): Promise<HTMLImageElement> {
		const img = document.createElement('img');

		return new Promise((resolve, reject) => {
			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = url;
		});
	}

	static getImageAsBlobUrl(url: string): Promise<{ url: string; type: string }> {
		url = ImageTools.getImageUrlViaProxy(url);

		if (url.startsWith('data:')) {
			fetch(url)
				.then((res) => res.blob())
				.then((res) => {
					const blobUrl = URL.createObjectURL(res);
					return { url: blobUrl, type: res.type };
				});
		}

		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', url);
			xhr.responseType = 'blob';

			xhr.onerror = function () {
				reject(new Error(`Image not found: ${url}`));
			};

			xhr.onreadystatechange = async function () {
				if (xhr.status >= 400 && xhr.status < 600) {
					reject(new Error(`Image not found: ${url}`));
				}

				if (xhr.readyState === 4 && xhr.status === 200) {
					resolve({ url: URL.createObjectURL(xhr.response), type: xhr.response.type });
				}
			};

			xhr.send(null);
		});
	}

	static async urlToBase64(blobUrl: string): Promise<string> {
		const blob = await fetch(blobUrl).then((r) => r.blob());
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.readAsDataURL(blob);
		});
	}

	static getImageUrlViaProxy(url: string) {
		// En estos casos no usamos el proxy
		if (
			url.includes('api/image') ||
			url.includes(';base64,') ||
			url.includes('wepik.com') ||
			url.includes('localhost') ||
			url.includes('googleusercontent') ||
			url.startsWith('blob:')
		) {
			return url;
		}

		return `${import.meta.env.VITE_APP_API_PATH}image/serve?url=${encodeURIComponent(url)}`;
	}

	static async resizeDomImage(img: HTMLImageElement, targetSize: Size, mime: string): Promise<string> {
		const canvas = document.createElement('canvas'),
			ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

		let targetWidth = targetSize.width;
		let targetHeight = targetSize.height;

		// if the image is wider than it is high, we need to constrain the
		// width to the value passed in or the image will be squished
		// and the height will be wrong
		if (img.width > img.height) {
			targetHeight = (img.height * targetWidth) / img.width;
		} else {
			targetWidth = (img.width * targetHeight) / img.height;
		}

		canvas.width = targetWidth;
		canvas.height = targetHeight;

		ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, targetWidth, targetHeight);

		return new Promise((resolve) => {
			canvas.toBlob((blob) => {
				const newUrl = URL.createObjectURL(blob as Blob);
				resolve(newUrl);
			}, mime);
		});
	}

	static async maskImage(sourceSrc: string, maskSrc: string): Promise<string> {
		const source = await ImageTools.loadImg(sourceSrc);
		const mask = await ImageTools.loadImg(maskSrc);

		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

		canvas.width = source.width;
		canvas.height = source.height;

		ctx.drawImage(source, 0, 0);
		ctx.globalCompositeOperation = 'destination-out';
		ctx.drawImage(mask, 0, 0, canvas.width, canvas.height);

		return new Promise((resolve) => {
			canvas.toBlob((blob) => {
				const newUrl = URL.createObjectURL(blob as Blob);
				resolve(newUrl);
			});
		});
	}

	static async findTransparentCorners(imageUrl: string) {
		const source = await ImageTools.loadImg(imageUrl);

		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

		canvas.width = source.width;
		canvas.height = source.height;

		ctx.drawImage(source, 0, 0);

		const data = ctx.getImageData(0, 0, canvas.width, canvas.height);

		let top = 0,
			bottom = data.height,
			left = 0,
			right = data.width;

		while (top < bottom && rowBlank(data, canvas.width, top)) ++top;
		while (bottom - 1 > top && rowBlank(data, canvas.width, bottom - 1)) --bottom;
		while (left < right && columnBlank(data, canvas.width, left, top, bottom)) ++left;
		while (right - 1 > left && columnBlank(data, canvas.width, right - 1, top, bottom)) --right;

		return {
			top,
			bottom,
			left,
			right,
			width: canvas.width,
			height: canvas.height,
		};
	}
}

function rowBlank(imageData, width, y) {
	for (let x = 0; x < width; ++x) {
		if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
	}
	return true;
}

function columnBlank(imageData, width, x, top, bottom) {
	for (let y = top; y < bottom; ++y) {
		if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
	}
	return true;
}

export default ImageTools;
