<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { computed, defineEmits, ref, toRef } from 'vue';

import { FilterPresetEmpty } from '@/Classes/FilterPresets';
// Classes
import Image from '@/Classes/Image';
import CanvasTeleport from '@/components/Common/CanvasTeleport.vue';
// Types
import Dropdown from '@/components/Common/Dropdown.vue';
import Popper from '@/components/Common/Popper.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import ImageFilter from '@/components/selection/toolbars/images/ImageFilter.vue';
import { useElementRenderStyles } from '@/composables/canvas/useElementRenderStyles';
// Composables
import { useImage } from '@/composables/element/image/useImage';
import { useAuth } from '@/composables/useAuth';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useToast } from '@/composables/useToast';

// Props
const props = defineProps<{ element: Image }>();
const emits = defineEmits(['loading', 'endLoading']);
const element = toRef(props, 'element');
const { replace, removeBackground } = useImage(element);
const { isMobile } = useDeviceInfo();
const toast = useToast();

// Data
const removingBackground = ref(false);
const showFilters = ref(false);

const { requireAuth } = useAuth();
const { isPhotoMode } = useEditorMode();

const setBackgroundMode = async (value: string) => {
	if (!isPhotoMode.value && requireAuth()) {
		return;
	}

	// If we're going to keep both items, we're going to add a default blur filter
	// to the background element.
	if (value === 'both') {
		element.value.filter = new FilterPresetEmpty();
		element.value.filter.blur = 10;
	}

	if (value !== 'both' && element.value.backgroundMode === 'both') {
		element.value.filter = null;
	}

	// Set the display mode for the image
	element.value.backgroundMode = value;
	Bugsnag.leaveBreadcrumb(`Select the follow background mode: ${value}`);
	if (value !== 'original' && !element.value.urlBackgroundRemoved) {
		await requestBackgroundRemoval();
	}
};

const requestBackgroundRemoval = async () => {
	if (removingBackground.value) return;

	removingBackground.value = true;
	emits('loading');

	try {
		const remainingRemovals = await removeBackground();

		if (remainingRemovals > 0) {
			toast.success(`${remainingRemovals} more background removals available for today`);
		} else if (remainingRemovals === 0) {
			toast.warning(`You've reached background removals for today`);
		}
	} catch (error: any) {
		toast.error(error.message);
		Bugsnag.leaveBreadcrumb(`Error removing image background to ${element.value.type}-${element.value.id}`);
	} finally {
		emits('endLoading');
		removingBackground.value = false;
		Bugsnag.leaveBreadcrumb(`Apply remove background to ${element.value.type}-${element.value.id}`);
	}
};

const { styles } = useElementRenderStyles(props.element);
const options = computed(() =>
	[
		{ label: 'Original', value: 'original' },
		{ label: 'Remove background', value: 'foreground' },
		{ label: 'Remove object', value: 'background' },
		!isMobile.value && { label: 'Stylize the background', value: 'both' },
	].filter((i) => !!i)
);
</script>

<template>
	<button @click="element.backgroundMode === 'original' && setBackgroundMode('foreground')">
		<Dropdown
			class="flex w-12 items-center justify-center lg:w-auto"
			:options="options"
			:selected="element.backgroundMode"
			:hide-options="removingBackground"
			@update="setBackgroundMode"
		>
			<template #title>
				<span class="flex p-2 text-center font-bold">Background removal options</span>
			</template>
			<template #default="{ onToggleVisibility }">
				<button
					:tooltip="element.backgroundMode === 'original' ? `Remove background` : `Recover background`"
					tooltip-position="top"
					data-button-importance="important"
					class="relative flex h-11 w-14 shrink-0 flex-col items-center justify-between whitespace-nowrap text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
					@click="onToggleVisibility"
				>
					<SvgIcon name="remove-bg" class="h-5 w-5 shrink-0 scale-90 lg:h-4 lg:w-4" />
					<span class="text-2xs lg:mt-0 lg:ml-2 lg:text-sm" data-button-label>Remove Bg</span>
					<span
						class="font-sm absolute -top-1 right-0 flex h-4 shrink-0 scale-75 items-center justify-center rounded bg-blue-500 px-1 text-2xs font-bold text-white lg:relative lg:top-auto lg:right-auto lg:-mr-1 lg:ml-2 lg:scale-100"
					>
						<p class="mt-px">NEW</p>
					</span>
				</button>
			</template>
		</Dropdown>
	</button>

	<CanvasTeleport v-if="removingBackground" :element="element">
		<div :style="styles" class="pointer-events-none flex items-center justify-center bg-gray-900/60">
			<SvgIcon name="spinner" class="h-1/6 w-1/6 animate-spin text-white" />
		</div>
	</CanvasTeleport>

	<!-- Filter panel Desktop -->
	<Popper v-if="showFilters && !isMobile" parent-selector=".toolbar" placement="right-start" :offsets="{ x: 0, y: 5 }">
		<div class="h-auto w-48 rounded bg-gray-800 p-2 text-white">
			<ImageFilter :image="element" />
		</div>
	</Popper>
</template>
