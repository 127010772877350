<script lang="ts" setup>
// Vue
import { toRef } from 'vue';

// Classes
import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composable
import { useElementOrderOrchestrator } from '@/composables/element/useElementOrderOrchestrator';

// Props
const props = defineProps<{ element: Element }>();

// Data
const element = toRef(props, 'element');

// Using composables
const { moveToFront, moveToBack, canGoUp, canGoDown } = useElementOrderOrchestrator(element).value;
</script>

<template>
	<div class="flex">
		<button
			data-testid="to-back"
			class="flex h-11 w-14 shrink-0 flex-col items-center justify-between"
			:class="{
				'text-gray-300 opacity-75': !canGoDown,
				'text-gray-100 hover:text-white': canGoDown,
			}"
			:disabled="!canGoDown"
			@click="moveToBack"
		>
			<SvgIcon name="backward" class="h-5 w-5 fill-current" />
			<span class="text-2xs">To back</span>
		</button>
		<button
			data-testid="to-front"
			class="flex h-11 w-14 shrink-0 flex-col items-center justify-between"
			:class="{
				'text-gray-300 opacity-75': !canGoUp,
				'text-gray-100 hover:text-white': canGoUp,
			}"
			:disabled="!canGoUp"
			@click="moveToFront"
		>
			<SvgIcon name="forward" class="h-5 w-5 fill-current" />
			<span class="text-2xs">To front</span>
		</button>
	</div>
</template>
