import { v4 as uuidv4 } from 'uuid';

import { Flip, Position, Size } from '@/Types/types';
import CollisionTools from '@/utils/CollisionTools';
import MathTools from '@/utils/MathTools';

abstract class Element {
	id: string;
	abstract type: 'shape' | 'storyset' | 'text' | 'image' | 'line' | 'qrcode' | 'illustrator';
	metadata: object | any | null;
	size: Size;
	position: Position;
	rotation: number;
	flip: Flip;
	group: string | null;
	locked: boolean;
	keepProportions: boolean;
	opacity: number;

	protected constructor(
		metadata: object | null,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number
	) {
		this.id = uuidv4();
		this.metadata = metadata;
		this.size = size;
		this.position = position;
		this.rotation = rotation;
		this.flip = flip;
		this.locked = locked;
		this.group = group;
		this.keepProportions = keepProportions;
		this.opacity = opacity;
	}

	get flipHTML(): { x: number; y: number } {
		return {
			x: this.flip.x ? -1 : 1,
			y: this.flip.y ? -1 : 1,
		};
	}

	setSize(width: number, height: number): this {
		this.size.width = width;
		this.size.height = height;
		return this;
	}

	setPosition(x: number, y: number) {
		this.position = {
			x,
			y,
		};
	}

	setMetadata(metadata: object) {
		this.metadata = metadata;
	}

	setOpacity(opacity: number) {
		this.opacity = opacity;
	}

	setLocked(locked: boolean) {
		this.locked = locked;
	}

	setGroup(group: string | null) {
		this.group = group;
	}

	setRotation(rotation: number) {
		this.rotation = rotation;
	}

	/**
	 * Retorna la primera aparicion
	 */
	domNode(): HTMLElement | null {
		return document.querySelector(`#element-${this.id}`);
	}

	getCorners() {
		let angle = this.rotation;
		const leftTopCorner = MathTools.getRotatedTopLeftCornerOfRect(
			this.position.x,
			this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		const vecLength = MathTools.getVectorLength(this.position.x, this.position.y, this.size.width, this.size.height);

		angle += MathTools.getAngleForNextCorner(this.size.width / 2, vecLength);
		const rightTopCorner = MathTools.getRotatedTopLeftCornerOfRect(
			this.position.x,
			this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		angle += MathTools.getAngleForNextCorner(this.size.height / 2, vecLength);
		const rightBottomCorner = MathTools.getRotatedTopLeftCornerOfRect(
			this.position.x,
			this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		angle += MathTools.getAngleForNextCorner(this.size.width / 2, vecLength);
		const leftBottomCorner = MathTools.getRotatedTopLeftCornerOfRect(
			this.position.x,
			this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		return [leftTopCorner, rightTopCorner, rightBottomCorner, leftBottomCorner];
	}

	isCollided(el: Element) {
		// This check is lighter than checking with rotation
		const hasCollisionWithBox = CollisionTools.checkCollisionWithBox(
			this.domNode() as HTMLElement,
			el.domNode() as HTMLElement
		);

		if (!hasCollisionWithBox) return hasCollisionWithBox;

		// Used only to check if elements are really collisioning
		return CollisionTools.checkCollisionWithRotation(this, el);
	}
}

export default Element;
