<script setup lang="ts">
// Vue & Packages
import { MaybeElementRef, useMouseInElement, watchOnce } from '@vueuse/core';
import { intersection } from 'lodash-es';
import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';

// Classes
import Page from '@/Classes/Page';
import { Text } from '@/Classes/Text';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Canvas from '@/components/render/Canvas.vue';
import CanvasTools from '@/components/render/CanvasTools.vue';
import Crop from '@/components/render/Crop.vue';
import CropPhotoMode from '@/components/render/CropPhotoMode.vue';
import ImageReplacing from '@/components/render/ImageReplacing.vue';
import TextEditing from '@/components/render/TextEditing.vue';
import ElementsSelection from '@/components/selection/ElementsSelection.vue';
// Composables
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useCanvasSize } from '@/composables/useCanvasSize';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useHandleDraggables } from '@/composables/useHandleDraggables';
// Stores
import { useMainStore } from '@/stores/store';
const store = useMainStore();

// Props
const props = defineProps<{ page: Page; scrollArea: MaybeElementRef }>();

// Data
const page = toRef(props, 'page');

const canvas = ref();
const canvasTool = ref();
const domNode = ref();
const interactiveContainer = ref();
const showSelection = ref(false);

// Using composables
const { isPhotoMode } = useEditorMode();
const { isIdle } = useInteractions();
const { isOutside } = useMouseInElement(interactiveContainer);
const { isMobile } = useDeviceInfo();
const { width, height, canvasWidthScaled, canvasHeightScaled, canvasToolHeight } = useCanvasSize(
	canvas,
	canvasTool,
	interactiveContainer
);

// Computeds
const activePageId = computed(() => store.activePageId);
const selectionCanvas = computed(() => store.getSelectionFromCanvas(page.value));
// Avoid blank empty canvas flash in photo mode
const isReadyToBeShown = computed(() => !isPhotoMode.value || page.value.elements[0]?.locked);

// Using composables
const { hoveredImage, draggingFile } = useHandleDraggables(page, interactiveContainer);

// Lifecycle hooks
onMounted(() => {
	if (page.value.name == 'temporal') return;
	store.activePageId = store.activePageId || page.value.id;
});

// Watchers
watch(isOutside, async (isOutside) => {
	if (isOutside || !isIdle.value || store.activePageId === page.value.id) {
		return;
	}

	// si había algo seleccionado, esperamos a la deselección para marcar el active
	if (store.selection.length) {
		watchOnce(
			() => store.selection,
			() => store.setActivePage(page.value)
		);
		return;
	}
	store.setActivePage(page.value);
});

watch(
	selectionCanvas,
	async (value, oldValue) => {
		// Crop toolbar debe mostrarse cuando hacemos crop a la foto del modo photo
		if (isPhotoMode.value && !!store.croppingId) {
			showSelection.value = true;
			return;
		}

		// Si la selección es la misma no queremos desmontar el componente ya que estaremos haciendo un cambio de z-index
		// o similar
		const newIds = value.map((e) => e.id);
		const oldIds = (oldValue || []).map((e) => e.id);

		// Intersection = elementos en ambos array
		const isSameSelection = oldIds.length === newIds.length && intersection(oldIds, newIds).length === newIds.length;
		if (isSameSelection) {
			return;
		}
		showSelection.value = false;
		if (!value.length) {
			return;
		}
		await nextTick();
		showSelection.value = true;
	},
	{ immediate: true }
);

watch(
	activePageId,
	() => {
		domNode.value = store.activePage?.domNode();
	},
	{ immediate: true }
);
</script>

<template>
	<div
		:id="`interactive-canvas-${page.id}`"
		ref="interactiveContainer"
		class="interactive-canvas relative my-auto mx-4 select-none"
		:data-page-id="page.id"
		@click="store.setActivePage(page)"
	>
		<div
			class="mx-auto my-4 lg:my-0 lg:overflow-hidden lg:p-8"
			:style="{
				width: `${!isMobile ? canvasWidthScaled + 64 + 'px' : canvasWidthScaled + 'px'}`,
				height: `${
					!isMobile ? canvasHeightScaled + canvasToolHeight + 64 + 'px' : canvasHeightScaled + canvasToolHeight + 'px'
				}`,
			}"
		>
			<CanvasTools v-if="!isPhotoMode" ref="canvasTool" :page="page" />
			<div>
				<ElementsSelection v-if="showSelection && selectionCanvas.length" :elements="selectionCanvas" />

				<div ref="canvas" class="relative h-0" style="transform: translateZ(0)">
					<Canvas
						v-show="isReadyToBeShown"
						lazy-render
						:page="page"
						:scroll-area="scrollArea"
						:use-parent-size="{
							width,
							height,
							canvasWidthScaled,
							canvasHeightScaled,
							scale: store.scale,
						}"
					>
						<template v-if="store.cropping && selectionCanvas.includes(store.cropping)">
							<CropPhotoMode
								v-if="isPhotoMode && page.backgroundImageId === store.croppingId"
								:image="store.cropping"
							/>
							<Crop v-else :image="store.cropping" />
						</template>
						<TextEditing
							v-if="store.textEditing && selectionCanvas.includes(store.textEditing)"
							:text="(store.textEditing as Text)"
						/>
						<ImageReplacing v-if="hoveredImage" :image="hoveredImage" />
					</Canvas>

					<div
						v-if="draggingFile"
						class="pointer-events-none absolute top-0 left-0 flex flex-col items-center justify-center border-2 border-dashed border-white bg-black/75 text-white"
						:style="{ width: `${canvasWidthScaled}px`, height: `${canvasHeightScaled}px` }"
					>
						<span class="text-xl font-bold">Drop file</span>
						<SvgIcon name="drop-zone" class="mt-8 h-32 w-32" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
