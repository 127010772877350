<script lang="ts" setup>
// Vue & Packages
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

// Classes
import Image from '@/Classes/Image';
import { Shape } from '@/Classes/Shape';
// Components
import PanelHeader from '@/components/panels/PanelHeader.vue';
import FreepikImages from '@/components/panels/photos/FreepikImages.vue';
import PexelImages from '@/components/panels/photos/PexelImages.vue';
import ProviderButtons from '@/components/panels/photos/ProviderButtons.vue';
import UploadPanel from '@/components/panels/photos/UploadPanel.vue';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useToast } from '@/composables/useToast';
import { useCustomImagesActions } from '@/composables/useUploadImagesActions';
import { useUserImageProvider } from '@/composables/useUserImageProvider';
// Store
import { useMainStore } from '@/stores/store';
// Types
import { ImageApi } from '@/Types/apiClient';
// Utils
import GAnalytics from '@/utils/GAnalytics';

const store = useMainStore();

// Emits
const emits = defineEmits<{
	(e: 'closeModal'): void;
	(e: 'selection', image: ImageApi): void;
}>();

// Props
const props = defineProps<{ disableClose?: boolean; modal?: boolean }>();

// Data
const provider = ref('freepik');
const search = ref('');

// Using composables
const { userUploads } = useUserImageProvider(false);
const temporalRef = ref<Image | Shape>(Image.createDefault());
const usingElementOrchestrator = useElementOrchestrator(temporalRef);
const { addElement } = useActivePage();
const { runOnMobile } = useDeviceInfo();

// Solo para provider Google Photos
// -------------------------------------------------------------------------------------------------
const { uploadImage } = useCustomImagesActions();
const toast = useToast();
const { onFetchResponse: onUploadResponse, onFetchError: onUploadError, data: imageApi } = uploadImage();
onUploadResponse(() => {
	userUploads.value.unshift(imageApi.value);
	toast.success('Image uploaded sucessfully');
});

onUploadError(() => {
	toast.error('Something went wrong with the upload');
});
// -------------------------------------------------------------------------------------------------

// Methods
const onCloseModal = () => {
	emits('closeModal');
};

const onSearch = (val: string) => (search.value = val);

const onSetProvider = (prov: string) => (provider.value = prov);

/**
 * Dependiendo de si el componente esta siendo usado en modal, emitimos o insertamos la imagen
 * @param img
 */
const selectImage = async (img: ImageApi) => {
	if (props.modal) {
		emits('selection', img);
		return;
	}

	// Si el elemento es del tipo svg lo añadimos como shape
	let element;
	if (img.type === 'svg') {
		element = await Shape.fromApiImage(img);
	} else {
		element = await Image.fromApiImage(img);
	}

	temporalRef.value = element;
	Bugsnag.leaveBreadcrumb(`Add image to canvas from ${provider.value}; idProvider: ${img.id}; image-${element.id}}`);
	GAnalytics.track('click', 'add-image', `${provider.value}`, null);

	addElement(element);
	usingElementOrchestrator.value.setupInPage();

	await store.setSelection(element, false);

	runOnMobile(() => (store.activePanel = null));
};
</script>

<template>
	<div id="photos-panel" class="flex h-full flex-col">
		<PanelHeader
			:disable-close="disableClose"
			:modal="modal"
			:title="modal ? 'Select image' : 'Add a new image'"
			@closeModal="onCloseModal"
		/>

		<ProviderButtons :modal="modal" :provider="provider" @setProvider="onSetProvider" />

		<KeepAlive>
			<FreepikImages
				v-if="provider === 'freepik'"
				:search="search"
				:modal="modal"
				@select="selectImage"
				@search="onSearch"
			/>
		</KeepAlive>

		<KeepAlive>
			<PexelImages
				v-if="provider === 'pexels'"
				:search="search"
				:modal="modal"
				@select="selectImage"
				@search="onSearch"
			/>
		</KeepAlive>

		<KeepAlive>
			<UploadPanel v-if="provider === 'upload'" :modal="modal" @select="selectImage" />
		</KeepAlive>
	</div>
</template>
