<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';

// Classes
import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useFeedback } from '@/composables/interactions/useFeedback';
// Composables
import { useActivePage } from '@/composables/page/useActivePage';

// Props
const props = defineProps<{ element: Element }>();

const { deleteFeedback } = useFeedback();

const { removeElement } = useActivePage();

const remove = () => {
	deleteFeedback();
	removeElement(props.element);
	Bugsnag.leaveBreadcrumb(`Remove ${props.element.type}-${props.element.id}`);
};
</script>

<template>
	<button
		data-testid="remove"
		class="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gray-600 text-sm text-white"
		@click="remove()"
	>
		<SvgIcon name="trash" :toolbar="true" class="h-3 w-3" />
	</button>
</template>
