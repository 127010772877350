<script lang="ts" setup>
import { ref, watch } from 'vue';

import { saveInterests } from '@/api/UserApiClient';
import CoverInterests from '@/assets/cover-interests.jpg';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useAuth } from '@/composables/useAuth';
import { useEditorMode } from '@/composables/useEditorMode';

const { isLogged, user } = useAuth();
const { inSlidesgoContext } = useEditorMode();

const show = ref();
watch(user, () => (show.value = !inSlidesgoContext.value && isLogged.value && !user.value?.interests));

const interest = ref();
const saveInterestsUser = async () => {
	const { data } = await saveInterests({ interest: interest.value });

	if (data.value) {
		show.value = false;
	}
};
</script>

<template>
	<div
		v-if="show"
		class="fixed inset-0 z-50 overflow-y-auto"
		aria-labelledby="modal-title"
		role="dialog"
		aria-modal="true"
	>
		<div
			class="fixed left-0 top-0 z-50 h-screen w-full justify-center bg-gray-900 bg-opacity-75 p-6 transition-opacity sm:inset-0 sm:flex sm:items-center"
		>
			<form
				method="post"
				class="relative max-h-full w-full max-w-4xl overflow-y-auto rounded-xl bg-white shadow-xl"
				@change.prevent="saveInterestsUser"
			>
				<div class="flex">
					<div class="w-full p-6 sm:w-7/12 sm:p-8">
						<h3 class="mb-2 text-xl font-semibold text-darkblue-500">Nice to meet you!</h3>
						<p class="mb-2 text-gray-500">
							We are glad you are here! It’s time to know you better so we can improve your user experience.<br />
							Please select one of the following options:
						</p>
						<p class="font-bold text-gray-700">For what purpose do you use Wepik?</p>
						<div class="mt-4 grid grid-cols-2 gap-4 lg:grid-cols-3">
							<label
								for="interest-1"
								class="relative flex w-auto cursor-pointer flex-col items-center justify-center rounded-lg border border-gray-100 p-4 text-gray-500 hover:border-blue-500 hover:text-blue-500"
							>
								<input id="interest-1" v-model="interest" value="personal" type="radio" class="hidden" name="radio" />
								<SvgIcon name="personal" alt="person icon" class="mb-2 h-7 w-7 sm:h-9 sm:w-9" />
								<p class="text-center text-sm leading-5">Personal use</p>
							</label>
							<label
								for="interest-2"
								class="relative flex w-auto cursor-pointer flex-col items-center justify-center rounded-lg border border-gray-100 p-4 text-gray-500 hover:border-blue-500 hover:text-blue-500"
							>
								<input id="interest-2" v-model="interest" value="freelance" type="radio" class="hidden" name="radio" />
								<SvgIcon name="freelancer" alt="person icon" class="mb-2 h-7 w-7 sm:h-9 sm:w-9" />
								<p class="text-center text-sm leading-5">I am a freelancer</p>
							</label>
							<label
								for="interest-3"
								class="relative flex w-auto cursor-pointer flex-col items-center justify-center rounded-lg border border-gray-100 p-4 text-gray-500 hover:border-blue-500 hover:text-blue-500"
							>
								<input
									id="interest-3"
									v-model="interest"
									value="small-business"
									type="radio"
									class="hidden"
									name="radio"
								/>
								<SvgIcon name="small-business" alt="person icon" class="mb-2 h-7 w-7 sm:h-9 sm:w-9" />
								<p class="text-center text-sm leading-5">I own a small business</p>
							</label>
							<label
								for="interest-4"
								class="relative flex w-auto cursor-pointer flex-col items-center justify-center rounded-lg border border-gray-100 p-4 text-gray-500 hover:border-blue-500 hover:text-blue-500"
							>
								<input
									id="interest-4"
									v-model="interest"
									value="small-company"
									type="radio"
									class="hidden"
									name="radio"
								/>
								<SvgIcon name="suitcase" alt="person icon" class="mb-2 h-7 w-7 sm:h-9 sm:w-9" />
								<p class="text-center text-sm leading-5">I work for a small company</p>
							</label>
							<label
								for="interest-5"
								class="relative flex w-auto cursor-pointer flex-col items-center justify-center rounded-lg border border-gray-100 p-4 text-gray-500 hover:border-blue-500 hover:text-blue-500"
							>
								<input
									id="interest-5"
									v-model="interest"
									value="large-company"
									type="radio"
									class="hidden"
									name="radio"
								/>
								<SvgIcon name="building" alt="person icon" class="mb-2 h-7 w-7 sm:h-9 sm:w-9" />
								<p class="text-center text-sm leading-5">I work for a large company</p>
							</label>
						</div>
					</div>
					<div class="relative hidden sm:flex sm:w-5/12">
						<img :src="CoverInterests" class="absolute left-0 top-0 h-full w-full object-cover object-left" />
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<style scoped></style>
