<script lang="ts" setup>
import { ref } from 'vue';

import { getImages } from '@/api/DataApiClient';
import { getUserGooglePhotos } from '@/api/UserApiClient';
import InfiniteLoading from '@/components/Common/_InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import ListImage from '@/components/panels/photos/ListImage.vue';
import { useUserImageProvider } from '@/composables/useUserImageProvider';
import { ImageApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		modal?: boolean;
	}>(),
	{
		modal: false,
	}
);

const { uploadFromPhotos } = useUserImageProvider(false);

const emit = defineEmits<{
	(e: 'select', value: ImageApi): void;
	(e: 'close'): void;
}>();

const page = ref(``);
const { data: images, isFetching } = getUserGooglePhotos(page, { refetch: true });

const loadMore = async () => {
	if (isFetching.value || !images.value?.links?.next) return;
	page.value = images.value.links.next;
};

const selectHandler = (img: ImageApi) => {
	uploadFromPhotos(img);
	emit('close');
};
</script>

<template>
	<div class="flex h-full flex-col">
		<div class="relative mb-3 flex items-center justify-between lg:mb-4">
			<div class="flex flex-1 items-center">
				<button class="text-gray-100 opacity-75 hover:text-white" @click="emit('close')">
					<SvgIcon name="return" class="h-6 w-6 fill-current"></SvgIcon>
				</button>
				<div class="h-6 w-6 rounded bg-white p-1">
					<SvgIcon name="integrations-photos" class="h-full w-full" />
				</div>
				<span class="ml-2 text-gray-100">Google Photos</span>
			</div>
		</div>

		<InfiniteLoading
			:data="(images && images.data) || []"
			:is-fetching="isFetching"
			:modal="modal"
			class="flex flex-col"
			masonry
			:masonry-cols="2"
			@load="loadMore"
		>
			<template #item="{ item }">
				<InsertableElement
					:data="item"
					:type="Panels.photos"
					:draggable="!props.modal"
					class="mb-2"
					@click="selectHandler(item)"
				>
					<ListImage :image="item" />
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>

<style scoped></style>
