<script lang="ts" setup>
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

defineProps<{ panel: EditPanels }>();

const emit = defineEmits<{ (e: 'moreTools'): void }>();

const onClickMoreTools = () => {
	emit('moreTools');
};
</script>

<template>
	<div data-testid="open-more-tools">
		<button
			tooltip="More tools"
			tooltip-position="top"
			class="hidden h-8 w-8 items-center justify-center text-gray-100 hover:text-white lg:flex"
			@click="onClickMoreTools"
		>
			<SvgIcon name="more" :toolbar="true" class="h-4 w-4" :class="store.editPanel === panel ? 'text-white' : ''" />
		</button>
	</div>
</template>
