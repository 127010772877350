import Bugsnag from '@bugsnag/js';
import { until, useBase64 } from '@vueuse/core';
import sysend from 'sysend';
import { computed, nextTick, ref, watch } from 'vue';

import { checkProvider } from '@/api/UserApiClient';
import Image from '@/Classes/Image';
import { Shape } from '@/Classes/Shape';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
import { useAuth } from '@/composables/useAuth';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useToast } from '@/composables/useToast';
import { useCustomImagesActions } from '@/composables/useUploadImagesActions';
import { useMainStore } from '@/stores/store';
import { ImageApi } from '@/Types/apiClient';
import ImageTools from '@/utils/ImageTools';

const userUploads = ref<ImageApi[]>([]);

export const useUserImageProvider = (checkOnInit = true) => {
	const { isLogged, requireAuth, user } = useAuth();
	const toast = useToast();
	const oauthWindow = ref();
	const files = ref();
	const fileToBase64 = ref();
	const toastId = ref();
	const currentUpload = ref();
	const temporalRef = ref();
	const pastedFromClipboard = ref(false);
	const { base64 } = useBase64(fileToBase64);
	const oldBase64 = ref(base64);

	const { uploadImage } = useCustomImagesActions();
	const { uploadImageToServer, onFetchResponse, onFetchError, data: imageApi, statusCode } = uploadImage();

	const store = useMainStore();

	const { addElement } = useActivePage();
	const usingElementOrchestrator = useElementOrchestrator(temporalRef);
	const { runOnMobile } = useDeviceInfo();

	onFetchResponse(() => {
		userUploads.value.unshift(imageApi.value);

		// Comprobamos si la imagen se ha añadido desde clipboard y la insertamos en la página activa
		if (pastedFromClipboard.value) {
			insertImage(imageApi.value);
		}
		// toast.success('Image uploaded sucessfully');
	});

	onFetchError(() => {
		if (statusCode.value === 429) {
			toast.error('Daily upload limit exceeded');
		} else {
			toast.error(
				currentUpload.value.name.length
					? `Something went wrong with the upload called: ${currentUpload.value.name}`
					: `Something went wrong with the upload`
			);
		}
	});

	// Vigilamos los cambios en files para lanzar las subidas de los archivos
	watch(
		files,
		async (newFiles) => {
			toastId.value = toast.success('Starting upload...', {
				timeout: false,
				toastClassName: 'custom-spinner-toast bg-darkblue-900',
			});
			let uploadIndex = 0;

			for (const file of newFiles) {
				let compressedFile = file.file;
				if (file.type !== 'svg' && file.provider === 'local') {
					compressedFile = await ImageTools.compressImage(file.file);
				}
				currentUpload.value = file;

				await uploadImageToServer(file.type, file.provider, compressedFile);

				// Update it later
				toast.update(toastId.value, { content: `Uploading ${uploadIndex + 1} of ${newFiles.length}...` });

				await nextTick();

				uploadIndex++;
			}

			currentUpload.value = undefined;
			toast.dismiss(toastId.value);

			//Reseteamos el input para permitir subir las mismas imagenes cuando ya se hayan subido todas
			const photoSelectorInput = document.querySelector<HTMLInputElement>('#upload-img');

			if (photoSelectorInput) {
				photoSelectorInput.value = '';
			}

			// Comprobamos si la imagen se ha añadido desde clipboard y volvemos a ponerlo a false cuando termina
			if (pastedFromClipboard.value) {
				pastedFromClipboard.value = false;
			}
		},
		{ deep: true }
	);

	const checkIsLogged = async () => {
		if (isLogged.value) return;
		requireAuth();
		await until(isLogged).toBeTruthy();
	};
	// LOCAL ---------------------------------------------------------------------

	const selectFromLocal = async () => {
		await checkIsLogged();

		document.querySelector<HTMLElement>('#upload-img')?.click();
	};

	const emptyUserUploads = () => {
		userUploads.value = [];
	};

	const uploadFromLocal = async (e: any) => {
		await checkIsLogged();

		let selectedFiles = e.target?.files || e.dataTransfer?.files;
		const fromDragging = e.dataTransfer?.files;
		if (fromDragging && selectedFiles.length) {
			selectedFiles = [fromDragging[0]];
		}

		if (!selectedFiles) return;

		convertToBase64AndAddToFiles(selectedFiles);
		Bugsnag.leaveBreadcrumb(
			`User upload ${selectedFiles.length} local file: ${Array.from(selectedFiles).map((file) => file.type)}`
		);
	};

	/**
	 * Subida desde el clipboard
	 * */
	const uploadFromClipboard = async (pastedFiles: File[]) => {
		await checkIsLogged();
		const selectedFiles = pastedFiles;

		if (!selectedFiles) return;

		convertToBase64AndAddToFiles(selectedFiles, true);
	};

	const convertToBase64AndAddToFiles = async (selectedFiles: File[], pastedFiles?: boolean) => {
		const bases64 = [];

		for (const file of selectedFiles) {
			if (!['image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg'].includes(file.type)) {
				toast.error('Invalid file');
				return;
			}

			fileToBase64.value = file;

			await until(base64).not.toBe(oldBase64.value);

			if (file.type === 'image/svg+xml') {
				bases64.push({
					type: 'svg',
					provider: 'local',
					file: base64.value,
					name: file.name,
				});
				continue;
			}

			bases64.push({
				type: 'image',
				provider: 'local',
				file: base64.value,
				name: file.name,
			});

			oldBase64.value = base64.value;
		}

		if (bases64.length && pastedFiles) {
			pastedFromClipboard.value = true;
		}

		files.value = bases64;

		oldBase64.value = '';

		await nextTick();
	};

	/**
	 * Insertamos una imagen
	 * @param img
	 */
	const insertImage = async (img: ImageApi) => {
		// Si el elemento es del tipo svg lo añadimos como shape
		let element;
		if (img.type === 'svg') {
			element = await Shape.fromApiImage(img);
		} else {
			element = await Image.fromApiImage(img);
		}

		temporalRef.value = element;
		addElement(element);
		usingElementOrchestrator.value.setupInPage();

		await store.setSelection(element, false);

		runOnMobile(() => (store.activePanel = null));
	};

	const getClipboardFilesAndUploadImages = async (e: ClipboardEvent) => {
		if (!e.clipboardData || e.clipboardData.items.length === 0) {
			return;
		}

		const dataList = Array.from(e.clipboardData?.items).filter(
			(i) => i.type === 'image/png' || i.type === 'image/jpeg' || i.type === 'image/jpg' || i.type === 'image/svg+xml'
		);

		if (!dataList.length) {
			toast.error('Invalid file');
			return;
		}

		const files: File[] = [];

		await dataList.forEach(async (image) => {
			const file = await image.getAsFile();

			if (file) {
				files.push(file);
			}
		});

		if (files.length) {
			uploadFromClipboard(files);
		}
	};

	// DROPBOX ---------------------------------------------------------------------
	const authenticatedInDropbox = computed(() => user.value?.tokens.dropbox.status);
	const {
		data: checkDropbox,
		execute: executeCheckDropbox,
		onFetchResponse: onFetchDropboxResponse,
	} = checkProvider('dropbox');

	onFetchDropboxResponse(() => {
		user.value!.tokens.dropbox = checkDropbox.value;
	});

	const registerSysendDropbox = () => {
		sysend.on(`oauth:dropbox`, async () => {
			oauthWindow.value?.close();
			await executeCheckDropbox();

			if (authenticatedInDropbox.value) {
				sysend.off(`oauth:dropbox`);
				toast.success('Dropbox account linked succesfully');
			}
		});
	};

	const loadDropbox = () => {
		if (document.querySelector('#dropboxjs')) {
			return Promise.resolve();
		}

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
		script.id = 'dropboxjs';
		script.dataset.appKey = 'v8yifh0jz4bs5iu';

		return new Promise((resolve) => {
			document.head.appendChild(script);
			script.addEventListener('load', resolve);
		});
	};

	const selectFromDropbox = async () => {
		await checkIsLogged();

		// Cargamos la librería si no está
		await loadDropbox();

		// Si no estamos autenticados en dropbox hacemos check para comprobar si tenemos el token
		if (!authenticatedInDropbox.value) {
			await executeCheckDropbox();
		}

		// Si no tenemos token lo pedimos
		if (!authenticatedInDropbox.value) {
			oauthWindow.value = window.open(
				`${import.meta.env.VITE_APP_BASE}auth/provider?provider=dropbox`,
				`Dropbox Auth`,
				'height=800,width=700'
			);

			return;
		}

		// Si tenemos token dejamos elegir fotos
		window.Dropbox.choose({
			// Required. Called when a user selects an item in the Chooser.
			success: (filesSelected: any) => {
				files.value = filesSelected.map((file: any) => ({
					type: 'image',
					provider: 'dropbox',
					file: file.id,
				}));
			},
			cancel: () => {
				return false;
			},
			linkType: 'preview', // or "direct"
			multiselect: true, // or true
			extensions: ['images'],
			folderselect: false,
		});
	};

	// GOOGLE DRIVE ---------------------------------------------------------------------
	const authenticatedInGoogle = computed(() => user.value?.tokens.google.status);
	const googleToken = computed(() => checkGoogle.value?.token);
	const { data: checkGoogle, execute: executeCheckGoogle, onFetchResponse: onFetchGoogle } = checkProvider('google');

	onFetchGoogle(() => {
		user.value!.tokens.google = checkGoogle.value;
	});
	const loadDrive = () => {
		if (document.querySelector('#googlejs')) {
			return Promise.resolve();
		}

		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/api.js?onload=loadPicker';
		script.id = 'googlejs';

		return new Promise((success) => {
			document.head.appendChild(script);
			window.loadPicker = function () {
				window.gapi.load('picker', { callback: success });
			};
		});
	};

	const selectFromDrive = async () => {
		await checkIsLogged();

		await loadDrive();

		if (!authenticatedInGoogle.value) {
			await executeCheckGoogle();
		}

		// Si no tenemos token lo pedimos
		if (!authenticatedInGoogle.value) {
			oauthWindow.value = window.open(
				`${import.meta.env.VITE_APP_BASE}auth/provider?provider=google`,
				`Google Auth`,
				'height=800,width=700'
			);

			return;
		}

		const { google } = window;

		const images = new google.picker.View(google.picker.ViewId.DOCS_IMAGES);
		images.setMimeTypes('image/png,image/jpeg,image/jpg');

		const picker = new google.picker.PickerBuilder()
			.enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
			.setAppId(import.meta.env.VITE_APP_GOOGLE_APP_ID)
			.setOAuthToken(googleToken.value)
			.setMaxItems(3)
			.addView(images)
			.setDeveloperKey(import.meta.env.VITE_APP_GOOGLE_API_KEY)
			.setCallback((event: any) => {
				if (event.action === 'picked') {
					files.value = event.docs.map((file: any) => ({
						type: 'image',
						provider: 'google',
						file: file.id,
					}));
				}
			})
			.build();

		picker.setVisible(true);
	};

	const registerSysendGoogle = () => {
		sysend.on(`oauth:google`, async () => {
			oauthWindow.value?.close();
			await executeCheckGoogle();

			if (authenticatedInGoogle.value) {
				sysend.off(`oauth:google`);
				toast.success('Google account linked succesfully');
			}
		});
	};

	const selectFromPhotos = async () => {
		await checkIsLogged();

		if (!authenticatedInGoogle.value) {
			await executeCheckGoogle();
		}

		// Si no tenemos token lo pedimos
		if (!authenticatedInGoogle.value) {
			oauthWindow.value = window.open(
				`${import.meta.env.VITE_APP_BASE}auth/provider?provider=google`,
				`Google Auth`,
				'height=800,width=700'
			);

			return false;
		}

		return true;
	};

	const uploadFromPhotos = async (img: ImageApi) => {
		files.value = [
			{
				type: 'image',
				provider: 'google-photos',
				file: img.id,
			},
		];
	};

	if (checkOnInit) {
		registerSysendDropbox();
		registerSysendGoogle();
	}

	return {
		uploadFromLocal,
		selectFromLocal,
		selectFromDropbox,
		selectFromDrive,
		selectFromPhotos,
		uploadFromPhotos,
		getClipboardFilesAndUploadImages,
		files,
		authenticatedInDropbox,
		authenticatedInGoogle,
		onFetchResponse,
		onFetchError,
		actualUpload: imageApi,
		userUploads,
		emptyUserUploads,
	};
};
