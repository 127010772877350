import { useToast as usingToast } from 'vue-toastification';

export const useToast = () => {
	if (import.meta.env.MODE === 'test') {
		return {
			error: () => true,
			success: () => true,
			warning: () => true,
		};
	}

	return usingToast();
};
