import { MaybeRef, useEventListener, useTimeoutFn } from '@vueuse/core';
import { nextTick, Ref, ref } from 'vue';

import { getSvg } from '@/api/DataApiClient';
import Image from '@/Classes/Image';
import Mask from '@/Classes/Mask';
import Page from '@/Classes/Page';
import { Shape } from '@/Classes/Shape';
import Storyset from '@/Classes/Storyset';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { usePage } from '@/composables/page/usePage';
import { useProject } from '@/composables/project/useProject';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useUserImageProvider } from '@/composables/useUserImageProvider';
import { useMainStore } from '@/stores/store';
import {
	BasicShapeApi,
	FlaticonElementApi,
	ImageApi,
	MaskApi,
	StickersElementApi,
	StorysetApi,
} from '@/Types/apiClient';
import { ElementsPanelTypes, ImageHover, Panels, Position } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';

const { isFirefox } = useDeviceInfo();

/**
 * Maneja los eventos de drop de archivos para insertar elementos
 * @param page
 * @param container
 */
export const useHandleDraggables = (page: Ref<Page>, container: MaybeRef<HTMLElement>) => {
	const store = useMainStore();

	const hoveredImage = ref<ImageHover | null>(null);
	const draggingFile = ref<boolean>(false);
	const temporalRef = ref(Image.createDefault());
	const positionMouseOnDrop = ref<Position>({ x: 0, y: 0 });

	const usingElementOrchestrator = useElementOrchestrator(temporalRef);
	const { addElement, getElementFromDom } = usePage(page);
	const { getPageElementUnderMouse } = useProject();
	const { uploadFromLocal, onFetchResponse, actualUpload } = useUserImageProvider(false);

	onFetchResponse(async () => {
		store.draggingItem = {
			data: actualUpload.value,
			type: Panels.photos,
		};
		await nextTick();
		insertCommon();
	});

	// Inserta o reemplaza el elemento que se esta arrastrando
	const insertCommon = async (
		data?: BasicShapeApi | FlaticonElementApi | ImageApi | MaskApi | StickersElementApi | StorysetApi,
		type?: ElementsPanelTypes | Panels
	) => {
		if (!store.draggingItem) return;

		const insertableData = data || store.draggingItem.data;
		const insertableType = type || store.draggingItem.type;

		// TODO: fix type
		let inserted: any;

		if (
			insertableType === ElementsPanelTypes.Flaticon ||
			insertableType === ElementsPanelTypes.Stickers ||
			insertableType === ElementsPanelTypes.BasicShapes
		) {
			inserted = await insertShape(insertableData as FlaticonElementApi | StickersElementApi | BasicShapeApi);
		}

		if (insertableType === ElementsPanelTypes.Storysets) {
			inserted = await insertStoryset(insertableData as StorysetApi);
		}

		if (insertableType === ElementsPanelTypes.ImageMasks) {
			inserted = await insertImageMask(insertableData as MaskApi);
		}

		if (insertableType === Panels.photos) {
			inserted = await insertImage(insertableData as ImageApi);
		}

		if (!inserted) return;

		temporalRef.value = inserted;

		const pageUnderCursor = getPageElementUnderMouse(positionMouseOnDrop.value);
		if (!pageUnderCursor) throw new Error('No page to drop in!');

		const pageUnderCursorEl = pageUnderCursor.domNode();
		if (!pageUnderCursorEl) throw new Error('No page HTML element to drop in!');

		const { left, top } = pageUnderCursorEl.getBoundingClientRect();

		const position = {
			x: positionMouseOnDrop.value.x - left + window.pageXOffset,
			y: positionMouseOnDrop.value.y - top + window.pageYOffset,
		};

		addElement(inserted);
		usingElementOrchestrator.value.setupInPage({
			x: position.x / store.scale,
			y: position.y / store.scale,
		});
		store.setSelection(inserted, false);
		store.draggingItem = null;

		positionMouseOnDrop.value = { x: 0, y: 0 };
	};

	const setHoveredImage = (e: DragEvent) => {
		if (!store.draggingItem) return;

		const target = getCurrentTarget(e);
		const node = target?.closest('.editable, .target') as HTMLElement;
		if (node) {
			const element = getElementFromDom(node);
			if (
				element instanceof Image &&
				store.draggingItem.type === Panels.photos &&
				(!hoveredImage.value || hoveredImage.value.target.id !== element.id)
			) {
				hoveredImage.value = { target: element, data: store.draggingItem.data as any };
			}
		}
	};

	// para que el drop funcione hay que cancelar el enter y el over
	useEventListener(container, 'dragenter', (e: DragEvent) => {
		e.preventDefault();

		if (e.dataTransfer) {
			e.dataTransfer.dropEffect = 'copy';
		}

		// Si estabamos arrastrando un DraggableItem
		if (store.draggingItem) {
			setHoveredImage(e);
			return;
		}

		// Si no hay draggingItem, estamos arrastrando un archivo desde el ordenador
		draggingFile.value = true;

		// hay casos donde no podemoo escuchar el evento de que ya no estamos arrastrando el archivo,
		// asi que lo cancelamos pasados un par de segundos
		useTimeoutFn(() => (draggingFile.value = false), 2000);
	});

	// si salimos definitivamente del canvas con el dragleave (se lanza tambien al hacer leave entre elementos)
	useEventListener(container, 'dragleave', (e: DragEvent) => {
		const target = getCurrentTarget(e);
		if (!target) return;

		// Restauramos la imagen original si salimos del elemento Image
		const node = target.closest('.editable, .target') as HTMLElement;
		if (node) {
			const element = getElementFromDom(node);
			if (
				element instanceof Image &&
				store.draggingItem?.type === Panels.photos &&
				hoveredImage.value &&
				node.id.includes(hoveredImage.value.target.id)
			) {
				hoveredImage.value = null;
			}
		}

		if (target.classList.contains('interactive-canvas') && hoveredImage.value) {
			hoveredImage.value = null;
		}

		if (target.closest('.interactive-canvas')) {
			return;
		}

		draggingFile.value = false;
	});

	// En cada evento del over, seteamos el dropeffect para que salga el simbolo
	// sobre el raton
	useEventListener(container, 'dragover', (e: DragEvent) => {
		e.preventDefault();

		if (e.dataTransfer) {
			e.dataTransfer.dropEffect = 'copy';
		}

		if (!hoveredImage.value) {
			setHoveredImage(e);
		}
	});

	// Al soltar lo que sea, tratamos de insertarlo
	useEventListener(container, 'drop', async (e: DragEvent) => {
		e.preventDefault();

		const target = getCurrentTarget(e);
		if (!target || target.classList.contains('interactive-canvas')) return;

		positionMouseOnDrop.value = { x: e.clientX, y: e.clientY };

		if (store.draggingItem) {
			insertCommon();
		} else {
			uploadFromLocal(e);
		}

		GAnalytics.track('add-image', 'Button', 'drag-and-drop', null);

		hoveredImage.value = null;
		draggingFile.value = false;
	});

	// By type
	const insertImage = async (fromApi: ImageApi) => {
		// si estamos soltando una imagen y esta sobre otra, la reemplazamos
		if (hoveredImage.value && fromApi.type !== 'svg') {
			temporalRef.value = hoveredImage.value.target;
			(usingElementOrchestrator.value as any).replace(fromApi);
			return null;
		}

		const img = fromApi.type === 'svg' ? await Shape.fromApiImage(fromApi) : await Image.fromApiImage(fromApi);

		return img;
	};

	const insertImageMask = async (fromApi: MaskApi) => {
		const image = await Image.fromUrl('https://wepik.com/svg/mask-placeholder.svg');
		const mask = await Mask.fromApi(fromApi);
		image.setMask(mask);
		return image;
	};

	const insertStoryset = async (fromApi: StorysetApi) => {
		const { data } = await getSvg(fromApi.src);
		if (!data.value) return null;
		const storyset = Storyset.fromSvg(data.value);
		return storyset;
	};

	const insertShape = async (fromApi: FlaticonElementApi | StickersElementApi | BasicShapeApi) => {
		const { data } = await getSvg(fromApi.svg);

		if (!data.value) return null;

		const shape = Shape.fromSvg(data.value);

		return shape;
	};

	return {
		draggingFile,
		hoveredImage,
	};
};

const getCurrentTarget = (e: DragEvent): HTMLElement | null => {
	// @ts-ignore
	if (isFirefox) {
		// @ts-ignore
		return e.target;
		// @ts-ignore
	} else if (e.toElement) {
		// @ts-ignore
		return e.toElement;
	} else if (e.currentTarget) {
		// @ts-ignore
		return e.currentTarget;
	} else if (e.srcElement) {
		// @ts-ignore
		return e.srcElement;
	} else {
		return null;
	}
};
