<script setup lang="ts">
// Vue & Packages
import { createPopper } from '@popperjs/core';
import { useDebounceFn } from '@vueuse/shared';
import { computed, ComputedRef, nextTick, onBeforeUnmount, ref, watch } from 'vue';

// Classes
import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
// Components
import GradientPicker from '@/components/Common/Color/GradientPicker.vue';
import SolidColorPicker from '@/components/Common/Color/SolidColorPicker.vue';
import OnClickOutsideWithPortals from '@/components/Common/OnClickOutsideWithPortals';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useBugsnag } from '@/composables/useBugsnag';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
import GAnalytics from '@/utils/GAnalytics';
// type ValidColorProps = SolidColor | GradientColor | Array<SolidColor | GradientColor>

// Props
const props = defineProps<{
	color: any;
	hideAlpha?: boolean;
	hideGradient?: boolean;
	parent?: string;
}>();

// Emits
const emit = defineEmits<{
	(e: 'select', color: SolidColor | GradientColor | null): void;
	(e: 'change', color: SolidColor | GradientColor): void;
	(e: 'track');
}>();

// Template refs
const button = ref();
const picker = ref();

// Constants
const rgbaGridTemplateColumns = `repeat(${props.hideAlpha ? '5' : '6'}, minmax(0, 1fr))`;
const rgbaAlphaChannelDisplay = props.hideAlpha ? 'none' : 'initial';
const { isMobile } = useDeviceInfo();
const { breadScrumbWithDebounce } = useBugsnag();

// Data
const isOpen = ref(false);
const currentColor = ref();

// Computeds
const colors = computed(() => (Array.isArray(props.color) ? props.color : [props.color])) as ComputedRef<SolidColor[]>;

const colorPreview = computed(() => {
	if (colors.value.length === 1) {
		return colors.value[0].toCssString();
	}

	const steps = parseFloat((100 / colors.value.length).toFixed(0));
	let gradient = `${colors.value[0]} ${steps}%`;

	for (let i = 1; i < colors.value.length; i++) {
		const start = steps * i;
		const end = steps * (i + 1);
		const color = colors.value[i];
		gradient += `,${color} ${start}%, ${color} ${end}%`;
	}

	return `conic-gradient(${gradient})`;
});

const finalColor = computed(() => (Array.isArray(props.color) ? props.color[0] : props.color));

const isGradient = computed(() => props.color instanceof GradientColor);

// Using composables
const { listen } = useOrderedKeyboardListener();
listen('Escape', () => {
	if (!isOpen.value) return true;
	isOpen.value = false;
});

// Methods
const toggleColorPicker = () => {
	isOpen.value = !isOpen.value;

	if (isOpen.value && !isMobile.value) {
		nextTick(() => {
			const reference = props.parent ? button.value.closest(props.parent) : button.value;

			createPopper(reference, picker.value, {
				placement: 'right-start',
			});
		});
	}

	if (isOpen.value) emit('select', props.color);
	if (!isOpen.value) emit('select', null);
};

watch(isOpen, () => checkForColorPickers());

const checkForColorPickers = async () => {
	await nextTick();
	requestAnimationFrame(async () => {
		document.documentElement.classList.toggle('color-picking', !!document.querySelector('[data-color-picker]'));
	});
};

onBeforeUnmount(async () => {
	checkForColorPickers();
});

const closePicker = () => {
	if (!isOpen.value) {
		return;
	}
	isOpen.value = false;
	emit('select', null);
};

const changeColor = (color: SolidColor | GradientColor) => {
	currentColor.value = color;
	breadScrumbWithDebounce(color);
	emit('track');
	emit('change', color);
};

watch(
	currentColor,
	useDebounceFn(() => {
		GAnalytics.track('click', 'Button', 'colorpicker', null);
	}, 100)
);

const gradientToSolid = (gradient: GradientColor) => {
	if (!isGradient.value) return;
	emit('change', gradient.convertToSolidColor());
};

const solidToGradient = (color: SolidColor) => {
	if (isGradient.value) return;
	emit('change', color.convertToGradient());
};
</script>

<template>
	<OnClickOutsideWithPortals @trigger="closePicker">
		<div class="color-picker relative h-full w-full">
			<div class="bg-transparent-pattern absolute inset-0 rounded-full" style="clip-path: circle(49% at 50% 50%)"></div>
			<button
				ref="button"
				data-testid="open-colorpicker"
				class="absolute left-0 top-0 h-full w-full rounded-full"
				:style="{
					background: colorPreview,
				}"
				@click="toggleColorPicker"
			></button>

			<div
				v-if="isOpen && !isMobile"
				ref="picker"
				data-color-picker
				class="z-30 w-full rounded-tl-lg rounded-tr-lg bg-gray-800/95 p-2 text-left backdrop-blur lg:w-48 lg:rounded-md lg:bg-opacity-100 lg:shadow-custom"
			>
				<div v-if="!hideGradient" class="mb-2 flex">
					<button
						class="h-6 flex-1 rounded text-xs font-bold uppercase"
						:class="!isGradient ? 'bg-gray-600 text-white' : 'text-gray-100 hover:text-white'"
						@click="gradientToSolid(finalColor)"
					>
						Solid
					</button>

					<button
						class="h-6 flex-1 rounded text-xs font-bold uppercase"
						:class="isGradient ? 'bg-gray-600 text-white' : 'text-gray-100 hover:text-white'"
						@click="solidToGradient(finalColor)"
					>
						Gradient
					</button>
				</div>

				<div ref="el">
					<GradientPicker v-if="isGradient" :gradient="finalColor" :hide-alpha="hideAlpha" @change="changeColor" />

					<SolidColorPicker
						v-else
						:color="finalColor"
						:hide-gradient="hideGradient"
						:hide-alpha="hideAlpha"
						@change="changeColor"
					/>
				</div>
			</div>
		</div>

		<teleport to="#toolbar-bottom-portal, #navigation-portal">
			<div
				v-if="isOpen && isMobile"
				data-testid="colorpicker-mobile"
				class="color-picker absolute bottom-0 z-50 w-full bg-gray-700 p-3"
			>
				<button
					class="mb-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
					@click="isOpen = false"
				>
					<SvgIcon name="cross" class="h-3 w-3" />
				</button>
				<div v-if="!hideGradient" class="mb-2 flex">
					<button
						class="h-6 flex-1 rounded-full text-xs font-bold uppercase lg:rounded"
						:class="!isGradient ? 'bg-gray-600 text-white' : 'text-gray-100'"
						@click="gradientToSolid(finalColor)"
					>
						Solid
					</button>

					<button
						class="h-6 flex-1 rounded-full text-xs font-bold uppercase lg:rounded"
						:class="isGradient ? 'bg-gray-600 text-white' : 'text-gray-100'"
						@click="solidToGradient(finalColor)"
					>
						Gradient
					</button>
				</div>

				<GradientPicker v-if="isGradient" :gradient="finalColor" :hide-alpha="hideAlpha" @change="changeColor" />

				<SolidColorPicker v-else :color="finalColor" :hide-alpha="hideAlpha" @change="changeColor" />
			</div>
		</teleport>
	</OnClickOutsideWithPortals>
</template>

<style lang="scss">
.vc-sketch .vc-sketch-field {
	grid-template-columns: v-bind(rgbaGridTemplateColumns);

	.vc-sketch-field--single:last-of-type {
		display: v-bind(rgbaAlphaChannelDisplay);
	}
}
</style>
