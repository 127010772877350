<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { until } from '@vueuse/core';
import sysend from 'sysend';
import { computed, onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';

import Page from '@/Classes/Page';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useArtboard } from '@/composables/project/useArtboard';
import { useAuth } from '@/composables/useAuth';
import useDownloadsProject from '@/composables/useDownloadsProject';
import { useShare } from '@/composables/useShare';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import MathTools from '@/utils/MathTools';

const props = defineProps<{ pages: Page[] }>();
const emits = defineEmits(['close', 'goBack']);

const loading = ref(false);
const errors = ref<string[]>([]);
const selectedTemplatePage = ref<any>([]);
const message = ref('');
const oauthWindow = ref<Window | null>(null);
const authenticated = ref(false);

const { share } = useDownloadsProject();
const store = useMainStore();
const project = useProjectStore();
const toast = useToast();
const { artboardSizeInPx } = useArtboard();
const { requireAuth, isLogged, user } = useAuth();
const { checkTwitter, executeCheckTwitter, authenticatedInTwitter, logoutTwitter } = useShare();

const dropdownTemplatePages = computed(() => {
	return props.pages.map((page: Page) => {
		const index = project.pages.findIndex((p) => p.id === page.id);

		return {
			label: `Page ${index + 1}`,
			value: project.pages[index].id,
		};
	});
});

const closeModal = () => {
	if (store.sharing) return;
	emits('close');
};

const goBack = () => {
	if (store.sharing) return;
	emits('goBack');
};

const checkAuthentication = async () => {
	if (!isLogged.value) {
		return;
	}

	if (authenticatedInTwitter.value) {
		authenticated.value = true;
	}

	if (!authenticated.value) {
		await executeCheckTwitter();

		if (checkTwitter.value.status && user.value) {
			authenticated.value = true;
			user.value.tokens.twitter = {
				status: true,
				token: checkTwitter.value.token,
			};
		}
	}

	if (authenticated.value) {
		selectedTemplatePage.value = dropdownTemplatePages.value.slice(0, 4).map((el) => el.value);
	}

	loading.value = false;
};

const initTwitter = async () => {
	if (store.sharing || loading.value) return;

	if (!isLogged.value) {
		requireAuth();
		await until(isLogged).toBeTruthy();
	}

	loading.value = true;

	sysend.on('oauth:twitter', async () => {
		oauthWindow.value?.close();
		await checkAuthentication();

		if (authenticated.value) {
			sysend.off('oauth:twitter');

			toast.success('Twitter account linked successfully!');
		}
	});

	oauthWindow.value = window.open(
		`${import.meta.env.VITE_APP_BASE}auth/provider?provider=twitter`,
		`Login in Twitter`,
		'height=800,width=700'
	);
};

const shareInTwitter = async () => {
	if (store.sharing) return;

	errors.value = [];

	if (!selectedTemplatePage.value.length) {
		errors.value.push('Please select at least one page');
	}

	if (errors.value.length) {
		return;
	}

	const params = {
		twitter_message: message.value,
	};

	try {
		await share('twitter', params, selectedTemplatePage.value);

		toast.success('Your design has been published on Twitter');
		closeModal();
	} catch (error) {
		toast.error('Your design has not been uploaded on Twitter');
	}
};

const logout = async () => {
	await logoutTwitter();
	authenticated.value = false;
};

onMounted(async () => {
	await checkAuthentication();
});
</script>
<template>
	<Modal open content-classes="w-full max-w-lg" @close="closeModal">
		<div class="relative max-h-full w-full overflow-auto rounded-lg bg-white py-4 px-4 lg:px-8 lg:pt-8 lg:pb-8">
			<button class="absolute top-2 left-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25" @click="goBack">
				<SvgIcon name="right-small" class="h-5 w-5 rotate-180 text-gray-700" />
			</button>
			<button
				class="absolute top-2 right-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
				@click="closeModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
			</button>
			<div v-if="!authenticated" class="flex flex-col items-center">
				<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800">Publish directly on Twitter!</h3>
				<p class="px-6 text-center text-gray-600 lg:px-12">
					Connect your social media to share content in the blink of an eye!
				</p>
				<div class="flex items-center justify-center gap-6 py-10">
					<SvgIcon name="iso" class="h-16 w-16 text-blue-500" />
					<SvgIcon name="left-right" class="h-10 w-10 text-gray-100" />
					<div class="flex h-16 w-16 items-center justify-center rounded-full bg-[#03A9F4]">
						<SvgIcon name="twitter" class="h-8 w-8 text-white" />
					</div>
				</div>
				<button class="rounded bg-blue-500 py-3 px-6 font-semibold text-white hover:bg-blue-600" @click="initTwitter">
					<span v-if="!loading">Connect with Twitter</span>
					<SvgIcon v-if="loading" name="spinner" class="mx-auto h-7 w-7 animate-spin" />
				</button>
			</div>
			<div v-if="authenticated" class="flex flex-col">
				<h3 class="mb-4 flex items-center justify-center text-xl font-semibold text-gray-800">
					<span class="mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-[#03A9F4]">
						<SvgIcon name="twitter" class="h-4 w-4 text-white" />
					</span>
					Twitter
				</h3>
				<div class="mb-4 flex flex-col gap-4 lg:flex-row">
					<div class="w-full">
						<label class="mb-2 block font-semibold text-gray-600">Page selected</label>
						<Multiselect
							v-model="selectedTemplatePage"
							mode="multiple"
							placeholder="Template pages"
							:classes="{
								container: 'multiselect text-gray-700',
								containerActive: 'ring ring-blue-500 ring-opacity-30',
								tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
								groupLabelSelected: 'bg-blue-600 text-white',
								groupLabelSelectedPointed: 'bg-blue-600 text-white opacity-90',
								groupLabelSelectedDisabled: 'text-blue-100 bg-blue-600 bg-opacity-50 cursor-not-allowed',
								optionSelected: 'text-blue-500 font-semibold opacity-100 bg-gray-100/50',
								optionSelectedPointed: 'text-blue-500 font-semibold opacity-100',
								optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
							}"
							:hide-selected="false"
							:can-clear="false"
							:close-on-select="false"
							:max="10"
							:options="dropdownTemplatePages"
						>
							<template #option="{ option }">
								<div class="flex w-full items-center justify-between text-sm">
									<div>{{ option.label }}</div>
									<div class="flex h-6 w-12 items-center">
										<Canvas
											preview
											:page="project.pages.find((p) => p.id === option.value)"
											:use-parent-size="{
												height: artboardSizeInPx.height,
												width: artboardSizeInPx.width,
												scale: MathTools.ruleOfThree(artboardSizeInPx.height, 1, 30),
											}"
										/>
									</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<label class="mb-1 block font-semibold text-gray-600">
					Write a message
					<span class="text-sm text-gray-300">(optional)</span>
				</label>
				<div class="relative mb-4">
					<textarea
						v-model="message"
						maxlength="280"
						class="texts-gray-700 h-52 w-full resize-none rounded border border-gray-200 px-4 pt-4 pb-6"
					></textarea>
					<span v-if="message.length" class="absolute bottom-2 right-1 bg-white px-2 py-1 text-xs text-gray-100"
						>({{ message.length }}/280)
					</span>
				</div>

				<div v-for="error in errors" class="mb-4">
					<p class="text-sm font-semibold text-red-500">- {{ error }}</p>
				</div>

				<button
					class="rounded py-3 px-6 font-semibold text-white"
					:class="{
						'bg-blue-500 hover:bg-blue-600': !store.sharing,
						'pointer-events-none bg-blue-600': store.sharing,
					}"
					@click="shareInTwitter"
				>
					<span v-if="!store.sharing">Publish</span>
					<span v-else class="flex items-center justify-center">
						<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin" />
						Publishing
					</span>
				</button>
			</div>
		</div>
	</Modal>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
