import { ClipPath, Dom, Element as SvgElement, SVG, Svg } from '@svgdotjs/svg.js';
import { useUrlSearchParams } from '@vueuse/core';
import Normalize from 'color-normalize';
import { v4 as uuidv4 } from 'uuid';
import { ref } from 'vue';

// Api
import { getProject, getSvg } from '@/api/DataApiClient';
// Classes
import Element from '@/Classes/Element';
import ErrorPhotoModeUrl from '@/Classes/ErrorPhotoModeUrl';
import { Filter } from '@/Classes/Filter';
import { GradientColor } from '@/Classes/GradientColor';
import { Illustrator } from '@/Classes/Illustrator';
import Image from '@/Classes/Image';
import Line from '@/Classes/Line';
import Mask from '@/Classes/Mask';
import Page from '@/Classes/Page';
import Project from '@/Classes/Project';
import { QRCode } from '@/Classes/QRCode';
import { Shape } from '@/Classes/Shape';
import { SolidColor } from '@/Classes/SolidColor';
import Storyset from '@/Classes/Storyset';
import { Text } from '@/Classes/Text';
import { useIllustrator } from '@/composables/element/illustrator/useIllustrator';
// Composables
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { usePage } from '@/composables/page/usePage';
import { useArtboard } from '@/composables/project/useArtboard';
import { useEditorMode } from '@/composables/useEditorMode';
import { useFonts } from '@/composables/useFonts';
import { useToast } from '@/composables/useToast';
import { useProjectStore } from '@/stores/project';
// Types
import { RenderData, TemplateLoaderData } from '@/Types/templateLoaderData';
import { FontStyle, FontWeight, TextAlign, TextTransform } from '@/Types/text';
import { Position } from '@/Types/types';
// Utils
import ElementTools from '@/utils/ElementTools';
import IllustratorTools from '@/utils/IllustratorTools';
import ImageTools from '@/utils/ImageTools';
import MathTools from '@/utils/MathTools';
import TextTools from '@/utils/TextTools';

class TemplateLoader {
	static async fromSlug(slug: string): Promise<{ templateData: TemplateLoaderData; pages: Page[] }> {
		const templateData = (await this.getTemplateData(slug, false)) as TemplateLoaderData;

		const pages = await Promise.all(
			templateData.pages.map(async (page) => {
				const { data, response } = await getSvg(page.svg_url);
				const contentType = response.value?.headers.get('content-type');
				const content = contentType === 'application/json' ? JSON.parse(data.value as string) : data.value;

				return typeof content === 'string' ? await this.parseSvg(content, templateData) : this.loadFromData(content);
			})
		);

		return {
			templateData,
			pages,
		};
	}

	static fromRender(data: RenderData): Project {
		const project = new Project(data.width, data.height, data.unit, -1);

		project.pages = data.pages.map((page) => {
			const newPage = this.loadFromData(page);

			if (data.transparentBackground) newPage.background = SolidColor.transparent();

			return newPage;
		});

		return project;
	}

	static async initPhotoMode(page: Page, templateData: TemplateLoaderData) {
		const params = useUrlSearchParams<{ mode?: string; photo?: string }>();
		let url = '';

		try {
			const result =
				params.mode === 'photo' ? Image.createDefault() : await ImageTools.getImageAsBlobUrl(params.photo as string);
			url = result.url;
		} catch (error) {
			throw new ErrorPhotoModeUrl(error);
		}
		const size = await ImageTools.getRealImageSize(url);
		const { maxArtboardSize } = useArtboard();

		if (size.width * size.height > maxArtboardSize.value) {
			templateData.artboard.width = size.width <= size.height ? 1500 : 1500 * (size.width / size.height);
			templateData.artboard.height = size.width <= size.height ? 1500 * (size.height / size.width) : 1500;
		} else {
			templateData.artboard.width = size.width;
			templateData.artboard.height = size.height;
		}

		templateData.artboard.unit = 'px';

		const { addElement } = usePage(page);
		const image = Image.create(url);
		image.setSize(size.width, size.height);
		page.background = SolidColor.transparent();

		addElement(image);
	}

	static initPredefindedTextMode(pages: Page[]) {
		const { setBackground } = usePage(pages[0]);

		setBackground(SolidColor.transparent());
	}

	static initIllustratorMode() {
		const project = useProjectStore();
		const { moveTexts } = useIllustrator(ref(project.pages[0].elements[0] as Illustrator));

		const illustratorSvg = (project.pages[0].elements[0] as Illustrator).contentSvg.addTo(document.body) as Svg;
		const viewbox = illustratorSvg.viewbox();

		illustratorSvg.height(viewbox.height);
		illustratorSvg.width(viewbox.width);
		illustratorSvg.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		// Movemos los textos
		const ids = illustratorSvg.find('text').map((text) => text.data('illustrator-link'));
		moveTexts(ids);

		// Aplicamos el mismo fondo
		const illustratorBackground = project.pages[0].background;
		project.pages[1].background = illustratorBackground;

		illustratorSvg.remove();
	}

	static parseIllustrator(svg: string, templateData: TemplateLoaderData) {
		// Necesitamos añadir el svg al DOM para poder extraer los datos de los textos svg
		const doc = SVG(svg).addTo(document.body) as Svg;
		doc.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		const viewbox = doc.viewbox();

		templateData.artboard.height = viewbox.height;
		templateData.artboard.width = viewbox.width;
		templateData.artboard.unit = 'px';

		IllustratorTools.removeUnnecessaryClipPaths(doc);
		IllustratorTools.removeGroupTransforms(doc);
		IllustratorTools.simplifyTreeOfElements(doc);
		IllustratorTools.fixTexts(doc);

		// Buscamos el primer elemento con fill
		const bgElement = doc.findOne('rect[style*="fill"], path[style*="fill"], polygon[style*="fill"]') as Dom;
		const bgFill = bgElement.css('fill').toString();
		const bg = SolidColor.fromString(bgFill);

		// Definimos el container y eliminamos el fondo ya que no es necesario
		bgElement.parent()?.id('illustrator-container');
		bgElement.remove();

		const viewboxString = doc.attr('viewBox').toString();
		const content = doc.node.innerHTML.toString();

		const illustratorElement = Illustrator.create(viewboxString, content);
		illustratorElement.setSize(viewbox.width, viewbox.height);

		const newPage = Page.create();

		const { addElement, setBackground } = usePage(newPage);

		addElement(illustratorElement);
		setBackground(bg);

		doc.remove();

		return newPage;
	}

	static async getTemplateData(slug: string, checkPreload: boolean): Promise<TemplateLoaderData> {
		const preloadData = checkPreload ? window.preloadVector : null;
		let res = preloadData;

		if (!preloadData) {
			const { data, error } = await getProject(slug);
			if (error.value) {
				throw new Error(error.value);
			}

			res = data.value;
		}

		if (res.user_vector) {
			// Si no es el propietario de la plantilla redigirimos a copy
			if (!res.is_owner && !window.location.hostname.includes('netlify') && !window.location.hostname.includes('pages.dev') && import.meta.env.PROD) {
				window.location.href = `${import.meta.env.VITE_APP_BASE}copy/${slug}`;
			}

			return {
				id: res.vector.id,
				name: res.name || '',
				freepik_id: res.vector.freepik_id,
				category_tree: res.vector.category_tree,
				slug: res.vector.slug,
				project: res.project,
				artboard: {
					id: null,
					name: 'Custom',
					height: res.size?.height || res.artboard.height,
					width: res.size?.width || res.artboard.width,
					unit: res.size?.unit || res.artboard.unit,
				},
				pages: res.media.map((media: { id: number; url: string }, i: number) => ({
					order: i,
					svg_url: media.url,
					preview: res.previews[i]?.preview,
				})),
				preview: res.preview || '',
				userVectorId: res.uuid,
				gradients: res.gradients || [],
				flaticonSearch: res.flaticon_search,
			};
		}

		return {
			id: res.id,
			freepik_id: res.freepik_id,
			category_tree: res.category_tree,
			slug: res.slug,
			name: res.name || '',
			artboard: {
				id: res.artboard?.id || null,
				name: res.artboard?.name || 'Custom',
				height:
					res.size && Object.keys(res.size).length > 0
						? res.size.height
						: res.artboard?.landscape
						? res.artboard?.width || 0
						: res.artboard?.height || 0,
				width:
					res.size && Object.keys(res.size).length > 0
						? res.size.width
						: res.artboard?.landscape
						? res.artboard?.height || 0
						: res.artboard?.width || 0,
				unit: res.size && Object.keys(res.size).length > 0 ? res.size.unit : res.artboard?.unit || 'px',
			},
			pages: res.media.map((media: { id: number; url: string }, i: number) => ({
				order: i,
				svg_url: media.url,
				preview: res.previews[i]?.preview,
			})),
			preview: res.preview || '',
			gradients: res.gradients || [],
			flaticonSearch: res.flaticon_search,
		};
	}

	static loadFromData(data: any, isUserVector = false): Page {
		const newPage = Page.unserialize(data);

		// Si no es un vector de usuario le generamos una nueva id, ya que sino nos dará problemas al sincronizar
		// ya que estaremos envíando siempre la misma id para todos los user vector
		if (!isUserVector) {
			newPage.id = uuidv4();
		}

		// Cargamos los elementos
		if (data.elements) {
			if (typeof data.elements === 'object') {
				// Por lo que sea el backend a veces lo devuelve como objeto (seguramente el json diff)
				data.elements = Object.values(data.elements);
			}

			newPage.elements = data.elements.map((element: any) => {
				const newElement = this.unserializeElement(element);

				// Nos adelantamos a la carga de los canvas y vamos pidiendo las imagenes
				// para que estén listas antes.
				if (newElement instanceof Image) {
					document.createElement('img').src = newElement.preview || newElement.url;
				}

				// Dado que no restauramos los id buscamos que foto estaba asignada de fondo
				if (data.backgroundImageId && element.id === data.backgroundImageId) {
					newPage.backgroundImageId = newElement.id;
				}

				return newElement;
			});
		}

		return newPage;
	}

	static async preloadFonts(doc: Svg) {
		const { loadFontsByName } = useFonts();

		// Buscamos las fuentes tanto de los fObj como de los textos nativos del SVG
		return await loadFontsByName([
			...new Set(
				doc.find('foreignObject [style*="font-family"], text').map((el) => {
					const fontFamilySplit = el.css('font-family').toString().split(', ');
					return fontFamilySplit[fontFamilySplit.length - 1]?.replaceAll('"', '') || 'Montserrat';
				})
			),
		]);
	}

	static getElementByType(element: SvgElement) {
		const type = ElementTools.getElementType(element);

		// Hay veces que podemos tener un texto dentro de un grupo, con esto lo evitamos
		if (type === 'g-text') {
			element = element.first();
		}

		switch (type) {
			case 'text':
			case 'g-text':
				return this.getText(element);

			case 'native-text':
				return this.getNativeText(element);

			case 'image':
				return this.getImage(element);

			case 'storyset':
				return this.getStoryset(element);

			case 'line':
			case 'native-line':
				return this.getLine(element);

			default:
				return this.getShape(element);
		}
	}

	static async parseSvg(svg: string, templateData: TemplateLoaderData): Promise<Page> {
		if (templateData.userVectorId) {
			templateData.forceSync = true;
		}

		// Necesitamos añadir el svg al DOM para poder extraer los datos de los textos svg
		const doc = SVG(svg).addTo(document.body) as Svg;
		const viewbox = doc.viewbox();

		doc.height(viewbox.height);
		doc.width(viewbox.width);
		doc.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		const hasArtboardSize = !!(templateData.artboard?.width && templateData.artboard.height);

		// Comprobamos si se respeta el landscape si no pues lo forzamos
		if (templateData && templateData.artboard.width > templateData.artboard.height !== viewbox.width > viewbox.height) {
			const tempWidth = templateData.artboard.width;

			templateData.artboard.width = templateData.artboard.height;
			templateData.artboard.height = tempWidth;
		}

		// Si tiene artboard calculamos la escala del svg
		let scaleSvg = 1;

		if (templateData && templateData.artboard) {
			const { MM_TO_PX } = useArtboard();
			const mmToPx = templateData.artboard.unit === 'mm' ? MM_TO_PX : 1;
			scaleSvg = (templateData.artboard.width * mmToPx) / viewbox.width;
		}

		// Si el artboard no tiene ni width ni height usamos el viewbox del svg
		if (!hasArtboardSize) {
			templateData.artboard.height = viewbox.height;
			templateData.artboard.width = viewbox.width;
		}

		this.fixPage(doc);
		this.setupGradients(doc, templateData);

		const background = this.getBackground(doc);
		const container = background.parent() as Dom;
		const bgIsGradient = background.css('fill').includes('url');

		let pageBackground: GradientColor | SolidColor;

		if (bgIsGradient) {
			const idGradient = ElementTools.getIdFromUrl(background.css('fill'));
			const gradient = doc.findOne(idGradient) as SvgElement;

			pageBackground = ElementTools.svgGradientToObject(gradient);
		} else {
			const [r, g, b, a] = Normalize(background.css('fill'));

			// Si el background no tiene relleno aplicamos negro
			pageBackground = background.css('fill').length
				? new SolidColor(r * 255, g * 255, b * 255, a)
				: SolidColor.black();
		}

		const docClipPath = this.getClipPathContainer(doc);

		// Desagrupamos los grupos creados y dejamos un data para crear los grupos virtuales
		this.unGroup(doc);

		await this.preloadFonts(doc);

		// Extraemos los elementos
		const elements = container
			.find(':scope > *:not([id*="background"]):not(title)')
			.filter((el) => {
				// Descartamos grupos vacíos
				const isInvalidG = el.type === 'g' && el.first() === null;
				const isClipContainerElement = el.id().includes('clip-container');
				const isEmptyText = el.type === 'text' && !el.node.textContent?.length;

				return !isInvalidG && !isClipContainerElement && !isEmptyText;
			})
			.map((el) => TemplateLoader.getElementByType(el));

		const newPage = Page.create();
		const { addElement, setBackground } = usePage(newPage);

		setBackground(pageBackground);

		const temporalRef = ref<Element>(Shape.createDefault());
		const usingElementTransform = useElementTransformOrchestrator(temporalRef);

		elements.forEach((el) => {
			temporalRef.value = el;

			// Guardamos el estado del bloqueo para restaurarlo tras ajustar los elementos
			const currentLocked = el.locked;
			el.locked = false;

			// Ajustamos la posición de los elementos respecto al clip-path container
			if (!(el instanceof Image)) {
				usingElementTransform.value.move(-docClipPath.x, -docClipPath.y);
			}

			// Aplicamos la escala del svg al elemento
			if (scaleSvg !== 0) {
				usingElementTransform.value.fitElementRegardingFactor(scaleSvg);
			}

			el.locked = currentLocked;

			addElement(el);

			// Establecemos la imagen bloqueada como fondo
			if (el.type === 'image' && el.locked) {
				newPage.backgroundImageId = el.id;
			}
		});

		doc.node.remove();

		return newPage;
	}

	private static getBackground(doc: Svg): Dom {
		let background = doc.findOne('[id*="background"]') as Dom;
		if (!background) {
			background = doc.findOne('rect, path') as Dom;
		}

		return background;
	}

	private static fixPage(doc: Svg) {
		// Para simplificar las búsquedas de elementos ponemos todas las id en minúsculas,
		// a excepción de los que estén en defs
		doc.find('[id]').forEach((el) => {
			if (!el.node.closest('defs')) {
				el.id(el.id().toLowerCase());
			}
		});

		// Las plantillas de slidesgo pueden tener el color de fondo como attr y no en el css
		const background = this.getBackground(doc);
		const fillColor =
			background.node.getAttribute('fill-color') || background.node.getAttribute('fill') || background.node.style.fill;
		const fillOpacity = background.attr('fill-opacity') !== undefined ? parseFloat(background.attr('fill-opacity')) : 1;

		if (fillColor && fillColor.includes('url')) {
			background.css('fill', fillColor);
		} else if (fillColor) {
			const [r, g, b] = Normalize(fillColor);
			const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${fillOpacity})`;

			background.css('fill', rgba);
		}

		// Hay veces que los grupos están formados de otra forma, usan el id custom-group
		// y tienen otros custom dentro, por lo que hay que hay que ponerles el data-group
		// para que podamos detectarlos
		doc
			.find('[id*="custom-group"]')
			.filter((el) => el.find('[id*="custom"], [data-type], [data-old-crop]').length > 0)
			.forEach((el) => {
				el.id('');
				el.data('group', true);
			});
	}

	private static setupGradients(doc: Svg, templateData: TemplateLoaderData) {
		// Los degradados pueden venir en la API, así que los generamos
		// a partir de los datos que nos llegan
		templateData.gradients?.forEach((gradient) => {
			const newGradient = doc.gradient(gradient.type, (add) => {
				gradient.stops.forEach((stop) => {
					add.stop(stop.offset, stop.color, stop.opacity);
				});
			});

			newGradient.id(gradient.id);
			newGradient.attr('transform', gradient.transform);
		});

		// Movemos elementos que deberían estar en defs a este
		const background = this.getBackground(doc);
		const container = background.parent() as Dom;

		ElementTools.fixDefsPosition(doc);

		// Comprobamos si el degradado del elemento existe, si no eliminamos el elemento
		container.find('[style*="url("]').forEach((el) => {
			const fill = el.css('fill');

			// Comprobamos que el fill con url existe para evitar búsquedas
			// de elementos con clip-path ya que tembién usan url
			if (!fill || !fill.includes('url')) {
				return;
			}

			const idGradient = ElementTools.getIdFromUrl(fill);
			const gradient = doc.defs().findOne(idGradient);
			const isBackground = el.id().includes('background');

			if (!gradient && !isBackground) {
				el.remove();
			} else if (!gradient && isBackground) {
				el.css('fill', doc.css('background') || '#FFFFFF');
			}
		});
	}

	private static fixTextErrors(div: any) {
		// Ñapa para evitar bug Chrome Mac
		div.style.transform = null;

		if (div.style.lineHeight) {
			div.style.lineHeight = `${Math.round(parseFloat(div.style.lineHeight))}px`;
		}

		const fontSize = Math.round(parseFloat(div.style.fontSize));

		if (fontSize === parseInt(div.style.lineHeight) || !div.style.lineHeight) {
			div.style.lineHeight = `${fontSize}px`;
		}

		// Especificamos un letter spacing sin mucha precision para evitar diferentes letter spacing
		// en base a la precisión del navegador
		if (div.style.letterSpacing) {
			const style = getComputedStyle(div);
			const spacingInPx = parseFloat(style.letterSpacing);
			div.style.letterSpacing = `${spacingInPx.toFixed(2)}px`;
		}

		// Por lo que sea se meten nodos de textos vacios al final, y da problemas con la serializacion
		if (
			div.childNodes.length > 1 &&
			div.lastChild.nodeName === '#text' &&
			div.lastChild.textContent.trim().length === 0
		) {
			div.removeChild(div.lastChild);
		}

		// Corregimos el alto de los textos
		const foreign = div.closest('foreignObject');
		const transform = foreign.getAttribute('transform');

		foreign.setAttribute('transform', '');

		let { height } = div.getBoundingClientRect();

		if (height === 0) {
			const { isAdminMode } = useEditorMode();
			if (isAdminMode.value) {
				const toast = useToast();
				toast.error('Invalid height detected');
				height = 30;
			}
		}

		if (transform) {
			foreign.setAttribute('transform', transform);
		}

		foreign.setAttribute('height', height);
	}

	private static getClipPathContainer(doc: Svg): Position {
		const viewbox = doc.viewbox();
		let clipPathContainer = doc.findOne('g[id*="clip-container"] > g');

		// Tenemos en cuenta que aunque tenga un clip-path asignado este puede no existir
		let clipPathId = clipPathContainer?.node.style.clipPath.toString().replace('url("#', '').replace('")', '');
		let clipRect = doc.findOne(`#${clipPathId} > :not(g)`) as SvgElement | null;

		if (!clipPathContainer || !clipRect) {
			const clipParent = doc.group();
			let clipPath = doc.findOne('[id*="svgjsclippath"],[id=runtime-clip-path]');

			clipParent.id('clip-container');
			clipPathContainer = clipParent.group();
			clipPathContainer.id('runtime-cp');

			// Si no tenemos clip-path lo creamos con el tamaño del svg
			if (!clipPath) {
				clipPath = doc.clip();
				clipPath.id('runtime-clip-path');

				const clipPathHeight = parseFloat(viewbox.height.toString());
				const clipPathWidth = parseFloat(viewbox.width.toString());

				const rect = doc.rect(clipPathWidth, clipPathHeight);
				clipPath.add(rect);
			}

			clipPathContainer.node.style.clipPath = `url(#${clipPath.id()})`;
		}

		// Movemos los elementos al interior del clipContainer
		const clipParent = clipPathContainer.parent() as SvgElement;

		Array.from(doc.node.children).forEach((node) => {
			if (
				node !== clipParent.node &&
				node !== doc.defs().node &&
				!node.contains((clipPathContainer as SvgElement).node)
			) {
				(clipPathContainer as SvgElement).node.append(node);
			}
		});

		// Unificamos los defs para que sea más fácil buscar los elementos
		doc
			.find(`defs`)
			.filter((def) => def.node !== doc.defs().node)
			.forEach((def) => {
				Array.from(def.node.children).forEach((i) => doc.defs().node.append(i));
				def.node.remove();
			});

		clipPathId = clipPathContainer.node.style.clipPath.toString().replace('url("#', '').replace('")', '');
		clipRect = doc.findOne(`#${clipPathId} > :not(g)`) as SvgElement;
		const clipPath = clipRect.parent() as SvgElement;

		let x = 0;
		let y = 0;

		// Obtenemos la posición del clipPath y ajustamos el contenido
		if (clipRect) {
			x = parseFloat(clipPath?.transform().translateX?.toString() || viewbox.x.toString());
			y = parseFloat(clipPath?.transform().translateY?.toString() || viewbox.y.toString());

			x += parseFloat(clipRect.x().toString());
			y += parseFloat(clipRect.y().toString());
		}

		return {
			x,
			y,
		};
	}

	private static fixOldGroups(doc: Svg) {
		const oldGroups = doc.find('[data-group="true"]');

		if (!oldGroups || !oldGroups.length) {
			return;
		}

		oldGroups
			.filter((group) => group.children().length > 1)
			.forEach((group) => {
				const wrapperGroup = doc.group();

				group.children().forEach((element) => {
					element.toParent(wrapperGroup);
				});

				wrapperGroup.toParent(group);
			});
	}

	private static unGroup(doc: Svg) {
		// Si hay grupos antiguos los actualizamos
		this.fixOldGroups(doc);

		doc.find('[data-group="true"]').each((group) => {
			const gId = uuidv4();
			let gContainer = group.last();

			// Buscamos el padre de los elementos del grupo, ya que por un
			// bug antiguo hay veces que están anidados en más de 1 grupos
			while (
				gContainer &&
				gContainer.children().length &&
				!gContainer.find(':scope > [id*="custom"], :scope > [data-type], :scope > [data-old-crop]').length
			) {
				gContainer = gContainer.last();
			}

			gContainer?.children().forEach((element) => {
				const container = group.parent() as Dom;
				let parent = element.parent() as SvgElement;

				// Buscamos el padre base y vamos aplicando los transforms según buscamos
				while (parent !== container) {
					element.transform(parent.transform(), true);
					parent = parent.parent() as SvgElement;
				}

				// Le asignamos un data para identificar que elementos pertenian al grupo
				element.data('groupId', gId);

				// Lo colocamos en la posición del grupo para que mantenga su z-index
				container.node.insertBefore(element.node, group.node);
			});

			group.remove();
		});
	}

	static getText(el: SvgElement): Text {
		const divs = el.children();
		let mainDiv = divs[0].node;
		let borderDiv = divs[divs.length === 1 ? 0 : 1].node;

		// Hay textos que no respetan el orden de los divs, así que buscamos el que tenga el border
		if (mainDiv.style.webkitTextStrokeColor) {
			mainDiv = divs[divs.length === 1 ? 0 : 1].node;
			borderDiv = divs[0].node;
		}

		this.fixTextErrors(mainDiv);

		// Anulamos la rotación para poder calcular la posición correctamente
		const rotation = parseFloat(el.transform('rotate').toString());
		el.transform({ rotate: -rotation }, true);

		const mainDivStyle = getComputedStyle(mainDiv);

		// Actualizamos los line-height del contenido
		mainDiv.querySelectorAll<HTMLElement>('[style*="line-height"]').forEach((line) => {
			const fontSize = parseFloat(line.style.fontSize.toString()) || parseFloat(mainDivStyle.fontSize);
			line.style.lineHeight = (parseFloat(line.style.lineHeight) / fontSize || 1.2).toString();
		});
		const textShadow = TextTools.getTextShadow(el);
		const content = mainDiv.innerHTML;
		const fontFamily = mainDivStyle.fontFamily.replace(/['"]+/g, '');

		let fontWeight = parseFloat(mainDiv.style.fontWeight || '400') as FontWeight;
		fontWeight = TextTools.getNearestWeight(fontFamily, fontWeight);

		const fontStyle = mainDivStyle.fontStyle as FontStyle;
		const fontSize = parseFloat(mainDivStyle.fontSize);
		const lineHeight = parseFloat(mainDiv.style.lineHeight) / fontSize || 1.2;
		const letterSpacing = parseFloat(mainDivStyle.letterSpacing) || 0;
		const textAlign = mainDivStyle.textAlign as TextAlign;
		const outline = {
			color: SolidColor.fromString(borderDiv.style.webkitTextStrokeColor),
			width: parseFloat(borderDiv.style.webkitTextStrokeWidth),
		};
		const color = SolidColor.fromString(mainDivStyle.color);
		const textTransform = mainDivStyle.textTransform !== 'none' ? mainDivStyle.textTransform : ('' as TextTransform);
		const size = {
			width: parseFloat(el.width().toString()) * parseFloat(el.transform('scaleX').toString()),
			height: parseFloat(el.height().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const position = {
			x: parseFloat(el.attr('x')) + parseFloat(el.transform('translateX').toString()),
			y: parseFloat(el.attr('y')) + parseFloat(el.transform('translateY').toString()),
		};
		const group = el.data('groupId') || null;
		const scale = parseFloat(el.transform('scaleX').toString());

		const newText = Text.create(content, {
			size,
			position,
			rotation,
			group,
			fontFamily,
			fontWeight,
			fontStyle,
			fontSize,
			lineHeight,
			letterSpacing,
			textAlign,
			outline,
			textShadow,
			color,
			colors: [color],
			textTransform,
			scale,
		});

		return newText;
	}

	static getNativeText(textNode: SvgElement): Text {
		const scale = parseFloat(textNode.transform('scaleX').toString());
		const { fontFamily, fontWeight, fontStyle, fontSize, letterSpacing, outline, color } =
			TextTools.extractFontDataFromText(textNode);
		const { content, matrix, height, width, textAlign } = TextTools.extractTextData(textNode, fontSize);

		const newText = Text.create(content, {
			scale,
			fontFamily,
			fontWeight,
			fontStyle,
			fontSize,
			letterSpacing,
			outline,
			textAlign,
			color,
			colors: [color],
			size: {
				width,
				height,
			},
			position: {
				x: matrix.e,
				y: matrix.f,
			},
		});

		return newText;
	}

	static getImage(el: SvgElement): Image {
		const mainImage = el.findOne('.main-image') as SvgElement;
		const rectHandler = el.findOne('.rect-handler') as SvgElement;
		const isNewCropsParser = mainImage && rectHandler;

		// Las imágenes del antiguo crop no tienen filtros así que las ignoramos
		const filter = mainImage ? this.getFilterData(mainImage) : null;

		const { crop, flip, keepProportions, opacity, position, rotation, size, url } = isNewCropsParser
			? this.cropParser(el)
			: this.oldCropParser(el);

		const group = el.data('groupId') || null;
		const locked = el.data('locked') || null;
		const mask = this.maskParser(el);
		const userUpload = mainImage ? mainImage.data('uploadid') : null;

		let metadata = {};

		if (userUpload) {
			metadata = { uploadId: userUpload };
		}

		// Pre-request the image
		document.createElement('img').src = url;

		const newImage = Image.create(url, {
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			crop,
			mask,
			filter,
			metadata,
		});
		newImage.setGroup(group);

		return newImage;
	}

	private static getFilterData(el: SvgElement) {
		const filterAttr = el.attr('filter');

		if (!filterAttr) return;

		// Buscamos el defs del filtro
		const filterId = ElementTools.getIdFromUrl(filterAttr);
		const filterSvg = el.defs().findOne(filterId);

		if (!filterSvg) return;

		// Extraemos los datos del filtro
		const filterData = el.data('filter') ? el.data('filter').split(' ') : null;

		const brightness = (filterData ? parseFloat(filterData[0]) : 1) * 100;
		const saturation = (filterData ? parseFloat(filterData[1]) : 1) * 100;
		const contrast = (filterData ? parseFloat(filterData[2]) : 1) * 100;
		const hue = (filterData ? parseFloat(filterData[3]) : 0) * 100;
		const blur = (filterData ? parseFloat(filterData[4]) : 0) * 100;
		const filterName = filterData ? filterData[5] : '';
		const grayscale = filterName === 'inkwell' ? 100 : null;

		const filter = new Filter(contrast, brightness, saturation, null, grayscale, null, hue, blur, null);

		return filter;
	}

	private static cropParser(element: SvgElement) {
		// Get elements to extract data
		const mainImage = element.findOne('.main-image') as SvgElement;

		// Creamos una imagen con las mismas transformaciones que el rectHandler, esto se hace porque el rectHandler
		// al quitarle la rotación se le produce un skew que nos acaba dando datos incorrectos, usamos esta copia para
		// trabajar con los datos
		const cloneRectImage = element.image('https://wepik.com/svg/mask-placeholder.svg');
		const rectHandler = element.findOne('.rect-handler') as SvgElement;
		cloneRectImage.transform(rectHandler.transform());
		cloneRectImage.width(rectHandler.width());
		cloneRectImage.height(rectHandler.height());
		cloneRectImage.x(rectHandler.x());
		cloneRectImage.y(rectHandler.y());

		const clipPathId = element.data('cpmask') || element.data('cp');
		const clipPath = clipPathId && (element.root().findOne(`defs > clipPath[id="${clipPathId}"]`) as SvgElement);
		const clipPathRectSizeMaskId =
			element.data('cp-rect-size') || element.data('cp-rectsize') || element.data('cpmask');
		const clipPathRectSizeMask =
			clipPathRectSizeMaskId &&
			(element.root().findOne(`defs > clipPath[id="${clipPathRectSizeMaskId}"]`) as SvgElement);
		const isMask = clipPath && clipPath.data('ismask');
		// El clippath de máscaras en safari no nos da width/height usamos el rectsize (clippath auxiliar) para obtenerlo
		const clipPathMainNode = clipPath
			? {
					x: isMask ? clipPath.transform('e') : clipPath.children()[0].x(),
					y: isMask ? clipPath.transform('f') : clipPath.children()[0].y(),
					width: isMask ? clipPathRectSizeMask.children()[0].width() : clipPath.children()[0].width(),
					height: isMask ? clipPathRectSizeMask.children()[0].height() : clipPath.children()[0].height(),
			  }
			: {
					x: 0,
					y: 0,
					width: mainImage.width(),
					height: mainImage.height(),
			  };

		// Data
		const flip = {
			x: parseFloat(mainImage.transform('a').toString()) < 0,
			y: parseFloat(mainImage.transform('d').toString()) < 0,
		};
		const keepProportions = !rectHandler.data('free-ratio') || true;
		const opacity = mainImage.opacity();
		const url = mainImage.attr('href') || mainImage.attr('xlink:href');

		// Get rotation before start parsing crop
		const rotation = parseFloat(rectHandler.transform('rotate').toString());

		mainImage.toRoot();
		mainImage.rotate(-rotation);

		const mainImageScale = {
			x: parseFloat(mainImage.transform('a').toString()),
			y: parseFloat(mainImage.transform('d').toString()),
		};

		const crop = {
			size: {
				width: (mainImage.width() as number) * Math.abs(mainImageScale.x),
				height: (mainImage.height() as number) * Math.abs(mainImageScale.y),
			},
			position: {
				x: -clipPathMainNode.x * mainImageScale.x,
				y: -clipPathMainNode.y * mainImageScale.y,
			},
		};

		const cloneRotation = cloneRectImage.transform('rotate');
		cloneRectImage.toRoot();
		cloneRectImage.rotate(-cloneRotation);

		const size = {
			width: cloneRectImage.width() * cloneRectImage.transform('a'),
			height: cloneRectImage.height() * cloneRectImage.transform('d'),
		};

		const position = {
			x: cloneRectImage.x() * cloneRectImage.transform('scaleX') + cloneRectImage.transform('e'),
			y: cloneRectImage.y() * cloneRectImage.transform('scaleY') + cloneRectImage.transform('f'),
		};

		if (mainImageScale.x < 0) {
			crop.position.x *= -1;
		}

		if (mainImageScale.y < 0) {
			crop.position.y *= -1;
		}

		return { crop, flip, keepProportions, opacity, position, rotation, size, url };
	}

	private static oldCropParser(element: SvgElement) {
		// Get elements to extract data
		const elTransform = element.transform();
		element.attr('transform', null);
		const image = element.find('image')[0];

		// Data
		const flip = {
			x: parseFloat(image.transform('a').toString()) < 0,
			y: parseFloat(image.transform('d').toString()) < 0,
		};
		const keepProportions = true;
		const opacity = element.opacity();
		const url = image.data('rawurl');

		// fix for lazy json
		if (image.data('size-raw') && typeof image.data('size-raw') === 'string') {
			const sizeRaw = JSON.parse(image.data('size-raw').replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": '));
			image.data('size-raw', sizeRaw);
		}

		if (!image.data('cropScaleX') && !image.data('crop-scale-x')) {
			image.data('cropScaleX', elTransform.scaleX);
			image.data('cropScaleY', elTransform.scaleY);
		}

		const cropScale = {
			x: image.data('cropScaleX') || image.data('crop-scale-x'),
			y: image.data('cropScaleY') || image.data('crop-scale-y'),
		};

		const scaleDiffX = !image.data('size-raw') ? cropScale.x : (elTransform.scaleX as number) / cropScale.x;
		const scaleDiffY = !image.data('size-raw') ? cropScale.y : (elTransform.scaleY as number) / cropScale.y;

		// setup for old uncropped images
		if (!image.data('size-raw')) {
			image.data('size-raw', {
				width: image.data('cropw'),
				height: image.data('croph'),
				left: 0,
				top: 0,
			});
		}

		// handle escalation after crop was done
		if (scaleDiffX !== 1 || scaleDiffY !== 1) {
			image.data('size-raw', {
				width: image.data('size-raw').width * scaleDiffX,
				height: image.data('size-raw').height * scaleDiffY,
				left: image.data('size-raw').left * scaleDiffX,
				top: image.data('size-raw').top * scaleDiffY,
			});

			image.data('cropw', image.data('cropw') * scaleDiffX);
			image.data('croph', image.data('croph') * scaleDiffY);
		}

		const crop = {
			position: {
				x: -image.data('cropx') + image.data('imagecropx'),
				y: -image.data('cropy') + image.data('imagecropy'),
			},
			size: {
				width: image.data('size-raw').width,
				height: image.data('size-raw').height,
			},
		};
		const position = {
			x: elTransform.e || 0,
			y: elTransform.f || 0,
		};
		const size = {
			width: image.data('cropw'),
			height: image.data('croph'),
		};

		// Remove parents <g> offsets
		const parents = element.parents('svg');
		parents.forEach((parent) => {
			if (parent.type === 'svg') return;
			const transform = parent.transform();
			position.x += transform.e || 0;
			position.y += transform.f || 0;
		});

		// Set parent rotation to children
		const rotation = elTransform.rotate;

		if (rotation) {
			const sign = rotation > 0 ? 1 : -1;
			const abs = Math.abs(rotation);

			let angle = rotation;

			if (abs > 89.0) {
				angle = Math.ceil(abs) * sign;
			}

			if (abs < 0.1) {
				angle = Math.floor(abs) * sign;
			}

			const sin = Math.sin(angle * (Math.PI / 180));

			if (angle > 0) {
				position.x = sin * image.data('croph');
			}

			if (angle < 0) {
				position.y = -sin * image.data('cropw');
			}
		}

		return { crop, flip, keepProportions, opacity, position, rotation, size, url };
	}

	private static maskParser(element: SvgElement) {
		const clipPathMaskId = element.data('cpmask');

		if (!clipPathMaskId) return undefined;

		const clipPathMask = element.root().findOne(`defs > clipPath[id="${clipPathMaskId}"]`) as ClipPath;
		const maskApiId = element.data('mask-selected');

		return Mask.fromTemplate(maskApiId, clipPathMaskId, clipPathMask);
	}

	static getShape(el: SvgElement): Shape {
		// Para los elementos que no son grupos, por ejemplo los rect que tenemos en algunas plantillas
		const isSingleElement = el.type !== 'g';

		const templateHash = TemplateLoader.hashCode(el.node);

		const groupId = el.data('groupId') || null;
		const keepProportions = !el.data('free-ratio');
		const rotation = parseFloat(el.transform('rotate').toString());
		const flip = {
			x: !isSingleElement ? parseFloat(el.first().transform('scaleX').toString()) < 0 : false,
			y: !isSingleElement ? parseFloat(el.first().transform('scaleY').toString()) < 0 : false,
		};
		const opacity = isSingleElement ? 1 : parseFloat(el.css('opacity') || '1');
		const viewbox = `${el.x()} ${el.y()} ${el.width()} ${el.height()}`;

		// Anulamos la rotación y el flip para evitar que se tenga en cuenta en el Shape.fromSvg
		el.transform({ rotate: -rotation }, true);

		if (!isSingleElement) {
			if (flip.x) el.first().flip('x');
			if (flip.y) el.first().flip('y');
			el.css('opacity', '');
		}

		const newShape = Shape.fromSvg(
			`<svg viewBox="${viewbox}"><defs>${el.defs().node.innerHTML}</defs>${el.node.outerHTML}</svg>`
		);
		newShape.rotation = rotation;
		newShape.flip = flip;
		newShape.opacity = opacity;
		newShape.keepProportions = keepProportions;
		newShape.setGroup(groupId);
		newShape.metadata.sourceTemplateHash = templateHash;

		return newShape;
	}

	private static getStoryset(el: SvgElement): Storyset {
		const groupId = el.data('groupId') || null;
		const keepProportions = !el.data('free-ratio');
		const viewbox = `${el.x()} ${el.y()} ${el.width()} ${el.height()}`;
		const size = {
			width: parseFloat(el.width().toString()) * parseFloat(el.transform('scaleX').toString()),
			height: parseFloat(el.height().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const position = {
			x:
				parseFloat(el.transform('translateX').toString()) +
				parseFloat(el.x().toString()) * parseFloat(el.transform('scaleX').toString()),
			y:
				parseFloat(el.transform('translateY').toString()) +
				parseFloat(el.y().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const rotation = parseFloat(el.transform('rotate').toString());
		const flip = {
			x: parseFloat(el.first().transform('scaleX').toString()) < 0,
			y: parseFloat(el.first().transform('scaleY').toString()) < 0,
		};
		const opacity = parseFloat(el.css('opacity') || '1');

		let mainColor;
		let colorSelector = '';

		if (el.data('color').includes('url')) {
			// Buscamos el degradado correcto ya que se genera un temporal
			let gradientId = Array.from(
				el.node.outerHTML
					.toString()
					.replaceAll('&quot;', '"')
					.matchAll(/url\("#(.*?)"\)/g),
				(url) => url[0]
			).find((url) => !url.includes('temporal')) as string;

			gradientId = ElementTools.getIdFromUrl(gradientId);

			// Sustituimos el degrado temporal por el principal
			el.node.innerHTML = el.node.innerHTML.replaceAll('#temporal-gradient', gradientId);

			colorSelector = gradientId;

			const gradientEl = el.defs().findOne(gradientId) as SvgElement;

			mainColor = ElementTools.svgGradientToObject(gradientEl);
		} else {
			mainColor = SolidColor.fromString(el.data('color'));
			colorSelector = mainColor.toRgb();
		}

		// Sustituimos el color principal por un placeholder para evitar conflictos en el comportamiento
		el.find(`[style*="${colorSelector}"]`).forEach((elChild) => {
			const fill = ElementTools.getIdFromUrl(elChild.css('fill')) === colorSelector;

			if (fill) {
				elChild.css('fill', 'var(--main-storyset-color)');
			}

			const stroke = ElementTools.getIdFromUrl(elChild.css('stroke')) === colorSelector;

			if (stroke) {
				elChild.css('stroke', 'var(--main-storyset-color)');
			}
		});

		const storysetSvg = el.first().node.innerHTML;
		const newStoryset = Storyset.fromSvg(`<svg viewBox="${viewbox}">${storysetSvg}</svg>`);
		newStoryset.viewbox = viewbox;
		newStoryset.size = size;
		newStoryset.position = position;
		newStoryset.rotation = rotation;
		newStoryset.flip = flip;
		newStoryset.opacity = opacity;
		newStoryset.mainColor = mainColor;
		newStoryset.keepProportions = keepProportions;
		newStoryset.setGroup(groupId);

		return newStoryset;
	}

	static getLine(el: SvgElement): Line {
		const groupId = el.data('groupId') || null;

		// Hay que tener en cuenta el transform del <g> base
		const scaleX = parseFloat(el.transform('scaleX').toString()) || 1;
		const scaleY = parseFloat(el.transform('scaleY').toString()) || 1;
		const translateX = parseFloat(el.transform('translateX').toString());
		const translateY = parseFloat(el.transform('translateY').toString());

		const realLine = el.type === 'line' ? el : el.first();

		const x1 = translateX + parseFloat((realLine.attr('x1') || '0').toString()) * scaleX;
		const y1 = translateY + parseFloat((realLine.attr('y1') || '0').toString()) * scaleY;
		const x2 = translateX + parseFloat((realLine.attr('x2') || '0').toString()) * scaleX;
		const y2 = translateY + parseFloat((realLine.attr('y2') || '0').toString()) * scaleY;

		const opacity = parseFloat(el.css('opacity') || '1');

		const height = parseFloat(realLine.css('stroke-width').toString() || '1') * scaleY;
		const width = MathTools.getDistanceBetween2Points(x1, y1, x2, y2);
		const rotation = MathTools.getAngle(x1, y1, x2, y2);

		// Calculamos la posición en base a la rotación
		const origin = {
			x: x1 + width / 2,
			y: y1 + height / 2,
		};
		const rotatePosition = MathTools.rotatePoint(origin.x, origin.y, x1, y1, rotation);
		const diff = {
			x: rotatePosition.x - x1,
			y: rotatePosition.y - y1,
		};

		const newLine = Line.fromSvg(`<svg>${realLine.node.outerHTML}</svg>`);
		newLine.setSize(width, height);
		newLine.setGroup(groupId);
		newLine.setPosition(x1 - diff.x, y1 - diff.y);
		newLine.setRotation(rotation);
		newLine.setOpacity(opacity);

		return newLine;
	}

	static async fromPredefinedText(url: string) {
		const { data, response } = await getSvg(url).text();
		const contentType = response.value?.headers.get('content-type');
		const dataCasted = contentType === 'application/json' ? JSON.parse(data.value as string) : data.value;

		return typeof dataCasted === 'string'
			? await this.parsePredefinedText(dataCasted)
			: this.loadPredefinedTextFromData(dataCasted);
	}

	private static async parsePredefinedText(data: any) {
		const doc = SVG(data).addTo(document.body) as Svg;
		const viewbox = doc.viewbox();

		doc.height(viewbox.height);
		doc.width(viewbox.width);
		doc.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		const background = this.getBackground(doc);
		const container = background.parent() as Dom;

		this.unGroup(doc);

		await this.preloadFonts(doc);

		const elements = container
			.find(':scope > *:not([id*="background"])')
			.map((el) => TemplateLoader.getElementByType(el));

		doc.node.remove();

		return elements;
	}

	private static loadPredefinedTextFromData(data: any): Element[] {
		const idGroup = uuidv4();
		const elements = data.elements.map((element: any) => {
			element.group = idGroup;
			return this.unserializeElement(element);
		});

		return elements;
	}

	static unserializeElement(element: any): Element {
		switch (element.type) {
			case 'shape': {
				return Shape.unserialize(element);
			}

			case 'text': {
				return Text.unserialize(element);
			}

			case 'image': {
				return Image.unserialize(element);
			}

			case 'storyset': {
				return Storyset.unserialize(element);
			}

			case 'line': {
				return Line.unserialize(element);
			}

			case 'qrcode': {
				return QRCode.unserialize(element);
			}

			default:
				throw new Error('Invalid element type');
		}
	}

	static hashCode(node: HTMLElement): string {
		// quitamos el groupId que se genera al vuelo para ser consistentes con los hashes
		const copy = node.cloneNode() as HTMLElement;
		copy.removeAttribute('data-groupId');
		copy.querySelectorAll('[data-groupId]').forEach((n) => n.removeAttribute('data-groupId'));

		const str = copy.outerHTML;

		let hash = 0;

		for (let i = 0; i < str.length; i++) {
			const char = str.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash &= hash; // Convert to 32bit integer
		}

		return new Uint32Array([hash])[0].toString(36);
	}
}

export default TemplateLoader;
