<script setup lang="ts">
import { useFps, useMemory } from '@vueuse/core';

import { useAdminActions } from '@/composables/useAdminActions';
import MathTools from '@/utils/MathTools';

const { projectData } = useAdminActions();
const fps = useFps();
const { isSupported, memory } = useMemory();
</script>

<template>
	<div v-if="isSupported && memory" class="mt-auto flex flex-col p-1 font-mono text-xs">
		<div>
			<span class="text-[11px] opacity-70">Fps: </span> <span class="mb-1">{{ fps }}</span>
		</div>

		<div>
			<span class="text-[11px] opacity-70">Used: </span>
			<span class="mb-1">{{ MathTools.formatBytes(memory.usedJSHeapSize, 2) }}</span>
		</div>

		<div>
			<span class="text-[11px] opacity-70">Allocated: </span>
			<span class="mb-1">{{ MathTools.formatBytes(memory.totalJSHeapSize, 2) }}</span>
		</div>

		<div>
			<span class="text-[11px] opacity-70">Limit: </span>
			<span class="mb-1">{{ MathTools.formatBytes(memory.jsHeapSizeLimit, 2) }}</span>
		</div>

		<div>
			<span class="text-[11px] opacity-70">Shape nodes: </span>
			<span class="mb-1">{{ projectData.nodesLength }}</span>
		</div>

		<div>
			<span class="text-[11px] opacity-70">Size: </span>
			<span class="mb-1">{{ MathTools.formatBytes(projectData.documentSize, 2) }}</span>
		</div>
	</div>
</template>
