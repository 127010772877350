<script lang="ts" setup>
import { Dom } from '@svgdotjs/svg.js';
import { useDebounceFn } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

import { Illustrator } from '@/Classes/Illustrator';
import Page from '@/Classes/Page';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useIllustrator } from '@/composables/element/illustrator/useIllustrator';
import { usePage } from '@/composables/page/usePage';
import { useProject } from '@/composables/project/useProject';
import { useEditorMode } from '@/composables/useEditorMode';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { Color } from '@/Types/colorsTypes';
import GAnalytics from '@/utils/GAnalytics';

const props = defineProps<{ page: Page }>();
const page = toRef(props, 'page');
const { setBackground, position } = usePage(page);
const { copyPage, removePage, addEmptyPage, movePage, canMoveUp, canMoveDown } = useProject();
const { isIllustratorContext } = useEditorMode();

const store = useMainStore();
const project = useProjectStore();
const illustratorElement = ref(
	isIllustratorContext.value ? (project.pages[0].elements[0] as Illustrator) : Illustrator.createDefault()
);
const { moveTexts, moveShapes } = useIllustrator(illustratorElement);

const move = (page: Page, direction: 'up' | 'down') => {
	const newIndex = position.value + (direction === 'down' ? 1 : -1);

	movePage(page, newIndex);
};

const showIllustratorButtons = computed(() => {
	return isIllustratorContext.value && position.value === 0 && project.pages.length > 1;
});

const addPage = () => {
	addEmptyPage(page.value);
	GAnalytics.track('click', 'Template', 'add-new-page', null);
};

const deletePage = () => {
	removePage(page.value);
	GAnalytics.track('click', 'Template', 'delete-page', null);
};

const clearIllustratorSelection = () => {
	store.illustratorSelection.clear();
};

const moveIllustratorElements = () => {
	const svg = illustratorElement.value.contentSvg;

	const textsId: string[] = [];
	const shapesId: string[] = [];

	// Categorizamos los elementos, ya que los textos y los shapes se crean de forma diferente,
	// los shapes se unificarán, mientras que los textos son individuales
	Array.from(store.illustratorSelection).forEach((id) => {
		const type = (svg.findOne(`[data-illustrator-link="${id}"]`) as Dom).type;

		switch (type) {
			case 'text': {
				textsId.push(id);
				break;
			}
			default: {
				shapesId.push(id);
				break;
			}
		}
	});

	moveTexts(textsId);
	moveShapes(shapesId);

	clearIllustratorSelection();
};

const removeIllustratorElements = () => {
	const svg = illustratorElement.value.contentSvg;
	const elements = Array.from(store.illustratorSelection)
		.map((id) => `[data-illustrator-link="${id}"]`)
		.join(', ');

	// Eliminamos los elementos seleccionados y los grupos vacíos que han podido quedar
	svg.find(elements).forEach((el) => el.remove());
	svg.find('g').forEach((el) => {
		if (!el.node.childElementCount) {
			el.remove();
		}
	});

	illustratorElement.value.content = svg.node.innerHTML.toString();

	clearIllustratorSelection();
};

const updateBackground = (bg: Color) => setBackground(bg);

const trackableEvent = useDebounceFn(() => {
	GAnalytics.track('click', 'Button', 'update-background', null);
}, 100);
</script>

<template>
	<div class="relative z-[5] flex items-end justify-between gap-3 pb-2">
		<p v-if="project.pages.length > 1" class="text-xs text-gray-400">Page {{ position + 1 }}</p>
		<div class="ml-auto flex items-center">
			<ColorPicker
				tooltip="Background color"
				tooltip-position="top"
				:color="page.background"
				class="z-10 h-5 w-5 shrink-0"
				@click="store.clearSelection"
				@track="trackableEvent"
				@change="updateBackground"
			/>

			<div v-if="showIllustratorButtons" class="z-0 ml-2 flex gap-2 border-l border-gray-700 pl-2">
				<button
					data-testid="clear-illustrator-selection"
					tooltip="Clear selection"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="clearIllustratorSelection"
				>
					<SvgIcon name="clean" class="h-4 w-4" />
				</button>

				<button
					data-testid="move-illustator-elements"
					tooltip="Move elements"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="moveIllustratorElements"
				>
					<SvgIcon name="blank" class="h-4 w-4" />
				</button>

				<button
					data-testid="delete-selection"
					tooltip="Remove elements"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="removeIllustratorElements"
				>
					<SvgIcon name="trash" class="h-4 w-4" />
				</button>
			</div>

			<div
				data-onboarding="page-interactions"
				class="z-0 flex gap-2 border-l border-gray-700"
				:class="{
					'ml-3 pl-2': !isIllustratorContext || (project.pages.length > 1 && position === 0),
				}"
			>
				<button
					v-if="!isIllustratorContext"
					data-testid="copy-page"
					tooltip="Copy"
					tooltip-position="top"
					class="tooltip flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="copyPage(page)"
				>
					<SvgIcon name="copy" class="h-4 w-4" />
				</button>

				<button
					v-if="!isIllustratorContext"
					data-testid="add-page"
					tooltip="Add page"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="addPage"
				>
					<SvgIcon name="plus" class="h-5 w-5" />
				</button>

				<button
					v-if="canMoveDown(page) && !isIllustratorContext"
					data-testid="move-down"
					tooltip="Move down"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="move(page, 'down')"
				>
					<SvgIcon name="arrow" class="h-6 w-6" />
				</button>

				<button
					v-if="canMoveUp(page) && !isIllustratorContext"
					data-testid="move-up"
					tooltip="Move up"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="move(page, 'up')"
				>
					<SvgIcon name="arrow" class="h-6 w-6 rotate-180" />
				</button>

				<button
					v-if="showIllustratorButtons || !isIllustratorContext"
					data-testid="delete-page"
					tooltip="Delete"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					:class="project.pages.length === 1 ? 'pointer-events-none opacity-50' : ''"
					@click="deletePage"
				>
					<SvgIcon name="trash" class="h-4 w-4" />
				</button>
			</div>
		</div>
	</div>
</template>
