<script lang="ts" setup>
import Image from '@/Classes/Image';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Storages
import { useMainStore } from '@/stores/store';
const store = useMainStore();

const props = defineProps<{ element: Image }>();

const onClickCrop = () => (store.croppingId = props.element.id);
</script>

<template>
	<button
		tooltip="Crop"
		tooltip-position="top"
		data-testid="crop-btn"
		data-button-importance="normal"
		class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-auto lg:flex-row lg:justify-center lg:px-2"
		@click="onClickCrop"
	>
		<SvgIcon v-if="element.mask" name="adjust" class="h-4 w-4 lg:scale-75" />
		<SvgIcon v-else name="crop" class="h-4 w-4 lg:scale-90" />
		<span class="text-2xs lg:mt-0 lg:ml-2 lg:text-sm" data-button-label>{{ element.mask ? 'Adjust' : 'Crop' }}</span>
	</button>
</template>
