// Classes
import { MaybeElementRef } from '@vueuse/core';
import { Ref, ref } from 'vue';

import { Text } from '@/Classes/Text';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useMainStore } from '@/stores/store';

export const useText = (text: Ref<Text>) => {
	const store = useMainStore();
	const { isCircleText } = useCircleTypeInfo(text);

	const fitHeight = (referenceNode?: MaybeElementRef): void => {
		if (isCircleText.value) {
			return;
		}

		const preReference = ref(referenceNode);
		const reference = ref(
			preReference.value || (text.value.domNode()?.querySelector(`.text-element-final`) as HTMLElement)
		);
		// Obtenemos el nodo principal para conseguir la altura
		let mainElement;

		if (reference.value instanceof HTMLElement) {
			mainElement = reference.value?.closest('[id^=element-')
				? (reference.value?.closest('[id^=element-') as HTMLElement)
				: (reference.value.parentElement as HTMLElement);
		}

		if (!mainElement) {
			return;
		}
		// Rotamos el elemento a la posición por defecto para poder obtener su altura sin tener en cuenta la rotación
		mainElement.style.transform = `translate(${text.value.position.x}px,${text.value.position.y}px)
		rotate(0deg)
		scaleX(${text.value.flipHTML.x})
		scaleY(${text.value.flipHTML.y})
	`;

		// Obtenemos la altura del texto una vez rotado
		// @ts-ignore
		let height = reference.value.getBoundingClientRect().height;

		// @ts-ignore
		const hasMultiLineHeight = Array.from(reference.value.querySelectorAll('*')).filter(
			(el) => el instanceof HTMLElement && el.style.lineHeight.length
		);

		// si tiene un lineheight < 1 y no es multiestilo, a la altura reportada le compensamos la altura
		// que nos ha quitado el lineheight
		if (
			reference.value &&
			!hasMultiLineHeight.length &&
			parseFloat((reference.value as HTMLElement).style.lineHeight) < 1
		) {
			height /= parseFloat((reference.value as HTMLElement).style.lineHeight);
		}

		if (height === 0) {
			console.error(`Texto detectado sin altura en el elemento [${mainElement.id}]`);
		}

		// Sobreescribimos la altura
		text.value.size.height = height / store.scale;

		// Volvemos a rotar a la posición original
		mainElement.style.transform = `translate(${text.value.position.x}px,${text.value.position.y}px)
		rotate(${text.value.rotation}deg)
		scaleX(${text.value.flipHTML.x})
		scaleY(${text.value.flipHTML.y})
	`;
	};

	return {
		fitHeight,
	};
};
