import { MaybeRef } from '@vueuse/core';
import { computed, ref } from 'vue';

import Element from '@/Classes/Element';

export function useElementRenderStyles(element: MaybeRef<Element>) {
	const el = ref(element);

	const styles = computed(() => {
		if (!el.value) return undefined;

		return {
			transform: `
      translate(${el.value.position.x}px,${el.value.position.y}px)
      rotate(${el.value.rotation}deg)
      scaleX(${el.value.flipHTML.x})
      scaleY(${el.value.flipHTML.y})
    `,
			width: `${el.value.size.width}px`,
			height: `${el.value.size.height}px`,
		};
	});

	return {
		styles,
	};
}
