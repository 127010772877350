import TextTools from './utils/TextTools';

// @ts-ignore
if (!String.prototype.replaceAll) {
	console.log('using pollyfill for String.prototype.replaceAll');
	// @ts-ignore
	String.prototype.replaceAll = function (str, newStr: string) {
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}
		return this.replace(new RegExp(typeof str === 'string' ? TextTools.escapeRegExp(str) : str, 'g'), newStr);
	};
}
