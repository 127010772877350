<script setup lang="ts">
import { toRef } from 'vue';

import { Text } from '@/Classes/Text';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import GAnalytics from '@/utils/GAnalytics';

// Props
const props = defineProps<{ element: Text }>();

// Using composables
const element = toRef(props, 'element');

// Methods
const { updateTextAlign } = useTextStyles(element);
const { isCircleText } = useCircleTypeInfo(element);

const alignClickManager = () => {
	switch (props.element.textAlign) {
		case 'left': {
			updateTextAlign('center');
			break;
		}
		case 'center': {
			updateTextAlign('right');
			break;
		}
		case 'right': {
			updateTextAlign('justify');
			break;
		}
		case 'justify': {
			updateTextAlign('left');
			break;
		}
	}
	GAnalytics.track('click', 'Button', `text-align-${props.element.textAlign}`, null);
};
</script>

<template>
	<div class="flex flex-col">
		<button
			:disabled="isCircleText"
			:class="isCircleText ? 'cursor-not-allowed opacity-25' : 'hover:bg-gray-600'"
			class="flex h-8 w-full items-center justify-center rounded bg-gray-700 px-3 text-gray-100"
			data-title="alignText"
			@click="alignClickManager()"
		>
			<SvgIcon
				v-if="element.textAlign === 'left'"
				name="alignleft"
				class="h-5 w-5"
				:class="{ 'fill-white': element.textAlign === 'left' }"
			/>
			<SvgIcon
				v-if="element.textAlign === 'center'"
				name="aligncenter"
				class="h-5 w-5"
				:class="{ 'fill-white': element.textAlign === 'center' }"
			/>
			<SvgIcon
				v-if="element.textAlign === 'right'"
				name="alignright"
				class="h-5 w-5"
				:class="{ 'fill-white': element.textAlign === 'right' }"
			/>
			<SvgIcon
				v-if="element.textAlign === 'justify'"
				name="alignjustify"
				class="h-5 w-5"
				:class="{ 'fill-white': element.textAlign === 'justify' }"
			/>
		</button>
		<label class="mt-1 text-center text-2xs text-gray-100" :class="isCircleText ? 'opacity-50' : ''"> Alignment </label>
	</div>
</template>
