<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { nextTick, onMounted, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import { useBugsnag } from '@/composables/useBugsnag';
import { useMainStore } from '@/stores/store';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const { percentageArc, getArcToApply } = useCircleTypeInfo(element);
const { fontSize, updateFontSize } = useTextStyles(element);
const store = useMainStore();
const { bugsnagMsgWithDebounce } = useBugsnag();

onMounted(() => {
	if (element.value.curvedProperties.arc !== null) {
		return;
	}

	handleCurve(0);
	Bugsnag.leaveBreadcrumb('Curved text is activated');
});

const handleCurve = async (percentage: number) => {
	if (store.textEditing) {
		store.textEditingId = null;
		await nextTick();
	}
	if (fontSize.value.length > 1) {
		updateFontSize(element.value.fontSize);
	}
	props.element.curvedProperties.arc = getArcToApply(percentage);
	bugsnagMsgWithDebounce('Modify curved text values');
};
</script>

<template>
	<div class="flex items-center gap-2 sm:pt-4">
		<SvgIcon name="rotation" class="h-3 w-3 -rotate-90 text-gray-100" />
		<div class="overflow relative flex h-4 w-full flex-1 items-center">
			<input
				:value="percentageArc"
				class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
				:max="100"
				:min="-100"
				:step="1"
				type="range"
				@input="handleCurve(parseInt($event.target.value))"
			/>
			<span
				class="pointer-events-none absolute top-[7px] left-0 h-[4px] origin-top-left scale-50 rounded-full bg-blue-500"
				:style="{
					width: `${percentageArc + 100}%`,
				}"
			/>
		</div>
		<SvgIcon name="rotation" class="h-3 w-3 rotate-90 text-gray-100" />
	</div>
</template>

<style scoped></style>
