import { useMemoize } from '@vueuse/core';
import { colord, extend } from 'colord';
import labPlugin from 'colord/plugins/lab';
import mixPlugin from 'colord/plugins/mix';
import { v4 as uuidv4 } from 'uuid';

import { SerializableClass } from '@/Classes/SerializableClass';
import { SolidColor } from '@/Classes/SolidColor';
import { ColorInterface, GradientColorDTO, GradientType, StopGradient } from '@/Types/colorsTypes';
import MathTools from '@/utils/MathTools';

extend([labPlugin, mixPlugin]);

const interpolateCached = useMemoize((gradient: GradientColor) => {
	return gradient.interpolateStops(5);
});

export class GradientColor extends SerializableClass implements ColorInterface {
	id: string;
	type: GradientType;
	rotation: number;
	stops: StopGradient[];

	constructor(type: GradientType, rotation: number, stops: StopGradient[], id?: string) {
		super();
		this.id = id || 'color-' + uuidv4();
		this.type = type;
		this.rotation = rotation;
		this.stops = stops;
	}

	static unique(colors: GradientColor[]): GradientColor[] {
		return colors.filter(
			(color, index, colors) => index === colors.findIndex((c) => c.toCssString() === color.toCssString())
		);
	}

	static create(type: GradientType, stops: StopGradient[], config?: any): GradientColor {
		const rotation = config?.rotation | 0;

		return new GradientColor(type, rotation, stops);
	}

	toCssString(): string {
		if (this.type === 'radial') {
			return `${this.type}-gradient(farthest-side, ${this.stops.map((stop) => this.stopToString(stop)).join()})`;
		}

		const newStops = interpolateCached(this);

		return `${this.type}-gradient(${this.rotation}deg, ${newStops.map((stop) => this.stopToString(stop)).join()})`;
	}

	toShapeString(): string {
		return `url('#${this.id}')`;
	}

	stopToString(stop: StopGradient): string {
		return `rgba(${stop.r}, ${stop.g}, ${stop.b}, ${stop.a}) ${stop.offset}%`;
	}

	convertToSolidColor(): SolidColor {
		const { r, g, b, a } = this.stops[0];
		return new SolidColor(r, g, b, a);
	}

	updateStop(stop: StopGradient, newStop: StopGradient) {
		const stopIndex = this.stops.findIndex((s) => this.stopToString(s) === this.stopToString(stop));
		this.stops[stopIndex] = newStop;
	}

	static fromObject(gradient: GradientColorDTO): GradientColor {
		const { id, type, rotation, stops } = gradient;

		const newGradient = new GradientColor(type, rotation, stops);

		if (id) {
			newGradient.id = id;
		}

		return newGradient;
	}

	isGradient(): boolean {
		return true;
	}

	isSolid(): boolean {
		return false;
	}

	toCssStringWithoutAlpha(): string {
		return this.withoutAlpha().toCssString();
	}

	withoutAlpha(): GradientColor {
		const stops = this.stops.map((stop) => ({ ...stop, a: 1 }));
		return new GradientColor(this.type, this.rotation, stops, this.id);
	}

	static unserialize(data: any): GradientColor {
		const { type, rotation, stops, id } = data;

		const fixedStops = Array.isArray(stops) ? stops : Object.values(stops as any);

		return new GradientColor(type, rotation, fixedStops, id);
	}

	toRegex(): RegExp {
		return new RegExp(this.toShapeString().replaceAll('(', '\\(').replaceAll(')', '\\)'), 'g');
	}

	interpolateStops(steps: number) {
		return this.stops.reduce((acc, stop) => {
			if (acc.length === 0) {
				return [stop];
			}
			const prev = acc[acc.length - 1];

			const start = colord({ r: prev.r, g: prev.g, b: prev.b, a: prev.a });
			const end = colord({ r: stop.r, g: stop.g, b: stop.b, a: stop.a });

			const interpolatedColors = [];

			for (let i = 0; i < steps; i++) {
				const step = (i + 1) / (steps + 1);
				const color = start.mix(end, step);

				const rgb = color.toRgb();
				const alpha = MathTools.interpolate(prev.a, stop.a, step);
				const midStop: StopGradient = {
					r: rgb.r,
					g: rgb.g,
					b: rgb.b,
					a: alpha > 1 ? alpha / 100 : alpha,
					offset: MathTools.interpolate(prev.offset, stop.offset, step),
				};

				interpolatedColors.push(midStop);
			}

			return [...acc, ...interpolatedColors, stop];
		}, [] as StopGradient[]);
	}
}
