// Vue & Packages
import Bugsnag from '@bugsnag/js';
import { createEventHook, useEventListener } from '@vueuse/core';
import { useDebounceFn } from '@vueuse/shared';
import Gesto, { OnPinch } from 'gesto';
import { computed, nextTick, ref } from 'vue';

// Composables
import { useArtboard } from '@/composables/project/useArtboard';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { ValidZoomTypes } from '@/Types/types';
// Utils
import EventTools from '@/utils/EventTools';
import MathTools from '@/utils/MathTools';

const isPinching = ref(false);
const finishHook = createEventHook();

// Valor del fit zoom para la plantilla
const templateFitZoom = ref(0);
// Tomamos como referencia que las plantillas con tamaño establecido su zoom va a rondar sobre el 70%
const factorZoom = computed(() => templateFitZoom.value / 70);

export const useZoom = () => {
	// Valores que vamos a mostrar siempre al usuario y que vamos a usar de referencia para las opciones / límites del zoom
	const ORIGINAL_ZOOM_OPTIONS: ValidZoomTypes[] = [
		10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 250, 300, 350, 400, 450, 500, 750,
	];
	const scrollArea = ref(document.querySelector('.scroll-area'));

	const store = useMainStore();
	const { isMobile } = useDeviceInfo();
	const { artboardSizeInPx } = useArtboard();

	const ZOOM_OPTIONS = computed(() => {
		return ORIGINAL_ZOOM_OPTIONS.map((n) => n * factorZoom.value);
	});

	// Esto debe ser scaleAsPercentage directamente
	const scaleAsPercentage = computed(() => {
		// Escala aplicada en porcentaje
		const scalePercentage = (store.scale || 0) * 100;
		// Le quitamos el factor aplicado
		return Math.round(scalePercentage / factorZoom.value);
	});

	const foundZoomIndex = computed(() => findClosestZoomIndex(scaleAsPercentage.value));
	const isMaxZoom = computed(() => foundZoomIndex.value === ZOOM_OPTIONS.value.length - 1);
	const isMinZoom = computed(() => !foundZoomIndex.value);

	const decreaseZoom = async (ev?: MouseEvent | OnPinch) => {
		if (isMinZoom.value) return;

		const previous = ZOOM_OPTIONS.value[foundZoomIndex.value - 1];
		ev ? await setMouseZoom(previous, ev) : setZoomDirectly(previous);
		Bugsnag.leaveBreadcrumb(
			`Decrease zoom ${(ev?.type as string) === 'wheel' ? 'using mouse wheel' : 'to'}: ${scaleAsPercentage.value}%`
		);
	};

	const increaseZoom = async (ev?: MouseEvent | OnPinch) => {
		if (isMaxZoom.value) return;

		const next = ZOOM_OPTIONS.value[foundZoomIndex.value + 1];
		ev ? await setMouseZoom(next, ev) : setZoomDirectly(next);

		Bugsnag.leaveBreadcrumb(
			`Increase zoom ${(ev?.type as string) === 'wheel' ? 'using mouse wheel' : 'to'}: ${scaleAsPercentage.value}%`
		);
	};

	const fitZoomScale = () => {
		const scrollArea = document.querySelector('#scroll-area');
		if (!scrollArea) throw new Error('scrollArea is undefined');

		const { width: scrollAreaWidth, height: scrollAreaHeight } = scrollArea.getBoundingClientRect();
		const { height, width } = artboardSizeInPx.value;

		const pageProportion = width / height;
		const scrollAreaProportion = scrollAreaWidth / scrollAreaHeight;

		// Si el svg es más alto que el canvas, ajustamos a lo alto. Si no, a lo ancho.
		const ratio = scrollAreaProportion > pageProportion ? scrollAreaHeight / height : scrollAreaWidth / width;

		const scale = ratio * (isMobile.value ? 0.9 : 0.8);

		templateFitZoom.value = scale * 100;

		setZoomDirectly(scale * 100);
	};

	const setZoomDirectly = (zoom: number) => {
		store.setScale(zoom / 100);

		// Control del zoom para el ring de selección de los elementos
		const ringWithZoom = 1 / store.scale;
		document.documentElement.style.setProperty('--zoom-ring', `${ringWithZoom}px`);
		Bugsnag.leaveBreadcrumb(`Set zoom from options: ${scaleAsPercentage.value}%`);
		finishZoom();
	};

	const setScrollInNewScale = async (zoom: number, ev: MouseEvent | OnPinch) => {
		if (!scrollArea.value) {
			scrollArea.value = document.querySelector('#scroll-area');
		}

		if (!scrollArea.value) throw new Error('scrollArea is undefined');
		// Guardamos lo necesario para calcular el nuevo scroll antes de hacer el zoom
		const mousePos = getEventPosition(ev);
		const previousWidth = scrollArea.value.scrollWidth;
		const previousHeight = scrollArea.value.scrollHeight;
		const boundContainer = scrollArea.value.getBoundingClientRect();

		const { x, y } = getScrollPosition(ev);

		const percentageScrollWidth = MathTools.ruleOfThree(previousWidth, 1, x);
		const percentageScrollHeight = MathTools.ruleOfThree(previousHeight, 1, y);

		store.setScale(zoom / 100);

		await nextTick();

		const scrollPosX = scrollArea.value.scrollWidth * percentageScrollWidth;
		const scrollPosY = scrollArea.value.scrollHeight * percentageScrollHeight;

		let scrollToX = mousePos ? scrollPosX - mousePos.x : scrollPosX - boundContainer.width / 2;
		let scrollToY = mousePos ? scrollPosY - mousePos.y : scrollPosY - boundContainer.height / 2;

		if (scrollArea.value.scrollWidth <= scrollArea.value.clientWidth) {
			scrollToX = 0;
		}

		if (scrollArea.value.scrollHeight <= scrollArea.value.clientHeight) {
			scrollToY = 0;
		}
		scrollArea.value.scrollTo(scrollToX, scrollToY);
	};

	const setMouseZoom = async (zoom: number, ev: MouseEvent | OnPinch) => {
		if (!scrollArea.value) {
			scrollArea.value = document.querySelector('#scroll-area');
		}

		if (!scrollArea.value) throw new Error('scrollArea is undefined');

		await setScrollInNewScale(zoom, ev);

		// Control del zoom para el ring de selección de los elementos
		const ringWithZoom = 1 / store.scale;
		document.documentElement.style.setProperty('--zoom-ring', `${ringWithZoom}px`);
		finishZoom();
	};

	const finishZoom = useDebounceFn(() => finishHook.trigger(store.scale), 400);

	const findClosestZoomIndex = (scaleAsPercentage: number) => {
		let min = Infinity;
		let closest = 0;

		// Buscamos el zoom más cercano al valor dado
		ORIGINAL_ZOOM_OPTIONS.forEach((option, index) => {
			const diff = scaleAsPercentage - option;

			if (diff < 0) return;

			if (diff < min) {
				min = diff;
				closest = index;
			}
		});

		return closest;
	};

	const getEventPosition = (ev: MouseEvent | OnPinch) => {
		if (!scrollArea.value) {
			scrollArea.value = document.querySelector('#scroll-area');
		}
		// CHECKPOINT: hablar en la reunión
		if (!scrollArea.value) {
			throw new Error('scrollArea is undefined');
		}
		return EventTools.getEventPositionInElement(scrollArea.value, ev);
	};

	const getScrollPosition = (ev: MouseEvent | OnPinch) => {
		if (!scrollArea.value) {
			scrollArea.value = document.querySelector('#scroll-area');
		}
		// CHECKPOINT: hablar en la reunión
		if (!scrollArea.value) {
			throw new Error('scrollArea is undefined');
		}

		const mousePos = getEventPosition(ev);
		const boundContainer = scrollArea.value.getBoundingClientRect();

		let x;
		let y;

		if (mousePos) {
			// Posición del ratón
			({ x, y } = mousePos);
			x += scrollArea.value.scrollLeft;
			y += scrollArea.value.scrollTop;
		} else {
			// Centro del canvas
			x = boundContainer.width / 2 + scrollArea.value.scrollLeft;
			y = boundContainer.height / 2 + scrollArea.value.scrollTop;
		}

		return {
			x,
			y,
		};
	};

	const initZoomMobile = () => {
		const scrollArea = document.querySelector('#scroll-area');
		if (!scrollArea) return;
		disableIosZoomNative();
		new Gesto(scrollArea, {
			container: scrollArea,
			events: ['touch'],
			preventDefault: false,
		})
			.on('pinchStart', async (ev) => {
				isPinching.value = true;
				store.clearSelection();
				ev.datas.startZoom = store.scale;
				await nextTick();
			})
			.on('pinch', async (e) => {
				const zoom = e.datas.startZoom * e.scale * 100;
				if (zoom < ZOOM_OPTIONS.value[0]) {
					return;
				}
				if (zoom > ZOOM_OPTIONS.value[ZOOM_OPTIONS.value.length - 1]) {
					return;
				}
				await setScrollInNewScale(zoom, e);
			})
			.on('pinchEnd', () => {
				// Control del zoom para el ring de selección de los elementos
				const ringWithZoom = 1 / store.scale;
				document.documentElement.style.setProperty('--zoom-ring', `${ringWithZoom}px`);
				isPinching.value = false;
			});
	};

	const disableIosZoomNative = () => {
		useEventListener(window.document, 'touchmove', (event: any) => {
			if (event.touches.length === 1) return;
			event = event.originalEvent || event;
			if (event.scale !== 1) {
				event.preventDefault();
			}
		});
		useEventListener(window.document, 'gesturestart', (event) => {
			event.preventDefault();
		});
	};

	return {
		ORIGINAL_ZOOM_OPTIONS,
		ZOOM_OPTIONS,
		foundZoomIndex,
		isMaxZoom,
		isMinZoom,
		scaleAsPercentage,
		isPinching,
		decreaseZoom,
		fitZoomScale,
		increaseZoom,
		setZoomDirectly,
		initZoomMobile,
		onZoomFinish: finishHook.on,
	};
};
