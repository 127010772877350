import { v4 as uuidv4 } from 'uuid';

import Element from '@/Classes/Element';
import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
import { Color } from '@/Types/colorsTypes';
import { SerializedClass } from '@/Types/types';

class Page {
	id: string;
	sourceId: string | null;
	name: string;
	background: Color;
	backgroundImageId: string | null;
	preview: string | null;
	elements: Element[];

	protected constructor(
		name: string,
		background: Color,
		elements: Element[],
		sourceId: string | null,
		backgroundImageId: string | null,
		preview: string | null
	) {
		this.id = uuidv4();
		this.name = name;
		this.background = background;
		this.elements = elements;
		this.sourceId = sourceId;
		this.backgroundImageId = backgroundImageId;
		this.preview = preview;
	}

	static defaults() {
		return {
			name: 'New page',
			background: SolidColor.white(),
			elements: [] as Element[],
			sourceId: '',
			backgroundImageId: '',
			preview: null,
		};
	}

	static create(config?: Partial<Page>): Page {
		const defaults = Page.defaults();

		return new Page(
			config?.name || defaults.name,
			config?.background || defaults.background,
			config?.elements || defaults.elements,
			config?.sourceId || defaults.sourceId,
			config?.backgroundImageId || defaults.backgroundImageId,
			config?.preview || defaults.preview
		);
	}

	static unserialize(data: SerializedClass<Page>): Page {
		const defaults = Page.defaults();
		const { name, background, elements, sourceId, backgroundImageId, preview } = data;

		let fixedBg: Color = defaults.background;

		if (background) {
			fixedBg = 'stops' in background ? GradientColor.unserialize(background) : SolidColor.unserialize(background);
		}

		const page = new Page(
			name || defaults.name,
			fixedBg,
			elements || defaults.elements,
			sourceId || defaults.sourceId,
			backgroundImageId || defaults.backgroundImageId,
			preview || defaults.preview
		);

		page.id = data.id as string;

		return page;
	}

	public static createDefault(): Page {
		const defaults = Page.defaults();

		return new Page(
			defaults.name,
			defaults.background,
			defaults.elements,
			defaults.sourceId,
			defaults.backgroundImageId,
			defaults.preview
		);
	}

	get contentSize(): {
		width: number;
		height: number;
		x: number;
		y: number;
	} {
		// Calculamos las coordenadas finales de los elementos
		const endCoords = this.elements
			.filter((el) => {
				const isBackground = el.locked && el.id === this.backgroundImageId;
				return !isBackground;
			})
			.map((element) => {
				const startX = element.position.x;
				const startY = element.position.y;
				const endX = element.position.x + element.size.width;
				const endY = element.position.y + element.size.height;

				return {
					startX,
					startY,
					endX,
					endY,
				};
			});

		const diffX = endCoords.reduce((acc, coord) => {
			return Math.min(acc, coord.startX);
		}, Infinity);

		const diffY = endCoords.reduce((acc, coord) => {
			return Math.min(acc, coord.startY);
		}, Infinity);

		const diffWidth = endCoords.reduce((acc, coord) => {
			return Math.max(acc, coord.endX);
		}, 0);

		const diffHeight = endCoords.reduce((acc, coord) => {
			return Math.max(acc, coord.endY);
		}, 0);

		return {
			height: diffHeight - diffY,
			width: diffWidth - diffX,
			x: diffX < 0 ? 0 : diffX,
			y: diffY < 0 ? 0 : diffY,
		};
	}

	/**
	 * Retorna la primera aparicion
	 */
	domNode(): HTMLElement | null {
		return document.querySelector(`#canvas-${this.id}`);
	}

	updateBackgroundColor(newColor: Color) {
		newColor.id = this.background.id;
		this.background = newColor;
	}

	replace(page: Page) {
		this.id = page.id;
		this.name = page.name;
		this.background = page.background;
		this.elements = page.elements;
		this.sourceId = page.sourceId;
		this.backgroundImageId = page.backgroundImageId;
		this.preview = page.preview || this.preview;
	}
}

export default Page;
