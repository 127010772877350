<script setup lang="ts">
// Vue
import { computed, toRef } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
// Classes
import { Shape } from '@/Classes/Shape';
// Components
import GradientsRenderer from '@/components/render/GradientsRenderer.vue';
// Composables
import { useShapeColors } from '@/composables/element/shape/useShapeColors';
import { useDeviceInfo } from '@/composables/useDeviceInfo';

// Props
const props = defineProps<{ element: Shape; forPreview?: boolean; smartSelection?: boolean, scale: number }>();

// Data
const element = toRef(props, 'element');

// Using composables
const { gradientColors } = useShapeColors(element);

const colorsVarCss = computed(() => {
	const obj: { [key: string]: string } = {};
	element.value.colors.forEach((color) => (obj[`--${color.id}`] = color.toShapeString()));
	return obj;
});

const { isMobile } = useDeviceInfo();
const smartStrokeWidth = computed(() => {
	return isMobile.value ? '40px' : '10px';
});
</script>

<template>
	<svg
		:viewBox="element.viewbox"
		width="100%"
		height="100%"
		:style="{ ...colorsVarCss }"
		preserveAspectRatio="none"
		:class="smartSelection ? 'smart-selection' : ''"
		v-html="element.content"
	></svg>
	<GradientsRenderer :gradients="(gradientColors as GradientColor[])" />
</template>

<style lang="css">
svg.smart-selection > * {
	pointer-events: auto;
}
svg.smart-selection *:not([style*='stroke']):not([stroke]) {
	stroke-width: v-bind(smartStrokeWidth);
	stroke: transparent;
}
.debug-mode svg.smart-selection *:not([style*='stroke']):not([stroke]) {
	stroke: red;
}
</style>
