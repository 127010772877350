import { until } from '@vueuse/core';
import { keyBy, sortBy } from 'lodash-es';
import { computed, Ref, ref, watch } from 'vue';
import WebFontLoader from 'webfontloader';

import { getFonts } from '@/api/DataApiClient';
import { Text } from '@/Classes/Text';
import { useTextStylesStatic } from '@/composables/element/text/useTextStyles';
import { useProjectStore } from '@/stores/project';
import { Dictionary, Font } from '@/Types/types';

let allFonts: Dictionary<Font> = {};
const loadedFonts = ref<string[]>([]);
const preloadedFonts = ref<string[]>([]);

const fontLoading = ref(true);
const firstLoadRequest = ref(true);
const fonts = ref(allFonts);

// Will ignore recommended ones
const sortedFonts = computed(() => {
	const all = Object.values(fonts.value).filter((f) => !f.recommended);
	return sortBy(all, 'name');
});

const recommendedFonts = computed(() => {
	const all = Object.values(fonts.value).filter((f) => !!f.recommended);
	return sortBy(all, 'name');
});

const loadedFontWeights: string[] = [];

const waitFont = (name: string, weight: string) => {
	weight = weight.toString();

	const key = name + weight;

	if (weight.includes('i')) {
		weight = 'italic ' + weight.replace('i', '');
	}

	return new Promise((success, reject) => {
		if (loadedFontWeights.includes(key)) {
			success(`Already loaded ${name} ${weight}`);
			return;
		}

		const interval = setInterval(() => {
			if (document.fonts.check(`${weight} 13px "${name}"`)) {
				clearTimeout(timeout);
				clearInterval(interval);

				loadedFontWeights.push(key);
				success(`Loaded ${name} ${weight}`);
			}
		}, 6);

		const timeout = setTimeout(() => {
			clearInterval(interval);
			reject(`Failed waiting for ${name} ${weight}`);
		}, 15000);
	});
};

const preloadFont = async (name: string, weight: string) => {
	const span = document.createElement('DIV');
	weight = weight.toString();

	span.style.fontFamily = name;
	span.style.fontWeight = weight.replace('i', '');
	span.style.lineHeight = '0';
	span.style.fontSize = '0';
	span.style.fontStyle = weight.includes('i') ? 'italic' : 'normal';
	span.innerText = 'load me';

	document.body.appendChild(span);

	return await waitFont(name, weight);
};

export const useFonts = () => {
	const store = useProjectStore();
	const temporalRef = ref(Text.createDefault());
	const { fontFamily } = useTextStylesStatic(temporalRef as Ref<Text>);

	const preload = async () => {
		if (window.preloadFonts && window.preloadFonts.length > 0) {
			allFonts = keyBy(window.preloadFonts, 'name');
			fonts.value = allFonts;
			return;
		}

		const { data } = await getFonts();

		allFonts = keyBy(data.value, 'name');
		fonts.value = allFonts;
	};

	const loadFonts = async (fonts: Font[], keepCurrentlyLoaded = true, isRetry = false) => {
		// A menos que sea la primera vez que se cargan fuentes, esperamos a que no se este cargando nada
		if (!firstLoadRequest.value) {
			await until(fontLoading).toBe(false, { timeout: 15000, throwOnTimeout: false });
		}

		if (!fonts.length) {
			return;
		}

		const notLoadedFontsRequested = fonts.some((font) => !loadedFonts.value.includes(font.name));

		if (!notLoadedFontsRequested) return;

		let names = fonts.filter((f) => !!f.name.length).map((f) => f.name);

		// cargamos las pedidas más las que ya hay en uso
		names = [
			...new Set([...names, ...inUseFonts.value.map((f) => f.name), ...(keepCurrentlyLoaded ? loadedFonts.value : [])]),
		];

		const fontLoadingPath = import.meta.env.VITE_APP_API_PATH;

		firstLoadRequest.value = false;

		WebFontLoader.load({
			timeout: 5000,
			custom: {
				families: names,
				urls: [`${fontLoadingPath}fonts/css/${names.sort().join(',')}`],
			},

			loading() {
				fontLoading.value = true;
			},

			active() {
				loadedFonts.value = names;
				fontLoading.value = false;
			},

			fontinactive(e: any) {
				console.error('Error loading font', e, names);
				if (!isRetry) {
					console.warn('Retrying font loading');
					loadFonts(fonts, keepCurrentlyLoaded, isRetry);
				}
			},
		});
	};

	const inUseFonts = computed(() => {
		const families = store.allTexts.flatMap((text: Text) => {
			temporalRef.value = text;
			return fontFamily.value;
		});

		return [...new Set([...families])]
			.map((family) => {
				if (!fonts.value[family] && Object.keys(fonts.value).length > 0) {
					console.warn(`Font ${family} not found`);
				}
				return fonts.value[family];
			})
			.filter((e) => !!e) as Font[];
	});

	const watchFonts = () => {
		watch(inUseFonts, () => loadFonts(inUseFonts.value, false), { immediate: true });

		watch(loadedFonts, () => preloadFontFilesInUse());
	};

	const getFont = (fontName: string) => {
		return fonts.value[fontName] || fonts.value['Lato'];
	};

	const getVariants = (fontsFamilies: string[]): { family: string; weight: string[] }[] => {
		const variants = <{ family: string; weight: string[] }[]>[];
		// Reemplazamos las comillas dobles contiene necesarias para las fuentes con números
		fontsFamilies.forEach((fontName) => {
			const fontNameCleaned = fontName.replaceAll('"', '');

			variants.push({
				family: fontNameCleaned,
				weight: fonts.value[fontNameCleaned].weights,
			});
		});

		return variants;
	};

	const loadFontsByName = async (fontNames: string[]) => {
		// Nos quedamos con las fuentes que aún no han cargado
		const fonts = fontNames
			.map((fontName) => getFont(fontName))
			.filter((font) => !loadedFonts.value.includes(font.name));

		// Si todas están cargadas pues pasamos
		if (!fonts.length) {
			return true;
		}

		await loadFonts(fonts);

		try {
			await until(fontLoading).toBe(false, { timeout: 15000, throwOnTimeout: true });
			await Promise.all(
				fonts.map((font) => until(loadedFonts).toContains(font.name, { timeout: 10000, throwOnTimeout: true }))
			);
			await Promise.all(
				fonts.map((font) => until(preloadedFonts).toContains(font.name, { timeout: 10000, throwOnTimeout: true }))
			);
		} catch (e) {
			console.error('Fallo al cargar fuentes', {
				fontLoading: fontLoading.value,
				requested: fonts.map((f) => f.name),
				loaded: loadedFonts.value,
				preloaded: preloadedFonts.value,
			});
		}
	};

	const preloadFontFilesInUse = async () => {
		if (import.meta.env.DEV) console.log('loaded fonts', loadedFonts.value);
		const missingPreloadingFonts = loadedFonts.value.filter((x) => !preloadedFonts.value.includes(x));

		if (import.meta.env.DEV) console.log('Preloading missing:', missingPreloadingFonts);
		await Promise.all(missingPreloadingFonts.map((font) => preloadFont(font, '400')));

		preloadedFonts.value = loadedFonts.value;
	};

	return {
		preload,
		fonts,
		sortedFonts,
		inUseFonts,
		getFont,
		getVariants,
		fontLoading,
		recommendedFonts,
		watchFonts,
		loadFontsByName,
	};
};
