import Bugsnag from '@bugsnag/js';
import { computed, Ref } from 'vue';

import Line from '@/Classes/Line';
import { useBugsnag } from '@/composables/useBugsnag';
import { Linecap } from '@/Types/types';

import { useLineColors } from './useLineColors';
const { bugsnagMsgWithDebounce } = useBugsnag();
export const useLine = (line: Ref<Line>) => {
	const lineColors = useLineColors(line);

	const hasMarkers = computed(() => {
		return !!(line.value.markerStart || line.value.markerEnd);
	});

	const updateStrokeWidth = (width: number) => {
		line.value.size.height = width;
		bugsnagMsgWithDebounce(`Modify line stroke width: ${width}; ${line.value.type}-${line.value.id}`)

	};

	const updateLinecap = (linecap: Linecap) => {
		line.value.linecap = linecap;
		Bugsnag.leaveBreadcrumb(`Set line linecap ${linecap} to ${line.value.type}-${line.value.id}`);
	};

	const updateDasharray = (dasharray: number[]) => {
		line.value.dasharray = dasharray;
		Bugsnag.leaveBreadcrumb(`Set line dasharray: ${dasharray}; ${line.value.type}-${line.value.id}`);
	};

	return {
		...lineColors,
		updateStrokeWidth,
		updateLinecap,
		updateDasharray,
		hasMarkers,
	};
};
