// Classes
import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';

export interface ColorInterface {
	isGradient(): boolean;
	isSolid(): boolean;
	toCssStringWithoutAlpha(): string;
	toCssString(): string;
	toRegex(): RegExp;
	toShapeString(): string;
}

export type Color = SolidColor | GradientColor;

export type GradientType = 'linear' | 'radial';

export interface SolidColorDTO {
	r: number;
	g: number;
	b: number;
	a: number;
}

export interface GradientColorDTO {
	id?: string;
	type: GradientType;
	rotation: number;
	stops: StopGradient[];
}

export interface StopGradient extends SolidColorDTO {
	offset: number;
}

export enum ColorPalette {
	document = 'Document',
	freepik = 'Freepik',
}
