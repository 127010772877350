import { debouncedWatch } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { nextTick, Ref, watch, WatchStopHandle } from 'vue';

import Page from '@/Classes/Page';
import { useHistoryStore } from '@/stores/history';

export function useDebouncedPageReference(pageReference: Ref<Page>, props) {
	const history = useHistoryStore();

	pageReference.value = cloneDeep(props.page);

	let watcher: WatchStopHandle | undefined;

	const registerWatcher = () => {
		if (watcher) watcher();

		watcher = debouncedWatch(
			props.page,
			(page) => {
				pageReference.value = cloneDeep(page);
			},
			{ debounce: 300 }
		);
	};

	registerWatcher();

	watch(
		() => history.key,
		async () => {
			await nextTick();
			pageReference.value = cloneDeep(props.page);
			registerWatcher();
		}
	);
}
