<script lang="ts" setup>
import { promiseTimeout, useInfiniteScroll } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';
import { nextTick, onActivated, onMounted, Ref, ref, toRef, watch } from 'vue';
import { computed } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';

const props = defineProps<{
	containerClasses?: string | object;
	modal?: boolean;
	masonry?: boolean;
	masonryCols?: number;
	horizontal?: boolean;
	isFetching?: boolean;
	data?: [];
}>();

const emit = defineEmits<{
	(e: 'load'): void;
}>();

// Template refs
const container = ref();

const data = toRef(props, 'data') as Ref<any[]>;
const sideContainer = ref(0);

const side = computed(() => (props.horizontal ? 'width' : 'height'));

onMounted(async () => {
	sideContainer.value = await calcSideContainer();
});

onActivated(async () => {
	container.value.style[side.value] = `${sideContainer.value}px`;
});

const calcSideContainer = async () => {
	await nextTick();

	container.value.style[side.value] = 'auto';

	const bounding = container.value.getBoundingClientRect();
	const size = bounding[side.value];
	container.value.style[side.value] = `${size}px`;
	container.value.style.flexGrow = 'initial';

	return size;
};

watch(sideContainer, async () => {
	await promiseTimeout(2000);

	const column = document.querySelector('.masonry-column');
	const heightColumn = column?.getBoundingClientRect().height;
	if (heightColumn && heightColumn < sideContainer.value) {
		emit('load');
	}
});

useInfiniteScroll(
	container,
	() => {
		emit('load');
	},
	{
		distance: props.horizontal ? window.outerWidth / 5 : window.outerHeight / 5,
	}
);
</script>

<template>
	<div
		ref="container"
		data-testid="container-resources"
		class="flex-grow overflow-auto pb-2 pr-1 scrollbar-thin"
		:class="modal ? 'text-white scrollbar-thumb-gray-100' : 'text-gray-800 scrollbar-thumb-gray-600'"
	>
		<slot></slot>

		<!-- Masonry -->
		<masonry v-if="masonry" :cols="masonryCols || 2" :gutter="10" :resolve-slot="true" column-class="masonry-column">
			<slot v-for="img in data" name="item" :item="img"></slot>
		</masonry>

		<!-- No masonry -->
		<div v-else :class="`masonry-column ${containerClasses}`">
			<template v-if="data && data.length">
				<slot name="default-item"></slot>
				<div v-for="item in data" :key="`${item?.id || uuidv4()}`">
					<slot name="item" :item="item"></slot>
				</div>
			</template>
		</div>

		<div v-if="isFetching && data.length" class="absolute bottom-8 flex w-full items-center justify-center">
			<span
				class="flex items-center rounded-full bg-opacity-75 p-1 pr-3 text-center text-sm font-semibold backdrop-blur"
				:class="modal ? 'bg-white text-gray-700' : 'bg-gray-900 text-gray-100'"
			>
				<SvgIcon data-testid="spinner" name="spinner" class="mr-1 h-7 w-7 animate-spin" /> Loading
			</span>
		</div>

		<div
			v-if="isFetching && !data.length"
			class="absolute inset-0 flex w-full items-center justify-center text-gray-100"
		>
			<SvgIcon name="spinner" class="h-10 w-10 animate-spin" />
		</div>
		<div
			v-if="!isFetching && !data.length"
			class="my-8 mx-auto rounded-full px-8 py-1 text-center text-xs"
			:class="modal ? 'bg-gray-100/50 text-gray-800' : 'bg-gray-600 text-gray-100'"
		>
			No results found
		</div>
	</div>
</template>

<style scoped></style>
