import { onClickOutside, RenderableComponent } from '@vueuse/core';
import { onMounted } from 'vue';
import { defineComponent, h, ref } from 'vue-demi';

export default defineComponent<RenderableComponent>({
	name: 'OnClickOutsideWithPortals',
	props: ['as', 'ignoreSelector'] as unknown as undefined,
	emits: ['trigger'],
	setup(props: any, { slots, emit }) {
		const target = ref();

		let childSlots: any[];

		onMounted(() => {
			const portalsTargets = childSlots.filter((a) => a.type.__isTeleport).map((a) => a.props.to);
			const portals = portalsTargets.map((selector) => document.querySelector(selector));

			onClickOutside(
				target,
				(e) => {
					if (props.ignoreSelector && (e.target as HTMLElement | null)?.closest(props.ignoreSelector)) return;
					emit('trigger', e);
				},
				{
					ignore: portals,
				}
			);
		});

		return () => {
			if (slots.default) return h(props.as || 'div', { ref: target }, (childSlots = slots.default()));
		};
	},
});
