import { MaybeElement, until, useIntersectionObserver } from '@vueuse/core';
import { nextTick, onMounted, Ref, watch } from 'vue';

import Page from '@/Classes/Page';
import { usePage } from '@/composables/page/usePage';

export function useLazyCanvas(
	page: Ref<Page>,
	canvasIsVisible: Ref<boolean>,
	scrollArea: Ref<MaybeElement>,
	canvas: Ref<MaybeElement>
) {
	const { position } = usePage(page);
	let watcher: ReturnType<typeof useIntersectionObserver> | null = null;

	const registerWatcher = async () => {
		if (watcher) {
			watcher.stop();
		}

		// Ya que es posible que el watcher se registre antes del cambio de posición
		// esperamos al siguiente tick para asegurarnos de que el canvas ya esta
		// en su sitio
		await nextTick();

		watcher = useIntersectionObserver(
			canvas,
			([{ isIntersecting }]) => {
				canvasIsVisible.value = isIntersecting;
			},
			{
				threshold: 0,
				rootMargin: '100px',
				root: scrollArea,
			}
		);
	};

	onMounted(async () => {
		if (!scrollArea.value) {
			await until(() => scrollArea.value).not.toBeUndefined();
		}

		if (!scrollArea.value || !canvas.value) return;

		const rect = (canvas.value as HTMLElement).getBoundingClientRect();

		// lanzamos un primer check de visibilidad para no esperar a que el
		// interseccion observer se active. Con esto evitamos flashes iniciales
		canvasIsVisible.value =
			rect.top <= (scrollArea.value as HTMLElement).clientHeight &&
			rect.left <= (scrollArea.value as HTMLElement).clientWidth &&
			rect.bottom >= 0 &&
			rect.right >= 0;

		await registerWatcher();

		// Vigilamos la posición del elemento para registrar el observer si es que el canvas cambia de posición
		watch(position, registerWatcher);
	});
}
