import { until, useDebounceFn, useWindowSize } from '@vueuse/core';
import { onMounted, Ref, ref, watch } from 'vue';

import Element from '@/Classes/Element';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useEditorMode } from '@/composables/useEditorMode';

export const useSmartSelection = (element: Ref<Element>) => {
	const smartSelection = ref(false);

	const { width: screenWidth, height: screenHeight } = useWindowSize();
	const { action } = useInteractions();
	const { isAdminMode } = useEditorMode();

	const updateSmartSelection = useDebounceFn(async () => {
		await until(action).toBe('idle');
		const { width: elWidth, height: elHeight } = element.value.domNode()?.getBoundingClientRect() || {
			width: 0,
			height: 0,
		};

		smartSelection.value = elWidth > screenWidth.value / 8 || elHeight > screenHeight.value / 8;
	}, 500);

	if (element.value.type === 'shape' && !isAdminMode.value) {
		watch([screenWidth, screenHeight, () => element.value.size.width], () => updateSmartSelection());
		onMounted(() => updateSmartSelection());
	}

	return {
		smartSelection,
	};
};
