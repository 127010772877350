// Bugsnag
import Bugsnag from '@bugsnag/js';
// Classes
import { findIndex } from 'lodash-es';
import { computed, Ref, ref } from 'vue';

// Classes
import { getArtboards } from '@/api/DataApiClient';
import Element from '@/Classes/Element';
import Page from '@/Classes/Page';
// Composables
import { usePage } from '@/composables/page/usePage';
import { useArtboard } from '@/composables/project/useArtboard';
import { useAuth } from '@/composables/useAuth';
import { useEditorClipboard } from '@/composables/useEditorClipboard';
import { useZoom } from '@/composables/useZoom';
// Stores
import { useHistoryStore } from '@/stores/history';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
// Types
import { Position, Unit } from '@/Types/types';
// Utils
import TemplateLoader from '@/utils/TemplateLoader';

export const useProject = () => {
	const store = useMainStore();
	const history = useHistoryStore();
	const project = useProjectStore();

	const temporalRefPage = ref(Page.createDefault());

	const artboard = useArtboard();
	const { clone } = useEditorClipboard();
	const { fitZoomScale } = useZoom();
	const { addElement } = usePage(temporalRefPage as Ref<Page>);

	const addPage = (page: Page, after?: Page) => {
		if (after) {
			const indexOfReference = project.pages.indexOf(after);
			project.pages.splice(indexOfReference + 1, 0, page);
			return;
		}
		project.pages.push(page);
	};

	const movePage = (page: Page, position: number) => {
		const currentIndex = findIndex(project.pages, page);

		const prevValue = project.pages[position];

		project.$patch(() => {
			project.pages[position] = page;
			project.pages[currentIndex] = prevValue;

			// Ya que es  un cambio de posición no esta siendo detectado
			// forzamos guardado
			project.saveState();
		});
		Bugsnag.leaveBreadcrumb(`Move page ${page.id} from ${currentIndex} to ${position}`);
	};

	const addPages = (pages: Page[]) => {
		pages.forEach((page) => addPage(page));
	};

	const addEmptyPage = (after?: Page) => {
		const emptyPage = Page.create();
		addPage(emptyPage, after);
		Bugsnag.leaveBreadcrumb(`Add new page : ${emptyPage.id}`);
	};

	const clonePage = (page: Page) => {
		const { name, background, sourceId, backgroundImageId } = page;
		const newPage = Page.create({ name: `${name} copy`, background, sourceId, backgroundImageId });

		temporalRefPage.value = newPage;
		Object.values(page.elements).forEach((el) => {
			const cloned = clone(el);

			if (page.backgroundImageId === el.id) {
				newPage.backgroundImageId = cloned.id;
			}

			addElement(cloned);
		});

		return newPage;
	};

	const copyPage = (page: Page) => {
		const newPage = clonePage(page);

		addPage(newPage, page);
		Bugsnag.leaveBreadcrumb(`Add page copy : ${newPage.id}`);
	};

	const removePage = (page: Page) => {
		if (project.pages.length === 1) return;
		project.pages = project.pages.filter((toRemove) => toRemove.id !== page.id);

		if (page.id === store.activePageId) {
			store.setActivePage(project.pages[0] as Page);
		}
		Bugsnag.leaveBreadcrumb(`Remove page: ${page.id}`);
	};

	const removeAllPages = () => (project.pages = []);

	const getPageFromElement = (element: Element): Page | undefined => {
		return project.pages.find((page) => page.elements.find((el) => el.id === element.id)) as Page | undefined;
	};

	const getPageFromDom = (canvas: HTMLElement): Page | undefined => {
		const id = canvas.id.substring(7);
		return project.pages.find((page) => page.id === id) as Page | undefined;
	};

	const appendTemplate = async (slug: string) => {
		const { pages } = await TemplateLoader.fromSlug(slug);
		addPages(pages);
	};

	const { isLogged } = useAuth();
	const replaceTemplate = async (slug: string) => {
		store.finishedLoading = false;

		const { pages, templateData } = await TemplateLoader.fromSlug(slug);
		project.flaticonSearch = templateData.flaticonSearch;
		const { width, height, unit } = templateData.artboard;

		if (!isLogged.value && store.userVector) {
			store.userVector = null;
		}

		// Si no estamos en una plantilla del usuario cambiamos la url
		if (!store.userVector) {
			window.history.replaceState('', '', `/edit/${slug}`);
		}

		project.$patch(() => {
			project.sourceVectorId = templateData.id;
			removeAllPages();
			artboard.setArtboardSize(width, height, unit);
			addPages(pages);
		});

		fitZoomScale();

		store.$patch(() => {
			store.activePageId = pages[0].id;
			store.finishedLoading = true;
		});

		// si no había cambios y era plantilla nueva, empezamos de 0
		// el autoguardado ya se encarga de crear el estado inicial de nuevo
		if (history.states.length === 1 && !store.userVector) {
			history.states = [];
		}
	};

	const setArtboardFromUrl = async () => {
		const params = new URLSearchParams(location.href.split('?')[1]);
		const hasCustomSizeInUrl = params.get('width') && params.get('height') && params.get('unit');
		const hasCustomArtboardInUrl = params.get('size');

		let height = null;
		let width = null;
		let unit = null;

		if (hasCustomSizeInUrl) {
			width = !Number.isNaN(Number(params.get('width'))) ? Number(params.get('width')) : 500;
			height = !Number.isNaN(Number(params.get('height'))) ? Number(params.get('height')) : 500;
			unit = ['px', 'mm'].includes(params.get('unit') as string) ? (params.get('unit') as Unit) : 'px';
		} else if (hasCustomArtboardInUrl) {
			const artboards = await getArtboards();
			const artboardName = params.get('size');

			if (!artboards.length) return false;

			const artboardData = artboards.find((artboard) => artboard.name === artboardName);

			height = artboardData?.height;
			width = artboardData?.width;
			unit = artboardData?.unit;
		}

		if (!height || !width || !unit) {
			return false;
		}

		artboard.setArtboardSize(width, height, unit);
		return true;
	};

	const canMoveDown = (page: Page) => {
		const position = findIndex(project.pages, page);
		return project.pages.length > 1 && position < project.pages.length - 1;
	};

	const canMoveUp = (page: Page) => {
		const position = findIndex(project.pages, page);
		return project.pages.length > 1 && position > 0;
	};
	const findPosition = (page: Page) => {
		return findIndex(project.pages, page);
	};

	const totalPages = computed(() => {
		return project.pages.length;
	});

	const getElementFromDom = (element: HTMLElement): Element | undefined => {
		return project.pages.flatMap((t) => Object.values(t.elements)).find((e) => element.id.includes(e.id));
	};

	const getPageElementUnderMouse = (screenPosition: Position): Page | null => {
		const found = document.elementFromPoint(screenPosition.x, screenPosition.y);
		if (!found) return null;
		const pageElement = found.closest('[id^="interactive-canvas-"');
		if (!pageElement) return null;
		const pageUnderMouse = project.pages.find((p) => pageElement.id.endsWith(p.id)) as Page;
		if (!pageUnderMouse) return null;
		return pageUnderMouse;
	};

	return {
		...artboard,
		addPage,
		addEmptyPage,
		clonePage,
		copyPage,
		removePage,
		removeAllPages,
		getPageFromElement,
		getPageFromDom,
		appendTemplate,
		replaceTemplate,
		movePage,
		findPosition,
		canMoveDown,
		canMoveUp,
		totalPages,
		getElementFromDom,
		setArtboardFromUrl,
		addPages,
		getPageElementUnderMouse,
	};
};
