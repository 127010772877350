import { computed } from 'vue';

import { useEditorMode } from '@/composables/useEditorMode';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { Size, Unit } from '@/Types/types';

export const useArtboard = () => {
	const { isPhotoMode } = useEditorMode();
	const { clearSelection } = useMainStore();
	const project = useProjectStore();

	// Valores max y min en px
	const MAX_ARTBOARD_SIZE = 17401196; // 4171 x 4171 px

	const MAX_ARTBOARD_SIZE_PHOTO_MODE = 4840000; // 2200 x 2200 px

	const MIN_ARTBOARD_SIZE = 100; // 10 x 10 px

	const MM_TO_PX = 11.811;

	const maxArtboardSize = computed(() => (isPhotoMode.value ? MAX_ARTBOARD_SIZE_PHOTO_MODE : MAX_ARTBOARD_SIZE));

	const artboardSizeInPx = computed(() => {
		if (project.unit === 'mm') {
			return convertMmToPx(project.size.width, project.size.height);
		}

		return project.size;
	});

	const artboardSizeInMm = computed(() => {
		if (project.unit === 'px') {
			return convertPxToMm(project.size.width, project.size.height);
		}

		return project.size;
	});

	const aspectRatio = computed(() => project.size.width / project.size.height);

	const isLandscape = computed(() => project.size.width >= project.size.height);
	const isRotable = computed(() => project.size.width !== project.size.height);
	const ratio = computed(() => project.size.width / project.size.height);

	const convertMmToPx = (width: number, height: number): Size => {
		return {
			width: Math.round(width * MM_TO_PX),
			height: Math.round(height * MM_TO_PX),
		};
	};

	const convertPxToMm = (width: number, height: number): Size => {
		return {
			width: Math.round(width / MM_TO_PX),
			height: Math.round(height / MM_TO_PX),
		};
	};

	const setArtboardSize = (width: number, height: number, unit: Unit, allowInvalidSize = false) => {
		const hasChanges = width !== project.size.width || height !== project.size.height || unit !== project.unit;
		const hasValidSize = isValidSize(width, height, unit);

		if (!hasChanges) return;
		if (!hasValidSize && !allowInvalidSize) throw new Error('Proposed size and unit are invalid.');

		clearSelection();

		project.$patch((state) => {
			state.size = {
				width,
				height,
			};
			state.unit = unit;
		});
	};

	const rotateArtboard = () => {
		if (project.size.height !== project.size.width) {
			setArtboardSize(project.size.height, project.size.width, project.unit);
		}
	};

	const isValidSize = (width: number, height: number, unit: Unit): boolean => {
		const realWidth = width * (unit === 'mm' ? MM_TO_PX : 1);
		const realHeight = height * (unit === 'mm' ? MM_TO_PX : 1);

		const areSidesBiggerThanMin =
			realWidth >= Math.sqrt(MIN_ARTBOARD_SIZE) && realHeight >= Math.sqrt(MIN_ARTBOARD_SIZE);

		const isSmallArtboard = realWidth * realHeight < MIN_ARTBOARD_SIZE;
		const isBigArtboard = realHeight * realWidth > maxArtboardSize.value;

		return areSidesBiggerThanMin && !isSmallArtboard && !isBigArtboard;
	};

	return {
		maxArtboardSize,
		MIN_ARTBOARD_SIZE,
		MM_TO_PX,
		artboardSizeInPx,
		artboardSizeInMm,
		aspectRatio,
		isLandscape,
		isRotable,
		ratio,
		isValidSize,
		setArtboardSize,
		rotateArtboard,
	};
};
