<script lang="ts" setup>
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useAuth } from '@/composables/useAuth';
// Stores
import { useProjectStore } from '@/stores/project';
const project = useProjectStore();

const { user } = useAuth();
</script>

<template>
	<div v-if="user?.isAdmin" class="border-t border-gray-600 lg:mt-4 lg:pt-4">
		<a
			:href="`/nova/resources/vectors/${project.sourceVectorId}`"
			target="_blank"
			class="flex h-14 flex-col items-center justify-center rounded bg-blue-500 px-6 text-white focus:outline-none lg:h-16 lg:w-16 lg:px-0"
		>
			<SvgIcon name="blank" class="mb-1 h-6 w-6 fill-current lg:h-5 lg:w-5" />
			<span class="text-2xs leading-normal">Nova</span>
			<span class="text-xs"> Id: {{ project.sourceVectorId }} </span>
		</a>
	</div>
</template>
