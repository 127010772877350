import { Overlay } from '@/Classes/Overlay';
import { SerializableClass } from '@/Classes/SerializableClass';

export class Filter extends SerializableClass {
	name: string | null;
	contrast: number | null;
	brightness: number | null;
	saturate: number | null;
	sepia: number | null;
	grayscale: number | null;
	invert: number | null;
	hueRotate: number | null;
	blur: number | null;

	overlay: Overlay | null;

	constructor(
		contrast: number | null = null,
		brightness: number | null = null,
		saturate: number | null = null,
		sepia: number | null = null,
		grayscale: number | null = null,
		invert: number | null = null,
		hueRotate: number | null = null,
		blur: number | null = null,
		overlay: Overlay | null = null
	) {
		super();
		this.name = '';
		this.contrast = contrast;
		this.brightness = brightness;
		this.saturate = saturate;
		this.sepia = sepia;
		this.grayscale = grayscale;
		this.invert = invert;
		this.hueRotate = hueRotate;
		this.blur = blur;
		this.overlay = overlay;
	}

	toCssFilter(): string {
		const filters: string[] = [];

		if (this.contrast && this.contrast !== 100) filters.push(`contrast(${this.contrast}%)`);
		if (this.brightness && this.brightness !== 100) filters.push(`brightness(${this.brightness}%)`);
		if (this.saturate && this.saturate !== 100) filters.push(`saturate(${this.saturate}%)`);
		if (this.sepia) filters.push(`sepia(${this.sepia}%)`);
		if (this.grayscale) filters.push(`grayscale(${this.grayscale}%)`);
		if (this.invert) filters.push(`invert(${this.invert}%)`);
		if (this.hueRotate) filters.push(`hue-rotate(${this.hueRotate}deg)`);
		if (this.blur) filters.push(`blur(${this.blur}px)`);

		return filters.join(' ');
	}

	toSvgFilter(id: string): string {
		const filters: string[] = [];

		if (this.blur) {
			filters.push(`
				<feGaussianBlur stdDeviation="${this.blur}" />
					<feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 9 0'></feColorMatrix>
    		<feComposite in2='SourceGraphic' operator='in'></feComposite>
			`);
		}

		if (filters.length === 0) {
			return '';
		}

		return `
        <filter id="filter-${id}">
        ${filters.join('\n')}
      	</filter>
    	</defs>
		</svg>`;
	}

	static unserialize(data: any): Filter {
		return new Filter(
			data.contrast,
			data.brightness,
			data.saturate,
			data.sepia,
			data.grayscale,
			data.invert,
			data.hueRotate,
			data.blur,
			data.overlay ? Overlay.unserialize(data.overlay) : undefined
		);
	}
}
