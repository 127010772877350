<script lang="ts" setup>
import { toRef } from 'vue';

import Line from '@/Classes/Line';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import LineMarkers from '@/components/selection/panels/LineMarkers.vue';
import LineStroke from '@/components/selection/panels/LineStroke.vue';
import LineStyle from '@/components/selection/panels/LineStyle.vue';
import OrderMenu from '@/components/selection/panels/OrderMenu.vue';
import PositionMenu from '@/components/selection/panels/PositionMenu.vue';
import OpacitySlider from '@/components/selection/toolbars/OpacitySlider.vue';
import TransformMenu from '@/components/selection/toolbars/TransformMenu.vue';
import { useLine } from '@/composables/element/line/useLine';
import { useGroup } from '@/composables/group/useGroup';
import { useMainStore } from '@/stores/store';

const store = useMainStore();

const props = defineProps<{ element: Line }>();

const element = toRef(props, 'element');
const { hasMarkers } = useLine(element);
const { isGrouped } = useGroup(element);

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<div id="line-panel">
		<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
			<h2 class="text-xl font-semibold text-gray-100">Edit Line & Arrow</h2>
			<button
				class="text-gray-100 hover:text-white focus:outline-none"
				data-testid="close-panel"
				@click="onCloseEditPanel"
			>
				<SvgIcon name="cross" class="h-5 w-5" />
			</button>
		</div>

		<div data-testid="sidebar-panel" class="flex flex-col">
			<template v-if="!isGrouped">
				<PositionMenu :element="element" />
				<OrderMenu :element="element" />
			</template>

			<!-- Edition -->
			<div class="w-2/3">
				<TransformMenu :element="element" :allow-height="false" :allow-keep-proportion="false" />
			</div>

			<LineStroke :element="element" />

			<LineStyle :element="element" />

			<LineMarkers v-if="hasMarkers" :element="element" />

			<OpacitySlider :element="element" :opacity="element.opacity" />
		</div>
	</div>
</template>
