<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { computed, ref } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
import Palette from '@/components/Common/Palette.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useProjectColors } from '@/composables/useProjectColors';
import { useMainStore } from '@/stores/store';
import { Color, ColorPalette } from '@/Types/colorsTypes';

const props = defineProps<{ selected: Color; hideGradient?: boolean }>();
const emit = defineEmits<{ (e: 'change', color: Color): void }>();
const palette = ref(ColorPalette.document);
const { colors: templateColors } = useProjectColors();
const store = useMainStore();

const presetColors = computed(() => {
	if (palette.value === ColorPalette.document) {
		// Devolvemos los gradientes si el color que vamos a cambiar es un color sólido
		// si es un gradiente devolvemos los colores que componen los gradientes

		return (
			templateColors.value
				.map((color) => {
					if (color.isGradient() && props.selected.isGradient()) {
						return (color as GradientColor).stops.map((c) => SolidColor.fromObject(c));
					}

					return color;
				})
				.flatMap((c) => c)
				.filter((c) => {
					// Si tenemos desactivada la pestaña de degradados
					// los ocultamos en la paleta de colores
					if (props.hideGradient && c.isGradient()) {
						return false;
					}

					// Si el elemento seleccionado es un texto, eliminamos los colores gradientes
					if (store.selection.length && store.selection[0].type === 'text' && c.isGradient()) {
						return false;
					}

					return true;
				})
				// Como podemos estar devolviendo SolidColors que ya están en otros gradientes hacemos que queden colores unicos
				.filter((color, index, colors) => index === colors.findIndex((c) => c.toCssString() === color.toCssString()))
		);
	}

	// Freepik palette
	return SolidColor.getFreepikColors();
});

const open = ref(false);
const selector = ref();
const togglePaletteSelect = () => (open.value = !open.value);
const setPalette = (type: ColorPalette) => {
	palette.value = type;
	open.value = false;
};
onClickOutside(selector, () => (open.value = false));
</script>

<template>
	<Teleport to=".vc-sketch-presets">
		<div class="relative col-span-6">
			<button
				class="hidden w-full cursor-pointer items-center justify-between p-1 text-xs font-bold uppercase text-gray-300 hover:text-gray-100 lg:flex"
				@click="togglePaletteSelect"
			>
				<div>{{ palette }} colors</div>
				<SvgIcon name="arrow" class="h-4 w-4" />
			</button>

			<div
				v-if="open"
				ref="selector"
				class="absolute top-6 right-0 z-20 flex flex-col rounded bg-white/90 py-1 text-gray-800 shadow-xl backdrop-blur"
			>
				<button
					v-for="colorPalette in Object.values(ColorPalette)"
					:key="colorPalette"
					class="cursor-pointer py-1 px-4 text-left text-sm text-gray-700 hover:bg-gray-100/20 hover:text-gray-800"
					@click="setPalette(colorPalette)"
				>
					<span>{{ colorPalette }}</span>
				</button>
			</div>
		</div>
		<div
			class="col-span-6 overflow-auto pb-1 text-gray-700 scrollbar-thin scrollbar-thumb-gray-600 lg:max-h-24 lg:pb-0 lg:pr-1 lg:text-gray-800"
		>
			<div class="flex grid-cols-6 gap-2 lg:grid">
				<Palette :colors="presetColors" :selected="selected" @change="emit('change', $event)"></Palette>
			</div>
		</div>
	</Teleport>
</template>
