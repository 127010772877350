<script lang="ts" setup>
import { computed, ref } from 'vue';

import Page from '@/Classes/Page';
import { SolidColor } from '@/Classes/SolidColor';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useProject } from '@/composables/project/useProject';
import { useAuth } from '@/composables/useAuth';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { DownloadFormat } from '@/Types/types';

const project = useProjectStore();
const store = useMainStore();

const minimized = ref(false);
const visible = ref(true);
const { isMobile, isWebview } = useDeviceInfo();
const { isLogged } = useAuth();
const { clonePage } = useProject();

const continueWorking = () => {
	if (isWebview.value) {
		return;
	}

	if (isMobile.value) {
		visible.value = false;
		return;
	}

	minimized.value = true;
};

const firstPage = computed(() => {
	const page = clonePage(project.pages[0] as Page);

	if (store.downloadingFormat === DownloadFormat.tpng) {
		page.background = SolidColor.transparent();
	} else if (store.downloadingFormat === DownloadFormat.jpg) {
		page.background = page.background.withoutAlpha();
	}

	return page;
});
</script>

<template>
	<Modal :open="visible" :overlay="!minimized" :class="minimized ? 'pointer-events-none' : ''">
		<div
			class="fixed flex flex-col-reverse overflow-hidden bg-green-500 transition-all duration-300 lg:flex-row"
			:class="{
				'top-2 right-4 h-10 w-36 rounded': minimized && !isLogged,
				'top-2 right-20 h-10 w-36 rounded': minimized && isLogged,
				'top-[50%] right-[50%] w-[300px] translate-x-[50%] -translate-y-[50%] rounded-md bg-white lg:w-[600px]':
					!minimized,
			}"
		>
			<div
				class="flex flex-col"
				:class="
					minimized
						? 'w-full items-center justify-center py-3 px-6'
						: 'items-center justify-center bg-white p-4 lg:w-1/2 lg:py-10 lg:px-8'
				"
			>
				<span
					class="flex items-center font-bold"
					:class="minimized ? 'text-base text-white' : 'mb-2 text-xl text-gray-800 lg:text-2xl'"
				>
					<SvgIcon
						name="spinner"
						class="mr-2 animate-spin"
						:class="minimized ? 'h-4 w-4 text-white' : 'h-5 w-5 text-blue-500'"
					/>
					Downloading
				</span>
				<p class="text-gray-600" :class="minimized ? 'hidden' : 'lg:text-lg'">We are preparing your file!</p>
				<template v-if="!minimized">
					<div class="mt-4 flex lg:mt-6">
						<button
							class="h-12 w-full rounded border-2 border-blue-500 px-6 text-sm font-semibold leading-tight text-blue-500 hover:bg-blue-500 hover:text-white lg:w-auto lg:px-8 lg:text-base"
							@click="continueWorking"
						>
							Continue working
						</button>
					</div>
				</template>
			</div>
			<div
				class="relative overflow-hidden bg-gradient-to-br from-[#193A5A] to-[#091F37]"
				:class="minimized ? 'w-0' : 'h-40 lg:h-64 lg:w-1/2'"
			>
				<div class="absolute inset-0 z-10 m-6 flex items-center justify-center lg:m-8">
					<Canvas class="falling overflow-hidden rounded-xl" preview :page="firstPage" />
				</div>
				<SvgIcon name="illustrations-halo1" class="halo halo__1 absolute top-6 left-8 z-20 w-1 opacity-0" />
				<SvgIcon name="illustrations-halo1" class="halo halo__2 absolute bottom-8 right-8 z-20 w-1 opacity-0" />
				<SvgIcon name="illustrations-star-medium" class="star star__medium absolute top-0 right-0 h-full w-full" />
				<SvgIcon name="illustrations-star-big" class="star star__big absolute top-0 right-0 h-full w-full" />
			</div>
		</div>
	</Modal>
</template>

<style lang="scss" scoped>
.star {
	animation: star-animation 1s infinite linear;

	@keyframes star-animation {
		0% {
			transform: translateY(100%);
		}

		100% {
			transform: translateY(-100%);
		}
	}

	&__medium {
		animation-duration: 1.5s;
	}

	&__big {
		animation-duration: 2s;
	}
}

.halo {
	animation: halo 3s infinite ease-in;

	@keyframes halo {
		0% {
			opacity: 0;
			transform: translateY(0);
		}

		15% {
			opacity: 0.2;
		}

		35% {
			opacity: 0.2;
		}

		50% {
			transform: translateY(-30px);
			opacity: 0;
		}
	}

	&__1 {
		animation-delay: 1s;
	}

	&__2 {
		animation-delay: 1.5s;
	}
}

.falling {
	animation: 2s infinite ease-in-out falling, 6s infinite ease-in-out floating;
	animation-delay: 0.5s, 2.5s;
	opacity: 0;

	@keyframes falling {
		0% {
			transform: translateY(-100px) rotate(5deg);
			opacity: 1;
		}

		25% {
			transform: translateY(10px) rotate(-2deg);
			opacity: 1;
		}

		75% {
			transform: translateY(-5px) rotate(0);
			opacity: 1;
		}

		100% {
			transform: translateY(0) rotate(0);
			opacity: 1;
		}
	}
	@keyframes floating {
		0% {
			transform: translateY(0) rotate(0);
		}

		25% {
			transform: translateY(5px) rotate(-2deg);
		}

		75% {
			transform: translateY(5px) rotate(2deg);
		}

		100% {
			transform: translateY(0) rotate(0);
		}
	}
}
</style>
