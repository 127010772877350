<script setup lang="ts">
import { computed, toRef } from 'vue';
import { Illustrator } from '@/Classes/Illustrator';
import { useMainStore } from '@/stores/store';

// Props
const props = defineProps<{ element: Illustrator }>();

// Store
const store = useMainStore();

// Data
const element = toRef(props, 'element');

const selection = computed(() => {
	const selector = Array.from(store.illustratorSelection)
		.map((id) => `[data-illustrator-link="${id}"]`)
		.join(', ');

	if (!selector.length) return '';

	return `${selector} {
		stroke: currentColor !important;
    --tw-text-opacity: 1 !important;
    color: rgb(18 115 235 / var(--tw-text-opacity)) !important;
	}`;
});

const moved = computed(() => {
	const selector = Array.from(store.illustratorElementsMoved)
		.map((id) => `[data-illustrator-link="${id}"]`)
		.join(', ');

	if (!selector.length) return '';

	return `${selector} { display: none; }`;
});
</script>

<template>
	<component :is="'style'" type="text/css">
		{{ selection }}
		{{ moved }}
	</component>
	<svg :viewBox="element.viewbox" width="100%" height="100%" preserveAspectRatio="none" v-html="element.content"></svg>
</template>
