<script lang="ts" setup>
// Vue & Packages
import { useDebounceFn } from '@vueuse/shared';
import { computed, InputHTMLAttributes, ref } from 'vue';

// Utils
import MathTools from '@/utils/MathTools';

// Props
const props = defineProps<{
	max?: number;
	min?: number;
	placeholder?: string;
	nullable?: boolean;
	step?: number;
	value: number | number[];
}>();

// Emits
const emit = defineEmits(['update', 'trackTyping', 'trackStep']);

// Template refs
const numberInput = ref();

const pressing: {
	interval: undefined | ReturnType<typeof setInterval>;
	timeout: undefined | ReturnType<typeof setTimeout>;
} = {
	interval: undefined,
	timeout: undefined,
};

// Computeds
const computedMax = computed(() => (typeof props.max === 'number' ? props.max : Infinity));
const computedMin = computed(() => (typeof props.min === 'number' ? props.min : -Infinity));
const computedStep = computed(() => props.step || 1);
const computedValue = computed(() => {
	if (Array.isArray(props.value)) {
		return props.value.length === 1
			? MathTools.clamp(MathTools.toFixedOrInt(props.value[0]), computedMin.value, computedMax.value)
			: props.value.map((v: number) =>
					MathTools.clamp(MathTools.toFixedOrInt(v), computedMin.value, computedMax.value)
			  );
	}

	return MathTools.clamp(MathTools.toFixedOrInt(props.value), computedMin.value, computedMax.value);
});

// Methods
const onUpdate = useDebounceFn((e: KeyboardEvent) => {
	emit('update', MathTools.clamp((e.target as InputHTMLAttributes).value, computedMin.value, computedMax.value));
	if (numberInput.value) {
		emit('trackTyping', numberInput.value.value);
	}
}, 333);

const onUpdateOneStep = (dir: number) => {
	if (Array.isArray(computedValue.value) || Array.isArray(props.value)) {
		emit('update', dir > 0 ? 'plus' : 'minus');
		return;
	}

	const num = computedValue.value + dir * computedStep.value;
	emit('update', MathTools.clamp(num, computedMin.value, computedMax.value));
	emit('trackStep', dir);
};

const onStart = (dir: number) => {
	emit('trackStep', dir);

	pressing.timeout = setTimeout(() => {
		pressing.interval = setInterval(() => {
			if (Array.isArray(props.value)) {
				emit('update', dir > 0 ? 'plus' : 'minus');
			} else {
				const value = Array.isArray(props.value) ? props.value[0] : props.value;
				if ((dir === -1 && value <= computedMin.value) || (dir === 1 && value >= computedMax.value)) {
					onStop();
					return;
				}
				emit('update', value + dir * computedStep.value);
			}
		}, 50);
	}, 200);
};

const onStop = () => {
	if (pressing.interval) clearInterval(pressing.interval);
	if (pressing.timeout) clearTimeout(pressing.timeout);
};
</script>

<template>
	<div class="group relative">
		<input
			ref="numberInput"
			class="h-full w-full appearance-none rounded bg-gray-900 pl-2 text-gray-300 focus:outline-none"
			type="number"
			name="w"
			:max="computedMax"
			:min="computedMin"
			:step="computedStep"
			:value="computedValue"
			:placeholder="placeholder"
			@change="onUpdate"
			@input="onUpdate"
		/>
		<div
			class="absolute top-0 right-0 flex h-full w-4 flex-col rounded-tr rounded-br bg-gray-800 opacity-0 ring-1 ring-inset ring-gray-900 group-hover:opacity-100 group-focus:opacity-100"
		>
			<span
				class="flex flex-1 cursor-pointer items-center justify-center rounded-tr leading-none text-gray-300 hover:text-white"
				@click="onUpdateOneStep(1)"
				@mousedown="onStart(1)"
				@mouseup="onStop"
				@mouseout="onStop"
				>▴</span
			>
			<span
				class="flex flex-1 rotate-180 transform cursor-pointer items-center justify-center rounded-tl leading-none text-gray-300 hover:text-white"
				@click="onUpdateOneStep(-1)"
				@mousedown="onStart(-1)"
				@mouseup="onStop"
				@mouseout="onStop"
				>▴</span
			>
		</div>
	</div>
</template>
<style lang="sass" scoped>
input[type="number"]
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button
		@apply appearance-none m-0

	-moz-appearance: textfield
</style>
