import { promiseTimeout, watchOnce } from '@vueuse/core';
import { computed, nextTick, Ref, ref, toRef, watch } from 'vue';

import { Text } from '@/Classes/Text';
import { useCircleType } from '@/composables/element/text/useCircleType';
import { useFonts } from '@/composables/useFonts';
import { useMainStore } from '@/stores/store';
import MathTools from '@/utils/MathTools';
import TextTools from '@/utils/TextTools';

export const useCircleText = async (text: Ref<Text>, dom: Ref<HTMLDivElement>, canvasScale: Ref<number>) => {
	const store = useMainStore();
	const { fontLoading } = useFonts();
	const textEditing = toRef(store, 'textEditing');
	const arc = computed(() =>
		text.value.curvedProperties.arc !== null ? Math.abs(text.value.curvedProperties.arc) : null
	);
	const isCurved = computed(() => !!arc.value);
	const temporalClone = ref(document.createElement('div'));
	const scale = computed(() => text.value.scale);
	const fontWeight = computed(() => text.value.fontWeight);
	const fontStyle = computed(() => text.value.fontStyle);
	const fontSize = computed(() => text.value.fontSize);
	const outline = computed(() => text.value.outline);
	const textShadow = computed(() => text.value.textShadow);

	const observerOptions = {
		childList: true,
		attributes: true,
		subtree: true,
		characterData: true,
		attributeFilter: ['style'],
		attributeOldValue: true,
	};

	const prevWidth = ref(text.value.size.width);
	const prevFontSize = ref(0);
	const width = computed(() => text.value.size.width);
	const refreshCurvedText = (mutations: MutationRecord[]) => {
		const withoutChanges = mutations.every(
			(m) => m.type === 'characterData' || m.oldValue === (m.target as HTMLDivElement).getAttribute('style')
		);

		if (textEditing.value && textEditing.value.id !== text.value.id) {
			return;
		}

		if (arc.value === null || (withoutChanges && !textEditing.value)) {
			return;
		}

		// Si la mutación es del width no queremos refrescar el texto ya que este cambio vendrá del propio refresh del texto curvo
		if (prevWidth.value !== text.value.size.width && prevFontSize.value === text.value.fontSize) {
			prevWidth.value = text.value.size.width;
			return;
		}

		prevFontSize.value = text.value.fontSize;
		refresh();
		setRadius(arc.value);
	};

	const observer = new MutationObserver(refreshCurvedText);

	const { init, refresh, destroy, setRadius } = useCircleType(text, temporalClone, canvasScale);

	const createCurvedText = () => {
		const textFinal = text.value.domNode()?.querySelector('.text-element-final');
		if (!textFinal) {
			throw new Error('Text element final not found in DOM');
		}
		observer.observe(textFinal, observerOptions);
		temporalClone.value = textFinal?.cloneNode(true) as HTMLDivElement;
		temporalClone.value.id = 'clone-curved';
		temporalClone.value.classList.remove('opacity-0');
		dom.value.insertAdjacentElement('afterend', temporalClone.value);
		init();
	};

	const destroyCurvedText = () => {
		observer.disconnect();
		destroy();
		temporalClone.value.remove();
	};

	watch(dom, async () => {
		// Si no están las fuentes no queremos hacer nada ya que los cálculos no serían correctos
		if (fontLoading.value) {
			return;
		}

		// Si no es curvo no queremos hacer nada
		if (!isCurved.value) {
			return;
		}
		// No queremos que se genere un history nuevo
		window.fromHistory = true;

		await nextTick();
		createCurvedText();
	});

	// Al cargar el editor tenemos que esperar a que las fuentes estén cargadas para hacer los cálculos con los tamaños correctos
	watchOnce(fontLoading, () => {
		if (!isCurved.value) {
			return;
		}
		if (text.value.curvedProperties.transformCurve) {
			text.value.position.y += text.value.curvedProperties.transformCurve;
		}

		createCurvedText();
	});

	watch(arc, (newArc, prevArc) => {
		const isDestroy = newArc === null;
		const isNew = newArc !== null && prevArc === null;

		if (isDestroy) {
			destroyCurvedText();
			return;
		}

		if (isNew) {
			createCurvedText();
			return;
		}

		// isUpdate
		setRadius(newArc);
	});

	watch(textEditing, async (newVal, oldValue) => {
		if (!isCurved.value) {
			return;
		}

		if (!newVal && oldValue?.id === text.value.id) {
			const textFinal = text.value.domNode()?.querySelector('.text-element-final');
			if (!textFinal) {
				throw new Error('Text element final not found in DOM');
			}

			observer.takeRecords();
			observer.disconnect();
			observer.observe(textFinal, observerOptions);
			await nextTick();
			refresh();
			return;
		}

		if (newVal?.id !== text.value.id) {
			return;
		}

		await nextTick();
		const domElement = document.querySelector(`#editable-${text.value ? text.value.id : undefined}`);
		if (!domElement) {
			throw new Error('Text element final not found in DOM');
		}

		observer.takeRecords();
		observer.disconnect();

		observer.observe(domElement, observerOptions);
		await nextTick();

		refresh();
	});

	watch(scale, (newScale) => {
		if (!isCurved.value) {
			return;
		}
		temporalClone.value.style.transform = `scale(${newScale})`;
	});

	watch(width, (newWidth) => {
		if (!isCurved.value) {
			return;
		}
		temporalClone.value.style.width = `${newWidth / text.value.scale}px`;
	});

	watch([fontStyle, fontWeight], async () => {
		if (!isCurved.value) {
			return;
		}
		// Cuando cambiamos el fontStyle o el fontWeight el que se encarga de pedir la nueva fuente es el navegador
		// no tenemos manera de saber cuando el texto está renderizado con la fuente que queremos, si no esperamos cogeremos
		// medidas incorrectas.
		// Damos algo de tiempo para crear el texto nuevo y destruirlo
		await promiseTimeout(10);
		destroyCurvedText();
		// Y volvemos a generar el texto nuevo ya si con las medidas correctas
		await promiseTimeout(10);
		createCurvedText();
	});

	watch(outline, (newOutline) => {
		temporalClone.value.style.webkitTextStroke = `${newOutline.width}${newOutline.unit || 'px'} ${newOutline.color}`;
	});

	watch(textShadow, (newTextShadow) => {
		temporalClone.value.style.textShadow = TextTools.textShadowToCssString(newTextShadow);
	});

	// Cuando actualizamos el fontsize estamos actualizando el minArc ya que el width es distinto, tenemos que actualizar
	// el arc aplicado en relación para que no se produzca un desfase en la curvatura aplicada
	watch(fontSize, async () => {
		if (!isCurved.value) {
			return;
		}

		const prevMinArc = text.value.curvedProperties.minArc;
		await nextTick();

		const newArc = MathTools.ruleOfThree(
			prevMinArc,
			text.value.curvedProperties.arc!,
			text.value.curvedProperties.minArc
		);
		if (prevMinArc !== newArc) {
			text.value.curvedProperties.arc = newArc;
		}
	});
};
