<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { computed, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import NumberInput from '@/components/Common/NumberInput.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import FontPicker from '@/components/selection/panels/FontPicker.vue';
import WeightPicker from '@/components/selection/toolbars/texts/WeightPicker.vue';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import { useBugsnag } from '@/composables/useBugsnag';
import GAnalytics from '@/utils/GAnalytics';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const {
	lineHeightLabel,
	fontSizeScaled,
	finalFontFamily,
	fontWeight,
	italic,
	fontVariants,
	letterSpacing,
	updateFontSize,
	updateFontFamily,
	updateLineHeight,
	updateFontWeight,
	updateFontStyle,
	updateLetterSpacing,
	rescaleText,
} = useTextStyles(element);
const { breadScrumbWithDebounce } = useBugsnag(element);
const onUpdateRotation = (val: number) => {
	props.element.rotation = val;
	breadScrumbWithDebounce('rotation');
	GAnalytics.track(`${window.event ? window.event.type : 'change'}`, 'Button', 'rotate-input', null);
};

const computedFontWeight = computed(() => `${fontWeight.value[0]}${italic.value[0] === 'italic' ? 'i' : ''}`);

const onUpdate = (val: string) => {
	Bugsnag.leaveBreadcrumb(`Set font style to ${props.element.id}: ${val}`);
	if (val.includes('i')) {
		updateFontWeight(val.split('i')[0]);
		updateFontStyle(true);
	} else {
		updateFontWeight(val);
		updateFontStyle(false);
	}
};

const updateTextFontSize = (fontSize: number | 'plus' | 'minus') => {
	if (element.value.scale !== 1) {
		rescaleText();
	}

	updateFontSize(fontSize);
	breadScrumbWithDebounce('fontSize');
};

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};

const trackFontFamily = (font: string) => {
	updateFontFamily(font);
	GAnalytics.track('click', 'Button', `change-font-family`, null);
};
</script>

<template>
	<div class="mb-2">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Styles</h4>

		<div class="mb-2 flex gap-2">
			<FontPicker :font="finalFontFamily" @change="trackFontFamily">
				<template #default="{ fontFamily, toggle }">
					<button
						data-testid="font"
						class="relative flex h-10 w-full cursor-pointer items-center rounded bg-gray-900 pl-3 pr-4 text-sm text-gray-100 hover:text-gray-700 focus:outline-none lg:hover:text-white"
						@click="toggle"
					>
						<span :style="{ fontFamily: fontFamily }" class="truncate" v-text="fontFamily" />
						<SvgIcon name="arrow" class="absolute top-3 right-3 h-4 w-4 fill-current" />
					</button>
				</template>
			</FontPicker>

			<WeightPicker :options="fontVariants" :selected="computedFontWeight" @update="onUpdate" />
		</div>

		<div class="flex items-center gap-2">
			<div class="flex flex-col">
				<NumberInput
					data-testid="input-fontsize"
					class="h-10 w-full text-sm"
					:min="1"
					:value="fontSizeScaled"
					:placeholder="'Mix'"
					@track-typing="trackTypingEvent('text-font-size')"
					@track-step="trackStepEvent('text-font-size')"
					@update="updateTextFontSize"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Font size</label>
			</div>
			<div class="flex flex-col">
				<NumberInput
					data-testid="input-lineheight"
					class="h-10 w-full text-sm"
					:min="0.1"
					:step="0.1"
					:value="lineHeightLabel"
					:placeholder="'Mix'"
					@track-typing="trackTypingEvent('text-line-height')"
					@track-step="trackStepEvent('text-line-height')"
					@update="updateLineHeight"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Line height</label>
			</div>
			<div class="flex flex-col">
				<NumberInput
					data-testid="input-spacing"
					class="h-10 text-sm"
					:step="0.1"
					:value="letterSpacing"
					:placeholder="'Mix'"
					@track-typing="trackTypingEvent('text-letter-spacing')"
					@track-step="trackStepEvent('text-letter-spacing')"
					@update="updateLetterSpacing"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Spacing</label>
			</div>
			<div class="flex flex-col">
				<NumberInput
					data-testid="input-rotation"
					class="h-10 text-sm"
					:value="element.rotation"
					@track-typing="trackTypingEvent('text-rotation')"
					@track-step="trackStepEvent('text-rotation')"
					@update="(rotation, $event) => onUpdateRotation(rotation, $event)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Rotation</label>
			</div>
		</div>
	</div>
</template>
