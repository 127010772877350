<script lang="ts" setup>
import { toRef } from 'vue';

import Storyset from '@/Classes/Storyset';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import CopyToolbar from '@/components/selection/toolbars/CopyToolbar.vue';
import FlipMenuToolbar from '@/components/selection/toolbars/FlipMenuToolbar.vue';
import MoreToolsToolbar from '@/components/selection/toolbars/MoreToolsToolbar.vue';
import OrderToolbar from '@/components/selection/toolbars/OrderToolbar.vue';
import RemoveToolbar from '@/components/selection/toolbars/RemoveToolbar.vue';
import ChangeBackgroundButton from '@/components/selection/toolbars/storyset/ChangeBackgroundButton.vue';
import { useStorysetColors } from '@/composables/element/storyset/useStorysetColors';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

const props = defineProps<{ element: Storyset }>();

const element = toRef(props, 'element');

const { updateColor } = useStorysetColors(element);
const { isMobile } = useDeviceInfo();

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.Storyset) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.Storyset;
};
</script>

<template>
	<div>
		<ChangeBackgroundButton class="bg-storyset" :element="element" />
		<div
			class="order-first mr-2 h-10 border-r border-gray-600 py-1 pl-2 pr-4 lg:order-none lg:ml-1 lg:h-5 lg:w-5 lg:border-0 lg:p-0"
		>
			<ColorPicker
				parent=".toolbar"
				class="h-8 w-8 lg:h-5 lg:w-5"
				:color="element.mainColor"
				:hide-alpha="true"
				@change="updateColor"
			/>
		</div>
		<FlipMenuToolbar :element="element" />

		<hr class="mx-1 hidden h-5 w-px bg-white opacity-25 lg:block" />

		<OrderToolbar v-if="isMobile" :element="element" />

		<CopyToolbar v-if="isMobile" :element="element" />

		<RemoveToolbar :element="element" />

		<MoreToolsToolbar :panel="EditPanels.Storyset" @more-tools="onClickMoreTools" />
	</div>
</template>
