<script lang="ts" setup>
// Template refs
import { onKeyStroke, useFullscreen, useScroll, useWindowSize } from '@vueuse/core';
import { computed, onMounted, ref, toRef, watch } from 'vue';

import Project from '@/Classes/Project';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';

const props = defineProps<{
	project: Project;
	loaded?: boolean;
	fullscreen?: boolean;
	page: number;
}>();

const project = toRef(props, 'project');

const container = ref();
//Refs
const widthToSum = ref(0);
// const activePage = ref(0);
const activePage = toRef(props, 'page');
// Composables
const { isTouch } = useDeviceInfo();
const { x: scrollX } = useScroll(container);
const { isFullscreen, enter } = useFullscreen(container);
const { width: windowWidth } = useWindowSize();

// Computeds
const totalPages = computed(() => project.value.pages.length);
const activePageTouch = computed(() => Math.round(scrollX.value / widthToSum.value) + 1);
const activePageToShow = computed(() => {
	if (isTouch.value) {
		return activePageTouch.value;
	}

	return activePage.value + 1;
});

// Lifehooks
onMounted(() => (widthToSum.value = container.value.offsetWidth));

watch(activePage, (newActive) => {
	container.value.scrollLeft = widthToSum.value * newActive;
});

// Si hace resize a la pantalla actualizamos el valor del width
watch(windowWidth, () => (widthToSum.value = container.value.offsetWidth));

onKeyStroke(['ArrowLeft'], (e) => {
	e.preventDefault();
	prevSlide();
});
onKeyStroke(['ArrowRight'], (e) => {
	e.preventDefault();
	nextSlide();
});

// Methods
const prevSlide = () => {
	if (activePage.value === 0) return;
	emit('activePage', activePage.value - 1);
};

const nextSlide = () => {
	if (activePage.value === totalPages.value - 1) return;
	emit('activePage', activePage.value + 1);
};

const emit = defineEmits(['activePage', 'fullscreenExit']);

watch(
	toRef(props, 'fullscreen'),
	() => {
		if (props.fullscreen) enter();
	},
	{ immediate: true }
);

watch(isFullscreen, () => {
	if (!isFullscreen.value) emit('fullscreenExit');
});
</script>

<template>
	<div
		ref="container"
		class="flex flex-1 snap-x snap-mandatory overflow-hidden"
		:class="{
			'scroll-smooth': !isTouch,
			'fixed inset-0 z-50 bg-gray-900': isFullscreen,
		}"
	>
		<div
			v-for="p in project.pages"
			:key="p.id"
			class="mr-2 flex h-full w-full shrink-0 snap-center transition-opacity duration-75"
			:class="loaded === false ? 'opacity-0' : ''"
		>
			<Canvas :page="p" preview fit-to="height" class="h-full w-full" />
		</div>

		<!--	Prev page button-->
		<button
			v-show="activePageToShow > 1"
			class="fixed top-0 bottom-0 left-0 z-10 hidden w-1/2 items-center justify-start text-gray-600 hover:text-gray-100 lg:flex"
			:class="fullscreen ? 'opacity-0' : ''"
			@click="prevSlide"
		>
			<span data-testid="back-btn-viewer" class="ml-8 h-16 w-16 rounded-full bg-realblack/75">
				<SvgIcon name="arrow" class="h-full w-full rotate-90 transform fill-current px-2 pb-2 pt-3" />
			</span>
		</button>
		<!--	Next page button-->
		<button
			v-show="activePageToShow !== totalPages"
			class="fixed top-0 bottom-0 right-0 z-10 hidden w-1/2 w-32 items-center justify-end text-gray-600 hover:text-gray-100 lg:flex"
			:class="fullscreen ? 'opacity-0' : ''"
			@click="nextSlide"
		>
			<span data-testid="next-btn-viewer" class="mr-8 h-16 w-16 rounded-full bg-realblack/75">
				<SvgIcon name="arrow" class="h-full w-full -rotate-90 transform fill-current px-2 pb-2 pt-3" />
			</span>
		</button>
	</div>
</template>
