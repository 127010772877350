<script lang="ts" setup>
import { computed } from 'vue';

import DownloadButtons from '@/components/export/DownloadButtons.vue';
import SocialShareButtons from '@/components/export/share/SocialShareButtons.vue';
import { useEditorMode } from '@/composables/useEditorMode';

const emits = defineEmits(['error', 'share']);

const props = defineProps<{ selection: string[]; templateDistribution: string }>();

const { isPhotoMode } = useEditorMode();

const downloadButtonsOrder = computed(() => {
	switch (props.templateDistribution) {
		case 'multipages':
			return 'first';
		case 'publish':
			return '2';
		case 'present':
			return 'first';
		default:
			return 'first';
	}
});
// Methods
const onError = () => emits('error');
const share = (socialNetwork: string) => {
	emits('share', socialNetwork);
};
</script>
<template>
	<div class="flex flex-1 flex-col gap-4 p-4 lg:gap-8 lg:p-8">
		<!-- Download Buttons -->
		<DownloadButtons
			:class="`order-${downloadButtonsOrder}`"
			:selection="selection"
			:template-distribution="templateDistribution"
			@error="onError"
		/>

		<hr v-if="!isPhotoMode" class="border-gray-100/50" />

		<!-- Share -->
		<SocialShareButtons
			v-if="!isPhotoMode"
			:class="`order-${downloadButtonsOrder === 'first' ? 'last' : 'first'}`"
			:selection="selection"
			@share="share"
		/>
	</div>
</template>
<style scoped>
.order-last {
	order: 999;
}
</style>
