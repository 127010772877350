<script lang="ts" setup>
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import useNavigation from '@/composables/useNavigation';

const emit = defineEmits(['close']);

const close = () => emit('close');

const { confirmIntendedNavigation } = useNavigation();
</script>

<template>
	<Modal open @close="close">
		<div class="relative flex max-w-lg overflow-hidden rounded">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded-sm bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="close"
			>
				<SvgIcon name="cross" class="h-5 w-5 fill-current text-gray-700 lg:h-6 lg:w-6" />
			</button>
			<div class="flex flex-col bg-white p-8 text-center">
				<h2 class="mt-2 mb-4 text-center text-xl font-semibold text-gray-800 lg:text-2xl">
					Are you sure you want to leave?
				</h2>
				<p class="mb-0 text-center text-gray-600 lg:text-lg">You will lose all changes</p>
				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-10 items-center justify-center rounded border-2 border-gray-200 px-6 font-semibold text-gray-700 hover:border-gray-300 lg:h-12 lg:px-8 lg:text-lg"
						@click="confirmIntendedNavigation"
					>
						Leave
					</button>
					<button
						class="mx-2 flex h-10 items-center justify-center rounded border-2 border-red-500 bg-red-500 px-6 font-semibold text-white hover:border-red-400 hover:bg-red-400 lg:h-12 lg:px-8 lg:text-lg"
						@click="close"
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
