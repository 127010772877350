import TextTools from './TextTools';

class TextSelectionTools {
	// Expresion regular para saber si se está seleccionando
	// el texto completo de izquierda a derecha
	static LEFT_TO_RIGHT_WITH_CHILDREN(sel: Selection) {
		if (
			(sel.focusNode?.textContent || typeof sel.focusNode?.textContent === 'string') &&
			(sel.anchorNode?.textContent || typeof sel.anchorNode?.textContent === 'string')
		) {
			return new RegExp(
				`^${TextTools.replaceSpecialChars(
					sel.anchorNode?.textContent?.substring(sel.anchorOffset, sel.anchorNode.textContent.length)
				)}[\\w\\W]*?${TextTools.replaceSpecialChars(sel.focusNode?.textContent)?.substring(0, sel.focusOffset)}$`
			);
		}
	}
	// Expresion regular para saber si se está seleccionando
	// el texto completo de derecha a izquierda
	static RIGHT_TO_LEFT_WITH_CHILDREN(sel: Selection) {
		if (
			(sel.focusNode?.textContent || typeof sel.focusNode?.textContent === 'string') &&
			(sel.anchorNode?.textContent || typeof sel.anchorNode?.textContent === 'string')
		) {
			return new RegExp(
				`^${TextTools.replaceSpecialChars(
					sel.focusNode?.textContent?.substring(sel.focusOffset, sel.focusNode.textContent.length)
				)}[\\w\\W]*?${TextTools.replaceSpecialChars(sel.anchorNode?.textContent)?.substring(0, sel.anchorOffset)}$`
			);
		}
	}

	// Expresion regular para saber si se está seleccionando el texto completo
	static START_AND_END_WITH_FULL_TEXT(domNode: HTMLElement) {
		if (domNode.textContent) {
			return new RegExp(`^${TextTools.replaceSpecialChars(domNode?.textContent)}$`);
		}
	}

	/**
	 * Retorna true si se ha seleccionado el texto al completo
	 * @param range
	 */
	static detectFullRange(selection: Selection, domNode: HTMLElement): boolean {
		// si el selection no tiene ningun nodo, selección completa
		if (!selection.focusNode) {
			return true;
		}

		const nodes = TextTools.getSelectedNodes();
		const selectionLength = Math.abs(selection.anchorOffset - selection.focusOffset);

		// si no hay nada en selection
		if (!nodes.length) {
			return true;
		}

		/*
		// si solo hay un elemento y no hay texto seleccionado, el parent
		if (nodes.length === 1 && selectionLength === 0) {
			return true;
		}
*/
		// si solo hay un elemento y la selección es de tipo caret, el parent
		if (
			nodes.length === 1 &&
			selectionLength === 0 &&
			selection.type.toLowerCase() === 'caret' &&
			!domNode.children.length
		) {
			return true;
		}

		// Comprobamos si no tenemos nodos hijos y que la longitud del nodo sea la misma que la de la selección
		if (nodes.length === 1 && domNode?.textContent?.length === selectionLength) {
			return true;
		}

		// Comprobamos si no tenemos nodos hijos y si hemos seleccionado el texto completo
		if (
			nodes.length === 1 &&
			domNode?.textContent &&
			this.START_AND_END_WITH_FULL_TEXT(domNode)?.test(TextTools.replaceBreakLines(selection.toString()))
		) {
			return true;
		}

		// Comprobamos si no tenemos algún nodo hijo y en caso de tenerlo si hemos seleccionado en algún de los dos sentidos
		if (
			nodes.length > 1 &&
			domNode?.textContent &&
			((this.LEFT_TO_RIGHT_WITH_CHILDREN(selection)?.test(TextTools.replaceBreakLines(domNode?.textContent)) &&
				this.LEFT_TO_RIGHT_WITH_CHILDREN(selection)?.toString() !== '/^[\\w\\W]*?$/') ||
				(this.RIGHT_TO_LEFT_WITH_CHILDREN(selection)?.test(TextTools.replaceBreakLines(domNode?.textContent)) &&
					this.RIGHT_TO_LEFT_WITH_CHILDREN(selection)?.toString() !== '/^[\\w\\W]*?$/'))
		) {
			return true;
		}

		return false;
	}
}

export default TextSelectionTools;
