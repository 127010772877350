<script lang="ts" setup>
import { GradientColor } from '@/Classes/GradientColor';
import { GradientType } from '@/Types/colorsTypes';
import GAnalytics from '@/utils/GAnalytics';

const props = defineProps<{ gradient: GradientColor }>();

const setGradientType = (type: GradientType) => {
	props.gradient.type = type;

	GAnalytics.track('click', 'Button', `add-gradient-${type}`, null);
};
</script>

<template>
	<div class="flex">
		<button
			class="flex items-center rounded py-1 px-2 text-xs font-semibold"
			:class="gradient.type === 'linear' ? 'bg-gray-600 text-white' : 'text-gray-300 hover:text-white'"
			@click="setGradientType('linear')"
		>
			Linear
		</button>
		<button
			class="flex items-center rounded py-1 px-2 text-xs font-semibold"
			:class="gradient.type === 'radial' ? 'bg-gray-600 text-white' : 'text-gray-300 hover:text-white'"
			@click="setGradientType('radial')"
		>
			Radial
		</button>
	</div>
</template>
