import { computed } from 'vue';

import { checkProvider } from '@/api/UserApiClient';

import { useEditorApiFetch } from './api/useEditorApiFetch';
import { useAuth } from './useAuth';

export const useShare = () => {
	const { user } = useAuth();

	// Facebook + Instagram
	const authenticatedInFacebook = computed(() => user.value?.tokens.facebook.status);
	const {
		data: checkFacebook,
		execute: executeCheckFacebook,
		onFetchResponse: onFetchFacebookResponse,
	} = checkProvider('facebook');

	const getFacebookPages = async () => {
		const { data } = await useEditorApiFetch('facebook/pages').get().json();
		return data;
	};

	const getInstagramAccounts = async () => {
		const { data } = await useEditorApiFetch('facebook/instagram-accounts').get().json();
		return data;
	};

	const logoutFacebook = async () => {
		await useEditorApiFetch('facebook/logout').post();
		if (user.value) {
			user.value.tokens.facebook.status = false;
			user.value.tokens.facebook.token = null;
		}
	};

	// Pinterest
	const authenticatedInPinterest = computed(() => user.value?.tokens.pinterest.status);
	const {
		data: checkPinterest,
		execute: executeCheckPinterest,
		onFetchResponse: onFetchPinterestResponse,
	} = checkProvider('pinterest');

	const hasPinterestBoards = async () => {
		const { data } = await useEditorApiFetch('pinterest/boards').get().json();

		return data.value?.items?.length > 0 || false;
	};

	const logoutPinterest = async () => {
		await useEditorApiFetch('pinterest/logout').post();
		if (user.value) {
			user.value.tokens.pinterest.status = false;
			user.value.tokens.pinterest.token = null;
		}
	};

	// Twitter
	const authenticatedInTwitter = computed(() => user.value?.tokens.twitter.status);
	const {
		data: checkTwitter,
		execute: executeCheckTwitter,
		onFetchResponse: onFetchTwitterResponse,
	} = checkProvider('twitter');

	const logoutTwitter = async () => {
		await useEditorApiFetch('twitter/logout').post();
		if (user.value) {
			user.value.tokens.twitter.status = false;
			user.value.tokens.twitter.token = null;
		}
	};

	return {
		// Facebook + Instagram
		authenticatedInFacebook,
		checkFacebook,
		executeCheckFacebook,
		onFetchFacebookResponse,
		getFacebookPages,
		getInstagramAccounts,
		logoutFacebook,
		// Pinterest
		authenticatedInPinterest,
		checkPinterest,
		executeCheckPinterest,
		onFetchPinterestResponse,
		hasPinterestBoards,
		logoutPinterest,
		// Twitter
		authenticatedInTwitter,
		checkTwitter,
		executeCheckTwitter,
		onFetchTwitterResponse,
		logoutTwitter,
	};
};
