<script setup lang="ts">
import { ref } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useCommon } from '@/composables/element/common/useCommon';
import { usePage } from '@/composables/page/usePage';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { TemplateSuggestion } from '@/Types/types';

const store = useMainStore();
const project = useProjectStore();

const elRef = ref();
const { page } = useCommon(elRef);

const showSuggestion = async (suggestion: TemplateSuggestion, fix = false) => {
	const element = project.getElementById(suggestion.element);

	if (!element) {
		return;
	}

	elRef.value = element;

	// Si no encontramos el contenido renderizado, hacemos scroll hacia esa página y esperamos a que este listo
	if (!page.value.domNode()?.querySelector(`#element-${suggestion.element}`)) {
		page.value.domNode()?.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
		});
		await new Promise((resolve) => setTimeout(resolve, 1500));
	}

	const domElement = element.domNode() as HTMLElement;

	if (domElement.classList.contains('report-error')) {
		domElement.classList.remove('report-error');
		return;
	}

	store.selectionId = [suggestion.element];

	if (suggestion.fix && fix) {
		suggestion.fix();
	} else {
		domElement.classList.add('report-error');
	}
};
</script>

<template>
	<div class="my-4 flex flex-col">
		<button
			v-for="suggestion in store.templateSuggestions"
			class="group relative mt-2 cursor-pointer rounded bg-gray-900 p-2 hover:bg-orange-400"
			@click="showSuggestion(suggestion)"
		>
			<SvgIcon class="h-12 w-12 animate-pulse fill-current text-orange-600 hover:animate-none" name="alert" />
			<div
				class="absolute left-full top-0 -ml-1 hidden h-full w-64 flex-col bg-orange-400 p-2 text-sm group-hover:flex"
			>
				<span class="text-white">{{ suggestion.message }}</span>
				<span
					v-if="suggestion.fix"
					class="rounded bg-green-600 p-1 hover:bg-green-800"
					@click="showSuggestion(suggestion, true)"
					>FIX</span
				>
			</div>
		</button>
	</div>
</template>
