import { Ref } from 'vue';

import { SolidColor } from '@/Classes/SolidColor';
import { Text } from '@/Classes/Text';
import { useTextEffects } from '@/composables/element/text/useTextEffects';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import { Color } from '@/Types/colorsTypes';
import { TextEffects } from '@/Types/types';

export const useTextColors = (textRef: Ref<Text>) => {
	const { outline, colors } = useTextStyles(textRef);
	const { hasColoredEffect, hasEcho, hasNeon, refreshEffect } = useTextEffects(textRef);

	const getSelectedColors = (oldColor: Color, newColor: Color) => {
		const selectedColor = colors.value
			.filter((c) => c)
			.find((c) => c.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha());

		if (selectedColor && selectedColor instanceof SolidColor && newColor instanceof SolidColor) {
			selectedColor.r = newColor.r;
			selectedColor.g = newColor.g;
			selectedColor.b = newColor.b;
			selectedColor.a = newColor.a;
		}

		return selectedColor;
	};

	const updateColor = (oldColor: Color, newColor: Color) => {
		const selectedColor = getSelectedColors(oldColor, newColor);
		if (selectedColor?.id !== textRef.value.color.id) return;

		// Algunos efectos dependen del color del texto y deben actualizarse
		if (hasColoredEffect.value) {
			if (hasEcho.value) refreshEffect(TextEffects.Echo);
			if (hasNeon.value) refreshEffect(TextEffects.Neon);
		}

		textRef.value.color.r = selectedColor.r;
		textRef.value.color.g = selectedColor.g;
		textRef.value.color.b = selectedColor.b;
		textRef.value.color.a = selectedColor.a;
	};

	const updateOutlineColor = (oldColor: Color, newColor: Color) => {
		const selectedColor = outline.color.value.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha();
		if (!selectedColor) return;

		textRef.value.outline.color.r = newColor.r;
		textRef.value.outline.color.g = newColor.g;
		textRef.value.outline.color.b = newColor.b;
		textRef.value.outline.color.a = newColor.a;
	};

	return {
		outline,
		colors,
		updateColor,
		updateOutlineColor,
	};
};
