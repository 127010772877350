<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import Line from '@/Classes/Line';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import CopyToolbar from '@/components/selection/toolbars/CopyToolbar.vue';
import LineStyleToolbar from '@/components/selection/toolbars/LineStyleToolbar.vue';
import MoreToolsToolbar from '@/components/selection/toolbars/MoreToolsToolbar.vue';
import OrderToolbar from '@/components/selection/toolbars/OrderToolbar.vue';
import RemoveToolbar from '@/components/selection/toolbars/RemoveToolbar.vue';
import { useLineColors } from '@/composables/element/line/useLineColors';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

// Props
const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');

const { updateColor } = useLineColors(element);
const { isMobile } = useDeviceInfo();

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.Line) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.Line;
	Bugsnag.leaveBreadcrumb('Open edit line panel');
};
</script>

<template>
	<div>
		<div class="mr-3 border-r border-gray-600 pl-2 pr-3 lg:mr-0 lg:border-0 lg:pl-1 lg:pr-0">
			<ColorPicker
				parent=".toolbar"
				class="relative h-8 w-8 shrink-0 before:pointer-events-none before:absolute before:top-1 before:left-1 before:z-10 before:h-6 before:w-6 before:rounded-full before:bg-gray-700 lg:mr-1 lg:h-5 lg:w-5 lg:before:h-3 lg:before:w-3"
				:color="element.mainColor"
				:hide-gradient="true"
				@change="updateColor"
			/>
		</div>

		<LineStyleToolbar :element="element" />

		<OrderToolbar v-if="isMobile" :element="element" />

		<hr class="mx-1 hidden h-5 w-px bg-white opacity-25 lg:block" />

		<CopyToolbar v-if="isMobile" :element="element" />

		<RemoveToolbar :element="element" />

		<MoreToolsToolbar :panel="EditPanels.Line" @more-tools="onClickMoreTools" />
	</div>
</template>
