import { tryOnScopeDispose } from '@vueuse/core';

const listeners: { [key: string]: EventListener[] } = {};

let registered = false;

const registerKeyboardListener = () => {
	if (registered) return;

	window.addEventListener('keyup', (e) => {
		handleKeyboardEvent(`keyup-${e.key}`, e);
	});
	window.addEventListener('keydown', (e) => {
		handleKeyboardEvent(`keydown-${e.key}`, e);
	});
	registered = true;
};

const handleKeyboardEvent = (key: string, event: KeyboardEvent): void => {
	if (!listeners[key]) return;

	// Empezamos a llamar a los listeners asociados a este evento en orden de más reciente
	// a más antiguo.

	listeners[key]
		.slice()
		.reverse()
		.find(function (listener) {
			const result = listener(event);
			// solamente en el caso de que el listener vamos a devolver false para que se continue iterando
			return result !== true;
		});
};

const register = (key: string, listener: EventListener, type: 'keydown' | 'keyup' = 'keydown') => {
	const computedKey = `${type}-${key}`;

	registerKeyboardListener();

	// si no teniamos ningun listener para esta clave, lo añadimos
	// y además nos suscribimos
	if (!listeners[computedKey]) {
		listeners[computedKey] = [];
	}

	// añadimos el listener a la lista de listener de esta clave
	listeners[computedKey].push(listener);

	// en el momento en que se quite el componente registrado, lo quitamos de la lista de listener
	tryOnScopeDispose(() => {
		listeners[computedKey] = listeners[computedKey].filter((l) => l !== listener);
	});
};
type EventListener = (e: KeyboardEvent) => void | true;

/**
 * Este hook permite registrar eventos de teclado que se auto desbindean al destruir el componente.
 * Solamente se ejecutara el listener más reciente a menos que el listener retorne true
 */
export const useOrderedKeyboardListener = () => {
	/**
	 * Escucha un evento de teclado
	 * @param keys
	 * @param listener
	 * @param type
	 */
	const listen = (keys: string | string[], listener: EventListener, type: 'keydown' | 'keyup' = 'keydown') => {
		(Array.isArray(keys) ? keys : [keys]).forEach((key) => {
			register(key, listener, type);
		});
	};

	/**
	 * Cancela los eventos de teclado
	 * @param keys
	 * @param type
	 */
	const stopPropagation = (keys: string | string[], type: 'keydown' | 'keyup' = 'keydown') => {
		(Array.isArray(keys) ? keys : [keys]).forEach((key) => {
			register(key, () => undefined, type);
		});
	};

	return {
		listen,
		stopPropagation,
	};
};
