<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, ref, watch } from 'vue';

import { getImages } from '@/api/DataApiClient';
import InfiniteLoading from '@/components/Common/_InfiniteLoading.vue';
import AutocompleteInput from '@/components/Common/AutocompleteInput.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import ListImage from '@/components/panels/photos/ListImage.vue';
import { ImageApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		search: string;
		modal?: boolean;
	}>(),
	{
		search: '',
		modal: false,
	}
);

const emit = defineEmits<{
	(e: 'select', value: ImageApi): void;
	(e: 'search', value: string): void;
}>();

const search = computed(() => props.search);

const source = ref(`images/freepik?query=${search.value}`);
const autocompleteSource = computed(() => `autocomplete/freepik?search=${search.value}`);

const { data: images, isFetching } = getImages(source, { refetch: true });

watch(search, () => {
	source.value = `images/freepik?query=${search.value}`;
});

const loadMore = async () => {
	if (isFetching.value || !images.value?.links?.next) return;
	source.value = images.value.links.next;
};

const updateSearchValue = (searchValue: string) => {
	emit('search', searchValue);

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in Freepik images: ${searchValue}`);
};
</script>

<template>
	<AutocompleteInput
		:placeholder="'Search Image...'"
		:query="search"
		:autocomplete-source="autocompleteSource"
		:modal="modal"
		@change="updateSearchValue"
	/>

	<InfiniteLoading
		:data="(images && images.data) || []"
		:is-fetching="isFetching"
		:modal="modal"
		class="flex flex-col"
		masonry
		:masonry-cols="2"
		@load="loadMore"
	>
		<template #item="{ item }">
			<InsertableElement
				:data="item"
				:type="Panels.photos"
				:draggable="!props.modal"
				class="mb-2"
				@click="emit('select', item)"
			>
				<ListImage :image="item" />
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
