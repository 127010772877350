import { useMouse } from '@vueuse/core';
import { computed, Ref } from 'vue';

import Image from '@/Classes/Image';
import { usePhotoMode } from '@/composables/element/image/usePhotoMode';
import { useTransform } from '@/composables/element/useTransform';
import { useArtboard } from '@/composables/project/useArtboard';
import { useZoom } from '@/composables/useZoom';
import { useMainStore } from '@/stores/store';
import { ImageApi } from '@/Types/apiClient';
import { Anchor, Size } from '@/Types/types';
import ElementTools from '@/utils/ElementTools';
import ImageTools from '@/utils/ImageTools';

const mouse = useMouse();

export const useImageTransform = (image: Ref<Image>) => {
	const { artboardSizeInPx, setArtboardSize } = useArtboard();
	const store = useMainStore();
	const { photoModeImage } = usePhotoMode();
	const usingTransform = useTransform(image);
	const { fitZoomScale } = useZoom();

	const isPhotoModeImage = computed(() => photoModeImage.value?.id === image.value.id);

	const adjustToHeight = (height: number): void => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingTransform.adjustTo('height', height);
	};

	const adjustToWidth = (width: number): void => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingTransform.adjustTo('width', width);
	};

	const adjustTo = (side: keyof Size, val: number) => {
		if (!isPhotoModeImage.value && image.value.locked) return;

		// Save size before update to use it in crop
		const prevSize = {
			width: image.value.size.width,
			height: image.value.size.height,
		};

		if (side === 'width') {
			adjustToWidth(val);
		}

		if (side === 'height') {
			adjustToHeight(val);
		}

		adjustCrop(prevSize, image.value.size);
	};

	const adjustCrop = (prevSize: Size, newSize: Size) => {
		const ratio = {
			x: newSize.width / prevSize.width,
			y: newSize.height / prevSize.height,
		};
		image.value.crop.size.width *= ratio.x;
		image.value.crop.size.height *= ratio.y;
		image.value.crop.position.x *= ratio.x;
		image.value.crop.position.y *= ratio.y;
	};

	const adjustCropToHeight = (height: number) => {
		if (image.value.locked) return;
		image.value.crop.size = ElementTools.getSizeKeepingAspectRatioByHeight(image.value.crop.size, height);
	};

	const adjustCropToWidth = (width: number) => {
		if (image.value.locked) return;
		image.value.crop.size = ElementTools.getSizeKeepingAspectRatioByWidth(image.value.crop.size, width);
	};

	const align = (position: Anchor): void => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingTransform.align(position);
	};

	const fitAndCenterInArtboard = () => {
		// Adjust to width or height regarding page orientation
		adjustTo('width', artboardSizeInPx.value.width);

		if (image.value.size.height < artboardSizeInPx.value.height) {
			adjustTo('height', artboardSizeInPx.value.height);
		}

		// Align image to center horizontally and vertically
		usingTransform.align(Anchor.center);
	};

	const fitAndCenterOnReplace = async (apiImage: ImageApi) => {
		// Get state to locate it correctly later
		const prevSize = {
			width: image.value.size.width,
			height: image.value.size.height,
		};

		// Set load image size in order to fix aspect ratio
		await ImageTools.getRealImageSize(apiImage.preview || apiImage.url).then(({ width, height }) => {
			image.value.hasCrop() ? (image.value.crop.size = { width, height }) : image.value.setSize(width, height);
		});

		const prevRatio = prevSize.width / prevSize.height;
		image.value.hasCrop() ? fitWithCrop(prevRatio, prevSize) : fitWithoutCrop(prevRatio, prevSize);
	};

	const fitElementRegardingFactor = (factor: number) => {
		usingTransform.fitElementRegardingFactor(factor);

		image.value.crop.position.x *= factor;
		image.value.crop.position.y *= factor;
		image.value.crop.size.width *= factor;
		image.value.crop.size.height *= factor;
	};

	const fitWithCrop = (prevRatio: number, prevSize: Size) => {
		// Fix size
		const cropRatio = image.value.crop.size.width / image.value.crop.size.height;

		if (prevRatio >= 1) {
			if (cropRatio >= prevRatio) {
				adjustCropToHeight(prevSize.height);
			} else {
				adjustCropToWidth(prevSize.width);
			}
		} else {
			if (cropRatio < prevRatio) {
				adjustCropToWidth(prevSize.width);
			} else {
				adjustCropToHeight(prevSize.height);
			}
		}

		// Fix position
		image.value.crop.position = {
			x: image.value.crop.size.width * -0.5 + image.value.size.width * 0.5,
			y: image.value.crop.size.height * -0.5 + image.value.size.height * 0.5,
		};
	};

	const fitWithoutCrop = (prevRatio: number, prevSize: Size) => {
		// Fix size
		const newRatio = image.value.size.width / image.value.size.height;

		image.value.crop.size = image.value.size;
		image.value.size = prevSize;

		if (prevRatio >= 1) {
			if (newRatio >= prevRatio) {
				adjustCropToHeight(prevSize.height);
			} else {
				adjustCropToWidth(prevSize.width);
			}
		} else {
			if (newRatio < prevRatio) {
				adjustCropToWidth(prevSize.width);
			} else {
				adjustCropToHeight(prevSize.height);
			}
		}

		// Fix position
		image.value.crop.position = {
			x: (prevSize.width - image.value.crop.size.width) * 0.5,
			y: (prevSize.height - image.value.crop.size.height) * 0.5,
		};
	};

	const flipAxis = (axis: 'x' | 'y'): void => {
		if (!isPhotoModeImage.value && image.value.locked) return;

		usingTransform.flipAxis(axis);

		if (!image.value.hasCrop()) return;

		if (axis === 'x') {
			let x = 0;
			if (image.value.crop.position.x > 0) {
				x = Math.abs(image.value.crop.size.width - image.value.size.width) - image.value.crop.position.x;
			} else {
				x = (image.value.crop.size.width - image.value.size.width + image.value.crop.position.x) * -1;
			}
			image.value.crop.position.x = x;
		}

		if (axis === 'y') {
			let y = 0;
			if (image.value.crop.position.y > 0) {
				y = Math.abs(image.value.crop.size.height - image.value.size.height) - image.value.crop.position.y;
			} else {
				y = (image.value.crop.size.height - image.value.size.height + image.value.crop.position.y) * -1;
			}
			image.value.crop.position.y = y;
		}
	};

	const move = (x: number, y: number, relative = true): void => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingTransform.move(x, y, relative);
	};

	const replacePhotoModeImage = async (apiImage: ImageApi) => {
		// Set load image size in order to fix aspect ratio
		await ImageTools.getRealImageSize(apiImage.url || apiImage.preview).then(({ width, height }) => {
			setArtboardSize(width, height, 'px');
			store.$patch(() => {
				image.value.setPosition(0, 0);
				image.value.setSize(width, height);
				image.value.crop.position = { x: 0, y: 0 };
				image.value.crop.size = { width: 0, height: 0 };
				image.value.rotation = 0;
			});
			fitZoomScale();
		});
	};

	const resize = (width: number, height: number) => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingTransform.resize(width, height);
	};

	const rotate = (angle: number, relative = true) => {
		if (!isPhotoModeImage.value && image.value.locked) return;

		if (relative) {
			image.value.rotation += angle;

			if (isPhotoModeImage.value) {
				move(usingTransform.left.value, usingTransform.top.value, false);
			}
			return;
		}

		image.value.rotation = angle;
	};

	const scale = (scale: number) => {
		if (image.value.locked) return;
		usingTransform.scale(scale);
	};

	const isInOtherPage = computed(() => {
		let canvas: HTMLElement[] = [];

		if (store.selection.some((element) => element.id === element.id)) {
			canvas = Array.from(document.querySelectorAll<HTMLElement>("[data-canvas-visible='true']")).filter((canvaEl) => {
				const canvasRect = canvaEl.getBoundingClientRect();
				const id = canvaEl.getAttribute('data-id');

				const checkX = mouse.x.value >= canvasRect.left && mouse.x.value <= canvasRect.left + canvasRect.width;
				const checkY = mouse.y.value >= canvasRect.top && mouse.y.value <= canvasRect.top + canvasRect.height;

				return checkX && checkY && id !== store.activePageId;
			});
		}

		return {
			status: canvas.length > 0,
			canvas: canvas.length > 0 ? canvas[0] : null,
		};
	});

	return {
		...usingTransform,
		adjustTo,
		adjustCropToHeight,
		adjustCropToWidth,
		align,
		fitAndCenterInArtboard,
		fitAndCenterOnReplace,
		fitElementRegardingFactor,
		flipAxis,
		move,
		replacePhotoModeImage,
		resize,
		rotate,
		scale,
		isInOtherPage,
	};
};
