<script setup lang="ts">
import { useMouseInElement } from '@vueuse/core';
import { computed, nextTick, ref, watch } from 'vue';

import { Shape } from '@/Classes/Shape';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import ContextMenuContainer from '@/components/selection/menus/ContextMenuContainer.vue';
import ContextMenuOption from '@/components/selection/menus/ContextMenuItem.vue';
import ContextMenuSeparator from '@/components/selection/menus/ContextMenuSeparator.vue';
import { useElementOrderOrchestrator } from '@/composables/element/useElementOrderOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
import { useEditorClipboard } from '@/composables/useEditorClipboard';
import { useHistoryStore } from '@/stores/history';
import { useMainStore } from '@/stores/store';
import { Position } from '@/Types/types';
const store = useMainStore();
const { removeElement } = useActivePage();
const history = useHistoryStore();

// Data
const domNode = ref();
const positionInPage = ref<Position>({
	x: 0,
	y: 0,
});

// Template refs
const container = ref();

// Using composables
const { canBeCopied, canBePasted, copySelection, pasteSelection } = useEditorClipboard();
const { elementX, elementY } = useMouseInElement(domNode);

// Computeds
const activePage = computed(() => store.activePage);
const element = computed(() => store.selection[0] || Shape.createDefault());
const usingOrder = useElementOrderOrchestrator(element);

const forwardEnable = computed(() => {
	if (!store.selection.length) return false;

	return usingOrder.value.canGoUp.value;
});
const backwardEnable = computed(() => {
	if (!store.selection.length) return false;

	return usingOrder.value.canGoDown.value;
});
const toFrontEnable = computed(() => {
	if (!store.selection.length) return false;

	return usingOrder.value.canGoUp.value;
});
const toBackEnable = computed(() => {
	if (!store.selection.length) return false;

	return usingOrder.value.canGoDown.value;
});
const activeState = computed(() => history.activeState);

watch(
	activePage,
	(newVal) => {
		domNode.value = newVal?.domNode();
	},
	{ immediate: true }
);

// Watcher para controlar el refresco de posición X e Y del ratón cuando hay un cambio en el historial
watch(activeState, async () => {
	// Forzamos la reasignación del nodo de la página
	domNode.value = undefined;

	await nextTick();
	domNode.value = activePage.value?.domNode();
});

// Methods
const copy = () => {
	if (!canBeCopied.value) return;

	copySelection();
	hideContextMenu();
};

const paste = () => {
	if (!canBePasted.value) return;

	pasteSelection(positionInPage.value);
	hideContextMenu();
};

const doDelete = () => {
	if (!canBeCopied.value) return;

	store.selection.forEach((element) => removeElement(element));
	hideContextMenu();
};

const forward = () => {
	if (!forwardEnable.value) return;

	usingOrder.value.moveUp();
	hideContextMenu();
};

const backward = () => {
	if (!backwardEnable.value) return;

	usingOrder.value.moveDown();
	hideContextMenu();
};

const toFront = () => {
	if (!toFrontEnable.value) return;

	usingOrder.value.moveToFront();
	hideContextMenu();
};

const toBack = () => {
	if (!toBackEnable.value) return;

	usingOrder.value.moveToBack();
	hideContextMenu();
};

const hideContextMenu = () => {
	container.value.hide();
};

const updateClickPosition = () => {
	positionInPage.value = {
		x: elementX.value / store.scale,
		y: elementY.value / store.scale,
	};
};
</script>

<template>
	<context-menu-container ref="container" @show="updateClickPosition">
		<context-menu-option :enabled="canBeCopied" :run="copy">
			<SvgIcon name="copy" class="mr-2 h-3 w-3" /> Copy
		</context-menu-option>
		<context-menu-option :enabled="canBePasted" :run="paste">
			<SvgIcon name="paste" class="mr-2 h-3 w-3" /> Paste
		</context-menu-option>
		<context-menu-separator />
		<context-menu-option :enabled="canBeCopied" :run="doDelete" danger>
			<SvgIcon name="trash" class="mr-2 h-3 w-3" /> Delete
		</context-menu-option>
		<context-menu-separator />
		<context-menu-option :enabled="forwardEnable" :run="forward">
			<SvgIcon name="layer-up" class="mr-2 h-3 w-3" /> Forward
		</context-menu-option>
		<context-menu-option :enabled="backwardEnable" :run="backward">
			<SvgIcon name="layer-down" class="mr-2 h-3 w-3" /> Backward
		</context-menu-option>
		<context-menu-option :enabled="toFrontEnable" :run="toFront">
			<SvgIcon name="forward" class="mr-2 h-3 w-3" /> To front
		</context-menu-option>
		<context-menu-option :enabled="toBackEnable" :run="toBack">
			<SvgIcon name="backward" class="mr-2 h-3 w-3" /> To back
		</context-menu-option>
	</context-menu-container>
</template>
