import { Element as SvgElement, SVG } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';

import Element from '@/Classes/Element';
import { GradientColor } from '@/Classes/GradientColor';
import { Color } from '@/Types/colorsTypes';
import { Flip, Linecap, Marker, Position, Size } from '@/Types/types';
import ElementTools from '@/utils/ElementTools';
import MathTools from '@/utils/MathTools';

import { SolidColor } from './SolidColor';

class Line extends Element {
	type: 'line' = 'line';
	mainColor: Color;
	linecap: Linecap;
	dasharray: number[];
	markerStart: Marker | null;
	markerEnd: Marker | null;

	protected constructor(
		// Element
		metadata: object,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number,
		// Line
		mainColor: Color,
		linecap: Linecap,
		dasharray: number[],
		markerStart: Marker | null,
		markerEnd: Marker | null
	) {
		super(metadata, size, position, rotation, flip, group, locked, keepProportions, opacity);
		this.mainColor = mainColor;
		this.linecap = linecap;
		this.dasharray = dasharray;
		this.markerStart = markerStart;
		this.markerEnd = markerEnd;
	}

	static unserialize(data: any): Line {
		const defaults = Line.defaults();

		const {
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// Line
			mainColor,
			linecap,
			dasharray,
			markerStart,
			markerEnd,
		} = data;

		const fixedMainColor =
			'stops' in mainColor ? GradientColor.unserialize(mainColor) : SolidColor.unserialize(mainColor);

		if (markerStart) {
			markerStart.color =
				'stops' in markerStart.color
					? GradientColor.unserialize(markerStart.color)
					: SolidColor.unserialize(markerStart.color);
		}

		if (markerEnd) {
			markerEnd.color =
				'stops' in markerEnd.color
					? GradientColor.unserialize(markerEnd.color)
					: SolidColor.unserialize(markerEnd.color);
		}

		const elem = new Line(
			// Element
			metadata || defaults.metadata,
			size || defaults.size,
			position || defaults.position,
			rotation !== null ? rotation : defaults.rotation,
			flip || defaults.flip,
			group || defaults.group,
			locked !== null ? locked : defaults.locked,
			keepProportions !== null ? keepProportions : defaults.keepProportions,
			opacity !== undefined ? opacity : defaults.opacity,
			// Line
			fixedMainColor,
			linecap,
			dasharray,
			markerStart,
			markerEnd
		);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static defaults() {
		return {
			// Element
			metadata: {},
			size: { height: 0, width: 0 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: false,
			opacity: 1,
			// Line
			mainColor: SolidColor.black(),
			linecap: 'butt' as Linecap,
			dasharray: [0],
			markerStart: null,
			markerEnd: null,
		};
	}

	static createDefault(merge = {}) {
		let defaults = Line.defaults();
		defaults = { ...defaults, ...merge };

		return new Line(
			defaults.metadata,
			defaults.size,
			defaults.position,
			defaults.rotation,
			defaults.flip,
			defaults.group,
			defaults.locked,
			defaults.keepProportions,
			defaults.opacity,
			defaults.mainColor,
			defaults.linecap,
			defaults.dasharray,
			defaults.markerStart,
			defaults.markerEnd
		);
	}

	static create(height: number, width: number, config?: any): Line {
		const defaults = Line.defaults();
		// Element
		const metadata = config?.metadata || defaults.metadata;
		const size = { height, width };
		const position = config?.position || defaults.position;
		const rotation = config?.rotation || defaults.rotation;
		const flip = config?.flip || defaults.flip;
		const group = config?.group || defaults.group;
		const locked = config?.locked || defaults.locked;
		const keepProportions = config?.keepProportions || defaults.keepProportions;
		const opacity = config?.opacity || defaults.opacity;
		// Line
		const mainColor = config?.mainColor || defaults.mainColor;
		const linecap = config?.linecap || defaults.linecap;
		const dasharray = config?.dasharray || defaults.dasharray;
		const markerStart = config?.markerStart || defaults.markerStart;
		const markerEnd = config?.markerEnd || defaults.markerEnd;

		return new Line(
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// Line
			mainColor,
			linecap,
			dasharray,
			markerStart,
			markerEnd
		);
	}

	updateStrokeColor(newColor: Color) {
		newColor.id = this.mainColor.id;
		this.mainColor = newColor;
	}

	updateMarkerStartColor(newColor: Color) {
		if (this.markerStart) {
			newColor.id = this.markerStart.color.id;
			this.markerStart.color = newColor;
		}
	}

	updateMarkerEndColor(newColor: Color) {
		if (this.markerEnd) {
			newColor.id = this.markerEnd.color.id;
			this.markerEnd.color = newColor;
		}
	}

	dasharrayToString() {
		const dashArray = Array.isArray(this.dasharray) ? this.dasharray : Object.values(this.dasharray);
		return dashArray.join(',');
	}

	static fromSvg(rawSvg: string) {
		rawSvg = rawSvg.substring(rawSvg.indexOf('<svg'));

		const el = SVG(rawSvg);

		const realLine = el.findOne('line') as SvgElement;

		const x1 = parseFloat((realLine.attr('x1') || '0').toString());
		const y1 = parseFloat((realLine.attr('y1') || '0').toString());
		const x2 = parseFloat((realLine.attr('x2') || '0').toString());
		const y2 = parseFloat((realLine.attr('y2') || '0').toString());

		const [r, g, b] = Normalize(realLine.css('stroke'));
		const strokeOpacity = parseFloat(realLine.css('opacity') || '1');
		const height = parseFloat(realLine.css('stroke-width').toString() || '1');
		const width = MathTools.getDistanceBetween2Points(x1, y1, x2, y2);
		const rotation = MathTools.getAngle(x1, y1, x2, y2);
		const linecap = realLine.css('stroke-linecap') || 'butt';
		const dasharrayParsed = realLine.css('stroke-dasharray').toString().replaceAll(' ', '');
		let dasharray =
			dasharrayParsed.length > 0 ? dasharrayParsed.split(',').map((dash) => parseFloat(dash) / height) : [0];

		dasharray = ElementTools.fixDashArray(dasharray);

		const mainColor = SolidColor.fromObject({
			r: r * 255,
			g: g * 255,
			b: b * 255,
			a: strokeOpacity,
		});

		// Calculamos la posición en base a la rotación
		const origin = {
			x: x1 + width / 2,
			y: y1 + height / 2,
		};
		const rotatePosition = MathTools.rotatePoint(origin.x, origin.y, x1, y1, rotation);
		const diff = {
			x: rotatePosition.x - x1,
			y: rotatePosition.y - y1,
		};

		// Buscamos los markers de inicio y fin
		let markerStart = null;
		let markerEnd = null;

		el.find('[id]').forEach((element) => element.id(element.id().toLocaleLowerCase()));

		el.find('[id*="sticky"]').forEach((sticky) => {
			const stickyX = sticky.x();
			const stickyHeight = parseFloat(sticky.height().toString());
			const stickyWidth = parseFloat(sticky.width().toString());
			const rotation = parseFloat(sticky.transform('rotate').toString());

			const fill = el.css('fill') || '#000000';
			const opacity = parseFloat(el.css('opacity') || '1');
			const [r, g, b] = Normalize(fill);
			const color = SolidColor.fromObject({
				r: r * 255,
				g: g * 255,
				b: b * 255,
				a: opacity,
			});

			// Escalamos y posicionamos el marker para que cuadre con next-gen
			sticky.x(0);
			sticky.y(0);
			sticky.height(stickyHeight / height);
			sticky.width(stickyWidth / height);
			sticky.attr('rx', sticky.attr('rx') / height);
			sticky.id('');

			// Determinamos que marker es el del principio y cual es el del final,
			// también ajustamos su posición para que cuadre con al línea
			if (stickyX < parseFloat(realLine.width().toString()) / 2) {
				sticky.css('fill', 'var(--start-sticky-color)');
				sticky.transform({
					translateX: -(stickyWidth / height / 2),
					translateY: -(stickyHeight / height / 2),
					rotate: rotation,
				});

				markerStart = {
					element: sticky.node.outerHTML.toString(),
					color,
				};
			}

			if (stickyX >= parseFloat(realLine.width().toString()) / 2) {
				sticky.css('fill', 'var(--end-sticky-color)');
				sticky.transform({
					translateX: -(stickyWidth / height / 2),
					translateY: -(stickyHeight / height / 2),
					rotate: rotation,
				});

				markerEnd = {
					element: sticky.node.outerHTML.toString(),
					color,
				};
			}
		});

		return Line.create(height, width, {
			position: {
				x: x1 - diff.x,
				y: y1 - diff.y,
			},
			rotation,
			mainColor,
			linecap,
			dasharray,
			markerStart,
			markerEnd,
			opacity: 1,
		});
	}

	static defaultDasharrays() {
		return [[0], [6, 3], [6, 6], [1, 4]];
	}
}

export default Line;
