<script lang="ts" setup>
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';

const onTryAgain = () => window.location.reload();
</script>

<template>
	<Modal open>
		<div
			class="relative flex flex-col items-center justify-center overflow-hidden rounded-md bg-white px-8 py-8 sm:px-16"
		>
			<SvgIcon name="alert" class="mb-6 h-10 w-10 text-red-500" />
			<h1 class="text-2xl font-bold text-gray-800">Something wen't wrong</h1>
			<p class="text-lg text-gray-600">This template cannot be loaded</p>
			<div class="mt-6 flex flex-col gap-4 sm:flex-row">
				<button
					class="rounded border-2 border-blue-500 bg-blue-500 px-4 py-2 font-semibold text-white hover:border-blue-600 hover:bg-blue-600"
					@click="onTryAgain"
				>
					Try again
				</button>
				<a
					class="rounded border-2 border-blue-500 px-4 py-2 font-semibold text-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white"
					href="/"
				>
					Search more templates
				</a>
			</div>
		</div>
	</Modal>
</template>
