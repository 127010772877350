<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { computed, ref } from 'vue';

// Api
import { getStorysets, getSvg } from '@/api/DataApiClient';
// Classes
import Storyset from '@/Classes/Storyset';
import AutocompleteInput from '@/components/Common/AutocompleteInput.vue';
// Componenst
import InfiniteLoading from '@/components/Common/InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import StorysetStyles from '@/components/panels/elements/StorysetStyles.vue';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
// Composables
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { ElementsPanelTypes, StorysetsStyles } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';
const store = useMainStore();

const temporalRef = ref(Storyset.createDefault());
const usingElementOrchestrator = useElementOrchestrator(temporalRef);
const { addElement } = useActivePage();
const emit = defineEmits(['elementAdded']);

// Constants
const URL_CONFIG = {
	ORDER: 'recent',
	LIMIT: 30,
	PAGE: 1,
};

// Data
const search = ref('');

const style = ref<StorysetsStyles | ''>('');

// Computed
const autocompleteSource = computed(() => `autocomplete/stories?search=${search.value}`);

const source = computed(
	() =>
		`https://stories.freepiklabs.com/api/vectors?order=${URL_CONFIG.ORDER}&limit=${URL_CONFIG.LIMIT}&page=${URL_CONFIG.PAGE}&query=${search.value}&style=${style.value}`
);

const addStoryset = async (url: string) => {
	const { data } = await getSvg(url);

	if (!data.value || !store.activePage) return;

	const newStoryset = Storyset.fromSvg(data.value);

	temporalRef.value = newStoryset;
	addElement(newStoryset);

	usingElementOrchestrator.value.setupInPage();

	store.setSelection(newStoryset, false);

	GAnalytics.track('click', 'Button', 'add-icon', null);
	Bugsnag.leaveBreadcrumb(`Add Story to canvas: ${newStoryset.type}-${newStoryset.id}`);
	emit('elementAdded');
};

// Methods
const onSetStyle = (val: StorysetsStyles | '') => (style.value = val);

const updateSearchValue = (searchValue: string) => {
	search.value = searchValue;

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in Storyset panel: ${searchValue}`);
};
</script>

<template>
	<div class="flex h-full flex-col">
		<AutocompleteInput
			:placeholder="'Search Illustration...'"
			:query="search"
			:autocomplete-source="autocompleteSource"
			@change="updateSearchValue"
		/>

		<StorysetStyles :style="style" @setStyle="onSetStyle" />

		<InfiniteLoading :source="source" :fetch="getStorysets" masonry :masonry-cols="2">
			<template #item="{ item }">
				<InsertableElement
					:data="item"
					:type="ElementsPanelTypes.Storysets"
					:draggable="true"
					class="mb-2 flex aspect-square w-full overflow-hidden rounded bg-gray-700 hover:bg-gray-600"
					data-testid="storyset"
					@click="addStoryset(item.src)"
				>
					<img :src="item.preview" :alt="item.illustration.name" class="h-full w-full object-contain p-2" />
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
