export type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type FontStyle = 'normal' | 'italic';
export type RangeOption = 'full' | Selection;
export type TextAlign = 'left' | 'center' | 'right' | 'justify';
export type TextTransform = '' | 'lowercase' | 'uppercase' | 'capitalize';
export type ListStyle = '' | 'ordered' | 'unordered';

export enum StyleProperties {
	fontSize = 'fontSize',
	color = 'color',
	fontFamily = 'fontFamily',
	lineHeight = 'lineHeight',
	fontWeight = 'fontWeight',
	fontStyle = 'fontStyle',
	letterSpacing = 'letterSpacing',
}

export type SelectionStyle =
	| StyleProperties.color
	| StyleProperties.fontSize
	| StyleProperties.fontFamily
	| StyleProperties.lineHeight
	| StyleProperties.fontWeight
	| StyleProperties.fontStyle
	| StyleProperties.letterSpacing;
