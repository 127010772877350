import { ref } from 'vue';

import { useProjectStore } from '@/stores/project';

const showUnsavedChangesModal = ref(false);
const confirmedNavigation = ref(false);
const intendedNavigation = ref('');

export default function () {
	const navigateWithoutLosingChanges = (url: string) => {
		const store = useProjectStore();

		if (store.allChangesSaved) {
			window.location.href = url;
			return;
		}

		showUnsavedChangesModal.value = true;
		intendedNavigation.value = url;
	};

	const confirmIntendedNavigation = () => {
		showUnsavedChangesModal.value = false;
		confirmedNavigation.value = true;
		window.location.href = intendedNavigation.value;
	};

	const guardAgainstUnsavedChanges = () => {
		if (import.meta.env.DEV) return;

		window.addEventListener('beforeunload', function (e) {
			if (confirmedNavigation.value) {
				return;
			}

			const store = useProjectStore();

			if (store.allChangesSaved) {
				return;
			}
			e.returnValue = "Are you sure? Your changes aren't saved jet";

			return e.returnValue;
		});
	};

	return {
		navigateWithoutLosingChanges,
		showUnsavedChangesModal,
		confirmIntendedNavigation,
		guardAgainstUnsavedChanges,
	};
}
