<script lang="ts" setup>
// Vue & Packges
import { useFetch } from '@vueuse/core';
import { watch } from 'vue';

// Stores
import { useMainStore } from '@/stores/store';
// Types
import { TemplateCategory } from '@/Types/types';
const store = useMainStore();

// Using composables
const { data, isFetching } = useFetch(`${import.meta.env.VITE_APP_API_PATH}categories`)
	.get()
	.json();

// Emits
const emit = defineEmits(['fetching']);

// Watchers
watch(isFetching, (val) => emit('fetching', val), { immediate: true });

// Methods
const setCategory = (category: TemplateCategory) => {
	store.activeTemplateCategory = category;
};
</script>

<template>
	<div
		v-if="!!data?.length && !isFetching"
		ref="container"
		class="mt-2 flex max-h-[calc(100vh-176px)] flex-grow flex-col gap-4 overflow-y-auto pr-1 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
	>
		<!-- Categories -->
		<div v-for="category in data" :key="category.name" class="mb-2 flex flex-col">
			<div class="mb-2 flex items-center justify-between">
				<h3 class="font-bold text-gray-100">{{ category.name }}</h3>
				<button class="text-xs font-bold text-gray-300 hover:text-white" @click="setCategory(category)">See all</button>
			</div>
			<div
				data-testid="template-category"
				class="flex snap-x snap-mandatory gap-2 overflow-x-auto pb-1 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
			>
				<!-- Subcategories -->
				<button
					v-for="subcategory in category.subcategories"
					:key="subcategory.name"
					class="group flex snap-start flex-col items-center"
					@click="setCategory(subcategory)"
				>
					<picture class="mb-2 h-32 w-32 shrink-0 overflow-hidden rounded bg-gray-700">
						<img
							v-if="subcategory.preview"
							:alt="subcategory.name"
							:src="subcategory.preview.thumb"
							class="aspect-square h-full w-full object-contain text-xs text-white"
							draggable="false"
						/>
					</picture>
					<h4 class="text-center text-sm font-semibold leading-tight text-gray-300 group-hover:text-gray-100">
						{{ subcategory.name }}
					</h4>
				</button>
			</div>
		</div>
	</div>
</template>
