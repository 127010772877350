import {
	Circle,
	ClipPath,
	Element,
	Ellipse,
	Line,
	Path,
	PathArray,
	Polygon,
	Polyline,
	Rect,
	SVG,
	Svg,
} from '@svgdotjs/svg.js';

import { SerializableClass } from '@/Classes/SerializableClass';
import { ViewBox } from '@/Types/types';

const viewboxMasks: { [key: string]: { width: number; height: number; x: number; y: number } } = {
	37641: { width: 388.64, height: 360.95, x: 0, y: 0 },
	37679: { width: 334.72, height: 334.72, x: 0, y: 0 },
	37669: { width: 251.15, height: 250.65, x: 0, y: 0 },
	37609: { width: 127.6, height: 122.62, x: 0, y: 0 },
	37647: { width: 500, height: 314.28, x: 0, y: 0 },
	37632: { width: 437.36, height: 437.36, x: 0, y: 0 },
	37617: { width: 167.05, height: 324.52, x: 0, y: 0 },
	37620: { width: 241.67, height: 200.85, x: 0, y: 0 },
	37619: { width: 303.04, height: 307.54, x: 0, y: 0 },
	37618: { width: 348.48, height: 408.33, x: 0, y: 0 },
	37615: { width: 244.29, height: 218.47, x: 0, y: 0 },
};

class Mask extends SerializableClass {
	id: number;
	name: string;
	content: string;
	keepRatio: boolean;
	isPlaceholder: boolean;

	protected constructor(id: number, name: string, content: string, keepRatio: boolean, isPlaceholder: boolean) {
		super();
		this.id = id;
		this.name = name;
		this.content = content;
		this.keepRatio = keepRatio;
		this.isPlaceholder = isPlaceholder;
	}

	static create(id: number, name: string, content: string, config?: any) {
		const keepRatio = config?.keepRatio || true;
		const isPlaceholder = config?.isPlaceholder || false;
		return new Mask(id, name, content, keepRatio, isPlaceholder);
	}

	static async fromApi(data: any) {
		const svg = await fetch(data.svg).then((r) => r.text());

		const instanceSvg = SVG(svg) as Svg;
		const viewbox = instanceSvg.viewbox();
		instanceSvg.children().forEach((elem) => {
			if (!this.isAcceptedElement(elem)) {
				return;
			}

			this.resizeSvgElement(elem, viewbox);
		});

		return Mask.create(data.id, data.name, instanceSvg.node.innerHTML);
	}

	static fromTemplate(id: number, name: string, maskNode: ClipPath) {
		maskNode.children().forEach((elem) => {
			if (!this.isAcceptedElement(elem)) {
				return;
			}

			this.resizeSvgElement(elem, viewboxMasks[id] || { width: 500, height: 500, x: 0, y: 0 });
		});

		return Mask.create(id, name, maskNode.node.innerHTML);
	}

	public toggleRatio() {
		this.keepRatio = !this.keepRatio;
	}

	/**
	 *
	 * @param elem Path a aplicar el resize
	 * @param width width a aplicar
	 * @param height height a aplicar
	 * @param viewbox viewbox del svg donde se encuentra el path
	 * @return Un nuevo PathArray con el tamaño indicado
	 * */
	private static getResizedPath(elem: Path, width: number, height: number, viewbox: ViewBox): PathArray {
		const pathArray = elem.array();
		// get bounding box of current situation
		const { width: widthViewbox, height: heightViewbox } = viewbox;
		const box = elem.bbox();
		let i, l;

		// If the box width or height is 0 then we ignore
		// transformations on the respective axis
		box.width = box.width === 0 ? 1 : box.width;
		box.height = box.height === 0 ? 1 : box.height;

		// recalculate position of all points according to new size
		for (i = pathArray.length - 1; i >= 0; i--) {
			l = pathArray[i][0];

			if (l === 'M' || l === 'L' || l === 'T') {
				pathArray[i][1] = ((Number(pathArray[i][1]) - box.x) * width) / widthViewbox + box.x / widthViewbox;
				pathArray[i][2] = ((Number(pathArray[i][2]) - box.y) * height) / heightViewbox + box.y / heightViewbox;
			} else if (l === 'H') {
				pathArray[i][1] = ((Number(pathArray[i][1]) - box.x) * width) / widthViewbox + box.x / widthViewbox;
			} else if (l === 'V') {
				pathArray[i][1] = ((Number(pathArray[i][1]) - box.y) * height) / heightViewbox + box.y / heightViewbox;
			} else if (l === 'C' || l === 'S' || l === 'Q') {
				pathArray[i][1] = ((Number(pathArray[i][1]) - box.x) * width) / widthViewbox + box.x / widthViewbox;
				pathArray[i][2] = ((Number(pathArray[i][2]) - box.y) * height) / heightViewbox + box.y / heightViewbox;
				pathArray[i][3] = ((Number(pathArray[i][3]) - box.x) * width) / widthViewbox + box.x / widthViewbox;
				pathArray[i][4] = ((Number(pathArray[i][4]) - box.y) * height) / heightViewbox + box.y / heightViewbox;

				if (l === 'C') {
					pathArray[i][5] = ((Number(pathArray[i][5]) - box.x) * width) / widthViewbox + box.x / widthViewbox;
					pathArray[i][6] = ((Number(pathArray[i][6]) - box.y) * height) / heightViewbox + box.y / heightViewbox;
				}
			} else if (l === 'A') {
				// resize radii
				pathArray[i][1] = (Number(pathArray[i][1]) * width) / widthViewbox + box.x / widthViewbox;
				pathArray[i][2] = (Number(pathArray[i][2]) * height) / heightViewbox + box.y / heightViewbox;

				// move position values
				pathArray[i][6] = ((Number(pathArray[i][6]) - box.x) * width) / widthViewbox + box.x / widthViewbox;
				pathArray[i][7] = ((Number(pathArray[i][7]) - box.y) * height) / heightViewbox + box.y / heightViewbox;
			}
		}

		return pathArray;
	}

	private static resizePolygon(elem: Polygon) {
		elem.plot(elem.array().size(1, 1));
	}

	private static resizePolyLine(elem: Polyline) {
		elem.plot(elem.array().size(1, 1));
	}

	private static resizePath(elem: Path, viewbox: ViewBox) {
		const newPath = this.getResizedPath(elem, 1, 1, viewbox);
		elem.plot(newPath.toString());
	}

	private static resizeRect(elem: Rect, viewbox: ViewBox) {
		const { width: widthViewbox, height: heightViewbox } = viewbox;
		elem.size(Number(elem.width()) / widthViewbox, Number(elem.height()) / heightViewbox);
		elem.x(Number(elem.x()) / widthViewbox);
		elem.y(Number(elem.y()) / heightViewbox);
		elem.attr('rx', elem.attr('rx') / widthViewbox);
		elem.attr('ry', elem.attr('ry') / heightViewbox);
	}

	private static resizeCircle(elem: Circle, viewbox: ViewBox) {
		const { width: widthViewbox, height: heightViewbox } = viewbox;

		elem.attr({
			cx: elem.attr('cx') / widthViewbox,
			cy: elem.attr('cy') / heightViewbox,
			r: elem.attr('r') / widthViewbox,
		});
	}

	private static resizeEllipse(elem: Ellipse, viewbox: ViewBox) {
		const { width: widthViewbox, height: heightViewbox } = viewbox;

		elem.attr({
			cx: elem.attr('cx') / widthViewbox,
			cy: elem.attr('cy') / heightViewbox,
			rx: elem.attr('rx') / widthViewbox,
			ry: elem.attr('ry') / heightViewbox,
		});
	}

	private static resizeLine(elem: Line, viewbox: ViewBox) {
		const { width: widthViewbox, height: heightViewbox } = viewbox;
		elem.attr({
			x1: elem.attr('x1') / widthViewbox,
			y1: elem.attr('y1') / heightViewbox,
			x2: elem.attr('x2') / widthViewbox,
			y2: elem.attr('y2') / heightViewbox,
		});
	}

	public static resizeSvgElement(elem: Element, viewbox: ViewBox) {
		if (elem instanceof Polygon) {
			this.resizePolygon(elem);
		}
		if (elem instanceof Polyline) {
			this.resizePolyLine(elem);
		}
		if (elem instanceof Path) {
			this.resizePath(elem, viewbox);
		}
		if (elem instanceof Rect) {
			this.resizeRect(elem, viewbox);
		}
		if (elem instanceof Circle) {
			this.resizeCircle(elem, viewbox);
		}
		if (elem instanceof Ellipse) {
			this.resizeEllipse(elem, viewbox);
		}
		if (elem instanceof Line) {
			this.resizeLine(elem, viewbox);
		}
		this.resizeTransform(elem, viewbox);
	}

	public static resizeTransform(elem: Element, viewbox: ViewBox) {
		const { width: widthViewbox, height: heightViewbox } = viewbox;
		const { translateX, translateY, rotate } = elem.transform();
		elem.attr(
			'transform',
			`translate(${Number(translateX) / widthViewbox}, ${Number(translateY) / heightViewbox}) rotate(${rotate})`
		);
	}

	public static isAcceptedElement(elem: Element): boolean {
		return (
			elem instanceof Polygon ||
			elem instanceof Polyline ||
			elem instanceof Path ||
			elem instanceof Rect ||
			elem instanceof Circle ||
			elem instanceof Ellipse ||
			elem instanceof Line
		);
	}

	static unserialize(data: any): Mask {
		const { id, name, content, keepRatio, isPlaceholder } = data;
		return new Mask(id, name, content, keepRatio, isPlaceholder);
	}
}

export default Mask;
