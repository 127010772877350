import '@/polyfills.ts';
import 'vue-toastification/dist/index.css';
import 'virtual:svg-icons-register';
import '@/index.css';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { GesturePlugin } from '@vueuse/gesture';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueGtag, { pageview } from 'vue-gtag';
import masonry from 'vue-next-masonry';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';

import App from '@/App.vue';
import { useAuth } from '@/composables/useAuth';
import { historyPlugin } from '@/stores/history-plugin';

const pinia = createPinia();
// @ts-ignore
pinia.use(historyPlugin);

const optionsToast: PluginOptions = {
	position: POSITION.BOTTOM_RIGHT,
	maxToasts: 3,
	containerClassName: ['custom-toast'],
	closeButtonClassName: [],
	bodyClassName: [''],
	toastClassName: ['bg-darkblue-900 rounded'],
};

Bugsnag.start({
	maxBreadcrumbs: 100,
	apiKey: import.meta.env.VITE_APP_BUGSNAG_KEY,
	appVersion: import.meta.env.VITE_APP_VERSION,
	plugins: [new BugsnagPluginVue()],
	onError: function (event) {
		const { user } = useAuth();
		if (user.value) {
			event.setUser(user.value.id, user.value.email);
		}
	},
	releaseStage: import.meta.env.PROD ? 'production' : 'testing',
	enabledReleaseStages: ['production'],
	autoTrackSessions: false,
});
Bugsnag.startSession();

const bugsnagVue: any = Bugsnag.getPlugin('vue');

createApp(App)
	.use(VueGtag, {
		config: { id: 'G-NVE4KXTJVW' },
		includes: [{ id: 'UA-193008345-1' }],
		enable: true,
	})
	.use(pinia)
	.use(GesturePlugin)
	.use(Toast, optionsToast)
	.use(masonry)
	.use(bugsnagVue)
	.mount('#app');

pageview({
	page_title: document.title,
	page_path: window.location.pathname,
	page_location: window.location.href,
});
