// Bugsnag
import Bugsnag from '@bugsnag/js';
import { useMouse } from '@vueuse/core';
// Vue
import { computed, Ref } from 'vue';

// Classes
import Element from '@/Classes/Element';
import Image from '@/Classes/Image';
// Composables
import { useTransform } from '@/composables/element/useTransform';
import { useArtboard } from '@/composables/project/useArtboard';
import { useMainStore } from '@/stores/store';
// Types
import { Anchor, Size } from '@/Types/types';
// Utils
import ElementTools from '@/utils/ElementTools';
import GAnalytics from '@/utils/GAnalytics';
import MathTools from '@/utils/MathTools';

const mouse = useMouse();

export const useElementTransform = (element: Ref<Element>) => {
	const { artboardSizeInPx: sizeInPx } = useArtboard();
	const usingTransform = useTransform(element);
	const store = useMainStore();

	const left = computed(() => (widthWithRotation.value - element.value.size.width) / 2);
	const top = computed(() => (heightWithRotation.value - element.value.size.height) / 2);
	const centerX = computed(() => sizeInPx.value.width / 2 - widthWithRotation.value / 2 + left.value);
	const centerY = computed(() => sizeInPx.value.height / 2 - heightWithRotation.value / 2 + top.value);
	const right = computed(() => sizeInPx.value.width - widthWithRotation.value + left.value);
	const bottom = computed(() => sizeInPx.value.height - heightWithRotation.value + top.value);

	const rotationInRadians = computed(() => MathTools.angleToRadians(element.value.rotation));

	const widthWithRotation = computed(() => {
		return (
			element.value.size.width * Math.abs(Math.cos(rotationInRadians.value)) +
			element.value.size.height * Math.abs(Math.sin(rotationInRadians.value))
		);
	});

	const heightWithRotation = computed(() => {
		return (
			element.value.size.height * Math.abs(Math.cos(rotationInRadians.value)) +
			element.value.size.width * Math.abs(Math.sin(rotationInRadians.value))
		);
	});

	const isAlignLeft = computed(() => Math.abs(element.value.position.x - left.value) <= 1);
	const isAlignCenter = computed(() => Math.abs(element.value.position.x - centerX.value) < 1);
	const isAlignRight = computed(() => Math.abs(element.value.position.x - right.value) < 1);

	const isAlignTop = computed(() => Math.abs(element.value.position.y - top.value) < 1);
	const isAlignMiddle = computed(() => Math.abs(element.value.position.y - centerY.value) < 1);
	const isAlignBottom = computed(() => Math.abs(element.value.position.y - bottom.value) < 1);

	const isOutsidePage = computed(() => {
		const isOutsideLeft = element.value.position.x + widthWithRotation.value < left.value;
		const isOutsideRight = element.value.position.x - widthWithRotation.value > right.value;
		const isOutsideX = isOutsideLeft || isOutsideRight;

		const isOutsideTop = element.value.position.y + heightWithRotation.value < top.value;
		const isOutsideBottom = element.value.position.y - heightWithRotation.value > bottom.value;
		const isOutsideY = isOutsideTop || isOutsideBottom;

		return isOutsideX || isOutsideY;
	});

	const isInOtherPage = computed(() => {
		let canvas: HTMLElement[] = [];

		if (store.selection.some((element) => element.id === element.id)) {
			canvas = Array.from(document.querySelectorAll<HTMLElement>("[data-canvas-visible='true']")).filter((canvaEl) => {
				const canvasRect = canvaEl.getBoundingClientRect();
				const id = canvaEl.getAttribute('data-id');

				const checkX = mouse.x.value >= canvasRect.left && mouse.x.value <= canvasRect.left + canvasRect.width;
				const checkY = mouse.y.value >= canvasRect.top && mouse.y.value <= canvasRect.top + canvasRect.height;

				return checkX && checkY && id !== store.activePageId;
			});
		}

		return {
			status: canvas.length > 0,
			canvas: canvas.length > 0 ? canvas[0] : null,
		};
	});

	const flipAxis = (axis: 'x' | 'y'): void => {
		if (element.value.locked) return;
		usingTransform.flipAxis(axis);

		GAnalytics.track('click', 'Button', `image-flip-${axis}`, null);
		Bugsnag.leaveBreadcrumb(`flip ${axis} axis to ${element.value.type}-${element.value.id}`);
	};

	const move = (x: number, y: number, relative = true): void => {
		if (element.value.locked) return;
		usingTransform.move(x, y, relative);
	};

	const scale = (scale: number) => {
		if (element.value.locked) return;
		usingTransform.scale(scale);
	};

	const resize = (width: number, height: number) => {
		if (element.value.locked) return;
		usingTransform.resize(width, height);
	};

	const rotate = (angle: number, relative = true) => {
		if (element.value.locked) return;
		usingTransform.rotate(angle, relative);
	};

	const align = (position: Anchor): void => {
		if (element.value.locked) return;
		usingTransform.align(position);

		GAnalytics.track('click', 'Button', `position-${position}`, null);
		Bugsnag.leaveBreadcrumb(`Align ${element.value.type}-${element.value.id} to ${position}`);
	};

	const adjustToHeight = (height: number): void => {
		if (element.value.locked) return;
		usingTransform.adjustTo('height', height);
	};

	const adjustToWidth = (width: number): void => {
		if (element.value.locked) return;
		usingTransform.adjustTo('width', width);
	};

	const adjustTo = (side: keyof Size, val: number) => {
		if (side === 'width') {
			adjustToWidth(val);
		}

		if (side === 'height') {
			adjustToHeight(val);
		}
	};

	const fitElementRegardingFactor = (factor: number) => {
		usingTransform.fitElementRegardingFactor(factor);
	};

	return {
		...usingTransform,
		flipAxis,
		move,
		scale,
		resize,
		rotate,
		adjustTo,
		align,
		fitElementRegardingFactor,
		isAlignLeft,
		isAlignCenter,
		isAlignRight,
		isAlignTop,
		isAlignMiddle,
		isAlignBottom,
		isOutsidePage,
		isInOtherPage,
		widthWithRotation,
		heightWithRotation,
	};
};
