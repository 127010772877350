<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { computed, ref } from 'vue';

import BasicShapesPanel from '@/components/panels/elements/BasicShapesPanel.vue';
import ElementsTypesPanel from '@/components/panels/elements/ElementsTypesPanel.vue';
import FlaticonPanel from '@/components/panels/elements/FlaticonPanel.vue';
import ImageMasksPanel from '@/components/panels/elements/ImageMasksPanel.vue';
import LinesAndArrowsPanel from '@/components/panels/elements/LinesAndArrowsPanel.vue';
import QRCodes from '@/components/panels/elements/QRCodes.vue';
import StickersPanel from '@/components/panels/elements/StickersPanel.vue';
import StorysetPanel from '@/components/panels/elements/StorysetPanel.vue';
// Components
import PanelHeader from '@/components/panels/PanelHeader.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { ElementCategory, ElementsPanelTypes } from '@/Types/types';
// Utils
import StringTools from '@/utils/StringTools';
const store = useMainStore();

// Data
const activeElementCategory = ref<ElementCategory | null>();

// Computeds
const elementTypeComponent = computed(() => {
	if (store.activeElementType === ElementsPanelTypes.Flaticon) return FlaticonPanel;
	if (store.activeElementType === ElementsPanelTypes.Storysets) return StorysetPanel;
	if (store.activeElementType === ElementsPanelTypes.Stickers) return StickersPanel;
	if (store.activeElementType === ElementsPanelTypes.BasicShapes) return BasicShapesPanel;
	if (store.activeElementType === ElementsPanelTypes.ImageMasks) return ImageMasksPanel;
	if (store.activeElementType === ElementsPanelTypes.LinesAndArrows) return LinesAndArrowsPanel;
	if (store.activeElementType === ElementsPanelTypes.QRCodes) return QRCodes;

	return null;
});

const title = computed(() => {
	if (!store.activeElementType) return 'Elements';

	if (activeElementCategory.value) {
		return activeElementCategory.value?.name;
	}

	return StringTools.getAllWordsCapitalized(store.activeElementType);
});

// Methods
const onGoBack = () => {
	if (activeElementCategory.value) {
		Bugsnag.leaveBreadcrumb(`Leave ${activeElementCategory.value.name} subcategory panel`);
		activeElementCategory.value = null;
		return;
	}

	store.activeElementType = null;
};

const onSetElementCategory = (cat: any) => {
	if (!cat) {
		activeElementCategory.value = cat;
		return;
	}

	if (store.activeElementType === ElementsPanelTypes.Flaticon) {
		activeElementCategory.value = {
			id: cat.id,
			name: cat.description,
		};
	}

	if (store.activeElementType === ElementsPanelTypes.Stickers) {
		activeElementCategory.value = {
			id: cat.tagsId,
			name: cat.name,
		};
	}
};

const { runOnMobile } = useDeviceInfo();
const onElementAdded = () => {
	runOnMobile(() => (store.activePanel = null));
};
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader :title="title" @goBack="onGoBack" />

		<component
			:is="elementTypeComponent"
			v-if="elementTypeComponent"
			:active-element-category="activeElementCategory"
			@setElementCategory="onSetElementCategory"
			@elementAdded="onElementAdded"
		/>

		<ElementsTypesPanel v-else />
	</div>
</template>
