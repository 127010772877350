<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue & Packges
import { computed, nextTick, ref, watch } from 'vue';

// Api
import { getTemplates } from '@/api/DataApiClient';
import InfiniteLoading from '@/components/Common/_InfiniteLoading.vue';
import AutocompleteInput from '@/components/Common/AutocompleteInput.vue';
// Components
import PanelHeader from '@/components/panels/PanelHeader.vue';
import AppendReplaceModal from '@/components/panels/templates/AppendReplaceModal.vue';
import TemplateCategoriesPanel from '@/components/panels/templates/TemplateCategoriesPanel.vue';
import TemplateSubcategoriesButtons from '@/components/panels/templates/TemplateSubcategoriesButtons.vue';
// Composables
import { useAvailableHeight } from '@/composables/useAvailableHeight';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { TemplateApi } from '@/Types/apiClient';
import { TemplateCategory } from '@/Types/types';
import TemplateLoader from '@/utils/TemplateLoader';
const store = useMainStore();

// Data
const parentCategory = ref<TemplateCategory | null>(null);
const search = ref('');
const selectedTemplate = ref<TemplateApi | null>(null);
const subcategories = ref<TemplateCategory[]>([]);

// Template refs
const container = ref();

// Using composables
useAvailableHeight(container);
const { isMobile } = useDeviceInfo();

// Computeds
const activeTemplateCategory = computed(() => store.activeTemplateCategory);

const hasCategory = computed(() => !!activeTemplateCategory.value?.name);

const isSearching = computed(() => !!search.value.length);

const autocompleteSource = computed(() => `autocomplete/wepikWeb?search=${search.value}`);

const source = computed(() => {
	if (hasCategory.value && !isSearching.value) {
		return `category/${activeTemplateCategory.value?.id}`;
	}

	return `search?mode=editor&q=${search.value}`;
});

const finalSource = ref(source.value);

watch(source, () => {
	finalSource.value = source.value;
});

const title = computed(() => activeTemplateCategory.value?.name || 'Templates');

// Watchers
watch(
	activeTemplateCategory,
	async (newVal) => {
		if (!newVal) {
			parentCategory.value = null;
			subcategories.value = [];
			return;
		}
	},
	{ immediate: true }
);

const { data: templates, isFetching } = getTemplates(finalSource, { refetch: true });

const loadMore = async () => {
	if (isFetching.value || !templates.value?.links?.next) return;
	finalSource.value = templates.value.links.next;
};

// Methods
const onClickTemplate = async (template: TemplateApi) => {
	selectedTemplate.value = template;
	Bugsnag.leaveBreadcrumb(`Click on template id: ${template.id};  slug: ${template.slug}`);

	// Obtenemos la nueva info del template seleccionado
	const templateData = await TemplateLoader.getTemplateData(template.slug, false);

	// Actualizamos la categoría del template para la pantalla de descarga
	store.downloadTemplateCategory = templateData.category_tree[2];
};

const onCloseModal = () => {
	selectedTemplate.value = null;
};

const onGoBack = () => {
	search.value = '';

	if (parentCategory.value?.id) {
		store.activeTemplateCategory = parentCategory.value;
		return;
	}

	store.activeTemplateCategory = null;
};

const updateSearchValue = async (searchValue: string | { id: number; name: string }) => {
	search.value = searchValue.id ? searchValue.name : searchValue;

	if (searchValue.id) {
		store.activeTemplateCategory = { id: searchValue.id, name: searchValue.name };
		await nextTick();
	}

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in templates panel: ${searchValue}`);
};
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader :title="title" @goBack="onGoBack" @closeModal="onCloseModal" />

		<AutocompleteInput
			:placeholder="'Search Template...'"
			:query="search"
			:autocomplete-source="autocompleteSource"
			@change="updateSearchValue"
		/>

		<TemplateCategoriesPanel v-if="!hasCategory && !isSearching" />

		<InfiniteLoading
			v-else
			:data="(templates && templates.data) || []"
			:is-fetching="isFetching"
			class="flex flex-col"
			masonry
			:masonry-cols="2"
			@load="loadMore"
		>
			<template #default>
				<TemplateSubcategoriesButtons v-if="subcategories.length" :subcategories="subcategories" />
			</template>
			<template #item="{ item }">
				<button
					class="relative mb-2 w-full overflow-hidden rounded bg-gray-700"
					:style="{ aspectRatio: item.metadata?.proportion }"
					@click.stop="onClickTemplate(item)"
				>
					<img
						v-if="item.previews.length"
						:src="item.previews[0].thumb"
						:alt="item.name"
						class="inset-0 w-full cursor-pointer rounded bg-gray-600 object-contain"
						draggable="false"
					/>
					<span
						v-if="item.pages > 1"
						class="pointer-events-none absolute top-2 right-2 z-10 flex h-4 items-center justify-center rounded-full bg-blue-500/80 px-2 text-2xs font-bold text-white"
						v-text="item.pages"
					></span>
				</button>
			</template>
		</InfiniteLoading>

		<AppendReplaceModal v-if="!!selectedTemplate" :selected-template="selectedTemplate" @close="onCloseModal" />
	</div>
</template>
