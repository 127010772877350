import { onStartTyping, useEventListener } from '@vueuse/core';

import { Text } from '@/Classes/Text';
import { useEditorClipboard } from '@/composables/useEditorClipboard';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
import { useUserImageProvider } from '@/composables/useUserImageProvider';
import { useZoom } from '@/composables/useZoom';
import { useHistoryStore } from '@/stores/history';
import { useMainStore } from '@/stores/store';
import GAnalytics from '@/utils/GAnalytics';

export const useEditorKeyboardEvents = () => {
	const store = useMainStore();
	const history = useHistoryStore();
	const { copySelection, pasteSelection, hasContent } = useEditorClipboard();
	const { listen } = useOrderedKeyboardListener();
	const { decreaseZoom, increaseZoom } = useZoom();
	const { getClipboardFilesAndUploadImages } = useUserImageProvider();

	listen('Escape', () => {
		store.clearSelection();
		store.illustratorSelection.clear();
	});

	listen(['z', 'Z'], (e) => {
		if (e.metaKey || e.ctrlKey) {
			e.preventDefault();
			history.rollback();
		}
	});

	listen(['y', 'Y'], (e) => {
		if (e.metaKey || e.ctrlKey) {
			e.preventDefault();
			history.forward();
		}
	});

	// Ctrl+c
	useEventListener(document.body, 'copy', (e: ClipboardEvent) => {
		// Si es un INPUT, permitimos que copie y salimos
		if (e.target instanceof HTMLInputElement && e.target?.tagName === 'INPUT') {
			return;
		}

		e.preventDefault();

		// Si no existe ninguna selección, salimos
		if (!store.selection.length) {
			return;
		}

		GAnalytics.track('click', 'Template', 'copy', null);
		copySelection();
	});

	// Ctrl+v
	useEventListener(document.body, 'paste', async (e: ClipboardEvent) => {
		// Si es un INPUT, analizamos el texto para evitar insertar elementos y actualizamos el input, luego salimos
		if (e.target instanceof HTMLInputElement && e.target?.tagName === 'INPUT') {
			const text = e.clipboardData?.getData('Text');

			// Prevenimos el comportamiento en caso de que empiece por 'wepik|'
			if (text && text.startsWith('wepik|')) {
				e.preventDefault();
			}

			return;
		}

		e.preventDefault();

		// Si estamos editando un texto, salimos
		if (store.textEditingId) {
			return;
		}

		// Si hay archivos para pegar priorizamos sobre elementos copiados
		if (e.clipboardData?.files.length) {
			getClipboardFilesAndUploadImages(e);
		} else if (hasContent.value) {
			GAnalytics.track('click', 'Template', 'paste', null);

			// Si tenemos algún elemento copiado y no estamos editando texto
			pasteSelection();
		}
	});

	// Control +|- to zoom in|out
	listen(['-'], (e) => {
		if (!(e.metaKey || e.ctrlKey)) {
			return;
		}

		e.preventDefault();
		decreaseZoom();
	});

	listen(['+'], (e) => {
		if (!(e.metaKey || e.ctrlKey)) {
			return;
		}

		e.preventDefault();
		increaseZoom();
	});

	// When your trying to write on a non writable element
	onStartTyping(() => {
		const selection = (store.selection.length ? store.selection[0] : false) as Text | false;

		// if the selected element is a text and we arent editing, we will start to edit this
		if (!store.textEditing && selection && selection.type === 'text') {
			store.textEditingId = selection.id;
		}
	});
};
