<script setup lang="ts">
// Vue
import { toRef } from 'vue';

// Classes
import Storyset from '@/Classes/Storyset';
// Components
import GradientsRenderer from '@/components/render/GradientsRenderer.vue';
// Composables
import { useStorysetColors } from '@/composables/element/storyset/useStorysetColors';

// Props
const props = defineProps<{ element: Storyset; forPreview?: boolean, scale: number; smartSelection?: boolean }>();

// Data
const element = toRef(props, 'element');

// Using composables
const { gradientColors } = useStorysetColors(element);
</script>

<template>
	<svg
		:viewBox="element.viewbox"
		:width="element.size.width"
		:height="element.size.height"
		:style="{
			'--main-storyset-color': element.mainColor.toShapeString(),
		}"
		preserveAspectRatio="none"
		v-html="element.content"
	></svg>
	<GradientsRenderer :gradients="gradientColors" />
</template>
