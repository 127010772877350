import { useFeedback } from '@/composables/interactions/useFeedback';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useActivePage } from '@/composables/page/useActivePage';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
import { useMainStore } from '@/stores/store';
import GAnalytics from '@/utils/GAnalytics';

interface MoveConfig {
	axis: 'x' | 'y';
	dir: 1 | -1;
}

export const useSelectionKeyboardEvents = () => {
	const store = useMainStore();
	const { removeElement } = useActivePage();
	const { deleteFeedback } = useFeedback();
	const { listen } = useOrderedKeyboardListener();
	const { moveable } = useInteractions();

	// Move every element in selection using keyboard arrows
	const deleteByKeyboard = () => {
		if (document.activeElement?.tagName === 'INPUT') return;

		store.selection.forEach((el) => {
			removeElement(el);
			GAnalytics.track('click', 'Template', `remove-${el.type === 'text' ? el.type : 'custom'}`, null);
			deleteFeedback();
		});
	};

	const init = () => {
		listen('Escape', () => {
			store.clearSelection();
		});

		listen(['Delete', 'Backspace'], deleteByKeyboard);

		listen('ArrowUp', moveByArrow);
		listen('ArrowLeft', moveByArrow);
		listen('ArrowDown', moveByArrow);
		listen('ArrowRight', moveByArrow);
	};

	const moveByArrow = (e: KeyboardEvent) => {
		if (store.textEditing || document.activeElement?.tagName === 'INPUT') return;

		e.preventDefault();

		GAnalytics.track('keypress', 'Template', `move-element-with-keyboard`, null);

		const config: MoveConfig = {
			axis: ['ArrowLeft', 'ArrowRight'].includes(e.key) ? 'x' : 'y',
			dir: ['ArrowRight', 'ArrowDown'].includes(e.key) ? 1 : -1,
		};

		const amount = config.dir * (e.shiftKey ? 10 : 1);

		moveable.value?.request(
			'draggable',
			{
				deltaX: config.axis === 'x' ? amount : 0,
				deltaY: config.axis === 'y' ? amount : 0,
			},
			true
		);
	};

	return {
		deleteByKeyboard,
		init,
		moveByArrow,
	};
};
