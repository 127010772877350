import { GradientColor } from '@/Classes/GradientColor';
import { SerializableClass } from '@/Classes/SerializableClass';
import { SolidColor } from '@/Classes/SolidColor';

type OverlayType = 'solid' | 'gradient';
type BlendMode =
	| 'overlay'
	| 'normal'
	| 'multiply'
	| 'screen'
	| 'darken'
	| 'lighten'
	| 'color-dodge'
	| 'color-burn'
	| 'hard-light'
	| 'soft-light'
	| 'difference'
	| 'exclusion'
	| 'hue'
	| 'saturation'
	| 'color'
	| 'luminosity';

export abstract class Overlay extends SerializableClass {
	type: OverlayType;
	blendMode: BlendMode;
	opacity: number;

	protected constructor(type: OverlayType, blendMode: BlendMode, opacity: number) {
		super();
		this.type = type;
		this.blendMode = blendMode;
		this.opacity = opacity;
	}

	abstract toCssBackground(): string;

	static unserialize(data: any): Overlay {
		if (data.type === 'solid') {
			return new SolidOverlay(SolidColor.fromObject(data.color), data.blendMode, data.opacity);
		}

		return new GradientOverlay(GradientColor.fromObject(data.color), data.blendMode, data.opacity);
	}
}

export class SolidOverlay extends Overlay {
	color: SolidColor;

	constructor(color: SolidColor, blendMode: BlendMode, opacity = 1) {
		super('solid', blendMode, opacity);
		this.color = color;
	}

	toCssBackground(): string {
		return this.color.toCssString();
	}
}

export class GradientOverlay extends Overlay {
	color: GradientColor;

	constructor(color: GradientColor, blendMode: BlendMode, opacity = 1) {
		super('gradient', blendMode, opacity);
		this.color = color;
	}

	toCssBackground(): string {
		return this.color.toCssString();
	}
}
