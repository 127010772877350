import Bugsnag from '@bugsnag/js';
import { useDebounceFn, useTimeoutFn } from '@vueuse/core';
import { debounce, keyBy } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { PiniaPluginContext } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref } from 'vue';

import { saveProject as apiSaveProject, syncProject } from '@/api/UserApiClient';
import { HistoryState } from '@/Classes/HistoryState';
import Page from '@/Classes/Page';
import { useEditorMode } from '@/composables/useEditorMode';
import { useToast } from '@/composables/useToast';
import { useHistoryStore } from '@/stores/history';
import { MainState, useMainStore } from '@/stores/store';
import { SyncData } from '@/Types/history';

/**
 * Plugin para el store que gestiona el historial y el auto guardado
 * @param context
 */
export function historyPlugin(context: PiniaPluginContext<'project', MainState>) {
	// Este plugin solo es para el store principal
	if (context.store.$id !== 'project') {
		return;
	}

	const history = useHistoryStore();
	const { isEditorMode, inSlidesgoContext } = useEditorMode();
	const mainStore = useMainStore();
	const ready = ref(false);
	const toast = useToast();
	const lastSyncDate = ref(new Date());
	const lastStateDate = ref(new Date());

	/**
	 * Crea un nuevo HistoryState y lo añade al store con los cambios
	 */
	const saveState = debounce(async () => {
		if (ready.value === false || mainStore.croppingId) {
			return;
		}

		history.$patch(() => {
			// si no estamos en el último estados, descartamos todos los que esten por detras
			if (history.activeState !== history.states[history.states.length - 1] && history.activeState) {
				history.states = history.states.slice(0, history.activeState.index + 1);
			}
			lastStateDate.value = new Date();
			const previousState = history.states[history.states.length - 1];

			// @ts-ignore
			const h = new HistoryState(context.store, Object.keys(history.states).length, previousState);
			history.states.push(h);
			history.activeState = h;

			triggerSync();
		});
	}, 500);

	/**
	 * Nos suscribemos a los cambios del store y filtramos por los que nos interesa crear un nuevo estado,
	 * es decir, los cambios en los templates.
	 */
	context.store.$subscribe(
		(mutation) => {
			// @ts-ignore
			if (window.moving) {
				return;
			}

			if (window.fromHistory) {
				window.fromHistory = false;
				return;
			}

			if (!history.states.length) {
				// si no tenemos un estado inicial, no nos interesan los cambios
				return;
			}

			saveState();
		},
		{ immediate: false }
	);

	const syncing = ref(false);
	const pendingSync = ref(false);
	const internalTriggerSync = useDebounceFn(() => {
		pendingSync.value = false;
		sync(0);
	}, 3000);

	const triggerSync = async () => {
		if (!isEditorMode.value) {
			return;
		}
		pendingSync.value = true;
		internalTriggerSync();
	};

	/**
	 * Crea el vector del usuario
	 */
	const saveProject = async () => {
		const body: SyncData = {
			width: context.store.size.width,
			height: context.store.size.height,
			unit: context.store.unit,
			name: context.store.name,
			vector_id: context.store.sourceVectorId,
			id: context.store.id,
			editorVersion: 'next-gen',
			project: inSlidesgoContext.value ? 'slidesgo' : 'wepik',
		};

		const { data } = await apiSaveProject(body).json();

		mainStore.userVector = data.value;

		window.history.replaceState('', '', `/edit/${data.value.uuid}`);
	};

	const serverVersions: { [id: string]: Page } = {};

	/**
	 * Negocia la sincronización con el servidor
	 */
	const performSync = async (fullSync: string[] = [], pages: Page[] = [], attempt = 0) => {
		// Guaradmos una copia de la página más reciente antes de lanzar el request.
		// Vamos a guardar ese copia como la versión que tenemos en el servidor
		// Para que los diffs se hagan respecto a esa
		const pagesAtSync = keyBy<Page>(cloneDeep(pages.length > 0 ? pages : context.store.pages) as Page[], 'id');
		const history = HistoryState.generateSyncData(serverVersions, pagesAtSync, fullSync);

		if (history.length === 0) {
			return;
		}

		const body: SyncData = {
			width: context.store.size.width,
			height: context.store.size.height,
			unit: context.store.unit,
			name: context.store.name,
			vector_id: context.store.sourceVectorId,
			uuid: context.store.id,
			editorVersion: 'next-gen',
			project: inSlidesgoContext.value ? 'slidesgo' : 'wepik',
		};

		Bugsnag.leaveBreadcrumb('Sync request data', { ...body, history });

		const { data } = await syncProject({
			...body,
			history,
		});

		if (Array.isArray(data.value) === false) {
			throw new Error('Sync failed');
			return;
		}

		// Update the server version for in ok syncs
		data.value.filter((result) => result.success).forEach(({ id }) => (serverVersions[id] = pagesAtSync[id]));

		const fullSyncRequests = data.value.filter((item) => !item.success).map((item) => item.id);

		if (fullSync.length > 0 && fullSyncRequests.length > 0) {
			throw new Error("Can't sync all pages");
		}

		if (fullSyncRequests.length > 0) {
			console.warn('Full sync requested', fullSyncRequests);
			Bugsnag.notify('Full sync requested');
			const fullSync = data.value.filter((result) => !result.success).map(({ id }) => id);
			await performSync(fullSync);
		}

		lastSyncDate.value = new Date();
	};

	/**
	 * Lanza un sync con todo el contenido que tenemos en el estado actual
	 */
	const performSyncWithStateInUse = async () => {
		history.lastChangeFromNavigation = false;
		syncing.value = true;
		try {
			await performSync([], history.activeState?.pages as Page[]);
		} catch (e) {
			console.warn('sync failed', e);
		} finally {
			syncing.value = false;
		}
	};

	/**
	 * Inicial el flujo de sincronizacion y controla reintentos.
	 * @param attempt
	 */
	const sync = async (attempt: number) => {
		if (!mainStore.user) {
			return;
		}

		if (history.states.length < 1) {
			return;
		}

		// si ya estabamos en proceso se sincronizar, lo volvemos a lanzar
		// para cuando termine
		if (syncing.value && !attempt) {
			return await triggerSync();
		}

		syncing.value = true;

		if (attempt > 5) {
			syncing.value = false;
			pendingSync.value = true;
			toast.error('Sync error, your changes cannot be saved');
			return;
		}

		if (!mainStore.userVector) {
			try {
				await saveProject();
			} catch (e: any) {
				console.error(e);
				Bugsnag.notify(`Error on create user vector: ${e.message}`);
				// si falla, volvemos a intentarlo
				useTimeoutFn(() => sync(attempt + 1), 2000 * attempt);
				return;
			}
		}

		try {
			await performSync();
		} catch (e) {
			console.error(e);
			// si falla, volvemos a intentarlo

			return await new Promise((resolve) => {
				setTimeout(() => sync(attempt + 1).then(resolve), 1000 * attempt);
			});
		}

		syncing.value = false;
	};

	const initSync = async (forceSave = false) => {
		if (forceSave) {
			await performSync();
		}
		// Si estamos comenzando a editar uno ya existente, declaramos las server versions
		if (mainStore.userVector && Object.keys(serverVersions).length === 0) {
			(context.store.pages as Page[]).forEach((p) => (serverVersions[p.id] = cloneDeep(p)));

			// Marcamos como ID de estado inicial el estado anterior del primer vector de usuario
			history.states[0].id = uuidv4();
		}

		lastSyncDate.value = new Date();
		lastStateDate.value = new Date();
		ready.value = true;
	};

	const allChangesSaved = computed(() => {
		return (
			!syncing.value &&
			!history.lastChangeFromNavigation &&
			!pendingSync.value &&
			lastSyncDate.value >= lastStateDate.value
		);
	});

	return {
		syncing,
		pendingSync,
		allChangesSaved,
		triggerSync,
		initSync,
		saveState,
		performSyncWithStateInUse,
	};
}

declare module 'pinia' {
	export interface PiniaCustomProperties {
		pendingSync: boolean;
		syncing: boolean;
		unsyncChanges: boolean;
		allChangesSaved: boolean;
		triggerSync(): void;
		initSync(force: boolean): void;
		saveState(): void;
		performSyncWithStateInUse(): void;
	}
}
