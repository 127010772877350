<script lang="ts" setup>
import { ref, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import OnClickOutsideWithPortals from '@/components/Common/OnClickOutsideWithPortals';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import TextShadowMenu from '@/components/selection/panels/TextShadowMenu.vue';
import { useTextEffects } from '@/composables/element/text/useTextEffects';
import { useTextStyles } from '@/composables/element/text/useTextStyles';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const isOpen = ref(false);

const { hasMultiShadow, hasShadow } = useTextEffects(element);
const { resetTextShadow } = useTextStyles(element);

const reset = () => {
	isOpen.value = false;
	resetTextShadow();
};
</script>

<template>
	<OnClickOutsideWithPortals data-testid="text-shadow" @trigger="isOpen = false">
		<div
			v-show="isOpen"
			ref="textShadowContainer"
			class="fixed bottom-16 left-0 z-10 w-full rounded-tl-2xl rounded-tr-2xl bg-gray-700 px-4 py-3 text-left lg:absolute lg:bottom-auto lg:left-auto lg:top-0 lg:-right-50 lg:ml-2 lg:w-48 lg:rounded-lg lg:bg-gray-800 lg:px-3 lg:shadow-custom"
		>
			<div class="mb-4 flex justify-between">
				<button class="flex items-center text-xs text-gray-100 hover:text-white" @click="reset">
					<SvgIcon name="reload" class="mr-2 h-3 w-3" />
					Reset
				</button>
				<button
					class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
					@click="isOpen = false"
				>
					<SvgIcon name="cross" class="h-3 w-3" />
				</button>
			</div>
			<TextShadowMenu v-if="isOpen" :element="element" @close="isOpen = !isOpen" />
		</div>

		<button
			class="flex h-11 w-14 shrink-0 flex-col items-center justify-between hover:text-white lg:h-8 lg:w-8 lg:justify-center"
			:class="{
				'text-white': hasShadow && !hasMultiShadow,
				'text-gray-100': !hasShadow || hasMultiShadow,
			}"
			tooltip="Text shadow"
			tooltip-position="top"
			@click="isOpen = !isOpen"
		>
			<SvgIcon name="text-shadow" class="h-5 w-5 lg:h-4 lg:w-4" />
			<span
				v-if="hasShadow && !hasMultiShadow"
				class="absolute top-0 right-2 h-2 w-2 rounded-full border border-blue-400 bg-blue-500"
			></span>
			<p class="text-2xs lg:hidden">Shadow</p>
		</button>
	</OnClickOutsideWithPortals>
</template>
