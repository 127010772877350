<script lang="ts" setup>
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';

const emit = defineEmits(['close']);

const onClose = () => emit('close');
</script>

<template>
	<Modal open>
		<div
			class="relative flex flex-col items-center justify-center overflow-hidden rounded-md bg-white px-8 py-8 sm:px-16"
		>
			<SvgIcon name="alert" class="mb-6 h-10 w-10 text-red-500" />
			<h1 class="text-2xl font-bold text-gray-800">Something went wrong</h1>
			<p class="text-lg text-gray-600">There was a problem while trying to process your work, please try again</p>
			<div class="mt-6 flex flex-col gap-4 sm:flex-row">
				<button
					class="rounded border-2 border-red-500 bg-red-500 px-4 py-2 font-semibold text-white hover:border-red-600 hover:bg-red-600"
					@click="onClose"
				>
					Ok
				</button>
			</div>
		</div>
	</Modal>
</template>
