<script lang="ts" setup>
// Vue & Packages
import { useEventListener } from '@vueuse/core';
import { ref } from 'vue';

import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import {
	BasicShapeApi,
	FlaticonElementApi,
	ImageApi,
	MaskApi,
	StickersElementApi,
	StorysetApi,
} from '@/Types/apiClient';
import { ElementsPanelTypes, Panels } from '@/Types/types';
const store = useMainStore();

const { isMobile } = useDeviceInfo();

// Props
const props = defineProps<{
	draggable: boolean;
	data: BasicShapeApi | FlaticonElementApi | ImageApi | MaskApi | StickersElementApi | StorysetApi;
	type: ElementsPanelTypes | Panels;
}>();

// Data
const el = ref();
const dragging = ref(false);

// Methods
useEventListener(el, 'dragstart', (e: DragEvent) => {
	if (!e.dataTransfer) return;

	if (isMobile.value) {
		e.preventDefault();
		return false;
	}

	if (!props.draggable) {
		e.preventDefault();
		return false;
	}
	dragging.value = true;

	store.draggingItem = {
		data: props.data,
		type: props.type,
	};
	e.dataTransfer.effectAllowed = 'copyMove';
});

useEventListener(el, 'dragend', () => {
	store.draggingItem = null;
	dragging.value = false;
});
</script>

<template>
	<button ref="el" data-testid="item-panel" class="flex w-full" :class="dragging ? 'opacity-25' : ''">
		<slot></slot>
	</button>
</template>
