import { v4 as uuidv4 } from 'uuid';

import Page from '@/Classes/Page';
import { Size, Unit } from '@/Types/types';

class Project {
	id: string;
	name: string | null;
	category: string | null;
	flaticonSearch: string | null;
	pages: Page[];
	size: Size;
	unit: Unit;
	sourceVectorId: number;

	constructor(width: number, height: number, unit: Unit, sourceVectorId: number) {
		this.pages = [];
		this.size = {
			width,
			height,
		};
		this.unit = unit;
		this.sourceVectorId = sourceVectorId;
		this.id = uuidv4();
		this.name = '';
		this.category = '';
		this.flaticonSearch = '';
	}
}

export default Project;
