import { Element as SvgElement } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';

import { GradientColor } from '@/Classes/GradientColor';
import Line from '@/Classes/Line';
import { StopGradient } from '@/Types/colorsTypes';
import { Size } from '@/Types/types';

class ElementTools {
	static getSizeKeepingAspectRatioByHeight = (size: Size, height: number): Size => {
		const ratio = size.width / size.height;
		return {
			width: ratio * height > 1 ? ratio * height : 1,
			height: ratio * height > 1 ? height : 1 / ratio,
		};
	};

	static getSizeKeepingAspectRatioByWidth = (size: Size, width: number): Size => {
		const ratio = size.height / size.width;
		return {
			height: ratio * width > 1 ? ratio * width : 1,
			width: ratio * width > 1 ? width : 1 / ratio,
		};
	};

	static getElementType(el: SvgElement) {
		if (el.data('stories')) return 'storyset';
		if (el.data('isline')) return 'line';
		if (el.data('type')) return el.data('type');
		if ((el.id().includes('image') && el.find('image').length) || el.data('old-crop')) return 'image';
		if (el.type === 'g' && el.first().type === 'foreignObject') return 'g-text';

		return `native-${el.type}`;
	}

	static getIdFromUrl(attr: string): string {
		return attr.replace('url(', '').replace(')', '').replaceAll('"', '');
	}

	static getTransformValues(transform: string): number[] {
		// Obtenemos los números que hay en el attr
		return Array.from(transform.matchAll(/-?(\d+\.?\d*)/g), (coord) => parseFloat(coord[0]));
	}

	static svgGradientToObject(gradient: SvgElement): GradientColor {
		let deg;

		// Si existen coordenadas X e Y, priorizamos el ángulo de rotación obtenido de ellas y obtenemos los grados
		if (
			gradient.attr('x1') !== undefined &&
			gradient.attr('x2') !== undefined &&
			gradient.attr('y1') !== undefined &&
			gradient.attr('y2') !== undefined
		) {
			const scaleX = gradient.transform().a;
			const scaleY = gradient.transform().d;
			const deltaX = (gradient.attr('x2') - gradient.attr('x1')) * (scaleX ? scaleX : 1);
			const deltaY = (gradient.attr('y2') - gradient.attr('y1')) * (scaleY ? scaleY : 1);

			const rad = Math.atan2(deltaY, deltaX); // Radians
			deg = rad * (180 / Math.PI);
		} else {
			deg = gradient.transform().rotate || 0;
		}

		// Se suman 90 para que el grado 0 de SVG sea igual al grado 0 de CSS
		// OJO: Si se eliminan los 90 grados, el color preview y el elemento tendrán angulos distintos
		const rotation = deg + 90;
		const stops = gradient.find('stop').map((stop) => {
			const color = stop.css('stop-color') || stop.attr('stop-color');
			const opacity = stop.css('stop-opacity') || stop.attr('stop-opacity');

			const [r, g, b] = Normalize(color.toString());
			const offset = parseFloat(stop.attr('offset')) * 100;

			return {
				r: r * 255,
				g: g * 255,
				b: b * 255,
				a: opacity,
				offset,
			} as any as StopGradient;
		});

		return GradientColor.fromObject({
			type: gradient.type.includes('linear') ? 'linear' : 'radial',
			rotation,
			stops,
		});
	}

	static checkIfIsDefsElement(el: SvgElement) {
		const isDefs = el.type === 'defs' || el.node.closest('defs');
		const isGradient =
			el.type === 'linearGradient' ||
			el.type === 'radialGradient' ||
			el.type === 'stop' ||
			el.node.closest('linearGradient') ||
			el.node.closest('radialGradient');

		return !!isDefs || !!isGradient;
	}

	static fixDefsPosition(el: SvgElement) {
		el.find('linearGradient, radialGradient, filter, clipPath, mask').forEach((defsEl) => {
			el.defs().add(defsEl);
		});
	}

	static changeGradientsReferencesByCloneStops(el: SvgElement) {
		el.find('linearGradient, radialGradient').forEach((gradient) => {
			const gradientReferenceId = gradient.attr('xlink:href');
			if (!gradientReferenceId) return;
			const gradientReference = el.findOne(gradientReferenceId);

			if (!gradientReference) {
				return;
			}

			gradientReference.children().forEach((stop) => {
				const clone = stop.clone();
				gradient.add(clone);
			});
		});
	}

	static fixDashArray(dasharray: number[]): number[] {
		const defaultDasharrays = Line.defaultDasharrays();
		const isValidDashArray = defaultDasharrays.find((el) => el.toString() === dasharray.toString());

		if (isValidDashArray) {
			return dasharray;
		}

		// Buscamos el valor más cercano a uno de los valores por defecto
		const diffs = defaultDasharrays.map((da) => {
			const diff1 = da[0] - (dasharray[0] || 0);
			const diff2 = da[1] - (dasharray[1] || 0);

			return diff1 + diff2;
		});
		const value = diffs.reduce(function (prev, curr) {
			return Math.abs(curr) < Math.abs(prev) ? curr : prev;
		}, Infinity);
		const index = diffs.findIndex((el) => el === value);

		return defaultDasharrays[index];
	}

	static getClassStyles(styleSheet: string, className: any) {
		className = '.' + className;

		let classContent = styleSheet.substring(styleSheet.indexOf(className) + className.length + 1, styleSheet.length);
		classContent = classContent.substring(0, classContent.indexOf('}'));

		const classProps = classContent
			.split(';')
			.filter((el) => el.length)
			.map((prop) => {
				const [key, value] = prop.split(':');

				return {
					key: key.trim() as CSSStyleName,
					value: value.trim(),
				};
			});

		return classProps;
	}
}

export default ElementTools;
