// Bugsnag
import Bugsnag from '@bugsnag/js';
import { computed, nextTick, Ref } from 'vue';

import Image from '@/Classes/Image';
import { useImage } from '@/composables/element/image/useImage';
import { useImageOrder } from '@/composables/element/image/useImageOrder';
import { useImageTransform } from '@/composables/element/image/useImageTransform';
import { useMainStore } from '@/stores/store';
import GAnalytics from '@/utils/GAnalytics';

export const useImageBackground = (image: Ref<Image>) => {
	const { clearSelection, setSelection } = useMainStore();

	const { fitAndCenterInArtboard } = useImageTransform(image);
	const { isBackground, page, removeFromGroup } = useImage(image);
	const { moveToBack } = useImageOrder(image, isBackground);

	const canBeSetAsBackground = computed(() => !image.value.locked && !page.value?.backgroundImageId);

	const setImageAsBackground = async () => {
		if (!canBeSetAsBackground.value) throw new Error('This image can not be set as background');

		// Clear rotation, remove mask
		image.value.rotation = 0;
		image.value.setMask(null);

		fitAndCenterInArtboard();

		// Set image as locked to disable transforms (translate|rotate|scale)
		image.value.setLocked(true);

		// reset group (background cannot group)
		removeFromGroup();
		clearSelection();
		await nextTick();
		await setSelection(image.value, false);

		// Set as first in Page.elements[]
		moveToBack();

		// Set as background in Page
		page.value!.backgroundImageId = image.value.id;
		GAnalytics.track('click', 'Button', 'background-image', null);
		Bugsnag.leaveBreadcrumb(`image-${image.value.id} to background`);
	};

	const removeImageAsBackground = () => {
		if (!isBackground.value) throw new Error('Image is not set as background');

		image.value.setLocked(false);

		page.value!.backgroundImageId = null;

		Bugsnag.leaveBreadcrumb(`Remove image-${image.value.id} to background`);
	};

	return {
		canBeSetAsBackground,
		setImageAsBackground,
		removeImageAsBackground,
	};
};
