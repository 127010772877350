<script setup lang="ts">
import { watch } from 'vue';

import ColorPickerGroup from '@/components/Common/Color/ColorPickerGroup.vue';
// Composables
import { useEditorMode } from '@/composables/useEditorMode';
import { useProjectColors } from '@/composables/useProjectColors';

// Using composables
const { isPhotoMode } = useEditorMode();
const { colors, textsColors, linesStrokeColors, updateTemplateColor, colorSelected, getElementsWithColorSelected } =
	useProjectColors();

watch(
	colorSelected,
	() => {
		getElementsWithColorSelected();
	},
	{ immediate: true }
);
</script>

<template>
	<div
		data-onboarding="project-colors"
		class="flex items-center"
		:class="{ 'mr-1 border-r border-gray-600 pr-3': isPhotoMode && !!colors.length }"
	>
		<ColorPickerGroup
			class="h-6 lg:h-7"
			picker-class="w-6 lg:w-7 "
			:max="4"
			:colors="colors"
			:texts-colors="textsColors"
			:lines-stroke-colors="linesStrokeColors"
			:hide-alpha="true"
			@change="({ color, newColor }) => updateTemplateColor(color, newColor)"
			@select="colorSelected = $event"
		/>
	</div>
</template>
