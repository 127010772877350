// Bugsnag
import Bugsnag from '@bugsnag/js';
import { useMousePressed } from '@vueuse/core';
import { isEmpty } from 'lodash';
import Selecto, { OnDragStart, OnSelect, OnSelectEnd } from 'selecto';
import { computed, ref } from 'vue';

import Element from '@/Classes/Element';
import Image from '@/Classes/Image';
import { useGhostMoveable } from '@/composables/element/image/useCrop';
import { useCropPhotoModeMoveable } from '@/composables/element/image/useCropPhotoMode';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useMoveable } from '@/composables/interactions/useInteractiveElements';
import { useActivePage } from '@/composables/page/useActivePage';
import { useProject } from '@/composables/project/useProject';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMainStore } from '@/stores/store';
import GAnalytics from '@/utils/GAnalytics';

let firstClickDate = new Date();
const selecto = ref();
const disableToolbar = ref(false);
const { moveable, action, dragAction, isMiddleHandler } = useMoveable();
const { ghostMoveable } = useGhostMoveable();
const { cropPhotoModeMoveable } = useCropPhotoModeMoveable();

const isDragging = computed(() => action.value === 'drag');
const isResizing = computed(() => action.value === 'resize');
const isSelecting = computed(() => action.value === 'select');
const isRotating = computed(() => action.value === 'rotate');
const isIdle = computed(() => action.value === 'idle');
const isDragRemove = computed(() => dragAction.value === 'remove');
const isDragMove = computed(() => dragAction.value === 'move');

const { pressed } = useMousePressed();

export const useInteractions = () => {
	const store = useMainStore();
	const isCropping = computed(() => !!store.croppingId);
	const isEditingText = computed(() => !!store.textEditingId);
	const { getElementFromDom } = useProject();
	const { getElementFromDom: getElementFromActivePageDom } = useActivePage();
	const temporalRef = ref<Element>(Image.createDefault());
	const usingElementOrchestrator = useElementOrchestrator(temporalRef);
	const { isPhotoMode, isIllustratorContext } = useEditorMode();
	const { isMobile, isTouch } = useDeviceInfo();

	const setupInteraction = () => {
		selecto.value = createSelectoInstance();
		registerEvents();
	};

	const createSelectoInstance = () => {
		const scrollContainer = document.getElementById('scroll-area');
		return new Selecto({
			// The container to add a selection element
			container: scrollContainer,
			// Selecto's root container (No transformed container. (default: null)
			rootContainer: null,
			// Targets to select. You can register a queryselector or an Element.
			selectableTargets: ['.target', '.editable', '[data-illustrator-link]'],
			// Whether to select by click (default: true)
			selectByClick: false,
			// Whether to select from the target inside (default: true)
			selectFromInside: true,
			// After the select, whether to select the next target with the selected target (deselected if the target is selected again).
			continueSelect: false,
			// Determines which key to continue selecting the next target via keydown and keyup.
			toggleContinueSelect: isIllustratorContext.value ? null : ['shift'],
			// The container for keydown and keyup events
			keyContainer: window,
			// The rate at which the target overlaps the drag area to be selected. (default: 100)
			hitRate: isIllustratorContext.value ? 0 : 1,
		});
	};

	const registerEvents = () => {
		selecto.value
			.on('select', (e: OnSelect) => selectingHandler(e))
			.on('selectEnd', (e: OnSelectEnd) => selectionHandler(e))
			.on('dragStart', async (e: OnDragStart) => dragStartHandler(e));
	};
	/**
	 * e nos devuelve un array con elementos seleccionados (e.added) y eliminados (e.removed)
	 * añadimos o eliminamos de la selección según corresponda
	 * */
	const selectionHandler = (e: OnSelectEnd) => {
		if (!store.activePage) {
			return;
		}

		const isShiftPressed = e.inputEvent?.shiftKey || false;

		const selectedElements = (
			e.selected
				.map((domEl: HTMLElement | SVGElement) => getElementFromActivePageDom(domEl as HTMLElement))
				.filter((el: Element | undefined) => el) as Element[]
		).filter((element) => {
			// Filtramos que no incluya al background ya que no puede pertenecer a un grupo
			if (element instanceof Image) {
				temporalRef.value = element;
				return !(usingElementOrchestrator.value as any).isBackground.value;
			}
			return true;
		});

		// Selección del modo illustrator
		const illustratorSelection = e.selected.filter((el) => el.dataset.illustratorLink);

		store.$patch(() => {
			illustratorSelection.forEach((el) => {
				store.illustratorSelection[isShiftPressed ? 'delete' : 'add'](el.dataset.illustratorLink as string);
				el.classList.remove('tree-element-selection');
			});

			selectedElements.forEach((element) => {
				store.setSelection(element, !!store.selection.length);
			});
		});

		action.value = 'idle';

		if (!isEmpty(selectedElements)) {
			Bugsnag.leaveBreadcrumb(`Mouse selection: ${selectedElements.map((el) => `${el.type}-${el.id}`)}`);

			if (selectedElements.every((e) => e.type === 'text') && selectedElements.length > 1) {
				// En caso de haber más de un elemento de tipo texto
				GAnalytics.track('click', 'Template', 'select-multiple-texts', null);
			} else if (selectedElements.length > 1) {
				// En caso de haber más de un elemento de varios tipos
				GAnalytics.track('click', 'Template', 'select-multiple-elements', null);
			} else {
				// En caso de solo haber un elemento
				GAnalytics.track('click', 'Template', `select-${selectedElements[0].type}`, null);
			}
		}
	};

	const selectingHandler = (e: OnSelect) => {
		e.added.forEach((el) => {
			if (!el.closest(`#canvas-${store.activePage?.id}`)) {
				return;
			}

			el.classList.add(el.dataset.illustratorLink ? 'tree-element-selection' : 'ring-custom-select');
		});

		e.removed.forEach((el) => {
			el.classList.remove(el.dataset.illustratorLink ? 'tree-element-selection' : 'ring-custom-select');
		});
	};

	const dragStartHandler = (e: OnDragStart) => {
		if (e.inputEvent?.touches?.length > 1) {
			e.stop();
			return;
		}

		// El evento de drag start en móvil se lanza dos veces. Esto provoca errores
		// como que el crop se active sin querer al recibir doble evento en muy poco tiempo.
		if (isTouch.value && e.inputEvent.type === 'mousedown') {
			e.stop();
			return;
		}

		const target = getRealTarget(e);

		// EditableElement: editable y aún no seleccionado
		const isEditableElement = (target.closest('.target') || target.closest('.editable')) as
			| HTMLElement
			| SVGElement
			| null;
		const isGhostImage = target.classList.contains('ghost-image');
		const isLineHandler = target.classList.contains('line-handler');
		const isToolbar = target.classList.contains('toolbar') || target.closest('.toolbar');
		const diff = new Date().getTime() - firstClickDate.getTime();
		const isDoubleClick = diff > 0 && diff < 500;
		const shouldActiveCropOnDoubleClick =
			target.tagName === 'IMG' && !isToolbar && store.selection[0]?.type === 'image' && !isCropping.value;

		// En mobile no habilitamos el doble click.
		// Controlamos la fecha desde el primer click, para poder detectar el doble click sólo en desktop.
		if (isDoubleClick) {
			// Si se produce un doble click, se lo pasamos a la imagen subyacente, ya que esta escuchando
			// el doble click para iniciar el modo crop
			if (shouldActiveCropOnDoubleClick) {
				target.dispatchEvent(new MouseEvent('dblclick'));
			}
		} else {
			firstClickDate = new Date();
		}

		if (!isLineHandler) {
			action.value = 'idle';
		}
		// Si el elemento seleccionado no está en la página activa seteamos como activa la página a la que pertenece
		if (isEditableElement && !isEditableElement.closest(`#canvas-${store.activePage?.id}`)) {
			const element = getElementFromDom(isEditableElement as HTMLElement);
			if (!element) return;
			temporalRef.value = element;
			store.setActivePage(usingElementOrchestrator.value.page.value, true);
		}
		if (store.textEditing) {
			const mouseStartTarget = document.elementFromPoint(e.clientX, e.clientY);
			if (mouseStartTarget?.closest('[contenteditable]')) {
				e.stop();
				return;
			}
		}

		// Si no hay selección y tenemos shift pulsado queremos lanzar selecto incluso estando encima de un elemento
		if (!store.selection.length && e.inputEvent.shiftKey) {
			return;
		}

		if (!isCropping.value && (isEditableElement || isGhostImage)) {
			if (!isTouch.value) {
				setSelectedAndDrag(e, isEditableElement as HTMLElement);
				return;
			}

			// Si es touch, no queremos que se seleccione cuando estamos haciendo scroll, para poder comprobarlo retrasamos
			// 100 ms la selección para así poder comprobar si el usuario está haciendo scroll.
			const scrollArea = document.querySelector('#scroll-area');
			const scroll = {
				x: scrollArea.scrollLeft,
				y: scrollArea.scrollTop,
			};
			e.stop();

			setTimeout(() => {
				if (scroll.x !== scrollArea.scrollLeft || scroll.y !== scrollArea.scrollTop) return;
				setSelectedAndDrag(e, isEditableElement as HTMLElement);
			}, 100);
			return;
		}

		// Si hay elementos seleccionados y pulsamos shift, no permitimos selección con selecto
		// además si es un elemento gestionamos la selección
		if (e.inputEvent.shiftKey && store.selection.length) {
			const target = getRealTarget(e, true);
			const isTarget = target.closest('.target');
			const isEditable = target.closest('.editable');

			e.stop();

			if (!isTarget && !isEditable) {
				return;
			}

			if (isTarget) {
				const element = getElementFromActivePageDom(isTarget as HTMLElement);

				if (!element) {
					return;
				}

				store.removeFromSelection(element);

				return;
			}

			if (isEditable) {
				const element = getElementFromActivePageDom(isEditable as HTMLElement);

				if (!element) {
					return;
				}

				store.setSelection(element, true);

				return;
			}
		}

		// Si es un elemento seleccionado, es un elemento de Moveable (rect, handlers....) o una parte de la
		// interfaz que se usa mientras tenemos el elemento seleccionado evitamos perder el foco del elemento
		if (
			moveable.value?.isMoveableElement(target) ||
			cropPhotoModeMoveable.value?.isMoveableElement(target) ||
			target.closest('.color-picker') ||
			target.closest('.target') ||
			target.closest('.toolbar') ||
			target.closest('.toolbar-group') ||
			target.closest('.ContextMenu') ||
			target.closest('.ghost-image') ||
			target.closest('.line-handler')
		) {
			e.stop();
		} else {
			if (isMobile.value) e.stop();
			if (isPhotoMode.value && isCropping.value) store!.croppingId = null;
			store.clearSelection();
		}
	};

	/**
	 *  Si el target del evento es el dragarea del Moveable devuelve el elemento que hay debajo realmente
	 *
	 * @param e
	 * @param force Te devuelve el elemento debajo del moveable area siempre
	 * @return Elemento editable del dom
	 */
	const getRealTarget = (e: OnDragStart, force = false): HTMLElement => {
		let target = e.inputEvent.target;
		if (!moveable.value) {
			return target;
		}

		const isLockedGroup =
			store.selection.length && store.selection.every((s) => s.group && s.group === store.selection[0].group);
		const isMoveableArea = target.classList.contains('moveable-area');

		// Crop
		if (isCropping.value && isMoveableArea) {
			const ghostImage = document.querySelector('.ghost-image');
			if (ghostImage) target = ghostImage;
		}

		// Groups
		if (!isCropping.value && ((isMoveableArea && moveable.value && isLockedGroup) || force)) {
			moveable.value.passDragArea = true;
			const targetBehind = document.elementFromPoint(e.clientX, e.clientY);
			if (targetBehind?.closest('.target') || targetBehind?.closest('.editable')) {
				target = targetBehind;
			}
			if (!isCropping.value) {
				moveable.value.passDragArea = false;
			}
		}

		return target;
	};
	/**
	 * Añade a la selección y permite hacer drag sin tener que hacer click de nuevo
	 * */
	const setSelectedAndDrag = (e: OnDragStart, editableElement: HTMLElement | SVGElement) => {
		if (!store.activePage) {
			return;
		}

		if (isCropping.value) {
			e.stop();
			requestAnimationFrame(() => {
				if (pressed.value) {
					ghostMoveable.value?.dragStart(e.inputEvent);
				}
			});
			return;
		}

		const element = getElementFromActivePageDom(editableElement as HTMLElement);

		if (!element) {
			return;
		}

		store.setSelection(element, e.inputEvent.shiftKey);
		Bugsnag.leaveBreadcrumb(`Select ${element.type}-${element.id}`);

		if (!isEmpty(store.selection)) {
			if (store.selection.every((e) => e.type === 'text') && store.selection.length > 1) {
				// En caso de haber más de un elemento de tipo texto
				GAnalytics.track('click', 'Template', 'select-multiple-texts', null);
			} else if (store.selection.length > 1) {
				// En caso de haber más de un elemento de varios tipos
				GAnalytics.track('click', 'Template', 'select-multiple-elements', null);
			} else {
				// En caso de solo haber un elemento
				GAnalytics.track('click', 'Template', `select-${element.type}`, null);
			}
		}

		e.stop();

		requestAnimationFrame(() => {
			if (pressed.value && !isMobile.value) {
				moveable.value?.dragStart(e.inputEvent);
				Bugsnag.leaveBreadcrumb(`drag element: ${element.type}-${element.id} `);
			} else {
				// Para evitar falsos positivos del drag
				action.value = 'idle';
			}
		});
	};

	const toggleMiddleHandlers = () => {
		const focusedElement = store.selection[0];

		// Ignoramos esto en las líneas ya que sus handlers son independientes al resto
		if (!moveable.value || focusedElement.type === 'line') return;

		const allHandles = ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'];
		const handlerWithoutMiddles = ['nw', 'ne', 'sw', 'se'];

		moveable.value!.renderDirections = (moveable.value.renderDirections as string[]).includes('n')
			? handlerWithoutMiddles
			: allHandles;
	};

	return {
		selecto,
		setupInteraction,
		action,
		disableToolbar,
		moveable,
		isSelecting,
		isDragging,
		isResizing,
		isRotating,
		isCropping,
		isIdle,
		isDragRemove,
		isDragMove,
		isEditingText,
		isMiddleHandler,
		toggleMiddleHandlers,
	};
};
