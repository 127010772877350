class StringTools {
	static getAllWordsCapitalized(str: string): string {
		return str.replaceAll('-', ' ').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
	}

	static getSize(str: string): number {
		return new TextEncoder().encode(str).length;
	}
}

export default StringTools;
