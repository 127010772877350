// Packages
import { SVG, Svg } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';

// Types
import Element from '@/Classes/Element';
import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
// Classes
import { Color } from '@/Types/colorsTypes';
import { Flip, Position, SerializedClass, Size } from '@/Types/types';

class Storyset extends Element {
	type: 'storyset' = 'storyset';
	viewbox: string;
	content: string;
	mainColor: Color;

	protected constructor(
		// Element
		metadata: object,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number,
		// Storyset
		viewbox: string,
		content: string,
		mainColor: Color
	) {
		super(metadata, size, position, rotation, flip, group, locked, keepProportions, opacity);

		this.viewbox = viewbox;
		this.content = content;
		this.mainColor = mainColor;
	}

	/**
	 * Instancia para trabajar con el contenido de storyset usando SVG.js
	 * @returns Instancia de SVG.js
	 */
	get instance(): Svg {
		return SVG(`<svg viewBox="${this.viewbox}">${this.content}</svg>`) as Svg;
	}

	static get defaultColors(): string[] {
		return [
			'rgba(64, 123, 255, 1)',
			'rgba(146, 227, 169, 1)',
			'rgba(186, 104, 200, 1)',
			'rgba(255, 114, 94, 1)',
			'rgba(255, 199, 39, 1)',
		];
	}

	static defaults() {
		return {
			// Element
			metadata: {},
			size: { height: 0, width: 0 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: true,
			opacity: 1,
			// Storyset
			viewbox: '0 0 64 64',
			content: '',
			mainColor: SolidColor.fromString('rgba(64, 123, 255, 1)'),
		};
	}

	static create(viewBox: string, content: string, mainColor: Color, config?: Partial<Storyset>): Storyset {
		const defaults = Storyset.defaults();

		return new Storyset(
			// Element
			config?.metadata || defaults.metadata,
			config?.size || defaults.size,
			config?.position || defaults.position,
			config?.rotation || defaults.rotation,
			config?.flip || defaults.flip,
			config?.group || defaults.group,
			config?.locked || defaults.locked,
			config?.keepProportions || defaults.keepProportions,
			config?.opacity || defaults.opacity,
			// Storyset
			viewBox,
			content,
			mainColor
		);
	}

	static unserialize(data: SerializedClass<Storyset>): Storyset {
		const defaults = Storyset.defaults();

		const {
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// Storyset
			viewbox,
			content,
			mainColor,
		} = data;

		// Gradients have stops but Solids only have color channels
		let fixedMainColor: Color = defaults.mainColor;
		if (mainColor) {
			fixedMainColor = 'stops' in mainColor ? GradientColor.unserialize(mainColor) : SolidColor.unserialize(mainColor);
		}

		const elem = new Storyset(
			// Element
			metadata || defaults.metadata,
			size || defaults.size,
			position || defaults.position,
			rotation !== undefined ? rotation : defaults.rotation,
			flip || defaults.flip,
			group || defaults.group,
			locked !== undefined ? locked : defaults.locked,
			keepProportions !== undefined ? keepProportions : defaults.keepProportions,
			opacity !== undefined ? opacity : defaults.opacity,
			// Storyset
			viewbox || defaults.viewbox,
			content || defaults.content,
			fixedMainColor
		);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static createDefault(merge = {}) {
		let defaults = Storyset.defaults();
		defaults = { ...defaults, ...merge };

		return new Storyset(
			defaults.metadata,
			defaults.size,
			defaults.position,
			defaults.rotation,
			defaults.flip,
			defaults.group,
			defaults.locked,
			defaults.keepProportions,
			defaults.opacity,
			defaults.viewbox,
			defaults.content,
			defaults.mainColor
		);
	}

	static fromSvg(rawSvg: string): Storyset {
		const storysetSvg = SVG(rawSvg);
		const originalContent = storysetSvg.node.innerHTML;

		// Añadimos un <g> temporal para obtener info del contenido
		storysetSvg.node.innerHTML = `<g>${originalContent}</g>`;

		const mainG = storysetSvg.first();
		const { x, y, height, width } = mainG.bbox();

		storysetSvg.node.innerHTML = originalContent;

		// Normalizamos los colores
		storysetSvg.find('[fill], [fill-color], [stroke], [stroke-color]').forEach((el) => {
			// Normalizamos el fill-color
			const fillColor = el.node.getAttribute('fill-color') || el.node.getAttribute('fill');

			if (fillColor) {
				const [r, g, b] = Normalize(fillColor);

				el.attr('fill-color', null);
				el.attr('fill', null);

				el.css('fill', `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`);
			}

			// Normalizamos el stroke-color
			const strokeColor = el.node.getAttribute('stroke-color') || el.node.getAttribute('stroke');

			if (strokeColor) {
				const [r, g, b] = Normalize(strokeColor);

				el.attr('stroke-color', null);
				el.attr('stroke', null);

				el.css('stroke', `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`);
			}
		});

		// Pasamos los colores a rgba
		storysetSvg.find('[style*="stroke"], [style*="fill"]').forEach((el) => {
			const stroke = el.css('stroke');

			if (stroke && !stroke.includes('var')) {
				const [r, g, b] = Normalize(stroke);
				const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`;

				el.css('stroke', '');
				el.attr('style', el.attr('style') + ` stroke: ${rgba};`);
			}

			const fill = el.css('fill');

			if (fill && !fill.includes('var')) {
				const [r, g, b] = Normalize(fill);
				const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`;

				el.css('fill', '');
				el.attr('style', el.attr('style') + ` fill: ${rgba};`);
			}
		});

		// Ponemos las id en minúsculas
		storysetSvg.find('[id]').forEach((el) => {
			el.attr('id', el.attr('id').toLowerCase());
		});

		// Por defecto los fondos están ocultos
		storysetSvg.find('[id*=background-simple], [id*=background-complete]').forEach((el) => {
			el.css('visibility', 'hidden');
		});

		const viewBox = `${x} ${y} ${width} ${height}`;
		const mainColorString = this.defaultColors.find((color) => storysetSvg.node.innerHTML.toString().includes(color));
		const mainColor = SolidColor.fromString(mainColorString || this.defaultColors[0]);
		const size = {
			width: parseFloat(mainG.width().toString()) * parseFloat(mainG.transform('scaleX').toString()),
			height: parseFloat(mainG.height().toString()) * parseFloat(mainG.transform('scaleY').toString()),
		};

		if (mainColorString) {
			// Sustituimos el color principal por un placeholder para evitar conflictos en el comportamiento
			storysetSvg.find(`[style*="${mainColor.toCssString()}"]`).forEach((el) => {
				const fill = el.css('fill');

				if (fill) {
					el.css('fill', 'var(--main-storyset-color)');
				}

				const stroke = el.css('stroke');

				if (stroke) {
					el.css('stroke', 'var(--main-storyset-color)');
				}
			});
		}

		const content = storysetSvg.node.innerHTML.toString();

		return Storyset.create(viewBox, content, mainColor, {
			size,
		});
	}

	updateColor(newColor: Color) {
		newColor.id = this.mainColor.id;
		this.mainColor = newColor;
	}
}

export default Storyset;
