<script lang="ts" setup>
// Vue
import { computed, nextTick, onBeforeUnmount, onMounted, toRef, watch } from 'vue';

// Classes
import Image from '@/Classes/Image';
// Components
import CropGrid from '@/components/render/CropGrid.vue';
// Composables
import { useCropPhotoMode } from '@/composables/element/image/useCropPhotoMode';
import { useCropPhotoModeMoveable } from '@/composables/element/image/useCropPhotoMode';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
// Stores
import { useMainStore } from '@/stores/store';
const store = useMainStore();

// Props
const props = defineProps<{ image: Image }>();

// Data
const image = toRef(props, 'image');
const scale = toRef(store, 'scale');

// Using composables
const { applyCrop, initCrop, cancelCrop, fromApplyCrop } = useCropPhotoMode();
const { cropPhotoModeMoveable } = useCropPhotoModeMoveable();
const { listen } = useOrderedKeyboardListener();

// Watchers
watch(scale, async () => {
	await nextTick();
	cropPhotoModeMoveable.value?.updateTarget();
});

// Lifecycle hooks
onMounted(async () => {
	await initCrop();
});

onBeforeUnmount(() => {
	if (fromApplyCrop.value) {
		fromApplyCrop.value = false;
		return;
	}
	cancelCrop();
});

// Register events
listen('Enter', (ev) => {
	ev.preventDefault();
	applyCrop();
});

listen('Escape', (ev) => {
	ev.preventDefault();
	cancelCrop();
});

// Methods
const ghostImageSize = computed(() => ({
	width: props.image.crop?.size.width || props.image.size.width,
	height: props.image.crop?.size.height || props.image.size.height,
}));

const ghostImagePosition = computed(() => ({
	x: props.image.crop?.position.x,
	y: props.image.crop?.position.y,
}));
</script>

<template>
	<div
		class="ghost-image-wrapper pointer-events-none absolute top-0 left-0"
		:style="{
			transform: `
        translate(${image.position.x}px, ${image.position.y}px)
				rotate(${image.rotation}deg
      `,
			width: `${image.size.width}px`,
			height: `${image.size.height}px`,
		}"
	>
		<div
			class="h-full w-full opacity-25"
			:style="{
				transform: `
					scaleX(${image.flipHTML.x})
					scaleY(${image.flipHTML.y})
				`,
			}"
		>
			<div
				:data-test-crop="store.croppingId"
				:style="{
					width: `${image.size.width}px`,
					height: `${image.size.height}px`,
				}"
			>
				<div
					:style="{
						width: `${image.size.width}px`,
						height: `${image.size.height}px`,
						transform: `
							scaleX(${image.flipHTML.x})
							scaleY(${image.flipHTML.y})
						`,
					}"
				>
					<div
						class="ghost-image"
						:style="{
							width: `${ghostImageSize.width}px`,
							height: `${ghostImageSize.height}px`,
							transform: `
								translate(${ghostImagePosition.x}px, ${ghostImagePosition.y}px)
							`,
						}"
					>
						<img
							:src="image.url"
							class="h-full w-full"
							:style="{
								filter: image.filter?.toCssFilter(),
								opacity: image.opacity,
								transform: `
									scaleX(${image.flipHTML.x})
									scaleY(${image.flipHTML.y})
								`,
							}"
							@dragstart.prevent
						/>
					</div>
				</div>
			</div>
		</div>
		<CropGrid :size="image.size" />
	</div>
</template>
