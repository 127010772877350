<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { computed, nextTick, Ref, ref } from 'vue';

// Api
import { getMasks } from '@/api/DataApiClient';
import { SolidColor } from '@/Classes/SolidColor';
// Classes
import { Text } from '@/Classes/Text';
import ActivableItem from '@/components/Common/ActivableItem.vue';
// Components
import InfiniteLoading from '@/components/Common/InfiniteLoading.vue';
import PanelHeader from '@/components/panels/PanelHeader.vue';
// Composables
import { useText } from '@/composables/element/text/useText';
import { useElementTransform } from '@/composables/element/useElementTransform';
import { useGroup } from '@/composables/group/useGroup';
import { useGroupTransform } from '@/composables/group/useGroupTransform';
import { useActivePage } from '@/composables/page/useActivePage';
import { useArtboard } from '@/composables/project/useArtboard';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useFonts } from '@/composables/useFonts';
// Store
import { useMainStore } from '@/stores/store';
import { MaskApi } from '@/Types/apiClient';
// Types
import { Anchor } from '@/Types/types';
// Utils
import GAnalytics from '@/utils/GAnalytics';
import TemplateLoader from '@/utils/TemplateLoader';

const store = useMainStore();

const source = `elements/predefined-texts?page=1`;

const temporalRef = ref(Text.createDefault());
const { artboardSizeInPx: sizeInPx } = useArtboard();
const { resize, align } = useElementTransform(temporalRef);
const { addElement } = useActivePage();
const { group, setOpacity: setGroupOpacity } = useGroup(temporalRef);

const { align: groupAlign, resize: resizeGroup, size: groupSize } = useGroupTransform(group);
const { fitHeight } = useText(temporalRef as Ref<Text>);
const { inUseFonts } = useFonts();

const { runOnMobile } = useDeviceInfo();

const fontFamily = computed(() =>
	inUseFonts.value.length > 0 ? inUseFonts.value[0].name : temporalRef.value.fontFamily
);

const textStyles = [
	{
		text: 'Add a heading',
		fontSize: 24,
		lineHeight: 1.2,
		fontWeight: 700,
		fontFamily: `${fontFamily.value}`,
	},
	{
		text: 'Heading with outline',
		fontSize: 24,
		lineHeight: 1.2,
		fontWeight: 700,
		outline: {
			color: SolidColor.white(),
			width: 2,
		},
		fontFamily: `${fontFamily.value}`,
	},
	{
		text: 'Add a subheading',
		fontSize: 18,
		lineHeight: 1.2,
		fontWeight: 700,
		fontFamily: `${fontFamily.value}`,
	},
	{
		text: 'Add a little bit of body text',
		fontSize: 14,
		lineHeight: 1.2,
		fontWeight: 400,
		fontFamily: `${fontFamily.value}`,
	},
];

const addText = (style: Partial<Text>) => {
	const text = Text.create('Example text', style);

	Bugsnag.leaveBreadcrumb(` ${style.text} text to canvas`);
	GAnalytics.track('click', 'Button', 'add-new-text', null);
	temporalRef.value = text;
	resize(sizeInPx.value.width / 2, 0);

	// Conversión de tamaño de fuente para adaptarse al ancho de las pantallas
	const defaultScreenSize = 400;
	text.fontSize = (sizeInPx.value.width * text.fontSize) / defaultScreenSize;

	// Cambio de fontFamily para que tenga el mismo que en la plantilla
	text.fontFamily = inUseFonts.value.length > 0 ? inUseFonts.value[0].name : text.fontFamily;

	if (!store.activePage) {
		return;
	}
	addElement(text);

	nextTick(() => {
		fitHeight();
		align(Anchor.center);
		store?.setSelection(text, false);
	});

	runOnMobile(() => (store.activePanel = null));
};

const addPredefinedText = async (text: MaskApi) => {
	if (!store.activePage) {
		return;
	}
	try {
		// Eliminamos la selección para que no se reemplace el texto predefinido encima de otro texto seleccionado
		store.clearSelection();

		const elements = await TemplateLoader.fromPredefinedText(text.svg);
		const opacities = elements.map((el) => el.opacity);
		const portalTarget = document.querySelector('#portalTarget');
		const toolbarTarget = document.querySelector('#toolbarTarget');

		store.activePage.elements.push(...elements);

		if (elements.length) {
			temporalRef.value = elements[0];
			await nextTick();
		}

		await store.setSelection(elements[0]);
		if (portalTarget && toolbarTarget) {
			portalTarget.style.opacity = 0;
			toolbarTarget.style.opacity = 0;
		}

		setGroupOpacity(0);

		setTimeout(async () => {
			resizeGroup(sizeInPx.value.width / 2, groupSize.value.height * store.scale);
			await nextTick();
			groupAlign(Anchor.center);

			elements.forEach((el, i) => el.setOpacity(opacities[i]));

			if (portalTarget && toolbarTarget) {
				portalTarget.style.opacity = 1;
				toolbarTarget.style.opacity = 1;
			}
		}, 0);
		Bugsnag.leaveBreadcrumb(`Add the follow predefined text to canvas: ${elements.map((el) => 'text-' + el.id).join('; ')}`);
	} catch (e) {
		console.error('Something wrong on generate predefined text');
	}
	runOnMobile(() => (store.activePanel = null));
};
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader :title="'Texts'" />

		<InfiniteLoading
			:source="source"
			:fetch="getMasks"
			class="flex h-full flex-col gap-2"
			data-testid="text-panel"
			container-classes="grid grid-cols-2 gap-2"
		>
			<button
				v-for="style in textStyles"
				:key="style.text"
				class="w-full cursor-pointer rounded bg-gray-700 p-4 text-left text-xl font-bold text-gray-100 hover:bg-gray-600 hover:text-white focus:outline-none"
				:style="{
					fontSize: `${style.fontSize}px`,
					fontWeight: style.fontWeight,
					fontFamily: style.fontFamily,
				}"
				@click="addText(style as Partial<Text>)"
				v-text="style.text"
			></button>
			<template #item="{ item }">
				<button
					class="w-full cursor-pointer rounded bg-gray-700 px-3 py-2 transition-opacity duration-300 hover:bg-gray-600"
					@click="addPredefinedText(item)"
				>
					<ActivableItem :active="item.active">
						<img
							:src="item.preview"
							class="h-24 w-full cursor-pointer object-contain"
							:class="{ 'opacity-50 invert filter': item.multicolor === false }"
							draggable="false"
						/>
					</ActivableItem>
				</button>
			</template>
		</InfiniteLoading>
		<!-- Temporal -->
		<div class="absolute bottom-0 mt-auto mb-4 flex w-full justify-center gap-2"></div>
	</div>
</template>
