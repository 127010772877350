import Element from '@/Classes/Element';
import { SolidColor } from '@/Classes/SolidColor';
import { Flip, Position, SerializedClass, Size, ViewBox } from '@/Types/types';

export class QRCode extends Element {
	type: 'qrcode' = 'qrcode';
	url: string;
	viewbox: string;
	content: string;
	margin: number;
	frontColor: SolidColor;
	bgColor: SolidColor;

	protected constructor(
		// Element
		metadata: object,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number,
		// QRCode
		url: string,
		viewbox: string,
		content: string,
		margin: number,
		frontColor: SolidColor,
		bgColor: SolidColor
	) {
		super(metadata, size, position, rotation, flip, group, locked, keepProportions, opacity);

		this.url = url;
		this.viewbox = viewbox;
		this.content = content;
		this.margin = margin;
		this.frontColor = frontColor;
		this.bgColor = bgColor;
	}

	public get viewboxObject(): ViewBox {
		const viewboxData = this.viewbox.split(' ').map(Number);

		return {
			x: viewboxData[0],
			y: viewboxData[1],
			width: viewboxData[2],
			height: viewboxData[3],
		};
	}

	static defaults() {
		return {
			// Element
			metadata: {},
			size: { height: 100, width: 100 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: true,
			opacity: 1,
			// QRCode
			url: '',
			viewbox: '0 0 100 100',
			content: '',
			margin: 4,
			frontColor: SolidColor.black(),
			bgColor: SolidColor.white(),
		};
	}

	static create(url: string, viewBox: string, content: string, config?: Partial<QRCode>): QRCode {
		const defaults = QRCode.defaults();

		return new QRCode(
			// Element
			config?.metadata || defaults.metadata,
			config?.size || defaults.size,
			config?.position || defaults.position,
			config?.rotation || defaults.rotation,
			config?.flip || defaults.flip,
			config?.group || defaults.group,
			config?.locked || defaults.locked,
			config?.keepProportions || defaults.keepProportions,
			config?.opacity || defaults.opacity,
			// QRCode
			url,
			viewBox,
			content,
			config?.margin || defaults.margin,
			config?.frontColor || defaults.frontColor,
			config?.bgColor || defaults.bgColor
		);
	}

	static unserialize(data: SerializedClass<QRCode>): QRCode {
		const defaults = QRCode.defaults();

		const {
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// QRCode
			url,
			viewbox,
			content,
			margin,
			frontColor,
			bgColor,
		} = data;

		const elem = new QRCode(
			// Element
			metadata || defaults.metadata,
			size || defaults.size,
			position || defaults.position,
			rotation !== undefined ? rotation : defaults.rotation,
			flip || defaults.flip,
			group || defaults.group,
			locked !== undefined ? locked : defaults.locked,
			keepProportions !== undefined ? keepProportions : defaults.keepProportions,
			opacity !== undefined ? opacity : defaults.opacity,
			// QRCode
			url || defaults.url,
			viewbox || defaults.viewbox,
			content || defaults.content,
			margin || defaults.margin,
			frontColor ? SolidColor.unserialize(frontColor) : defaults.frontColor,
			bgColor ? SolidColor.unserialize(bgColor) : defaults.bgColor
		);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static createDefault(merge = {}) {
		let defaults = QRCode.defaults();
		defaults = { ...defaults, ...merge };

		return new QRCode(
			// Element
			defaults.metadata,
			defaults.size,
			defaults.position,
			defaults.rotation,
			defaults.flip,
			defaults.group,
			defaults.locked,
			defaults.keepProportions,
			defaults.opacity,
			// QRCode
			defaults.url,
			defaults.viewbox,
			defaults.content,
			defaults.margin,
			defaults.frontColor,
			defaults.bgColor
		);
	}

	static getInnerSvg(rawSvg: string) {
		const div = document.createElement('div');
		div.insertAdjacentHTML('afterbegin', rawSvg);
		const svg = div.querySelector('svg');
		if (!svg) return '';
		return svg.innerHTML;
	}

	static getViewBoxFromRawSvg(rawSvg: string) {
		const div = document.createElement('div');
		div.insertAdjacentHTML('afterbegin', rawSvg);
		const svg = div.querySelector('svg');
		if (!svg) return QRCode.defaults().viewbox;
		return svg.getAttribute('viewBox') || QRCode.defaults().viewbox;
	}

	static fromSvg(url: string, rawSvg: string): QRCode | undefined {
		const div = document.createElement('div');
		div.insertAdjacentHTML('afterbegin', rawSvg);
		const svg = div.querySelector('svg');
		if (!svg) return;
		const viewBox = svg.getAttribute('viewBox');
		if (!viewBox) return;
		const mappedViewBox = viewBox.split(' ').map((val: string) => parseFloat(val));
		return QRCode.create(url, viewBox, svg.innerHTML, { size: { width: mappedViewBox[2], height: mappedViewBox[3] } });
	}

	updateFrontColor(newColor: SolidColor) {
		this.frontColor = newColor;
	}

	updateBgColor(newColor: SolidColor) {
		this.bgColor = newColor;
	}
}
