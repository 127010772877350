import { OnPinch } from 'gesto';

import { Position } from '@/Types/types';

class EventTools {
	static getEventPositionInElement(element: Element, event: MouseEvent | OnPinch): Position | undefined {
		if (!event || event.type === 'keydown') return;

		const rbox = element.getBoundingClientRect();

		// CHECKPOINT: hablar en la reunión
		const clientX = event.clientX;
		const clientY = event.clientY;

		const x = clientX - rbox.left - element.clientLeft;
		const y = clientY - rbox.top - element.clientTop;

		return {
			x,
			y,
		};
	}

	static getErrorMessage(error: unknown) {
		if (error instanceof Error) return error.message;
		return String(error);
	}
}

export default EventTools;
