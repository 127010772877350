<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const el = ref();
const ready = ref(false);

const height = ref(0);
onMounted(() => {
	height.value = el.value.getBoundingClientRect().height;
	el.value.style.height = `${height.value}px`;
	ready.value = true;
});
</script>

<template>
	<div
		ref="el"
		:class="
			ready ? 'overflow-y-auto overflow-x-hidden text-gray-800 scrollbar-thin scrollbar-thumb-gray-600' : 'h-full'
		"
	>
		<slot v-if="ready"></slot>
	</div>
</template>
