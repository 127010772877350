<script lang="ts" setup>
// Vue & Packages
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

// Api
import { getSvg } from '@/api/DataApiClient';
// Classes
import Line from '@/Classes/Line';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
// Types
// Datas
import { useAuth } from '@/composables/useAuth';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
// Datas
import { ElementTypes } from '@/data/ElementTypes';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { ElementsPanelTypes, ElementType } from '@/Types/types';

const temporalRef = ref();

const store = useMainStore();
const { user } = useAuth();
const { isDevelopmentEnvironment } = useEditorMode();
const { addElement } = useActivePage();
const usingElementOrchestrator = useElementOrchestrator(temporalRef);
const { runOnMobile } = useDeviceInfo();

// Constants
const publicPath = import.meta.env.BASE_URL;
const lineUrl = `${import.meta.env.VITE_APP_URL}line.svg`;

const getPreviewsContainerClasses = (id: string | number): string => {
	if (['flaticon', 1740, 1741, 2328].includes(id)) {
		return 'grid-cols-5 sm:grid-cols-10 sm:min-h-0 lg:min-h-32 lg:grid-cols-5';
	}

	if (['storyset', 'stickers'].includes(id as string)) {
		return 'grid-cols-3 sm:grid-cols-6 sm:min-h-0 lg:min-h-32 lg:grid-cols-3';
	}

	return '';
};

const setCategory = (elementType: ElementType | any) => {
	if (elementType.id === 'flaticon') store.activeElementType = ElementsPanelTypes.Flaticon;
	if (elementType.id === 'storyset') store.activeElementType = ElementsPanelTypes.Storysets;
	if (elementType.id === 'stickers') store.activeElementType = ElementsPanelTypes.Stickers;
	if (elementType.id === 1740) store.activeElementType = ElementsPanelTypes.BasicShapes;
	if (elementType.id === 1741) store.activeElementType = ElementsPanelTypes.ImageMasks;
	if (elementType.id === 2328) store.activeElementType = ElementsPanelTypes.LinesAndArrows;
	if (elementType.id === 'qr-codes') store.activeElementType = ElementsPanelTypes.QRCodes;

	Bugsnag.leaveBreadcrumb(`select category ${elementType.id}`);
};

const addLine = async (url: string) => {
	const { data } = await getSvg(url);

	if (!data.value || !store.activePage) return;

	temporalRef.value = Line.fromSvg(data.value);

	addElement(temporalRef.value);
	usingElementOrchestrator.value.setupInPage();
	store.setSelection(temporalRef.value, false);
	runOnMobile(() => (store.activePanel = null));

	Bugsnag.leaveBreadcrumb(`Add line-${temporalRef.value.id}`);
};
</script>

<template>
	<div
		class="flex max-h-[calc(100vh-112px)] w-full flex-col gap-4 overflow-y-auto pb-4 pr-1 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
	>
		<div class="flex items-center gap-3">
			<button
				data-testid="add-qr"
				class="flex h-8 items-center rounded bg-gray-700 px-4 text-xs font-semibold text-gray-100 hover:bg-gray-600 hover:text-white"
				@click="setCategory({ id: 'qr-codes' })"
			>
				<SvgIcon name="qr" class="mr-2 h-3 w-3" />
				Add QR code
			</button>

			<button
				data-testid="add-line"
				class="flex h-8 items-center rounded bg-gray-700 px-4 text-xs font-semibold text-gray-100 hover:bg-gray-600 hover:text-white"
				@click="addLine(lineUrl)"
			>
				<SvgIcon name="border-dasharray-0" class="mr-3 h-4 w-4" />
				Add Line
			</button>
		</div>
		<!-- Element categories -->
		<div v-for="elementType in ElementTypes" :key="elementType.id" :data-testid="elementType.name">
			<!-- El panel de flechas solo está disponible de momento para admins y devs -->
			<div v-if="elementType.id !== 2328 || (elementType.id === 2328 && user?.isAdmin) || isDevelopmentEnvironment">
				<div class="mb-2 flex items-center justify-between">
					<h3 class="font-bold text-gray-100">{{ elementType.name }}</h3>

					<button class="text-xs font-bold text-gray-300 hover:text-white" @click="setCategory(elementType)">
						See all
					</button>
				</div>

				<button
					class="grid min-h-32 w-full cursor-pointer gap-2 rounded bg-gray-700 px-2 py-2 hover:bg-gray-600 lg:gap-3"
					:class="getPreviewsContainerClasses(elementType.id)"
					@click="setCategory(elementType)"
				>
					<img
						v-for="element in elementType.elements"
						:key="element.id"
						:alt="`${elementType.name} item`"
						:src="`${publicPath}${element.svg}`"
						class="p-2 lg:p-1"
						draggable="false"
					/>
				</button>
			</div>
		</div>
	</div>
</template>
