import { useEditorApiFetch } from '@/composables/api/useEditorApiFetch';

export const useCustomImagesActions = () => {
	const getCustomImages = () => {
		const { onFetchResponse, onFetchError, isFetching, get } = useEditorApiFetch('user-uploads', {
			immediate: false,
		}).json();

		const getImages = () => get().execute();

		return { onFetchResponse, onFetchError, isFetching, getImages };
	};

	const uploadImage = () => {
		const { onFetchResponse, onFetchError, isFetching, post, data, statusCode } = useEditorApiFetch('user-uploads', {
			immediate: false,
		}).json();

		const uploadImageToServer = async (type: string, provider: string, image: string) => {
			await post({
				type,
				provider,
				upload: image,
			}).execute();
		};

		return { onFetchResponse, onFetchError, isFetching, uploadImageToServer, data, statusCode };
	};

	const deleteImage = async (imageId: string | number) =>
		await useEditorApiFetch(`user-uploads/${imageId}`).delete().json();

	return {
		getCustomImages,
		uploadImage,
		deleteImage,
	};
};
