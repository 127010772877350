<script setup lang="ts">
// Bugsnag
import Bugsnag from '@bugsnag/js';
import { computed, onMounted } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useHistoryStore } from '@/stores/history';
import GAnalytics from '@/utils/GAnalytics';

const store = useHistoryStore();

const canGoForward = computed(() => store.states.length && store.activeState !== store.states[store.states.length - 1]);
const canGoBackward = computed(() => store.states.length && store.activeState !== store.states[0]);

const backward = () => {
	if (!canGoBackward.value) {
		return;
	}
	Bugsnag.leaveBreadcrumb(`Undo changes`);
	GAnalytics.track('click', 'Button', 'Undo', null);
	store.rollback();
};

const forward = () => {
	if (!canGoForward.value) {
		return;
	}
	Bugsnag.leaveBreadcrumb(`Redo changes`);
	GAnalytics.track('click', 'Button', 'Redo', null);
	store.forward();
};
</script>

<template>
	<div class="flex gap-2" data-testid="history">
		<button
			class="flex h-10 w-10 items-center justify-center"
			tooltip="Back"
			tooltip-position="bottom"
			:class="!canGoBackward ? `pointer-events-none opacity-25` : 'opacity-50 hover:opacity-100'"
			@click="backward"
		>
			<SvgIcon name="back" class="h-5 w-5 text-white" />
		</button>
		<button
			class="flex h-10 w-10 items-center justify-center"
			tooltip="Forwards"
			tooltip-position="bottom"
			:class="!canGoForward ? `pointer-events-none opacity-25` : 'opacity-50 hover:opacity-100'"
			@click="forward"
		>
			<SvgIcon name="next" class="h-5 w-5 text-white" />
		</button>
	</div>
</template>
