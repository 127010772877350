import { cloneDeep } from 'lodash-es';
import { computed, Ref } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
import { Shape } from '@/Classes/Shape';
import { SolidColor } from '@/Classes/SolidColor';
import { Color } from '@/Types/colorsTypes';
import GAnalytics from '@/utils/GAnalytics';

export const useShapeColors = (shapeRef: Ref<Shape>) => {
	const colors = computed(() => shapeRef.value.colors);

	const solidColors = computed(() => colors.value.filter((color) => color instanceof SolidColor));
	const gradientColors = computed(() => colors.value.filter((color) => color instanceof GradientColor));

	const trackChangeColor = (color1: Color, color2: Color) => {
		// En caso de cambio de solido a solido
		if (color1 instanceof SolidColor && color2 instanceof SolidColor) {
			if (color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a !== color2.a) {
				GAnalytics.track('click', 'Button', 'change-element-opacity', null);
			} else if (color1.r !== color2.r || color1.g !== color2.g || color1.b !== color2.b) {
				GAnalytics.track('click', 'Button', 'change-element-color', null);
			}
		}

		// En caso de cambio de solido a Gradiente
		if (
			(color1 instanceof SolidColor && color2 instanceof GradientColor) ||
			(color1 instanceof GradientColor && color2 instanceof SolidColor)
		) {
			GAnalytics.track('click', 'Button', 'change-element-color', null);
		}

		// En caso de cambio de gradiente a Gradiente
		if (color1 instanceof GradientColor && color2 instanceof GradientColor) {
			const oldColors = color1.stops.map((c) => {
				return { r: c.r, g: c.g, b: c.b, a: c.a, offset: c.offset };
			});

			const newColors = color2.stops.map((c) => {
				return { r: c.r, g: c.g, b: c.b, a: c.a, offset: c.offset };
			});

			// En caso de tener menos colores
			if (oldColors.length < newColors.length) {
				GAnalytics.track('click', 'Button', 'change-element-color', null);
			} else {
				// En otro caso si se ha modificado cualquiera de los colores de las paradas del gradiente
				oldColors.forEach((c) => {
					newColors.forEach((nc) => {
						if (nc.r === c.r && nc.g === c.g && nc.b === c.b && nc.a !== c.a && nc.offset === c.offset) {
							GAnalytics.track('click', 'Button', 'change-element-opacity', null);
						} else if ((nc.r !== c.r || nc.g !== c.g || nc.b !== c.b) && nc.offset === c.offset) {
							GAnalytics.track('click', 'Button', 'change-element-color', null);
						}
					});
				});
			}
		}
	};

	const updateColor = (oldColor: Color, newColor: Color): Shape => {
		const cloneNewColor = cloneDeep(newColor);
		cloneNewColor.id = oldColor.id;

		trackChangeColor(oldColor, newColor);

		const isUpdatingStop =
			oldColor instanceof GradientColor && cloneNewColor instanceof GradientColor && oldColor.id === cloneNewColor.id;
		if (isUpdatingStop) {
			const gradientToChange = shapeRef.value.colors.find(
				(gradient) => gradient.toCssString() === oldColor.toCssString()
			);

			if (gradientToChange) {
				(gradientToChange as GradientColor).stops = [...cloneNewColor.stops];
			}

			return shapeRef.value;
		}

		const indexColor = colors.value.findIndex((c) => c.toCssString() === oldColor.toCssString() && c.id == oldColor.id);

		shapeRef.value.colors[indexColor] = cloneNewColor;

		return shapeRef.value;
	};

	return {
		colors,
		solidColors,
		gradientColors,
		updateColor,
	};
};
