import Bugsnag from '@bugsnag/browser';
import { MaybeRef, useEventListener, useScroll } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import Moveable, { OnDrag, OnEvent, OnResize, OnResizeStart, OnRotate } from 'moveable';
import { computed, nextTick, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';

import Element from '@/Classes/Element';
import Image from '@/Classes/Image';
import Line from '@/Classes/Line';
import Page from '@/Classes/Page';
import { Text } from '@/Classes/Text';
import { useCrop } from '@/composables/element/image/useCrop';
import { useText } from '@/composables/element/text/useText';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { useGroup } from '@/composables/group/useGroup';
import { useGroupTransform } from '@/composables/group/useGroupTransform';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { usePage } from '@/composables/page/usePage';
import { useProject } from '@/composables/project/useProject';
import { useBugsnag } from '@/composables/useBugsnag';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMainStore } from '@/stores/store';
import { InteractionAction } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';
import TemplateLoader from '@/utils/TemplateLoader';

type MaybeHtmlElement = HTMLElement | null;

const moveable = ref<Moveable | null>();
const action = ref<InteractionAction>('idle');
const dragAction = ref<'remove' | 'move' | null>(null);
const isMiddleHandler = ref(false);

export const useInteractiveElements = (elementsSelected: MaybeRef<Element[]>) => {
	const elements = ref(elementsSelected);
	const canvas = ref<MaybeHtmlElement>();
	const scrollContainer = ref<MaybeHtmlElement>();
	const isMoveableEvent = ref(false);
	const isToolbarEvent = ref(false);
	const maxPositionAndSize = ref({
		position: {
			x: 0,
			y: 0,
		},
		size: {
			width: 0,
			height: 0,
		},
	});

	const temporalRefPage = ref(Page.createDefault());
	const element = computed(() => elements.value[0]);
	const isReady = computed(() => element.value && !element.value.locked);
	const { isTouch } = useDeviceInfo();
	const activePanel = computed(() => store.activePanel);
	const selection = computed(() => store.selection);
	const { addElement, removeElement } = usePage(temporalRefPage as Ref<Page>);

	const store = useMainStore();
	const { isPhotoMode } = useEditorMode();
	const usingElementOrchestrator = useElementOrchestrator(element);
	const { page } = usingElementOrchestrator.value;
	const { breadScrumbWithDebounce } = useBugsnag(element);

	const { isCropping } = useInteractions();
	const { getPageFromDom, getPageFromElement } = useProject();

	const isUngroupedImage = elements.value.length === 1 && element.value instanceof Image;
	const isUngroupedText =
		elements.value.length === 1 && element.value instanceof Text && !element.value.curvedProperties.arc;
	const isUngroupedCurvedText =
		elements.value.length === 1 && element.value instanceof Text && element.value.curvedProperties.arc;
	const isGroup = elements.value.length > 1;

	let groupIsOutside = ref();

	if (isGroup) {
		const { group } = useGroup(element);
		const { isOutsidePage } = useGroupTransform(group);
		groupIsOutside = isOutsidePage;
	}

	const usingElementTransform = useElementTransformOrchestrator(element);

	const containsImage = elements.value.some((el) => el instanceof Image);

	const useCropInstance = containsImage ? useCrop(element as any as Ref<Image>) : null;
	const temporalRef = ref(Text.createDefault());
	const { fitHeight } = useText(temporalRef as Ref<Text>);
	// Guardamos a que escala esta el texto seleccionado al comienzo para tenerlo en cuenta
	// en el momento en el que lo queramos actualizar
	let initialScale: any = {};

	const isCornerEvent = (ev: OnResize): boolean => {
		return ev.direction[0] !== 0 && ev.direction[1] !== 0;
	};

	const getGuideLinesVerticalPosition = (): number[] => {
		if (!scrollContainer.value || !canvas.value) {
			return [];
		}
		const startPosition = 0 + scrollContainer.value?.scrollLeft;
		const centerPosition = (parseFloat(canvas.value.style.width) / 2) * store.scale + scrollContainer.value?.scrollLeft;
		const endPosition = parseFloat(canvas.value.style.width) * store.scale + scrollContainer.value?.scrollLeft;

		return [startPosition, centerPosition, endPosition];
	};

	const getGuideLinesHorizontalPosition = (): number[] => {
		if (!scrollContainer.value || !canvas.value) {
			return [];
		}

		const startPosition = 0 + scrollContainer.value?.scrollTop;
		const centerPosition = (parseFloat(canvas.value.style.height) / 2) * store.scale + scrollContainer.value?.scrollTop;
		const endPosition = parseFloat(canvas.value.style.height) * store.scale + scrollContainer.value?.scrollTop;

		return [startPosition, centerPosition, endPosition];
	};

	const getMoveableRenderDirections = () => {
		if (isUngroupedCurvedText) return ['nw', 'ne', 'sw', 'se'];
		if (isUngroupedText) return ['nw', 'ne', 'w', 'e', 'sw', 'se'];
		if (isGroup) return ['nw', 'ne', 'sw', 'se'];
		if (element.value instanceof Line) return false;
		// imagen y no tiene mascara con ratio
		if (element.value instanceof Image && element.value.mask?.keepRatio) return ['nw', 'ne', 'sw', 'se'];
		// no es imagen pero tiene ratio
		if (!(element.value instanceof Image) && element.value.keepProportions) return ['nw', 'ne', 'sw', 'se'];
		if (page.value?.backgroundImageId === element.value.id) return false;

		return ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'];
	};

	const createMoveable = () => {
		const target = Array.from(document.querySelectorAll('.target')) as HTMLElement[];
		const isLine = element.value instanceof Line && store.selection.length === 1;
		const renderDirections = getMoveableRenderDirections();
		const hideDefaultLines = elements.value.length > 1 || isLine;
		const resizable = !isLine;
		const rotatable = page.value?.backgroundImageId !== element.value.id;

		return new Moveable(document.body, {
			target,
			elementGuidelines: Array.from(document.querySelectorAll('.ruler')),
			renderDirections,
			snappable: true,
			snapContainer: canvas.value,
			verticalGuidelines: getGuideLinesVerticalPosition(),
			horizontalGuidelines: getGuideLinesHorizontalPosition(),
			snapThreshold: 3,
			isDisplaySnapDigit: false,
			snapGap: true,
			snapDirections: { center: true, middle: true, top: true, right: true, bottom: true, left: true },
			elementSnapDirections: { top: true, right: true, bottom: true, left: true },
			hideDefaultLines,
			snapDigit: 0,
			originDraggable: false,
			draggable: true,
			resizable,
			rotatable,
			roundable: false,
			pinchable: false, // ["resizable", "scalable", "rotatable"]
			origin: true,
			keepRatio: true,
			clipTargetBounds: true,
			dragWithClip: false,
			clipArea: false,
			// passDragArea: true,
			// Resize, Scale Events at edges.
			edge: false,
			rootContainer: scrollContainer.value,
			container: scrollContainer.value,
			portalContainer: document.getElementById('portalTarget'),
			rotationPosition: 'bottom',
			defaultGroupRotate: 0,
			defaultGroupOrigin: '50% 50%',
			className: isLine ? 'moveable-element-line' : 'moveable-element',
			ables: [],
			checkInput: true,
		});
	};

	const setIsMoveableEvent = (ev: OnEvent) => {
		if (ev.inputEvent?.target?.closest('.toolbar') || ev.inputEvent?.target?.closest('.toolbar-group')) {
			isToolbarEvent.value = true;
			return;
		}
		isMoveableEvent.value = true;
		if (!moveable.value) return;
		moveable.value.passDragArea = false;
	};

	const unsetIsMoveableEvent = async (ev = null) => {
		isMoveableEvent.value = false;
		isToolbarEvent.value = false;

		// En móvil el evento de touchend no se esta transmitiendo al elemento que hay detras
		// a diferencia de versión PC. Esto es un problema a la hora de escuchar entrar en el modo edición
		// La solucion es detectar si el evento que esta finalizando es el group end y le mandamos
		// a mano al elemento real el evento de touch end
		// Hay que comprobar si tiene inputEvent ya que cuando movemos un grupo desde el position del panel se lanzará
		// también el dragGroupEnd y este vendrá sin inputEvent
		if (ev && ev.inputEvent && moveable.value && isTouch.value && ev.eventType === 'dragGroupEnd') {
			const area = document.querySelector('.moveable-area') as HTMLElement | null;

			if (area) {
				area.style.pointerEvents = 'none';
				const targetBehind = document.elementFromPoint(
					ev.inputEvent.changedTouches[0].clientX,
					ev.inputEvent.changedTouches[0].clientY
				);
				area.style.pointerEvents = 'auto';

				if (targetBehind) targetBehind.dispatchEvent(new TouchEvent('touchend'));
			}
		}
		// Hay un bug por el cual los botones de abajo en móvil no son clicables hasta el
		// segundo click. El problema parece venir del moveable, lo solventamos forzando
		// cambio en el dragarea para que se recargue y funcionen bien de primeras.
		if (moveable.value) {
			moveable.value.dragArea = true;
			setTimeout(() => {
				if (moveable.value) {
					moveable.value.dragArea = store.selection.length > 1 && !isCropping.value;
				}
			}, 0);
		}

		removeOrMoveElement();

		action.value = 'idle';
	};

	const dragHandler = (element: Element, ev: OnDrag) => {
		action.value = 'drag';

		if (isToolbarEvent.value) return;
		if (!isReady.value) return;

		element.position = {
			x: ev.beforeTranslate[0],
			y: ev.beforeTranslate[1],
		};
		ev.target!.style.transform = `translate(${ev.beforeTranslate[0]}px, ${ev.beforeTranslate[1]}px) rotate(${element.rotation}deg)`;
	};

	const rotateHandler = (element: Element, { rotate, target }: OnRotate) => {
		if (!isReady.value) return;
		action.value = 'rotate';
		element.rotation = rotate;
		target.style.transform = `translate(${element.position.x}px, ${element.position.y}px)` + ` rotate(${rotate}deg)`;
	};

	const rotateGroupHandler = (events: OnRotate[]) => {
		action.value = 'rotate';
		events.forEach(({ target, drag, rotate }) => {
			const elem = elements.value.find((el) => el.id === target.id.replace('element-', ''));
			if (!elem || elem.locked) return;

			elem.rotation = rotate;
			elem.position = {
				x: drag.beforeTranslate[0],
				y: drag.beforeTranslate[1],
			};

			target.style.transform =
				`translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)` + ` rotate(${rotate}deg)`;
		});
	};

	const resizeStartHandler = (ev: OnResizeStart) => {
		if (!moveable.value) throw new Error('resizeStartHandler error');

		// Calculamos el tamaño máximo que puede tener el recorte para usarlo como límite
		if (isUngroupedImage) {
			maxPositionAndSize.value.position.x =
				element.value.crop.position.x < 0
					? element.value.position.x + element.value.crop.position.x
					: element.value.position.x;
			maxPositionAndSize.value.position.y =
				element.value.crop.position.y < 0
					? element.value.position.y + element.value.crop.position.y
					: element.value.position.y;
			maxPositionAndSize.value.size.width =
				element.value.crop.position.x < 0
					? element.value.size.width - element.value.crop.position.x
					: element.value.size.width;
			maxPositionAndSize.value.size.height =
				element.value.crop.position.y < 0
					? element.value.size.height - element.value.crop.position.y
					: element.value.size.height;
		}

		isMiddleHandler.value = !(ev.direction[0] && ev.direction[1]);
		if (!isCropping.value) {
			moveable.value.keepRatio = elements.value.length > 1 || element.value.keepProportions;

			if (isUngroupedText || isUngroupedImage) {
				moveable.value.keepRatio = !!(ev.direction[0] && ev.direction[1]) && !isCropping.value;
			}
		}

		// Si es un texto, al comienzo del resize actualizamos la escala en la que estaba
		initialScale = {};
		(elements.value.filter((el) => el instanceof Text) as Text[]).forEach((el) => (initialScale[el.id] = el.scale));

		if (useCropInstance && isMiddleHandler.value) {
			const { temporalSize } = useCropInstance;
			temporalSize.value = {
				width: (element.value as Image).crop.size.width || element.value.size.width,
				height: (element.value as Image).crop.size.height || element.value.size.height,
			};
		}

		setIsMoveableEvent(ev);
	};

	const resizeHandler = (element: Element, ev: OnResize) => {
		if (!isReady.value) return;

		action.value = 'resize';

		let { width, height } = ev;
		const { drag, target, delta, direction } = ev;
		const { translate } = drag;

		if (element instanceof Text) {
			// Si es un texto y se esta tirando de una esquina, tenemos que cambiar su escala en base al valor de escala
			// que haya al comienzo del resize y al cambio de scale en base al ancho inicial vs nueva.
			// Por ejemplo, si el texto inicialmente estaba a 1.5, y teniamos 200x200, y ahora estamos en 400x400
			// la nueva escala es 1.5 * 2 = 4
			if (isCornerEvent(ev)) {
				const scale = (width / (width - ev.dist[0])) * initialScale[element.id];
				element.scale = scale;
			}
		}

		if (element instanceof Image && useCropInstance) {
			// WIP | Restrict image position and size on limits if needed
			const { position, size } = element.getImagePropsRegardingLimits(ev, isCropping.value, maxPositionAndSize.value);
			width = size.width;
			height = size.height;
			translate[0] = position.x;
			translate[1] = position.y;

			const limits = element.getImageLimits(delta, maxPositionAndSize.value);

			if (isMiddleHandler.value) {
				if (isCropping.value) {
					// WIP | Restric crop position and size on limits if needed
					const { resizeCropByMiddleHandler } = useCropInstance;
					resizeCropByMiddleHandler(element as Image, delta, direction, limits);
				}

				if (!isCropping.value) {
					const { preCropHandler } = useCropInstance;
					preCropHandler(delta, direction, { width, height });
				}
			}

			if (!isMiddleHandler.value) {
				if (isCropping.value) {
					// WIP | Restric crop position and size on limits if needed
					const { resizeCropByCornerHandler } = useCropInstance;
					resizeCropByCornerHandler(element as Image, delta, direction);
				}

				if (!isCropping.value) {
					const { fitCroppedImageOnResize } = useCropInstance;
					fitCroppedImageOnResize(element as Image, { width, height });
				}
			}
		}

		// Es necesario para evitar flickeo al redimensionar
		target!.style.width = `${width}px`;
		target!.style.height = `${height}px`;
		target!.style.transform = `translate(${translate[0]}px, ${translate[1]}px) rotate(${element.rotation}deg)`;

		store.$patch(() => {
			element.size = { width, height };
			element.position = {
				x: translate[0],
				y: translate[1],
			};
		});
	};

	const resizeEndHandler = async () => {
		if (useCropInstance && isMiddleHandler.value) {
			const { temporalSize } = useCropInstance;
			temporalSize.value = null;
		}

		if (isUngroupedText) {
			const textNode =
				(document.querySelector(`#editable-${element.value.id}`) as HTMLElement | null) ||
				(document.querySelector(`#element-${element.value.id} .text-element-final`) as HTMLElement | null);
			if (!textNode) return;

			temporalRef.value = element.value;
			requestAnimationFrame(() => fitHeight(textNode));
		}

		await unsetIsMoveableEvent();
	};

	const toggleSizeHandlers = () => {
		if (!moveable.value || isCropping.value) return;

		const allHandles = getMoveableRenderDirections();
		const smallSizeHandles = element.value instanceof Text && store.selection.length === 1 ? ['nw', 'e'] : ['nw', 'se'];

		if (store.selection.length > 1) {
			const moveableArea = document.querySelector('.moveable-area');
			if (!moveableArea) return;
			const heightGroup = moveableArea.getBoundingClientRect().height;
			moveable.value!.renderDirections = heightGroup && heightGroup < 30 ? smallSizeHandles : allHandles;
			return;
		}

		if (!element.value) return;

		moveable.value!.renderDirections =
			(element.value.domNode()?.getBoundingClientRect().height || 30) < 30 ? smallSizeHandles : allHandles;
	};

	const registerEvents = () => {
		if (!moveable.value) return;
		if (!elements.value.length) return;

		moveable.value
			.on('dragStart', (ev) => setIsMoveableEvent(ev))
			.on('drag', (ev) => {
				dragHandler(element.value, ev);
				breadScrumbWithDebounce('position', 'Drag element');
			})
			.on('dragEnd', () => {
				GAnalytics.track('drag and drop', 'Template', `move-${element.value.type}`, null);
				unsetIsMoveableEvent();
			})
			.on('resizeStart', (ev) => resizeStartHandler(ev))
			.on('resize', (ev) => {
				resizeHandler(element.value, ev);
				breadScrumbWithDebounce('size', 'Resize from handler');
			})
			.on('resizeEnd', () => {
				GAnalytics.track('drag and drop', 'Template', `resize-${element.value.type}`, null);
				toggleSizeHandlers();
				resizeEndHandler();
			})
			.on('rotateStart', (ev) => setIsMoveableEvent(ev))
			.on('rotate', (ev) => {
				rotateHandler(element.value, ev);
				breadScrumbWithDebounce('rotation', 'Rotate from handler');
			})
			.on('rotateEnd', () => {
				GAnalytics.track('drag and drop', 'Template', `rotate-${element.value.type}`, null);
				unsetIsMoveableEvent();
			})
			.on('dragGroupStart', (ev) => setIsMoveableEvent(ev))
			.on('dragGroup', ({ events }) => {
				store.$patch(() => {
					events.forEach((ev) => {
						const element = elements.value.find((el) => el.id === ev.target.id.replace('element-', ''));
						if (!element) return;
						dragHandler(element, ev);
					});
				});

				breadScrumbWithDebounce('position', `Drag group: ${elements.value.map((el) => ` ${el.type}-${el.id}`)} `);
			})
			.on('dragGroupEnd', (ev) => {
				GAnalytics.track('drag and drop', 'Template', `move-group`, null);
				unsetIsMoveableEvent(ev);
			})
			.on('rotateGroupStart', (ev) => setIsMoveableEvent(ev))
			.on('rotateGroup', ({ events }) =>
				store.$patch(() => {
					rotateGroupHandler(events);
					breadScrumbWithDebounce(
						'rotation',
						`Rotate group from handler: ${elements.value.map((el) => ` ${el.type}-${el.id}`)} `
					);
				})
			)
			.on('rotateGroupEnd', () => {
				GAnalytics.track('drag and drop', 'Template', `rotate-group`, null);
				unsetIsMoveableEvent();
			})
			.on('resizeGroupStart', (ev) => resizeStartHandler(ev))
			.on('resizeGroup', ({ events }) => {
				store.$patch(() => {
					events.forEach((ev) => {
						const element = elements.value.find((el) => el.id === ev.target.id.replace('element-', ''));
						if (!element) return;
						resizeHandler(element, ev);
					});
				});
				breadScrumbWithDebounce(
					'size',
					`Resize group from handler: ${elements.value.map((el) => ` ${el.type}-${el.id}`)} `
				);
			})
			.on('resizeGroupEnd', () => {
				GAnalytics.track('drag and drop', 'Template', `resize-group`, null);
				unsetIsMoveableEvent();
				toggleSizeHandlers();
			});
	};

	const updateGuideLines = () => {
		if (!moveable.value) {
			return;
		}
		moveable.value.verticalGuidelines = getGuideLinesVerticalPosition();
		moveable.value.horizontalGuidelines = getGuideLinesHorizontalPosition();
	};

	const removeOrMoveElement = () => {
		if (!moveable.value || action.value !== 'drag' || store.selection[0].locked) {
			return;
		}

		const selection = [...store.selection];
		const elements = selection.map((el) => TemplateLoader.unserializeElement(cloneDeep(el)));

		nextTick().then(() => {
			// Eliminar si está fuera de las páginas
			const removeEl = isGroup ? groupIsOutside.value : usingElementTransform.value.isOutsidePage.value;

			if (!usingElementTransform.value.isInOtherPage.value.status && removeEl) {
				dragAction.value = 'remove';

				setTimeout(() => {
					selection.forEach((elem) => {
						temporalRefPage.value = getPageFromElement(elem) as Page;
						removeElement(elem);
						Bugsnag.leaveBreadcrumb(`${elem.type}-${elem.id} removed after dragging off canvas`);
					});

					dragAction.value = null;
				}, 700);

				document.querySelector('.outside')?.classList.add('delete');
			}

			// Mover si está en otra página
			if (usingElementTransform.value.isInOtherPage.value.status) {
				dragAction.value = 'move';

				const newPage = usingElementTransform.value.isInOtherPage.value.canvas as HTMLElement;

				const moveableElements = Array.from(moveable.value?.target).map((el) => {
					return {
						position: {
							x: (el.getBoundingClientRect().left - newPage.getBoundingClientRect().left) / store.scale,
							y: (el.getBoundingClientRect().top - newPage.getBoundingClientRect().top) / store.scale,
						},
						id: el.id.replace('element-', ''),
					};
				});
				Bugsnag.leaveBreadcrumb(
					`Move the follow elements to ${newPage.id}: ${moveableElements.map((el) => el.id).join('; ')}`
				);

				setTimeout(() => {
					selection.forEach((elem) => {
						temporalRefPage.value = getPageFromElement(elem) as Page;
						removeElement(elem);
					});

					temporalRefPage.value = getPageFromDom(newPage) as Page;

					elements.forEach((elem) => {
						const el = moveableElements.find((dataEl: any) => dataEl.id === elem.id);
						elem.setPosition(el.position.x, el.position.y);

						addElement(elem);
					});

					dragAction.value = null;
				}, 700);

				document.querySelector('.outside')?.classList.add('move');
			}
		});
	};

	// Si cambia el tamaño de la ventana, avisamos al moveable
	// para que se ubique bien
	useEventListener('resize', () => {
		setTimeout(() => {
			if (moveable.value) moveable.value.updateRect();
		}, 500);
	});

	onMounted(async () => {
		if (moveable.value) {
			moveable.value?.destroy();
			moveable.value = null;
		}

		if (page.value) {
			canvas.value = page.value.domNode();
		}

		await nextTick();

		if (isPhotoMode.value && page.value?.backgroundImageId === element.value.id) return;

		scrollContainer.value = document.getElementById('scroll-area');
		moveable.value = createMoveable();
		registerEvents();
		toggleSizeHandlers();

		const { isScrolling } = useScroll(scrollContainer);

		watch(isScrolling, (val) => {
			if (!val && moveable.value) {
				updateGuideLines();
			}
		});

		useEventListener('resize', () => {
			moveable.value?.updateTarget();
			updateGuideLines();
		});
	});

	onBeforeUnmount(() => {
		moveable.value?.destroy();
		document.querySelector('.moveable-control-box')?.removeAttribute('style');
		moveable.value = null;
	});

	watch(
		[elements, activePanel],
		async () => {
			if (isMoveableEvent.value || !moveable.value) {
				return;
			}
			await nextTick();
			moveable.value?.updateTarget();
		},
		{ deep: true }
	);

	// Si se actualiza la selección actualizamos los targets para evitar que queden seleccionados elementos anteriores
	watch(selection, async () => {
		await nextTick();
		if (!moveable.value) {
			return;
		}
		moveable.value!.target = Array.from(document.querySelectorAll('.target')) as HTMLElement[];
	});

	// Los handlers del moveable se deben mostrar u ocultar dependiendo de su propiedad locked
	watch(isReady, () => {
		if (!moveable.value) return;
		moveable.value.renderDirections = getMoveableRenderDirections();
	});

	const interactiveElementReady = computed(() => !!moveable.value);

	return { interactiveElementReady };
};

export const useMoveable = () => ({ moveable, action, dragAction, isMiddleHandler });
