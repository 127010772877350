<script setup lang="ts">
// Classes
// Composables
import { toRef } from 'vue';

import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useElementOrderOrchestrator } from '@/composables/element/useElementOrderOrchestrator';

// Props
const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');

// Using composables
const { moveToFront, moveToBack, moveUp, moveDown, canGoUp, canGoDown } = useElementOrderOrchestrator(element).value;
</script>

<template>
	<div>
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Order</h4>

		<div data-testid="order" class="button-group update-position-div mb-6 flex flex-1">
			<div class="mr-px flex-1">
				<button
					class="flex h-8 w-full items-center justify-center rounded-tl rounded-bl bg-gray-700 px-3 text-gray-100"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoUp,
						'hover:bg-gray-600 hover:text-white': canGoUp,
					}"
					:disabled="!canGoUp"
					@click="moveUp"
				>
					<SvgIcon name="layer-up" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100">Forward</label>
			</div>

			<div class="mr-px flex-1">
				<button
					class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoDown,
						'hover:bg-gray-600 hover:text-white': canGoDown,
					}"
					:disabled="!canGoDown"
					@click="moveDown"
				>
					<SvgIcon name="layer-down" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100">Backward</label>
			</div>

			<div class="mr-px flex-1">
				<button
					class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoUp,
						'hover:bg-gray-600 hover:text-white': canGoUp,
					}"
					:disabled="!canGoUp"
					@click="moveToFront"
				>
					<SvgIcon name="forward" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100">To Front</label>
			</div>

			<div class="mr-px flex-1">
				<button
					class="flex h-8 w-full items-center justify-center rounded-tr rounded-br bg-gray-700 px-3 text-gray-100"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoDown,
						'hover:bg-gray-600 hover:text-white': canGoDown,
					}"
					:disabled="!canGoDown"
					@click="moveToBack"
				>
					<SvgIcon name="backward" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100">To Back</label>
			</div>
		</div>
	</div>
</template>
