<script lang="ts" setup>
// Classes
// Composables
import { toRef } from 'vue';

import Line from '@/Classes/Line';
// Components
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import NumberInput from '@/components/Common/NumberInput.vue';
import { useLine } from '@/composables/element/line/useLine';
import { useLineColors } from '@/composables/element/line/useLineColors';
import GAnalytics from '@/utils/GAnalytics';

// Props
const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');

// Hooks
const { updateStrokeWidth } = useLine(element);
const { updateColor } = useLineColors(element);

// Methods
const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<div class="mb-3">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Stroke</h4>
		<div class="flex gap-2">
			<ColorPicker class="h-10 w-10" :hide-gradient="true" :color="element.mainColor" @change="updateColor" />

			<div class="flex flex-col" data-testid="input-line-element-height">
				<NumberInput
					:min="1"
					class="h-10 w-20 text-sm"
					:value="element.size.height"
					@track-typing="trackTypingEvent('line-width')"
					@track-step="trackStepEvent('line-width')"
					@update="(strokeWidth) => updateStrokeWidth(strokeWidth)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Width</label>
			</div>
		</div>
	</div>
</template>
