import { ref } from 'vue';

import Image from '@/Classes/Image';
import { useCommon } from '@/composables/element/common/useCommon';
import { usePage } from '@/composables/page/usePage';
import { useArtboard } from '@/composables/project/useArtboard';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';

export type Report = {
	type: string;
	model: 'template' | 'user-vector';
	data: object;
	id: string | number;
};

class Reports {
	static findOverlapingTexts(): Array<Report> {
		const shapeRef = ref();
		const { page } = useCommon(shapeRef);
		const { getElementIndex } = usePage(page);

		const project = useProjectStore();
		return project.pages
			.map((p, index): Report | undefined => {
				const shapes = p.elements.filter((e) => e.type === 'shape');
				const texts = p.elements.filter((e) => e.type === 'text');

				const shapesWithTexts = shapes.filter((s) => {
					shapeRef.value = s;
					const shapeIndex = getElementIndex(s);

					const textsBehindOverlapped = texts
						.filter((t) => getElementIndex(t) < shapeIndex)
						.filter((t) => {
							return s.isCollided(t);
						});

					return textsBehindOverlapped.length > 0;
				});

				if (shapesWithTexts.length === 0) {
					return;
				}

				return {
					type: 'shape-over-text',
					model: 'template',
					data: {
						shapes: shapesWithTexts.map((shape) => {
							return {
								id: shape.id,
								hash: shape.metadata.sourceTemplateHash,
							};
						}),
					},
					id: project.sourceVectorId,
				};
			})
			.filter((el) => !!el) as Report[];
	}

	static async findBrokenImages(): Promise<Report | null> {
		const shapeRef = ref();
		const { page } = useCommon(shapeRef);
		const project = useProjectStore();
		const store = useMainStore();

		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			const p = project.pages[0];
			if (!p) {
				resolve(null);
				return;
			}
			const images = p.elements.filter((e) => e.type === 'image') as Image[];

			let brokenImages = images.filter((image) => {
				return !image.urlBackgroundRemoved && image.useBackgroundRemoved;
			});

			const imageRequests = images
				.filter((image) => {
					return image.urlBackgroundRemoved && image.useBackgroundRemoved;
				})
				.map((image) => {
					return new Promise((resolve) => {
						const img = document.createElement('img');

						img.onerror = () => resolve(image);
						img.onload = () => resolve(false);
						img.src = image.urlBackgroundRemoved as string;
					});
				});

			const failedResponses = (await Promise.all(imageRequests)).filter((response) => !!response) as Image[];

			brokenImages = [...brokenImages, ...failedResponses];

			if (!brokenImages.length) {
				resolve(null);
				return;
			}

			resolve({
				type: 'broken-image',
				model: 'user-vector',
				data: {
					images: brokenImages.map((image) => {
						return {
							id: image.id,
						};
					}),
				},
				id: store.userVector?.uuid as string,
			});
		});
	}

	static findContentOutside(): Report[] {
		const project = useProjectStore();
		const { artboardSizeInPx } = useArtboard();

		return project.allElements
			.filter((e) => {
				if (e.type === 'text') {
					return (
						e.position.x < 0 ||
						e.position.y < 0 ||
						e.position.x + e.size.width > artboardSizeInPx.value.width ||
						e.position.y + e.size.height > artboardSizeInPx.value.height
					);
				}

				return (
					e.position.x + e.size.width < 0 ||
					e.position.y + e.size.height < 0 ||
					e.position.x > artboardSizeInPx.value.width ||
					e.position.y > artboardSizeInPx.value.height
				);
			})
			.map((e) => {
				return {
					type: 'element-outside',
					model: 'template',
					data: {
						element: e.id,
					},
					id: project.sourceVectorId,
				};
			});
	}

	static findBlendModes(): Report[] {
		const project = useProjectStore();

		return project.allShapes
			.filter((e) => {
				return e.content.includes('mix-blend');
			})
			.map((e) => {
				return {
					type: 'element-blend-mode',
					model: 'template',
					data: {
						element: e.id,
					},
					id: project.sourceVectorId,
				};
			});
	}

	static findWrongTextHeight() {
		const project = useProjectStore();

		return project.allTexts
			.filter((e) => {
				const height = e.domNode()?.getBoundingClientRect().height || 0;
				const textHeight = e.domNode()?.querySelector('.text-element-final')?.getBoundingClientRect().height || 0;

				return textHeight > height * 1.05 || textHeight < height * 0.95;
			})
			.map((e) => {
				return {
					type: 'element-blend-mode',
					model: 'template',
					data: {
						element: e.id,
					},
					element: e,
					id: project.sourceVectorId,
				};
			});
	}
}

function elementsOverlap(el1: HTMLElement, el2: HTMLElement) {
	const domRect1 = el1.getBoundingClientRect();
	const domRect2 = el2.getBoundingClientRect();

	return !(
		domRect1.top > domRect2.bottom ||
		domRect1.right < domRect2.left ||
		domRect1.bottom < domRect2.top ||
		domRect1.left > domRect2.right
	);
}

export default Reports;
