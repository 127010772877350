<script setup lang="ts">
import { Ref, toRef } from 'vue';

import Element from '@/Classes/Element';
import { Text } from '@/Classes/Text';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import GAnalytics from '@/utils/GAnalytics';

// Props
const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');

// Using composables
const { textTransform, updateTextTransform } = useTextStyles(element as Ref<Text>);

const transformClickManager = () => {
	switch (textTransform.value) {
		case '': {
			updateTextTransform('capitalize');
			break;
		}
		case 'capitalize': {
			updateTextTransform('uppercase');
			break;
		}
		case 'uppercase': {
			updateTextTransform('lowercase');
			break;
		}
		case 'lowercase': {
			updateTextTransform('');
			break;
		}
	}
	GAnalytics.track('click', 'Button', `text-transform-${textTransform.value}`, null);
};
</script>

<template>
	<div data-testid="text-transform" class="flex flex-col">
		<button
			class="flex h-8 w-full items-center justify-center rounded bg-gray-700 px-3 text-gray-100 hover:bg-gray-600"
			data-title="alignText"
			@click="transformClickManager()"
		>
			<SvgIcon v-if="textTransform === ''" name="minus" class="h-5 w-5" />
			<SvgIcon v-if="textTransform === 'capitalize'" name="capitalize" class="h-5 w-5 text-white" />
			<SvgIcon v-if="textTransform === 'uppercase'" name="uppercase" class="h-5 w-5 text-white" />
			<SvgIcon v-if="textTransform === 'lowercase'" name="lowercase" class="h-5 w-5 text-white" />
		</button>
		<label class="mt-1 text-center text-2xs text-gray-100"> Transform </label>
	</div>
</template>
