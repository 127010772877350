import { v4 as uuidv4 } from 'uuid';

import ApiClient from '@/utils/api/ApiClient';

type SocialNetwork = 'instagram' | 'facebook' | 'pinterest' | 'twitter';

class UserApiClient extends ApiClient {
	static async exportProjectPages(body: BodyInit | any): Promise<Response> {
		const url = `${this.API_PATH}vector/export?uuid=${uuidv4()}`;

		const response = await this.request(
			url,
			{
				method: 'POST',
				body,
				headers: {
					Accept: 'application/json',
				},
			},
			true
		);

		if (!response.ok) {
			throw new Error('Export project pages | Something went wrong!');
		}

		return response;
	}

	static async sendToSocialNetwork(socialNetwork: SocialNetwork, body: BodyInit | any): Promise<Response> {
		const url = `${this.API_PATH}share/${socialNetwork}?uuid=${uuidv4()}`;

		let response;

		try {
			response = await this.request(
				url,
				{
					method: 'POST',
					body,
					headers: {
						Accept: 'application/json',
					},
				},
				true
			);
		} catch (error) {
			throw new Error(error);
		}

		if (!response.ok) {
			throw new Error('Share project pages | Something went wrong!');
		}

		return response;
	}

	static async sendToEmails(body: BodyInit | any): Promise<Response> {
		const url = `${this.API_PATH}share/email?uuid=${uuidv4()}`;

		let response;

		try {
			response = await this.request(
				url,
				{
					method: 'POST',
					body,
					headers: {
						Accept: 'application/json',
					},
				},
				true
			);
		} catch (error) {
			throw new Error(error);
		}

		if (!response.ok) {
			throw new Error('Share project pages | Something went wrong!');
		}

		return response;
	}
}

export default UserApiClient;
