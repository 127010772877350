<script lang="ts" setup>
import { ref, watch } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import Viewer from '@/components/export/Viewer.vue';
import { useUsersnap } from '@/composables/useUsersnap';
import { useProjectStore } from '@/stores/project';

const project = useProjectStore();
const present = ref(false);
const activePage = ref(0);
const { loadUserSnap, showUsersnap } = useUsersnap();
watch(present, (val) => {
	if (val) {
		loadUserSnap();
		return;
	}

	showUsersnap();
});
</script>

<template>
	<button
		data-onboarding="btn-share"
		class="relative ml-2 flex h-8 w-8 items-center justify-center overflow-hidden rounded bg-green-500 font-bold text-white hover:bg-green-600 lg:h-10 lg:w-auto lg:py-3 lg:px-6"
		tooltip="Share"
		tooltip-position="bottom"
		@click="present = true"
	>
		<SvgIcon name="presentation" class="h-4 w-4 sm:mr-2" /> <span class="hidden sm:block">Present</span>
	</button>
	<teleport to="body">
		<div v-if="present" class="fixed inset-0 z-50 bg-black">
			<Viewer
				:project="(project as any)"
				fullscreen
				class="h-screen w-screen"
				loaded
				:page="activePage"
				@fullscreenExit="present = false"
				@activePage="activePage = $event"
			/>
		</div>
	</teleport>
</template>
