<script lang="ts" setup>
// Vue
import { toRef } from 'vue';

// Classes
import Image from '@/Classes/Image';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import CropImageButton from '@/components/selection/panels/CropImageButton.vue';
import FlipMenu from '@/components/selection/panels/FlipMenu.vue';
import OrderMenu from '@/components/selection/panels/OrderMenu.vue';
import PositionMenu from '@/components/selection/panels/PositionMenu.vue';
import ReplaceImageButton from '@/components/selection/panels/ReplaceImageButton.vue';
import ToBackgroundButton from '@/components/selection/panels/ToBackgroundButton.vue';
import OpacitySlider from '@/components/selection/toolbars/OpacitySlider.vue';
import TransformMenu from '@/components/selection/toolbars/TransformMenu.vue';
import { useGroup } from '@/composables/group/useGroup';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMainStore } from '@/stores/store';

const store = useMainStore();

const props = defineProps<{ element: Image }>();

const element = toRef(props, 'element');

const { isPhotoMode } = useEditorMode();
const { isGrouped } = useGroup(element);

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">Edit image</h2>
		<button
			data-testid="close-panel"
			class="text-gray-100 hover:text-white focus:outline-none"
			@click="onCloseEditPanel"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<!-- Edition -->
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Edition</h4>

		<div class="mb-6 flex">
			<div class="w-1/3">
				<FlipMenu :inline-label="true" :element="element" />
			</div>

			<div class="w-1/4 pl-2">
				<CropImageButton :label-position="'bottom'" :element="element" />
			</div>

			<div class="w-1/4 pl-2">
				<ReplaceImageButton :label-position="'bottom'" :element="element" />
			</div>

			<div v-if="!isPhotoMode" class="w-1/4 pl-2">
				<ToBackgroundButton :element="element" />
			</div>
		</div>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="false" />

		<OpacitySlider :element="element" :opacity="element.opacity" />
	</div>
</template>
