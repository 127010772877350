<script setup lang="ts">
// Vue
import { computed, ref, toRef } from 'vue';

// Classes
import Storyset from '@/Classes/Storyset';
import Modal from '@/components/Common/modals/Modal.vue';
import Popper from '@/components/Common/Popper.vue';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useStoryset } from '@/composables/element/storyset/useStoryset';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { StorysetBackground } from '@/Types/storyset';

const props = defineProps<{
	element: Storyset;
}>();
const element = toRef(props, 'element');
const { isMobile } = useDeviceInfo();

// Emits
const emit = defineEmits(['change']);

// Methods
const onSelectOption = () => {
	emit('change', changeStorysetBackground);
	panelVisible.value = false;
};
const { storysetBackgrounds, currentStorysetBackground, changeStorysetBackground } = useStoryset(element);

const backgroundOptions = computed(() => {
	return storysetBackgrounds.value.map((bg) => ({
		label: `${bg.name}`,
		value: bg.name.toLowerCase() as StorysetBackground,
	}));
});
const panelVisible = ref(false);
const setVisibility = (visible: boolean) => (panelVisible.value = visible);
const togglePanelVisibility = () => setVisibility(!panelVisible.value);
</script>

<template>
	<button
		class="background-storyset flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-8 lg:justify-center"
		tooltip="Change Background"
		tooltip-position="top"
		@click="togglePanelVisibility"
	>
		<SvgIcon name="background" class="h-4 w-4 scale-110 lg:scale-100" />
		<span class="text-2xs lg:hidden">Backgr.</span>
	</button>
	<Popper v-if="panelVisible && !isMobile" placement="top-start">
		<div
			class="mb-1 w-28 overflow-y-scroll rounded bg-white px-2 pt-2 pb-2 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100"
		>
			<button
				v-for="option in backgroundOptions"
				:key="option.value"
				class="w-full rounded px-3 py-1.5 text-sm hover:bg-gray-100/25 hover:text-gray-800"
				:class="
					currentStorysetBackground.includes(option.label) ? 'bg-gray-100/25 font-bold text-gray-800' : 'text-gray-500'
				"
				@click.stop="onSelectOption"
				@click="changeStorysetBackground(option.value)"
			>
				<span class="flex items-baseline whitespace-nowrap">
					{{ option.label }}
				</span>
			</button>
		</div>
	</Popper>

	<Modal v-if="panelVisible && isMobile" to="body" open content-classes="py-8" @close="panelVisible = false">
		<div
			data-testid="element-background-mdal"
			class="flex w-64 flex-col items-center rounded-lg bg-gray-800 p-2 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
			v-bind="(containerProps as any)"
		>
			<p class="flex px-3 pt-4 pb-1 text-center font-bold text-white">Change background</p>
			<button
				v-for="option in backgroundOptions"
				:key="option.value"
				class="w-full rounded px-3 py-1.5 text-sm hover:bg-gray-100/25 hover:text-gray-800"
				:class="
					currentStorysetBackground.includes(option.label) ? 'bg-gray-100/25 font-bold text-gray-100' : 'text-gray-100'
				"
				@click.stop="onSelectOption"
				@click="changeStorysetBackground(option.value)"
			>
				<span class="flex items-baseline whitespace-nowrap">
					{{ option.label }}
				</span>
			</button>
		</div>
	</Modal>
</template>
