import { until, useUrlSearchParams } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { getSvg } from '@/api/DataApiClient';
import ErrorPhotoModeUrl from '@/Classes/ErrorPhotoModeUrl';
import Image from '@/Classes/Image';
import Page from '@/Classes/Page';
import { useHistoryStore } from '@/stores/history';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { PageData, TemplateLoaderData } from '@/Types/templateLoaderData';
import TemplateLoader from '@/utils/TemplateLoader';

import { useImageBackground } from './element/image/useImageBackground';
import { useArtboard } from './project/useArtboard';
import { useProject } from './project/useProject';
import { useEditorMode } from './useEditorMode';
import { useFonts } from './useFonts';
import { useZoom } from './useZoom';

const currentTasks = ref([]);
const pendingTasks = ref([]);
const isFirstLoad = ref(true);

export const useTemplateLoader = () => {
	const store = useMainStore();
	const history = useHistoryStore();
	const project = useProjectStore();
	const { setArtboardFromUrl, addPages } = useProject();
	const { isPhotoMode, isIllustratorContext, isPredefinedTextMode } = useEditorMode();
	const { fitZoomScale } = useZoom();
	const { setArtboardSize } = useArtboard();
	const { fonts } = useFonts();

	const temporalRefElement = ref(Image.createDefault());
	const { setImageAsBackground } = useImageBackground(temporalRefElement as Ref<Image>);

	const templateData = ref();

	const tasks = computed(() => {
		return [...currentTasks.value, ...pendingTasks.value];
	});

	const init = async () => {
		const pathNameSplit = window.location.pathname.split('/');
		const slug = pathNameSplit.length <= 2 ? 'new-artboard' : pathNameSplit.pop() || 'new-artboard';

		await until(fonts).toMatch((fonts) => {
			return Object.keys(fonts).length > 0;
		});

		templateData.value = await TemplateLoader.getTemplateData(slug, true);
		pendingTasks.value = templateData.value.pages.slice(1);
		currentTasks.value = templateData.value.pages.slice(0, 1);

		const defaultPages = templateData.value.pages.map((pageResponse: any) => {
			const page = Page.create();
			page.name = 'temporal';
			page.preview = pageResponse.preview;

			// vamos precargando
			const img = document.createElement('img');
			img.src = page.preview || '';

			return page;
		});

		addPages(defaultPages);

		await runTasks();
	};

	const runTasks = async () => {
		// Hemos terminado de cargar todas las páginas del proyecto
		if (pendingTasks.value.length === 0 && currentTasks.value.length === 0) {
			// Aplicamos los cambios que cada modo requiere
			await setupMode();

			history.setInitialState(templateData.value.forceSync);
			store.finishedLoading = true;

			return;
		}

		// Siguiente tanda de páginas
		if (pendingTasks.value.length > 0 && currentTasks.value.length === 0) {
			currentTasks.value = pendingTasks.value.splice(0, 5);
		}

		// Parseo de páginas
		const queue = currentTasks.value.map(async (page: PageData) => {
			let pageLoaded = Page.createDefault();

			try {
				const { data, response } = await getSvg(page.svg_url);
				const contentType = response.value?.headers.get('content-type');
				const content = contentType === 'application/json' ? JSON.parse(data.value as string) : data.value;

				pageLoaded =
					typeof content === 'string'
						? isFirstLoad.value && isIllustratorContext.value
							? TemplateLoader.parseIllustrator(content, templateData.value)
							: await TemplateLoader.parseSvg(content, templateData.value)
						: TemplateLoader.loadFromData(content, !!templateData.value.userVectorId);
			} catch (error: any) {
				// Si hay un error en multi-página lo ignoramos
				if (templateData.value.pages.length === 1) {
					throw new Error(error);
				} else {
					console.warn('error loading page', error);
				}
			}

			return {
				order: page.order,
				page: pageLoaded,
			};
		});

		// Procesamos las páginas que hay en la cola
		await Promise.all(queue).then(async (pages) => {
			// Añadimos todas las páginas del template al proyecto
			project.$patch(() => {
				pages.forEach((page) => {
					if (page.page) {
						// En la página se esta guardado la preview que puede estar desfasada
						// Usamos la que hay en la respuesta de la api del vector si esta disponible
						page.page.preview = project.pages[page.order].preview || page.page.preview;
						project.pages[page.order].replace(page.page);
					}
				});
			});

			if (isFirstLoad.value) {
				isFirstLoad.value = false;
				project.id = templateData.value.userVectorId || project.id;

				const artboardInUrl = await setArtboardFromUrl();

				if (!artboardInUrl && !isPhotoMode.value) {
					const { width, height, unit } = templateData.value.artboard;
					setArtboardSize(width, height, unit, true);
				}

				// Actualizamos el editor para usar la info del template
				setProjectMetadataFromTemplate(templateData.value);

				// Ajustamos la escala de zoom por defecto
				fitZoomScale();
			}

			currentTasks.value = [];

			await runTasks();
		});
	};

	const setProjectMetadataFromTemplate = (templateData: TemplateLoaderData) => {
		const params = useUrlSearchParams('history');

		if (!!params.category_id && !!params.category_name) {
			const categoryId = params.category_id.toString();
			const isCategoryIdNumber = /^\d+$/.test(categoryId);
			store.activeTemplateCategory = {
				id: isCategoryIdNumber ? parseInt(categoryId) : 0,
				name: params.category_name.toString(),
			};
		} else if (templateData.category_tree.length) {
			store.downloadTemplateCategory = templateData.category_tree[2];
			store.activeTemplateCategory = templateData.category_tree.reverse()[0];
		}

		if (params.category_name) {
			project.category = params.category_name.toString();
		} else {
			project.category = templateData.category_tree.length ? templateData.category_tree.reverse()[0].name : '';
		}

		project.name = !isPhotoMode.value ? templateData.name : 'Photo mode';
		project.flaticonSearch = templateData.flaticonSearch;
		project.sourceVectorId = templateData.id;

		if (templateData.userVectorId) {
			store.userVector = {
				uuid: templateData.userVectorId,
				project: templateData.project || 'wepik',
				preview: templateData.preview || '',
			};
		}
	};

	const fitPhotoModeImage = async () => {
		if (!isPhotoMode.value) return;

		temporalRefElement.value = project.pages[0].elements[0] as Image;

		setArtboardSize(templateData.value.artboard.width, templateData.value.artboard.height, 'px', true);
		await setImageAsBackground();
		fitZoomScale();
	};

	const setupMode = async () => {
		try {
			if (isPhotoMode.value) {
				await TemplateLoader.initPhotoMode(project.pages[0] as Page, templateData.value);
				await fitPhotoModeImage();
			} else if (isPredefinedTextMode.value) {
				TemplateLoader.initPredefindedTextMode(project.pages as Page[]);
			} else if (isIllustratorContext.value) {
				TemplateLoader.initIllustratorMode();
			}
		} catch (error: any) {
			if (error.name === 'ErrorPhotoModeUrl') {
				if (!localStorage.getItem('wepik.com.isPhotoMode.error')) {
					// Si falla al cargar el modo photo lo intentamos una vez más antes de lanzar el error
					localStorage.setItem('wepik.com.isPhotoMode.error', 'true');
					window.location.reload();
				} else {
					// Si falla después de reintentarlo entonces lanzamos el error definitivamente
					localStorage.removeItem('wepik.com.isPhotoMode.error');
					throw new ErrorPhotoModeUrl(error);
				}
				return;
			} else {
				throw new Error(error);
			}
		}
	};

	return {
		init,
		tasks,
		isFirstLoad,
	};
};
