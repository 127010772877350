<script lang="ts" setup>
import { computed } from 'vue';

import Element from '@/Classes/Element';
import { useProject } from '@/composables/project/useProject';

const { getPageFromElement } = useProject();
const props = defineProps<{ element: Element }>();
const page = getPageFromElement(props.element);

const target = computed(() => page?.domNode());
</script>

<template>
	<teleport :to="`#${target.id} [data-elements-container]`">
		<slot />
	</teleport>
</template>
