import { nextTick, Ref } from 'vue';

import { Text } from '@/Classes/Text';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useMainStore } from '@/stores/store';
import CurvedTextTools, { Box } from '@/utils/CurvedTextTools';
import MathTools from '@/utils/MathTools';

export const useCircleType = (text: Ref<Text>, elem: Ref<HTMLDivElement>, scale: Ref<number>) => {
	const { moveable } = useInteractions();
	const store = useMainStore();
	const { minRadius, direction, getArcToApply, getBoxInfo, percentageArc } = useCircleTypeInfo(text, scale);
	let radius = Math.abs(text.value.curvedProperties.arc || 0);
	let fontSizeNumber = 0;
	let lineHeightNumber = 0;
	let metrics: Box[] = [];
	let letters: HTMLSpanElement[] = [];
	let lettersStroke: HTMLSpanElement[] = [];
	let container = document.createElement('div');
	let strokeContainer = document.createElement('div');
	let originalPositionY: number | null = null;

	const generate = () => {
		// Preparamos los container de las letras
		container = document.createElement('div');
		const fragment = document.createDocumentFragment();
		container.setAttribute('aria-label', elem.value.innerText);
		container.style.position = 'relative';

		// Separamos en spans por letra el texto completo
		letters = CurvedTextTools.splitNode(elem.value);
		letters.forEach((letter) => fragment.appendChild(letter));
		container.appendChild(fragment);
		// Creamos una copia para el efecto stroke
		strokeContainer = container.cloneNode(true) as HTMLDivElement;
		strokeContainer.style.webkitTextStroke = '0px';
		strokeContainer.style.textShadow = 'none';
		lettersStroke = Array.from(strokeContainer.children) as HTMLSpanElement[];

		// Los añadimos a la copia del texto
		elem.value.innerHTML = '';
		elem.value.appendChild(container);
		elem.value.appendChild(strokeContainer);

		const { fontSize, lineHeight } = window.getComputedStyle(elem.value);

		fontSizeNumber = parseFloat(fontSize);
		lineHeightNumber = parseFloat(lineHeight) || fontSizeNumber;

		// Cuando estamos en el 0% necesitamos guardar la posición Y que tiene el elemento para colocar correctamente la caja
		// en caso de que el arc sea negativo
		if (percentageArc.value === 0 || originalPositionY === null) {
			originalPositionY = text.value.position.y;
		}

		// Como el texto puede estar rotado y nos daría unas medidas/posiciones incorrectas le anulamos temporalmente la rotación
		// recogemos el rect y le restauramos el transform
		const transform = elem.value.style.transform;
		elem.value.style.transform = `${transform} rotate(${-text.value.rotation}deg)`;

		metrics = letters.map(CurvedTextTools.getRect);
		elem.value.style.transform = transform;

		// Para sacar el arco minímo (hacer un círculo completo) necesitamos saber cual es el width total del texto que no es mas
		// que la suma de todos los width de las letras
		const totalWidth = metrics.reduce((sum, { width }) => sum + width, 0);
		text.value.curvedProperties.minArc = totalWidth / Math.PI / 2 / scale.value / text.value.scale + lineHeightNumber;

		// Cuando el radius es 0 es un texto curvo nuevo, por lo que sobreescribimos y le aplicamos un arc de un 40% ya que
		// nunca va a tener un radius 0
		if (radius === 0) {
			text.value.curvedProperties.arc = getArcToApply(40);
			radius = text.value.curvedProperties.arc;
		}
	};

	/**
	 * Devuelve el nodo del texto original en el dom en su estado actual
	 */
	const getFreshNode = (): HTMLDivElement => {
		let node = text.value.domNode()?.querySelector<HTMLDivElement>('.text-element-final');
		if (store.textEditing) {
			node = document.querySelector<HTMLDivElement>(`#editable-${text.value ? text.value.id : undefined}`);
		}

		if (!node) {
			throw new Error('Text element final not found in DOM');
		}

		return node;
	};

	const render = () => {
		if (!originalPositionY) {
			originalPositionY = text.value.position.y;
		}

		// Si la posición que tenemos guardada como original del texto - el transformCurve que aplicamos no coincide con
		// la posición del texto, el texto ha sido movido de posición por lo que le recalcumamos la posición original del texto
		// entendemos como posición original como la que tendría el texto sin ser curvo
		if (
			!text.value.curvedProperties.transformCurve ||
			Math.round(originalPositionY - text.value.curvedProperties.transformCurve) !== Math.round(text.value.position.y)
		) {
			originalPositionY = text.value.position.y + text.value.curvedProperties.transformCurve;
		}

		// Calculamos el origin que debemos aplicar los span que contienen las letras
		const originY = direction.value === -1 ? -radius + lineHeightNumber : radius;
		const origin = `center ${originY / fontSizeNumber}em`;
		const innerRadius = radius - lineHeightNumber;
		// Calculamos sus rotaciones a traves del radio
		const { rotations, total } = CurvedTextTools.getLetterRotation(metrics, innerRadius);

		const halfOf = (number: number) => number * 0.5; // Devuelve la mitad de un número

		const setStylesToLetter = (letter: HTMLSpanElement, index: number) => {
			const { style } = letter;
			const rotate = ((-halfOf(total) + rotations[index]) * direction.value) / scale.value / text.value.scale;
			const translateX = -halfOf(metrics[index].width) / scale.value / text.value.scale / fontSizeNumber;
			const transform = `translateX(${translateX}em) rotate(${rotate}deg)`;

			style.position = 'absolute';
			style.bottom = 'auto';
			style.left = '50%';
			style.transform = transform;
			style.transformOrigin = origin;
			style.webkitTransform = transform;
			style.webkitTransformOrigin = origin;
		};

		// Por cada letra calculamos su rotación y translate
		letters.forEach((letter: HTMLSpanElement, index: number) => setStylesToLetter(letter, index));
		lettersStroke.forEach((letter: HTMLSpanElement, index: number) => setStylesToLetter(letter, index));

		const height =
			total > 180 ? MathTools.sagitta(radius, total) : MathTools.sagitta(innerRadius, total) + lineHeightNumber;

		// Seteamos altura al container de los spans
		container.style.height = `${height / scale.value / fontSizeNumber}em`;
		container.style.transform = '';
		// Aplicamos transform al container de los strokes para que visualmente esté en el mismo sitio que el container de los spans
		strokeContainer.style.transform = `translateY(-${height / scale.value / fontSizeNumber}em)`;

		// Calculamos el tamaño y posición Y que debe tener la caja
		const { width: widthBox, height: heightBox, y: yBox } = getBoxInfo();

		text.value.size.height = heightBox;

		// Si el arc es negativo el texto se va a desplazar hacía arriba, pero no su caja, por lo que tenemos que aplicar
		// un translate a dicha caja para que el rect de selección esté visualmente conteniendo el texto
		if (text.value.curvedProperties.arc! < 0) {
			const yToApply = Math.abs(yBox);
			text.value.position.y = originalPositionY - yToApply;
			container.style.transform = `translateY(${yToApply / text.value.scale}px)`;
			strokeContainer.style.transform = `translateY(calc(${yToApply / text.value.scale}px - ${
				container.style.height
			}))`;
			text.value.curvedProperties.transformCurve = yToApply;
		}

		const diff = widthBox - text.value.size.width;
		const xToApply = diff / 2;

		// Al cambiar el width de la caja va a hacer que se produzca un movimiento de la posición de la caja
		// corregimos dicho movimiento para que se mantenga visualmente en el sitio
		if (diff !== 0) {
			text.value.size.width = widthBox;
			text.value.position.x -= xToApply;
		}

		// Un texto curvo nunca va a poder ser deformable por lo que dejamos solos los handlers de las esquinas
		if (moveable.value) {
			moveable.value!.renderDirections = ['nw', 'ne', 'sw', 'se'];
		}

		// En cambios relacionados con fuentes, parece que a veces se da algunas condiciones por el que se genera mas de un texto curvo
		// nos vamos a quedar solo con el primero ya que el resto se están generando erroneamente
		const nodes = text.value.domNode()?.querySelectorAll('#clone-curved') || [];
		if (nodes.length > 1) {
			const arrayNodes = Array.from(nodes);
			const [first, ...toRemove] = arrayNodes;
			toRemove.forEach((el) => el.remove());
		}
	};

	const setRadius = (value: number) => {
		radius = Math.max(minRadius.value, value);
		render();
	};

	const refresh = () => {
		const freshNode = getFreshNode();

		elem.value.innerHTML = freshNode.innerHTML;
		elem.value.setAttribute('style', freshNode.getAttribute('style')!);
		elem.value.style.display = 'block';

		init();
		// Si estás editando un texto tenemos que aplicar una opacidad del 50% al texto curvo, hay que esperar un tick
		// para saber si está editando el texto o no
		nextTick().then(() => {
			if (store.textEditing) {
				elem.value.style.opacity = '50%';
			} else {
				elem.value.style.opacity = '100%';
			}
		});
	};

	const init = () => {
		generate();
		render();
	};

	const destroy = () => {
		container.remove();
		strokeContainer.remove();
		text.value.curvedProperties.transformCurve = 0;
		text.value.curvedProperties.arc = null;
		text.value.curvedProperties.minArc = 0;
		originalPositionY = null;
		radius = 0;

		if (moveable.value) {
			moveable.value!.renderDirections = ['nw', 'ne', 'w', 'e', 'sw', 'se'];
		}
	};

	return {
		init,
		refresh,
		destroy,
		setRadius,
	};
};
