<script setup lang="ts">
import { watch } from 'vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useAuth } from '@/composables/useAuth';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { useDeviceInfo } from '@/composables/useDeviceInfo';

const store = useMainStore();
const project = useProjectStore();

const { isLogged, requireAuth } = useAuth();
const { isWebview, webviewVersion } = useDeviceInfo();

// Enviamos el estado del sync a la app para poder prevenir el goBack solo si es necesario
watch([() => project.allChangesSaved, () => project.syncing], ([newAllChangesSaves, newSyncing]) => {
	// Solo disponible en versión 2 superiores, ya que hacen faltas cambios en la app
	if (isWebview.value && webviewVersion.value >= 2) {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				action: 'sync',
				data: {
					allChangesSaved: newAllChangesSaves,
					syncing: newSyncing,
				},
			})
		);
	}
});
</script>

<template>
	<button
		v-if="!isLogged"
		class="group relative flex h-8 w-8 items-center justify-center rounded text-white opacity-50 hover:opacity-100 lg:h-8 lg:w-8"
		tooltip="Save changes"
		tooltip-position="bottom"
		@click="requireAuth()"
	>
		<SvgIcon name="save" class="h-5 w-5 lg:h-4 lg:w-4" />
	</button>

	<div v-if="store.userVector && isLogged">
		<span
			v-if="!project.syncing"
			class="flex h-8 w-8 items-center justify-center text-white hover:opacity-100"
			:tooltip="!project.allChangesSaved ? 'Your changes will be saved soon' : 'Auto saved few seconds ago'"
			tooltip-position="bottom"
			:class="!project.allChangesSaved ? 'opacity-30' : 'opacity-75'"
		>
			<SvgIcon name="autosave" class="h-5 w-5 lg:h-4 lg:w-4" />
		</span>
		<span v-else class="flex h-8 w-8 items-center justify-center text-white opacity-75 hover:opacity-100">
			<SvgIcon name="spinner" class="h-5 w-5 animate-spin" />
		</span>
	</div>
</template>
