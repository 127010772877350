import { debounce } from 'lodash-es';

const debounces: { [key: string]: () => void } = {};

/**
 * Permite usar un debounce sin tener que declararlo:
 * Example: useNamedDebounce('history', () => history.back(), 1000)
 * @param name
 * @param ms
 * @param callback
 */
export const useNamedDebounce = (name: string, ms: number, callback: () => void) => {
	if (!debounces[name]) {
		debounces[name] = debounce(callback, ms);
	}

	debounces[name]();
};
