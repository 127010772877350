import { computed, Ref, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import { useMainStore } from '@/stores/store';
export const useCircleTypeInfo = (text: Ref<Text>, scale?: Ref<number>) => {
	const store = useMainStore();
	if (!scale) {
		scale = toRef(store, 'scale');
	}
	const isCircleText = computed(() => text.value.curvedProperties.arc !== null);
	const minRadius = computed(() => text.value.curvedProperties.minArc || 0);
	const direction = computed(() => {
		return text.value.curvedProperties.arc !== null && text.value.curvedProperties.arc >= 0 ? 1 : -1;
	});
	const percentageArc = computed(() => {
		const max = text.value.curvedProperties.minArc!;
		const min = max + max / 2;
		const arc = Math.abs(text.value.curvedProperties.arc!);
		if (arc === 0) {
			return 100;
		}
		const stepsSlider = 50;
		const symbol = text.value.curvedProperties.arc! < 0 ? -1 : 1;
		// Si el arc es menor que el radio podemos asumir que esta por encima del 50%(no es aún un semicirculo) y por lo tanto la progresión es lineal
		if (arc <= min) {
			const unitsByStep = (min - max!) / stepsSlider;
			return Math.round((100 - (arc - max!) / unitsByStep) * symbol);
		}

		const stepsRadiusExponent = 1;
		const stepBySlider = stepsRadiusExponent / stepsSlider;

		// sabemos que arc = Math.pow(radius, exponent) necesitamos el exponente por lo que para despejar el exponente = logaritmo en base radius de arc
		const exponent = Math.log(arc) / Math.log(min);

		return Math.round(100 - (exponent - 1) / stepBySlider - stepsSlider) * symbol;
	});

	/*
	Acepta un step entre -100 a 100 y te da el arc a aplicar
	Donde el -100 es un circulo completo donde las puntas se tocan arriba y 100 un círculo completo donde las puntas se tocan abajo
	 */
	const getArcToApply = (step: number) => {
		const symbol = step >= 0 ? 1 : -1;
		step = Math.abs(step);
		const max = text.value.curvedProperties.minArc!;
		const min = max + max / 2;

		// Del 0 al 50 vamos a seguir una progresión lineal
		if (step >= 50) {
			// Como nuestro 0 es el minArc calculamos cuanto vale cada unidad del step en el slider
			// max - min (cantidad disponible) / 50 pasos que tenemos
			const unitsByStep = (min - max!) / 50;
			// El arc será el mínimo permitido(nuestro 0) + step por el valor de cada step
			const arc = max! + unitsByStep * (100 - step);

			return arc * symbol;
		}

		// Como queremos incrementar de manera exponencial vamos a tomar de exponente base el 1 e iremos incrementando este exponente hasta
		// el marcado en stepsRadiusExponent + 1(exponente base);
		// vamos a ir incrementando el exponente de 1 a 2 por lo que tenemos 1 paso de incremento(2 - 1)
		const stepsRadiusExponent = 1;
		// Total de pasos que quedan
		const stepsSlider = 50;
		// Cantidad a incrementar por cada step del slider
		const stepBySlider = stepsRadiusExponent / stepsSlider;

		const exponent = 1 + (stepsSlider - step) * stepBySlider;

		const arc = Math.pow(min, exponent);

		return arc * symbol;
	};

	/** *
	 * @return height: la altura que debe tener el texto para contener a todos sus elementos rotados
	 * @return y: px que hay que desplazar dicha caja para que visualmente quede en la misma posición
	 * */
	const getBoxInfo = () => {
		const node = text.value.domNode()?.querySelector<HTMLDivElement>('#clone-curved');
		if (!node) {
			throw new Error('Curved node not found');
		}
		const transform = node.style.transform;
		node.style.transform = `${transform} rotate(${-text.value.rotation}deg)`;
		const { letterInLeft, letterInTop, letterInRight, letterInBottom } = getLettersInLimit(node);

		const letterInLeftBounding = letterInLeft.getBoundingClientRect();
		const letterInRightBounding = letterInRight.getBoundingClientRect();
		const letterInTopBounding = letterInTop.getBoundingClientRect();
		const letterInBottomBounding = letterInBottom.getBoundingClientRect();

		node.style.transform = transform;

		const widthTextCurve =
			(letterInRightBounding.x + letterInRightBounding.width - letterInLeftBounding.x) / scale.value;
		const heightTextCurve =
			(letterInBottomBounding.y + letterInBottomBounding.height - letterInTopBounding.y) / scale.value;

		const originalTextHeight = text.value.fontSize * text.value.lineHeight * text.value.scale;
		const diffY = originalTextHeight - heightTextCurve;

		return { width: widthTextCurve, height: heightTextCurve, y: diffY };
	};

	/**
	 * Obtiene la letra mas arriba y mas abajo en el texto rotado
	 * */
	const getLettersInLimit = (node: HTMLDivElement) => {
		const letter = Array.from(node.firstChild?.childNodes || []) as HTMLSpanElement[];
		if (!letter.length) {
			throw new Error('Letters not found');
		}
		const translatesX = letter.map((span) => span.getBoundingClientRect().x);
		const translatesY = letter.map((span) => span.getBoundingClientRect().y);

		const minTranslateX = Math.min(...translatesX);
		const maxTranslateX = Math.max(...translatesX);

		const minTranslateY = Math.min(...translatesY);
		const maxTranslateY = Math.max(...translatesY);

		const letterInLeft = letter.find((span) => span.getBoundingClientRect().x === minTranslateX);
		const letterInRight = letter.find((span) => span.getBoundingClientRect().x === maxTranslateX);

		const letterInTop = letter.find((span) => span.getBoundingClientRect().y === minTranslateY);
		const letterInBottom = letter.find((span) => span.getBoundingClientRect().y === maxTranslateY);

		if (!letterInTop || !letterInRight || !letterInBottom || !letterInLeft) {
			throw new Error('Limit letters not found');
		}

		return {
			letterInLeft,
			letterInTop,
			letterInRight,
			letterInBottom,
		};
	};

	return {
		isCircleText,
		direction,
		percentageArc,
		minRadius,
		getArcToApply,
		getBoxInfo,
	};
};
