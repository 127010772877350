<script setup lang="ts">
// Vue
import { onMounted, ref } from 'vue';

import ErrorPhotoModeUrl from '@/Classes/ErrorPhotoModeUrl';
import ErrorModal from '@/components/Common/modals/ErrorModal.vue';
import OldAppModal from '@/components/Common/modals/OldAppModal.vue';
import PhotoModeErrorModal from '@/components/Common/modals/PhotoModeErrorModal.vue';
import { useAdminActions } from '@/composables/useAdminActions';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useTemplateLoader } from '@/composables/useTemplateLoader';
import Editor from '@/Editor.vue';

// Using composables
const { init } = useTemplateLoader();
const { autoConvert, crawl, showReports, showTemplateSuggestions } = useAdminActions();
const { isOldApp } = useDeviceInfo();
const { isDebugMode } = useEditorMode();

// Data
const error = ref<Error | null>(null);

// Lifecycle hooks
onMounted(async () => {
	// Si es la app antigua no hacemos nada
	if (isOldApp.value) {
		return;
	}

	try {
		await init();
	} catch (e) {
		error.value = e;

		if (e instanceof ErrorPhotoModeUrl) {
			return;
		}

		crawl(e);
		throw e;
	}
	showReports();
	showTemplateSuggestions();
	await crawl();
	await autoConvert();
});
</script>

<template>
	<div class="select-none bg-gray-800 selection:bg-blue-600" :class="isDebugMode ? 'debug-mode' : ''" style="">
		<Editor v-if="!isOldApp" />
		<OldAppModal v-if="isOldApp" />
		<template v-if="error">
			<PhotoModeErrorModal v-if="error.name === 'ErrorPhotoModeUrl'" />
			<ErrorModal v-else />
		</template>
	</div>
</template>

<style lang="sass">

.report-error

	box-shadow: 0px 0px 0px red
	opacity: 1
	animation: report-error-anim 2s infinite

	@keyframes report-error-anim
		50%
			opacity: 0.7
			box-shadow: 0px 0px 30px red

body
	-webkit-user-select: none
	-moz-user-select: none
	user-select: none
	-webkit-user-drag: none

@media (max-width: 767px)
	.toolbar *::-webkit-scrollbar
		@apply hidden

body .scrollbar-thin
	overflow: auto
	scrollbar-width: thin
	scrollbar-color: red, blue

	&::-webkit-scrollbar
		width: 12px
		height: 12px

	&::-webkit-scrollbar-thumb
		border-radius: 50px
		border: 3px solid

	&::-webkit-scrollbar-corner
		background: inherit

	&.overflow-x-hidden
		overflow-x: hidden!important


.bg-transparent-pattern
	background-image: url('/transparent-pattern.png')
	background-repeat: repeat
	background-size: 10px

.input-range, .input-range input
	&::-webkit-slider-thumb
		@apply h-4 w-4 cursor-ew-resize appearance-none rounded-full relative z-10 shadow-none bg-blue-500

		@media (min-width: 767px)
			@apply w-3 h-3

	&::-webkit-slider-thumb:hover
		@apply bg-white

	&::-moz-range-thumb
		@apply h-3 w-3 cursor-ew-resize appearance-none rounded-full bg-blue-500 relative z-20 border-0

.moveable-control-box > .moveable-control-box
	display: none !important

.moveable-area
	border: 1px var(--moveable-color) dashed !important

.moveable-origin
	display: none !important

// Tooltips
*[tooltip]
	@apply relative z-20

	&::before
		content: attr(tooltip)
		@apply text-gray-100 z-10 absolute text-xs bg-gray-800 shadow-lg py-1 px-2 rounded pointer-events-none opacity-0 whitespace-nowrap block transition-all duration-300

		@media (max-width: 767px)
			@apply hidden

	&:hover::before
		animation-delay: 0.3s


*[tooltip-position="bottom"]
	&:before
		@apply top-full left-1/2 -translate-x-1/2

	&:hover::before
		animation: tooltip-bottom 2s forwards

	@keyframes tooltip-bottom
		0%
			@apply opacity-0 mt-1.5
		25%
			@apply opacity-100 mt-1
		100%
			@apply opacity-100 mt-1

*[tooltip-position="left"]
	&:before
		@apply top-1/2 right-full -translate-y-1/2

	&:hover::before
		animation: tooltip-left 2s forwards

	@keyframes tooltip-left
		0%
			@apply opacity-0 mr-1.5
		25%
			@apply opacity-100 mr-1
		100%
			@apply opacity-100 mr-1

*[tooltip-position="right"]
	&:before
		@apply top-1/2 left-full -translate-y-1/2

	&:hover::before
		animation: tooltip-right 2s forwards

	@keyframes tooltip-right
		0%
			@apply opacity-0 ml-1.5
		25%
			@apply opacity-100 ml-1
		100%
			@apply opacity-100 ml-1

*[tooltip-position="top"]
	&:before
		@apply bottom-full left-1/2 -translate-x-1/2

	&:hover::before
		animation: tooltip-top 2s forwards

	@keyframes tooltip-top
		0%
			@apply opacity-0 mb-1.5
		25%
			@apply opacity-100 mb-1
		100%
			@apply opacity-100 mb-1

// Toast notification
.custom-toast
	@apply p-4

	.Vue-Toastification__icon
		@apply hidden

	.Vue-Toastification__toast-body
		@apply flex items-center font-bold

		&::before
			@apply mr-4

	.Vue-Toastification__close-button
		@apply opacity-100 hover:opacity-50 absolute top-0 right-0 p-3
		font-size: 0

		&::before
			content: url('data:image/svg+xml; utf8, <svg viewBox="-49 141 512 512" width="20px" height="20px" fill="rgb(255 255 255)" xmlns="http://www.w3.org/2000/svg"><path d="M242.35 397l128-127.99a25 25 0 00-35.36-35.35L207 361.64 79.01 233.66A25 25 0 0043.66 269L171.64 397 43.66 524.99A25 25 0 0079 560.34L207 432.35l127.99 128a25 25 0 0035.35-35.36L242.36 397z"/></svg>')

	.Vue-Toastification__toast--success
		.Vue-Toastification__toast-body::before
			content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 16 16" width="16px" height="16px" fill="rgb(74 210 149)" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.89321 13.6584C4.64475 13.6584 4.39628 13.5636 4.20669 13.374L0.284359 9.45168C-0.0947864 9.07253 -0.0947864 8.45777 0.284359 8.07867C0.663544 7.69948 1.27822 7.69948 1.65737 8.07867L4.89384 11.3152L14.3433 1.88383C14.7227 1.50503 15.3375 1.50558 15.7163 1.88511C16.0951 2.26461 16.0945 2.87937 15.715 3.25812L5.57908 13.3746C5.38956 13.5638 5.14133 13.6584 4.89321 13.6584Z"/></svg>')

		.Vue-Toastification__progress-bar
			@apply bg-green-500

	.Vue-Toastification__toast--error
		.Vue-Toastification__toast-body::before
			content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 16 16" width="16px" height="16px" fill="rgb(251 82 82)" xmlns="http://www.w3.org/2000/svg"><path d="M2.34313 13.6569C3.85413 15.1678 5.86313 16 8 16C10.1369 16 12.1459 15.1678 13.6569 13.6569C15.1678 12.1458 16 10.1369 16 8C16 5.86313 15.1678 3.85416 13.6569 2.34313C12.1459 0.832156 10.1369 0 8 0C5.86313 0 3.85413 0.832156 2.34313 2.34313C0.832156 3.85416 0 5.86313 0 8C0 10.1369 0.832156 12.1458 2.34313 13.6569ZM1.5625 8C1.5625 4.45034 4.45034 1.5625 8 1.5625C11.5497 1.5625 14.4375 4.45034 14.4375 8C14.4375 11.5497 11.5497 14.4375 8 14.4375C4.45034 14.4375 1.5625 11.5497 1.5625 8ZM8 10.375C7.56853 10.375 7.21875 10.0252 7.21875 9.59375V3.59375C7.21875 3.16228 7.56853 2.8125 8 2.8125C8.43147 2.8125 8.78125 3.16228 8.78125 3.59375V9.59375C8.78125 10.0252 8.43147 10.375 8 10.375ZM7.0625 11.9375C7.0625 12.4544 7.48306 12.875 8 12.875C8.51694 12.875 8.9375 12.4544 8.9375 11.9375C8.9375 11.4206 8.51694 11 8 11C7.48306 11 7.0625 11.4206 7.0625 11.9375Z"/></svg>')

		.Vue-Toastification__progress-bar
			@apply bg-red-500

	.Vue-Toastification__toast--warning
		.Vue-Toastification__toast-body::before
			content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 16 16" width="16px" height="16px" fill="rgb(255 178 41)" xmlns="http://www.w3.org/2000/svg"><path d="M2.34313 13.6569C3.85413 15.1678 5.86313 16 8 16C10.1369 16 12.1459 15.1678 13.6569 13.6569C15.1678 12.1458 16 10.1369 16 8C16 5.86313 15.1678 3.85416 13.6569 2.34313C12.1459 0.832156 10.1369 0 8 0C5.86313 0 3.85413 0.832156 2.34313 2.34313C0.832156 3.85416 0 5.86313 0 8C0 10.1369 0.832156 12.1458 2.34313 13.6569ZM1.5625 8C1.5625 4.45034 4.45034 1.5625 8 1.5625C11.5497 1.5625 14.4375 4.45034 14.4375 8C14.4375 11.5497 11.5497 14.4375 8 14.4375C4.45034 14.4375 1.5625 11.5497 1.5625 8ZM8 10.375C7.56853 10.375 7.21875 10.0252 7.21875 9.59375V3.59375C7.21875 3.16228 7.56853 2.8125 8 2.8125C8.43147 2.8125 8.78125 3.16228 8.78125 3.59375V9.59375C8.78125 10.0252 8.43147 10.375 8 10.375ZM7.0625 11.9375C7.0625 12.4544 7.48306 12.875 8 12.875C8.51694 12.875 8.9375 12.4544 8.9375 11.9375C8.9375 11.4206 8.51694 11 8 11C7.48306 11 7.0625 11.4206 7.0625 11.9375Z"/></svg>')

		.Vue-Toastification__progress-bar
			@apply bg-yellow-500

	.Vue-Toastification__toast--success.custom-spinner-toast
		.Vue-Toastification__toast-body::before
			@apply animate-spin
			width: 24px
			height: 24px
			content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 40 40" width="24px" height="24px" xmlns="http://www.w3.org/2000/svg"><path fill="white" opacity="0.3" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/><path fill="white" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z"></path></svg>')

	.Vue-Toastification__toast--info
		.Vue-Toastification__toast-body::before
			content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 16 16" width="16px" height="16px" fill="rgb(18 115 235)" xmlns="http://www.w3.org/2000/svg"><path d="M2.34313 13.6569C3.85413 15.1678 5.86313 16 8 16C10.1369 16 12.1459 15.1678 13.6569 13.6569C15.1678 12.1458 16 10.1369 16 8C16 5.86313 15.1678 3.85416 13.6569 2.34313C12.1459 0.832156 10.1369 0 8 0C5.86313 0 3.85413 0.832156 2.34313 2.34313C0.832156 3.85416 0 5.86313 0 8C0 10.1369 0.832156 12.1458 2.34313 13.6569ZM1.5625 8C1.5625 4.45034 4.45034 1.5625 8 1.5625C11.5497 1.5625 14.4375 4.45034 14.4375 8C14.4375 11.5497 11.5497 14.4375 8 14.4375C4.45034 14.4375 1.5625 11.5497 1.5625 8ZM8 10.375C7.56853 10.375 7.21875 10.0252 7.21875 9.59375V3.59375C7.21875 3.16228 7.56853 2.8125 8 2.8125C8.43147 2.8125 8.78125 3.16228 8.78125 3.59375V9.59375C8.78125 10.0252 8.43147 10.375 8 10.375ZM7.0625 11.9375C7.0625 12.4544 7.48306 12.875 8 12.875C8.51694 12.875 8.9375 12.4544 8.9375 11.9375C8.9375 11.4206 8.51694 11 8 11C7.48306 11 7.0625 11.4206 7.0625 11.9375Z"/></svg>')

		.Vue-Toastification__progress-bar
			@apply bg-blue-500
</style>
