<script lang="ts" setup>
// Vue
import { computed, ref, toRef } from 'vue';

// Classes
import Image from '@/Classes/Image';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Components
import PhotosPanel from '@/components/panels/photos/PhotosPanel.vue';
// Composables
import { useImage } from '@/composables/element/image/useImage';
import { useMainStore } from '@/stores/store';
// Types
import { ImageApi } from '@/Types/apiClient';

// Props
const props = defineProps<{ element: Image; labelPosition?: string }>();
const element = toRef(props, 'element');
const { replace } = useImage(element);

// Data
const replacing = ref(false);

// Computeds
const isCropping = computed(() => !!store.croppingId?.length);

// Using composables
const store = useMainStore();

// Methods
const onCloseModal = () => (replacing.value = false);

const replaceImage = async (image: ImageApi) => {
	await replace(image);

	replacing.value = false;
};
</script>

<template>
	<div>
		<div>
			<button
				class="flex h-9 w-9 items-center justify-center rounded bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white lg:w-full lg:px-3"
				data-testid="replace-image"
				:class="{
					'disabled cursor-not-allowed opacity-50': isCropping,
					'lg:h-9': labelPosition === 'right',
					'lg:h-8': labelPosition === 'bottom',
				}"
				:disabled="isCropping"
				@click="replacing = true"
			>
				<SvgIcon name="replace" class="h-4 w-4 scale-90" />
				<label v-if="labelPosition === 'right'" class="pointer-events-none ml-2 text-sm">Replace</label>
			</button>
		</div>
		<label v-if="labelPosition === 'bottom'" class="mt-1 block text-center text-2xs text-gray-100">Replace</label>

		<Modal
			v-if="replacing"
			content-classes="rounded w-11/12  lg:w-auto lg:max-w-lg lg:min-w-lg relative bg-white max-h-full py-4 lg:py-5 px-4 lg:px-6 h-[90vh]"
			open
			@close="replacing = false"
		>
			<PhotosPanel data-testid="replace-img-modal" modal @closeModal="onCloseModal" @selection="replaceImage" />
		</Modal>
	</div>
</template>
