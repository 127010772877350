<script setup lang="ts">
import { watchOnce } from '@vueuse/core';
import { computed, ref, toRef, watch } from 'vue';

import { Text } from '@/Classes/Text';
import { useCircleText } from '@/composables/element/text/useCircleText';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useText } from '@/composables/element/text/useText';
import { getTextStyles } from '@/composables/element/text/useTextStyles';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMainStore } from '@/stores/store';
import TextTools from '@/utils/TextTools';

const store = useMainStore();
const props = defineProps<{ element: Text; forPreview?: boolean; scale: number; smartSelection?: boolean }>();
const scale = toRef(props, 'scale');
const element = toRef(props, 'element');
const textStyles = getTextStyles(element);

const firstClick = ref(false);

const { isDragging } = useInteractions();
const { isRenderingContext } = useEditorMode();

const text = ref();
useCircleText(element, text, scale);

const startEditing = () => {
	if (isDragging.value) {
		return;
	}

	if (!store.textEditing && store.selection.length && store.selectionId[0] === props.element.id) {
		if (firstClick.value === false) {
			firstClick.value = true;

			// Comprobamos que cuando se cambie la selección y se pulsa sobre otro texto se pone a false el firstClick
			watchOnce(
				() => store.selectionId[0],
				() => {
					if (store.selectionId[0] !== props.element.id) {
						firstClick.value = false;
					}
				}
			);

			return;
		}

		store.textEditingId = props.element.id;
		watchOnce(
			() => store.textEditingId,
			() => {
				firstClick.value = false;
			}
		);
	}
};

const outlinedTextStyles = computed(() => TextTools.getOutlinedTextStyles(textStyles.value));
const isOutlinedText = computed(() => TextTools.haveOutlinedText(textStyles.value));
// Fix de sombras para descarga de PDF en IOS
// -webkit-filter: opacity(1)

const inRenderingContext = !!window.RENDERER;
const { fitHeight } = useText(element);
const { isCircleText } = useCircleTypeInfo(element, scale);
watch(isCircleText, async () => {
	if (store.textEditing || isCircleText.value) {
		return;
	}
	fitHeight();
});
</script>

<template>
	<div
		class="notranslate h-full w-full"
		translate="no"
		@mouseup="startEditing"
		@touchend.prevent="startEditing"
		@keyup="startEditing"
	>
		<!-- Para que los pdf links funcionen -->
		<div
			v-if="inRenderingContext"
			class="text-element-final absolute z-0"
			:class="element.curvedProperties.arc !== null ? 'opacity-0' : ''"
			:style="textStyles"
			v-html="props.element.content"
		></div>
		<!-- Texto real -->
		<div
			v-show="store.textEditing?.id !== element.id"
			ref="text"
			:class="element.curvedProperties.arc !== null ? 'opacity-0' : ''"
			class="text-element-final pointer-events-none absolute z-0 w-full"
			:style="isRenderingContext ? { ...textStyles, '-webkit-filter': 'opacity(1)' } : textStyles"
			v-html="props.element.content"
		></div>
		<!-- Outline que hay por detrás -->
		<div
			v-if="isOutlinedText"
			v-show="store.textEditing?.id !== element.id"
			:class="element.curvedProperties.arc !== null ? 'opacity-0' : ''"
			class="z-1 pointer-events-none absolute w-full"
			:style="outlinedTextStyles"
			v-html="props.element.content"
		></div>
	</div>
</template>
