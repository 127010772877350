import { OnResize } from 'moveable';

import Element from '@/Classes/Element';
import { Filter } from '@/Classes/Filter';
import Mask from '@/Classes/Mask';
import { ImageApi } from '@/Types/apiClient';
import { Crop, Flip, Position, SerializedClass, Size } from '@/Types/types';
import ImageTools from '@/utils/ImageTools';

type LegacyImageValues = {
	useBackgroundRemoved: boolean;
};
type BackgroundModes = 'foreground' | 'background' | 'both' | 'original';

class Image extends Element {
	type: 'image' = 'image';
	url: string;
	preview: string | null;
	urlBackgroundRemoved: string | null;
	backgroundMode: BackgroundModes;
	filter: Filter | null;
	mask: Mask | null;
	crop: Crop;

	protected constructor(
		// Element
		metadata: object,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number,
		// Image
		url: string,
		preview: string | null,
		filter: Filter | null,
		mask: Mask | null,
		crop: Crop,
		urlBackgroundRemoved: string | null,
		backgroundMode: BackgroundModes
	) {
		super(metadata, size, position, rotation, flip, group, locked, keepProportions, opacity);

		this.url = url;
		this.preview = preview;
		this.filter = filter;
		this.mask = mask;
		this.crop = crop;
		this.urlBackgroundRemoved = urlBackgroundRemoved;
		this.backgroundMode = backgroundMode;
	}

	static defaults() {
		return {
			// Element
			metadata: {},
			size: { height: 0, width: 0 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: true,
			opacity: 1,
			// Image
			url: 'https://wepik.com/svg/mask-placeholder.svg',
			preview: null,
			filter: null,
			mask: null,
			crop: {
				size: {
					width: 0,
					height: 0,
				},
				position: {
					x: 0,
					y: 0,
				},
			},

			urlBackgroundRemoved: null,
			backgroundMode: 'original' as BackgroundModes,
		};
	}

	static create(url: string, config?: Partial<Image>): Image {
		const defaults = Image.defaults();

		return new Image(
			// Element
			config?.metadata || defaults.metadata,
			config?.size || defaults.size,
			config?.position || defaults.position,
			config?.rotation || defaults.rotation,
			config?.flip || defaults.flip,
			config?.group || defaults.group,
			config?.locked || defaults.locked,
			config?.keepProportions || defaults.keepProportions,
			config?.opacity || defaults.opacity,
			// Image
			url,
			config?.preview || defaults.preview,
			config?.filter || defaults.filter,
			config?.mask || defaults.mask,
			config?.crop || defaults.crop,

			config?.urlBackgroundRemoved || defaults.urlBackgroundRemoved,
			config?.backgroundMode || defaults.backgroundMode
		);
	}

	static unserialize(data: SerializedClass<Image> & LegacyImageValues): Image {
		const defaults = Image.defaults();

		const {
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// Image
			url,
			preview,
			filter,
			mask,
			crop,
			urlBackgroundRemoved,
			backgroundMode,
			useBackgroundRemoved,
		} = data;

		const restoredBgMode =
			useBackgroundRemoved !== undefined ? (useBackgroundRemoved ? 'foreground' : 'original') : backgroundMode;

		const elem = new Image(
			// Element
			metadata || defaults.metadata,
			size || defaults.size,
			position || defaults.position,
			rotation !== undefined ? rotation : defaults.rotation,
			flip || defaults.flip,
			group || defaults.group,
			locked !== undefined ? locked : defaults.locked,
			keepProportions !== undefined ? keepProportions : defaults.keepProportions,
			opacity !== undefined ? opacity : defaults.opacity,
			// Image
			url || defaults.url,
			preview || defaults.preview,
			filter ? Filter.unserialize(filter) : null,
			mask ? Mask.unserialize(mask) : null,
			crop || defaults.crop,

			urlBackgroundRemoved || defaults.urlBackgroundRemoved,
			restoredBgMode || defaults.backgroundMode
		);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static createDefault(merge = {}): Image {
		let defaults = Image.defaults();

		defaults = { ...defaults, ...merge };

		return new Image(
			// Element
			defaults.metadata,
			defaults.size,
			defaults.position,
			defaults.rotation,
			defaults.flip,
			defaults.group,
			defaults.locked,
			defaults.keepProportions,
			defaults.opacity,
			// Image
			defaults.url,
			defaults.preview,
			defaults.filter,
			defaults.mask,
			defaults.crop,
			defaults.urlBackgroundRemoved,
			defaults.backgroundMode
		);
	}

	static async fromApiImage(img: ImageApi): Promise<Image> {
		const image = Image.create(img.url, {
			metadata: img.metadata,
			preview: img.preview,
			urlBackgroundRemoved: img.backgroundRemoved,
			backgroundMode: img.backgroundRemoved ? 'foreground' : 'original',
		});
		await ImageTools.getRealImageSize(img.preview || img.url).then((size) => image.setSize(size.width, size.height));
		return image;
	}

	static async fromUrl(url: string): Promise<Image> {
		const image = Image.create(url);
		await ImageTools.getRealImageSize(url).then((size) => image.setSize(size.width, size.height));
		return image;
	}

	public setMask(mask: Mask | null) {
		this.mask = mask;
	}

	public hasCrop() {
		return this.crop?.size.width !== 0 && this.crop?.size.height !== 0;
	}

	public getImageLimits(delta: number[], maxPositionAndSize: any) {
		return {
			top: this.position.y - delta[1] <= maxPositionAndSize.position.y,
			right:
				this.position.x + this.size.width + delta[0] >= this.position.x + this.crop.position.x + this.crop.size.width,
			bottom:
				this.position.y + this.size.height + delta[1] >= this.position.y + this.crop.position.y + this.crop.size.height,
			left: this.position.x - delta[0] <= maxPositionAndSize.position.x,
		};
	}

	public getImagePropsRegardingLimits(ev: OnResize, isCropping: boolean, maxPositionAndSize: any) {
		const { drag, width, height, delta, direction } = ev;
		const { translate } = drag;

		const position = {
			x: translate[0],
			y: translate[1],
		};

		const size = {
			width,
			height,
		};

		// #3615 | Crop limits
		// if (!isCropping) return { position, size };

		// const isLeftHandler = direction[0] === -1;
		// const isRightHandler = direction[0] === 1;
		// const isTopHandler = direction[1] === -1;
		// const isBottomHandler = direction[1] === 1;

		// const limits = this.getImageLimits(delta, maxPositionAndSize);

		// if (isLeftHandler && limits.left && delta[0] >= 0) {
		// 	position.x = maxPositionAndSize.position.x;
		// 	size.width = maxPositionAndSize.size.width;
		// }

		// if (isRightHandler && limits.right) {
		// 	size.width = this.crop.size.width + this.crop.position.x;
		// }

		// if (isTopHandler && limits.top && delta[1] >= 0) {
		// 	position.y = maxPositionAndSize.position.y;
		// 	size.height = maxPositionAndSize.size.height;
		// }

		// if (isBottomHandler && limits.bottom) {
		// 	size.height = this.crop.size.height + this.crop.position.y;
		// }

		return { position, size };
	}
}

export default Image;
