<script lang="ts" setup>
// Vue
import { computed } from 'vue';

import Page from '@/Classes/Page';
import { SolidColor } from '@/Classes/SolidColor';
// Components
import DefaultPreview from '@/components/export/previews/DefaultPreview.vue';
import FacebookPostsPreview from '@/components/export/previews/FacebookPostsPreview.vue';
import FacebookProfileCoversPreview from '@/components/export/previews/FacebookProfileCoversPreview.vue';
import InstagramPostsPreview from '@/components/export/previews/InstagramPostsPreview.vue';
import InstagramStoriesPreview from '@/components/export/previews/InstagramStoriesPreview.vue';
import MultipagePreview from '@/components/export/previews/MultipagePreview.vue';
import SlidegoPresentationPreview from '@/components/export/previews/SlidegoPresentationPreview.vue';
import TwitterHeadersPreview from '@/components/export/previews/TwitterHeadersPreview.vue';
import TwitterPostPreview from '@/components/export/previews/TwitterPostPreview.vue';
import YoutubeChannelArtsPreview from '@/components/export/previews/YoutubeChannelArtsPreview.vue';
import YoutubeThumbnailsPreview from '@/components/export/previews/YoutubeThumbnailsPreview.vue';
import ZoomBackgroundPreview from '@/components/export/previews/ZoomBackgroundPreview.vue';
import { useProject } from '@/composables/project/useProject';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { DownloadFormat } from '@/Types/types';

// props
const props = defineProps<{
	selection?: string[];
	activeTemplate: string;
	templateDistribution: string;
}>();

const project = useProjectStore();
const store = useMainStore();
const { clonePage } = useProject();

// Data
const template = computed(() => {
	if (props.templateDistribution === 'present') {
		return SlidegoPresentationPreview;
	}

	if (props.templateDistribution === 'multipages') {
		return MultipagePreview;
	}

	if (props.templateDistribution === 'publish') {
		switch (props.activeTemplate) {
			case 'Instagram Stories':
				return InstagramStoriesPreview;
			case 'Instagram Posts':
				return InstagramPostsPreview;
			case 'Facebook Posts':
				return FacebookPostsPreview;
			case 'Facebook Profile Covers':
				return FacebookProfileCoversPreview;
			case 'Youtube Thumbnails':
				return YoutubeThumbnailsPreview;
			case 'Youtube Channel Arts':
				return YoutubeChannelArtsPreview;
			case 'Twitter Headers':
				return TwitterHeadersPreview;
			case 'Twitter Posts':
				return TwitterPostPreview;
			case 'Zoom Background':
				return ZoomBackgroundPreview;
			default:
				// Default
				return DefaultPreview;
		}
	}

	return DefaultPreview;
});

// Methods
const pages = computed(() => {
	const pagesClone = project.pages.map((page) => clonePage(page as Page));

	pagesClone.map((page) => {
		if (store.downloadingFormat === DownloadFormat.tpng) {
			page.background = SolidColor.transparent();
		} else if (store.downloadingFormat === DownloadFormat.jpg) {
			page.background = page.background.withoutAlpha();
		}
		return page;
	});

	return pagesClone;
});
</script>

<template>
	<component :is="template" :key="'preview-' + store.downloadingFormat" :pages="pages"></component>
</template>
