<script setup lang="ts">
// Vue
import { computed, ref, toRef } from 'vue';

// Classes
import { Text } from '@/Classes/Text';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import TextLinkInput from '@/components/selection/toolbars/texts/TextLinkInput.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';

// Props
const props = defineProps<{ element: Text }>();
const textElement = toRef(props, 'element');
const { isCircleText } = useCircleTypeInfo(textElement);

// Data
const showLink = ref(false);
const textLink = computed(() => textElement.value.link);

// Methods
const handleLink = () => {
	if (isCircleText.value) {
		return;
	}
	showLink.value = !showLink.value;
};

const activeLink = computed(() => textLink.value.length > 0 && textLink.value.every((l) => l.length));
</script>

<template>
	<div>
		<button
			:disabled="isCircleText"
			class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-8 lg:w-8 lg:justify-center"
			tooltip="Link"
			tooltip-position="top"
			:class="{ 'text-white': activeLink, 'cursor-not-allowed opacity-25': isCircleText }"
			@click="handleLink"
		>
			<SvgIcon name="chain" class="h-5 w-5 rotate-45 scale-90 lg:h-4 lg:w-4" />
			<p class="text-2xs lg:hidden">Link</p>
		</button>

		<TextLinkInput v-if="showLink" :element="textElement" @close="showLink = !showLink" />
	</div>
</template>
