<script lang="ts" setup>
import { onBeforeMount, toRef } from 'vue';

import { SolidColor } from '@/Classes/SolidColor';
import { Text } from '@/Classes/Text';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import NumberInput from '@/components/Common/NumberInput.vue';
import { useTextEffects } from '@/composables/element/text/useTextEffects';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import { Color } from '@/Types/colorsTypes';
import GAnalytics from '@/utils/GAnalytics';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const { hasOutline, isOutlineEm } = useTextEffects(element);
const { outline, updateBorderColor, updateBorderWidth } = useTextStyles(element);

onBeforeMount(() => {
	if (hasOutline.value && isOutlineEm.value) convertFromEmToPx();
	if (!hasOutline.value) updateBorderSize(2);
});

const convertFromEmToPx = () => {
	const factor = element.value.fontSize / 16;
	if (element.value.outline.unit) delete element.value.outline.unit;
	element.value.outline.width *= 16 * factor;
};

const updateColor = (color: Color) => {
	// Trackeamos si ha cambiado la opacidad del borde
	if (
		color.r === element.value.outline.color.r &&
		color.g === element.value.outline.color.g &&
		color.b === element.value.outline.color.b &&
		color.a !== element.value.outline.color.a
	) {
		GAnalytics.track('click', 'Button', `change-stroke-opacity`, null);
	}

	// Trackeamos si ha cambiado el color
	if (color.toCssString() !== element.value.outline.color.toCssString()) {
		GAnalytics.track('click', 'Button', `change-stroke-color`, null);
	}

	updateBorderColor(color as SolidColor);
};

const updateBorderSize = (width: number) => {
	updateBorderWidth(width);
	GAnalytics.track('click', 'Button', `change-stroke-width`, null);
};
</script>

<template>
	<div class="flex items-end gap-4">
		<ColorPicker class="h-10 w-10 shrink-0" :color="outline.color" :hide-gradient="true" @change="updateColor" />
		<div class="flex w-full items-center gap-2">
			<div class="relative flex flex-1">
				<label class="absolute -top-7 text-xs text-gray-100">Width</label>
				<input
					:value="outline.width"
					class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
					:min="0"
					:step="0.01"
					type="range"
					@input="updateBorderSize(parseInt($event.target.value))"
				/>
				<span
					class="pointer-events-none absolute top-0 left-0 h-[2px] rounded-full bg-blue-500"
					data-testid="opacityInput"
					:style="{
						width: `${outline.width}%`,
					}"
				/>
			</div>
			<NumberInput
				data-testid="text-border"
				:min="0"
				class="h-6 w-12 text-xs"
				:value="outline.width"
				@update="(width) => updateBorderSize(width)"
			/>
		</div>
	</div>
</template>
