// Vue
import { computed, ComputedRef, Ref } from 'vue';

// Classes
import Image from '@/Classes/Image';
// Composables
import { useElementOrder } from '@/composables/element/useElementOrder';

export const useImageOrder = (image: Ref<Image>, isBackground: ComputedRef<boolean>) => {
	const commonOrder = useElementOrder(image);

	const canGoDown = computed(() => commonOrder.canGoDown.value && !isBackground.value);
	const canGoUp = computed(() => commonOrder.canGoUp.value && !isBackground.value);

	const moveElementToIndex = (indexToMove: number) => {
		if (isBackground.value) return;
		commonOrder.moveElementToIndex(indexToMove);
	};

	return {
		...commonOrder,
		canGoDown,
		canGoUp,
		moveElementToIndex,
	};
};
