<script setup lang="ts">
import { toRef, watch } from 'vue';

import Element from '@/Classes/Element';
import ColorPickerGroup from '@/components/Common/Color/ColorPickerGroup.vue';
// Composables
import { useGroupColors } from '@/composables/group/useGroupColors';

const props = withDefaults(
	defineProps<{
		group: Element[];
	}>(),
	{
		group: () => [],
	}
);

const group = toRef(props, 'group');
// Using composables
const { colors, textsColors, linesStrokeColors, updateGroupColor, colorSelected, getElementsWithColorSelected } =
	useGroupColors(group);

watch(
	colorSelected,
	() => {
		getElementsWithColorSelected();
	},
	{ immediate: true }
);
</script>

<template>
	<div data-onboarding="project-colors" class="flex items-center">
		<ColorPickerGroup
			class="h-6 flex-wrap lg:h-10"
			:max="Infinity"
			picker-class="w-6 lg:w-10 last:mr-0 lg:mr-0 gap-2"
			:colors="colors"
			:texts-colors="textsColors"
			:lines-stroke-colors="linesStrokeColors"
			@change="({ color, newColor }) => updateGroupColor(color, newColor)"
			@select="colorSelected = $event"
		/>
	</div>
</template>
