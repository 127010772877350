import HeaderWelcomePhotos from '@/assets/header-welcome-photos.jpg';
import HeaderWelcomeSlidesgo from '@/assets/header-welcome-slidesgo.jpg';
import HeaderWelcome from '@/assets/header-welcome-wepik.jpg';

export default {
	wepik: [
		{
			attachTo: {
				element: '#onboarding',
			},
			content: {
				image: HeaderWelcome,
				title: 'Hey, there! Welcome to Wepik, Freepik’s online editor!',
				description:
					'This new tool allows you to customize templates to make your next project stand out or create your own designs without using any additional software. Download all your work - ready to print or use directly online!',
			},
			options: {
				popper: {
					placement: 'auto',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-panel-templates"]',
			},
			content: {
				title: 'Find the perfect template',
				description: 'Select a customizable template and start editing',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-panel-elements"]',
			},
			content: {
				title: 'Add text, elements, and images',
				description: 'Explore through our collection of elements, add personalized text, replace photos, and more.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="project-colors"]',
			},
			content: {
				title: 'Colors',
				description: 'Modify element colors in just one click.',
			},
			options: {
				popper: {
					placement: 'bottom',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="page-interactions"]',
			},
			content: {
				title: 'Add pages',
				description: 'Create as many pages as you want or copy them.',
			},
			options: {
				popper: {
					placement: 'top',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-download"]',
			},
			content: {
				title: 'Download and share',
				description: 'Once edited, download your creation - ready to print or share it online.',
			},
			options: {
				popper: {
					placement: 'bottom',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="help"]',
			},
			content: {
				title: 'Help',
				description: 'Need help with something? Click here',
			},
			options: {
				popper: {
					placement: 'top',
				},
			},
		},
	],
	slidesgo: [
		{
			attachTo: {
				element: '#onboarding',
			},
			content: {
				image: HeaderWelcomeSlidesgo,
				title: "Hey there! Welcome to Wepik, Slidesgo's online editor!",
				description:
					'This new tool allows you to customize presentation templates to make your projects stand out without using\n            any additional software. Download all your work and engage your audience! Would you like to help us to improve the beta version? You can provide your feedback directly at support@wepik.com',
			},
			options: {
				popper: {
					placement: 'auto',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-panel-templates"]',
			},
			content: {
				title: 'Start editing online',
				description: 'Find a wide variety of graphic resources to boost your presentation.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-panel-elements"]',
			},
			content: {
				title: 'Add text, elements, and images',
				description: 'Explore through our collection of elements, add personalized text, replace photos, and more.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="project-colors"]',
			},
			content: {
				title: 'Colors',
				description: 'Modify element colors in just one click.',
			},
			options: {
				popper: {
					placement: 'bottom',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="page-interactions"]',
			},
			content: {
				title: 'Add pages',
				description: 'Create as many pages as you want or copy them.',
			},
			options: {
				popper: {
					placement: 'top',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-share"]',
			},
			content: {
				title: 'Present online',
				description: 'Once edited, get your link to share or present your creation online!.',
			},
			options: {
				popper: {
					placement: 'bottom',
				},
			},
		},
	],
	photo: [
		{
			attachTo: {
				element: '#onboarding',
			},
			content: {
				image: HeaderWelcomePhotos,
				title: 'Hey there! Welcome to Wepik, Freepik’s online editor!',
				description:
					'This new feature allows you to customize photos easily without using any additional software. Make your own adjustments, such as cropping images, or applying fun filters quickly. Once edited, download your pic in jpg or png in just one click.',
			},
			options: {
				popper: {
					placement: 'auto',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="crop"]',
			},
			content: {
				title: 'Crop images',
				description: 'Customize your photos and crop them to your liking.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="filter"]',
			},
			content: {
				title: 'Apply filters',
				description: 'Enhance your images in one click with our awesome filters.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="filter-settings"]',
			},
			content: {
				title: 'Settings',
				description: 'Make adjustments to give your photos a professional look.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-panel-elements"]',
			},
			content: {
				title: 'Add text, elements, and images',
				description: 'Explore through our collection of elements, add personalized text, replace photos, and more.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-download"]',
			},
			content: {
				title: 'Download and share',
				description: 'Once edited, download your creation - ready to print',
			},
			options: {
				popper: {
					placement: 'bottom',
				},
			},
		},
		{
			attachTo: {
				element: '[data-onboarding="btn-panel-templates"]',
			},
			content: {
				title: 'Find a template',
				description: 'Boost your designs with our customizable templates. Explore our collection now!.',
			},
			options: {
				popper: {
					placement: 'right',
				},
			},
		},
	],
};
