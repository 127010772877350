// Bugsnag
import Bugsnag from '@bugsnag/js';
import { computed, Ref } from 'vue';

import Element from '@/Classes/Element';
import { useCommon } from '@/composables/element/common/useCommon';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { usePage } from '@/composables/page/usePage';
import ArrayTools from '@/utils/ArrayTools';

export const useElementOrder = (element: Ref<Element>) => {
	const { page } = useCommon(element);
	const { getElementIndex } = usePage(page);
	const { isDragging } = useInteractions();

	const canGoDown = computed(() => !!collisioningSortedElementsUnder.value.length);
	const canGoUp = computed(() => !!collisioningSortedElementsOver.value.length);
	const elementIndex = computed(() => getElementIndex(element.value));
	const firstIndex = computed(() => (page.value.backgroundImageId ? 1 : 0));
	const collisioningElements = computed(() =>
		page.value.elements.filter(
			(el) =>
				!isDragging.value &&
				el.id !== element.value.id &&
				el.id !== page.value.backgroundImageId &&
				el.isCollided(element.value)
		)
	);
	const collisioningSortedElementsOver = computed(() =>
		collisioningElements.value.filter((el: Element) => {
			const elIndex = getElementIndex(el);
			return elIndex > elementIndex.value;
		})
	);
	const collisioningSortedElementsUnder = computed(() =>
		collisioningElements.value.filter((el: Element) => {
			const elIndex = getElementIndex(el);
			return elIndex < elementIndex.value;
		})
	);

	const moveElementToIndex = (indexToMove: number) => {
		if (indexToMove === elementIndex.value) return;
		if (indexToMove < elementIndex.value && !canGoDown.value && !element.value.group) return;
		if (indexToMove > elementIndex.value && !canGoUp.value && !element.value.group) return;

		const actualIndex = elementIndex.value;

		const elements = page.value.elements;
		ArrayTools.move(elements, actualIndex, indexToMove);
	};

	const moveUp = () => {
		if (!canGoUp.value) return;
		const newIndex = getElementIndex(collisioningSortedElementsOver.value[0]);
		moveElementToIndex(newIndex);
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} Forward`);
	};

	const moveDown = () => {
		if (!canGoDown.value) return;
		const newIndex = getElementIndex(
			collisioningSortedElementsUnder.value[collisioningSortedElementsUnder.value.length - 1]
		);
		moveElementToIndex(newIndex);
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} Backward`);
	};

	const moveToBack = () => {
		moveElementToIndex(firstIndex.value);
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} To Back`);
	};

	const moveToFront = () => {
		const lastPosition = page.value.elements.length - 1;
		moveElementToIndex(lastPosition);
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} To Front`);
	};

	return {
		canGoUp,
		canGoDown,
		firstIndex,
		elementIndex,
		moveElementToIndex,
		moveUp,
		moveDown,
		moveToBack,
		moveToFront,
	};
};
