<script lang="ts" setup>
import { toRef } from 'vue';

import Storyset from '@/Classes/Storyset';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import FlipMenu from '@/components/selection/panels/FlipMenu.vue';
import OrderMenu from '@/components/selection/panels/OrderMenu.vue';
import PositionMenu from '@/components/selection/panels/PositionMenu.vue';
import OpacitySlider from '@/components/selection/toolbars/OpacitySlider.vue';
import ChangeBackgroundDropdown from '@/components/selection/toolbars/storyset/ChangeBackgroundDropdown.vue';
import TransformMenu from '@/components/selection/toolbars/TransformMenu.vue';
import { useStorysetColors } from '@/composables/element/storyset/useStorysetColors';
import { useGroup } from '@/composables/group/useGroup';
import { useMainStore } from '@/stores/store';

const store = useMainStore();

const props = defineProps<{ element: Storyset }>();

const element = toRef(props, 'element');

const { updateColor } = useStorysetColors(element);
const { isGrouped } = useGroup(element);

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">Edit Storyset element</h2>
		<button
			class="text-gray-100 hover:text-white focus:outline-none"
			data-testid="close-panel"
			@click="onCloseEditPanel"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<!-- Edition -->
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Edition</h4>

		<div class="flex">
			<div class="w-1/3">
				<FlipMenu :inline-label="true" :element="element" />
			</div>
		</div>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="true" />

		<OpacitySlider :element="element" :opacity="element.opacity" />

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Background</h4>
		<ChangeBackgroundDropdown :element="element" />

		<!-- Main color -->
		<h4 class="mt-8 mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Color</h4>

		<div class="grid grid-cols-6 gap-2">
			<ColorPicker
				class="h-10 w-10"
				:color="element.mainColor"
				:hide-alpha="true"
				@change="(newColor) => updateColor(newColor)"
			/>
		</div>
	</div>
</template>
