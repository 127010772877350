import MathTools from '@/utils/MathTools';

export interface LetterRotations {
	total: number;
	rotations: number[];
}

export interface Box {
	height: number;
	left: number;
	top: number;
	width: number;
}

class CurvedTextTools {
	public static getLetterRotation(metrics: Box[], r: number): LetterRotations {
		return metrics.reduce<LetterRotations>(
			(data, { width }) => {
				const rotation = MathTools.radiandsToAngle(width / r);

				return {
					total: data.total + rotation,
					rotations: data.rotations.concat([data.total + rotation / 2]),
				};
			},
			{ total: 0, rotations: [] }
		);
	}

	public static getRect(element: HTMLSpanElement): Box {
		const rect = element.getBoundingClientRect();

		return {
			height: rect.height,
			left: rect.left + window.pageXOffset,
			top: rect.top + window.pageYOffset,
			width: rect.width,
		};
	}

	public static splitNode(node: HTMLElement): HTMLSpanElement[] {
		const chars: HTMLSpanElement[] = [];

		/**
		 * Divide los nodos de tipo de texto en spans con los estilos del padre (si lo tuviera) por cada carácter del texto
		 * Si no es un nodo de tipo texto se recorre los hijos recursivamente para aplicar la misma lógica teniendo como resultado
		 * un array de spans por carácter con los estilos del padre
		 * */
		const generateSpanCharsWithStyles = (element: HTMLElement) => {
			const parentSpan = element.parentElement?.closest('[data-unique]');
			if (element.nodeType === 3) {
				[...element.textContent!].forEach((char) => {
					const parent = wrapperElement.cloneNode() as HTMLSpanElement;
					parent.insertAdjacentHTML('afterbegin', char === ' ' ? '&nbsp;' : char);
					if (parentSpan) {
						parent.setAttribute('style', parentSpan.getAttribute('style') || '');
					}

					chars.push(parent);
				});
			}
			const childrens = Array.from(element.childNodes);

			childrens.forEach((child) => {
				generateSpanCharsWithStyles(child as HTMLElement);
			});
		};

		const wrapperElement = document.createElement('span');
		wrapperElement.style.display = 'inline-block';
		generateSpanCharsWithStyles(node);

		return chars;
	}
}

export default CurvedTextTools;
