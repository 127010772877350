import { Ref, toRef } from 'vue';

import Page from '@/Classes/Page';
import { usePage } from '@/composables/page/usePage';
import { useMainStore } from '@/stores/store';

export const useActivePage = () => {
	const store = useMainStore();
	const activePage = toRef(store, 'activePage');
	const usePageInstance = usePage(activePage as Ref<Page>);

	return usePageInstance;
};
