<script lang="ts" setup>
// Props
defineProps<{ modal?: boolean; provider: string }>();

// Constants
const PROVIDERS = ['freepik', 'pexels', 'upload'];

// Emits
const emit = defineEmits(['setProvider']);

// Methods
const onClickProvider = (prov: string) => emit('setProvider', prov);
</script>

<template>
	<div data-testid="provider-images" class="flex h-10 shrink-0 justify-between gap-1">
		<button
			v-for="tab in PROVIDERS"
			:key="`provider-${tab}`"
			class="flex h-8 flex-1 items-center justify-center rounded px-2 text-sm font-semibold capitalize"
			:class="{
				'bg-gray-700 text-gray-100': provider === tab && !modal,
				'bg-gray-100/50 text-gray-700': provider === tab && modal,
				'text-gray-200 hover:text-gray-100': provider !== tab && !modal,
				'text-gray-700 hover:bg-gray-100/25': provider !== tab && modal,
			}"
			@click="onClickProvider(tab)"
		>
			{{ tab }}
		</button>
	</div>
</template>
