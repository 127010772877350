<script setup lang="ts">
// Vue & Packages
import { computed, ref, watch } from 'vue';

// Componentes
import NumberInputTopbar from '@/components/Common/NumberInputTopbar.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useArtboard } from '@/composables/project/useArtboard';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
// Stores
import { useProjectStore } from '@/stores/project';
// Utils
import ElementTools from '@/utils/ElementTools';

const project = useProjectStore();

// Using composables
const { maxArtboardSize, MIN_ARTBOARD_SIZE, MM_TO_PX, isValidSize } = useArtboard();
const { isMobile } = useDeviceInfo();
const { isPhotoMode } = useEditorMode();
const { listen } = useOrderedKeyboardListener();

// Emits
const emit = defineEmits(['changeArtboardSizeAndUnit']);

const artboardSizeLabel = {
	max: {
		mm: `${Math.round(Math.sqrt(maxArtboardSize.value) / MM_TO_PX)} x ${Math.round(
			Math.sqrt(maxArtboardSize.value) / MM_TO_PX
		)} mm`,
		px: `${Math.round(Math.sqrt(maxArtboardSize.value))} x ${Math.round(Math.sqrt(maxArtboardSize.value))} px`,
	},
	min: {
		mm: `${Math.round(Math.sqrt(MIN_ARTBOARD_SIZE) / MM_TO_PX)} x ${Math.round(
			Math.sqrt(MIN_ARTBOARD_SIZE) / MM_TO_PX
		)} mm`,
		px: `${Math.round(Math.sqrt(MIN_ARTBOARD_SIZE))} x ${Math.round(Math.sqrt(MIN_ARTBOARD_SIZE))} px`,
	},
};

// Data
const proposedArtboard = ref({
	width: project.size.width,
	height: project.size.height,
	unit: project.unit,
});

const rawSize = ref({
	width: project.size.width,
	height: project.size.height,
});

// Computeds
const hasChanged = computed(
	() =>
		proposedArtboard.value.width !== project.size.width ||
		proposedArtboard.value.height !== project.size.height ||
		proposedArtboard.value.unit !== project.unit
);

const inputMaxValue = computed(() => {
	return Math.sqrt(maxArtboardSize.value) / (proposedArtboard.value.unit === 'px' ? 1 : MM_TO_PX);
});

const inputMinValue = computed(() => (proposedArtboard.value.unit === 'px' ? Math.sqrt(MIN_ARTBOARD_SIZE) : 1));

const isValidProposedSize = computed(
	() =>
		isValidSize(rawSize.value.width, rawSize.value.height, proposedArtboard.value.unit) &&
		isValidSize(proposedArtboard.value.width, proposedArtboard.value.height, proposedArtboard.value.unit)
);

const projectArtboard = computed(() => ({
	width: project.size.width,
	height: project.size.height,
	unit: project.unit,
}));

// Watchers
watch(
	projectArtboard,
	() => {
		proposedArtboard.value.width = project.size.width;
		proposedArtboard.value.height = project.size.height;
		proposedArtboard.value.unit = project.unit;
	},
	{ deep: true }
);

// Register events
listen('Enter', () => {
	if (!hasChanged.value || !isValidProposedSize.value) return;
	onConfirmUpdateSize();
});

// Methods
const onCheckValidSize = (key: 'width' | 'height', value: number) => {
	if (key === 'width') {
		rawSize.value.width = value;
		rawSize.value.height = proposedArtboard.value.height;
	}
	if (key === 'height') {
		rawSize.value.width = proposedArtboard.value.width;
		rawSize.value.height = value;
	}
};

const onChangeSize = (key: 'width' | 'height', value: number) => {
	if (isPhotoMode.value) {
		const size =
			key === 'width'
				? ElementTools.getSizeKeepingAspectRatioByWidth(project.size, value)
				: ElementTools.getSizeKeepingAspectRatioByHeight(project.size, value);

		const { width, height } = size;
		proposedArtboard.value.width = Math.floor(width);
		proposedArtboard.value.height = Math.floor(height);
		return;
	}

	proposedArtboard.value[key] = Math.floor(value);
};

const onConfirmUpdateSize = () => {
	emit(
		'changeArtboardSizeAndUnit',
		{
			width: proposedArtboard.value.width,
			height: proposedArtboard.value.height,
		},
		proposedArtboard.value.unit
	);
};
</script>

<template>
	<div
		class="flex shrink-0 items-center gap-2"
		data-testid="artboard-custom-size"
		:class="{
			'absolute right-0 top-4 z-10 h-12 w-80 rounded bg-gray-800/90 px-2 backdrop-blur': !isMobile && !isPhotoMode,
			'absolute -right-10 h-12 w-80 rounded bg-gray-800 px-2': isMobile && !isPhotoMode,
		}"
	>
		<!-- Width -->
		<div class="relative w-full">
			<NumberInputTopbar
				class="h-9 rounded text-sm"
				:class="{
					'bg-gray-800': isPhotoMode,
					'bg-gray-900': !isPhotoMode,
				}"
				:test-id="'input-width'"
				:max="inputMaxValue"
				:min="inputMinValue"
				:value="proposedArtboard.width"
				@update="(width: number) => onChangeSize('width', width)"
				@rawUpdate="(width: number) => onCheckValidSize('width', width)"
			/>
			<span class="absolute top-0 left-0 flex h-full w-6 items-center justify-center text-2xs text-gray-300">W</span>
		</div>

		<!-- Height -->
		<div class="relative w-full">
			<NumberInputTopbar
				class="h-9 rounded text-sm"
				:class="{
					'bg-gray-800': isPhotoMode,
					'bg-gray-900': !isPhotoMode,
				}"
				:test-id="'input-height'"
				:max="inputMaxValue"
				:min="inputMinValue"
				:value="proposedArtboard.height"
				@update="(height: number) => onChangeSize('height', height)"
				@rawUpdate="(height: number) => onCheckValidSize('height', height)"
			/>
			<span class="absolute top-0 left-0 flex h-full w-6 items-center justify-center text-2xs text-gray-300">H</span>
		</div>

		<div
			v-if="!isValidProposedSize"
			class="absolute -bottom-14 left-0 w-full rounded-sm bg-red-500 p-1 px-2 text-xs text-white"
		>
			Resolution can not be greater than <strong>{{ artboardSizeLabel.max[proposedArtboard.unit] }}</strong> or smaller
			than <strong>{{ artboardSizeLabel.min[proposedArtboard.unit] }}</strong>
		</div>

		<!-- Unit -->
		<div v-if="!isPhotoMode" class="relative shrink-0">
			<select
				v-model="proposedArtboard.unit"
				data-testid="select-unit"
				class="flex h-9 w-full cursor-pointer appearance-none items-center truncate rounded bg-gray-900 pl-4 pr-8 text-sm text-gray-300 focus:outline-none"
			>
				<option value="px">px</option>
				<option value="mm">mm</option>
			</select>

			<span
				class="pointer-events-none absolute top-0 right-0 flex h-full w-8 items-center justify-center focus:outline-none"
			>
				<SvgIcon name="arrow" class="h-3 w-3 text-gray-300" />
			</span>
		</div>

		<!-- Confirm size change -->
		<button
			data-testid="confirm-artboard-size"
			class="relative h-8 shrink-0 px-2 text-sm text-gray-100 lg:h-9"
			:class="{
				'text-green-400 hover:text-green-300': hasChanged,
				'cursor-not-allowed opacity-50 hover:text-white': !hasChanged,
			}"
			:disabled="!hasChanged || !isValidProposedSize"
			@click="onConfirmUpdateSize"
		>
			<SvgIcon v-if="isValidProposedSize" name="check" class="h-4 w-4" />
			<SvgIcon v-else name="cross" class="h-4 w-4 text-red-500" />
		</button>
	</div>
</template>
