<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { useScroll } from '@vueuse/core';
import { computed, onBeforeMount, ref, watch } from 'vue';

import { useEditorApiFetch } from '@/composables/api/useEditorApiFetch';

const emits = defineEmits(['select']);

const select = ref();
const nextBookmark = ref();
const boards = ref([]);
const selectedPinterestBoard = ref();
const loading = ref(false);
const hasMore = ref(true);

const getBoards = async () => {
	if (loading.value || !hasMore.value) return;

	let url = `pinterest/boards`;

	if (nextBookmark.value) {
		url += `?bookmark=${nextBookmark.value}`;
	}

	loading.value = true;

	const { data } = await useEditorApiFetch(url).get().json();

	nextBookmark.value = data.value.bookmark;
	boards.value = boards.value.concat(data.value.items);

	if (!selectedPinterestBoard.value) {
		selectedPinterestBoard.value = boards.value[0].id;
		emits('select', selectedPinterestBoard.value);
	}

	hasMore.value = data.value.items.length === 10;
	loading.value = false;
};

const pinterestBoardsOptions = computed(() => {
	return boards.value.map((board) => {
		return {
			label: board.name,
			value: board.id,
		};
	});
});

watch(select, () => {
	if (!select.value) return;

	const scroll = useScroll(select.value.$el.querySelector('.multiselect-dropdown'), {
		offset: {
			bottom: 30,
		},
		onStop() {
			if (scroll.arrivedState.bottom) {
				getBoards();
			}
		},
	});
});

onBeforeMount(() => getBoards());
</script>
<template>
	<Multiselect
		ref="select"
		v-model="selectedPinterestBoard"
		mode="single"
		placeholder="Pinterest board"
		:classes="{
			container: 'multiselect text-gray-700',
			containerActive: 'ring ring-blue-500 ring-opacity-30',
			tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
			groupLabelSelected: 'bg-blue-600 text-white',
			groupLabelSelectedPointed: 'bg-blue-600 text-white opacity-90',
			groupLabelSelectedDisabled: 'text-blue-100 bg-blue-600 bg-opacity-50 cursor-not-allowed',
			optionSelected: 'text-blue-500 font-semibold opacity-100 bg-gray-100/50',
			optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
			optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
		}"
		:hide-selected="false"
		:can-clear="false"
		:options="pinterestBoardsOptions"
		@select="emits('select', selectedPinterestBoard)"
	/>
</template>
