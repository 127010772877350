<script setup lang="ts">
// Vue
import { useUrlSearchParams } from '@vueuse/core';
import { computed, onBeforeMount, Ref, ref, watch } from 'vue';

// Classes
import Image from '@/Classes/Image';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Components
import PhotosPanel from '@/components/panels/photos/PhotosPanel.vue';
// Composables
import { useImage } from '@/composables/element/image/useImage';
import { usePhotoMode } from '@/composables/element/image/usePhotoMode';
// Stores
import { useHistoryStore } from '@/stores/history';
import { useMainStore } from '@/stores/store';
// Types
import { ImageApi } from '@/Types/apiClient';

const history = useHistoryStore();
const store = useMainStore();

// Data
const image = ref<Image>(Image.createDefault());

// Computeds
const isCropping = computed(() => !!store.croppingId?.length);
const selection = computed(() => store.selection);

// Using composables
const { photoModeImage } = usePhotoMode();
const usingImage = useImage(image as Ref<Image>);

// Data
const replacing = ref(false);

const disableClose = computed(() => {
	const params = useUrlSearchParams<{ mode?: string; photo?: string }>();
	return replacing.value && params.mode === 'photo' && !params.photo;
});

// Watchers
watch(selection, (newVal) => {
	if (image.value || !newVal.length) return;
	image.value = newVal[0] as Image;
});

onBeforeMount(() => {
	const params = useUrlSearchParams<{ mode?: string; photo?: string }>();
	replacing.value = params.mode === 'photo';
});

// Methods
const onCloseModal = () => {
	if (disableClose.value) return;
	replacing.value = false;
};

const replaceImage = async (img: ImageApi) => {
	// This is how we init photo mode without an image url param
	const params = useUrlSearchParams<{ mode?: string; photo?: string }>();
	if (params.mode === 'photo' && !params.photo) {
		params.photo = 'ready';
	}

	if (!photoModeImage.value) return;

	image.value = photoModeImage.value;
	await usingImage.replace(img);

	history.states = [];
	if (store.activePage!.elements.length > 1) store.activePage!.elements.splice(1);

	replacing.value = false;
};
</script>

<template>
	<button
		class="flex h-9 items-center justify-center rounded bg-blue-500 px-3 font-semibold text-white lg:pl-3 lg:pr-4"
		:class="{ 'disabled cursor-not-allowed opacity-50': isCropping }"
		:disabled="isCropping"
		@click="replacing = true"
	>
		<SvgIcon name="plus" class="h-5 w-5 fill-current lg:mr-2 lg:h-4 lg:w-4" />
		<span class="hidden lg:block">New image</span>
	</button>

	<Modal
		v-if="replacing"
		content-classes="rounded w-11/12  lg:w-auto lg:max-w-lg lg:min-w-lg relative bg-white max-h-full py-4 lg:py-5 px-4 lg:px-6 h-[90vh]"
		open
		@close="onCloseModal"
	>
		<PhotosPanel
			data-testid="replace-img-modal"
			:disable-close="disableClose"
			modal
			@closeModal="onCloseModal"
			@selection="replaceImage"
		/>
	</Modal>
</template>
