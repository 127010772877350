<script setup lang="ts">
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { computed, onBeforeMount, toRef } from 'vue';

// Components
import ElementsPanel from '@/components/panels/elements/ElementsPanel.vue';
import TreeEditorPanel from '@/components/panels/illustrator/TreeEditorPanel.vue';
import PhotoEditPanel from '@/components/panels/PhotoEditPanel.vue';
import PhotosPanel from '@/components/panels/photos/PhotosPanel.vue';
import TemplatesPanel from '@/components/panels/templates/TemplatesPanel.vue';
import TextsPanel from '@/components/panels/texts/TextsPanel.vue';
import NavigationAuth from '@/components/skeleton/NavigationAuth.vue';
import NavigationButtons from '@/components/skeleton/NavigationButtons.vue';
import PerformancePanel from '@/components/skeleton/PerformancePanel.vue';
import TemplateSuggestionsPanel from '@/components/skeleton/TemplateSuggestionsPanel.vue';
// Composables
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { Panels } from '@/Types/types';

const store = useMainStore();

// Data
const isPanelVisible = toRef(store, 'activePanel');

// Computeds
const showPanel = computed(
	() =>
		!!isPanelVisible.value &&
		(!isPhotoMode.value || !(isPhotoMode.value && isMobile.value && store.activePanel === Panels.photoEdit))
);

// Using composables
const { isMobile, isXL } = useDeviceInfo();
const { isPhotoMode, isAdminMode, isDevelopmentEnvironment, isIllustratorContext } = useEditorMode();

// Lifecycle hooks
onBeforeMount(async () => {
	// Show templates panels in wide resolutions
	if (isXL.value && !isAdminMode.value) {
		store.activePanel = isPhotoMode.value ? Panels.photoEdit : Panels.templates;
	}
});

// Methods
const getPanelType = (panel: Panels) => {
	if (panel === Panels.templates) return TemplatesPanel;
	if (panel === Panels.texts) return TextsPanel;
	if (panel === Panels.elements) return ElementsPanel;
	if (panel === Panels.photos) return PhotosPanel;
	if (panel === Panels.photoEdit) return PhotoEditPanel;
};

const setPanel = (panel: Panels) => {
	if (store.activePanel === panel) {
		store.activePanel = null;
		Bugsnag.leaveBreadcrumb(`Close ${panel} panel`);
		return;
	}

	store.activePanel = panel;
	Bugsnag.leaveBreadcrumb(`Open ${panel} panel`);
};
</script>

<template>
	<nav id="navigation" class="relative flex h-14 shrink-0 bg-gray-800 lg:z-20 lg:h-full">
		<div id="navigation-portal"></div>
		<div
			class="relative flex h-full w-full items-center justify-between bg-gray-700 px-8 sm:justify-center lg:w-20 lg:flex-col lg:justify-start lg:bg-transparent lg:px-0 lg:pt-4"
		>
			<NavigationButtons @setPanel="setPanel" />
			<NavigationAuth />
			<TemplateSuggestionsPanel v-if="isAdminMode" class="hidden lg:block" />
			<PerformancePanel v-if="isDevelopmentEnvironment" class="hidden lg:block" />
		</div>

		<div
			v-if="showPanel && store.activePanel !== Panels.treeEditor"
			:data-test-active-panel="store.activePanel"
			class="fixed bottom-14 left-0 right-0 z-40 h-2/3 rounded-tl-2xl rounded-tr-2xl border-gray-700 bg-gray-800/95 pl-4 pr-4 pt-4 backdrop-blur lg:relative lg:bottom-0 lg:z-10 lg:h-full lg:w-[320px] lg:rounded-none lg:border-l lg:bg-gray-800"
		>
			<component :is="getPanelType(store.activePanel)" />
		</div>

		<div
			v-if="isIllustratorContext && store.finishedLoading"
			v-show="showPanel && store.activePanel === Panels.treeEditor"
			class="fixed bottom-14 left-0 right-0 z-40 h-2/3 rounded-tl-2xl rounded-tr-2xl border-l border-gray-700 bg-gray-800 pl-4 pr-4 pt-4 lg:relative lg:bottom-0 lg:z-10 lg:h-full lg:w-[320px] lg:rounded-none"
		>
			<TreeEditorPanel />
		</div>
	</nav>
</template>
