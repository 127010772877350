import { useEventListener } from '@vueuse/core';
import { computed, onBeforeUnmount, Ref, ref, watch } from 'vue';

import Image from '@/Classes/Image';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { Size } from '@/Types/types';
import ImageTools from '@/utils/ImageTools';

type ImageOutput = {
	resized: string;
	original: string;
	originalSize: Size;
};

/**
 * This object will store a reference of generated images to avoid generating them again.
 */
const imageCache = new Map<string, ImageOutput>();
const maskCache = new Map<string, string>();

/**
 * This hooks manages the rendering of a image, base on the device, image on screen size and loading factor.
 * @param image
 * @param domImage
 */
export const useImageRendering = (image: Ref<Image>, domImage: Ref<HTMLImageElement>) => {
	const store = useMainStore();
	const { isMobile } = useDeviceInfo();
	const loaded = ref(false);
	const url = ref<string | null>(null);
	const resized = ref<string | null>(null);
	const maskedImage = ref<string | null>(null);
	let maskInProcess = false;

	const inRenderingContext = !!window.RENDERER;

	// The url that wants to be shown at the page.
	// Usually the url of the image, but can be the version without the background.
	// Also we use the raw image in the render process to better quality download.
	const finalTargetUrl = computed(() => {
		if (image.value.backgroundMode === 'background') {
			return maskedImage.value;
		}

		if (image.value.backgroundMode === 'foreground' || image.value.backgroundMode === 'both') {
			return image.value.urlBackgroundRemoved || image.value.url;
		}

		if (inRenderingContext) {
			return image.value.metadata?.raw || image.value.url || url.value;
		}

		return image.value.url;
	});

	// The max size of the image that can be shown at the page. If the image is bigger, it will be resized.
	const targetSize = computed(() => {
		return {
			width: isMobile.value ? 1250 : 1500,
			height: isMobile.value ? 1250 : 1500,
		};
	});

	// Starts the loading of the image
	const load = () => {
		//
		watch(
			() => image.value.backgroundMode,
			async () => {
				if (
					image.value.backgroundMode === 'original' ||
					maskedImage.value ||
					maskInProcess ||
					!image.value.urlBackgroundRemoved
				) {
					return;
				}

				maskInProcess = true;
				store.finishedLoading = false;
				maskedImage.value = await maskImageCached(image.value.url, image.value.urlBackgroundRemoved);
				store.finishedLoading = true;
				maskInProcess = false;
			},
			{ immediate: true }
		);

		// If something went wrong while loading the image, we replace it with a broken one
		// Maybe we should return a broken status instead and do not change the url.
		useEventListener(domImage, 'error', () => {
			if (!image.value) return;

			url.value = `/svg/image-not-found.svg`;
			loaded.value = true;
			resized.value = null;

			if (store.selection && store.selection[0] === image.value) {
				store.selectionId = [];
			}
		});

		// If the image reference changes, we reset the derived images
		watch(() => image.value.url, () => {
			maskedImage.value = null;
			loaded.value = false;
		});

		// If the image target changes (maybe because we want to show the background removed version), we will want to restart this process
		// In case we're showing a svg, a blob or are in rendering context, the url to be used its just that one.
		// In any other case, we'll check if the image is larger than the target size and if so, we'll resize it and use it
		watch(
			finalTargetUrl,
			async () => {
				if (!finalTargetUrl.value) {
					return;
				}

				if (
					inRenderingContext ||
					finalTargetUrl.value.startsWith('blob:') ||
					finalTargetUrl.value.includes('.svg') ||
					maskedImage.value === finalTargetUrl.value ||
					image.value.backgroundMode !== 'original'
				) {
					url.value = finalTargetUrl.value;
					loaded.value = true;
					resized.value = null;
					return;
				}

				try {
					const newUrl = await getImageToBeRendered(finalTargetUrl.value, targetSize.value);
					url.value = newUrl.original;
					resized.value = newUrl.original === newUrl.resized ? null : newUrl.resized;
				} catch (error) {
					console.warn('Image load error', error);
					url.value = finalTargetUrl.value;
				} finally {
					loaded.value = true;
				}
			},
			{ immediate: true }
		);
	};

	// Computes the real size of the image (visual area)
	const imageSize = computed(() => ({
		width: image.value.crop.size.width || image.value.size.width,
		height: image.value.crop.size.height || image.value.size.height,
	}));

	// Computes the real size of the image on screen
	const screenWidth = computed(() => {
		return Math.round(imageSize.value.width * store.scale) * window.devicePixelRatio;
	});

	// Select the image to be shown at the page based on the screen size and resized availability
	const imageToRender = computed(() => {
		if (screenWidth.value < targetSize.value.width && resized.value) {
			return resized.value;
		}

		return url.value;
	});

	// Gets the image to be rendered based on the url and the target size, and caches the result
	const getImageToBeRendered = async (url: string, targetSize: Size): Promise<ImageOutput> => {
		if (imageCache.has(url)) {
			return imageCache.get(url) as ImageOutput;
		}

		const { url: blobUrl, type } = await ImageTools.getImageAsBlobUrl(url);
		const img = await ImageTools.loadImg(blobUrl);
		const size = ImageTools.sizeInDom(img);

		if (size.width * size.height < 700 * 700) {
			const output = {
				resized: url,
				original: url,
				originalSize: size,
			};

			imageCache.set(url, output);
			return output;
		}

		const newUrl = await ImageTools.resizeDomImage(img, targetSize, type);
		URL.revokeObjectURL(blobUrl);

		const output = {
			resized: newUrl,
			original: url,
			originalSize: size,
		};

		imageCache.set(url, output);
		return output;
	};

	return {
		loaded,
		inRenderingContext,
		imageUrl: imageToRender,
		imageSize,
		load,
		imageCache,
		maskedImage,
	};
};

const maskImageCached = async (url: string, urlBackgroundRemoved: string): Promise<string> => {
	if (maskCache.has(url)) {
		return Promise.resolve(maskCache.get(url) as string);
	}

	const maskedUrl = await ImageTools.maskImage(url, urlBackgroundRemoved);

	maskCache.set(url, maskedUrl);

	return maskedUrl;
};
