<script lang="ts" setup>
import { createPopper } from '@popperjs/core';
import { computed, nextTick, ref, toRef, watch } from 'vue';

import Element from '@/Classes/Element';
import CropToolbar from '@/components/selection/toolbars/CropToolbar.vue';
import ImageToolbar from '@/components/selection/toolbars/images/ImageToolbar.vue';
import LineToolbar from '@/components/selection/toolbars/LineToolbar.vue';
import QRCodeToolbar from '@/components/selection/toolbars/QRCodeToolbar.vue';
import ShapeToolbar from '@/components/selection/toolbars/ShapeToolbar.vue';
import StorysetToolbar from '@/components/selection/toolbars/StorysetToolbar.vue';
import TextToolbar from '@/components/selection/toolbars/TextToolbar.vue';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useMainStore } from '@/stores/store';

// Stores
const store = useMainStore();

// Props
const props = defineProps<{ element: Element; visibility: boolean }>();

// Template refs
const toolbar = ref();

// Data
const element = toRef(props, 'element');
const popper = ref();

// Computeds
const croppingId = computed(() => store.croppingId);
const size = computed(() => element.value.size);
const position = computed(() => element.value.position);

// Using composables
const { isCropping } = useInteractions();

// Watchers
watch([position, size], async () => {
	await nextTick();

	if (!popper.value) {
		initPopper();
		return;
	}

	popper.value.update();
});

watch(
	[croppingId, element],
	async () => {
		await nextTick();
		initPopper();
	},
	{ immediate: true }
);

// Methods
const componentType = () => {
	if (isCropping.value) return CropToolbar;

	switch (element.value.type) {
		case 'image': {
			return ImageToolbar;
		}
		case 'shape': {
			return ShapeToolbar;
		}
		case 'storyset': {
			return StorysetToolbar;
		}
		case 'text': {
			return TextToolbar;
		}
		case 'line': {
			return LineToolbar;
		}
		case 'qrcode': {
			return QRCodeToolbar;
		}
	}
};

const initPopper = () => {
	const domNode = element.value.domNode();
	if (!domNode || !toolbar.value) return;

	popper.value = createPopper(domNode, toolbar.value, {
		placement: 'top-end',
		modifiers: [
			{ name: 'offset', options: { offset: [0, 5] } },
			{
				name: 'preventOverflow',
				options: {
					tether: false,
				},
			},
		],
	});
};
</script>

<template>
	<teleport to="#toolbarTarget">
		<div ref="toolbar" class="z-20" :class="!visibility ? 'pointer-events-none opacity-0' : ''">
			<component
				:is="componentType()"
				:element="props.element"
				class="toolbar flex h-8 items-center rounded-full bg-gray-700/90 px-2 shadow-lg backdrop-blur"
			/>
		</div>
	</teleport>
</template>
