<script setup lang="ts">
import { Ref, ref } from 'vue';

import Page from '@/Classes/Page';
import DownloadErrorModal from '@/components/Common/modals/DownloadErrorModal.vue';
import OnClickOutsideWithPortals from '@/components/Common/OnClickOutsideWithPortals';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import DownloadOptions from '@/components/export/DownloadOptions.vue';
import DownloadPickerModal from '@/components/export/DownloadPickerModal.vue';
import FacebookModal from '@/components/export/share/FacebookModal.vue';
import InstagramModal from '@/components/export/share/InstagramModal.vue';
import PinterestModal from '@/components/export/share/PinterestModal.vue';
import TwitterModal from '@/components/export/share/TwitterModal.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import useDownloadsProject from '@/composables/useDownloadsProject';
import { useEditorMode } from '@/composables/useEditorMode';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { DownloadFormat } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';

type ModalOption = 'download' | 'instagram' | 'facebook' | 'pinterest' | 'twitter';

const error = ref(false);
const open = ref<ModalOption | null>(null);
const selectedPages = ref<Page[]>([]);

const { download } = useDownloadsProject();
const { isWebview } = useDeviceInfo();
const { inSlidesgoContext } = useEditorMode();
const project = useProjectStore();
const store = useMainStore();

const openDownloadModal = async () => {
	if (store.downloading) {
		return;
	}
	const projectName = project.name?.length ? project.name : 'custom-artboard';

	GAnalytics.track('click-download', 'Template', projectName, null);

	if (isWebview.value) {
		await download(DownloadFormat.app);
		return;
	}

	open.value = 'download';
};

const onCloseDownloadErrorModal = () => (error.value = false);

const onDownloadError = () => (error.value = true);

const share = ($event: { socialNetwork: ModalOption; pages: Page[] }) => {
	open.value = $event.socialNetwork;
	selectedPages.value = $event.pages;
};
</script>

<template>
	<OnClickOutsideWithPortals ignore-selector=".ignore-click" @trigger="open = null">
		<template v-if="inSlidesgoContext">
			<button
				data-onboarding="btn-download"
				class="text-white-100 relative flex h-10 w-10 items-center justify-center rounded bg-gray-600 text-gray-100 hover:bg-gray-500 hover:text-white focus:text-white"
				@click="openDownloadModal"
			>
				<SvgIcon name="download" class="h-4 w-4" />
			</button>
		</template>
		<template v-else>
			<button
				data-onboarding="btn-download"
				class="btn-download relative flex h-8 w-8 items-center justify-center rounded bg-green-500 font-bold text-white hover:bg-green-600 lg:h-10 lg:w-auto lg:py-3 lg:px-6"
				:class="store.downloading ? 'cursor-wait' : ''"
				@click="openDownloadModal"
			>
				<SvgIcon name="download" class="h-4 w-4 lg:mr-2" />
				<span class="hidden lg:block">Download</span>
			</button>
		</template>

		<DownloadPickerModal
			v-if="open === 'download' && project.pages.length > 1"
			class="ignore-click"
			@close="open = null"
			@error="onDownloadError"
			@share="share"
		/>
		<InstagramModal
			v-if="open === 'instagram' && selectedPages.length"
			class="ignore-click"
			:pages="selectedPages"
			@close="open = null"
			@go-back="open = 'download'"
		/>
		<FacebookModal
			v-if="open === 'facebook' && selectedPages.length"
			class="ignore-click"
			:pages="selectedPages"
			@close="open = null"
			@go-back="open = 'download'"
		/>
		<PinterestModal
			v-if="open === 'pinterest' && selectedPages.length"
			class="ignore-click"
			:pages="selectedPages"
			@close="open = null"
			@go-back="open = 'download'"
		/>
		<TwitterModal
			v-if="open === 'twitter' && selectedPages.length"
			class="ignore-click"
			:pages="selectedPages"
			@close="open = null"
			@go-back="open = 'download'"
		/>
		<DownloadErrorModal v-if="error" @close="onCloseDownloadErrorModal" />
		<DownloadOptions
			v-if="open === 'download' && project.pages.length === 1"
			:selection="[]"
			@close="open = null"
			@error="onDownloadError"
			@share="share"
		/>
	</OnClickOutsideWithPortals>
</template>
