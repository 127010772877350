<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { computed, ref, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import GAnalytics from '@/utils/GAnalytics';
import TextTools from '@/utils/TextTools';

const props = defineProps<{ element: Text }>();

const textElement = toRef(props, 'element');

const emit = defineEmits<{ (e: 'close'): void }>();

const { link, updateLink } = useTextStyles(textElement);

const textLink = ref(link.value);

// Methods
const sumbitLink = () => {
	updateLink(textLink.value);
	Bugsnag.leaveBreadcrumb(`Add text link to text-${textElement.value.id}: ${textLink.value}`);

	GAnalytics.track('click', 'Button', `insert-link`, null);

	emit('close');
};

const deleteLink = () => {
	updateLink('unlink');
	Bugsnag.leaveBreadcrumb(`Remove text link to text-${textElement.value.id}`);

	GAnalytics.track('click', 'Button', `remove-link`, null);

	emit('close');
};

const isValidLink = computed(() => {
	return textLink.value.trim().length > 0 && TextTools.isValidUrl(textLink.value.trim());
});
</script>

<template>
	<div
		class="link-menu absolute right-0 top-2 flex w-full items-center rounded bg-gray-700 p-2 lg:top-auto lg:right-0 lg:-mt-20 lg:-ml-6 lg:w-72 lg:bg-white lg:shadow-lg"
	>
		<input
			v-model="textLink"
			data-text-input
			class="placehoder-gray-200 lg:placehoder-gray-500 h-8 flex-1 rounded bg-gray-100/25 px-2 text-sm text-white focus:outline-none lg:h-7 lg:text-gray-800"
			type="text"
			placeholder="Type a URL (example: https://wepik.com)"
		/>
		<button
			class="ml-2 flex h-8 w-8 items-center justify-center rounded bg-green-500 lg:h-7 lg:w-7"
			:class="{
				'cursor-not-allowed opacity-50': !isValidLink,
				'hover:bg-green-600': isValidLink,
			}"
			data-testid="submitLink"
			:disabled="!isValidLink"
			@click="sumbitLink"
		>
			<SvgIcon name="check" class="h-4 w-4 scale-90 fill-current text-white group-hover:text-white" />
		</button>
		<button
			v-if="textLink.length > 0"
			data-testid="deleteLink"
			class="absolute top-0 right-0 mr-14 mt-3.5 flex h-5 w-5 items-center justify-center rounded-sm bg-gray-700 lg:mt-3 lg:mr-12 lg:bg-white"
			@click="deleteLink"
		>
			<SvgIcon
				name="trash"
				class="h-3 w-3 scale-90 fill-current text-gray-100 hover:text-gray-300 group-hover:text-white lg:text-gray-600"
			/>
		</button>
	</div>
</template>
