<script lang="ts" setup>
// Vue
import { toRef } from 'vue';

// Classes
import Image from '@/Classes/Image';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useImageBackground } from '@/composables/element/image/useImageBackground';
// Composables
import { useEditorMode } from '@/composables/useEditorMode';
import { useToast } from '@/composables/useToast';
import EventTools from '@/utils/EventTools';

// Props
const props = defineProps<{ element: Image }>();

// Data
const element = toRef(props, 'element');
const toast = useToast();
// Using composables
const { isPhotoMode } = useEditorMode();
const { canBeSetAsBackground, setImageAsBackground } = useImageBackground(element);

const setAsBackground = () => {
	try {
		setImageAsBackground();
	} catch (e) {
		const message = EventTools.getErrorMessage(e);
		toast.error(message);
	}
};
</script>

<template>
	<div class="flex flex-col">
		<button
			data-testid="to-background"
			class="flex h-8 w-full items-center justify-center rounded bg-gray-700 px-3 text-gray-100"
			:class="{
				'disabled cursor-not-allowed opacity-50': !canBeSetAsBackground || isPhotoMode,
				'hover:bg-gray-600 hover:text-white': canBeSetAsBackground && !isPhotoMode,
			}"
			:disabled="!canBeSetAsBackground || isPhotoMode"
			@click="setAsBackground"
		>
			<SvgIcon name="background" class="h-4 w-4" />
		</button>
		<label class="mt-1 text-center text-2xs leading-snug text-gray-100">Image to background</label>
	</div>
</template>
