<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { useDebounceFn, useElementBounding, useEventListener, useMutationObserver, useTitle } from '@vueuse/core';
import { sortBy } from 'lodash-es';
import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';

import BottomBar from '@/BottomBar.vue';
import Image from '@/Classes/Image';
import Page from '@/Classes/Page';
import ConfirmNavigationModal from '@/components/Common/ConfirmNavigationModal.vue';
import ConfirmNavigationPhotoModeModal from '@/components/Common/ConfirmNavigationPhotoModeModal.vue';
import LoginModal from '@/components/Common/modals/LoginModal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import InteractiveCanvas from '@/components/render/InteractiveCanvas.vue';
import ContextMenu from '@/components/selection/ContextMenu.vue';
import CropImageButton from '@/components/selection/panels/CropImageButton.vue';
import EditPanel from '@/components/selection/panels/EditPanel.vue';
import FlipMenu from '@/components/selection/panels/FlipMenu.vue';
import RemoveBgButton from '@/components/selection/panels/RemoveBgButton.vue';
import ReplaceImageButton from '@/components/selection/panels/ReplaceImageButton.vue';
import RotateMenu from '@/components/selection/panels/RotateMenu.vue';
import ImageFilter from '@/components/selection/toolbars/images/ImageFilter.vue';
import CanvasNavigation from '@/components/skeleton/CanvasNavigation.vue';
import DownloadingModal from '@/components/skeleton/DownloadingModal.vue';
import Navigation from '@/components/skeleton/Navigation.vue';
import OnBoarding from '@/components/skeleton/OnBoarding.vue';
import ProjectColorPicker from '@/components/skeleton/ProjectColorPicker.vue';
import TopBar from '@/components/skeleton/TopBar.vue';
import UserInterests from '@/components/skeleton/UserInterests.vue';
import { usePhotoMode } from '@/composables/element/image/usePhotoMode';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useArtboard } from '@/composables/project/useArtboard';
import { useProject } from '@/composables/project/useProject';
import { useAuth } from '@/composables/useAuth';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorKeyboardEvents } from '@/composables/useEditorKeyboardEvents';
import { useEditorMode } from '@/composables/useEditorMode';
import { useEditorMouseEvents } from '@/composables/useEditorMouseEvents';
import { useFonts } from '@/composables/useFonts';
import useNavigation from '@/composables/useNavigation';
import { useTemplateLoader } from '@/composables/useTemplateLoader';
import { useZoom } from '@/composables/useZoom';
import { useHistoryStore } from '@/stores/history';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';

const store = useMainStore();
const project = useProjectStore();
const { addEmptyPage, totalPages } = useProject();
const { isFirstLoad } = useTemplateLoader();
const scrollArea = ref<HTMLElement | null>();
const { watchFonts, preload } = useFonts();
const { width, height } = useElementBounding(scrollArea);
const { isMobile, isTouch, isWebview } = useDeviceInfo();
const { fitZoomScale, initZoomMobile, scaleAsPercentage, isPinching, onZoomFinish } = useZoom();
const { isCropping, moveable } = useInteractions();

const { authRequired, checkLoginStatus } = useAuth();
const { showUnsavedChangesModal, guardAgainstUnsavedChanges } = useNavigation();
const { detect: detectEditorMode, isEditorMode, isPhotoMode, isIllustratorContext, mode } = useEditorMode();

detectEditorMode();

guardAgainstUnsavedChanges();

const refCanvasToExtractSize = ref<HTMLElement | null>();
const boundingSize = ref();

watch(scaleAsPercentage, async () => {
	await nextTick();
	portalTarget.value.style.display = 'none';
	if (isCropping.value) {
		portalTargetCrop.value.style.display = 'none';
	}

	refCanvasToExtractSize.value = scrollArea.value?.querySelector('.interactive-canvas [id^="canvas-"]');
	if (!refCanvasToExtractSize.value) return;
	const maxSize = { width: window.innerWidth - 40, height: (window.innerWidth - 40) / aspectRatio.value };
	const { width, height } = refCanvasToExtractSize.value.getBoundingClientRect();
	boundingSize.value = {
		width: `${Math.min(width, maxSize.width)}px`,
		height: `${Math.min(height, maxSize.height)}px`,
	};
});

const resizeContainer = () => {
	if (!scrollArea.value) return;

	scrollArea.value.style.width = '';
	scrollArea.value.style.height = '';

	const { width } = scrollArea.value.getBoundingClientRect();
	let offset = scrollArea.value?.offsetTop || 0;
	const height = window.innerHeight;

	if (isMobile.value) {
		offset += document.querySelector('#navigation')?.getBoundingClientRect().height || 0;
		scrollArea.value.classList.remove('flex-1');
	}

	scrollArea.value.style.width = `${width}px`;
	scrollArea.value.style.height = `${height - offset}px`;

	if (!isPinching.value && !store.textEditing) {
		fitZoomScale();
	}
};

useEventListener(window, 'resize', useDebounceFn(resizeContainer, 100), { passive: true });
const ready = ref(false);

onMounted(async () => {
	resizeContainer();
	setupInteraction();

	if (isTouch.value) {
		initZoomMobile();
	}

	ready.value = true;
});

useEditorKeyboardEvents();
useEditorMouseEvents();
const { setupInteraction } = useInteractions();
const { aspectRatio } = useArtboard();

Bugsnag.leaveBreadcrumb(
	`Mode ${mode.value} ${isMobile.value ? 'responsive; ' : ''} ${isTouch.value ? 'is touch; ' : ''} ${
		isWebview.value ? 'is webView.' : ''
	}`
);

checkLoginStatus();
watchFonts();
preload();
useTitle(computed(() => `${project.name || project.category || 'Editor'} | Wepik`));

const history = useHistoryStore();

// Al cargar el UserVector comprobamos si la plantilla requiere slidesgo context
const userVector = toRef(store, 'userVector');
watch(userVector, () => detectEditorMode());
const usingPhotoMode = usePhotoMode();

// Photo mode UI conditions
const photoModeImage = computed(() => {
	if (!isPhotoMode.value) return undefined;
	return usingPhotoMode.photoModeImage.value;
});
const isMobilePhotoModeFiltersVisible = computed(
	() => isMobile.value && isPhotoModeMainActionsVisible.value && photoModeImage.value?.id === store.selection[0]?.id
);
const isPhotoModeMainActionsVisible = computed(
	() => isPhotoMode.value && !!project.pages.length && !!project.pages[0].elements.length && !!photoModeImage.value
);
const shouldHideCanvasUntilSelectImage = computed(() => photoModeImage.value?.url.includes('placeholder'));

const canvases = ref();
const determineActivePage = useDebounceFn(() => {
	if (store.selectionId.length > 0 || canvases.value.length < 2) return;

	const currentScroll = scrollArea.value.scrollTop - scrollArea.value?.clientHeight / 2;
	const orderedCanvas = sortBy(canvases.value, (canvas) => canvas.$el.offsetTop);

	for (let canvas of orderedCanvas) {
		if (currentScroll < canvas.$el.offsetTop) {
			store.setActivePage(canvas.$props.page);
			break;
		}
	}
}, 300);

useEventListener(scrollArea, 'scroll', determineActivePage, { passive: true });

const portalTarget = ref();
const portalTargetCrop = ref();

// Moveable en grupos hace un flasheo al montarse, para evitarlo solo le ponemos el display block cuando ya tiene
// transform(está desplazado a la posición del elemento)
useMutationObserver(
	portalTarget,
	() => {
		if (store.selectionId.length <= 1) {
			return;
		}

		const transform = portalTarget.value.style.transform;
		if (!transform || transform === 'translate3d(0px, 0px, 0px)') {
			portalTarget.value.style.display = 'none';
		} else {
			portalTarget.value.style.display = 'block';
		}
	},
	{ attributes: true, attributeFilter: ['style'] }
);

onZoomFinish(() => {
	moveable.value?.updateTarget();
	portalTarget.value.style.display = 'block';
	if (isCropping.value) {
		portalTargetCrop.value.style.display = 'block';
	}
});
</script>

<template>
	<div class="flex flex-col lg:h-screen">
		<TopBar />
		<OnBoarding />
		<div class="flex w-full flex-col-reverse lg:flex-1 lg:flex-row">
			<Navigation class="text-white" />

			<div
				id="scroll-area"
				ref="scrollArea"
				class="relative flex flex-col bg-gray-900 pt-4 pb-16 text-gray-900 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 lg:z-10 lg:flex-1"
			>
				<div v-show="!isPinching" id="portalTarget" ref="portalTarget" class="absolute"></div>
				<div id="portalTargetCrop" ref="portalTargetCrop" class="absolute"></div>
				<div id="portalTargetSupport" class="absolute"></div>
				<div id="portalTargetHandlerLine1" class="absolute"></div>
				<div id="portalTargetHandlerLine2" class="absolute"></div>
				<div id="toolbarTarget" class="absolute"></div>
				<div id="groupToolbarTarget" class="absolute"></div>

				<ContextMenu v-if="!isMobile && !isIllustratorContext" />

				<InteractiveCanvas
					v-for="page in project.pages"
					v-show="ready && !isFirstLoad && !shouldHideCanvasUntilSelectImage"
					ref="canvases"
					:key="page.id + history.key"
					:page="(page as Page)"
					:scroll-area="scrollArea"
				/>

				<div
					v-if="isEditorMode && isMobile && totalPages > 1 && ready && boundingSize"
					class="sticky left-0 mt-4 flex w-full justify-center"
					:style="{ width: `${parseInt(scrollArea?.style.width) - 12}px` }"
				>
					<button
						:style="{ width: boundingSize.width, height: boundingSize.height }"
						class="mx-auto flex shrink-0 flex-col items-center justify-center rounded border-2 border-dashed border-gray-700"
						@click="addEmptyPage()"
					>
						<SvgIcon name="plus" class="mr-2 h-12 w-12 text-gray-600" />
						<span class="text-sm text-gray-600 lg:text-base">Add new page</span>
					</button>
				</div>

				<template v-if="isMobilePhotoModeFiltersVisible">
					<div class="fixed bottom-14 left-0 w-full bg-gray-800 pt-4 pb-2">
						<ImageFilter :image="(photoModeImage as Image)" />
					</div>
				</template>

				<BottomBar v-if="scrollArea" :width="width" :scroll-area="scrollArea" />
			</div>

			<div
				v-if="isMobile"
				class="topbar-color-mobile flex h-12 items-center overflow-auto border-t border-gray-600 bg-gray-700 px-2"
			>
				<ProjectColorPicker />

				<template v-if="isPhotoModeMainActionsVisible">
					<div class="flex items-center gap-1">
						<RemoveBgButton :element="(photoModeImage as Image)" />
						<CropImageButton :element="(photoModeImage as Image)" />
						<ReplaceImageButton :element="(photoModeImage as Image)" />
						<FlipMenu :element="(photoModeImage as Image)" />
						<RotateMenu :element="(photoModeImage as Image)" />
					</div>
				</template>
			</div>

			<EditPanel v-if="store.activePage"></EditPanel>

			<CanvasNavigation v-if="!isMobile && !isIllustratorContext" :editor-height="height" />
			<DownloadingModal v-if="store.downloading" />
			<LoginModal v-if="authRequired" @close="authRequired = false" />
			<component
				:is="isPhotoMode ? ConfirmNavigationPhotoModeModal : ConfirmNavigationModal"
				v-if="showUnsavedChangesModal"
				@close="showUnsavedChangesModal = false"
			/>
			<UserInterests />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.scrollbar-thin {
	overflow-y: scroll;
	overflow-x: scroll;
}
</style>
