<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ref } from 'vue';

import Page from '@/Classes/Page';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useProject } from '@/composables/project/useProject';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';

const store = useMainStore();
const project = useProjectStore();
const props = defineProps<{ editorHeight: number }>();

const setActivePage = (page: Page) => {
	if (store.activePageId === page.id) return;

	store.setActivePage(page);
	page.domNode()?.scrollIntoView({ behavior: 'smooth' });
};

const { removePage, canMoveDown, canMoveUp, movePage, findPosition, copyPage, addEmptyPage } = useProject();

const move = (page: Page, direction: 'up' | 'down') => {
	const newIndex = findPosition(page) + (direction === 'down' ? 1 : -1);

	movePage(page, newIndex);
};

const scrollArea = ref();
</script>

<template>
	<div
		v-if="project.pages.length > 1"
		ref="scrollArea"
		class="z-0 w-48 overflow-x-hidden text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
		:style="{ height: `${props.editorHeight}px` }"
	>
		<div data-testid="preview-canvas" class="flex flex-col pt-1 pb-4 pl-4 pr-2">
			<div class="sticky top-0 z-20 -mx-1 flex items-center justify-between bg-gray-800 py-2 px-1">
				<button class="flex items-center text-xs font-bold text-gray-100 hover:text-white" @click="addEmptyPage(page)">
					<SvgIcon name="plus" class="mr-1 h-4 w-4" /> New page
				</button>
				<div class="relative scale-90 font-bold text-gray-100 opacity-90">
					<SvgIcon name="pages" class="h-6 w-6" />
					<span
						class="absolute bottom-[3px] left-0 right-1 flex items-center justify-center rounded text-[9px] text-gray-100"
					>
						{{ project.pages.length }}
					</span>
				</div>
			</div>
			<div class="flex flex-col gap-4 pt-1">
				<div
					v-for="(page, index) in (project.pages as Page[])"
					:key="`page-${page.id}`"
					class="group relative flex h-32 cursor-pointer items-center overflow-hidden rounded bg-gray-600"
					:class="{
						'ring-2 ring-blue-500': page.id === store.activePage?.id,
					}"
				>
					<span
						class="absolute top-1 left-1 z-10 flex h-4 w-4 items-center justify-center rounded bg-gray-800 text-2xs font-bold"
						:class="page.id === store.activePage?.id ? 'text-white' : 'text-gray-100'"
					>
						{{ index + 1 }}
					</span>
					<Menu v-if="store.finishedLoading" v-slot="{ open }">
						<MenuButton
							class="absolute top-0 right-0 z-10 m-1 rounded-sm p-1 text-white opacity-0 group-hover:opacity-100"
							:class="open ? 'bg-blue-400' : 'bg-blue-500'"
							><SvgIcon name="more" class="h-3 w-3"
						/></MenuButton>
						<MenuItems
							class="absolute right-0 top-7 z-20 w-24 origin-top-right rounded-md bg-white py-1 opacity-0 shadow focus:outline-none group-hover:opacity-100"
						>
							<MenuItem v-if="canMoveDown(page)">
								<button
									class="block w-full px-4 py-1 text-xs text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
									@click="move(page, 'down')"
								>
									Move down
								</button>
							</MenuItem>
							<MenuItem v-if="canMoveUp(page)">
								<button
									class="block w-full px-4 py-1 text-xs text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
									@click="move(page as Page, 'up')"
								>
									Move up
								</button>
							</MenuItem>
							<MenuItem>
								<button
									class="block w-full px-4 py-1 text-xs text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
									@click="copyPage(page)"
								>
									Copy
								</button>
							</MenuItem>
							<MenuItem>
								<button
									class="block w-full px-4 py-1 text-xs text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
									@click="removePage(page)"
								>
									Delete
								</button>
							</MenuItem>
						</MenuItems>
					</Menu>

					<Canvas
						:key="`thumb${page.id}`"
						:page="page"
						class="z-0 h-full w-full"
						:preview="true"
						use-render-queue
						fit-to="height"
						lazy-render
						:index="index"
						:scroll-area="scrollArea"
						:preview-scale="2"
						@click="setActivePage(page)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
