<script lang="ts" setup>
// Vue
import { computed } from 'vue';

// Components
import Dropdown from '@/components/Common/Dropdown.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';

// Const
const FONT_SIZE_OPTIONS = [
	{ label: 12, value: 12 },
	{ label: 14, value: 14 },
	{ label: 16, value: 16 },
	{ label: 18, value: 18 },
	{ label: 24, value: 24 },
	{ label: 30, value: 30 },
	{ label: 48, value: 48 },
	{ label: 56, value: 56 },
	{ label: 64, value: 64 },
	{ label: 96, value: 96 },
];

// Props
const props = defineProps<{
	selected: number[];
}>();

// Data
const pressing: { interval: undefined | number; timeout: undefined | number } = {
	interval: undefined,
	timeout: undefined,
};

// Computeds
const selectedText = computed(() => {
	const currentFontSize = getCurrentFontSize();

	if (currentFontSize) {
		return currentFontSize;
	}

	return props.selected.length > 1 ? 'MIX' : props.selected[0];
});

// Emits
const emit = defineEmits(['update']);

// Methods
const onUpdate = (val: number) => {
	emit('update', val);
};

const onUpdateByStep = (dir: number) => {
	emit('update', dir > 0 ? 'plus' : 'minus');
};

const onStart = (dir: number) => {
	pressing.timeout = setTimeout(() => {
		pressing.interval = setInterval(() => {
			emit('update', dir > 0 ? 'plus' : 'minus');
		}, 100);
	}, 200);
};

const onStop = () => {
	if (pressing.interval) clearInterval(pressing.interval);
	if (pressing.timeout) clearTimeout(pressing.timeout);
};

/**
 * Obtiene el fontSize del elemento sobre el que se ha dejado el cursor
 * @returns Devuelve el número del fontSize o false en caso de no encontrarlo
 */
const getCurrentFontSize = (): number | false => {
	const sel = window.getSelection();

	if (sel?.type.toLowerCase() === 'caret' && sel.anchorNode && props.selected.length > 1) {
		let result;

		if (sel.anchorNode.nodeType === 1) {
			result = props.selected.find((fs) => fs === parseInt(getComputedStyle(sel.anchorNode as Element).fontSize));
		} else {
			result = props.selected.find((fs) => {
				if (sel.anchorNode && sel.anchorNode.parentElement) {
					return fs === parseInt(getComputedStyle(sel.anchorNode?.parentElement).fontSize);
				}
			});
		}

		return result ? result : false;
	}

	return false;
};
</script>

<template>
	<div class="align-center flex h-full justify-center rounded border border-gray-600 lg:border-0">
		<!-- Font size dropdown -->
		<button
			data-testid="decrease-font-size"
			class="flex h-8 w-8 items-center justify-center border-r border-gray-600 text-gray-100 hover:text-white lg:w-6 lg:border-0"
			@click="onUpdateByStep(-1)"
			@mousedown="onStart(-1)"
			@mouseup="onStop"
			@touchstart="onStart(-1)"
			@touchend="onStop"
		>
			<SvgIcon name="minus" class="h-4 w-4" />
		</button>

		<Dropdown
			class="flex w-12 items-center justify-center lg:w-auto"
			:selected="selectedText"
			:options="FONT_SIZE_OPTIONS"
			@update="onUpdate"
		>
			<template #title>
				<span class="flex p-2 text-center font-bold">Select a font size</span>
			</template>
			<template #default="{ onToggleVisibility, value }">
				<button
					data-testid="list-font-size"
					class="relative flex h-full w-6 cursor-pointer items-center justify-center font-bold text-white focus:outline-none"
					@click.stop="onToggleVisibility"
				>
					{{ value }}
				</button>
			</template>
		</Dropdown>

		<button
			data-testid="increase-font-size"
			class="flex h-8 w-8 items-center justify-center border-l border-gray-600 text-gray-100 hover:text-white lg:w-6 lg:border-0"
			@click="onUpdateByStep(1)"
			@mousedown="onStart(1)"
			@mouseup="onStop"
			@touchstart="onStart(1)"
			@touchend="onStop"
		>
			<SvgIcon name="plus" class="h-4 w-4" />
		</button>
	</div>
</template>
