import Bugsnag from '@bugsnag/browser';
import { until, useIntervalFn } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';
import { computed, ref } from 'vue';

import { getCsrfCookie, getUserData, logout as apiLogout } from '@/api/UserApiClient';
import { useMainStore } from '@/stores/store';
import { User } from '@/Types/types';

const authRequired = ref(false);
const { get } = useCookies();

export const useAuth = () => {
	const store = useMainStore();

	const isLogged = computed<boolean>(() => !!store.user);
	const user = computed<User | null>(() => store.user);

	const checkLoginStatus = async () => {
		await getCsrfCookie();

		window.xsrf = get('XSRF-TOKEN');

		try {
			const { data } = await getUserData();

			if (!data.value) throw new Error('User data is empty');

			store.user = data.value.userData;
		} catch (error) {
			store.user = null;
			delete window.localStorage.logged;
		}
	};

	const logout = async () => {
		await apiLogout();
		delete window.localStorage.logged;

		await checkLoginStatus();
		Bugsnag.leaveBreadcrumb(`Logout`);
		if (store.userVector) {
			window.location.href = import.meta.env.VITE_APP_BASE;
		}
	};

	const requireAuth = () => {
		if (user.value) return false;

		authRequired.value = true;

		const interval = useIntervalFn(() => {
			if (!window.localStorage.logged) return;
			interval.pause();
			checkLoginStatus();
			until(isLogged)
				.toBe(true)
				.then(() => {
					authRequired.value = false;
				});
		}, 1000);

		return true;
	};

	return { isLogged, user, checkLoginStatus, logout, requireAuth, authRequired };
};
