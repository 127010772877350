import { Element as SvgElement } from '@svgdotjs/svg.js';
import { cloneDeep, groupBy, max, mean, min, minBy } from 'lodash-es';
import { CSSProperties } from 'vue';

import { SolidColor } from '@/Classes/SolidColor';
import { Text } from '@/Classes/Text';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useFonts } from '@/composables/useFonts';
import { FontStyle, FontWeight, TextAlign } from '@/Types/text';
import { TextShadowProperties } from '@/Types/types';

import MathTools from './MathTools';

class TextTools {
	static createNodeFromString(source: string): HTMLElement {
		const div = document.createElement('DIV');

		div.innerHTML = source;

		if (!div.firstElementChild) {
			return div;
		}

		return <HTMLElement>div.firstElementChild;
	}

	static nextNode(node: Node | ParentNode | ChildNode | null) {
		if (node && node.hasChildNodes()) {
			return node.firstChild;
		}

		while (node && !node.nextSibling) {
			node = node.parentNode;
		}

		if (!node) {
			return null;
		}

		return node.nextSibling;
	}

	static getRangeSelectedNodes(range: Range): Node[] {
		let node: Node | ParentNode | ChildNode | null = range.startContainer;
		const endNode = range.endContainer;

		// Special case for a range that is contained within a single node
		if (node === endNode) {
			return [node];
		}

		// Iterate nodes until we hit the end container
		const rangeNodes = [];

		while (node && node !== endNode) {
			const nextNode = this.nextNode(node);

			if (nextNode) {
				rangeNodes.push((node = nextNode));
			}
		}

		// Add partially selected nodes at the start of the range
		node = range.startContainer;

		const { isFirefox } = useDeviceInfo();
		// si es firefox reporta mal el de comienzo
		if (isFirefox) {
			const nextSibling = window.getSelection()?.getRangeAt(0).startContainer.nextSibling;

			if (nextSibling) {
				node = nextSibling;
			}
		}

		while (node && node !== range.commonAncestorContainer) {
			rangeNodes.unshift(node);

			// si el nodo padre ya tiene todo el contenido del nodo actual
			// no hace falta seguir buscando padres
			if (node.parentElement?.textContent === node.textContent) {
				break;
			}

			node = node.parentNode;
		}

		return rangeNodes;
	}

	static getSelectedNodes(): Node[] {
		const sel = window.getSelection();

		if (!sel) {
			return [];
		}

		if (!sel.isCollapsed) {
			return this.getRangeSelectedNodes(sel.getRangeAt(0));
		}

		if (sel.type.toLowerCase() === 'caret' && sel?.anchorNode) {
			return [sel?.anchorNode];
		}

		return [];
	}

	/**
	 * Dado un Nodo root retorna todos los nodos Text hijos
	 * @param root Nodo raíz de texto
	 * @returns Array de nodos Text que se encuentren dentro del root
	 */
	static getNodesFromRootNode(root: HTMLElement): Node[] {
		const getChildren = (node: HTMLElement | Node): ChildNode[] => {
			let children = Array.from(node.childNodes);

			if (children.every((el) => el.nodeType === 3)) {
				return children;
			}

			children.forEach((el) => {
				if (el.nodeType !== 3) {
					children = [...children, ...getChildren(el)];
				}
			});

			return children.filter((el) => el.nodeType === 3);
		};

		return getChildren(root);
	}

	static haveOutlinedText(textStyles: Partial<CSSProperties>) {
		// @ts-ignore
		const textStroke = textStyles.webkitTextStroke;

		if (textStroke) {
			return typeof textStroke === 'string' ? parseFloat(textStroke) > 0 : textStroke > 0;
		}

		return false;
	}

	static getOutlinedTextStyles(textStyles: Partial<CSSProperties>) {
		const outlinedTextStyles = cloneDeep(textStyles);
		// @ts-ignore
		outlinedTextStyles.webkitTextStroke = '0px';
		// Borramos el text shadow del outline, ya que se está aplicando en el texto
		if (outlinedTextStyles.textShadow) delete outlinedTextStyles.textShadow;
		return outlinedTextStyles;
	}

	static styleTextToObj(styleText: string) {
		const styleObj = styleText
			.split(';')
			.map((style) => {
				const styleData = style.split(':');

				if (styleData.length < 2) return null;

				return {
					name: styleData[0].trim(),
					value: styleData[1].trim(),
				};
			})
			.filter((style) => style);

		return styleObj;
	}

	/**
	 * Esta función buscará el peso más cercano de una fuente existente
	 * @param fontFamily Recibe el nombre de una fuente
	 * @param fontWeight Recibe un peso para buscar el siguiente o el más cercano a él
	 * @returns
	 */
	static getNearestWeight(fontFamily: string, fontWeight: number) {
		const { fonts } = useFonts();

		const weights = fonts.value[fontFamily].weights;

		let result = weights.find((w) => w === `${fontWeight}`);

		if (!result) {
			let flag = false;

			weights.forEach((w) => {
				if (!w.includes('i') && parseInt(w) > fontWeight && !flag) {
					result = w;
					flag = !flag;
				}
			});
		}

		if (!result) {
			result = Math.max(...weights.filter((w) => !w.includes('i')).map((w) => parseInt(w))).toString();
		}

		return parseInt(result) as FontWeight;
	}

	static getFontByName(fontFamily: string) {
		const { fonts } = useFonts();

		const fontName = Object.keys(fonts.value).find((font) => font.includes(fontFamily));

		return fontName || 'Montserrat';
	}

	static extractFontDataFromText(element: SvgElement) {
		const fontFamilySplit = element.css('font-family').toString().split(', ');
		const fontFamily = this.getFontByName(
			fontFamilySplit[fontFamilySplit.length - 1]?.replaceAll('"', '') || 'Montserrat'
		);
		const fontWeight = this.getNearestWeight(
			fontFamily,
			parseFloat(element.css('font-weight').toString()) || (400 as FontWeight)
		);
		const fontStyle = (element.css('font-style').toString() || 'normal') as FontStyle;
		const fontSize = parseFloat(element.css('font-size').toString()) || 16;
		const letterSpacing = parseFloat(element.css('letter-spacing').toString()) || 0;
		const outline = {
			color: SolidColor.fromString(element.css('stroke').toString()) || SolidColor.black(),
			width: parseFloat(element.css('stroke-width').toString()) || 0,
		};
		const color = element.css('fill').toString().length
			? SolidColor.fromString(element.css('fill').toString())
			: SolidColor.black();

		return { fontFamily, fontWeight, fontStyle, fontSize, letterSpacing, outline, color };
	}

	static extractTextData(textNode: SvgElement, fontSize: number) {
		// Con esto sacamos lo que ocupa realmente el texto
		const { y, width, height } = (textNode.node as SVGTextContentElement).getExtentOfChar(0);

		const tspansInText = Array.from(textNode.node.querySelectorAll('tspan'));

		// Agrupamos los tspan por línea según su posición en 'y' dentro del nodo text
		const lines = groupBy(
			tspansInText.filter((tspan) => !tspan.children.length),
			(tspan) => `line_${tspan.getAttribute('y') || 0}`
		);

		if (textNode.node.firstChild && textNode.node.firstChild.nodeType === Node.TEXT_NODE) {
			lines.line_0 = lines.line_0 || [];
			lines.line_0 = [textNode.node.firstChild as SVGTSpanElement, ...lines.line_0];
		}

		const offsetY = Math.abs(fontSize - height) / 2;

		// Calculamos el lineHeight en base a cuantas veces se repite esa separación entre líneas
		const positionsY = Object.keys(lines).map((key) => parseInt(key.split('_')[1]));

		const posDictionary: { [key: number]: number } = [];
		positionsY
			.map((posY, i) => (i < Object.keys(lines).length - 1 ? positionsY[i + 1] - posY : -1))
			.forEach((posY) => {
				if (posY >= 0) {
					if (posDictionary[posY]) {
						posDictionary[posY] += 1;
					} else {
						posDictionary[posY] = 1;
					}
				}
			});

		const moreOften = Math.max(...Object.values(posDictionary));

		let lineHeight = Array.from(Object.keys(posDictionary)).find((key) => {
			const keyParsed = parseFloat(key);

			if (posDictionary[keyParsed] === moreOften) {
				return keyParsed;
			}

			return null;
		});

		if (!lineHeight) {
			lineHeight = (Object.keys(lines).length > 1 ? Math.ceil(fontSize * 1.2) : fontSize).toString();
		}

		const theTspanClosestToLeft = minBy(tspansInText, (tspan) => parseFloat(tspan.getAttribute('x') || '0'));

		let minX = 0;

		if (theTspanClosestToLeft) {
			minX = parseFloat(theTspanClosestToLeft.getAttribute('x') || '0');
		}

		// Si tiene un texto sin tspan, asumimos que el min x es 0, si no hay otra linea que lo tenga inferior
		if (textNode.node.firstChild && textNode.node.firstChild.nodeType === Node.TEXT_NODE) {
			minX = Math.min(minX, 0);
		}

		let align = '';
		let offsetX = 0;

		// Por defecto estamos alineados la izquierda
		if ((minX === 0 && textNode.node.childElementCount === 0) || Object.keys(lines).length === 1) {
			align = 'left';
		} else {
			// Hay que distinguir entre los textos centrados de los alineados
			// a la derecha, podemos saber si el bounding de los textos que
			// estan más a la derecha acaban todos en la misma posicion

			const lastItemsInLine = Object.values(lines).map((tspans) => tspans[tspans.length - 1]);
			const firstItemsInLine = Object.values(lines).map((tspans) => tspans[0]);

			const lastItemsX = lastItemsInLine.map((tspan) => {
				// si es un texto suelto suponemos que ocupa todo el ancho
				if (tspan.nodeType === Node.TEXT_NODE) {
					return (tspan.parentNode as Element).getBoundingClientRect().right;
				}
				return tspan.getBoundingClientRect().right;
			});

			const firstItemsX = firstItemsInLine.map((tspan) => {
				// si es un texto suelto suponemos que ocupa todo el ancho
				if (tspan.nodeType === Node.TEXT_NODE) {
					return (tspan.parentNode as Element).getBoundingClientRect().left;
				}
				return tspan.getBoundingClientRect().left;
			});

			const averageLeftX = mean(firstItemsX);
			const minLeftX = min(firstItemsX) || 0;
			const averageRightX = mean(lastItemsX);
			const maxX = max(lastItemsX) || 0;
			const threshold = fontSize * 0.2;
			const diffLeft = Math.abs(averageLeftX - minLeftX);
			const diffRight = Math.abs(averageRightX - maxX);

			// definimos un margen de seguridad del tamaño de un caracter, para no confundir
			// para determinar si esta alineado a la derecha ya que no siempre acaba
			// en los mismos pixeles exactos por algún motivo

			if (diffLeft < threshold) {
				align = 'left';
			} else if (diffRight < threshold) {
				align = 'right';
				offsetX = Math.round(-width) * 2;
			} else {
				align = 'center';
				offsetX = Math.round(-width);
			}
		}

		// Calculamos el ancho en base al texto original + ancho de un caracter
		const rbox = textNode.bbox();
		const matrix = textNode.matrixify();
		const newMatrix = matrix.transform({
			translateX: minX + offsetX,
			translateY: y + offsetY,
		});

		let textContent = '';
		const orderedLines = Object.keys(lines).sort();

		orderedLines.forEach((line) => {
			const content = lines[line];

			content.forEach((item) => {
				textContent += item.textContent || ' ';
			});

			textContent += ' ';
		});

		return {
			textAlign: align as TextAlign,
			content: textContent,
			matrix: newMatrix,
			height: rbox.height,
			width: rbox.width + width,
		};
	}

	static getTextShadow(el: SvgElement): TextShadowProperties[] {
		const textShadowEl = el.findOne('[style*="text-shadow"]')?.node;
		if (!textShadowEl) return Text.defaults().textShadow;

		const { textShadow } = getComputedStyle(textShadowEl);
		const textShadowArray = textShadow.split('px, ');

		return textShadowArray.map((ts) => {
			// Extraemos el color y asignamos la opacidad a 1, ya que se gestiona a parte
			const color = SolidColor.fromString(ts);
			const opacity = color.a;
			color.a = 1;

			const splittedTextShadow = ts.split(') ')[1].split(' ');
			const blur = parseFloat(splittedTextShadow[2]);
			const x = parseFloat(splittedTextShadow[0]);
			const y = parseFloat(splittedTextShadow[1]);
			const angle = x ? Math.abs(MathTools.radiandsToAngle(Math.atan2(y, x))) : 0;
			const distance = Math.round(Math.sqrt(x ** 2 + y ** 2));

			return { angle, blur, color, distance, opacity };
		});
	}

	static removeTextsStyles = (element: HTMLElement) => {
		const childNodes = Array.from(element.querySelectorAll('*')) as HTMLElement[];

		if (childNodes.length) {
			for (const child of childNodes) {
				child.removeAttribute('style');
				child.removeAttribute('class');

				// Restauramos el underline de los links
				if (child.tagName.toLowerCase() === 'a') {
					child.style.textDecoration = 'underline';
				}
			}
		}
	};

	static escapeRegExp = (string: string) =>
		string.replace(/[\!\”\\\#\$\%\&\’\(\)\*\+\,\/\:\;\<\=\>\?\@\[\]\^\_\`\{\|\}\~]/g, '\\$&'); // $& means the whole matched string

	/**
	 * Reemplaza todos los carácteres especiales a \specialChar de un texto
	 * @param str texto inicial
	 * @returns texto con carácteres reemplazados
	 */
	static replaceSpecialChars = (str: string) =>
		this.replaceBreakLines(
			str
				.replaceAll('.', '\\.')
				.replaceAll('!', '\\!')
				.replaceAll('”', '\\”')
				.replaceAll('#', '\\#')
				.replaceAll('$', '\\$')
				.replaceAll('%', '\\%')
				.replaceAll('&', '\\&')
				.replaceAll('’', '\\’')
				.replaceAll('(', '\\(')
				.replaceAll(')', '\\)')
				.replaceAll('*', '\\*')
				.replaceAll('+', '\\+')
				.replaceAll(',', '\\,')
				.replaceAll('-', '\\-')
				.replaceAll('/', '\\/')
				.replaceAll(':', '\\:')
				.replaceAll(';', '\\;')
				.replaceAll('<', '\\<')
				.replaceAll('=', '\\=')
				.replaceAll('>', '\\>')
				.replaceAll('?', '\\?')
				.replaceAll('@', '\\@')
				.replaceAll('[', '\\[')
				.replaceAll('\\', '\\')
				.replaceAll(']', '\\]')
				.replaceAll('^', '\\^')
				.replaceAll('_', '\\_')
				.replaceAll('`', '\\`')
				.replaceAll('{', '\\{')
				.replaceAll('|', '\\|')
				.replaceAll('}', '\\}')
				.replaceAll('~', '\\~')
		);

	/**
	 * Reemplaza todos saltos de línea de un texto
	 * @param str texto inicial
	 * @returns texto con saltos de línea reemplazados
	 */
	static replaceBreakLines = (str: string) => str.replace(/[\n\t]/g, '');

	static isValidUrl = (url: string) => {
		let urlObj = null;

		try {
			urlObj = new URL(url);
		} catch (_) {
			return false;
		}

		return urlObj.protocol === 'http:' || urlObj.protocol === 'https:';
	};

	static textShadowToCssString(textShadow: TextShadowProperties[]) {
		return textShadow
			.map((ts: TextShadowProperties) => {
				const unit = ts.unit || 'px';
				return `rgba(${ts.color.r}, ${ts.color.g}, ${ts.color.b}, ${ts.opacity}) ${
					Math.cos(MathTools.angleToRadians(ts.angle)) * ts.distance
				}${unit} ${Math.cos(MathTools.angleToRadians(90 - ts.angle)) * ts.distance}${unit} ${ts.blur}${unit}`;
			})
			.join(', ');
	}
}

export default TextTools;
