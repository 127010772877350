import { defineStore } from 'pinia';

import Element from '@/Classes/Element';
import Image from '@/Classes/Image';
import Line from '@/Classes/Line';
import Project from '@/Classes/Project';
import { QRCode } from '@/Classes/QRCode';
import { Shape } from '@/Classes/Shape';
import Storyset from '@/Classes/Storyset';
import { Text } from '@/Classes/Text';

export const useProjectStore = defineStore('project', {
	// @ts-ignore
	state: (): Project => window.forceProject || new Project(800, 800, 'px', 3170),
	getters: {
		getElementById: (state) => {
			return (id: string | null): Element | null => {
				if (!id) return null;
				const result = state.pages.flatMap((t) => Object.values(t.elements)).find((e) => e.id === id);
				return result || null;
			};
		},
		allElements: (state): Element[] => {
			return state.pages.flatMap((t) => Object.values(t.elements)) as Element[];
		},
		allShapes: (state): Shape[] => {
			return state.pages.flatMap((t) => Object.values(t.elements)).filter((e) => e.type === 'shape') as Shape[];
		},
		allTexts: (state): Text[] => {
			return state.pages.flatMap((t) => Object.values(t.elements)).filter((e) => e.type === 'text') as Text[];
		},
		allImages: (state): Image[] => {
			return state.pages.flatMap((t) => Object.values(t.elements)).filter((e) => e.type === 'image') as Image[];
		},
		allStorysets: (state): Storyset[] => {
			return state.pages.flatMap((t) => Object.values(t.elements)).filter((e) => e.type === 'storyset') as Storyset[];
		},
		allLines: (state): Line[] => {
			return state.pages.flatMap((t) => Object.values(t.elements)).filter((e) => e.type === 'line') as Line[];
		},
		allQRCodes: (state): QRCode[] => {
			return state.pages.flatMap((t) => Object.values(t.elements)).filter((e) => e.type === 'qrcode') as QRCode[];
		},
	},
});
