import { createFetch } from '@vueuse/core';

export const useEditorWebFetch = createFetch({
	baseUrl: import.meta.env.VITE_APP_BASE,
	options: {
		beforeFetch({ options }) {
			const customHeaders = options.headers ? new Headers(options.headers) : new Headers();
			customHeaders.append('X-Requested-With', import.meta.env.VITE_APP_API_VERSION);
			// Session Token
			if (window.xsrf) {
				customHeaders.append('X-XSRF-TOKEN', window.xsrf);
			}

			options.headers = customHeaders;

			return { options };
		},
	},
});
