<script setup lang="ts">
// Vue
import { computed, toRef } from 'vue';

// Classes
import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { useMainStore } from '@/stores/store';

// Props
const props = defineProps<{ element: Element; inlineLabel?: boolean }>();

// Data
const element = toRef(props, 'element');

// Computeds
const isCropping = computed(() => !!store.croppingId?.length);

// Using composables
const { flipAxis } = useElementTransformOrchestrator(element).value;
const store = useMainStore();
</script>

<template>
	<div class="flex gap-1 lg:w-full lg:gap-px">
		<div class="lg:flex-1">
			<button
				class="flex h-9 w-9 items-center justify-center rounded bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white lg:h-8 lg:w-full lg:rounded-tl lg:rounded-bl lg:px-3"
				data-testid="flip-x"
				:class="{ 'disabled cursor-not-allowed opacity-50': isCropping }"
				:disabled="isCropping"
				@click="flipAxis('x')"
			>
				<SvgIcon name="flip-hor" class="h-4 w-4 scale-90" />
			</button>
		</div>

		<div class="lg:flex-1">
			<button
				class="flex h-9 w-9 items-center justify-center rounded bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white lg:h-8 lg:w-full lg:rounded-tr lg:rounded-br lg:px-3"
				data-testid="flip-y"
				:class="{ 'disabled cursor-not-allowed opacity-50': isCropping }"
				:disabled="isCropping"
				@click="flipAxis('y')"
			>
				<SvgIcon name="flip-ver" class="h-4 w-4 scale-90" />
			</button>
		</div>
	</div>

	<label v-if="inlineLabel" class="mt-1 block text-center text-2xs text-gray-100">Flip</label>
</template>
