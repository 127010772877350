class ArrayTools {
	/**
	 * Busca las cosas añadidas entre dos arrays
	 * @param newArray
	 * @param oldArray
	 */
	static findNewElements<T>(newArray: T[], oldArray: T[]) {
		return newArray.filter((x) => !oldArray.includes(x));
	}

	/**
	 * Busca las cosas eliminadas entre dos arrays
	 * @param newArray
	 * @param oldArray
	 */
	static findMissingElements<T>(newArray: T[], oldArray: T[]) {
		return oldArray.filter((x) => !newArray.includes(x));
	}

	/**
	 * Busca las cosas añadidas y eliminadas entre dos arrays
	 * @param newArray
	 * @param oldArray
	 */
	static findChanges<T>(newArray: T[], oldArray: T[]) {
		if (!oldArray) {
			return {
				deleted: [],
				added: newArray,
			};
		}
		return {
			deleted: this.findMissingElements(newArray, oldArray),
			added: this.findNewElements(newArray, oldArray),
		};
	}

	/**
	 * Mueve el elemento de un indice del array a otro
	 *
	 * Usamos este método frente este mas simple por performance this.splice(to, 0, this.splice(from, 1)[0]);
	 * Mas info en https://jsbench.me/1qkxqhvxvr 50% mas rápido
	 * @param array - Array a modificar
	 * @param from - Indice actual del elemento a mover
	 * @param to - Indice al que se quiere mover el elemento
	 */
	static move<T>(array: T[], from: number, to: number): void {
		// local variables
		let i, tmp;
		// if positions are different and inside array
		if (from !== to && 0 <= from && from <= array.length && 0 <= to && to <= array.length) {
			// save element from position 1
			tmp = array[from];
			// move element down and shift other elements up
			if (from < to) {
				for (i = from; i < to; i++) {
					array[i] = array[i + 1];
				}
			}
			// move element up and shift other elements down
			else {
				for (i = from; i > to; i--) {
					array[i] = array[i - 1];
				}
			}
			// put element from position 1 to destination
			array[to] = tmp;
		}
	}
}

export default ArrayTools;
