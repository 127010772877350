<script setup lang="ts">
import { computed } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import DeleteReportButton from '@/components/export/DeleteReportButton.vue';
import PresentButton from '@/components/export/PresentButton.vue';
import SaveButton from '@/components/export/SaveButton.vue';
import SavePredefinedTextButton from '@/components/export/SavePredefinedTextButton.vue';
import SaveTemplateButton from '@/components/export/SaveTemplateButton.vue';
import ShareButton from '@/components/export/ShareButton.vue';
import HistoryNavigation from '@/components/skeleton/HistoryNavigation.vue';
import ProjectColorPicker from '@/components/skeleton/ProjectColorPicker.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import useNavigation from '@/composables/useNavigation';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';

import DownloadButton from '../export/DownloadButton.vue';
import ArtboardPicker from './ArtboardPicker.vue';
import PhotoModeNewImageButton from './PhotoModeNewImageButton.vue';
import UserMenu from './UserMenu.vue';
const project = useProjectStore();
const store = useMainStore();
const { navigateWithoutLosingChanges } = useNavigation();

const {
	isAdminMode,
	isEditorMode,
	isPredefinedTextMode,
	isPhotoMode,
	inSlidesgoContext,
	isReportContext,
	isIllustratorContext,
} = useEditorMode();
const { isMobile, isWebview } = useDeviceInfo();

const modeName = computed(() => {
	if (isIllustratorContext.value) {
		return 'Modo illustrator';
	}

	return 'Modo edición';
});
</script>
<template>
	<header class="z-30 flex h-14 w-full shrink-0 items-center justify-between bg-gray-700 px-2 lg:px-4 lg:shadow-lg">
		<div class="flex w-8 flex-1 shrink-0 items-center text-white">
			<a
				href="https://wepik.com"
				:class="{
					'cursor-default': isPhotoMode,
				}"
				tooltip-position="right"
				:tooltip="isPhotoMode ? null : 'Go to Wepik'"
				@click.prevent="isPhotoMode ? null : navigateWithoutLosingChanges('https://wepik.com')"
			>
				<SvgIcon
					:name="isWebview ? 'return' : 'iso'"
					class="ml-2 h-7 w-7 fill-current text-white opacity-90 group-hover:opacity-100"
				/>
			</a>
			<span
				v-if="isAdminMode"
				class="absolute top-0 left-0 z-50 ml-28 mt-3 animate-bounce rounded bg-yellow-500 py-2 px-4 text-center text-xs font-bold uppercase text-white"
				:class="{
					'bg-[#F77800]': isIllustratorContext,
				}"
				style="animation-iteration-count: 2.5"
				>{{ modeName }}</span
			>
			<span
				v-else-if="isPredefinedTextMode"
				class="absolute top-0 left-0 z-50 ml-28 mt-3 animate-bounce rounded bg-blue-500 py-2 px-4 text-center text-xs font-bold uppercase text-white"
				style="animation-iteration-count: 2.5"
				>Modo crear texto predefinido</span
			>
		</div>
		<div class="flex items-center">
			<label v-if="isEditorMode" class="relative hidden h-8 min-w-[20px] max-w-xs sm:block">
				<input
					v-model="project.name"
					maxlength="100"
					type="text"
					class="absolute top-0 left-0 m-0 h-8 w-full text-ellipsis rounded bg-transparent px-3 text-gray-100 placeholder-gray-300 outline-none hover:text-gray-100 hover:ring-1 hover:ring-white/25 focus:bg-gray-800/50 focus:text-gray-100 focus:ring-1 focus:ring-white/25"
					:placeholder="project.category || 'Untitled project'"
				/>
				<span class="pointer-events-none flex h-8 items-center whitespace-pre px-3 opacity-0">{{
					project.name || project.category || 'Untitled project'
				}}</span>
			</label>
			<SaveButton v-if="!isPhotoMode" />
		</div>
		<div class="flex items-center justify-end gap-2 pl-2 sm:pl-4 lg:gap-4 2xl:flex-1 2xl:pl-0">
			<PhotoModeNewImageButton v-if="isPhotoMode" />
			<ProjectColorPicker class="hidden lg:block" />
			<HistoryNavigation />
			<ArtboardPicker v-if="isPhotoMode && !isMobile && store.finishedLoading" />
			<hr class="h-8 w-px border-0 bg-gray-600" />
			<div class="flex shrink-0 items-center gap-2">
				<ShareButton v-if="!isPhotoMode && project.pages.length && !isAdminMode && !isWebview" />
				<ArtboardPicker v-if="!isPhotoMode && !isIllustratorContext && store.finishedLoading" />
				<DownloadButton v-if="!isAdminMode" class="relative" />
				<SaveTemplateButton v-if="isAdminMode && store.finishedLoading" />
				<DeleteReportButton v-if="isReportContext" />
				<SavePredefinedTextButton v-else-if="isPredefinedTextMode" />
				<PresentButton v-else-if="inSlidesgoContext" />
				<UserMenu v-if="!isWebview" />
			</div>
		</div>
	</header>
</template>
<style scoped>
input:after {
	content: attr(data-text);
}
</style>
