<script setup lang="ts">
// Vue
import { computed, toRef } from 'vue';

// Classes
import Storyset from '@/Classes/Storyset';
// Components
import Dropdown from '@/components/Common/Dropdown.vue';
// Composables
import { useStoryset } from '@/composables/element/storyset/useStoryset';

const props = defineProps<{ element: Storyset }>();
const element = toRef(props, 'element');

const { storysetBackgrounds, currentStorysetBackground, changeStorysetBackground } = useStoryset(element);

const backgroundOptions = computed(() => {
	return storysetBackgrounds.value.map((bg) => ({
		label: `${bg.name} background`,
		value: bg.name.toLowerCase(),
	}));
});
</script>

<template>
	<Dropdown
		class="flex items-center justify-center"
		:selected="(currentStorysetBackground as string)"
		:options="backgroundOptions"
		@update="changeStorysetBackground"
	>
	</Dropdown>
</template>
