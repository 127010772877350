<script setup lang="ts">
// Vue & Packages
import Bugsnag from '@bugsnag/js';
import { onClickOutside } from '@vueuse/core';
import { computed, onBeforeMount, Ref, ref, watch } from 'vue';

// Api
import { getArtboards } from '@/api/DataApiClient';
// Classes
import Image from '@/Classes/Image';
import Page from '@/Classes/Page';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import ArtboardCustomSize from '@/components/skeleton/ArtboardCustomSize.vue';
import { usePage } from '@/composables/page/usePage';
import { useArtboard } from '@/composables/project/useArtboard';
// Composables
import { useEditorMode } from '@/composables/useEditorMode';
import { useZoom } from '@/composables/useZoom';
// Stores
import { useProjectStore } from '@/stores/project';
// Types
import { Artboard, Size, Unit } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';
const project = useProjectStore();

// Template refs
const selector = ref();

// Data
const artboardsPresets = ref<Artboard[]>([]);
const presetsVisibility = ref<boolean>(false);
const customSizeVisibility = ref<boolean>(false);
const temporalRef = ref(Page.createDefault());

// Composables
const currentPresetName = computed(() => {
	const currentArtboard = artboardsPresets.value.find(
		(preset) =>
			preset.name !== 'Custom' &&
			preset.width === project.size.width &&
			preset.height === project.size.height &&
			preset.unit === project.unit
	);
	// validamos si el último preset que se seleccionó sigue siendo el mismo que el calculado, de ser así, se mantiene
	if (presetNameSelected.value === currentPresetName.value) return presetNameSelected.value;
	return currentArtboard?.name || 'Original';
});
const presetNameSelected = ref<string>('');

// Using composables
const { artboardSizeInPx, rotateArtboard, setArtboardSize, isRotable, isLandscape } = useArtboard();
const { isPhotoMode } = useEditorMode();
const { fitZoomScale } = useZoom();
const { adjustContent } = usePage(temporalRef as Ref<Page>);

// Lifecycle hooks
onBeforeMount(async () => {
	await initPresets();
});

// Watchers
watch(
	artboardSizeInPx,
	(newArtboard, oldArtboard) => {
		// Volvemos a asignar los valores del custom artboard
		updateCustomArtboardData();
		// Si no es undefined es que hemos usado el historial, así
		// que ignoramos el cambio de artboard
		if (window.moving !== undefined) {
			window.moving = undefined;
			return;
		}
		project.pages.forEach((page) => {
			temporalRef.value = page;
			adjustContent(newArtboard, oldArtboard);
		});
	},
	{ deep: true }
);

// Methods
const closeArtboardOptions = () => {
	customSizeVisibility.value = false;
	presetsVisibility.value = false;
};

const initPresets = async () => {
	const artboards = await getArtboards();

	if (!artboards.length) return;

	artboardsPresets.value = artboards;
	presetNameSelected.value = currentPresetName.value;

	// Añadimos el tamaño original por si se quiere volver a él
	artboardsPresets.value.push({
		name: 'Original',
		width: project.size.width,
		height: project.size.height,
		unit: project.unit,
	});

	// Se añade en primera posición el preset Custom del artboard
	artboardsPresets.value.unshift({
		name: 'Custom',
		width: project.size.width,
		height: project.size.height,
		unit: project.unit,
	});
};

const updateCustomArtboardData = () => {
	// Volvemos a asignar los valores del custom preset con los datos actuales del artboard
	artboardsPresets.value.shift();
	artboardsPresets.value.unshift({
		name: 'Custom',
		width: project.size.width,
		height: project.size.height,
		unit: project.unit,
	});
};

const onToggleArtboardSize = (newMode: 'landscape' | 'portrait') => {
	const currentMode = isLandscape.value ? 'landscape' : 'portrait';

	if (currentMode === newMode) return;

	rotateArtboard();
	Bugsnag.leaveBreadcrumb(`rotate artboard to: ${newMode}`);
};

const onChangeArtboardSizeAndUnit = (newSize: Size, newUnit: Unit) => {
	onUpdateArtboard({
		name: 'Custom',
		width: newSize.width,
		height: newSize.height,
		unit: newUnit,
	});

	if (isPhotoMode.value) {
		// Make larger or smaller proportianlly regarding custom size
		const image = project.pages[0].elements[0] as Image;
		const factor = image.size.width / newSize.width;

		image.size = {
			width: newSize.width,
			height: newSize.height,
		};
		image.crop = {
			position: {
				x: image.crop.position.x / factor,
				y: image.crop.position.y / factor,
			},
			size: {
				width: image.crop.size.width / factor,
				height: image.crop.size.height / factor,
			},
		};
	}

	customSizeVisibility.value = false;
};

onClickOutside(selector, closeArtboardOptions);

const onUpdateArtboard = async (preset: Artboard) => {
	// Si el preset es seleccionado desde el panel, guardamos su name
	presetNameSelected.value = preset.name;
	if (!isPhotoMode.value && !customSizeVisibility.value && preset.name === 'Custom') {
		customSizeVisibility.value = true;
		return;
	}

	closeArtboardOptions();
	setArtboardSize(preset.width, preset.height, preset.unit);
	fitZoomScale();
	GAnalytics.track('click', 'select', 'change-artboard-size', null);
	Bugsnag.leaveBreadcrumb(`Update artboard to ${preset.name}: ${preset.width}, ${preset.height}, ${preset.unit}`);
};

const togglePresetsVisibility = () => {
	presetsVisibility.value = !presetsVisibility.value;
	if (!presetsVisibility.value) customSizeVisibility.value = false;
};

const getPresetIconName = (name: string) => `artboards-${name.toLowerCase().replace(/ /g, '_')}`;
</script>

<template>
	<div ref="selector" class="relative flex gap-1">
		<template v-if="isPhotoMode">
			<div class="w-64">
				<ArtboardCustomSize @changeArtboardSizeAndUnit="onChangeArtboardSizeAndUnit" />
			</div>
		</template>

		<template v-else>
			<button
				id="changeArtboardButton"
				data-testid="change-artboard"
				class="flex h-10 items-center justify-center gap-2 px-2 text-white opacity-50 hover:opacity-100 focus:opacity-100"
				@click="togglePresetsVisibility"
			>
				<SvgIcon name="resize" class="h-4 w-4" />
				<span class="hidden lg:block">Resize</span>
			</button>

			<div v-if="presetsVisibility" data-testid="artboard-list-container" class="absolute top-12 right-0 z-20">
				<Transition
					appear
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="transform -translate-y-4 opacity-0"
				>
					<ArtboardCustomSize v-if="customSizeVisibility" @changeArtboardSizeAndUnit="onChangeArtboardSizeAndUnit" />
					<div v-else class="relative">
						<div
							class="flex max-h-72 w-52 flex-col overflow-auto rounded bg-white py-1 pr-1 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100 lg:w-72"
						>
							<div class="mb-0.5 flex items-center pl-4 pt-2">
								<p class="flex-1 text-xs font-bold uppercase text-gray-500">Change artboard</p>
								<div v-if="isRotable" class="flex gap-1" data-testid="artboard-orientation">
									<button
										class="relative flex h-7 w-7 shrink-0 items-center justify-center rounded"
										:class="{
											'bg-gray-100/25 text-gray-700': !isLandscape,
											'text-gray-400 hover:text-gray-700': isLandscape,
										}"
										data-testid="artboard-orientation-portrait"
										@click="onToggleArtboardSize('portrait')"
									>
										<SvgIcon name="portrait" class="h-4 w-4" />
									</button>

									<button
										class="relative flex h-7 w-7 shrink-0 items-center justify-center rounded"
										:class="{
											'bg-gray-100/25 text-gray-700': isLandscape,
											'text-gray-400 hover:text-gray-700': !isLandscape,
										}"
										data-testid="artboard-orientation-landscape"
										@click="onToggleArtboardSize('landscape')"
									>
										<SvgIcon name="landscape" class="h-4 w-4" />
									</button>
								</div>
							</div>

							<button
								v-for="preset in artboardsPresets"
								:key="preset.name"
								:data-testid="getPresetIconName(preset.name)"
								:class="currentPresetName === preset.name ? 'bg-gray-100/25' : ''"
								class="flex cursor-pointer py-2 pl-4 pr-1 text-left text-sm text-gray-600 hover:bg-gray-100/25 hover:text-gray-800 lg:items-center"
								@click="onUpdateArtboard(preset)"
							>
								<SvgIcon
									:name="getPresetIconName(preset.name)"
									class="mr-2 mt-1 h-3.5 w-3.5 shrink-0 text-gray-800 lg:mt-0"
								/>
								<div class="flex w-full flex-col lg:flex-row lg:items-center">
									<span class="flex-1">{{ preset.name }}</span>
									<span class="text-xs text-gray-500 opacity-75">
										{{ Math.round(preset.width) }}x{{ Math.round(preset.height) }}{{ preset.unit }}
									</span>
								</div>
							</button>
						</div>
					</div>
				</Transition>
			</div>
		</template>
	</div>
</template>
