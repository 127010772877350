<script setup lang="ts">
// Vue
import { useScroll } from '@vueuse/core';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';

// Classes
import Element from '@/Classes/Element';
import GroupToolbar from '@/components/selection/toolbars/GroupToolbar.vue';
// Components
import Toolbar from '@/components/selection/toolbars/Toolbar.vue';
// Composables
import { useCropPhotoMode } from '@/composables/element/image/useCropPhotoMode';
import { useGroup } from '@/composables/group/useGroup';
import { useGroupTransform } from '@/composables/group/useGroupTransform';
import { useFeedback } from '@/composables/interactions/useFeedback';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useInteractiveElements } from '@/composables/interactions/useInteractiveElements';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useSelectionKeyboardEvents } from '@/composables/useSelectionKeyboardEvents';
// Storages
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';
const store = useMainStore();

// Props
const props = defineProps<{ elements: Element[] }>();

// Lifecycle hooks
onMounted(() => (scrollArea.value = document.querySelector('#scroll-area')));

onBeforeUnmount(() => {
	store.croppingId = null;
});

// Data
const elements = toRef(props, 'elements');
const scrollArea = ref();

// Computeds
const selectedElement = computed(() => elements.value[0]);

// Using composables
const usingSelectionKeyboardEvents = useSelectionKeyboardEvents();
const { isMobile } = useDeviceInfo();
const { interactiveElementReady } = useInteractiveElements(elements);
const { isDragging, isRotating, isResizing, disableToolbar, isCropping } = useInteractions();
const { isCropPhotoModeReady } = useCropPhotoMode();
const { selectFeedback } = useFeedback();
const { isGrouped, group, isLockGroup } = useGroup(selectedElement);
const { updateTargets } = useGroupTransform(group);
const { isScrolling } = useScroll(scrollArea);

const scale = computed(() => store.scale);
const activePanel = computed(() => store.activePanel);
const isGroupToolbar = computed(() => isGrouped.value && interactiveElementReady.value && !shouldHide.value);

const shouldHide = computed(
	() => !isCropping.value && (isDragging.value || isRotating.value || isResizing.value || disableToolbar.value)
);
const isToolbarVisible = computed(
	() =>
		!!(
			(isCropPhotoModeReady.value ||
				((elements.value.length === 1 || isLockGroup.value) && interactiveElementReady.value)) &&
			!shouldHide.value
		)
);

// Register keyboard events
usingSelectionKeyboardEvents.init();

selectFeedback();

// Cuando es un grupo y cambian estas variables necesatimos que se actualicen los datas del groupTransform ya que
// no es reactivo a estos cambios
watch([isScrolling, scale, activePanel], async (newVal) => {
	if (newVal || !isGrouped.value) return;

	await nextTick();
	updateTargets();
});
</script>

<template>
	<div>
		<GroupToolbar v-if="isGroupToolbar" :elements="elements" />
		<Toolbar v-if="!isMobile" :key="selectedElement.id" :visibility="isToolbarVisible" :element="selectedElement" />
	</div>
</template>

<style lang="sass">
#scroll-area .moveable-control-box
	@apply z-10 will-change-auto

	.moveable-area
		border: 1px dashed rgb(18 115 235) !important
	.moveable-line
		@apply bg-blue-500

		&.moveable-guideline
			@apply bg-pink-400

			&.moveable-vertical
				@apply w-px

			&.moveable-horizontal
				@apply h-px

			&.moveable-dashed
				@apply bg-transparent border-pink-400

	.moveable-control
		@apply bg-transparent border-0 w-[30px] h-[30px] -mt-[15px] -ml-[15px]

		&::before
			content: ''
			@apply absolute inset-0 border border-blue-500 rounded-full m-[10px] bg-white

		&:hover::before
			@apply bg-blue-500

		&.moveable-n,
		&.moveable-s
			@apply bg-transparent border-0 w-[35px] h-[20px] -mt-[10px] -ml-[17px] rounded

			&::before
				@apply absolute inset-0 border border-blue-500 rounded m-[7px] bg-white

			&:hover::before
				@apply bg-blue-500

		&.moveable-e,
		&.moveable-w
			@apply bg-transparent border-0 w-[20px] h-[35px] -mt-[17px] -ml-[10px] rounded-full

			&::before
				@apply absolute inset-0 border border-blue-500 rounded m-[7px] bg-white

			&:hover::before
				@apply bg-blue-500

	.moveable-rotation
		.moveable-rotation-line
			@apply hidden

		.moveable-rotation-control
			@apply bg-blue-500 border-0 w-4 h-4 -mt-2 -ml-2 bg-no-repeat bg-center
			background-image: url('../../assets/svgs/rotation-white.svg')
			background-size: 8px
			transform: translate(0.5px) scale(1.3) rotate(180deg) !important

			&::before
				@apply hidden

			&:hover
				@apply bg-blue-400

	&.moveable-origin
		@apply hidden
</style>
