import { computed, Ref } from 'vue';

import Element from '@/Classes/Element';
import Image from '@/Classes/Image';

import { useImageTransform } from './image/useImageTransform';
import { useElementTransform } from './useElementTransform';

export const useElementTransformOrchestrator = (element: Ref<Element>) => {
	return computed(() => {
		if (element.value instanceof Image) {
			return useImageTransform(element as Ref<Image>);
		}

		return useElementTransform(element);
	});
};
