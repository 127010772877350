<script lang="ts" setup>
// Vue & Packages
import { until, UseFetchOptions, UseFetchReturn, useScroll } from '@vueuse/core';
import Colcade from 'colcade';
import { v4 as uuidv4 } from 'uuid';
import { nextTick, onMounted, Ref, ref, toRef, watch } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
// Props
const props = defineProps<{
	containerClasses?: string | object;
	fetch?: (
		url: Ref<string>,
		options?: UseFetchOptions
	) => (UseFetchReturn<unknown> & PromiseLike<UseFetchReturn<unknown>>) | any;
	modal?: boolean;
	masonry?: boolean;
	horizontal?: boolean;
	masonryCols?: number;
	source: string;
}>();

// Template refs
const container = ref();

// Data
const sourceRef = toRef(props, 'source');
const url = ref(props.source);
const { data, isFetching } = props.fetch
	? props.fetch(url, { refetch: true }).json()
	: { data: ref([]), isFetching: ref(false) };

// Watchers
watch(sourceRef, async (newVal) => {
	container.value.scrollTop = 0;

	await until(isFetching).not.toBeTruthy();
	url.value = newVal;
});

onMounted(() => {
	let reference = props.horizontal ? 'width' : 'height';

	container.value.style[reference] = `auto`;
	const size = container.value.getBoundingClientRect()[reference];
	container.value.style[reference] = `${size}px`;
	container.value.style.flexGrow = 'initial';
});

// Methods
const loadMore = () => {
	if (isFetching.value || !data.value.links.next) return;
	url.value = data.value.links.next;
};

// Cuando lleguemos al pie cargamos más recursos
const scroll = useScroll(container, {
	offset: {
		bottom: window.outerHeight / 3,
		right: window.outerWidth / 5,
	},
	onStop() {
		if (!props.horizontal && scroll.arrivedState.bottom) {
			loadMore();
		}

		if (props.horizontal && scroll.arrivedState.right) {
			loadMore();
		}
	},
});

// masonry
const itemsContainer = ref();
let grid: any = null;
const newMasonry = ref(true);

const loadMasonry = () => {
	itemsContainer.value.querySelectorAll('.grid-col .grid-item').forEach((item: any) => {
		item.parentElement.removeChild(item);
	});

	grid = new Colcade(itemsContainer.value, {
		columns: '.grid-col',
		items: '.grid-item',
	});
	newMasonry.value = true;
};

if (props.masonry) {
	onMounted(() => {
		loadMasonry();

		watch(data, async () => {
			await nextTick();

			const items = itemsContainer.value.querySelectorAll(':scope > .grid-item');
			grid.append(items);

			newMasonry.value = false;
		});

		watch(sourceRef, loadMasonry);
	});
}

const emits = defineEmits(['onFetch']);
watch(data, () => {
	emits('onFetch', data.value);
});
</script>

<template>
	<div
		ref="container"
		data-testid="container-resources"
		class="flex-grow overflow-auto pb-2 pr-1 scrollbar-thin"
		:class="modal ? 'text-white scrollbar-thumb-gray-100' : 'text-gray-800 scrollbar-thumb-gray-600'"
	>
		<slot></slot>

		<!-- No masonry -->
		<div v-if="!masonry" :class="containerClasses">
			<slot name="default-item"></slot>
			<div v-for="(item, index) in data?.data || []" :key="index">
				<slot name="item" :item="item"></slot>
			</div>
		</div>

		<!-- Masonry -->
		<div
			v-else
			ref="itemsContainer"
			class="grid gap-2"
			:class="newMasonry ? 'opacity-0' : ''"
			:style="{ gridTemplateColumns: `repeat(${props.masonryCols}, minmax(0, 1fr))` }"
		>
			<div
				v-for="index in props.masonryCols"
				:key="`grid-col--${index - 1}`"
				class="grid-col"
				:class="`grid-col--${index - 1}`"
			></div>
			<template v-if="data">
				<div v-for="item in data.data" :key="`${sourceRef}-${item?.id || uuidv4()}`" class="grid-item">
					<slot name="item" :item="item"></slot>
				</div>
			</template>
		</div>

		<div v-if="isFetching && data" class="absolute bottom-8 flex w-full items-center justify-center">
			<span
				class="flex items-center rounded-full bg-opacity-75 p-1 pr-3 text-center text-sm font-semibold backdrop-blur"
				:class="modal ? 'bg-white text-gray-700' : 'bg-gray-900 text-gray-100'"
			>
				<SvgIcon data-testid="spinner" name="spinner" class="mr-1 h-7 w-7 animate-spin" /> Loading
			</span>
		</div>

		<div v-if="isFetching && !data" class="absolute inset-0 flex w-full items-center justify-center text-gray-100">
			<SvgIcon name="spinner" class="h-10 w-10 animate-spin" />
		</div>
		<div
			v-if="!isFetching && (data && data.data ? data.data.length : data?.length || 0) === 0"
			class="my-8 mx-auto rounded-full px-8 py-1 text-center text-xs"
			:class="modal ? 'bg-gray-100/50 text-gray-800' : 'bg-gray-600 text-gray-100'"
		>
			No results found
		</div>
	</div>
</template>
