<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import OnClickOutsideWithPortals from '@/components/Common/OnClickOutsideWithPortals';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useTextEffects } from '@/composables/element/text/useTextEffects';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const scale = computed(() => store.scale);

const store = useMainStore();
const { isMobile } = useDeviceInfo();

const { isCircleText } = useCircleTypeInfo(element, scale);
const { hasEffect, hasPreset } = useTextEffects(element);

const onClickEffects = () => {
	if (store.editPanel === EditPanels.TextEffects) {
		store.editPanel = isMobile.value ? EditPanels.Text : null;
		return;
	}

	store.editPanel = EditPanels.TextEffects;
};

const onCloseEditPanel = () => {
	if (!isMobile.value) return;
	store.editPanel = EditPanels.Text;
};
</script>

<template>
	<OnClickOutsideWithPortals data-testid="text-effects" :class="{ '-order-1': isMobile }" @trigger="onCloseEditPanel">
		<button
			data-testid="text-effects"
			data-button-importance="normal"
			class="relative flex h-11 w-14 shrink-0 flex-col items-center justify-between hover:text-white sm:order-none lg:h-6 lg:w-auto lg:flex-row lg:justify-center lg:px-1"
			:class="{
				'text-white': isMobile ? hasPreset : hasEffect || isCircleText,
				'text-gray-100': isMobile ? !hasPreset : !(hasEffect || isCircleText),
			}"
			@click="onClickEffects"
		>
			<span
				v-if="isMobile ? hasPreset : hasEffect || isCircleText"
				class="absolute top-0 right-2 z-10 h-2 w-2 rounded-full border border-blue-300 bg-blue-500 lg:top-0.5 lg:-left-0.5"
			></span>
			<SvgIcon name="effects" class="h-6 w-6 p-px sm:hidden" />
			<span class="text-2xs lg:mt-0 lg:text-sm" data-button-label>Effects</span>
		</button>
	</OnClickOutsideWithPortals>
</template>
