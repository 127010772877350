<script setup lang="ts">
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { toRef } from 'vue';

// Classes
import Element from '@/Classes/Element';
// Componentes
import NumberInput from '@/components/Common/NumberInput.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { useBugsnag } from '@/composables/useBugsnag';
// Types
import { Size } from '@/Types/types';
//Utils
import GAnalytics from '@/utils/GAnalytics';

// Custom types
type validInputKey = keyof Size | 'rotation';

// Props
const props = defineProps<{ element: Element; allowKeepProportion: boolean; allowHeight: boolean }>();
const element = toRef(props, 'element');

// Using composables
const { adjustTo, toggleKeepProportions, rotate } = useElementTransformOrchestrator(element).value;
Bugsnag.leaveBreadcrumb(props.element);
const { breadScrumbWithDebounce } = useBugsnag(element);
// Methods
const onUpdate = (key: validInputKey, val: number) => {
	if (key === 'rotation') {
		rotate(val, false);
		breadScrumbWithDebounce('rotation');
		return;
	}

	if (props.element.keepProportions) {
		adjustTo(key, val);
	} else {
		props.element.size[key] = val;
	}
	breadScrumbWithDebounce('size');
};
const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Size</h4>
	<div
		class="flex flex-col"
		:class="{
			'mb-6': props.allowKeepProportion,
			'mb-2': !props.allowKeepProportion,
		}"
	>
		<div class="mb-3 flex items-center gap-3">
			<div class="flex flex-col">
				<NumberInput
					class="h-10 text-sm"
					data-testid="input-width"
					:min="1"
					:value="element.size.width"
					@track-typing="trackTypingEvent('element-width')"
					@track-step="trackStepEvent('element-width')"
					@update="(width: number) => onUpdate('width', width)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Width</label>
			</div>
			<div v-if="allowHeight" class="flex flex-col">
				<NumberInput
					class="h-10 text-sm"
					data-testid="input-height"
					:min="1"
					:value="element.size.height"
					@track-typing="trackTypingEvent('element-height')"
					@track-step="trackStepEvent('element-height')"
					@update="(height: number) => onUpdate('height', height)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Height</label>
			</div>
			<div class="flex flex-col">
				<NumberInput
					class="h-10 text-sm"
					data-testid="input-rotation"
					:value="element.rotation"
					@track-typing="trackTypingEvent('element-rotation')"
					@track-step="trackStepEvent('element-rotation')"
					@update="(rotation: number) => onUpdate('rotation', rotation)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">Rotation</label>
			</div>
		</div>

		<div v-if="allowKeepProportion">
			<button
				class="relative flex h-4 w-4 cursor-pointer items-center justify-center rounded-sm border text-white"
				data-testid="button-keep-proportion"
				:class="{
					'border-gray-600 bg-transparent': !element.keepProportions,
					'border-blue-500 bg-blue-500 ': element.keepProportions,
				}"
				@click="toggleKeepProportions"
			>
				<SvgIcon v-if="element.keepProportions" name="check" class="h-3 w-3 fill-current"></SvgIcon>
				<label
					data-testid="keep-proportion"
					class="absolute top-0 left-0 ml-5 flex h-4 items-center whitespace-nowrap text-xs text-gray-100"
					>Keep proportion</label
				>
			</button>
		</div>
	</div>
</template>
