// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { computed, Ref } from 'vue';

// Classes
import Element from '@/Classes/Element';
import { Text } from '@/Classes/Text';
// Composables
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useArtboard } from '@/composables/project/useArtboard';
// Types
import { Anchor, Size } from '@/Types/types';
// Utils
import ElementTools from '@/utils/ElementTools';
import MathTools from '@/utils/MathTools';

export const useTransform = (element: Ref<Element>) => {
	const { artboardSizeInPx: sizeInPx } = useArtboard();

	const left = computed(() => (widthWithRotation.value - element.value.size.width) / 2);
	const top = computed(() => (heightWithRotation.value - element.value.size.height) / 2);
	const centerX = computed(() => sizeInPx.value.width / 2 - widthWithRotation.value / 2 + left.value);
	const centerY = computed(() => sizeInPx.value.height / 2 - heightWithRotation.value / 2 + top.value);
	const right = computed(() => sizeInPx.value.width - widthWithRotation.value + left.value);
	const bottom = computed(() => sizeInPx.value.height - heightWithRotation.value + top.value);

	const rotationInRadians = computed(() => MathTools.angleToRadians(element.value.rotation));

	const widthWithRotation = computed(() => {
		return (
			element.value.size.width * Math.abs(Math.cos(rotationInRadians.value)) +
			element.value.size.height * Math.abs(Math.sin(rotationInRadians.value))
		);
	});

	const heightWithRotation = computed(() => {
		return (
			element.value.size.height * Math.abs(Math.cos(rotationInRadians.value)) +
			element.value.size.width * Math.abs(Math.sin(rotationInRadians.value))
		);
	});

	const topLeftWithRotation = computed(() =>
		MathTools.getRotatedTopLeftCornerOfRect(
			element.value.position.x,
			element.value.position.y,
			element.value.size.width,
			element.value.size.height,
			element.value.rotation
		)
	);

	const isAlignLeft = computed(() => Math.abs(element.value.position.x - left.value) <= 1);
	const isAlignCenter = computed(() => Math.abs(element.value.position.x - centerX.value) < 1);
	const isAlignRight = computed(() => Math.abs(element.value.position.x - right.value) < 1);

	const isAlignTop = computed(() => Math.abs(element.value.position.y - top.value) < 1);
	const isAlignMiddle = computed(() => Math.abs(element.value.position.y - centerY.value) < 1);
	const isAlignBottom = computed(() => Math.abs(element.value.position.y - bottom.value) < 1);

	const isOutsidePage = computed(() => {
		const isOutsideLeft = element.value.position.x + widthWithRotation.value < left.value;
		const isOutsideRight = element.value.position.x - widthWithRotation.value > right.value;
		const isOutsideX = isOutsideLeft || isOutsideRight;

		const isOutsideTop = element.value.position.y + heightWithRotation.value < top.value;
		const isOutsideBottom = element.value.position.y - heightWithRotation.value > bottom.value;
		const isOutsideY = isOutsideTop || isOutsideBottom;

		return isOutsideX || isOutsideY;
	});

	const flipAxis = (axis: 'x' | 'y'): void => {
		element.value.flip[axis] = !element.value.flip[axis];
		Bugsnag.leaveBreadcrumb(`flip ${axis} axis to ${element.value.type}-${element.value.id}`);
	};

	const move = (x: number, y: number, relative = true): void => {
		if (relative) {
			element.value.position.x += x;
			element.value.position.y += y;
			return;
		}

		element.value.setPosition(x, y);
	};

	const scale = (scale: number) => {
		if (element.value.type === 'text') {
			(element.value as Text).scale *= scale;
		}

		element.value.size = {
			width: element.value.size.width * scale,
			height: element.value.size.height * scale,
		};
	};

	const resize = (width: number, height: number) => {
		element.value.setSize(width, height);
	};

	const rotate = (angle: number, relative = true) => {
		if (relative) {
			element.value.rotation += angle;
			return;
		}

		element.value.rotation = angle;
	};

	const align = (position: Anchor): void => {
		// Hay que tener en cuenta que el element.valueo puede estar rotado, asi que su punto 0,0 no es realmente el 0,0
		// sino que es la diferencia entre su tamaño con la rotación aplicada y su tamaño sin ella dividido entre 2
		// ya que son x pixeles por cada lado

		// POSITION X
		if ([Anchor.left, Anchor.topLeft, Anchor.leftCenter, Anchor.bottomLeft].includes(position)) {
			element.value.position.x = left.value;
		}

		if ([Anchor.centerX, Anchor.topCenter, Anchor.center, Anchor.bottomCenter].includes(position)) {
			element.value.position.x = centerX.value;
		}

		if ([Anchor.right, Anchor.topRight, Anchor.rightCenter, Anchor.bottomRight].includes(position)) {
			element.value.position.x = right.value;
		}

		// POSITION Y
		if ([Anchor.bottom, Anchor.bottomLeft, Anchor.bottomCenter, Anchor.bottomRight].includes(position)) {
			element.value.position.y = bottom.value;
		}

		if ([Anchor.top, Anchor.topLeft, Anchor.topCenter, Anchor.topRight].includes(position)) {
			element.value.position.y = top.value;
		}

		if ([Anchor.centerY, Anchor.leftCenter, Anchor.center, Anchor.topCenter].includes(position)) {
			element.value.position.y = centerY.value;
		}
		Bugsnag.leaveBreadcrumb(`Align ${element.value.type}-${element.value.id} to ${position}`);
	};

	const adjustToHeight = (height: number): void => {
		element.value.size = ElementTools.getSizeKeepingAspectRatioByHeight(element.value.size, height);
	};

	const adjustToWidth = (width: number): void => {
		element.value.size = ElementTools.getSizeKeepingAspectRatioByWidth(element.value.size, width);
	};

	const adjustTo = (side: keyof Size, val: number) => {
		if (side === 'width') {
			adjustToWidth(val);
		}

		if (side === 'height') {
			adjustToHeight(val);
		}
	};

	const toggleKeepProportions = () => {
		element.value.keepProportions = !element.value.keepProportions;

		const { toggleMiddleHandlers } = useInteractions();
		toggleMiddleHandlers();

		Bugsnag.leaveBreadcrumb(`${element.value.keepProportions ? 'Keep' : 'Unkeep'} proportion: ${element.value.type}-${element.value.id}`);
	};

	const fitElementRegardingFactor = (factor: number) => {
		// Dado que hacemos un reescalado falso en prod y el viewbox de los svgs no coincide con el tamaño
		// del artboard, hay que hacer una corrección usando la escala que se genera al comparar los tamaños
		scale(factor);

		element.value.position.x *= factor;
		element.value.position.y *= factor;
	};

	return {
		flipAxis,
		move,
		scale,
		resize,
		rotate,
		adjustTo,
		align,
		toggleKeepProportions,
		fitElementRegardingFactor,
		isAlignLeft,
		isAlignCenter,
		isAlignRight,
		isAlignTop,
		isAlignMiddle,
		isAlignBottom,
		isOutsidePage,
		left,
		top,
		heightWithRotation,
		widthWithRotation,
		topLeftWithRotation,
	};
};
