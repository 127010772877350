<script setup lang="ts">
// Classes
// Composables
import { toRef } from 'vue';

import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';

// Props
const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');

// Using composables
const { flipAxis } = useElementTransformOrchestrator(element).value;
</script>

<template>
	<button
		data-testid="flip-x"
		tooltip="Flip Horizontal"
		tooltip-position="top"
		class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-8 lg:justify-center"
		@click="flipAxis('x')"
	>
		<SvgIcon name="flip-hor" class="h-4 w-4 scale-90" />
		<span class="text-2xs lg:hidden">Flip Hor.</span>
	</button>
	<button
		data-testid="flip-y"
		class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-8 lg:justify-center"
		@click="flipAxis('y')"
	>
		<SvgIcon name="flip-ver" class="h-4 w-4 scale-90" />
		<span class="text-2xs lg:hidden">Flip Ver.</span>
	</button>
</template>
