<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{ prefix?: string; name: string }>();

const symbolId = computed(() => `#${props.prefix || 'icon'}-${props.name}`);
</script>

<template>
	<svg aria-hidden="true" class="pointer-events-none fill-current">
		<use :xlink:href="symbolId" />
	</svg>
</template>
