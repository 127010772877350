<script setup lang="ts">
import { computed, ref, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import TextBorderMenu from '@/components/selection/panels/TextBorderMenu.vue';
import TextCurve from '@/components/selection/panels/TextCurve.vue';
import TextShadowMenu from '@/components/selection/panels/TextShadowMenu.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useTextEffects } from '@/composables/element/text/useTextEffects';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels, TextEffects } from '@/Types/types';

const store = useMainStore();

const props = defineProps<{ element: Text; showFull?: boolean }>();

const element = toRef(props, 'element');
const scale = toRef(store, 'scale');
const curveBtn = ref();

const showTextOutlinePanel = computed(() => currentEffect.value === TextEffects.Outline);
const showTextShadowPanel = computed(() => currentEffect.value === TextEffects.Shadow);
const showTextCurvePanel = computed(() => currentEffect.value === TextEffects.Curve);

const { isCircleText } = useCircleTypeInfo(element, scale);
const { isMobile } = useDeviceInfo();
const {
	textEffectHandler,
	resetEffect,
	currentEffect,
	hasEcho,
	hasGlitch,
	hasNeon,
	hasOutline,
	hasMultiShadow,
	hasShadow,
	hasSticker,
} = useTextEffects(element);

/*
 Cuando estamos editando un texto el textEditing no va a refrescar el contenido ya que por defecto cuando tocamos el panel
 queremos mantener la selección.
 En estos casos no es así, por lo que quitamos el atributo data-keep-text-selection temporalmente para que actualice el content
 */
const refreshTextContentWhenEditing = () => {
	if (!store.textEditing) {
		return;
	}
	const divWithKeePSelection = curveBtn.value.closest('[data-keep-text-selection]');

	divWithKeePSelection?.removeAttribute('data-keep-text-selection');
	const editableDiv = document.querySelector<HTMLDivElement>(
		`#editable-${element.value ? element.value.id : undefined}`
	);
	if (editableDiv) {
		editableDiv.dispatchEvent(new Event('blur'));
	}
	divWithKeePSelection?.setAttribute('data-keep-text-selection', '');
};

const onClickEffect = (effect: TextEffects) => {
	refreshTextContentWhenEditing();
	textEffectHandler(effect);
};

const onCloseEditPanel = () => (store.editPanel = EditPanels.Text);

const onClickMoreEffects = () => {
	if (store.editPanel === EditPanels.TextEffects) {
		store.editPanel = isMobile.value ? EditPanels.Text : null;
		return;
	}

	store.editPanel = EditPanels.TextEffects;
};
</script>

<template>
	<div class="mb-6 hidden sm:block">
		<div class="mb-3 flex items-center justify-between">
			<h4 class="text-sm font-bold uppercase text-gray-100 opacity-75">{{ showFull ? 'Editable' : 'Effects' }}</h4>

			<button
				v-if="!showFull"
				class="text-sm font-semibold text-blue-500 hover:text-blue-600"
				@click="onClickMoreEffects"
			>
				More effects
			</button>
		</div>

		<div class="relative z-0 grid grid-cols-4 gap-3">
			<button
				data-testid="active-outline"
				class="group relative text-gray-100 hover:text-white"
				@click="onClickEffect(TextEffects.Outline)"
			>
				<div
					class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 sm:bg-gray-700"
					:class="{
						'text-white': hasOutline,
						'border-2 border-blue-500 text-white': hasOutline,
					}"
				>
					<SvgIcon name="outline" class="h-7 w-7" />
				</div>
				<button
					v-if="hasOutline"
					class="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white"
					@click.stop="resetEffect(TextEffects.Outline)"
				>
					<div tooltip="Remove border" tooltip-position="top" class="flex h-full w-full items-center justify-center">
						<SvgIcon name="cross" class="h-2.5 w-2.5" />
					</div>
				</button>
				<label class="mt-1 text-center text-xs">Outline</label>
			</button>

			<button
				data-testid="active-text-shadow"
				class="group relative text-gray-100 hover:text-white"
				@click="onClickEffect(TextEffects.Shadow)"
			>
				<div
					class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 sm:bg-gray-700"
					:class="{
						'text-white': hasShadow && !hasMultiShadow,
						'border-2 border-blue-500 text-white': hasShadow && !hasMultiShadow,
					}"
				>
					<SvgIcon name="text-shadow" class="h-7 w-7" />
				</div>
				<button
					v-if="hasShadow && !hasMultiShadow"
					class="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white"
					@click.stop="resetEffect(TextEffects.Shadow)"
				>
					<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
						<SvgIcon name="cross" class="h-2.5 w-2.5" />
					</div>
				</button>
				<label class="mt-1 text-center text-xs">Shadow</label>
			</button>

			<button
				ref="curveBtn"
				data-testid="active-curved-text"
				class="group relative text-gray-100 hover:text-white"
				@click="onClickEffect(TextEffects.Curve)"
			>
				<div
					class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 sm:bg-gray-700"
					:class="{
						'text-white': isCircleText,
						'border-2 border-blue-500 text-white': isCircleText,
					}"
				>
					<SvgIcon name="curved" class="h-7 w-7" />
				</div>
				<button
					v-if="isCircleText"
					class="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white"
					@click.stop="resetEffect(TextEffects.Curve)"
				>
					<div tooltip="Remove curve" tooltip-position="top" class="flex h-full w-full items-center justify-center">
						<SvgIcon name="cross" class="h-2.5 w-2.5" />
					</div>
				</button>
				<label class="mt-1 text-center text-xs">Curved</label>
			</button>
		</div>

		<TextBorderMenu v-if="showTextOutlinePanel" :element="element" />

		<TextShadowMenu v-if="showTextShadowPanel" :element="element" />

		<TextCurve v-if="showTextCurvePanel" :element="element" />
	</div>

	<div v-if="showFull" class="z-10 -mb-14 rounded-xl bg-gray-700 px-4 pt-4 pb-2 sm:bg-transparent sm:p-0">
		<div class="mb-3 hidden items-center justify-between sm:flex">
			<h4 class="text-sm font-bold uppercase text-gray-100 opacity-75">Presets</h4>
		</div>
		<div class="flex justify-end sm:hidden">
			<button
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="onCloseEditPanel"
			>
				<SvgIcon name="cross" class="h-3 w-3" />
			</button>
		</div>

		<div class="relative z-0 flex grid-cols-4 gap-3 overflow-auto pt-1 sm:-mx-4 sm:grid sm:overflow-hidden sm:px-4">
			<button
				data-testid="active-text-echo"
				class="group relative w-16 text-gray-100 hover:text-white sm:w-auto"
				@click="onClickEffect(TextEffects.Neon)"
			>
				<div
					class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 sm:bg-gray-700"
					:class="{
						'border-2 border-blue-500': hasNeon,
					}"
				>
					<SvgIcon name="neon" class="h-10 w-10" />
				</div>
				<button
					v-if="hasNeon"
					class="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white"
					@click.stop="resetEffect(TextEffects.Neon)"
				>
					<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
						<SvgIcon name="cross" class="h-2.5 w-2.5" />
					</div>
				</button>
				<label class="mt-1 text-center text-xs">Neon</label>
			</button>

			<button
				data-testid="active-text-echo"
				class="group relative w-16 text-gray-100 hover:text-white sm:w-auto"
				@click="onClickEffect(TextEffects.Glitch)"
			>
				<div
					class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 sm:bg-gray-700"
					:class="{
						'border-2 border-blue-500': hasGlitch,
					}"
				>
					<SvgIcon name="glitch" class="h-7 w-7" />
				</div>
				<button
					v-if="hasGlitch"
					class="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white"
					@click.stop="resetEffect(TextEffects.Glitch)"
				>
					<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
						<SvgIcon name="cross" class="h-2.5 w-2.5" />
					</div>
				</button>
				<label class="mt-1 text-center text-xs">Glitch</label>
			</button>

			<button
				data-testid="active-text-echo"
				class="group relative w-16 text-gray-100 hover:text-white sm:w-auto"
				@click="onClickEffect(TextEffects.Echo)"
			>
				<div
					class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 sm:bg-gray-700"
					:class="{
						'border-2 border-blue-500': hasEcho,
					}"
				>
					<SvgIcon name="echo" class="h-7 w-7" />
				</div>
				<button
					v-if="hasEcho"
					class="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white"
					@click.stop="resetEffect(TextEffects.Echo)"
				>
					<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
						<SvgIcon name="cross" class="h-2.5 w-2.5" />
					</div>
				</button>
				<label class="mt-1 text-center text-xs">Echo</label>
			</button>

			<button
				data-testid="active-text-sticker"
				class="group relative w-16 text-gray-100 hover:text-white sm:w-auto"
				@click="onClickEffect(TextEffects.Sticker)"
			>
				<div
					class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 sm:bg-gray-700"
					:class="{
						'border-2 border-blue-500': hasSticker,
					}"
				>
					<SvgIcon name="sticker" class="-mr-1 -mb-1 h-8 w-8" />
				</div>
				<button
					v-if="hasSticker"
					class="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white"
					@click.stop="resetEffect(TextEffects.Sticker)"
				>
					<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
						<SvgIcon name="cross" class="h-2.5 w-2.5" />
					</div>
				</button>
				<label class="mt-1 text-center text-xs">Sticker</label>
			</button>
		</div>
	</div>
</template>
