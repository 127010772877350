<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useAuth } from '@/composables/useAuth';
import useDownloadsProject from '@/composables/useDownloadsProject';
import { useEditorMode } from '@/composables/useEditorMode';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import GAnalytics from '@/utils/GAnalytics';

const emits = defineEmits(['share']);
const props = defineProps<{ selection: string[] }>();

const store = useMainStore();
const project = useProjectStore();
const toast = useToast();
const { requireAuth, isLogged } = useAuth();
const { share } = useDownloadsProject();
const { isPhotoMode } = useEditorMode();

const emails = ref('');

const isValidInstagramArtboard = computed(() => {
	const min = 4 / 5;
	const max = 1.91 / 1;
	const aspectRatio = project.size.width / project.size.height;

	return aspectRatio >= min && aspectRatio <= max;
});

const sendToEmails = async (ev: Event) => {
	ev.preventDefault();

	if (store.sharing) return;

	requireAuth();

	if (!isLogged || !store.userVector?.uuid) {
		toast.error('You must log in and save the template to send email');
		return;
	}

	const emailsArray = emails.value.split(',').map((email) => email.trim());

	try {
		await share('email', { emails: emailsArray }, props.selection);

		toast.success('Email sent');
		emails.value = '';
	} catch (error) {
		toast.error('Error sending email');
	}

	GAnalytics.track('share-by-mail', 'Template', project.name ? project.name : 'custom-artboard', null);
};

const shareModal = (socialNetwork: string) => {
	emits('share', socialNetwork);

	GAnalytics.track(`publish-${socialNetwork}`, 'Template', project.name ? project.name : 'custom-artboard', null);
};
</script>
<template>
	<div>
		<p class="mb-4 text-lg font-semibold text-gray-700">Publish your design</p>
		<div class="mb-4 flex items-start gap-2 xl:gap-4">
			<div
				class="relative flex flex-col items-center text-gray-700"
				:class="{
					'hover:text-blue-500': isValidInstagramArtboard,
					'hover:text-gray-700': !isValidInstagramArtboard,
				}"
			>
				<button
					class="mb-2 flex h-14 w-14 items-center justify-center rounded"
					:class="{
						'hover:opacity-90': isValidInstagramArtboard,
						'pointer-events-none opacity-25': !isValidInstagramArtboard,
					}"
					style="background-image: linear-gradient(135deg, #405DE6, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #F77737, #FCAF45, #FFDC80"
					:disabled="!isValidInstagramArtboard"
					@click="() => shareModal('instagram')"
				>
					<SvgIcon name="instagram" class="h-6 w-6 text-white" />
				</button>
				<p
					class="hidden text-center text-xs font-semibold leading-4 lg:block"
					:class="{
						'opacity-25': !isValidInstagramArtboard,
					}"
				>
					Instagram <br />
					Business
				</p>
				<div v-if="!isValidInstagramArtboard" class="group absolute -top-1 -right-1 cursor-help">
					<SvgIcon name="alert" class="h-5 w-5 rounded-full bg-red-500 p-px text-white" />
					<div
						class="pointer-events-none absolute top-0 left-full ml-1 w-32 rounded bg-white p-2 text-xs opacity-0 shadow-lg transition-opacity duration-300 group-hover:opacity-100"
					>
						Image size is not valid, the proportion must be between 4:5 and 1.91:1
					</div>
				</div>
			</div>
			<div class="flex flex-col items-center text-gray-700 hover:text-blue-500">
				<button
					class="mb-2 flex h-14 w-14 items-center justify-center rounded bg-[#4267B2] hover:opacity-90"
					@click="() => shareModal('facebook')"
				>
					<SvgIcon name="facebook" class="h-6 w-6 text-white" />
				</button>
				<p class="hidden text-center text-xs font-semibold leading-4 lg:block">Facebook <br />Page</p>
			</div>
			<div class="flex flex-col items-center text-gray-700 hover:text-blue-500">
				<button
					class="mb-2 flex h-14 w-14 items-center justify-center rounded bg-[#E60023] hover:opacity-90"
					@click="() => shareModal('pinterest')"
				>
					<SvgIcon name="pinterest" class="h-6 w-6 text-white" />
				</button>
				<p class="hidden text-center text-xs font-semibold leading-4 lg:block">Pinterest</p>
			</div>
			<div class="flex flex-col items-center text-gray-700 hover:text-blue-500" @click="() => shareModal('twitter')">
				<button class="mb-2 flex h-14 w-14 items-center justify-center rounded bg-[#03A9F4] hover:opacity-90">
					<SvgIcon name="twitter" class="h-6 w-6 text-white" />
				</button>
				<p class="hidden text-center text-xs font-semibold leading-4 lg:block">Twitter</p>
			</div>
		</div>
		<template v-if="!isPhotoMode">
			<p class="mb-1 font-semibold text-gray-800">Send by email</p>
			<form class="mb-1 flex w-full" @submit="sendToEmails">
				<input
					v-model="emails"
					type="mail"
					class="h-10 w-full rounded-tl rounded-bl border-l border-t border-b border-gray-100 px-3 text-gray-700 placeholder:text-gray-300 focus:border-blue-500 focus:outline-none lg:h-12"
					placeholder="info@wepik.com"
					required
					minlength="3"
				/>
				<button
					type="submit"
					class="h-10 rounded-tr rounded-br bg-blue-500 px-6 font-semibold text-white hover:bg-blue-600 lg:h-12 lg:px-8"
				>
					<div v-if="!store.sharing">Send</div>
					<SvgIcon v-if="store.sharing" data-testid="spinner" name="spinner" class="mr-1 h-7 w-7 animate-spin" />
				</button>
			</form>
			<p class="text-sm font-semibold text-gray-600/75">Use comma to add 2 or more emails. Max 10 emails</p>
		</template>
	</div>
</template>
