<script setup lang="ts">
import { ref } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useAdminActions } from '@/composables/useAdminActions';
import { useEditorMode } from '@/composables/useEditorMode';
import { useToast } from '@/composables/useToast';
import { useProjectStore } from '@/stores/project';

const saving = ref<boolean>(false);

const { updateTemplate } = useAdminActions();
const toast = useToast();
const { isIllustratorContext } = useEditorMode();
const project = useProjectStore();

const promptUpdateReason = async () => {
	if (saving.value) return Promise.resolve();

	const hasIllustratorElement = project.pages[0].elements.some((el) => el.type === 'illustrator');

	if (isIllustratorContext.value && hasIllustratorElement) {
		toast.error('Para poder guardar tienes que terminar de modificar la plantilla de illustrator');
		return Promise.resolve();
	}

	const reason = prompt('Indica en un comentario los cambios realizados');

	// If the user clicks OK without entering any text, an empty string is returned.
	// If the user clicks the Cancel button, this function returns null.
	if (reason === null) return Promise.resolve();

	saving.value = true;
	try {
		await updateTemplate(reason);
		toast.success('Template updated!');
	} catch (e) {
		toast.error('Error to save template');
	}
	saving.value = false;
};
</script>

<template>
	<div>
		<button
			class="relative flex h-10 w-8 items-center justify-center rounded bg-yellow-500 font-bold text-white hover:bg-yellow-600 lg:w-auto lg:py-3 lg:px-6"
			@click="promptUpdateReason"
		>
			<span v-if="saving">
				<SvgIcon name="spinner" class="h-4 w-4 animate-spin" />
			</span>
			<span v-else>Update template</span>
		</button>
	</div>
</template>
