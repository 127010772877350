<script setup lang="ts">
import Image from '@/Classes/Image';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();
const { isMobile } = useDeviceInfo();

defineProps<{
	element: Image;
}>();

const onClickEditMask = () => {
	if (store.editPanel === EditPanels.Mask) {
		store.editPanel = isMobile.value ? EditPanels.Image : null;
		return;
	}

	store.editPanel = EditPanels.Mask;
};
</script>

<template>
	<div>
		<button
			v-if="!element.locked"
			tooltip="Mask"
			tooltip-position="top"
			data-button-importance="normal"
			data-testid="mask"
			class="relative flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
			:class="element.mask ? 'text-white' : ''"
			@click="onClickEditMask"
		>
			<span
				v-if="element.mask"
				class="absolute -top-0.5 right-4 z-10 h-2 w-2 rounded-full border border-blue-300 bg-blue-500 lg:top-0.5 lg:left-[1.2rem]"
			></span>
			<SvgIcon name="mask" class="h-4 w-4 scale-110 lg:scale-100" />
			<span class="text-2xs lg:mt-0 lg:ml-2 lg:text-sm" data-button-label>Masks</span>
		</button>
	</div>
</template>
