import { computed, Ref } from 'vue';

import Element from '@/Classes/Element';
import Image from '@/Classes/Image';
import { useImage } from '@/composables/element/image/useImage';
import { useImageOrder } from '@/composables/element/image/useImageOrder';
import { useElementOrder } from '@/composables/element/useElementOrder';
import { useGroupOrder } from '@/composables/group/useGroupOrder';

export const useElementOrderOrchestrator = (element: Ref<Element>) => {
	return computed(() => {
		if (element.value.group) {
			return useGroupOrder(element);
		}

		if (element.value instanceof Image) {
			const { isBackground } = useImage(element as Ref<Image>);
			return useImageOrder(element as Ref<Image>, isBackground);
		}

		return useElementOrder(element);
	});
};
