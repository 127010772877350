import { useWheel } from '@vueuse/gesture';

import { useZoom } from './useZoom';

export const useEditorMouseEvents = () => {
	const { decreaseZoom, increaseZoom } = useZoom();

	useWheel(
		(e) => {
			const { event, movement } = e;
			const wheelDeltaY = movement[1];

			if (e.ctrlKey) {
				event.preventDefault();
				wheelDeltaY > 0 && decreaseZoom(event);
				wheelDeltaY < 0 && increaseZoom(event);
			}
		},
		{
			domTarget: window,
			eventOptions: { passive: false },
		}
	);
};
