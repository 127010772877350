<script lang="ts" setup>
import { ref } from 'vue';

//CLasses
import Page from '@/Classes/Page';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Viewer from '@/components/export/Viewer.vue';
// Components
import Canvas from '@/components/render/Canvas.vue';
// Composables
import { useProjectStore } from '@/stores/project';

// Using composables
const project = useProjectStore();
const present = ref(false);
const activePage = ref(0);
</script>

<template>
	<div class="flex w-full flex-col items-center gap-8">
		<Canvas class="z-10 shadow-xl" preview :page="(project.pages[0] as Page)" />
		<button
			class="relative z-10 flex h-8 w-8 items-center justify-center overflow-hidden rounded bg-blue-500 font-bold text-white hover:bg-blue-600 lg:h-10 lg:w-auto lg:py-3 lg:px-6"
			@click="present = true"
		>
			<SvgIcon name="presentation" class="mr-3 h-4 w-4" />
			Present
		</button>
		<teleport to="body">
			<div v-if="present" class="fixed inset-0 z-50 bg-black">
				<Viewer
					:project="(project as any)"
					fullscreen
					class="h-screen w-screen"
					loaded
					:page="activePage"
					@fullscreenExit="present = false"
					@activePage="activePage = $event"
				/>
			</div>
		</teleport>
	</div>
</template>
