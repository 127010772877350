import { MaybeRef } from '@vueuse/core';
import { ref, watch } from 'vue';

/**
 * Ya que el flexbox con overflow scroll requiere tener un tamaño fijo
 * este hook hace que el elemento reserve el espacio que ocupa inicialmente sin
 * los hijos.
 * @param domElement
 */
export const useAvailableHeight = (domElement: MaybeRef<HTMLElement>) => {
	const element = ref(domElement);

	watch(
		element,
		() => {
			if (!element.value) {
				return;
			}

			// le quitamos el overflow auto y le quitamos el height, ocultamos sus hijos
			element.value.classList.remove('overflow-y-auto');
			element.value.style.height = '';
			(Array.from(element.value.children) as HTMLElement[]).forEach((el: HTMLElement) => (el.style.display = 'none'));

			// El alto que ocuparia sin contenido
			const { height } = element.value.getBoundingClientRect();

			// Le fijamos su alto original
			element.value.style.height = `${height}px`;
			// volvemos a mostrar su contenido
			(Array.from(element.value.children) as HTMLElement[]).forEach((el: HTMLElement) => (el.style.display = ''));
			element.value.classList.add('overflow-y-auto');
		},
		{ immediate: true }
	);
};
