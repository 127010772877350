<script setup lang="ts">
import { Sketch } from '@ckpack/vue-color';
import { ref } from 'vue';

import { SolidColor } from '@/Classes/SolidColor';
import ColorPickerOpacity from '@/components/Common/Color/ColorPickerOpacity.vue';
import ColorsPalette from '@/components/Common/Color/ColorsPalette.vue';
import EyeDrop from '@/components/Common/EyeDrop.vue';
import { useMountedState } from '@/composables/useMountedState';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ color: SolidColor; hideAlpha?: boolean; hideGradient?: boolean }>();
const emit = defineEmits<{ (e: 'change', color: Color): void }>();

// Ya que el eye drop tiene que ir cuando este montado el sketch, lo retrasamos
const ready = useMountedState();
// Necesitamos mantener una copia del DTO que emite la libreria para que se entere de actualizaciones en el hue cuando
// estamos sobre un color blanco o negro que está presente en cualquier hue
const sketchModel = ref(props.color);

const emitColor = (event: { rgba: { r: number; g: number; b: number; a: number } }) => {
	const color = SolidColor.fromObject(event.rgba);

	sketchModel.value = color;
	emit('change', color);
};

const refreshColor = (event: Color) => {
	sketchModel.value = event;
	emit('change', event);
};
</script>

<template>
	<div ref="root" class="custom-picker">
		<Sketch :model-value="sketchModel" :preset-colors="[]" @update:model-value="emitColor" />
		<Teleport v-if="ready" to=".vc-sketch-controls">
			<EyeDrop @change="refreshColor" />
		</Teleport>
		<ColorPickerOpacity v-if="ready && !props.hideAlpha" :color="color" @change="refreshColor" />
		<ColorsPalette v-if="ready" :selected="color" :hide-gradient="hideGradient" @change="refreshColor" />
	</div>
</template>

<style lang="sass">
.custom-picker .vc-sketch
  @apply bg-transparent w-auto shadow-none p-0 flex flex-col relative

  @media (max-width: 767px)
    @apply bg-transparent shadow-none

  & > div[class*="vc-sketch"]
    @apply order-2

  .vc-sketch-saturation-wrap
    @apply rounded-xl lg:rounded mb-2
    padding-bottom: 65%

  @media (max-width: 1023px)
    .vc-sketch-saturation-wrap
      padding-bottom: 25%
    .vc-sketch-controls + .vc-sketch-field
      display: none

    .vc-saturation-circle
      @apply h-4 w-4 -translate-x-2 -translate-y-2 border-2 border-white shadow-none


  .vc-sketch-controls
    @apply items-center

  .vc-sketch-sliders
    @apply p-0 mr-2

    .vc-sketch-hue-wrap
      @apply h-2

      @media (max-width: 767px)
        @apply h-3

      .vc-hue
        @apply rounded-full h-2

        @media (max-width: 767px)
          @apply h-3

        .vc-hue-picker
          @apply w-2 w-2 rounded-full mt-0

          @media (max-width: 767px)
            @apply w-3 h-3

    .vc-sketch-alpha-wrap
      @apply hidden

  .vc-sketch-color-wrap
    @apply m-0 rounded-full

    @media (max-width: 767px)
      @apply w-10 h-10

    .vc-sketch-active-color
      @apply rounded-full shadow-none border border-gray-800

    .vc-checkerboard
      @apply rounded-full

  .vc-sketch-field
    @apply grid grid-cols-6 gap-1

    &--double
      @apply col-span-2

    &--single
      @apply p-0

    .vc-input__input
      @apply bg-gray-900 rounded shadow-none px-1 h-5 text-2xs text-gray-100 w-full text-center

    .vc-input__label
      @apply text-gray-300 text-2xs text-center pb-0 pt-1 justify-center

  .vc-sketch-presets
    @apply border-t border-gray-700 mx-0 mt-2 pt-2 pl-0 grid grid-cols-6 gap-2

    @media (max-width: 767px)
      @apply flex overflow-auto border-gray-600

    .vc-sketch-presets-color
      @apply m-0 w-full aspect-square rounded-full h-auto

      &::before
        content: ''
        @apply absolute inset-0 -z-10 rounded-full
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==")

      @media (max-width: 767px)
        @apply w-8 h-8 shrink-0
</style>
