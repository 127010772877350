// Bugsnag
import Bugsnag from '@bugsnag/js';
import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { computed, nextTick, Ref } from 'vue';

import Image from '@/Classes/Image';
import { useCommon } from '@/composables/element/common/useCommon';
import { useImageTransform } from '@/composables/element/image/useImageTransform';
import { useAuth } from '@/composables/useAuth';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMainStore } from '@/stores/store';
import { ImageApi } from '@/Types/apiClient';
import { Position, Size } from '@/Types/types';
import { ApiClient } from '@/utils/api';
import GAnalytics from '@/utils/GAnalytics';
import ImageTools from '@/utils/ImageTools';
import MathTools from '@/utils/MathTools';
import TemplateLoader from '@/utils/TemplateLoader';

export const useImage = (image: Ref<Image>) => {
	const elementCommon = useCommon(image);
	const { fitAndCenterInArtboard, fitAndCenterOnReplace, replacePhotoModeImage } = useImageTransform(image);
	const { isPhotoMode } = useEditorMode();
	const { page } = elementCommon;

	const isBase64 = computed(() => image.value.url.startsWith('data:image'));
	const store = useMainStore();

	const isBackground = computed(() => image.value.locked && image.value.id === page.value?.backgroundImageId);

	const isLandscape = computed(() => image.value.size.width >= image.value.size.height);

	const isPlaceholder = computed(() => image.value.url.includes('svg/mask-placeholder.svg'));

	const ratio = computed(() => image.value.size.width / image.value.size.height);

	/**
	 * Replaces the image by the given image
	 * @param apiImage
	 */
	const replace = async (apiImage: ImageApi) => {
		const isPhotoModeImage = image.value.id === page.value?.backgroundImageId;
		// Unlock image temporarily to set position and size on replace
		const wasLocked = image.value.locked;
		if (image.value.locked) image.value.setLocked(false);

		image.value.url = apiImage.url || apiImage.preview;
		image.value.flip.x = false;
		image.value.flip.y = false;
		image.value.preview = apiImage.preview;
		image.value.metadata = apiImage.metadata || {};

		isPhotoMode.value && isPhotoModeImage && wasLocked
			? await replacePhotoModeImage(apiImage)
			: await fitAndCenterOnReplace(apiImage);

		if (wasLocked) image.value.setLocked(true);
		Bugsnag.leaveBreadcrumb(`Replace image-${image.value.id}: ${apiImage.url}`);

		image.value.urlBackgroundRemoved = apiImage.backgroundRemoved || null;
		image.value.backgroundMode = 'original';
	};

	const adjustToNewArtboard = (zeroPosition: Position, centeredPosition: Position, scale: number) => {
		// Si la imagen está como fondo tenemos que ajustarla de forma diferente
		if (isBackground.value) {
			image.value.setLocked(false);
			fitAndCenterInArtboard();
			image.value.setLocked(true);
		} else {
			elementCommon.adjustToNewArtboard(zeroPosition, centeredPosition, scale);
		}
	};

	const removeBackground = async () => {
		if (image.value.urlBackgroundRemoved) {
			return -1;
		}
		Bugsnag.leaveBreadcrumb('Loading remove background');
		let response = null;

		if (!isPhotoMode.value) {
			const body = {
				url: image.value.url,
				userUpload: image.value.metadata.uploadId,
			};

			response = await ApiClient.request(
				`${import.meta.env.VITE_APP_API_PATH}remove-background`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
					},
					// @ts-ignore
					body,
				},
				true
			);
		} else {
			const body = {
				image: await ImageTools.urlToBase64(image.value.url),
			};

			response = await ApiClient.request(
				`${import.meta.env.VITE_APP_API_PATH}remove-background-anonymous`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
					},
					// @ts-ignore
					body,
				},
				true
			);
		}

		if (response.status >= 400) {
			if (response.status !== 429) {
				GAnalytics.track('click', 'Button', 'remove-background-error', null);
				throw new Error('Error while removing background');
			}

			throw new Error(`You've reached background removals for today`);
		}

		if (!isPhotoMode.value) {
			const apiImage = (await response.json()) as ImageApi;

			if (!apiImage.backgroundRemoved) {
				GAnalytics.track('click', 'Button', 'remove-background-error', null);
				throw new Error('Error while removing background');
			}

			image.value.url = apiImage.url || apiImage.preview;
			image.value.preview = apiImage.preview;
			image.value.metadata = apiImage.metadata || {};
			image.value.urlBackgroundRemoved = apiImage.backgroundRemoved;
		} else {
			const url = window.URL.createObjectURL(await response.blob());
			image.value.urlBackgroundRemoved = url;
		}

		const remainingRemovals = parseInt(response.headers.get('X-RateLimit-Remaining')) || 'Unlimited';

		GAnalytics.track('click', 'Button', isPhotoMode ? 'remove-bg-photoeditor' : 'remove-background', null);

		return remainingRemovals;
	};

	const separateLayers = async () => {
		if (!image.value.urlBackgroundRemoved) {
			throw new Error('Cannot separate layers without background removed');
		}

		const cloned = TemplateLoader.unserializeElement(cloneDeep(image.value)) as Image;
		cloned.id = uuidv4();

		image.value.backgroundMode = 'background';
		cloned.backgroundMode = 'foreground';
		cloned.locked = false;
		cloned.filter = null;

		const corners = await ImageTools.findTransparentCorners(cloned.urlBackgroundRemoved as string);
		// Calculamos cuanto tenemos que recortar por cada lado(valores sobre tamaño real de la imagen)
		const cropByLeft = corners.width - corners.left;
		const cropByRight = corners.width - corners.right;
		const cropByTop = corners.height - corners.top;
		const cropByBottom = corners.height - corners.bottom;
		const cropX = cropByLeft - cropByRight;
		const cropY = cropByTop - cropByBottom;

		// Calculamos el crop en el tamaño de nuestra imagen, nuestra referencia siempre va a ser el tamaño de la imagen completa
		// por lo que si tiene crop cogemos de ahí el dato
		const cropWidth = MathTools.ruleOfThree(
			corners.width,
			cropX,
			cloned.hasCrop() ? cloned.crop.size.width : cloned.size.width
		);
		const cropHeight = MathTools.ruleOfThree(
			corners.height,
			cropY,
			cloned.hasCrop() ? cloned.crop.size.height : cloned.size.height
		);

		// Guardamos el tamaño de la imagen sin crop
		cloned.crop.size.width = cloned.hasCrop() ? cloned.crop.size.width : cloned.size.width;
		cloned.crop.size.height = cloned.hasCrop() ? cloned.crop.size.height : cloned.size.height;
		// Aplicamos crop a la imagen
		cloned.size.width = cropWidth;
		cloned.size.height = cropHeight;

		// Si la imagen original tiene crop tenemos que sumarle a la posición cuanto está desplazada respecto a la original
		// para tener nuestro clone en el mismo x,y que la original
		if (cloned.hasCrop()) {
			cloned.position.x += cloned.crop.position.x;
			cloned.position.y += cloned.crop.position.y;
		}

		// colocamos el elemento visible del clone dentro de nuestro crop
		cloned.crop.position.x = -MathTools.ruleOfThree(corners.width, corners.left, cloned.crop.size.width);
		cloned.crop.position.y = -MathTools.ruleOfThree(corners.height, corners.top, cloned.crop.size.height);
		// movemos la imagen lo mismo que la hemos desplazado para que visualmente encaje con la imagen que estamos copiando
		cloned.position.x += cloned.crop.position.x * -1;
		cloned.position.y += cloned.crop.position.y * -1;
		page.value.elements.push(cloned);

		// En caso de que la imagen tenga rotación el rotate se va aplicar desde distintos centro ya que las imagenes no tiene el mismo size
		// tenemos que corregir la posición
		if (cloned.rotation) {
			await nextTick();
			const boundingImage = image.value.domNode()?.querySelector('img')?.getBoundingClientRect();
			const boundingClone = cloned.domNode()?.querySelector('img')?.getBoundingClientRect();

			if (boundingImage && boundingClone) {
				const originalX = boundingImage.x;
				const originalY = boundingImage.y;
				const clonedX = boundingClone.x;
				const clonedY = boundingClone.y;

				const diffX = Math.abs(originalX - clonedX) / store.scale;
				// Para corregirlo usamos la diferencia entre las x e y de los bounding de la img ( que siempre tiene el tamaño original)
				// y dependiendo del angulo de rotación le hacemos la correción que necesita
				const diffY = Math.abs(originalY - clonedY) / store.scale;
				const rotation = cloned.rotation;
				let realAngle = rotation;
				if (rotation > 360 || rotation < -360) {
					const angle = Math.round(rotation / 360) * 360;
					realAngle = realAngle - angle;
				}
				if (realAngle > 0 && realAngle <= 180) {
					cloned.position.x -= diffX;
					cloned.position.y += diffY;
				}

				if (realAngle > 180 && realAngle <= 360) {
					cloned.position.x -= diffX;
					cloned.position.y -= diffY;
				}

				if (realAngle < 0 && realAngle >= -180) {
					cloned.position.x -= diffX;
					cloned.position.y -= diffY;
				}

				if (realAngle < -180 && realAngle >= -360) {
					cloned.position.x -= diffX;
					cloned.position.y += diffY;
				}
			}
		}

		store.setSelection(cloned);
	};

	return {
		...elementCommon,
		isBackground,
		isLandscape,
		isPlaceholder,
		isBase64,
		ratio,
		replace,
		adjustToNewArtboard,
		removeBackground,
		separateLayers,
	};
};
