<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { until } from '@vueuse/core';
import sysend from 'sysend';
import { computed, onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';

import Page from '@/Classes/Page';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useArtboard } from '@/composables/project/useArtboard';
import { useAuth } from '@/composables/useAuth';
import useDownloadsProject from '@/composables/useDownloadsProject';
import { useShare } from '@/composables/useShare';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import MathTools from '@/utils/MathTools';

const props = defineProps<{ pages: Page[] }>();
const emits = defineEmits(['close', 'goBack']);

const loading = ref(false);
const errors = ref<string[]>([]);
const instagramPages = ref([]);
const selectedInstagramPage = ref<any>(null);
const selectedTemplatePage = ref<any>(null);
const message = ref('');
const oauthWindow = ref<Window | null>(null);
const authenticated = ref(false);

const { share } = useDownloadsProject();
const store = useMainStore();
const project = useProjectStore();
const toast = useToast();
const { artboardSizeInPx } = useArtboard();
const { requireAuth, isLogged, user } = useAuth();
const { checkFacebook, executeCheckFacebook, authenticatedInFacebook, getInstagramAccounts, logoutFacebook } =
	useShare();

const dropdownInstagramPagesOptions = computed(() => {
	return instagramPages.value.map((page: any) => ({
		label: page.name,
		value: page.id,
	}));
});

const dropdownTemplatePages = computed(() => {
	return props.pages.map((page: Page) => {
		const index = project.pages.findIndex((p) => p.id === page.id);

		return {
			label: `Page ${index + 1}`,
			value: project.pages[index].id,
		};
	});
});

const closeModal = () => {
	if (store.sharing) return;
	emits('close');
};

const goBack = () => {
	if (store.sharing) return;
	emits('goBack');
};

const checkAuthentication = async () => {
	if (!isLogged.value) {
		return;
	}

	if (authenticatedInFacebook.value) {
		authenticated.value = true;
	}

	if (!authenticated.value) {
		await executeCheckFacebook();

		if (checkFacebook.value.status && user.value) {
			authenticated.value = true;
			user.value.tokens.facebook = {
				status: true,
				token: checkFacebook.value.token,
			};
		}
	}

	if (authenticated.value) {
		const pagesRes = await getInstagramAccounts();
		instagramPages.value = pagesRes.value;

		if (pagesRes.value.length > 0) {
			selectedInstagramPage.value = pagesRes.value[0].id;
			selectedTemplatePage.value = dropdownTemplatePages.value[0].value;
		}
	}

	loading.value = false;
};

const initInstagram = async () => {
	if (store.sharing || loading.value) return;

	if (!isLogged.value) {
		requireAuth();
		await until(isLogged).toBeTruthy();
	}

	loading.value = true;

	sysend.on('oauth:facebook', async () => {
		oauthWindow.value?.close();
		await checkAuthentication();

		if (authenticated.value) {
			sysend.off('oauth:facebook');

			toast.success('Facebook account linked successfully!');
		}
	});

	oauthWindow.value = window.open(
		`${import.meta.env.VITE_APP_BASE}auth/provider?provider=facebook`,
		`Login in Facebook`,
		'height=800,width=700'
	);
};

const shareInInstagram = async () => {
	if (store.sharing) return;

	errors.value = [];

	if (!selectedInstagramPage.value) {
		errors.value.push('Please select a instagram page');
	}

	if (!selectedTemplatePage.value) {
		errors.value.push('Please select at least one page');
	}

	if (errors.value.length) {
		return;
	}

	const page: any = instagramPages.value.find((fbPage: any) => fbPage.id == selectedInstagramPage.value);

	const params = {
		facebook_page_id: page.id,
		facebook_message: message.value,
	};

	try {
		await share('instagram', params, [selectedTemplatePage.value]);

		toast.success('Your design has been published on Instagram');
		closeModal();
	} catch (error) {
		toast.error('Your design has not been uploaded on Instagram');
	}
};

const logout = async () => {
	await logoutFacebook();
	instagramPages.value = [];
	authenticated.value = false;
};

onMounted(async () => {
	await checkAuthentication();
});
</script>
<template>
	<Modal open content-classes="w-full max-w-lg" @close="closeModal">
		<div class="relative max-h-full w-full overflow-auto rounded-lg bg-white py-4 px-4 lg:px-8 lg:pt-8 lg:pb-8">
			<button class="absolute top-2 left-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25" @click="goBack">
				<SvgIcon name="right-small" class="h-5 w-5 rotate-180 text-gray-700" />
			</button>
			<button
				class="absolute top-2 right-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
				@click="closeModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
			</button>
			<div v-if="!authenticated" class="flex flex-col items-center">
				<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800 lg:px-0">
					Publish your creations on Instagram Business!
				</h3>
				<p class="text-center text-gray-600">Connect your social media and share content quickly and easily</p>
				<ul class="mt-6 mb-8 flex flex-col gap-4 px-6 text-gray-600 lg:px-12">
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-black text-blue-100/50">1</span>
						<p class="relative z-10">
							Make your Instagram account into a Business Account
							<a
								href="https://help.instagram.com/502981923235522"
								target="_blank"
								class="font-semibold text-blue-500 hover:underline"
							>
								Learn how
							</a>
						</p>
					</li>
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-black text-blue-100/50">2</span>
						<p class="relative z-10">
							Link it with your Facebook Page
							<a
								href="https://help.instagram.com/570895513091465"
								target="_blank"
								class="font-semibold text-blue-500 hover:underline"
							>
								Learn how
							</a>
						</p>
					</li>
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-black text-blue-100/50">3</span>
						<p class="relative z-10">Now everything's connected and ready to publish your designs!</p>
					</li>
				</ul>
				<button class="rounded bg-blue-500 py-3 px-6 font-semibold text-white hover:bg-blue-600" @click="initInstagram">
					<span v-if="!loading">Connect via Facebook</span>
					<SvgIcon v-if="loading" name="spinner" class="mx-auto h-7 w-7 animate-spin" />
				</button>
			</div>
			<div v-if="authenticated && instagramPages.length" class="flex flex-col">
				<h3 class="mb-4 flex items-center justify-center text-xl font-semibold text-gray-800">
					<span
						class="mr-2 flex h-8 w-8 items-center justify-center rounded-full"
						style="background-image: linear-gradient(135deg, #405DE6, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #F77737, #FCAF45, #FFDC80"
					>
						<SvgIcon name="instagram" class="h-4 w-4 text-white" />
					</span>
					Instagram Business
				</h3>
				<div class="mb-4 flex flex-col gap-4 lg:flex-row">
					<div class="w-full">
						<label class="mb-2 block font-semibold text-gray-600">Instagram account</label>
						<Multiselect
							v-model="selectedInstagramPage"
							mode="single"
							placeholder="Instagram page"
							:classes="{
								container: 'multiselect text-gray-700',
								containerActive: 'ring ring-blue-500 ring-opacity-30',
								tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
								groupLabelSelected: 'bg-blue-600 text-white',
								groupLabelSelectedPointed: 'bg-blue-600 text-white opacity-90',
								groupLabelSelectedDisabled: 'text-blue-100 bg-blue-600 bg-opacity-50 cursor-not-allowed',
								optionSelected: 'text-blue-500 font-semibold opacity-100 bg-gray-100/50',
								optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
								optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
							}"
							:hide-selected="false"
							:can-clear="false"
							:options="dropdownInstagramPagesOptions"
						/>
					</div>
					<div class="w-full">
						<label class="mb-2 block font-semibold text-gray-600">Page selected</label>
						<Multiselect
							v-model="selectedTemplatePage"
							mode="single"
							placeholder="Template pages"
							:classes="{
								container: 'multiselect text-gray-700',
								containerActive: 'ring ring-blue-500 ring-opacity-30',
								tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
								groupLabelSelected: 'bg-blue-600 text-white',
								groupLabelSelectedPointed: 'bg-blue-600 text-white opacity-90',
								groupLabelSelectedDisabled: 'text-blue-100 bg-blue-600 bg-opacity-50 cursor-not-allowed',
								optionSelected: 'text-blue-500 font-semibold opacity-100 bg-gray-100/50',
								optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
								optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
							}"
							:hide-selected="false"
							:can-clear="false"
							:options="dropdownTemplatePages"
						>
							<template #option="{ option }">
								<div class="flex w-full items-center justify-between text-sm">
									<div>{{ option.label }}</div>
									<div class="flex h-6 w-12 items-center">
										<Canvas
											preview
											:page="project.pages.find((p) => p.id === option.value)"
											:use-parent-size="{
												height: artboardSizeInPx.height,
												width: artboardSizeInPx.width,
												scale: MathTools.ruleOfThree(artboardSizeInPx.height, 1, 30),
											}"
										/>
									</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<label class="mb-1 block font-semibold text-gray-600">
					Write a message
					<span class="text-sm text-gray-300">(optional)</span>
				</label>
				<div class="relative mb-4">
					<textarea
						v-model="message"
						maxlength="2000"
						class="texts-gray-700 h-52 w-full resize-none rounded border border-gray-200 px-4 pt-4 pb-6"
					></textarea>
					<span v-if="message.length" class="absolute bottom-2 right-1 bg-white px-2 py-1 text-xs text-gray-100"
						>({{ message.length }}/2000)
					</span>
				</div>

				<div v-for="error in errors" class="mb-4">
					<p class="text-sm font-semibold text-red-500">- {{ error }}</p>
				</div>

				<button
					class="rounded py-3 px-6 font-semibold text-white"
					:class="{
						'bg-blue-500 hover:bg-blue-600': !store.sharing,
						'pointer-events-none bg-blue-600': store.sharing,
					}"
					@click="shareInInstagram"
				>
					<span v-if="!store.sharing">Publish</span>
					<span v-else class="flex items-center justify-center">
						<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin" />
						Publishing
					</span>
				</button>
			</div>

			<div v-if="authenticated && !instagramPages.length" class="flex flex-col items-center">
				<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800 lg:px-0">
					You account is not an Instagram Business
				</h3>
				<ul class="mt-6 mb-8 flex flex-col gap-4 px-6 text-gray-600 lg:px-12">
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-black text-blue-100/50">1</span>
						<p class="relative z-10">
							Make your Instagram account into a Business Account
							<a
								href="https://help.instagram.com/502981923235522"
								target="_blank"
								class="font-semibold text-blue-500 hover:underline"
							>
								Learn how
							</a>
						</p>
					</li>
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-black text-blue-100/50">2</span>
						<p class="relative z-10">
							Link it with your Facebook Page
							<a
								href="https://help.instagram.com/570895513091465"
								target="_blank"
								class="font-semibold text-blue-500 hover:underline"
							>
								Learn how
							</a>
						</p>
					</li>
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-black text-blue-100/50">3</span>
						<p class="relative z-10">Now everything's connected and ready to publish your designs!</p>
					</li>
				</ul>
				<button
					class="rounded border-2 border-blue-500 py-3 px-6 font-semibold text-blue-500 hover:bg-blue-500 hover:text-white"
					@click="logout"
				>
					Logout
				</button>
			</div>
		</div>
	</Modal>
</template>
