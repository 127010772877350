<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { createPopper } from '@popperjs/core';
import { SVG } from '@svgdotjs/svg.js';
import { useIntervalFn } from '@vueuse/core';
import { difference } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { computed, nextTick, onMounted, Ref, ref, toRef, watch } from 'vue';

import Element from '@/Classes/Element';
import { GradientColor } from '@/Classes/GradientColor';
import Page from '@/Classes/Page';
import { Shape } from '@/Classes/Shape';
import { SolidColor } from '@/Classes/SolidColor';
import { Text } from '@/Classes/Text';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useTextStyles, useTextStylesStatic } from '@/composables/element/text/useTextStyles';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useElementOrderOrchestrator } from '@/composables/element/useElementOrderOrchestrator';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useActivePage } from '@/composables/page/useActivePage';
import { usePage } from '@/composables/page/usePage';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useToast } from '@/composables/useToast';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';
import MathTools from '@/utils/MathTools';

const store = useMainStore();

// Props
const props = defineProps<{ elements: Element[] }>();

// Using composables
const elements = toRef(props, 'elements');
const { isAdminMode } = useEditorMode();
const { isMobile } = useDeviceInfo();

const temporalRef = ref<Element>(elements.value[0]);
const usingElementOrderOrchestrator = useElementOrderOrchestrator(temporalRef);
const usingElementOrchestrator = useElementOrchestrator(ref<Element>(elements.value[0]));
const { page } = usingElementOrchestrator.value;
const { getElementIndex } = usePage(page as Ref<Page>);
const { isCropping } = useInteractions();
const { addElement, removeElement } = useActivePage();
const toast = useToast();
const isMounted = ref(false);

// Computeds
const isLocked = computed(
	() => elements.value.every((el) => el.group) && elements.value.every((el) => el.group === elements.value[0].group)
);

// Methods
const lockGroup = () => {
	const idGroup = uuidv4();

	const index = Math.max(...elements.value.map((el) => getElementIndex(el)));
	const maxIndexElement = elements.value.find((el) => getElementIndex(el) === index);

	const sortElements = [...elements.value.sort((a, b) => (getElementIndex(a) < getElementIndex(b) ? 1 : -1))];

	let indexToMove = index;
	sortElements.forEach((el) => {
		el.group = idGroup;
		if (el.id === maxIndexElement?.id) return;
		temporalRef.value = el;
		const { moveElementToIndex } = usingElementOrderOrchestrator.value;
		indexToMove -= 1;
		moveElementToIndex(indexToMove);
	});

	Bugsnag.leaveBreadcrumb(`Lock group: ${elements.value.map((el) => ` ${el.type}-${el.id}`)}`);
};

const unlockGroup = () => {
	Bugsnag.leaveBreadcrumb(`Unlock group: ${elements.value.map((el) => ` ${el.type}-${el.id}`)}`);
	elements.value.forEach((el) => {
		el.group = null;
	});
};

const deleteGroup = () => {
	Bugsnag.leaveBreadcrumb(
		`Delete ${isLocked.value ? 'group' : 'selection'}: ${elements.value.map((el) => ` ${el.type}-${el.id}`)}`
	);
	store.selection.forEach((element) => removeElement(element));
};

const mergeSelection = () => {
	const onlyShapes = elements.value.every((el) => el.type === 'shape');

	if (!onlyShapes) {
		toast.error('Only can merge shapes');
		return;
	}

	const shapes = elements.value as Shape[];

	const selectionX = Math.min(...shapes.map((el) => el.position.x));
	const selectionY = Math.min(...shapes.map((el) => el.position.y));
	const selectionWidth = Math.max(...shapes.map((el) => el.position.x + el.size.width)) - selectionX;
	const selectionHeight = Math.max(...shapes.map((el) => el.position.y + el.size.height)) - selectionY;

	const colors = shapes.flatMap((shape) => shape.colors);

	const colorsUnique = colors.filter(
		(color, index, colors) => index === colors.findIndex((c) => c.toCssString() === color.toCssString())
	);

	const colorsToChange = difference(colors, colorsUnique);

	// Pasamos las props del elemento al svg para poder fusionarlos
	const childs = shapes
		.map((el) => {
			const svg = SVG(`<svg viewBox="${el.viewbox}"><g>${el.content}</g></svg>`);
			const viewbox = el.viewboxObject;
			const scaleX = MathTools.ruleOfThree(viewbox.width, 1, el.size.width);
			const scaleY = MathTools.ruleOfThree(viewbox.height, 1, el.size.height);

			const g = svg.first();
			const newX = el.position.x - selectionX;
			const newY = el.position.y - selectionY;

			g.attr('transform', `matrix(${scaleX},0,0,${scaleY},${newX},${newY})`);

			if (el.flip.x) {
				g.flip('x');
			}

			if (el.flip.y) {
				g.flip('y');
			}

			g.rotate(el.rotation);

			g.children().forEach((elChild) => elChild.transform(g.transform(), true));
			g.attr('transform', null);

			// Si hay colores repetido tenemos que cambiar la referencia a la varaible css para unificar colores que sean iguales
			if (colorsToChange.length) {
				g.find('*:not(g):not(defs)').forEach((elChild) => {
					if (!elChild.attr('style').includes('fill')) {
						return;
					}

					const style = elChild.attr('style');
					const colorToChange = colorsToChange.find((color) => style.includes(color.id));

					if (colorToChange) {
						const colorToApply = colorsUnique.find((color) => color.toCssString() === colorToChange.toCssString());
						elChild.attr('style', style.replaceAll(colorToChange.id, colorToApply?.id));
					}
				});
			}

			return g.node.innerHTML;
		})
		.flat()
		.join();

	// Eliminamos los originales y creamos el nuevo
	elements.value.forEach((el) => removeElement(el));

	const newViewBox = `0 0 ${selectionWidth} ${selectionHeight}`;
	const size = {
		width: selectionWidth,
		height: selectionHeight,
	};
	const position = { x: selectionX, y: selectionY };
	const flip = { x: false, y: false };
	const newShape = Shape.create(newViewBox, childs, {
		size,
		position,
		flip,
		colors: colorsUnique,
	});

	addElement(newShape);

	store.setSelection(newShape, false);
};

// Instanciamos el texto que posteriormente modificaremos para obtener su selectedColor
const finalText = ref(Text.createDefault()) as Ref<Text>;
const staticText = ref(Text.createDefault()) as Ref<Text>;

// Instanciamos el hook con nuestro texto default
const { selectedColor, updateColor } = useTextStyles(finalText);
const staticSelectedColor = useTextStylesStatic(staticText).selectedColor;

const selectedGroupColors = computed(() => {
	// Solo queremos devolver los colores del grupo para el botón del toolbar cuando todos los elementos son textos
	const onlyTexts = store.selection.every((el) => el instanceof Text);
	if (!onlyTexts) return [];

	let finalColors: SolidColor[] = [];

	if (isLocked.value) {
		// Instanciamos el hook con nuestro texto default
		store.selection.forEach((el) => {
			/// Si el elemento es de tipo Text reemplazamos nuestro textoDefault para poder acceder a su selectedColor y así poder obtener los colores computados de sus hijos
			if (el instanceof Text) {
				let colors: SolidColor | GradientColor | SolidColor[] | undefined | any;
				if (store.textEditing && store.textEditingId?.includes(el.id)) {
					finalText.value = el;
					colors = selectedColor.value;
				} else {
					staticText.value = el;
					colors = staticSelectedColor.value;
				}

				if (Array.isArray(colors)) {
					const result = colors.filter(
						(sc) => !finalColors.find((c) => c.r === sc.r && c.g === sc.g && c.b === sc.b && c.a === sc.a)
					);

					finalColors = [...result, ...finalColors];
				} else if (colors && colors instanceof SolidColor) {
					if (
						!finalColors.find((c) => c.r === colors?.r && c.g === colors?.g && c.b === colors?.b && c.a === colors?.a)
					) {
						finalColors = [colors, ...finalColors];
					}
				}
			}
		});
	}

	return finalColors.sort();
});

const updateTextColor = (color: SolidColor | GradientColor) => {
	store.selection.map((el) => {
		if (el instanceof Text) {
			const finalNode =
				document.querySelector(`#editable-${el.id}`) || el.domNode()?.querySelector('.text-element-final');

			if (finalNode) {
				finalText.value = el;

				updateColor(color);

				el.content = finalNode?.innerHTML;
			}
		}
	});
};

const size = computed(() => props.elements.map((el) => el.size));
const position = computed(() => props.elements.map((el) => el.position));

watch([size, position], async () => {
	await nextTick();
	popper.value?.update();
});

const toolbar = ref();
const popper = ref();

// El toolbar de los grupos trabaja respecto al elemento moveable-area. Este elemento
// no esta listo de primeras y no sabemos cuando va a estar listo y en su sitio
// asi que revisamos la transformación de su padre para ver que no este
// 0 0 0 y en ese momento mostramos el toolbar y creamos el popper
onMounted(async () => {
	await nextTick();
	const container = document.querySelector('.moveable-area') as HTMLElement;
	const parent = document.querySelector('#portalTarget') as HTMLElement;

	await new Promise((resolve) => {
		const interval = useIntervalFn(() => {
			if (parent.style.transform === 'translate3d(0px, 0px, 0px)') return;
			interval.pause();
			resolve(true);
			isMounted.value = true;
		}, 16);
	});

	await nextTick();

	if (!container || !parent || !toolbar.value) return;

	popper.value = createPopper(container, toolbar.value, {
		placement: 'right-start',
		modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
	});
});

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.Group) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.Group;
	Bugsnag.leaveBreadcrumb(`Open edit group panel`);
};
</script>

<template>
	<teleport v-if="!isMobile && isMounted" to="#groupToolbarTarget">
		<div
			v-show="!isCropping"
			ref="toolbar"
			data-testid="btns-group"
			class="toolbar-group z-10 flex flex-col items-center gap-1"
		>
			<button
				v-if="!isLocked"
				tooltip="Lock layers"
				tooltip-position="right"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700 text-white shadow-lg hover:bg-opacity-90"
				@click="lockGroup"
			>
				<SvgIcon name="unlock" class="h-3 w-3 fill-current" />
			</button>
			<button
				v-if="isLocked"
				tooltip="Unlock layers"
				tooltip-position="right"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-600"
				@click="unlockGroup"
			>
				<SvgIcon name="lock" class="h-3 w-3 fill-current" />
			</button>
			<button
				v-if="!isLocked && isAdminMode"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-600"
				tooltip="Merge shapes"
				tooltip-position="right"
				@click="mergeSelection"
			>
				<SvgIcon name="chain" class="h-3 w-3 fill-current" />
			</button>
			<button
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700 text-white shadow-lg hover:bg-opacity-90"
				tooltip="Delete group"
				tooltip-position="right"
				@click="deleteGroup"
			>
				<SvgIcon name="trash" class="h-3 w-3 fill-current" />
			</button>
			<!-- Color picker -->
			<div
				v-if="selectedGroupColors.length"
				tooltip="Text color"
				tooltip-position="right"
				class="h-6 w-6 rounded-full border-2 border-gray-600"
			>
				<ColorPicker class="h-5 w-5" :color="selectedGroupColors" @change="updateTextColor" />
			</div>

			<div v-if="isLocked">
				<button
					data-testid="open-more-tools-group"
					tooltip="More tools"
					tooltip-position="right"
					class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700 text-gray-100 hover:text-white"
					@click="onClickMoreTools"
				>
					<SvgIcon name="more" class="h-3 w-3" :class="store.editPanel === EditPanels.Group ? 'text-white' : ''" />
				</button>
			</div>
			<!-- <GroupPanel v-if="isLocked" :elements="elements" /> -->
		</div>
	</teleport>
	<teleport v-if="isMobile && isMounted" to=".topbar-color-mobile">
		<div class="ml-auto flex">
			<!-- Color picker -->
			<div v-if="selectedGroupColors.length" class="mr-3 border-r border-gray-600 pr-3">
				<ColorPicker class="h-6 w-6" :color="selectedGroupColors" @change="updateTextColor" />
			</div>
			<button
				v-if="isLocked"
				class="mr-2 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-600"
				@click="unlockGroup"
			>
				<SvgIcon name="lock" class="h-3 w-3 fill-current" />
			</button>
			<button
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700 text-gray-100 shadow-lg hover:bg-opacity-90"
				@click="deleteGroup"
			>
				<SvgIcon name="trash" class="h-4 w-4 scale-90 fill-current" />
			</button>
		</div>
	</teleport>
</template>
