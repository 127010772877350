<script setup lang="ts">
import { ref } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useAdminActions } from '@/composables/useAdminActions';
import { useToast } from '@/composables/useToast';

const saving = ref<boolean>(false);

const { deleteReport } = useAdminActions();
const toast = useToast();

const performDeleteReport = async () => {
	await deleteReport();
};
</script>

<template>
	<div>
		<button
			class="relative flex h-10 w-8 items-center justify-center rounded bg-red-500 font-bold text-white hover:bg-yellow-600 lg:w-auto lg:py-3 lg:px-6"
			@click="performDeleteReport"
		>
			<span>Delete report</span>
		</button>
	</div>
</template>
