import { useAuth } from '@/composables/useAuth';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';

export const useUsersnap = () => {
	const { isMobile } = useDeviceInfo();
	const { inSlidesgoContext } = useEditorMode();
	const { user } = useAuth();

	const openReportBug = () => {
		return new Promise((resolve) => {
			const script = document.createElement('script');
			const nonceScript = document.querySelector('[nonce]');
			const nonce = nonceScript?.getAttribute('nonce') || '';
			script.setAttribute('nonce', nonce);

			script.src = `https://widget.usersnap.com/global/load/0d0df480-fb29-49b7-85d6-37546e404002?onload=onUsersnapCXLoaded&n=${nonce}`;

			window.onUsersnapCXLoaded = (api) => {
				api.init({
					useSystemFonts: true,
					custom: {
						version: import.meta.env.VITE_APP_VERSION,
					},
					user: {
						userId: user.value?.id,
						email: user.value?.email,
					},
				});

				api.logEvent('start');
				api.on('close', () => {
					api.destroy();
				});
			};

			document.head.appendChild(script);
		});
	};

	const loadUserSnap = () => {
		let userSnapId = '852cf018-5268-42f2-b7f6-0f7f30661ed0';

		if (isMobile.value) {
			userSnapId = 'e9887b2e-0447-47d9-bb83-6587ef26dc50';
		}

		if (inSlidesgoContext.value) {
			userSnapId = '34166b03-8fb6-49bc-a90e-1b73079a5bb5';
		}

		window.onUsersnapCXLoad = function onUsersnapCXLoaded(api) {
			api.init({
				button: {
					isHidden: true,
				},
			});
			window.UsersnapCX = api;
		};
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.id = 'usersnap-load';
		const nonceScript = document.querySelector('[nonce]');
		const nonce = nonceScript?.getAttribute('nonce') || '';
		script.setAttribute('nonce', nonce);
		script.src = `https://widget.usersnap.com/load/${userSnapId}?onload=onUsersnapCXLoad&n=${nonce}`;

		document.head.appendChild(script);
	};

	const showUsersnap = () => {
		if (!window.UsersnapCX) {
			return;
		}
		window.UsersnapCX.open();
	};

	return {
		loadUserSnap,
		showUsersnap,
		openReportBug,
	};
};
