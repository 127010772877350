import { cloneDeep } from 'lodash-es';
import { computed, Ref, ref } from 'vue';

import Element from '@/Classes/Element';
import Line from '@/Classes/Line';
import Page from '@/Classes/Page';
import { Shape } from '@/Classes/Shape';
import Storyset from '@/Classes/Storyset';
import { Text } from '@/Classes/Text';
import { useShapeColors } from '@/composables/element/shape/useShapeColors';
import { useTextColors } from '@/composables/element/text/useTextColors';
import { Color } from '@/Types/colorsTypes';

export const useGroupColors = (group: Ref<Element[]>) => {
	const temporalShape = ref<Shape>(Shape.createDefault());
	const { colors: shapeColors, updateColor: updateShapeColor } = useShapeColors(temporalShape as Ref<Shape>);

	const temporalText = ref(Text.createDefault());
	const { colors: textColors, updateColor: updateTextColor } = useTextColors(temporalText as Ref<Text>);

	const elementsWithColor = ref<(Element | Page)[]>([]);
	const colorSelected = ref<Color>();

	// @ts-ignore
	const shapes = computed<Shape[]>(() => group.value.filter((el) => el instanceof Shape));
	// @ts-ignore
	const texts = computed<Text[]>(() => group.value.filter((el) => el instanceof Text));
	// @ts-ignore
	const lines = computed<Line[]>(() => group.value.filter((el) => el instanceof Line));
	// @ts-ignore
	const stories = computed<Storyset[]>(() => group.value.filter((el) => el instanceof Storyset));

	const shapesColors = computed(() => {
		return shapes.value
			.map((shape) => {
				temporalShape.value = shape;
				return [shapeColors.value];
			})
			.flat(2);
	});

	const textsColors = computed(() => {
		return texts.value
			.map((text) => {
				temporalText.value = text;
				return [textColors.value];
			})
			.flat(2);
	});

	const linesStrokeColors = computed(() => {
		return lines.value.map((line) => line.mainColor);
	});

	const groupColors = computed(() => {
		const storysetsColors = stories.value.map((storyset) => storyset.mainColor);
		const lineColors = lines.value.map((line) => {
			const colors = [line.mainColor];

			if (line.markerStart) {
				colors.push(line.markerStart.color);
			}

			if (line.markerEnd) {
				colors.push(line.markerEnd.color);
			}

			return colors;
		});

		const colors = [...shapesColors.value, ...textsColors.value, ...storysetsColors, ...lineColors].flat(1);

		return colors
			.filter(
				(color, index, colorList) => index === colorList.findIndex((c) => c.toCssString() === color.toCssString())
			)
			.flat(1);
	});

	const getElementsWithColorSelected = () => {
		if (!colorSelected.value) {
			elementsWithColor.value = [];
			return;
		}

		const shapeElements = shapes.value.filter((shape) => {
			temporalShape.value = shape;
			return shapeColors.value.map((c) => c.toCssString()).includes((colorSelected.value as Color).toCssString());
		});
		const storysets = stories.value.filter(
			(storyset) => storyset.mainColor.toCssString() === (colorSelected.value as Color).toCssString()
		);
		const textElements = texts.value.filter((text) => {
			temporalText.value = text;
			return textColors.value.map((c) => c.toCssString()).includes((colorSelected.value as Color).toCssString());
		});
		const linesElements = lines.value.filter(
			(line) =>
				line.mainColor.toCssString() === (colorSelected.value as Color).toCssString() ||
				(line.markerStart && line.markerStart.color.toCssString() === (colorSelected.value as Color).toCssString()) ||
				(line.markerEnd && line.markerEnd.color.toCssString() === (colorSelected.value as Color).toCssString())
		);

		elementsWithColor.value = [...shapeElements, ...storysets, ...textElements, ...linesElements] as Element[];
	};

	const updateGroupColor = (oldColor: Color, newColor: Color) => {
		const clone = cloneDeep(oldColor);
		const shapes = elementsWithColor.value.filter((e) => e instanceof Shape);
		const storysets = elementsWithColor.value.filter((e) => e instanceof Storyset);
		const lines = elementsWithColor.value.filter((e) => e instanceof Line);
		const texts = elementsWithColor.value.filter((e) => e instanceof Text);

		shapes.forEach((shape: any) => {
			temporalShape.value = shape;
			const colorsToChange = shapeColors.value.filter((c) => c.toCssString() === clone.toCssString());
			colorsToChange.forEach((c) => updateShapeColor(c, newColor));
		});

		texts.forEach((text: any) => {
			temporalText.value = text;
			const colorsToChange = textColors.value.filter((c) => c.toCssString() === clone.toCssString());
			colorsToChange.forEach((c) => updateTextColor(c, newColor));
		});

		storysets.forEach((storyset: any) => storyset.updateColor(newColor));

		lines.forEach((line: any) => {
			if (line.mainColor.toCssString() === clone.toCssString()) {
				line.updateStrokeColor(newColor);
			}

			if (line.markerStart && line.markerStart.color.toCssString() === clone.toCssString()) {
				line.updateMarkerStartColor(newColor);
			}

			if (line.markerEnd && line.markerEnd.color.toCssString() === clone.toCssString()) {
				line.updateMarkerEndColor(newColor);
			}
		});
	};

	return {
		colors: groupColors,
		updateGroupColor,
		getElementsWithColorSelected,
		textsColors,
		linesStrokeColors,
		colorSelected,
	};
};
