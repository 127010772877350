import { computed, Ref, ref } from 'vue';

import Element from '@/Classes/Element';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useElementOrder } from '@/composables/element/useElementOrder';
import { useGroup } from '@/composables/group/useGroup';
import { usePage } from '@/composables/page/usePage';
import GAnalytics from '@/utils/GAnalytics';

export const useGroupOrder = (element: Ref<Element>) => {
	const temporalRef = ref<Element>(element.value);
	const usingElementOrchestrator = useElementOrchestrator(element);
	const { page } = usingElementOrchestrator.value;
	const { group } = useGroup(element);
	const { group: targetGroup } = useGroup(temporalRef);
	const { getElementIndex } = usePage(page);
	const { firstIndex } = useElementOrder(element);
	const { moveElementToIndex } = useElementOrder(temporalRef);

	const maxIndexGroup = computed(() => Math.max(...group.value.map((el) => getElementIndex(el))));
	const minIndexGroup = computed(() => Math.min(...group.value.map((el) => getElementIndex(el))));

	const canGoDown = computed(() => maxIndexGroup.value - group.value.length >= firstIndex.value);
	const canGoUp = computed(() => maxIndexGroup.value < page.value.elements.length - 1);

	const moveToIndex = (indexToMove: number, dir: 'up' | 'down') => {
		// In order to keep grouped elements with continous indexes,
		// we get collisioned group max|min index regarding if current group
		// has to move down or up if any
		const currentElementInTargetIndex = page.value.elements.find((el) => getElementIndex(el) === indexToMove);

		if (currentElementInTargetIndex?.group) {
			temporalRef.value = currentElementInTargetIndex;
			const targetGroupedIndexes = targetGroup.value.map((el) => getElementIndex(el));
			indexToMove = dir === 'up' ? Math.max(...targetGroupedIndexes) : Math.min(...targetGroupedIndexes);
		}

		// Sort will depend on going down or up too
		const sortElements = [
			...group.value.sort((a, b) => {
				const check = dir === 'up' ? getElementIndex(a) > getElementIndex(b) : getElementIndex(a) < getElementIndex(b);
				return check ? 1 : -1;
			}),
		];

		sortElements.forEach((el) => {
			temporalRef.value = el;
			moveElementToIndex(indexToMove);
		});
	};

	const moveUp = () => {
		GAnalytics.track('click', 'Button', 'forward', null);
		moveToIndex(maxIndexGroup.value + 1, 'up');
	};

	const moveDown = () => {
		GAnalytics.track('click', 'Button', 'backward', null);
		moveToIndex(minIndexGroup.value - 1, 'down');
	};

	const moveToFront = () => {
		GAnalytics.track('click', 'Button', 'front', null);
		const lastPosition = page.value.elements.length - 1;
		moveToIndex(lastPosition, 'up');
	};

	const moveToBack = () => {
		GAnalytics.track('click', 'Button', 'back', null);
		moveToIndex(firstIndex.value, 'down');
	};

	return {
		canGoDown,
		canGoUp,
		moveUp,
		moveDown,
		moveToBack,
		moveToFront,
		moveElementToIndex,
	};
};
