<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import { Shape } from '@/Classes/Shape';
import ColorPickerGroup from '@/components/Common/Color/ColorPickerGroup.vue';
import CopyToolbar from '@/components/selection/toolbars/CopyToolbar.vue';
import FlipMenuToolbar from '@/components/selection/toolbars/FlipMenuToolbar.vue';
import MoreToolsToolbar from '@/components/selection/toolbars/MoreToolsToolbar.vue';
import OrderToolbar from '@/components/selection/toolbars/OrderToolbar.vue';
import RemoveToolbar from '@/components/selection/toolbars/RemoveToolbar.vue';
import { useShapeColors } from '@/composables/element/shape/useShapeColors';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

const props = defineProps<{ element: Shape }>();

const element = toRef(props, 'element');

const { colors, updateColor } = useShapeColors(element);
const { isMobile } = useDeviceInfo();

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.Shape) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.Shape;
	Bugsnag.leaveBreadcrumb('Open edit shape panel');
};
</script>

<template>
	<div>
		<FlipMenuToolbar :element="element" />

		<ColorPickerGroup
			class="order-first mx-2 h-10 border-r border-gray-600 py-1 pr-4 lg:order-none lg:mx-0 lg:h-5 lg:border-0 lg:py-0 lg:pr-1 lg:pl-1"
			picker-class="w-8 lg:w-5"
			:max="3"
			:colors="colors"
			picker-parent=".toolbar"
			@change="({ color, newColor }) => updateColor(color, newColor)"
		/>

		<hr class="mx-1 hidden h-5 w-px bg-white opacity-25 lg:block" />

		<!-- Order -->
		<OrderToolbar v-if="isMobile" :element="element" />

		<!-- Copy -->
		<CopyToolbar v-if="isMobile" :element="element" />

		<!-- Remove -->
		<RemoveToolbar :element="element" />

		<MoreToolsToolbar :panel="EditPanels.Shape" @more-tools="onClickMoreTools" />
	</div>
</template>
