import { Ref } from 'vue';

import { Illustrator } from '@/Classes/Illustrator';
import { useMainStore } from '@/stores/store';
import { useProjectStore } from '@/stores/project';
import { Svg } from '@svgdotjs/svg.js';
import TemplateLoader from '@/utils/TemplateLoader';
import { Shape } from '@/Classes/Shape';

export const useIllustrator = (illustrator: Ref<Illustrator>) => {
	const store = useMainStore();
	const project = useProjectStore();

	const moveTexts = (ids: string[]) => {
		if (!ids.length) return;

		const illustratorSvg = illustrator.value.contentSvg.addTo(document.body) as Svg;
		illustratorSvg.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		const selector = ids.map((id) => `[data-illustrator-link="${id}"]`).join(',');

		const texts = illustratorSvg.find(selector).map((text) => {
			const illustratorLink = text.data('illustrator-link');
			const newText = TemplateLoader.getNativeText(text);

			store.illustratorElementsMoved.add(illustratorLink);

			newText.setMetadata({
				...newText.metadata,
				illustratorLinks: [illustratorLink],
			});

			return newText;
		});

		illustratorSvg.remove();

		project.pages[1].elements.push(...texts);
	};

	const moveShapes = (ids: string[]) => {
		if (!ids.length) return;

		const illustratorSvg = illustrator.value.contentSvg as Svg;
		const selector = ids.map((id) => `[data-illustrator-link="${id}"]`).join(',');
		const tempG = illustratorSvg.group();

		illustratorSvg.find(selector).forEach((shape) => {
			const illustratorLink = shape.data('illustrator-link');

			store.illustratorElementsMoved.add(illustratorLink);

			tempG.add(shape);
		});

		const newShape = Shape.fromSvg(
			`<svg><defs>${illustratorSvg.defs().node.innerHTML}</defs>${tempG.node.outerHTML}</svg>`
		);

		newShape.setMetadata({
			...newShape.metadata,
			illustratorLinks: ids,
		});

		tempG.remove();

		project.pages[1].elements.unshift(newShape);
	};

	return {
		moveTexts,
		moveShapes,
	};
};
