<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { onBeforeMount, ref } from 'vue';

// Components
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useProject } from '@/composables/project/useProject';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Stores
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
// Types
import { TemplateApi } from '@/Types/apiClient';
import { Size, Unit } from '@/Types/types';

// Using composables
const { appendTemplate, replaceTemplate } = useProject();
const project = useProjectStore();

const { runOnMobile } = useDeviceInfo();
const store = useMainStore();

// Props
const props = defineProps<{ selectedTemplate: TemplateApi | null }>();

// Data
const isSameArtboard = ref<boolean>();

// Emits
const emit = defineEmits(['close']);

// Lifecycle hooks
onBeforeMount(() => (isSameArtboard.value = getIsSameArtboard()));

// Methods
const getIsSameArtboard = () => {
	if (!props.selectedTemplate) {
		return false;
	}

	// WIP! Solución temporal para saber si el template seleccionado tiene el mismo artboard que renderizado.
	// Actualmente, la api no devuelve correctamente las dimensiones del artboard (se desprecia su orientación, por ejemplo, con business-card).
	// Cuando la api devuelva el tamaño correctamente, simplemente comprobaremos el size del artboard actual contra el seleccionado.
	const currentArtboardSize: Size = project.size;
	const currentArtboardUnit: Unit = project.unit;

	const currentRatio = currentArtboardSize.width / currentArtboardSize.height;
	const selectedRatio = props.selectedTemplate.previews[0].width / props.selectedTemplate.previews[0].height;
	const isSameRatio = currentRatio.toFixed(2) === selectedRatio.toFixed(2);

	const selectedTemplateSize = {
		width:
			isSameRatio && currentRatio > 1
				? Math.max(props.selectedTemplate.artboard.width, props.selectedTemplate.artboard.height)
				: Math.min(props.selectedTemplate.artboard.width, props.selectedTemplate.artboard.height),
		height:
			isSameRatio && currentRatio > 1
				? Math.min(props.selectedTemplate.artboard.width, props.selectedTemplate.artboard.height)
				: Math.max(props.selectedTemplate.artboard.width, props.selectedTemplate.artboard.height),
	};
	const selectedTemplateUnit = props.selectedTemplate.artboard.unit;

	const isSameWidth = currentArtboardSize.width === selectedTemplateSize.width;
	const isSameHeight = currentArtboardSize.height === selectedTemplateSize.height;
	const isSameUnit = currentArtboardUnit === selectedTemplateUnit;

	return isSameWidth && isSameHeight && isSameUnit;
};

const appending = ref(false);
const onClickAppend = async () => {
	if (!props.selectedTemplate) return;
	appending.value = true;
	runOnMobile(() => (store.activePanel = null));
	await appendTemplate(props.selectedTemplate.slug);
	Bugsnag.leaveBreadcrumb(`Append template`);
	emit('close');
	appending.value = false;
};

const onClickClose = () => {
	emit('close');
	Bugsnag.leaveBreadcrumb(`Template replacement modal is closed`);
};

const onClickReplace = async () => {
	store.clearSelection();
	if (!props.selectedTemplate) return;
	runOnMobile(() => (store.activePanel = null));
	await replaceTemplate(props.selectedTemplate.slug);
	Bugsnag.leaveBreadcrumb(`Replace template`);
	emit('close');
};
</script>

<template>
	<Modal open @close="onClickClose">
		<div class="relative my-auto w-full max-w-lg rounded bg-white py-8 px-8">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<!-- Ask user for APPEND or REPLACE -->
			<template v-if="isSameArtboard">
				<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">We need confirmation!</h1>
				<p class="mb-0 text-center leading-normal text-gray-600">
					You can choose between adding the template as another page or replacing the current one.
				</p>

				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickAppend"
					>
						<SvgIcon v-show="appending" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ appending ? 'Appending' : 'Append' }}
					</button>

					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-blue-500 bg-blue-500 px-8 text-lg font-semibold text-white hover:border-blue-400 hover:bg-blue-400"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickReplace"
					>
						<SvgIcon v-show="!store.finishedLoading" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ store.finishedLoading ? 'Replace' : 'Replacing' }}
					</button>
				</div>
			</template>

			<!-- Ask user for REPLACE confirmation -->
			<template v-else>
				<SvgIcon name="alert" class="mx-auto mb-2 h-12 w-12 fill-current text-yellow-500" />
				<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">We need confirmation!</h1>
				<p class="mb-0 text-center leading-normal text-gray-600">
					The new template has a different artboard, the existing pages will be removed.
				</p>

				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-yellow-500 bg-yellow-500 px-8 text-lg font-semibold text-white hover:border-yellow-400 hover:bg-yellow-400"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickReplace"
					>
						<SvgIcon v-show="!store.finishedLoading" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ store.finishedLoading ? 'Replace' : 'Replacing' }}
					</button>
				</div>
			</template>
		</div>
	</Modal>
</template>
