<script lang="ts" setup>
import { onMounted, watch } from 'vue';

import { useAuth } from '@/composables/useAuth';
const emit = defineEmits<{ (e: 'close'): void }>();
const { isLogged, requireAuth, authRequired } = useAuth();

onMounted(() => requireAuth());

watch(authRequired, (newVal) => {
	if (!newVal && !isLogged.value) {
		emit('close');
	}
});
</script>

<template>
	<slot v-if="isLogged"></slot>
	<template v-else></template>
</template>

<style scoped></style>
