<script setup lang="ts">
import { toRef } from 'vue';

import { Text } from '@/Classes/Text';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import GAnalytics from '@/utils/GAnalytics';

// Props
const props = defineProps<{ element: Text }>();
const element = toRef(props, 'element');

// Using composables
const { listStyle, updateList } = useTextStyles(element);
const { isCircleText } = useCircleTypeInfo(element);

const listClickManager = () => {
	switch (listStyle.value) {
		case '': {
			updateList('unordered');
			break;
		}
		case 'unordered': {
			updateList('ordered');
			break;
		}
		case 'ordered': {
			updateList('');
			break;
		}
	}
	GAnalytics.track('click', 'Button', `text-list-${listStyle.value}`, null);
};
</script>

<template>
	<div class="flex flex-col">
		<button
			data-testid="text-list"
			:disabled="isCircleText"
			:class="isCircleText ? 'cursor-not-allowed opacity-25' : 'hover:bg-gray-600'"
			class="flex h-8 w-full items-center justify-center rounded bg-gray-700 px-3 text-gray-100"
			data-title="alignText"
			@click="listClickManager()"
		>
			<SvgIcon v-if="listStyle === ''" name="minus" class="h-5 w-5" />
			<SvgIcon v-if="listStyle === 'unordered'" name="list" class="h-5 w-5 text-white" />
			<SvgIcon v-if="listStyle === 'ordered'" name="list-ordened" class="h-5 w-5 text-white" />
		</button>
		<label class="mt-1 text-center text-2xs text-gray-100" :class="isCircleText ? 'opacity-50' : ''"> List </label>
	</div>
</template>
