<script lang="ts" setup>
import { computed, ref } from 'vue';

import Page from '@/Classes/Page';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import Switch from '@/components/Common/Switch.vue';
import DownloadOptions from '@/components/export/DownloadOptions.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useProjectStore } from '@/stores/project';
import GAnalytics from '@/utils/GAnalytics';

const emits = defineEmits(['close', 'error', 'share']);

const project = useProjectStore();

const maxAllowedPages = 20;

const closeModal = () => {
	emits('close');
};

const share = ($event: { socialNetwork: string; pages: Page[] }) => {
	const pages = $event.pages.filter((page: Page) => selection.value.includes(page.id));

	emits('share', { socialNetwork: $event.socialNetwork, pages });
};

const onError = () => emits('error');

const maxSelectablePages = computed(() => Math.min(project.pages.length, maxAllowedPages));
const selection = ref<string[]>(project.pages.map((p) => p.id).slice(0, maxSelectablePages.value));
const continueToShare = ref(false);

const toggle = (page: Page) => {
	if (selection.value.includes(page.id)) {
		selection.value = selection.value.filter((id) => id !== page.id);
		updateAll();
		return;
	}
	if (selection.value.length >= maxSelectablePages.value) return;
	selection.value.push(page.id);
	updateAll();
};

const allToggle = ref(true);

const updateAll = () => {
	allToggle.value = maxSelectablePages.value === selection.value.length;
};
const toggleAll = (all: boolean) => {
	if (!all) selection.value = [];
	else selection.value = project.pages.map((p) => p.id).slice(0, maxSelectablePages.value);
};

const clickOnPage = (page: Page, pageNumber: number) => {
	toggle(page as Page);

	const projectName = project.name?.length ? project.name : 'custom-artboard';

	GAnalytics.track(
		'click-download-select',
		'Template',
		selection.value.includes(page.id)
			? `check-page${pageNumber}-${projectName}`
			: `uncheck-page${pageNumber}-${projectName}`,
		null
	);
};

const continueToDownload = () => {
	continueToShare.value = true;
};
</script>

<template>
	<Modal open content-classes="w-full flex items-center justify-center" @close="closeModal()">
		<DownloadOptions
			v-if="continueToShare"
			:selection="selection"
			@error="onError"
			@close="closeModal"
			@share="share"
		/>
		<div
			v-if="!continueToShare"
			class="pointer-events-auto relative flex max-h-full w-full select-none flex-col rounded-lg bg-white py-4 px-4 lg:w-full lg:min-w-lg lg:max-w-2xl lg:py-6 lg:px-8"
		>
			<div class="mb-4 flex justify-between">
				<div class="flex items-center">
					<span class="text-sm font-semibold text-gray-800 lg:text-lg">Selected pages</span>
					<div class="flex items-center">
						<span
							class="ml-2 flex h-4 w-8 items-center justify-center rounded-full bg-gray-600 text-2xs font-semibold text-white lg:h-5 lg:w-10 lg:text-xs"
							>{{ selection.length }}/{{ maxSelectablePages }}</span
						>
						<div class="group relative ml-1 flex cursor-help">
							<SvgIcon
								name="help"
								class="inline h-3 w-3 fill-current text-gray-300 group-hover:text-gray-600 lg:h-4 lg:w-4"
							/>
							<div
								class="pointer-events-none absolute top-0 left-6 z-20 w-32 rounded bg-white p-2 text-sm leading-snug text-gray-600 opacity-0 shadow-lg transition-all duration-300 group-hover:translate-x-1 group-hover:opacity-100 lg:w-52"
							>
								For performance reasons, the maximum number of simultaneous downloads is 12
							</div>
						</div>
					</div>
				</div>
				<Switch v-model="allToggle" data-testid="switch-export-modal" @update:modelValue="toggleAll">Select all</Switch>
			</div>

			<div
				data-testid="container-export-modal-pages"
				class="-m-1 mb-6 grid max-h-96 grid-cols-3 gap-4 overflow-auto p-1 text-white scrollbar-thin scrollbar-thumb-gray-100 lg:grid-cols-5"
			>
				<button
					v-for="(page, index) in project.pages"
					:key="page.id"
					class="relative aspect-square rounded bg-white ring-2"
					:class="selection.includes(page.id) ? 'ring-blue-500' : 'ring-gray-100'"
					@click="() => clickOnPage(page, index + 1)"
				>
					<span
						class="absolute top-1 right-1 z-10 flex h-5 w-5 items-center justify-center rounded bg-blue-500"
						:class="selection.includes(page.id) ? 'bg-blue-500' : 'bg-gray-100/50 backdrop-blur'"
					>
						<SvgIcon
							v-if="selection.includes(page.id)"
							data-test-id="check-export-modal"
							name="check"
							class="h-3 w-3 fill-current text-white"
						/>
					</span>
					<Canvas
						use-render-queue
						:page="(page as Page)"
						class="h-full w-full"
						fit-to="height"
						preview
						:preview-scale="2"
					/>
				</button>
			</div>
			<div class="mt-2 flex items-center justify-center lg:mx-auto lg:max-w-xl">
				<button
					class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300"
					@click="closeModal()"
				>
					Cancel
				</button>
				<button
					class="mx-2 flex h-12 items-center justify-center rounded border-2 border-blue-500 bg-blue-500 px-8 text-lg font-semibold text-white hover:border-blue-400 hover:bg-blue-400"
					:class="selection.length === 0 ? 'pointer-events-none opacity-50' : ''"
					@click="continueToDownload"
				>
					Continue
				</button>
			</div>
		</div>
	</Modal>
</template>
