<script setup lang="ts">
import { onClickOutside, useClipboard } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useToast } from '@/composables/useToast';
import { useHistoryStore } from '@/stores/history';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import GAnalytics from '@/utils/GAnalytics';

const store = useMainStore();
const project = useProjectStore();
const history = useHistoryStore();

const isViewer = computed(() => location.href.includes('/share/'));

const props = defineProps<{ uuid?: string }>();
const emit = defineEmits<{ (e: 'close'): void }>();

const urlShare = computed(() => `${import.meta.env.VITE_APP_BASE}share/${props.uuid || store.userVector?.uuid}`);
const { copy, copied } = useClipboard();
const toast = useToast();

const modal = ref();
const title = "Take a look at this awesome template I've made on Wepik: ";

onClickOutside(modal, () => emit('close'));

onMounted(() => {
	if (!store.userVector && !isViewer.value) {
		project.triggerSync();
	}

	if (history.lastChangeFromNavigation && !isViewer.value) {
		project.performSyncWithStateInUse();
	}
});

const shareOnWhatsapp = () => {
	const url = `https://wa.me/?text=${title} ${encodeURIComponent(urlShare.value)}`;
	GAnalytics.track('click', 'Button', 'share-whatsapp', null);

	window.open(url, '_blank');
};

const shareOnFacebook = () => {
	const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlShare.value)}`;

	GAnalytics.track('click', 'Button', 'share-facebook', null);
	window.open(url, 'pagename', `resizable, height=600,width=500, top=200, left=600`);
};
const shareOnTwitter = () => {
	const url = `https://twitter.com/intent/tweet?text=${title}&url=${encodeURIComponent(urlShare.value)}`;
	GAnalytics.track('click', 'Button', 'share-twitter', null);

	window.open(url, 'pagename', `resizable, height=600,width=500, top=200, left=600`);
};
const shareOnPinterest = () => {
	if (store.userVector && store.userVector.preview.length) {
		const url = `https://pinterest.com/pin/create/bookmarklet/?media=${
			store.userVector?.preview
		}&description=${title}&url=${encodeURIComponent(urlShare.value)}`;

		GAnalytics.track('click', 'Button', 'share-pinterest', null);
		window.open(url, 'pagename', `resizable, height=600,width=500, top=200, left=600`);
	} else {
		toast.error('Error to share template');
	}
};

const shareOnCopyLink = () => {
	copy(urlShare.value);

	GAnalytics.track('click', 'button', 'copy-link', null);
};
</script>

<template>
	<div ref="modal" class="z-50 max-w-xs rounded bg-white p-3 text-left shadow-lg">
		<template v-if="(store.userVector && project.allChangesSaved) || isViewer">
			<p class="mb-3 font-semibold text-gray-700">Share this template</p>
			<div class="mb-2 flex">
				<a
					:href="urlShare"
					target="_blank"
					class="pointer-events-none flex h-8 w-64 min-w-0 items-center truncate rounded-tl rounded-bl bg-black/10 px-3 text-sm text-gray-600 lg:pointer-events-auto"
				>
					{{ urlShare }}
				</a>
			</div>
			<div class="flex gap-2">
				<button
					class="flex h-9 w-9 items-center justify-center rounded bg-[#4267B2] hover:opacity-90"
					@click="shareOnFacebook"
				>
					<SvgIcon name="facebook" class="h-4 w-4 text-white" />
				</button>
				<button
					class="flex h-9 w-9 items-center justify-center rounded bg-[#03A9F4] hover:opacity-90"
					@click="shareOnTwitter"
				>
					<SvgIcon name="twitter" class="h-4 w-4 text-white" />
				</button>
				<button
					class="flex h-9 w-9 items-center justify-center rounded bg-[#E60023] hover:opacity-90"
					@click="shareOnPinterest"
				>
					<SvgIcon name="pinterest" class="h-4 w-4 text-white" />
				</button>
				<button
					class="flex h-9 w-9 items-center justify-center rounded bg-[#25D366] hover:opacity-90"
					@click="shareOnWhatsapp"
				>
					<SvgIcon name="whatsapp" class="h-4 w-4 text-white" />
				</button>
				<button
					class="relative flex h-9 w-9 items-center justify-center rounded bg-gray-100/30 hover:opacity-90"
					@click="shareOnCopyLink"
				>
					<SvgIcon name="link" class="h-4 w-4 text-gray-600" />
					<div v-if="copied" class="copied-animation absolute top-3 left-4 text-xs text-blue-500 opacity-0">
						Copied!
					</div>
				</button>
			</div>
		</template>
		<template v-else>
			<div class="flex w-48 items-center justify-center bg-white text-gray-700">
				<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin text-blue-500" />
				<span class="text-sm font-semibold">Saving your template...</span>
			</div>
		</template>
	</div>
</template>
<style lang="sass" scoped>
.copied-animation
	animation: copied 1s forwards

	@keyframes copied
		0%
			opacity: 0

		90%
			opacity: 1
			left: 2.5rem

		100%
			opacity: 0
			left: 2.5rem
</style>
