import { MaybeRef } from '@vueuse/core';
import { findIndex } from 'lodash-es';
import { ref } from 'vue';
import { computed } from 'vue';

import Element from '@/Classes/Element';
import Page from '@/Classes/Page';
import { Shape } from '@/Classes/Shape';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useBugsnag } from '@/composables/useBugsnag';
import { useEditorMode } from '@/composables/useEditorMode';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { Color } from '@/Types/colorsTypes';
import { Size } from '@/Types/types';

const { bugsnagMsgWithDebounce } = useBugsnag();

export const usePage = (maybePage: MaybeRef<Page>) => {
	const page = ref(maybePage);
	const project = useProjectStore();
	const store = useMainStore();

	const { isPhotoMode, isIllustratorContext } = useEditorMode();

	const temporalRef = ref<Element>(Shape.createDefault());
	const usingElementOrchestrator = useElementOrchestrator(temporalRef);

	const backgroundImage = computed(() =>
		page.value.backgroundImageId ? getElementById(page.value.backgroundImageId) : null
	);

	const position = computed(() => findIndex(project.pages, page.value));

	const adjustContent = (newArtboard: Size, oldArtboard: Size) => {
		const { height: newHeight, width: newWidth } = newArtboard;
		const { height: oldHeight, width: oldWidth } = oldArtboard;

		// Calculamos el tamaño que ocupa el contenido respecto al canvas
		const { x, y } = page.value.contentSize;
		let { width: contentWidth, height: contentHeight } = page.value.contentSize;

		// Limitamos el tamaño al del propio canvas y tener solo en cuenta la parte visible
		contentWidth = contentWidth > oldWidth ? oldWidth : contentWidth;
		contentHeight = contentHeight > oldHeight ? oldHeight : contentHeight;

		// Obtenemos la escala en función de los artboards
		const scale = calulateScale(newArtboard, oldArtboard);

		// Ajustamos el contenido a la escala
		const newContentHeight = contentHeight * scale;
		const newContentWidth = contentWidth * scale;

		const centeredPosition = {
			x: newContentWidth !== newWidth ? (newWidth - newContentWidth) / 2 : 0,
			y: newContentHeight !== newHeight ? (newHeight - newContentHeight) / 2 : 0,
		};

		page.value.elements.forEach((el) => {
			// Cancel adjust photo mode image to artboard
			if (isPhotoMode.value && backgroundImage.value?.id === el.id) return;

			temporalRef.value = el;
			const { adjustToNewArtboard } = usingElementOrchestrator.value;
			adjustToNewArtboard({ x, y }, centeredPosition, scale);
		});
	};

	/**
	 *
	 * @param newArtboard Objeto con width y height del nuevo artboard
	 * @param oldArtboard Objeto con width y height del anterior artboard
	 * @returns valor de la escala final
	 */
	const calulateScale = (
		newArtboard: { height: number; width: number },
		oldArtboard: { height: number; width: number }
	): number => {
		// Calculamos la escala en el ancho y en el alto para saber cuanto debemos mover y escalar los elementos
		const scaleWidth = newArtboard.width / oldArtboard.width;
		const scaleHeight = newArtboard.height / oldArtboard.height;

		let scale = 1;

		if (newArtboard.width < newArtboard.height) {
			// si el nuevo ancho es menor que el nuevo alto, ajustamos la escala al lado más pequeño (ancho)
			scale = scaleWidth;
		} else if (newArtboard.width > newArtboard.height) {
			// si el nuevo alto es menor que el nuevo ancho, ajustamos la escala al lado más pequeño (alto)
			scale = scaleHeight;
		} else {
			// En caso de que el nuevo ancho sea el mismo valor que el nuevo alto, tenemos en cuenta los valores anteriores del artboard
			if (oldArtboard.width < oldArtboard.height) {
				// si el antiguo ancho es menor que el antiguo alto, ajustamos la escala al lado más pequeño (ancho)
				scale = 1 / scaleWidth;
			} else if (newArtboard.width > newArtboard.height) {
				// si el antiguo alto es menor que el antiguo ancho, ajustamos la escala al lado más pequeño (alto)
				scale = 1 / scaleHeight;
			} else {
				// En caso de que los valores anteriores del artboard sean iguales devolvemos la escala más pequeña
				scale = Math.min(scaleWidth, scaleHeight);
			}
		}

		return scale;
	};

	const setBackground = (background: Color) => {
		page.value.background = background;
		// Al inicio del modo foto se ejecuta, evitamos que aparezca en Bugsnag para no generar ruído
		if (!isPhotoMode) bugsnagMsgWithDebounce(`Modify background color`);
	};

	const addElement = (element: Element) => {
		page.value.elements.push(element);
	};

	const removeElement = (element: Element) => {
		// Remove is not allow if cropping or if element is photo mode image
		if (store.croppingId || (isPhotoMode.value && backgroundImage.value?.id === element.id)) return;

		page.value.elements = page.value.elements.filter((e) => e.id !== element.id);

		// Si está agrupado y solo queda un elemento le eliminamos el grupo
		const elementsFromGroup = getElementsFromGroup(element.group);
		if (elementsFromGroup.length === 1) {
			elementsFromGroup.forEach((el) => (el.group = null));
		}

		// Si era la imagen que estaba como background la borramos del page
		if (page.value.backgroundImageId === element.id) {
			page.value.backgroundImageId = null;
		}

		// Lo restauramos en el modo illustrator
		if (isIllustratorContext.value) {
			element.metadata.illustratorLinks.forEach((illustratorLink: string) => {
				store.illustratorElementsMoved.delete(illustratorLink);
			});
		}

		if (!store.selection.length) {
			store.clearSelection();
		}
	};

	const getElementById = (id: string): Element | undefined => {
		return page.value.elements.find((pageElement) => pageElement.id === id);
	};

	const getElementFromDom = (element: HTMLElement): Element | undefined => {
		const id = element.id.substring(8);
		return getElementById(id);
	};

	// TODO computed del element?
	const getElementIndex = (element: Element) => page.value.elements.findIndex((el) => el.id === element.id);

	const getElementsFromGroup = (group: string | null): Element[] => {
		if (!group) return [];
		return page.value.elements.filter((el) => el.group === group);
	};

	return {
		backgroundImage,
		adjustContent,
		setBackground,
		addElement,
		removeElement,
		getElementById,
		getElementFromDom,
		getElementIndex,
		position,
	};
};
