<script lang="ts" setup>
import { until } from '@vueuse/core';
import Moveable, { MoveableOptions, OnDrag } from 'moveable';
import { computed, onBeforeUnmount, ref, toRef, watch } from 'vue';

import Line from '@/Classes/Line';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useMainStore } from '@/stores/store';
import { Position } from '@/Types/types';
import ElementTools from '@/utils/ElementTools';
import MathTools from '@/utils/MathTools';

// Props
const props = defineProps<{ element: Line }>();

// Data
const element = toRef(props, 'element');

const store = useMainStore();
const { disableToolbar } = useInteractions();

const handler1 = ref();
const handler2 = ref();
const handler1Moveable = ref<Moveable | null>(null);
const handler2Moveable = ref<Moveable | null>(null);
const transformHandler1 = ref({ x: 0, y: 0 });
const transformHandler2 = ref({ x: 0, y: 0 });

const handlerSize = 10 / store.scale;
const lockAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];

const setHandlersPosition = () => {
	// Calculamos la posición de los handlers
	const origin = {
		x: element.value.position.x + element.value.size.width / 2 - handlerSize / 2,
		y: element.value.position.y + element.value.size.height / 2 - handlerSize / 2,
	};
	const firstCoords = MathTools.rotatePoint(
		origin.x,
		origin.y,
		element.value.position.x - handlerSize / 2,
		origin.y,
		element.value.rotation
	);
	const lastCoords = MathTools.rotatePoint(
		origin.x,
		origin.y,
		element.value.position.x + element.value.size.width - handlerSize / 2,
		origin.y,
		element.value.rotation
	);

	handler1.value.style.transform = `translate(${firstCoords.x}px, ${firstCoords.y}px)`;
	handler2.value.style.transform = `translate(${lastCoords.x}px, ${lastCoords.y}px)`;

	transformHandler1.value = {
		x: firstCoords.x,
		y: firstCoords.y,
	};
	transformHandler2.value = {
		x: lastCoords.x,
		y: lastCoords.y,
	};
};

const dragLineHandler = (handler1Position: Position, handler2Position: Position, ev: OnDrag) => {
	// La rotación y el ancho depende de los handlers
	let angle = MathTools.getAngle(handler1Position.x, handler1Position.y, handler2Position.x, handler2Position.y);
	const width = MathTools.getDistanceBetween2Points(
		handler1Position.x,
		handler1Position.y,
		handler2Position.x,
		handler2Position.y
	);

	// Posición real del elemento como tal
	const linePosition = {
		x: handler1Position.x + handlerSize / 2,
		y: handler1Position.y - element.value.size.height / 2 + handlerSize / 2,
	};

	// Centro del elemento para calcular el diff respecto a los handlers al mover la línea
	const origin = {
		x: linePosition.x + width / 2 - handlerSize / 2,
		y: linePosition.y + element.value.size.height / 2 - handlerSize / 2,
	};

	// Calculamos donde estará la línea tras la rotación para corregir su posición respecto al handler
	const rotatePosition = MathTools.rotatePoint(origin.x, origin.y, handler1Position.x, handler1Position.y, angle);

	const diff = {
		x: handler1Position.x - rotatePosition.x,
		y: handler1Position.y - rotatePosition.y,
	};

	if (ev.inputEvent.shiftKey) {
		// Buscamos el ángulo bloqueado más cercano
		let closestAngle = lockAngles.reduce((prev, curr) =>
			Math.abs(curr - angle) < Math.abs(prev - angle) ? curr : prev
		);

		// Para evitar saltos extraños cuando queremos usar líneas rectas
		if (closestAngle === 360) {
			closestAngle = 0;
		}

		if (ev.target.dataset.handler === '1') {
			// Calculamos el ángulo en base al handler 2 para poder calcular la posición del handler 1 bloqueado
			const supportAngle = MathTools.getAngle(
				handler2Position.x,
				handler2Position.y,
				handler1Position.x,
				handler1Position.y
			);

			// Buscamos el ángulo bloqueado más cercano
			let closestAngleHandler = lockAngles.reduce((prev, curr) =>
				Math.abs(curr - supportAngle) < Math.abs(prev - supportAngle) ? curr : prev
			);

			// Para evitar saltos extraños cuando queremos usar líneas rectas
			if (closestAngleHandler === 360) {
				closestAngleHandler = 0;
			}

			const newPosition = {
				x: handler2Position.x + handlerSize / 2 + width * Math.cos(MathTools.angleToRadians(closestAngleHandler)),
				y:
					handler2Position.y +
					handlerSize / 2 -
					element.value.size.height / 2 +
					width * Math.sin(MathTools.angleToRadians(closestAngleHandler)),
			};

			linePosition.x = newPosition.x;
			linePosition.y = newPosition.y;

			const tempHandlerPosition = {
				x: handler2Position.x + width * Math.cos(MathTools.angleToRadians(closestAngleHandler)),
				y: handler2Position.y + width * Math.sin(MathTools.angleToRadians(closestAngleHandler)),
			};

			const origin = {
				x: linePosition.x + width / 2 - handlerSize / 2,
				y: linePosition.y + element.value.size.height / 2 - handlerSize / 2,
			};

			const rotatePosition = MathTools.rotatePoint(
				origin.x,
				origin.y,
				tempHandlerPosition.x,
				tempHandlerPosition.y,
				closestAngle
			);

			diff.x = tempHandlerPosition.x - rotatePosition.x;
			diff.y = tempHandlerPosition.y - rotatePosition.y;
		} else {
			const rotatePosition = MathTools.rotatePoint(
				origin.x,
				origin.y,
				handler1Position.x,
				handler1Position.y,
				closestAngle
			);

			diff.x = handler1Position.x - rotatePosition.x;
			diff.y = handler1Position.y - rotatePosition.y;
		}

		angle = closestAngle;
	}

	store.$patch(() => {
		element.value.position = {
			x: linePosition.x + diff.x,
			y: linePosition.y + diff.y,
		};

		element.value.rotation = angle;
		element.value.size.width = width;
	});
};

const setupLineHandlersEvents = () => {
	if (!handler1.value && !handler2.value) return;

	const moveableConfig: MoveableOptions = {
		renderDirections: false,
		hideDefaultLines: true,
		snappable: false,
		draggable: true,
		resizable: false,
		rotatable: false,
		pinchable: false,
		origin: false,
		keepRatio: true,
		edge: false,
		rootContainer: document.getElementById('scroll-area'),
		container: document.getElementById('scroll-area'),
		checkInput: true,
		className: 'moveable-lines',
	};

	handler1Moveable.value = new Moveable(document.body, {
		target: handler1.value,
		portalContainer: document.getElementById('portalTargetHandlerLine1'),
		...moveableConfig,
	});

	handler2Moveable.value = new Moveable(document.body, {
		target: handler2.value,
		portalContainer: document.getElementById('portalTargetHandlerLine2'),
		...moveableConfig,
	});

	handler1Moveable.value
		?.on('drag', (ev: OnDrag) => {
			const { beforeTranslate } = ev;

			// Obtenemos la posición del handler usando un transform
			const hanlder2Transform = ElementTools.getTransformValues((handler2.value as HTMLDivElement).style.transform);

			const handler1Position = {
				x: beforeTranslate[0],
				y: beforeTranslate[1],
			};

			const handler2Position = {
				x: hanlder2Transform[0],
				y: hanlder2Transform[1],
			};

			transformHandler1.value = {
				x: handler1Position.x,
				y: handler1Position.y,
			};

			dragLineHandler(handler1Position, handler2Position, ev);
		})
		.on('dragStart', () => dragStartHandler())
		.on('dragEnd', () => dragEndHandler());

	handler2Moveable.value
		?.on('drag', (ev: OnDrag) => {
			const { beforeTranslate } = ev;

			// Obtenemos la posición del handler usando un transform
			const hanlder1Transform = ElementTools.getTransformValues((handler1.value as HTMLDivElement).style.transform);

			const handler1Position = {
				x: hanlder1Transform[0],
				y: hanlder1Transform[1],
			};

			const handler2Position = {
				x: beforeTranslate[0],
				y: beforeTranslate[1],
			};

			transformHandler2.value = {
				x: handler2Position.x,
				y: handler2Position.y,
			};

			dragLineHandler(handler1Position, handler2Position, ev);
		})
		.on('dragStart', () => dragStartHandler())
		.on('dragEnd', () => dragEndHandler());
};

const dragStartHandler = () => {
	disableToolbar.value = true;

	handler1.value.classList.add('opacity-0');
	handler2.value.classList.add('opacity-0');

	document.querySelector('#portalTarget')?.classList.add('opacity-0');
};

const dragEndHandler = () => {
	disableToolbar.value = false;

	handler1.value.classList.remove('opacity-0');
	handler2.value.classList.remove('opacity-0');

	document.querySelector('#portalTarget')?.classList.remove('opacity-0');
};

const removeMoveableHandlers = () => {
	handler1Moveable.value?.destroy();
	handler2Moveable.value?.destroy();

	handler1Moveable.value = null;
	handler2Moveable.value = null;

	document.querySelector('#portalTargetHandlerLine1')?.removeAttribute('style');
	document.querySelector('#portalTargetHandlerLine2')?.removeAttribute('style');
};

until(computed(() => handler1.value !== undefined && handler2.value !== undefined))
	.toBeTruthy()
	.then(() => {
		setHandlersPosition();
		setupLineHandlersEvents();
	});

onBeforeUnmount(() => removeMoveableHandlers());

// Para actualizar los handler al modificar el elemento desde el panel
watch(props.element, () => {
	const panelOpened = !!document.querySelector('#line-panel');

	if (panelOpened) {
		setHandlersPosition();
		handler1Moveable.value?.updateRect();
		handler2Moveable.value?.updateRect();
	}
});
</script>

<template>
	<div
		ref="handler1"
		data-handler="1"
		class="line-handler"
		:style="{
			height: `${handlerSize}px`,
			width: `${handlerSize}px`,
			transform: `translate(${transformHandler1.x}px, ${transformHandler1.y}px)`,
		}"
	></div>
	<div
		ref="handler2"
		data-handler="2"
		class="line-handler"
		:style="{
			height: `${handlerSize}px`,
			width: `${handlerSize}px`,
			transform: `translate(${transformHandler2.x}px, ${transformHandler2.y}px)`,
		}"
	></div>
</template>

<style lang="sass">
.moveable-element-line
	@apply pointer-events-none opacity-0

#portalTargetHandlerLine1[style], #portalTargetHandlerLine2[style]
	@apply pointer-events-none bg-white rounded-full border
	height: 10px
	width: 10px
	border-color: var(--moveable-color)

.line-handler
	@apply absolute left-0 top-0 cursor-move
	height: 10px
	width: 10px

#portalTargetHandlerLine1[class*='moveable-dragging'], #portalTargetHandlerLine2[class*='moveable-dragging'], .line-handler
	@apply opacity-0
</style>
