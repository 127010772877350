import { Element as SvgElement, LinkedHTMLElement, SVG } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';

import Element from '@/Classes/Element';
import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
import { ImageApi } from '@/Types/apiClient';
import { Color } from '@/Types/colorsTypes';
import { Flip, Position, SerializedClass, Size, ViewBox } from '@/Types/types';
import ElementTools from '@/utils/ElementTools';

export class Shape extends Element {
	type: 'shape' = 'shape';
	viewbox: string;
	content: string;
	colors: Color[];

	protected constructor(
		// Element
		metadata: object,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number,
		// Shape
		viewbox: string,
		content: string,
		colors: Color[]
	) {
		super(metadata, size, position, rotation, flip, group, locked, keepProportions, opacity);

		this.viewbox = viewbox;
		this.content = content;
		this.colors = colors;
	}

	public get viewboxObject(): ViewBox {
		const viewboxData = this.viewbox.split(' ').map(Number);

		return {
			x: viewboxData[0],
			y: viewboxData[1],
			width: viewboxData[2],
			height: viewboxData[3],
		};
	}

	static defaults() {
		return {
			// Element
			metadata: {},
			size: { height: 0, width: 0 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: true,
			opacity: 1,
			// Shape
			viewbox: '0 0 100 100',
			content: '',
			colors: [] as Color[],
		};
	}

	static create(viewBox: string, content: string, config?: Partial<Shape>): Shape {
		const defaults = Shape.defaults();

		return new Shape(
			// Element
			config?.metadata || defaults.metadata,
			config?.size || defaults.size,
			config?.position || defaults.position,
			config?.rotation || defaults.rotation,
			config?.flip || defaults.flip,
			config?.group || defaults.group,
			config?.locked || defaults.locked,
			config?.keepProportions || defaults.keepProportions,
			config?.opacity || defaults.opacity,
			// Shape
			viewBox,
			content,
			config?.colors || defaults.colors
		);
	}

	static unserialize(data: SerializedClass<Shape>): Shape {
		const defaults = Shape.defaults();

		const {
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// Shape
			viewbox,
			content,
			colors,
		} = data;

		const fixedArrayColors: Color[] | undefined =
			Array.isArray(colors) || !colors ? colors : Object.values(colors as any);

		const elem = new Shape(
			// Element
			metadata || defaults.metadata,
			size || defaults.size,
			position || defaults.position,
			rotation !== undefined ? rotation : defaults.rotation,
			flip || defaults.flip,
			group || defaults.group,
			locked !== undefined ? locked : defaults.locked,
			keepProportions !== undefined ? keepProportions : defaults.keepProportions,
			opacity !== undefined ? opacity : defaults.opacity,
			// Shape
			viewbox || defaults.viewbox,
			content || defaults.content,
			fixedArrayColors
				? fixedArrayColors.map((c) => ('stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)))
				: defaults.colors
		);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static fromSvg(rawSvg: string): Shape {
		rawSvg = rawSvg.substring(rawSvg.indexOf('<svg'));

		const shapeSvg = SVG(rawSvg);

		const colors: Color[] = [];

		// Añadimos un <g> temporal para obtener info del contenido
		shapeSvg.node.innerHTML = `<g>${shapeSvg.node.innerHTML}</g>`;

		const mainG = shapeSvg.first();
		const { x, y, height, width } = mainG.bbox();

		ElementTools.fixDefsPosition(shapeSvg);
		ElementTools.changeGradientsReferencesByCloneStops(shapeSvg);
		// Si tenemos un attr de colores en un <g> la movemos a los hijos directos
		const gColors =
			'g[fill], g[fill-color], g[fill-opacity], g[stroke], g[stroke-color], g[stroke-opacity], g[stroke-width], g[style*="fill"], g[style*="stroke"], g[style*="opacity"]';
		let gWithColors = mainG.find(gColors);
		const validAttrs = [
			'fill',
			'fill-color',
			'fill-opacity',
			'stroke',
			'stroke-color',
			'stroke-opacity',
			'stroke-width',
		];
		const validStyles = ['fill', 'stroke', 'opacity'];

		while (gWithColors.length) {
			gWithColors.forEach((elG) => {
				const hasTransparentStroke = elG.attr('stroke') === 'transparent' || elG.attr('stroke-color') === 'transparent';

				elG.children().forEach((elChild) => {
					validAttrs.forEach((attr) => {
						// Mergeamos attrs
						if (elG.node.hasAttribute(attr)) {
							if (attr.includes('stroke') && hasTransparentStroke) return;
							elChild.attr(attr, elG.attr(attr));
						}

						// Mergeamos styles
						const fillCss = elG.css('fill');
						const strokeCss = elG.css('stroke');
						const opacityCss = elG.css('opacity');

						if (fillCss) {
							elChild.css('fill', fillCss);
						}

						if (strokeCss) {
							elChild.css('stroke', strokeCss);
						}

						if (opacityCss) {
							elChild.css('opacity', opacityCss);
						}
					});
				});

				// Eliminamos los attr y css ya mergeados
				validAttrs.forEach((attr) => {
					elG.attr(attr, null);
				});

				validStyles.forEach((attr) => {
					elG.css(attr as CSSStyleName, '');
				});
			});

			gWithColors = mainG.find(gColors);
		}

		mainG.find('[class]').forEach((elWithClass) => {
			const stylesTag = shapeSvg
				.find('style')
				.map((elStyle) => elStyle.node.innerHTML)
				.join('')
				.replaceAll('\n', '')
				.replaceAll('\t', '');

			ElementTools.getClassStyles(stylesTag, elWithClass.attr('class')).forEach((classProps) => {
				elWithClass.css(classProps.key, classProps.value);
			});

			elWithClass.attr('class', null);
		});

		mainG.find('style').forEach((styleTag) => styleTag.remove());

		// Anulamos todos los transforms
		mainG.find('*:not(g)').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			let parent = elChild.parent() as SvgElement;

			// Vamos aplicando los transforms según buscamos el g base que contiene el
			// clip-path container
			while (parent !== null && parent.type !== 'svg') {
				if (elChild.type === 'DIV' || elChild.type === 'metadata') {
					return;
				}
				elChild.transform(parent.transform(), true);

				parent = parent.parent() as SvgElement;
			}

			// Eliminamos los data-* de los hijos
			Object.keys(elChild.node.dataset).forEach((dataKey) => delete elChild.node.dataset[dataKey]);

			// Anulamos la posición dada por el bbox del elemento
			elChild.transform({ translateX: -x, translateY: -y }, true);
		});

		// Aplicamos la lógica anterior a los clipPath del elemento
		mainG.find('[style*="clip-path"]').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			let parent = elChild.parent() as SvgElement;
			const clipPathId = ElementTools.getIdFromUrl(elChild.css('clip-path').toString());
			const clipPathElement = shapeSvg.defs().findOne(clipPathId)?.first() as SvgElement;

			while (parent !== null && parent.type !== 'svg') {
				clipPathElement.transform(parent.transform(), true);

				parent = parent.parent() as SvgElement;
			}

			clipPathElement.transform({ translateX: -x, translateY: -y }, true);
		});

		// Los transform ya no valen para nada, además eliminamos el resto de attrs
		mainG.find('g').forEach((elChild) => {
			const attrs = elChild.attr();

			Object.keys(attrs).forEach((attr: any) => {
				if (!attrs[attr].toString().includes('clip-path')) {
					elChild.attr(attr, null);
				}
			});
		});

		// Eliminamos los data-* del elemento base
		Object.keys(mainG.node.dataset).forEach((dataKey) => delete mainG.node.dataset[dataKey]);

		// Normalizamos los colores, queremos que siempre sean rgba
		mainG.find('*:not(g)').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			const isInvalidFill =
				!elChild.node.hasAttribute('style') ||
				!elChild.attr('style').includes('fill') ||
				elChild.css('fill') === 'none';

			const isInvalidStroke =
				!elChild.node.hasAttribute('style') ||
				!elChild.attr('style').includes('stroke') ||
				elChild.css('stroke') === 'none';

			// Transformamos el fill
			if (!isInvalidFill) {
				let color: GradientColor | SolidColor;

				if (elChild.css('fill').includes('url')) {
					const idGradient = ElementTools.getIdFromUrl(elChild.css('fill'));

					const gradient = shapeSvg.defs().findOne(idGradient) as SvgElement;
					color = ElementTools.svgGradientToObject(gradient);
				} else {
					const [r, g, b, a] = Normalize(elChild.css('fill'));
					const opacity = parseFloat(elChild.css('opacity')) || a;

					elChild.css('fill', '');
					elChild.css('opacity', '');

					const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${opacity})`;

					color = SolidColor.fromString(rgba);
				}

				const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

				if (!colorExist) {
					colors.push(color);
				}

				// nos aseguramos de que el fill está vacío
				elChild.node.style.fill = '';

				// Lo asignamos desde attr para evitar que transforme el rgba a rgb al tener opacidad 1
				const style = elChild.attr('style') || '';

				elChild.attr('style', style + ` fill: var(--${colorExist ? colorExist.id : color.id});`);
			}

			// Transformamos el stroke
			if (!isInvalidStroke) {
				let color: GradientColor | SolidColor;

				if (elChild.css('stroke').includes('url')) {
					const idGradient = ElementTools.getIdFromUrl(elChild.css('stroke'));

					const gradient = shapeSvg.defs().findOne(idGradient) as SvgElement;
					color = ElementTools.svgGradientToObject(gradient);
				} else {
					const [r, g, b, a] = Normalize(elChild.css('stroke'));

					elChild.css('stroke', '');

					const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a})`;

					color = SolidColor.fromString(rgba);
				}

				const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

				if (!colorExist) {
					colors.push(color);
				}

				// nos aseguramos de que el stroke está vacío
				elChild.node.style.stroke = '';

				// Lo asignamos desde attr para evitar que transforme el rgba a rgb al tener opacidad 1
				const style = elChild.attr('style') || '';

				elChild.attr('style', style + ` stroke: var(--${colorExist ? colorExist.id : color.id});`);
			}
		});

		// Movemos los attrs a styles
		mainG
			.find('[fill], [fill-color], [fill-opacity], [stroke], [stroke-color], [stroke-opacity], [stroke-width]')
			.forEach((elChild) => {
				if (ElementTools.checkIfIsDefsElement(elChild)) return;

				// Normalizamos fill-color y fill-opacity
				const fillColor = elChild.node.getAttribute('fill-color') || elChild.node.getAttribute('fill');
				const fillOpacity = elChild.attr('fill-opacity') !== undefined ? parseFloat(elChild.attr('fill-opacity')) : 1;

				if (fillColor && fillColor !== 'transparent' && fillColor !== 'none') {
					let color: GradientColor | SolidColor;

					if (fillColor.includes('url')) {
						const idGradient = ElementTools.getIdFromUrl(fillColor);

						const gradient = shapeSvg.findOne(idGradient) as SvgElement;
						color = ElementTools.svgGradientToObject(gradient);
					} else {
						const [r, g, b] = Normalize(fillColor);
						const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${fillOpacity})`;
						color = SolidColor.fromString(rgba);
					}

					elChild.attr('fill-color', null);
					elChild.attr('fill', null);

					if (fillOpacity !== 0) {
						elChild.attr('fill-opacity', null);
					}

					const style = elChild.attr('style') || '';
					const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

					if (!colorExist) {
						colors.push(color);
					}

					elChild.attr('style', style + ` fill: var(--${colorExist ? colorExist.id : color.id});`);
				}

				// Normalizamos el stroke-color y stroke-opacity
				const strokeColor = elChild.node.getAttribute('stroke-color') || elChild.node.getAttribute('stroke');
				const strokeOpacity =
					elChild.attr('stroke-opacity') !== undefined ? parseFloat(elChild.attr('stroke-opacity')) : 1;

				if (strokeColor && strokeColor !== 'transparent' && strokeColor !== 'none') {
					let color: GradientColor | SolidColor;

					if (strokeColor.includes('url')) {
						const idGradient = ElementTools.getIdFromUrl(strokeColor);

						const gradient = shapeSvg.findOne(idGradient) as SvgElement;
						color = ElementTools.svgGradientToObject(gradient);
					} else {
						const [r, g, b] = Normalize(strokeColor);
						const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${strokeOpacity})`;
						color = SolidColor.fromString(rgba);
					}

					elChild.attr('stroke-color', null);
					elChild.attr('stroke', null);

					if (strokeOpacity !== 0) {
						elChild.attr('stroke-opacity', null);
					}

					const style = elChild.attr('style') || '';
					const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

					if (!colorExist) {
						colors.push(color);
					}

					elChild.attr('style', style + ` stroke: var(--${colorExist ? colorExist.id : color.id});`);
				}

				// Normalizamos stroke-width
				const strokeWidth = elChild.attr('stroke-width');

				if (strokeWidth) {
					elChild.attr('stroke-width', null);
					elChild.css('strokeWidth', strokeWidth);
				}
			});

		// Si no hay style añadimos el color por defecto que pone el navegador para poder soportarlo
		mainG.find('*:not(g):not([style])').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			const style = elChild.attr('style') || '';
			const color = SolidColor.fromString('rgba(0,0,0,1)');
			const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

			if (!colorExist) {
				colors.push(color);
			}

			elChild.attr('style', style + ` fill: var(--${colorExist ? colorExist.id : color.id});`);
		});

		// Si el path no tiene fill pero si stroke le ponemos fill none para evitar que el contenido se vea negro
		mainG.find('path').forEach((path) => {
			const hasFill = path.css('fill');
			const hasStroke = path.css('stroke');

			if (!hasFill && hasStroke) {
				path.css('fill', 'none');
			}
		});

		// Extraemos los clip-path que pueda tener el elemento
		const clipPathIds = shapeSvg
			.find('[style*="clip-path"]')
			.map((el) => ElementTools.getIdFromUrl(el.css('clip-path').toString()));

		// Mantenemos solo los defs que nos interesan
		shapeSvg
			.defs()
			.children()
			.each((def) => !clipPathIds.includes(`#${def.id()}`) && def.remove());

		// Buscamos los elementos a mover
		const elements = mainG
			.find('*:not(g)')
			.filter((el) => !el.node.closest('defs'))
			.map((el) => {
				const gWithClipPath = el.node.closest('g[style*="clip-path"]');

				return gWithClipPath ? (gWithClipPath as LinkedHTMLElement).instance : el;
			});

		Array.from(new Set(elements)).forEach((el) => el.insertBefore(mainG));

		// Eliminamos los g vacíos
		let groups = mainG.find('g').filter((g) => g.children().length === 0);

		while (groups.length) {
			groups.forEach((g) => g.remove());
			groups = mainG.find('g').filter((g) => g.children().length === 0);
		}

		const viewBox = `0 0 ${width} ${height}`;
		const content = shapeSvg.node.innerHTML.toString();

		const size = {
			width: parseFloat(width.toString()) * parseFloat(mainG.transform('scaleX').toString()),
			height: parseFloat(height.toString()) * parseFloat(mainG.transform('scaleY').toString()),
		};

		const newShape = Shape.create(viewBox, content, { colors, position: { x, y }, size });

		return newShape;
	}

	static async fromApiImage(img: ImageApi): Promise<Shape> {
		if (img.type !== 'svg') {
			return Shape.createDefault();
		}

		const svgNode = await (await fetch(img.url)).text();

		return this.fromSvg(svgNode);
	}

	static createDefault(merge = {}) {
		let defaults = Shape.defaults();

		defaults = { ...defaults, ...merge };

		return new Shape(
			// Element
			defaults.metadata,
			defaults.size,
			defaults.position,
			defaults.rotation,
			defaults.flip,
			defaults.group,
			defaults.locked,
			defaults.keepProportions,
			defaults.opacity,
			// Shape
			defaults.viewbox,
			defaults.content,
			defaults.colors
		);
	}
}
