<script setup lang="ts">
// Vue
import { computed, toRef } from 'vue';

// Classes
import Line from '@/Classes/Line';
// Components
import GradientsRenderer from '@/components/render/GradientsRenderer.vue';
import { useLineColors } from '@/composables/element/line/useLineColors';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';

// Composables
import LineHandlers from './LineHandlers.vue';

// Props
const props = defineProps<{ element: Line; forPreview?: boolean; scale: number; smartSelection?: boolean }>();

// Data
const element = toRef(props, 'element');

const mobileBoxSize = computed(() => {
	const extra = 2;
	const min = 15;
	const size = element.value.size.height * extra;

	return size < min ? min : size;
});

// Using composables
const store = useMainStore();
const { gradientColors } = useLineColors(element);
const usingElementOrchestrator = useElementOrchestrator(element);
const { page } = usingElementOrchestrator.value;
const { isIdle } = useInteractions();
const { isMobile } = useDeviceInfo();
</script>

<template>
	<svg class="relative block h-full w-full overflow-visible" style="min-height: 1px; min-width: 1px">
		<g :transform="`scale(${element.size.height}) translate(0, 0.5)`">
			<line
				y1="0"
				:x2="element.size.width / element.size.height"
				:style="{
					stroke: element.mainColor.toShapeString(),
					'stroke-linecap': element.linecap,
					'stroke-dasharray': element.dasharrayToString(),
				}"
			/>
			<g
				v-if="element.markerStart"
				transform="translate(0)"
				:style="{
					'--start-sticky-color': element.markerStart.color.toShapeString(),
				}"
				v-html="element.markerStart.element"
			></g>
			<g
				v-if="element.markerEnd"
				:transform="`translate(${element.size.width / element.size.height})`"
				:style="{
					'--end-sticky-color': element.markerEnd.color.toShapeString(),
				}"
				v-html="element.markerEnd.element"
			></g>
		</g>
		<!-- Para mejorar la selección en mobile -->
		<rect
			v-if="isMobile"
			:transform="`translate(0, -${mobileBoxSize / 4})`"
			:width="element.size.width"
			:height="mobileBoxSize"
			style="fill: rgba(0, 0, 0, 0)"
		/>
	</svg>
	<GradientsRenderer :gradients="gradientColors" />
	<Teleport
		v-if="store.selection.length === 1 && store.selection[0] === element && isIdle"
		:to="page?.domNode()?.firstElementChild"
	>
		<LineHandlers :element="element" />
	</Teleport>
</template>
