class ErrorPhotoModeUrl extends Error {
	constructor(...params) {
		super(...params);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ErrorPhotoModeUrl);
		}

		this.name = 'ErrorPhotoModeUrl';
	}
}

export default ErrorPhotoModeUrl;
