<script setup lang="ts">
import { useScriptTag, useScroll } from '@vueuse/core';
import { MaybeRef } from '@vueuse/gesture';
import { computed, defineAsyncComponent } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import Shortcuts from '@/components/skeleton/Shortcuts.vue';
import ZoomControls from '@/components/skeleton/ZoomControls.vue';
import { useProject } from '@/composables/project/useProject';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useUsersnap } from '@/composables/useUsersnap';
import { useMainStore } from '@/stores/store';

const Help = defineAsyncComponent(() => import('@/components/skeleton/Help.vue'));

const props = defineProps<{
	width: number;
	scrollArea: MaybeRef<HTMLElement>;
}>();

const { addEmptyPage, totalPages } = useProject();
const { isEditorMode } = useEditorMode();
const { isMobile } = useDeviceInfo();
const { arrivedState } = useScroll(props.scrollArea, { offset: { bottom: 60 } });
const { openReportBug } = useUsersnap();

const store = useMainStore();

const showAddNewPage = computed(() => {
	return (
		isEditorMode.value &&
		(totalPages.value === 1 || !!arrivedState.bottom) &&
		(!isMobile.value || (isMobile.value && !store.selectionId.length && totalPages.value === 1))
	);
});
</script>

<template>
	<div
		class="pointer-events-none fixed bottom-14 z-20 flex items-end justify-between p-4 lg:bottom-0"
		:style="{ width: `${props.width}px` }"
	>
		<button
			class="pointer-events-auto hidden h-10 items-center rounded bg-gray-800 px-3 text-sm font-semibold text-gray-100 hover:bg-blue-600 hover:text-white lg:flex"
			@click="openReportBug"
		>
			<SvgIcon name="alert" class="mr-2 h-4 w-4" /> Report an issue
		</button>

		<transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0">
			<button
				v-if="showAddNewPage"
				data-testid="addNewPage bottom"
				class="pointer-events-auto mx-auto flex items-center rounded-full bg-gray-800 py-2 px-4 text-sm text-gray-100 opacity-100 transition-opacity duration-500 ease-in-out hover:bg-gray-700"
				@click="addEmptyPage()"
			>
				<SvgIcon name="plus" class="mr-2 h-4 w-4" /> Add new page
			</button>
		</transition>

		<div class="pointer-events-auto hidden lg:flex">
			<Shortcuts />
			<Help />
			<ZoomControls />
		</div>
	</div>
</template>
