import Bugsnag from '@bugsnag/js';
import { useEventListener } from '@vueuse/core';
import { computed, ComputedRef, CSSProperties, nextTick, Ref, ref } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
import { Text } from '@/Classes/Text';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { useText } from '@/composables/element/text/useText';
import { useTextEffects } from '@/composables/element/text/useTextEffects';
import { useBugsnag } from '@/composables/useBugsnag';
import { useFonts } from '@/composables/useFonts';
import { useMainStore } from '@/stores/store';
import { Color } from '@/Types/colorsTypes';
import { ListStyle, SelectionStyle, StyleProperties, TextAlign, TextTransform } from '@/Types/text.d';
import { TextEffects } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';
import MathTools from '@/utils/MathTools';
import TextSelectionTools from '@/utils/TextSelectionTools';
import TextTools from '@/utils/TextTools';

/**
 * Retorna los fonts sizes de un texto
 * @param elements
 */
export const getFontSizes = (text: Ref<Text>, elements: HTMLElement[]) => {
	// Hack para forzar el recomputado de los textos
	text.value.fontSize;

	const fontSizes = elements
		.filter((el) => el.style[StyleProperties.fontSize])
		.map((el) => {
			if (
				el.classList.contains('provisional-text') ||
				el === document.querySelector(`#editable-${text.value.id}`) ||
				el === document.querySelector(`#element-${text.value.id} .text-element-final`)
			) {
				return text.value.fontSize;
			}
			return parseFloat(el.style[StyleProperties.fontSize]);
		});

	if (fontSizes.length) {
		return [...new Set(fontSizes.filter((size) => size >= 1))];
	}

	return [text.value.fontSize];
};

/**
 * Retorna el lineHeight de un texto
 * @param text texto principal
 * @param elements Elementos seleccionados
 */
export const getLineHeight = (text: Ref<Text>, elements: HTMLElement[]) => {
	// Hack para forzar el recomputado de los textos
	text.value.lineHeight;

	const lineHeight = elements
		.filter((el) => el.style[StyleProperties.lineHeight].length)
		.map((el) => {
			if (
				el.classList.contains('provisional-text') ||
				el === document.querySelector(`#editable-${text.value.id}`) ||
				el === document.querySelector(`#element-${text.value.id} .text-element-final`)
			) {
				return MathTools.toFixedOrInt(text.value.lineHeight);
			}
			return MathTools.toFixedOrInt(parseFloat(el.style[StyleProperties.lineHeight]));
		});

	if (lineHeight.length) {
		return [...new Set(lineHeight.filter((height) => height >= 0))];
	}

	return [MathTools.toFixedOrInt(text.value.lineHeight)];
};

/**
 * Retorna el letterSpacing de un texto
 * @param text texto principal
 * @param elements Elementos seleccionados
 */
export const getLetterSpacing = (text: Ref<Text>, elements: HTMLElement[]): number[] => {
	// Hack para forzar el recomputado de los textos
	text.value.letterSpacing;

	const letterSpacing = elements
		.filter((el) => el.style[StyleProperties.letterSpacing])
		.map((el) => {
			return parseFloat(el.style[StyleProperties.letterSpacing]);
		});

	if (letterSpacing.length) {
		return [...new Set(letterSpacing)];
	}

	return [text.value.letterSpacing];
};

/**
 * Retorna el lineHeight de un texto
 * @param elements
 */
export const getAllLineHeight = (text: Ref<Text>, elements: HTMLElement[], domNode: HTMLElement | null) => {
	// Hack para forzar el recomputado de los textos
	text.value.lineHeight;

	let lineHeight = <number[]>[];

	const mainNode = domNode;

	lineHeight = elements
		.filter((el) => el.style[StyleProperties.lineHeight].length)
		.map((el) => {
			if (el === mainNode) {
				return MathTools.toFixedOrInt(text.value.lineHeight);
			}
			return MathTools.toFixedOrInt(parseFloat(el.style[StyleProperties.lineHeight]));
		});
	if (lineHeight.length) {
		return [...new Set(lineHeight.filter((height) => height >= 0))];
	}

	return [MathTools.toFixedOrInt(text.value.lineHeight)];
};

/**
 * Retorna los font families de un texto
 * @param text
 * @param elements
 */
export const getFontFamilies = (text: Ref<Text>, elements: HTMLElement[]) => {
	text.value.fontFamily;

	const families = elements
		.filter((el) => el?.style[StyleProperties.fontFamily])
		.map((el) => el.style[StyleProperties.fontFamily].replace(/['"]+/g, ''));

	if (families.length) {
		return [...new Set(families.filter((family) => !!family))];
	}

	return [text.value.fontFamily];
};

/**
 * Retorna los colores de un texto
 * @param text
 * @param elements
 */
export const getTextColorVars = (text: Text) => {
	let mainNode =
		document.querySelector(`#editable-${text.id}`) || document.querySelector(`#element-${text.id} .text-element-final`);

	// Si no encuentra un nodo principal crearemos un texto provisional para poder obtener los colores de los nodos hijos
	let isProvisional = false;

	// Creamos el nodo provisional y seteamos el flag provisional para posteriormente eliminar el nodo provisional
	if (!mainNode) {
		document.body.appendChild(
			TextTools.createNodeFromString(
				`<div class='provisional-text' font-family: '${text.fontFamily}'">${text.content}</div>`
			)
		);

		mainNode = document.querySelector('.provisional-text');

		isProvisional = true;
	}

	const children = mainNode?.querySelectorAll('*');
	const haveTextNodes = Array.from(mainNode?.childNodes).find((el) => el.nodeName.toLowerCase() === '#text');
	const colors: any = {};

	if (children?.length) {
		children.forEach((child) => {
			if (child instanceof HTMLElement && child.style.color.length) {
				const childColor = child.style.color;
				let foundColor: Color | undefined;

				// Si los hijos son un color RGB, lo convertimos a variable y lo añadimos al array de colores
				if (!childColor.includes('var(')) {
					const solidColor = SolidColor.fromString(childColor);

					// Comprobamos que esté en el array de colores del texto
					foundColor = text.colors.find((c) => c.toCssString() === solidColor.toCssString());

					// En caso de no haberse encontrado se añade al array de colores
					if (!foundColor) {
						text.colors.push(solidColor);
					} else {
						// si se ha encontrado se equiparan los ids
						solidColor.id = foundColor.id;
					}

					// Se añade el color a los colores generales del texto
					colors[`--${solidColor.id}`] = solidColor;

					// Se reemplaza el color style por la variable
					child.style.color = `var(--${solidColor.id})`;
				} else {
					// En caso de ser un element.style.color variable buscamos su color correspondiente y lo añadimos a los colores generales
					const childColorSplitted = childColor.split('var(--')[1].split(')')[0];

					text.colors.forEach((c) => {
						if (c.id === childColorSplitted) {
							foundColor = c;
						}
					});

					if (foundColor) {
						colors[`--${foundColor.id}`] = foundColor;
					}
				}
			}
		});
	}

	// Si el texto tiene un color, tiene nodos Text como hijos (nodos texto no HTML) y además sus hijos HTML tienen el color del
	// padre se añade el color del root al array de colores
	if (
		text.color &&
		(haveTextNodes ||
			Array.from(children).filter(
				// Si el hijo no tiene color === color del root o si coincide con el color del padre
				(child) =>
					child.style.color === '' ||
					(child.style.color.includes('var(--') &&
						(child.style.color === text.color.id ||
							colors[child.style.color.split('var(--')[1].split(')')[0]] === text.color.id))
			).length)
	) {
		// @ts-ignore
		colors[`--${text.color.id}`] = text.color;
	}

	// Eliminamos los que no se hayan encontrado en el dom
	let removeIndex;

	Object.keys(text.colors).forEach((c: any, idx) => {
		const activeColor = Object.keys(colors).find(
			(localColor) => colors[localColor].toCssString() === text.colors[c].toCssString()
		);

		if (!activeColor) {
			removeIndex = idx;
		}
	});

	if (typeof removeIndex === 'number') {
		if (text.colors.length > 1) text.colors.splice(removeIndex, 1);
	}

	// Si existe un nodo provisional actualizamos el contenido del texto y eliminamos el nodo provisional
	if (isProvisional && mainNode) {
		text.content = mainNode.innerHTML;
		mainNode.remove();
	}

	return text.colors;
};

export const getTextStyles = (text: Ref<Text>) => {
	const inRenderingContext = !!window.RENDERER;

	return computed<Partial<CSSProperties>>(() => {
		const colorVars: any = {};
		text.value.colors.forEach((color) => (colorVars[`--${color.id}`] = color.toCssString()));

		const fixes: any = {};

		// si tiene sombra, aplicamos filter para que renderice bien en MAC / ios
		if (inRenderingContext && text.value.textShadow.some((ts) => ts.opacity)) {
			fixes['-webkit-filter'] = 'opacity(1)';
		}

		return {
			...fixes,
			...colorVars,
			fontFamily: `"${text.value.fontFamily}"`,
			fontSize: `${text.value.fontSize}px`,
			color: `var(--${text.value.color.id})`,
			fontStyle: text.value.fontStyle,
			fontWeight: text.value.fontWeight,
			textTransform: text.value.textTransform,
			letterSpacing: `${text.value.letterSpacing}px`,
			lineHeight: text.value.lineHeight,
			textAlign: text.value.textAlign,
			width: `${text.value.size.width / text.value.scale}px`,
			webkitTextStroke: `${text.value.outline.width}${text.value.outline.unit || 'px'} ${text.value.outline.color}`,
			transform: text.value.scale !== 1 ? `scale(${text.value.scale})` : undefined,
			transformOrigin: '0 0',
			wordBreak: 'break-word',
			textShadow: TextTools.textShadowToCssString(text.value.textShadow),

			// background: text.color.toCssString(),
			// webkitTextFillColor: text.color.isGradient() ? 'transparent' : text.color.toCssString(),
			// webkitBackgroundClip: 'text'
		};
	});
};

export const getFontWeights = (
	text: Ref<Text>,
	elements: HTMLElement[],
	fontFamilies: string[]
): { family: string; weight: string[] }[] => {
	text.value.fontWeight;
	text.value.fontStyle;
	text.value.fontFamily;

	const { getVariants } = useFonts();

	return getVariants(fontFamilies);
};

/**
 * Retorna el font Weight de un texto
 * @param text
 * @param elements
 */
export const getCurrentFontWeight = (text: Ref<Text>, elements: HTMLElement[]) => {
	text.value.fontFamily;
	text.value.fontWeight;
	text.value.fontStyle;
	let weights;

	weights = elements
		.filter((el) => el.style[StyleProperties.fontWeight])
		.map((el) => parseInt(el.style[StyleProperties.fontWeight]));

	if (!weights.length) {
		weights = elements
			.filter((el) => getComputedStyle(el)[StyleProperties.fontWeight])
			.map((el) => parseInt(getComputedStyle(el)[StyleProperties.fontWeight]));
	}

	return [...new Set([...weights])];
};

/**
 * Retorna el italic de un texto
 * @param text
 * @param elements
 */
export const getCurrentFontStyle = (text: Ref<Text>, elements: HTMLElement[]) => {
	text.value.fontFamily;
	text.value.fontStyle;
	text.value.fontWeight;
	let style;

	style = elements.filter((el) => el.style[StyleProperties.fontStyle]).map((el) => el.style[StyleProperties.fontStyle]);

	if (!style.length) {
		style = elements
			.filter((el) => getComputedStyle(el)[StyleProperties.fontStyle])
			.map((el) => getComputedStyle(el)[StyleProperties.fontStyle]);
	}

	return style;
};

/**
 * Obtiene el fontSize del elemento sobre el que se ha dejado el cursor
 * @param colors Array de colores solidos o gradientes
 * @returns Devuelve el número del fontSize o false en caso de no encontrarlo
 */
const getCurrentColor = (text: Ref<Text>, colors: ComputedRef<Color[]>, domNode: ComputedRef<HTMLElement | null>) => {
	let result: Color[] = [];

	if (domNode.value) {
		if (selection.value?.selection && selection.value?.selection.anchorNode) {
			const nodes = TextTools.getRangeSelectedNodes(selection.value?.selection.getRangeAt(0));
			const fullRange = TextSelectionTools.detectFullRange(selection.value?.selection, domNode.value);

			if (nodes.length && !fullRange) {
				nodes.forEach((node) => {
					const finalNode = node.nodeType === 1 ? (node as HTMLElement) : node.parentElement;

					if (!finalNode) return;

					const isChildOfDomNode =
						finalNode.closest(`#editable-${text.value.id}`) ||
						finalNode.closest(`#element-${text.value.id} .text-element-final`);

					if (!isChildOfDomNode) return;

					colors.value.forEach((c) => {
						if (
							finalNode?.style.color.includes('var(--') &&
							finalNode?.style.color.length &&
							c.id === finalNode?.style.color.split('var(--')[1].split(')')[0]
						) {
							if (!result.find((rc) => rc.id === c.id)) {
								result.push(c);
							}
						}
					});
				});
			} else if (fullRange) {
				colors.value.forEach((c) => result.push(c));
			} else {
				colors.value.forEach((c) => {
					if (
						domNode.value?.style.color.includes('var(--') &&
						c.id === domNode.value?.style.color.split('var(--')[1].split(')')[0]
					) {
						result = [c];
					}
				});
			}
		} else {
			colors.value.forEach((c) => {
				const mainNode = domNode.value;
				const childNodes = Array.from(mainNode?.querySelectorAll('*')) as HTMLElement[] | undefined;

				// Obtenemos el color del texto root
				if (
					mainNode?.style.color.includes('var(--') &&
					c.id === mainNode?.style.color.split('var(--')[1].split(')')[0] &&
					mainNode.childNodes.length >= 1 &&
					Array.from(mainNode.childNodes).find(
						(child: ChildNode | HTMLElement) =>
							child.nodeType === 3 ||
							(child.nodeType === 1 && child.style && child.style.color === mainNode.style.color)
					)
				) {
					result.push(c);
				}

				// Obtenemos el color de los hijos
				if (childNodes?.length) {
					for (const child in childNodes) {
						if (
							childNodes[child]?.style.color.includes('var(--') &&
							c.id === childNodes[child]?.style.color.split('var(--')[1].split(')')[0]
						) {
							result.push(c);
						}
					}
				}
			});
		}
	}

	if (!result.length) colors.value.forEach((c) => result.push(c));

	return result.sort();
};

/**
 * Versión no interactiva del hook de textos. No tiene en cuenta la selección.
 * @param text
 */
export const useTextStylesStatic = (text: Ref<Text>) => {
	const domNode = computed<HTMLElement | null>(() => {
		text.value.content;
		text.value.fontFamily;

		return (
			document.querySelector(`#editable-${text.value.id}`) ||
			text.value.domNode()?.querySelector('.text-element-final') ||
			TextTools.createNodeFromString(
				`<div class='provisional-text' style="font-family: '${text.value.fontFamily}'">${text.value.content}</div>`
			)
		);
	});

	const elements = computed(() => {
		return ([domNode.value, ...Array.from(domNode.value?.querySelectorAll('*') || [])] as HTMLElement[]).filter(
			(el) => !!el && el.nodeName !== '#comment'
		);
	});

	const colors = computed(() => getTextColorVars(text.value));

	const selectedColor = computed(() => {
		// Hack para recomputar colors cuando se rehaga la selección
		// hay casos en los que los colores no cambien pero necesite recomputar por la selección
		selection.value;
		return getCurrentColor(text, colors, domNode);
	});

	const fontFamily = computed(() => getFontFamilies(text, elements.value));
	const finalFontFamily = computed(() => (fontFamily.value.length > 1 ? 'Mixed Fonts' : fontFamily.value[0]));
	const fontSize = computed(() => getFontSizes(text, elements.value));
	const fontSizeScaled = computed(() => fontSize.value.map((size) => Math.round(size * text.value.scale)));
	const lineHeight = computed(() => getAllLineHeight(text, elements.value, domNode.value));
	const lineHeightLabel = computed(() => getLineHeight(text, elements.value).map((lh) => MathTools.toFixedOrInt(lh)));
	const fontWeight = computed(() => getCurrentFontWeight(text, elements.value));
	const italic = computed(() => getCurrentFontStyle(text, elements.value));
	const fontVariants = computed(() => getFontWeights(text, elements.value, fontFamily.value));

	const textTransform = computed(() => text.value.textTransform);
	const letterSpacing = computed(() => getLetterSpacing(text, elements.value));

	const outline = computed(() => text.value.outline);
	const textShadow = computed(() => text.value.textShadow);

	return {
		colors,
		selectedColor,
		fontFamily,
		finalFontFamily,
		fontSize,
		fontSizeScaled,
		lineHeight,
		lineHeightLabel,
		fontWeight,
		italic,
		fontVariants,
		textTransform,
		letterSpacing,
		outline,
		textShadow,
	};
};

/**
 * Hook para la interacción con texto, permite la obtención reactiva
 * de estilos de un texto y la manipulación en base a la selección
 * @param text
 */
export const useTextStyles = (text: Ref<Text>) => {
	const { fitHeight } = useText(text);
	const { loadFontsByName } = useFonts();
	const store = useMainStore();
	const scale = computed(() => store.scale);
	const { isCircleText } = useCircleTypeInfo(text, scale);
	const { breadScrumbWithDebounce } = useBugsnag(text as Ref<Text>);
	const { hasEcho, hasNeon, refreshEffect } = useTextEffects(text);

	const resetTextShadow = () => {
		text.value.textShadow = [
			{
				angle: 0,
				blur: 0,
				color: SolidColor.black(),
				distance: 0,
				opacity: 0,
			},
		];
	};

	const domNode = computed<HTMLElement | null>(() => {
		// Hack para forzar el recomputado de los textos
		text.value.content;

		if (!store.textEditing) {
			const domNode = text.value.domNode();

			if (!domNode) return null;

			return domNode.querySelector('.text-element-final');
		}

		return document.querySelector(`#editable-${text.value.id}`);
	});

	const { selection, selectedNodes } = useSelection(domNode);

	const selectedElements = computed(() => {
		if (!selectedNodes.value[0]) return [text.value.domNode() as HTMLElement];
		return selectedNodes.value
			.map((el) => (el.nodeType !== Node.TEXT_NODE ? el : el.parentElement))
			.filter((el) => !!el && el.nodeName !== '#comment') as HTMLElement[];
	});

	const colors = computed(() => {
		// Hack para recomputar colors cuando se rehaga la selección
		// hay casos en los que los colores no cambien pero necesite recomputar por la selección
		selection.value;
		return getTextColorVars(text.value);
	});

	const selectedColor = computed(() => getCurrentColor(text, colors, domNode));

	const fontFamily = computed(() => getFontFamilies(text, selectedElements.value));
	const finalFontFamily = computed(() => (fontFamily.value.length > 1 ? 'Mixed Fonts' : fontFamily.value[0]));
	const fontSize = computed(() => getFontSizes(text, selectedElements.value));
	const fontSizeScaled = computed(() => fontSize.value.map((size) => Math.round(size * text.value.scale)));

	const lineHeight = computed(() => getAllLineHeight(text, selectedElements.value, domNode.value));
	const lineHeightLabel = computed(() => getLineHeight(text, selectedElements.value).map((lh) => lh));

	const fontWeight = computed(() => getCurrentFontWeight(text, selectedElements.value));
	const italic = computed(() => getCurrentFontStyle(text, selectedElements.value));
	const fontVariants = computed(() => getFontWeights(text, selectedElements.value, fontFamily.value));
	const boldAvailable = computed(() => checkBoldAvailability(text, selectedElements.value));
	const italicAvailable = computed(() => checkItalicAvailability(text, selectedElements.value));

	const textTransform = computed(() => text.value.textTransform);

	const letterSpacing = computed(() => getLetterSpacing(text, selectedElements.value));
	const outline = computed(() => text.value.outline);

	const textShadow = computed(() => text.value.textShadow);

	const listStyle = computed(() => text.value.listStyle);
	const isMultiStyleText = computed(() => checkMultiStyleText(text, domNode.value));

	const link = computed(() => {
		let links: string[] = [];

		if (
			selection.value?.selection &&
			domNode.value &&
			TextSelectionTools.detectFullRange(selection.value?.selection, domNode.value)
		) {
			const linkElements = Array.from(domNode.value?.querySelectorAll(':scope > a'));
			if (linkElements.length) {
				links = linkElements.map((l) => l.getAttribute('href') as string);
			}
		} else {
			links = selectedElements.value
				.filter((el) => el.nodeName === 'A')
				.map((el) => {
					return el.getAttribute('href') as string;
				});
		}

		text.value.link = links.length > 1 ? [...new Set(links)] : links;

		return [...new Set(links)].join(',');
	});

	/**
	 * Comprueba si el texto es multi estilo
	 */
	const checkMultiStyleText = (text: Ref<Text>, domNode: HTMLElement | null) => {
		text.value.fontFamily;
		text.value.fontStyle;
		text.value.fontWeight;

		const children = domNode ? domNode.querySelectorAll('*') : [];

		if (children.length) {
			// Con .getAttribute("style") comprobamos los estilos en línea
			return !!Array.from(children).filter((el) => el.getAttribute('style')).length;
		}

		return false;
	};

	/**
	 * Comprueba si la funcionalidad de negrita está disponible disponible para la selección actual
	 * @param text elemento Text
	 * @param elements Elementos seleccionados
	 * @returns retorna peso de fuente
	 */
	const checkBoldAvailability = (text: Ref<Text>, elements: HTMLElement[]) => {
		text.value.fontFamily;
		text.value.fontStyle;
		text.value.fontWeight;

		if (fontVariants.value.length > 1) {
			return fontVariants.value.every((font: { family: string; weight: string[] }) => {
				if (fontWeight.value.length > 1) {
					let result = false;

					const elementStyles = elements.map((el) => [
						getComputedStyle(el)[StyleProperties.fontFamily],
						getCurrentFontWeight(text, [el]),
					]);

					elementStyles.forEach((el) => {
						if (el[0] === font.family && font.weight.find((weight: string) => parseInt(weight) >= 600)) {
							result = true;
						}
					});

					return result;
				}

				return font.weight.find((weight: string) => parseInt(weight) >= 600);
			});
		}

		return fontVariants.value[0].weight.find((weight: string) => parseInt(weight) >= 600);
	};

	/**
	 * Comprueba si la funcionalidad de itálica está disponible disponible para la selección actual
	 * @param text elemento Text
	 * @param elements Elementos seleccionados
	 * @returns retorna peso con ítalica
	 */
	const checkItalicAvailability = (text: Ref<Text>, elements: HTMLElement[]) => {
		text.value.fontFamily;
		text.value.fontStyle;
		text.value.fontWeight;

		if (fontVariants.value.length > 1) {
			return fontVariants.value.every((font: { family: string; weight: string[] }) => {
				if (fontWeight.value.length > 1) {
					let result = false;

					const elementStyles = elements.map((el) => [
						getComputedStyle(el)[StyleProperties.fontFamily],
						getCurrentFontWeight(text, [el]),
					]);

					elementStyles.forEach((el) => {
						if (el[0] === font.family && font.weight.find((weight: string) => weight === `${el[1]}i`)) {
							result = true;
						}
					});

					return result;
				}

				return font.weight.find((weight: string) => weight === `${fontWeight.value[0]}i`);
			});
		}

		return fontVariants.value[0].weight.find((weight: string) => weight === `${fontWeight.value[0]}i`);
	};

	/**
	 * Resetea una fuente y su texto
	 */
	const resetFont = () => {
		text.value.fontWeight = 400;
		resetChildrenStyle(StyleProperties.fontWeight);

		text.value.fontStyle = 'normal';
		resetChildrenStyle(StyleProperties.fontStyle);
	};

	const updateHeight = (): void => {
		requestAnimationFrame(() => {
			// Comprobamos si existe el nodo editable ya que si hacemos doble
			// click al pulsar en las familias de fuentes por ejemplo perdemos la referencia del nodo editable
			const editableNode = document.querySelector<HTMLElement>(`#editable-${text.value.id}`);
			const originalNode = document.querySelector<HTMLElement>(`#element-${text.value.id} .text-element-final`);

			if (editableNode) {
				fitHeight(editableNode);
			} else if (originalNode) {
				fitHeight(originalNode);
			}
		});
	};

	/**
	 * Resetea a '' los valores de la propiedad indicada para los hijos del nodo seleccionado
	 * @param style Hará referencia a una propiedad CSS fontSize, color, fontFamily
	 */
	const resetChildrenStyle = (style: SelectionStyle): void => {
		const nodes = Array.from(domNode.value?.querySelectorAll('*') as NodeListOf<Element>) as HTMLElement[];
		// En caso de que no haya elementos span dentro del texto, salimos
		if (!nodes.length) return;
		Array.from(nodes).forEach((n) => {
			n.style[style] = '';
		});
	};

	/**
	 * Aplicamos el valor al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param style Propiedad CSS
	 * @param value Valor para la propiedad CSS
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyStyleToRootElement = (style: SelectionStyle, value: any): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!store.textEditing ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(store.textEditing && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			let finalValue: number | string | SolidColor = value;

			if (text.value[style] instanceof Number && typeof value === 'string') {
				finalValue = parseFloat(value);
			}
			if (typeof text.value[style] === 'string' && value instanceof Number) {
				finalValue = `${value}`;
			}

			// Trackeamos cambio de color para google
			if (finalValue instanceof SolidColor && style === 'color' && text.value[style] instanceof SolidColor) {
				trackChangeColor(finalValue, text.value[style] as SolidColor);
			}

			// @ts-ignore
			text.value[style] = finalValue;

			if (finalValue instanceof SolidColor) {
				const foundColor = text.value.colors.find((c) => c.toCssString() === (finalValue as SolidColor).toCssString());
				if (!foundColor) {
					text.value.colors.push(finalValue);
				} else {
					foundColor.id = finalValue.id;
				}
			}

			if (domNode.value && domNode.value?.children.length) {
				resetChildrenStyle(style);
			}

			text.value.content = domNode.value?.innerHTML as string;

			return true;
		}

		return false;
	};

	/**
	 * Si hay alguna variación de color, se hace el track para google analytics
	 * @param color1 SolidColor
	 * @param color2 SolidColor
	 */
	const trackChangeColor = (color1: SolidColor, color2: SolidColor) => {
		if (color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a !== color2.a) {
			GAnalytics.track('click', 'Button', 'change-text-opacity', null);
		} else if (color1.r !== color2.r || color1.g !== color2.g || color1.b !== color2.b) {
			GAnalytics.track('click', 'Button', 'change-text-color', null);
		}
	};

	/**
	 * Si hay alguna variación de color, se hace el track para google analytics
	 * @param color1 SolidColor
	 * @param color2 SolidColor
	 */
	const trackChangeColorToChildNodes = (value: SolidColor, foundColor: SolidColor | undefined) => {
		let colorFound: SolidColor | undefined = undefined;

		text.value.colors.forEach((c) => {
			if (c.r === value.r && c.g === value.g && c.b === value.b && c.a !== value.a) {
				colorFound = c as SolidColor;
			}
			if (!colorFound && c.r !== value.r && c.g !== value.g && c.b !== value.b) {
				colorFound = c as SolidColor;
			}
			return c.toCssString() === value.toCssString();
		});

		const color = (colorFound || foundColor) as SolidColor;
		if (color) {
			trackChangeColor(color, value);
		}
	};

	/**
	 * Aplicamos el fontFamily al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para la propiedad CSS
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyFontFamilyToRootElement = (value: string): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!store.textEditing ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(store.textEditing && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			text.value[StyleProperties.fontFamily] = value;
			resetChildrenStyle(StyleProperties.fontFamily);
			text.value.content = domNode.value?.innerHTML as string;

			return true;
		}

		return false;
	};

	/**
	 * Genera automáticamente los SPAN o DIV que de forma nativa
	 */
	const generateChildrenSpan = () => {
		// Esto fuerza a que el siguiente comando cree un span con la selección
		document.execCommand('styleWithCSS', true, undefined);
		// Ya que ahora mismo no damos soporte a underlines, creamos un underline con lo
		// seleccionado y luego le quitamos el style
		document.execCommand('backColor', false, '#000001');
	};

	/**
	 * Elimina el background de los hijos del nodo seleccionado
	 */
	const removeChildrenBackground = () => {
		if (domNode.value) {
			const selectedNodesAfterCreation: NodeListOf<HTMLElement> =
				domNode.value.querySelectorAll('[style*="background"]');

			selectedNodesAfterCreation.forEach((el) => {
				el.style.backgroundColor = '';
				el.style.background = '';
			});
		}
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param style Propiedad CSS
	 * @param value Valor para la propiedad CSS
	 */
	const applyFinalNodesStyle = (style: SelectionStyle, value: SolidColor | GradientColor | string | number) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(document.getSelection()?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(window.getSelection() instanceof Selection &&
				TextSelectionTools.detectFullRange(window.getSelection(), domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			// De toda la selección nos quedamos con los nodos de texto y descartamos los spans.
			const texts = finalNodes.filter((span) => span.nodeType === 3);

			// sacamos los span en los que estan los textos. ¿Por que no seleccionar directamente los spans?
			// por que el finalnodes tambien incluye los spans que incluyen otros spans y esos no queremos tocarlos
			let nodesToStyle = texts.map((el) => el.parentNode as HTMLElement);

			// Para realizar la vinculación con el clon del stroke, generamos un id random que luego consultar
			nodesToStyle = nodesToStyle.map((el) => {
				el.dataset.unique = `span-${Math.round(Math.random() * 100000)}`;
				return el;
			});

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				if (value && (value instanceof SolidColor || value instanceof GradientColor)) {
					el.style[StyleProperties.color] = `var(--${value.id})`;

					const foundColor = text.value.colors.find((c) => c.toCssString() === value.toCssString());

					if (value instanceof SolidColor) {
						trackChangeColorToChildNodes(value, foundColor);
					}

					if (!foundColor) {
						text.value.colors.push(value);
					} else {
						foundColor.id = value.id;
					}
				} else {
					// @ts-ignore
					el.style[style] = value;
				}
			});
		}
	};

	/**
	 * Aplicamos el fontSize al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para el fontSize
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyFontSizeToRootElement = (value: number[], direction?: string) => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!store.textEditing ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(store.textEditing && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			// Comprobamos si tenemos más de un valor de fontSize y si se ha seleccionado alguna dirección y se lo aplicamos a sus hijos
			if (direction && value.length > 1) {
				// Si hemos cambiado el fontSize desde las flechas parseamos el fontSize que tenemos en el store y buscamos el que le corresponde al root
				const rootSize = Math.round(text.value[StyleProperties.fontSize]);
				const result = value.find((size) => (direction === 'plus' ? size === rootSize + 1 : size === rootSize - 1));

				text.value[StyleProperties.fontSize] = result ? result : value[0];
				applyMultiFontSizeToFinalNodes(value, direction);
			} else {
				// En caso contrario es porque solo hay un valor posible para aplicar a todo el texto y se limpiarán los hijos
				text.value[StyleProperties.fontSize] = value[0];

				if (domNode.value && domNode.value?.children.length) {
					resetChildrenStyle(StyleProperties.fontSize);
				}
			}

			text.value.content = domNode.value?.innerHTML as string;

			return true;
		}

		return false;
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 * @param dir 'plus' or 'minus'
	 */
	const applyMultiFontSizeToFinalNodes = (value: number[], dir?: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(document.getSelection()?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(window.getSelection() instanceof Selection &&
				TextSelectionTools.detectFullRange(window.getSelection(), domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			// De toda la selección nos quedamos con los nodos de texto y descartamos los spans.
			const texts = finalNodes.filter((span) => span.nodeType === 3);

			// sacamos los span en los que estan los textos. ¿Por que no seleccionar directamente los spans?
			// por que el finalnodes tambien incluye los spans que incluyen otros spans y esos no queremos tocarlos
			let nodesToStyle = texts.map((el) => el.parentNode as HTMLElement);

			// Para realizar la vinculación con el clon del stroke, generamos un id random que luego consultar
			nodesToStyle = nodesToStyle.map((el) => {
				el.dataset.unique = `span-${Math.round(Math.random() * 100000)}`;
				return el;
			});

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un tamaño de letra
				if (value.length === 1) {
					el.style[StyleProperties.fontSize] = `${Math.round(value[0])}px`;
				} else {
					// Si tenemos varios tamaños de letra buscamos cual es el que le corresponde a cada elemento según la dirección indicada
					const foundValue = value.find(
						(prop: number) =>
							(dir === 'plus' && prop === Math.round(parseFloat(getComputedStyle(el)[StyleProperties.fontSize]) + 1)) ||
							(dir === 'minus' && prop === Math.round(parseFloat(getComputedStyle(el)[StyleProperties.fontSize]) - 1))
					);

					el.style[StyleProperties.fontSize] = `${foundValue}px`;
				}
			});
		}
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 */
	const applyMultiFontWeightToFinalNodes = (value: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(document.getSelection()?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(window.getSelection() instanceof Selection &&
				TextSelectionTools.detectFullRange(window.getSelection(), domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			// De toda la selección nos quedamos con los nodos de texto y descartamos los spans.
			const texts = finalNodes.filter((span) => span.nodeType === 3);

			// sacamos los span en los que estan los textos. ¿Por que no seleccionar directamente los spans?
			// por que el finalnodes tambien incluye los spans que incluyen otros spans y esos no queremos tocarlos
			let nodesToStyle = texts.map((el) => el.parentNode as HTMLElement);

			// Para realizar la vinculación con el clon del stroke, generamos un id random que luego consultar
			nodesToStyle = nodesToStyle.map((el) => {
				el.dataset.unique = `span-${Math.round(Math.random() * 100000)}`;
				return el;
			});

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un font weight
				if ((value && typeof value === 'string') || (Array.isArray(value) && value.length === 1)) {
					el.style[StyleProperties.fontWeight] = Array.isArray(value) ? value[0] : value;
				} else {
					let boldValue: string | undefined = '400';

					// Si tenemos varios fontWeight buscamos cual es el que le corresponde a cada elemento
					fontVariants.value.forEach((font: { family: string; weight: string[] }) => {
						const elementStyles = getComputedStyle(el);
						if (font.family === elementStyles[StyleProperties.fontFamily]) {
							if (font.weight) {
								boldValue =
									parseInt(elementStyles[StyleProperties.fontWeight]) < 600
										? font.weight.find((f) => parseInt(f) >= 600)
										: font.weight.find((f) => parseInt(f) === 400);
							}
						}
					});

					if (boldValue) {
						el.style[StyleProperties.fontWeight] = boldValue;
					}
				}
			});
		}
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 * @param dir 'plus' or 'minus'
	 */
	const applyMultiLineHeightToFinalNodes = (value: number[], dir?: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(document.getSelection()?.type.toLowerCase() === 'caret' && domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(window.getSelection() instanceof Selection &&
				TextSelectionTools.detectFullRange(window.getSelection(), domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			// De toda la selección nos quedamos con los nodos de texto y descartamos los spans.
			const texts = finalNodes.filter((span) => span.nodeType === 3);

			// sacamos los span en los que estan los textos. ¿Por que no seleccionar directamente los spans?
			// por que el finalnodes tambien incluye los spans que incluyen otros spans y esos no queremos tocarlos
			let nodesToStyle = [...new Set(texts.map((el) => el.parentNode as HTMLElement))];

			// Para realizar la vinculación con el clon del stroke, generamos un id random que luego consultar
			nodesToStyle = nodesToStyle.map((el) => {
				el.dataset.unique = `span-${Math.round(Math.random() * 100000)}`;
				return el;
			});

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un tamaño de letra
				if (value.length === 1) {
					el.style[StyleProperties.lineHeight] = `${MathTools.toFixedOrInt(value[0])}`;
				} else {
					const foundLineHeight = value.find(
						(prop: number) =>
							(dir === 'plus' &&
								prop === MathTools.toFixedOrInt((parseFloat(el.style[StyleProperties.lineHeight]) || 1.2) + 0.1)) ||
							(dir === 'minus' &&
								prop === MathTools.toFixedOrInt((parseFloat(el.style[StyleProperties.lineHeight]) || 1.2) - 0.1))
					);

					if (foundLineHeight) {
						el.style[StyleProperties.lineHeight] = `${foundLineHeight}`;
					}
				}
			});
		}
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 * @param dir 'plus' or 'minus'
	 */
	const applyMultiLetterSpacingToFinalNodes = (value: number[], dir?: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(document.getSelection()?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(window.getSelection() instanceof Selection &&
				TextSelectionTools.detectFullRange(window.getSelection(), domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			// De toda la selección nos quedamos con los nodos de texto y descartamos los spans.
			const texts = finalNodes.filter((span) => span.nodeType === 3);

			// sacamos los span en los que estan los textos. ¿Por que no seleccionar directamente los spans?
			// por que el finalnodes tambien incluye los spans que incluyen otros spans y esos no queremos tocarlos
			let nodesToStyle = texts.map((el) => el.parentNode as HTMLElement);

			// Para realizar la vinculación con el clon del stroke, generamos un id random que luego consultar
			nodesToStyle = nodesToStyle.map((el) => {
				el.dataset.unique = `span-${Math.round(Math.random() * 100000)}`;
				return el;
			});

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un tamaño de espaciado
				if (value.length === 1) {
					el.style[StyleProperties.letterSpacing] = `${value[0]}px`;
				} else {
					const foundLetterSpacing = value.find((prop: number) => {
						const spacing = getComputedStyle(el)[StyleProperties.letterSpacing];
						const finalSpacing = spacing === 'normal' ? 0 : parseFloat(spacing);
						const calculatedSpacing =
							dir === 'plus' ? MathTools.toFixedOrInt(finalSpacing + 0.1) : MathTools.toFixedOrInt(finalSpacing - 0.1);
						const floatedProp = MathTools.toFixedOrInt(prop);

						return floatedProp === calculatedSpacing;
					});

					el.style[StyleProperties.letterSpacing] = `${foundLetterSpacing}px`;
				}
			});
		}
	};

	/**
	 * Se encarga de la gestión completa de la asignación de un estilo al texto
	 * @param style Propiedad CSS
	 * @param value Valor para la propiedad CSS
	 */
	const updateStyle = (style: SelectionStyle, value: any) => {
		if (applyStyleToRootElement(style, value)) return;

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateChildrenSpan();

		removeChildrenBackground();

		applyFinalNodesStyle(style, value);

		updateHeight();
	};

	/**
	 * 	Genera un nuevo rango que contiene el elemento sobre el que está el puntero (caret)
	 * @returns Retorna undefined o un objeto con el nodo y la posición del caret
	 */
	const generateRangefromCaret = () => {
		// Si tenemos algún hijo y la selección es de tipo caret sobre el padre debemos generar un nuevo span en la parte correspondiente al puntero
		// Ej: <div>Hola <span>mu</span>nd|o</div> --- > | representa a la selección de tipo caret
		let oldRange: undefined | { anchorNode: Node; anchorOffset: number };

		if (
			domNode.value &&
			selection.value &&
			selection.value.selection?.isCollapsed &&
			((selection.value.selection?.anchorNode?.nodeType === 1 &&
				selection.value.selection?.anchorNode === domNode.value) ||
				(selection.value.selection?.anchorNode?.nodeType === 3 &&
					selection.value.selection?.anchorNode.parentElement === domNode.value)) &&
			domNode.value.querySelectorAll('*').length
		) {
			// Clonamos la selección para restaurarla después de generar el Span
			oldRange = {
				anchorNode: selection.value.selection?.anchorNode,
				anchorOffset: selection.value.selection?.anchorOffset,
			};

			const newRange = new Range();

			newRange.selectNodeContents(selection.value.selection.anchorNode);

			document.getSelection()?.removeAllRanges();
			document.getSelection()?.addRange(newRange);
		}

		return oldRange;
	};

	/**
	 * 	Genera un nuevo rango de selección de tipo Caret recibiendo
	 *  como parámetro un objeto con el elemento y la posición correspondiente
	 * @param oldRange objeto con el elemento sobre el que se debe posicionar el caret y la posición correspondiente
	 * @returns Retorna undefined o un objeto con el nodo y la posición del caret
	 */
	const restoreCaretRange = (oldRange: { anchorNode: Node; anchorOffset: number }) => {
		// En caso de existir un rango antiguo de tipo caret restauramos la selección
		if (oldRange?.anchorNode && domNode.value) {
			const restoredRange = new Range();
			const children = domNode.value.childNodes;

			if (children.length) {
				children.forEach((child) => {
					if (child.textContent === oldRange?.anchorNode.textContent) {
						// En caso de ser un hijo de tipo text añadimos text, si no buscamos el text de dicho span puesto que el rango necesita al nodo Text
						if (child.nodeType === 3) {
							restoredRange.setStart(child, oldRange.anchorOffset);
						} else {
							restoredRange.setStart(child.childNodes[0], oldRange.anchorOffset);
						}

						restoredRange.collapse(true);
					}
				});
			}
			document.getSelection()?.removeAllRanges();
			document.getSelection()?.addRange(restoredRange);
		}
	};

	/**
	 * Se encarga de actualizar el fontSize
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateFontSize = (value: number | 'plus' | 'minus') => {
		let finalValue: number[];

		if (isCircleText.value) {
			text.value.setScale(1);

			if (typeof value === 'number') {
				text.value.fontSize = value;
			} else if (value === 'plus') {
				text.value.fontSize += 1;
			} else if (value === 'minus') {
				text.value.fontSize -= 1;
			}

			return;
		}

		if (typeof value === 'string') {
			// En caso de ser 'plus' o 'minus' calculamos todos los nuevos valores para la selección
			finalValue = fontSize.value.map((el) =>
				value === 'plus' ? Math.round(el * text.value.scale + 1) : Math.round(el * text.value.scale - 1)
			);
		} else {
			finalValue = [value];
			text.value.setScale(1);
		}

		if (finalValue.length === 1 && finalValue[0] === 0) {
			return;
		}

		// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
		// para poder saber que valor corresponde al root y a cada uno de los posibles hijos
		if (typeof value === 'string') {
			if (applyFontSizeToRootElement(finalValue, value)) {
				updateHeight();
				return;
			}
		} else if (applyFontSizeToRootElement(finalValue)) {
			updateHeight();
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
			// para poder saber que valor corresponde a cada uno de los posibles hijos
			if (typeof value === 'string') {
				applyMultiFontSizeToFinalNodes(finalValue, value);
			} else {
				applyMultiFontSizeToFinalNodes(finalValue);
			}
		});
	};

	const generateSpansAndApplyStylesToChildNodes = (callback: () => void) => {
		// En caso de tener una selección de tipo Caret, generamos un rango con el elemento correspondiente
		const oldRange = generateRangefromCaret();

		// Generamos los Span de forma nativa
		generateChildrenSpan();

		// Eliminamos el background de la selección
		removeChildrenBackground();

		callback();

		// Si se había creado un rango desde una selección de tipo Caret, se restaura el caret
		if (oldRange) {
			restoreCaretRange(oldRange);
		}

		if (domNode.value) {
			text.value.content = domNode.value.innerHTML;
		}

		updateHeight();

		const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = document.getSelection() as Selection;

		if (anchorNode && focusNode) {
			nextTick(() => {
				restoreSelection(anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed);
			});
		}
	};

	/**
	 * Se encarga de la gestión completa de la asignación de un estilo al texto
	 * @param value Valor para la propiedad CSS
	 */
	const updateColor = (value: SolidColor | GradientColor) => {
		// Algunos efectos dependen del color del texto y deben actualizarse
		let effectToRefresh;
		if (hasEcho.value) effectToRefresh = TextEffects.Echo;
		if (hasNeon.value) effectToRefresh = TextEffects.Neon;

		if (applyStyleToRootElement(StyleProperties.color, value)) {
			if (effectToRefresh) refreshEffect(effectToRefresh);
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			applyFinalNodesStyle(StyleProperties.color, value);
		});

		if (effectToRefresh) refreshEffect(effectToRefresh);
	};

	/**
	 * Actualiza el fontWeight de la selección
	 * @param value Si recibe parámetro se aplica ese valor
	 */
	const updateFontWeight = (value?: string) => {
		let boldValue: string | undefined;

		// Si no ha llegado algún valor por parámetro comprobamos que tamaño se debe aplicar
		if (!value) {
			let fw = text.value.fontWeight;

			// En caso de tener 1 fuente seleccionada de un hijo cogemos la fuente del hijo
			if (fontWeight.value.length === 1) {
				fw = fontWeight.value[0];
			}

			fontVariants.value.forEach((font: { family: string; weight: string[] }) => {
				// if (font.family === text.value.fontFamily) {
				boldValue =
					fw < 600 ? font.weight.find((f) => parseInt(f) >= 600) : font.weight.find((f) => parseInt(f) === 400);
				// }
			});
			Bugsnag.leaveBreadcrumb(`Update font weight text-${text.value.id}: ${boldValue}`);
		} else {
			// Si ha llegado algún valor por parámetro
			boldValue = value;
		}

		if (boldValue && applyStyleToRootElement(StyleProperties.fontWeight, boldValue)) {
			updateHeight();
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			if (boldValue) {
				applyMultiFontWeightToFinalNodes(boldValue);
			}
		});
	};

	/**
	 * Actualiza el fontWeight de la selección
	 * @param value Si recibe parámetro se aplica ese valor
	 */
	const updateFontStyle = (value?: boolean) => {
		let result: string;

		if (
			(value !== false && italicAvailable.value && italic.value.length === 1 && italic.value[0] === 'normal') ||
			value
		) {
			result = 'italic';
			Bugsnag.leaveBreadcrumb(`Set font style to text-${text.value.id}: ${result}`);
		} else {
			result = 'normal';
			Bugsnag.leaveBreadcrumb(`Set font style to text-${text.value.id}: ${result}`);
		}

		if (applyStyleToRootElement(StyleProperties.fontStyle, result)) {
			updateHeight();

			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			applyFinalNodesStyle(StyleProperties.fontStyle, result);
		});
	};

	/**
	 * Se encarga de actualizar el font Family de una selección de texto
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateFontFamily = async (value: string) => {
		resetFont();

		if (previousInputSelection.value) {
			createRange(previousInputSelection.value);
		}

		await loadFontsByName([value]);

		// Asignamos comillas dobles a la fuente para que asigne
		// correctamente las fuentes que contengan números
		if (applyFontFamilyToRootElement(value)) {
			updateHeight();
			Bugsnag.leaveBreadcrumb(`set font ${value} to text-${text.value.id}`);
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// Asignamos comillas dobles a la fuente para que asigne
			// correctamente las fuentes que contengan números
			applyFinalNodesStyle(StyleProperties.fontFamily, `"${value}"`);
			Bugsnag.leaveBreadcrumb(`set font ${value} to child nodes from text-${text.value.id}`);
		});
	};

	/**
	 * Aplicamos el lineHeight al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para el lineHeight
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyLineHeightToRootElement = (value: number[], direction?: string): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!store.textEditing ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(store.textEditing && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			// Comprobamos si tenemos más de un valor de lineHeight y si se ha seleccionado alguna dirección y se lo aplicamos a sus hijos
			if (direction && value.length > 1) {
				// Si hemos cambiado el lineHeight desde las flechas parseamos el lineHeight que tenemos en el store y buscamos el que le corresponde al root
				const rootSize = MathTools.toFixedOrInt(text.value[StyleProperties.lineHeight]);
				const result = value.find((size) => (direction === 'plus' ? size === rootSize + 0.1 : size === rootSize - 0.1));

				text.value[StyleProperties.lineHeight] = result || value[0];
				applyMultiLineHeightToFinalNodes(value, direction);
				text.value.content = domNode.value?.innerHTML as string;
			} else {
				// En caso contrario es porque solo hay un valor posible para aplicar a todo el texto y se limpiaraán los hijos
				text.value[StyleProperties.lineHeight] = value[0];

				if (domNode.value && domNode.value?.children.length) {
					resetChildrenStyle(StyleProperties.lineHeight);
				}
			}

			text.value.content = domNode.value?.innerHTML as string;

			return true;
		}

		return false;
	};

	/**
	 * Se encarga de actualizar el lineHeight
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateLineHeight = (value: number | 'plus' | 'minus') => {
		let finalValue: number[] = [];

		// En caso de ser 'plus' o 'minus' calculamos todos los nuevos valores para la selección
		if (typeof value === 'string') {
			finalValue = lineHeight.value.map((el) =>
				value === 'plus' ? MathTools.toFixedOrInt(el + 0.1) : MathTools.toFixedOrInt(el - 0.1)
			);

			breadScrumbWithDebounce('lineHeight');
		} else {
			finalValue = [MathTools.toFixedOrInt(value)];
		}

		if ((finalValue.length === 1 && finalValue[0] <= 0) || !finalValue.length) {
			return;
		}

		// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
		// para poder saber que valor corresponde al root y a cada uno de los posibles hijos
		if (typeof value === 'string') {
			if (applyLineHeightToRootElement(finalValue, value)) {
				updateHeight();
				return;
			}
		} else if (applyLineHeightToRootElement(finalValue)) {
			updateHeight();
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
			// para poder saber que valor corresponde a cada uno de los posibles hijos
			if (typeof value === 'string') {
				applyMultiLineHeightToFinalNodes(finalValue, value);
			} else {
				applyMultiLineHeightToFinalNodes(finalValue);
			}
		});
	};

	/**
	 * Aplicamos el letterSpacing al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para el letterSpacing
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyLetterSpacingToRootElement = (value: number[], direction?: string): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!store.textEditing ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(store.textEditing && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			// Comprobamos si tenemos más de un valor de letterSpacing y si se ha seleccionado alguna dirección y se lo aplicamos a sus hijos
			if (direction && value.length > 1) {
				// Si hemos cambiado el letterSpacing desde las flechas parseamos el letterSpacing que tenemos en el store y buscamos el que le corresponde al root
				const rootSize = MathTools.toFixedOrInt(text.value[StyleProperties.letterSpacing]);
				const result = value.find((size) =>
					direction === 'plus'
						? size === MathTools.toFixedOrInt(rootSize + 0.1)
						: size === MathTools.toFixedOrInt(rootSize - 0.1)
				);

				text.value[StyleProperties.letterSpacing] = result
					? MathTools.toFixedOrInt(result)
					: MathTools.toFixedOrInt(value[0]);
				applyMultiLetterSpacingToFinalNodes(value, direction);
			} else {
				// En caso contrario es porque solo hay un valor posible para aplicar a todo el texto y se limpiaraán los hijos
				text.value[StyleProperties.letterSpacing] = MathTools.toFixedOrInt(value[0]);

				if (domNode.value && domNode.value?.children.length) {
					resetChildrenStyle(StyleProperties.letterSpacing);
				}
			}

			text.value.content = domNode.value?.innerHTML as string;

			return true;
		}

		return false;
	};

	/**
	 * Se encarga de actualizar el letterSpacing
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateLetterSpacing = (value: number | 'plus' | 'minus') => {
		let finalValue: number[];

		// En caso de ser 'plus' o 'minus' calculamos todos los nuevos valores para la selección
		if (typeof value === 'string') {
			finalValue = letterSpacing.value.map((el) =>
				value === 'plus'
					? MathTools.toFixedOrInt(MathTools.toFixedOrInt(el) + 0.1)
					: MathTools.toFixedOrInt(MathTools.toFixedOrInt(el) - 0.1)
			);
			breadScrumbWithDebounce('letterSpacing');
		} else {
			finalValue = [value];
		}

		// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
		// para poder saber que valor corresponde al root y a cada uno de los posibles hijos
		if (typeof value === 'string') {
			if (applyLetterSpacingToRootElement(finalValue, value)) {
				updateHeight();
				return;
			}
		} else if (applyLetterSpacingToRootElement(finalValue)) {
			updateHeight();
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
			// para poder saber que valor corresponde a cada uno de los posibles hijos
			if (typeof value === 'string') {
				applyMultiLetterSpacingToFinalNodes(finalValue, value);
			} else {
				applyMultiLetterSpacingToFinalNodes(finalValue);
			}
		});
	};

	const updateTextTransform = (value: TextTransform) => {
		text.value.textTransform = value;
		Bugsnag.leaveBreadcrumb(`Capitalize text-${text.value.id}: ${value}`);
	};

	const updateTextAlign = (value: TextAlign) => {
		text.value.textAlign = value;
		Bugsnag.leaveBreadcrumb(`Align text-${text.value.id} to ${value}`);
	};

	const updateBorderColor = (color: SolidColor) => {
		text.value.outline.color = color;
	};
	const updateBorderWidth = (width: number) => {
		if (text.value.outline.unit) delete text.value.outline.unit;
		text.value.outline.width = width;
	};

	const updateShadowAngle = (index: number, angle: number) => {
		if (text.value.textShadow[index].unit) delete text.value.textShadow[index].unit;
		text.value.textShadow[index].angle = angle;
		if (angle) breadScrumbWithDebounce('shadowAngle');
	};

	const updateShadowColor = (index: number, color: SolidColor) => {
		text.value.textShadow[index].color = color;
		if (color.r && color.g && color.b) breadScrumbWithDebounce('shadowColor');
	};

	const updateShadowOpacity = (index: number, opacity: number) => {
		text.value.textShadow[index].opacity = opacity;
		if (opacity) breadScrumbWithDebounce('shadowOpacity');
	};

	const updateShadowDistance = (index: number, distance: number) => {
		if (text.value.textShadow[index].unit) delete text.value.textShadow[index].unit;
		text.value.textShadow[index].distance = distance;
		if (distance) breadScrumbWithDebounce('shadowDistance');
	};

	const updateShadowBlur = (index: number, blur: number) => {
		if (text.value.textShadow[index].unit) delete text.value.textShadow[index].unit;
		text.value.textShadow[index].blur = blur;
		if (blur) breadScrumbWithDebounce('shadowBlur');
	};

	/**
	 * Asignamos contenteditable a un nodo texto que no lo tenga para crear selecciones de texto en dicho nodo
	 * @param node Nodo HTML
	 */
	const setTemporalContentEditable = (node: HTMLElement) => {
		const isEditable = node.contentEditable !== 'inherit' ? node.contentEditable : false;

		if (!isEditable || isEditable === 'inherit') {
			node.setAttribute('contenteditable', 'true');
		}

		return isEditable;
	};

	/**
	 * Eliminamos contenteditable a un nodo texto
	 * @param node Nodo HTML
	 */
	const removeTemporalContentEditable = (node: HTMLElement) => {
		node.removeAttribute('contenteditable');
	};

	const updateList = (type: ListStyle) => {
		let oldRange;
		let oldOffset;

		if (domNode.value) {
			const isEditable = setTemporalContentEditable(domNode.value);

			const temporalSelection = selection.value?.selection || document.getSelection();

			// Si domNode.value.id es '' es porque no hay un texto editable seleccionado
			const textSelected = domNode.value.id !== '';

			if (!textSelected) {
				// Borrar cualquier selección actual
				temporalSelection?.removeAllRanges();

				// Seleccionar párrafo
				const range = document.createRange();
				range.selectNodeContents(domNode.value);
				temporalSelection?.addRange(range);
			}

			const caretSelection = temporalSelection?.isCollapsed;

			let lineIsNotEmpty = true;

			// Asignamos un nuevo rango para cuando se haya hecho una selección con el caret
			if (caretSelection && textSelected) {
				oldRange = document.getSelection()?.getRangeAt(0).cloneRange();
				oldOffset = document.getSelection()?.anchorOffset;

				if (
					document.getSelection()?.anchorNode?.textContent &&
					(document.getSelection()?.anchorNode?.textContent as string).length > 0
				) {
					lineIsNotEmpty = false;
				}

				const range = new Range();
				if (oldRange?.endContainer) {
					range.selectNodeContents(oldRange?.endContainer);
				}

				document.getSelection()?.removeAllRanges();
				document.getSelection()?.addRange(range);
			}

			if (!caretSelection || (caretSelection && !lineIsNotEmpty)) {
				if (type === '') {
					type = text.value.listStyle;
				}
				document.execCommand(type === 'ordered' ? 'insertOrderedList' : 'insertUnorderedList', false);
			}

			let list;

			const focusNode = (temporalSelection?.focusNode || domNode.value) as HTMLElement;
			const parentFocusNode = focusNode?.parentNode as HTMLElement;

			if (focusNode) {
				if (focusNode.nodeType === 3 && parentFocusNode) {
					list = parentFocusNode.closest(type === 'ordered' ? 'ol' : 'ul');
				} else if (focusNode?.getAttribute('contenteditable')) {
					list = focusNode?.querySelector(type === 'ordered' ? 'ol' : 'ul');
				} else {
					list = focusNode?.closest(type === 'ordered' ? 'ol' : 'ul');
				}
			}

			if (list) {
				list.style.listStyleType = type === 'ordered' ? 'decimal' : 'disc';
				const fontSize = Array.isArray(fontSizeScaled.value) ? fontSizeScaled.value[0] : fontSizeScaled.value;
				list.style.marginLeft = `${fontSize * 1.2}px`;
			}

			// Al deshacer la lista, comprobamos si los estilos del span creado son iguales al del padre
			if (parentFocusNode) {
				const baseDiv = parentFocusNode.closest('foreignObject')?.lastElementChild as HTMLElement;

				if (!list && baseDiv && parentFocusNode && !baseDiv.isEqualNode(parentFocusNode)) {
					const selectionStyles = TextTools.styleTextToObj(parentFocusNode.style.cssText);
					const baseStyles = TextTools.styleTextToObj(baseDiv.style.cssText);

					const equalsStyles = selectionStyles.every(
						(style: any) =>
							baseStyles.find((bStyle: any) => bStyle.name === style.name && bStyle.value === style.value) !== undefined
					);

					// Si son iguales eliminamos el span, nos quedamos solo con el texto y
					// restauramos la selección
					if (equalsStyles) {
						parentFocusNode.nextSibling?.remove();
						parentFocusNode.outerHTML = parentFocusNode.innerHTML;

						const newRange = document.createRange();
						newRange.selectNodeContents(parentFocusNode);
						temporalSelection?.removeAllRanges();
						temporalSelection?.addRange(newRange);
					}
				}
			}

			if (!isEditable) {
				removeTemporalContentEditable(domNode.value);
			}

			// En caso de existir una selección de tipo caret restauramos la antigua selección
			if (oldRange && oldOffset) {
				const anchorNode = document.getSelection()?.anchorNode;

				document.getSelection()?.removeAllRanges();
				const temporalRange = new Range();

				if (anchorNode) {
					temporalRange.setStart(anchorNode, oldOffset);
					temporalRange.setStart(anchorNode, oldOffset);
				}

				document.getSelection()?.addRange(temporalRange);
			}

			text.value.content = domNode.value.innerHTML;
		}

		text.value.listStyle = text.value.listStyle === type ? '' : type;
		updateHeight();

		Bugsnag.leaveBreadcrumb(`set ${text.value.listStyle} list to text-${text.value.id}`);
	};

	const createRange = (sel: {
		anchorNode: Node;
		anchorOffset: number;
		focusNode: Node;
		focusOffset: number;
		isCollapsed: boolean;
	}) => {
		const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = sel;

		const range = new Range();

		if (anchorNode.textContent && focusNode.textContent && domNode.value?.textContent) {
			if (
				domNode.value?.textContent?.indexOf(anchorNode.textContent) <
					domNode.value?.textContent?.indexOf(focusNode.textContent) ||
				(anchorNode === focusNode && anchorOffset < focusOffset && !isCollapsed)
			) {
				range.setStart(anchorNode, anchorOffset);
				range.setEnd(focusNode, focusOffset);
			}
			if (
				domNode.value?.textContent?.indexOf(anchorNode.textContent) >
					domNode.value?.textContent?.indexOf(focusNode.textContent) ||
				(anchorNode === focusNode && anchorOffset > focusOffset && !isCollapsed)
			) {
				range.setStart(focusNode, focusOffset);
				range.setEnd(anchorNode, anchorOffset);
			}

			if (domNode.value?.textContent?.indexOf(anchorNode.textContent) >= 0 && isCollapsed) {
				range.setStart(focusNode, focusOffset);
				range.collapse(true);
			}

			if (Math.abs(anchorOffset + focusOffset) === anchorNode.textContent.length) {
				range.selectNodeContents(anchorNode);
			}
		}

		document.getSelection()?.removeAllRanges();
		document.getSelection()?.addRange(range);
	};

	const updateLink = (link: string) => {
		if (domNode.value) {
			text.value.link = link === 'unlink' ? [''] : [link];

			if (link !== 'unlink') {
				let selectAll;
				let isEditable;

				if (!store.textEditing) {
					isEditable = setTemporalContentEditable(domNode.value);

					if (previousInputSelection.value) {
						createRange(previousInputSelection.value);
					}

					selectAll = TextSelectionTools.detectFullRange(window.getSelection() as Selection, domNode.value);
				} else {
					if (previousInputSelection.value) {
						createRange(previousInputSelection.value);
					}
				}

				const temporalSelection = window.getSelection();
				const focusNode = temporalSelection?.focusNode as HTMLElement;

				if (temporalSelection?.anchorNode) {
					const finalNode =
						temporalSelection?.anchorNode.nodeType === 1
							? (temporalSelection?.anchorNode as HTMLElement)
							: (temporalSelection?.anchorNode.parentElement as HTMLElement);
					if (
						finalNode &&
						!finalNode.closest(`#editable-${text.value.id}`) &&
						!finalNode.closest(`#element-${text.value.id} .text-element-final`)
					) {
						selectAll = true;
					}
				}

				if (!focusNode || focusNode.id === 'text-floating') {
					selectAll = true;
				}

				if (selectAll) {
					const range = document.createRange();
					range.selectNodeContents(domNode.value);
					temporalSelection?.removeAllRanges();
					temporalSelection?.addRange(range);
				}

				const hasAnchorInside = Array.from(domNode.value.querySelectorAll('a')).filter(
					(a) => temporalSelection?.containsNode(a) || temporalSelection?.focusNode?.parentElement
				);

				// Para evitar conflictos con execCommand eliminamos temporalmente los estilos y attrs
				// de los enlaces, después los recuperamos
				domNode.value.querySelectorAll('a').forEach((a) => {
					a.removeAttribute('target');
					a.removeAttribute('style');
				});

				link = link.trim();

				if (!link.startsWith('http://') && !link.startsWith('https://')) {
					link = `https://${link}`;
				}

				if (hasAnchorInside.length) {
					hasAnchorInside.forEach((a) => {
						window.getSelection()?.getRangeAt(0).selectNode(a);
						document.execCommand('createLink', false, link);
					});
				} else {
					document.execCommand('createLink', false, link);
				}

				domNode.value.querySelectorAll('a').forEach((a) => {
					a.setAttribute('target', '_blank');
					a.style.textDecoration = 'underline';
				});

				if (!isEditable) {
					removeTemporalContentEditable(domNode.value);
				}
			} else {
				let selectAll;
				let isEditable;

				if (!store.textEditing) {
					isEditable = setTemporalContentEditable(domNode.value);

					if (window.getSelection() !== null) {
						selectAll = TextSelectionTools.detectFullRange(window.getSelection() as Selection, domNode.value);
					}
				}

				const temporalSelection = !store.textEditing ? window.getSelection() : selection.value?.selection;
				const focusNode = temporalSelection?.focusNode as HTMLElement;

				if (focusNode?.id === 'text-floating') {
					selectAll = true;
				}

				if (selectAll) {
					const range = document.createRange();
					range.selectNodeContents(domNode.value);
					temporalSelection?.removeAllRanges();
					temporalSelection?.addRange(range);
				}

				Array.from(selectedNodes.value)
					.reduce((acc: HTMLElement[], current) => {
						let realLink = current;

						if (realLink.parentNode && realLink.nodeName === '#text') {
							realLink = realLink.parentNode;
						}

						if (realLink instanceof HTMLElement && realLink.tagName !== 'A') {
							const links = realLink.querySelectorAll('a');
							if (links) {
								// @ts-ignore
								realLink = Array.from(links);
							}
						}

						const links = Array.isArray(realLink) ? realLink : [realLink];

						links
							.filter((el: HTMLElement) => el.tagName === 'A')
							.forEach((el) => {
								if (!acc.includes(el)) {
									acc.push(el);
								}
							});

						return acc;
					}, [])
					.forEach((el: HTMLElement) => (el.outerHTML = el.innerHTML));

				if (!isEditable) {
					removeTemporalContentEditable(domNode.value);
				}
			}
		}

		if (domNode.value) {
			text.value.content = domNode.value?.innerHTML;
		}

		updateHeight();

		store.textEditingId = null;
	};

	const rescaleText = () => {
		// Reseteamos el valor del Root del texto
		text.value.fontSize = text.value.fontSize * text.value.scale;

		const children = domNode.value?.querySelectorAll('*');

		// En caso de tener nodos hijo seteamos el fontSize para su posterior recomputado
		if (children?.length) {
			const childrenArray = Array.from(children) as HTMLElement[];

			childrenArray.forEach((element) => {
				const fontSize = element.style[StyleProperties.fontSize];

				if (fontSize.length) {
					element.style[StyleProperties.fontSize] = `${parseFloat(fontSize) * text.value.scale}px`;
				}
			});

			// Asignamos el contenido nuevo al texto
			if (domNode.value) {
				text.value.content = domNode.value?.innerHTML;
			}
		}

		// A continuación, se reasigna la variable reactiva selection para que se recomputen todos los valores de los textos
		text.value.setScale(1);

		if (selection.value?.selection instanceof Selection) {
			const anchorNode = selection.value?.selection.anchorNode;
			const anchorOffset = selection.value?.selection.anchorOffset;
			const focusNode = selection.value?.selection.focusNode;
			const focusOffset = selection.value?.selection.focusOffset;
			const isCollapsed = selection.value?.selection.isCollapsed;

			nextTick(() => {
				if (anchorNode && anchorOffset && focusNode && focusOffset && isCollapsed) {
					restoreSelection(anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed);
				}
			});
		}
	};

	const restoreSelection = (
		anchorNode: Node,
		anchorOffset: number,
		focusNode: Node,
		focusOffset: number,
		isCollapsed: boolean
	) => {
		document.getSelection()?.removeAllRanges();
		selection.value = null;

		if (anchorNode && domNode.value) {
			const range = document.createRange();

			const children = TextTools.getNodesFromRootNode(domNode.value);

			if (children?.length) {
				let aNode: Node, fNode: Node;

				Array.from(children).forEach((child) => {
					// En caso de que los nodos seleccionados no coincidan
					if (
						anchorNode !== focusNode &&
						domNode.value?.textContent &&
						Math.abs(anchorOffset + focusOffset) < domNode.value?.textContent?.length
					) {
						// En caso de ser un hijo de tipo text añadimos text, si no buscamos el text de dicho span puesto que el rango necesita al nodo Text
						if (child.textContent === anchorNode.textContent) {
							aNode = child;
						}
						if (child.textContent === focusNode.textContent) {
							fNode = child;
						}
					} else {
						if (anchorNode.textContent === child.textContent) {
							// Si es de tipo caret
							if (isCollapsed) {
								// En caso de ser un hijo de tipo text añadimos text, si no buscamos el text de dicho span puesto que el rango necesita al nodo Text
								if (child.nodeType === 3) {
									range.setStart(child, anchorOffset);
								} else {
									range.setStart(child.childNodes[0], anchorOffset);
								}

								// Hacemos que sea collapse para que sea de tipo caret
								range.collapse(true);
							} else {
								range.selectNodeContents(child);
							}
						}
					}

					// En caso de que los nodos (anchorNode y focusNode) seleccionados no coincidan
					if (aNode && fNode && aNode.textContent && fNode.textContent && domNode.value?.textContent) {
						// Asignamos el rango final
						if (
							domNode.value?.textContent?.indexOf(aNode.textContent) <
							domNode.value?.textContent?.indexOf(fNode.textContent)
						) {
							range.setStart(aNode, anchorOffset);
							range.setEnd(fNode, focusOffset);
						} else {
							range.setStart(fNode, focusOffset);
							range.setEnd(aNode, anchorOffset);
						}
					}
				});
			}

			nextTick(() => {
				document.getSelection()?.addRange(range);
				selection.value = { text: document.getSelection()?.toString(), selection: document.getSelection() };
			});
		}
	};

	return {
		colors,
		selectedColor,
		lineHeight,
		lineHeightLabel,
		fontSize,
		fontSizeScaled,
		fontFamily,
		finalFontFamily,
		fontWeight,
		italic,
		fontVariants,
		italicAvailable,
		boldAvailable,
		textTransform,
		letterSpacing,
		outline,
		textShadow,
		listStyle,
		isMultiStyleText,
		link,
		updateStyle,
		updateFontSize,
		updateColor,
		updateFontFamily,
		updateLineHeight,
		updateFontWeight,
		updateFontStyle,
		updateTextTransform,
		updateLetterSpacing,
		updateTextAlign,
		updateBorderColor,
		updateBorderWidth,
		updateShadowAngle,
		updateShadowColor,
		updateShadowOpacity,
		updateShadowDistance,
		updateShadowBlur,
		updateList,
		updateLink,
		rescaleText,
		resetTextShadow,
	};
};

interface SelectionResult {
	text?: string | null;
	selection: Selection | null;
}

/**
 * Retorna de manera reactiva la seleccion de un nodo
 * @param domNode
 */
export const selection = ref<SelectionResult | null>(null);
let prevSelection: string | null = null;

export const previousInputSelection = ref<{
	anchorNode: Node;
	anchorOffset: number;
	focusNode: Node;
	focusOffset: number;
	isCollapsed: boolean;
} | null>(null);

export const useSelection = (domNode: Ref<HTMLElement | null>) => {
	const selectionKey = (sel: Selection | null): string => {
		if (!sel) return '';

		// baseOffset no es oficialmente compatible con todos los navegadores https://github.com/w3c/selection-api/issues/34
		// así que lo tipamos nosotros en global.d.ts
		const baseOffset = sel.baseOffset || sel.anchorOffset;
		return `${sel.toString()}${sel.anchorOffset}${sel.focusOffset}${baseOffset}${sel.focusOffset}${
			sel.anchorNode?.textContent
		}`;
	};

	useEventListener(domNode, 'mouseup', () => {
		const newSelection = window.getSelection();
		const newSelectionAsString = selectionKey(newSelection);

		// Comprobamos que la selección no haya cambiado con respecto a la selección anterior
		if (newSelectionAsString === prevSelection) return;

		prevSelection = newSelectionAsString;

		selection.value = { text: newSelection?.toString(), selection: newSelection };
		Bugsnag.leaveBreadcrumb(`Select text range: ${newSelection?.toString()}`);
	});

	useEventListener(domNode, 'keyup', () => {
		const newSelection = window.getSelection();
		const newSelectionAsString = selectionKey(newSelection);

		// Comprobamos que la selección no haya cambiado con respecto a la selección anterior
		if (newSelectionAsString === prevSelection) return;

		prevSelection = newSelectionAsString;

		selection.value = { text: newSelection?.toString(), selection: newSelection };
	});

	const selectedNodes = computed<HTMLElement[] | Node[]>(() => {
		if (!selection.value || !selection.value.selection) {
			const editableText = domNode.value?.querySelector(':scope > div > div');
			if (editableText) {
				return [
					// domNode.value,
					...Array.from(editableText.querySelectorAll('*') || []),
				] as HTMLElement[];
			}
			return [domNode.value, ...Array.from(domNode.value?.querySelectorAll('*') || [])] as HTMLElement[];
		}

		let finalNodes: HTMLElement[] | Node[];

		finalNodes = [domNode.value as HTMLElement];

		const anchorNode = selection.value.selection.anchorNode as HTMLElement;

		if (
			anchorNode &&
			((anchorNode.nodeType === 1 && anchorNode.closest('.text-element-final')) ||
				(anchorNode.parentElement && anchorNode.parentElement.closest('.text-element-final')))
		) {
			const range = selection.value.selection.getRangeAt(0);

			// Obtenemos los nodos finales de la selección
			finalNodes = TextTools.getRangeSelectedNodes(range);
		} else {
			if (domNode.value) {
				const range = new Range();

				range.selectNodeContents(domNode.value as HTMLElement);

				finalNodes = TextTools.getRangeSelectedNodes(range);
			}
		}

		if (finalNodes.length === 1 && finalNodes[0] === domNode.value && domNode.value?.querySelectorAll('*').length) {
			finalNodes = [...finalNodes, ...Array.from(domNode.value?.querySelectorAll('*'))];
		}

		return finalNodes;
	});

	return {
		selection,
		selectedNodes,
	};
};
