<script setup lang="ts">
// Vue & Packages
import { MaybeElement, MaybeElementRef } from '@vueuse/core';
import { computed, Ref, ref, toRef, watch } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
// Clases
import Page from '@/Classes/Page';
import { SolidColor } from '@/Classes/SolidColor';
// Components
import Element from '@/components/render/Element.vue';
import { useDebouncedPageReference } from '@/composables/canvas/useDebouncedPageReference';
import { useLazyCanvas } from '@/composables/canvas/useLazyCanvas';
import { useCropPhotoMode } from '@/composables/element/image/useCropPhotoMode';
// Composables
import { useCanvasSizeContainer } from '@/composables/useCanvasSize';
import { useEditorMode } from '@/composables/useEditorMode';
import { useTaskQueue } from '@/composables/useTaskQueue';

// Props
const props = withDefaults(
	defineProps<{
		page: Page;
		scrollArea?: MaybeElementRef;
		lazyRender?: boolean;
		fitTo?: 'width' | 'height';
		useParentSize?: {
			width: number;
			height: number;
			canvasWidthScaled: number;
			canvasHeightScaled: number;
			scale: number;
		};
		preview?: boolean;
		disableRendering?: boolean;
		useRenderQueue?: boolean;
		previewScale?: number;
	}>(),
	{
		fitTo: 'width',
	}
);

const { isRenderingContext } = useEditorMode();

// Template refs
const canvas = ref<HTMLDivElement | undefined>();

let { width, height, canvasWidthScaled, canvasHeightScaled, scale } = !props.useParentSize
	? useCanvasSizeContainer(canvas, props.fitTo)
	: {
			width: computed(() => props.useParentSize?.width || 0),
			height: computed(() => props.useParentSize?.height || 0),
			canvasWidthScaled: computed(() => props.useParentSize?.width || 0),
			canvasHeightScaled: computed(() => props.useParentSize?.height || 0),
			scale: computed(() => props.useParentSize?.scale || 0),
	  };

const pageReference = ref(props.page);

// Controlamos la visibilidad del canvas para renderizarlo o no en función de si esta en pantalla
const canvasIsVisible = ref(!props.lazyRender);
const shouldRenderByVisibility = ref(true);
const shouldRenderByQueue = ref(props.useRenderQueue ? false : true);

if (props.lazyRender) {
	const scrollArea = toRef(props, 'scrollArea');
	useLazyCanvas(pageReference as Ref<Page>, canvasIsVisible, scrollArea as Ref<MaybeElement>, canvas);
}

// si estamos usando un preview, usamos una copia actualizada cada 300 ms
if (props.preview && !isRenderingContext) {
	useDebouncedPageReference(pageReference, props);
} else {
	// si no, estamos atentos por si cambia el page (al moverse por el historial)
	watch(
		() => props.page,
		() => {
			pageReference.value = props.page;
		}
	);
}

if (props.useRenderQueue && pageReference.value.elements) {
	useTaskQueue(
		'canvas-rendering',
		() => {
			shouldRenderByQueue.value = true;
		},
		200
	);
}

const shouldRenderContents = computed(() => {
	if (isRenderingContext) {
		return true;
	}

	return (
		canvasIsVisible.value &&
		shouldRenderByVisibility.value &&
		!props.disableRendering &&
		shouldRenderByQueue.value &&
		!pageReference.value.name.includes('temporal')
	);
});

const { isCropPhotoModeReady } = useCropPhotoMode();

const canvasStyle = computed(() => {
	const style: any = {
		width: `${canvasWidthScaled.value}px`,
		height: `${canvasHeightScaled.value}px`,
		'background-size': `${16 / (props.preview ? props.previewScale || 1 : scale.value)}px`,
	};

	if (props.useParentSize) {
		style.transformOrigin = '0 0';
		style.transform = `scale(${scale.value})`;
	}

	// Calculamos cuanto tiene que ocupar sin subpixels vs con subpixels y recortamos ese % del clip path
	const diffX = (Math.round(width.value * scaleWithoutSubpixels.value) / (width.value * scale.value)) * 100;
	const diffY = (Math.round(height.value * scaleWithoutSubpixels.value) / (height.value * scale.value)) * 100;

	style.clipPath = `polygon(0 0, ${diffX}% 0, ${diffX}% ${diffY}%, 0% ${diffY}%)`;

	return style;
});

const inRenderingContext = !!window.RENDERER;
const scaleWithoutSubpixels = computed(() => {
	return Math.floor(width.value * scale.value) / width.value;
});
</script>

<template>
	<div
		:id="!preview && page.id ? `canvas-${page.id}` : `canvas-${page.id}-preview`"
		ref="canvas"
		class="canvas relative w-full"
		:class="{
			'm-auto': preview,
			'bg-transparent-pattern': !isCropPhotoModeReady,
		}"
		:style="canvasStyle"
		:data-canvas-visible="!preview && page.id && shouldRenderContents"
		:data-id="page.id"
	>
		<div
			class="relative"
			:style="
      (useParentSize ?
      {
        width: `${width}px`,
        height: `${height}px`,
        background: isCropPhotoModeReady ? 'none' : (pageReference.background as SolidColor | GradientColor).toCssString()
      } : {
        transformOrigin: '0 0',
        width: `${width}px`,
        height: `${height}px`,

        pointerEvents: !inRenderingContext ? 'none' : 'auto',
        background: isCropPhotoModeReady ? 'none' : (pageReference.background as SolidColor | GradientColor).toCssString(),
        transform: `scale(${scale})`,
      }) as any
    "
		>
			<div
				class="h-full w-full overflow-hidden"
				:class="{
					'contain-strict': !isCropPhotoModeReady,
				}"
				data-elements-container
			>
				<template v-if="shouldRenderContents">
					<Element
						v-for="element in pageReference.elements"
						:key="element.id"
						:data-test-element-type="`${element.type}-${element.id}`"
						:element="element"
						:for-preview="preview"
						:scale="scale"
						:page="(pageReference as Page)"
					/>
				</template>
				<img
					v-else-if="pageReference.preview && !isRenderingContext"
					:src="pageReference.preview"
					alt=""
					class="pointer-events-none w-full"
				/>
				<slot name="default"></slot>
			</div>
		</div>
		<!-- Removes mouse events over the canvas if preview -->
		<div v-if="preview && !inRenderingContext" class="absolute inset-0 z-20"></div>
	</div>
</template>
<style>
.contain-strict {
	contain: strict;
}
</style>
