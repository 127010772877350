import { isClient } from '@vueuse/shared';
import qrcode from 'qrcode';
import { computed, Ref, ref, watch } from 'vue';

import { QRCode } from '@/Classes/QRCode';

export function useQRCodeAsSVG(element: Ref<QRCode>) {
	const result = ref('');

	const src = computed(() => {
		const hasHttps = element.value.url.startsWith('https://');
		return hasHttps ? element.value.url : `https://${element.value.url}`;
	});
	const options = computed(() => ({
		margin: element.value.margin,
		color: {
			dark: element.value.frontColor.toHex(),
			light: element.value.bgColor.toHex(),
		},
		type: 'svg', // svg | terminal | utf8
	}));

	const getQR = async () => {
		if (src.value && isClient) {
			result.value = await qrcode.toString(src.value, options.value);
		}
	};

	watch([options, src], () => getQR(), { deep: true, immediate: true });
	watch(
		result,
		(rawSvg) => {
			if (!rawSvg) return;
			element.value.content = QRCode.getInnerSvg(rawSvg);
			element.value.viewbox = QRCode.getViewBoxFromRawSvg(rawSvg);
		},
		{ immediate: true }
	);

	return result;
}
