<script lang="ts" setup>
// Classes
import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useEditorClipboard } from '@/composables/useEditorClipboard';

// Props
defineProps<{ element: Element }>();

// Using composables
const { copySelection, pasteSelection } = useEditorClipboard();

const copyAndPasteElement = async () => {
	await copySelection();
	await pasteSelection();
};
</script>

<template>
	<button
		class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white"
		data-testid="copy"
		@click="copyAndPasteElement"
	>
		<SvgIcon name="copy" class="h-4 w-4 fill-current text-gray-100 group-hover:text-white" />
		<p class="text-2xs">Copy</p>
	</button>
</template>
