<script lang="ts" setup>
// Vue
import { nextTick } from 'vue';

// Classes
import Image from '@/Classes/Image';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { usePhotoMode } from '@/composables/element/image/usePhotoMode';
// Storages
import { useMainStore } from '@/stores/store';
const store = useMainStore();

// Props
const props = defineProps<{ element: Image; labelPosition?: string }>();

// Using composables
const { photoModeImage } = usePhotoMode();

// Methods
const onClickCrop = async () => {
	if (props.element.id === photoModeImage.value?.id) {
		store.clearSelection();
		await nextTick();
		store.croppingId = photoModeImage.value.id;
		return;
	}

	store.croppingId = props.element.id;
};
</script>

<template>
	<div>
		<button
			data-onboarding="crop"
			data-testid="crop-btn"
			class="flex h-9 w-9 items-center justify-center rounded bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white focus:outline-none lg:w-full lg:px-3"
			:class="{
				'lg:h-9': labelPosition === 'right',
				'lg:h-8': labelPosition === 'bottom',
			}"
			@click="onClickCrop"
		>
			<SvgIcon name="crop" class="h-4 w-4 scale-90" />
			<label v-if="labelPosition === 'right'" class="pointer-events-none ml-2 text-sm">Crop</label>
		</button>
	</div>
	<label v-if="labelPosition === 'bottom'" class="mt-1 block text-center text-2xs text-gray-100">Crop</label>
</template>
