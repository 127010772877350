<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

import { Filter } from '@/Classes/Filter';
import { FilterPresetEmpty, presetTypes } from '@/Classes/FilterPresets';
import Image from '@/Classes/Image';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import FilterOverlay from '@/components/render/FilterOverlay.vue';
import { useBugsnag } from '@/composables/useBugsnag';
import { useDeviceInfo } from '@/composables/useDeviceInfo';

const props = defineProps<{ image: Image }>();

const fields = [
	{ name: 'Contrast', key: 'contrast', unit: '%', min: 0, max: 200, default: 100 },
	{ name: 'Brightness', key: 'brightness', unit: '%', min: 0, max: 200, default: 100 },
	{ name: 'Saturate', key: 'saturate', unit: '%', min: 0, max: 200, default: 100 },
	{ name: 'Sepia', key: 'sepia', unit: '%', min: 0, max: 100, default: 0 },
	{ name: 'Grayscale', key: 'grayscale', unit: '%', min: 0, max: 100, default: 0 },
	{ name: 'Invert', key: 'invert', unit: '%', min: 0, max: 100, default: 0 },
	{ name: 'Hue rotation', key: 'hueRotate', unit: 'deg', min: 0, max: 360, default: 0 },
	{ name: 'Blur', key: 'blur', unit: 'px', min: 0, max: 100, default: 0 },
] as {
	name: string;
	key: FilterParam;
	unit: string;
	min: number;
	max: number;
	default: number;
}[];

const { breadScrumbWithDebounce } = useBugsnag();

const setPreset = (preset: Filter | null) => {
	props.image.filter = preset;
	if (preset) {
		Bugsnag.leaveBreadcrumb(`Set preset to image-${props.image.id}: ${preset.name}`);
	}
};

const reset = () => {
	setPreset(null);
	Bugsnag.leaveBreadcrumb(`Remove preset to: image-${props.image.id}`);
};

type FilterParam = 'contrast' | 'brightness' | 'saturate' | 'sepia' | 'grayscale' | 'invert' | 'hueRotate' | 'blur';

const updateSetting = (field: FilterParam, value: number) => {
	if (!props.image.filter) props.image.filter = new FilterPresetEmpty();
	props.image.filter[field] = value;
	props.image.filter.name = 'Custom';
	breadScrumbWithDebounce(field);
};

const { isMobile } = useDeviceInfo();

const filterOption = ref<'filters' | 'settings'>('filters');
</script>

<template>
	<div>
		<div class="mx-4 mb-2 flex h-8 items-center overflow-hidden rounded-lg border border-gray-600 lg:hidden">
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="filterOption === 'filters' ? 'bg-gray-100 text-gray-800' : 'text-gray-300'"
				@click="filterOption = 'filters'"
			>
				Filters
			</button>
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="filterOption === 'settings' ? 'bg-gray-100 text-gray-800' : 'text-gray-300'"
				@click="filterOption = 'settings'"
			>
				Settings
			</button>

			<button
				v-if="isMobile"
				class="flex h-8 w-8 items-center justify-center px-2 text-xs font-bold uppercase text-white"
				:class="!image.filter ? 'pointer-events-none opacity-50' : ''"
				@click="reset"
			>
				<SvgIcon name="reload" class="h-3 w-3" />
			</button>
		</div>
		<p class="mb-1 hidden text-sm font-bold uppercase text-gray-100 lg:flex lg:opacity-75">Presets</p>
		<div
			v-show="!isMobile || filterOption === 'filters'"
			data-onboarding="filter"
			data-testid="presets-filters"
			class="flex gap-2 overflow-auto py-1 px-4 text-gray-800 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 lg:-mx-[2px] lg:mb-3 lg:grid lg:max-h-[207px] lg:grid-cols-4 lg:pl-1 lg:pr-1 lg:text-gray-800"
		>
			<div
				v-for="preset in presetTypes()"
				:key="(preset.name as string)"
				class="group relative flex w-1/6 shrink-0 cursor-pointer flex-col overflow-hidden rounded lg:w-full"
				:class="{
					'ring-2 ring-blue-600': preset.name === image.filter?.name,
				}"
				:title="(preset.name as string)"
				@click="setPreset(preset)"
			>
				<img
					:src="image.url"
					alt=""
					class="aspect-square rounded object-cover"
					draggable="false"
					:style="{ filter: preset.toCssFilter() }"
				/>
				<FilterOverlay v-if="preset?.overlay" :overlay="preset.overlay" />
			</div>
		</div>

		<div class="mb-2 hidden items-center justify-between lg:flex">
			<p class="text-sm font-bold uppercase text-gray-100 lg:opacity-75">Settings</p>
			<button class="flex items-center text-xs text-gray-100 hover:text-white" @click="reset">
				<SvgIcon name="reload" class="mr-2 h-3 w-3" />
				Reset
			</button>
		</div>
		<div
			v-show="!isMobile || filterOption === 'settings'"
			data-testid="custom-filters"
			class="h-32 w-full flex-col overflow-scroll px-4 py-2 text-sm text-gray-100 lg:flex lg:h-auto lg:overflow-visible lg:p-0"
			data-onboarding="filter-settings"
		>
			<div v-for="field in fields" :key="field.key" class="mb-1">
				<p class="text-sm text-gray-200" v-text="field.name"></p>
				<div class="flex items-center">
					<div class="input-range relative mr-4 flex w-full">
						<input
							type="range"
							class="input-range h-[4px] w-full cursor-pointer appearance-none rounded-full bg-gray-900 focus:outline-none sm:h-[2px]"
							:min="field.min"
							:max="field.max"
							step="1"
							:value="(image.filter && image.filter[field.key]) || field.default"
							@dblclick="updateSetting(field.key, field.default)"
							@input="updateSetting(field.key, ($event.target as any).value)"
						/>
						<span
							:style="`width: ${
								(image.filter && (image.filter[field.key] as number) * (100 / field.max)) || field.default * (100 / field.max)
							}%`"
							class="absolute top-[0px] left-0 h-[4px] rounded-full bg-blue-500 sm:h-[2px]"
						></span>
					</div>
					<span
						class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100"
					>
						{{ (image.filter && image.filter[field.key]) || field.default }}{{ field.unit }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
