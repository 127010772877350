<script lang="ts" setup>
import Element from '@/Classes/Element';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import OrderMenu from '@/components/selection/panels/OrderMenu.vue';
import PositionMenu from '@/components/selection/panels/PositionMenu.vue';
// import TransformGroupMenu from '@/components/selection/toolbars/TransformGroupMenu.vue';
import GroupElementColorPicker from '@/components/skeleton/GroupElementColorPicker.vue';
import { useMainStore } from '@/stores/store';

const store = useMainStore();

defineProps<{ elements: Element[] }>();

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">Edit Group</h2>
		<button
			class="text-gray-100 hover:text-white focus:outline-none"
			data-testid="close-panel"
			@click="onCloseEditPanel"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<PositionMenu :element="elements[0]" />
		<OrderMenu :element="elements[0]" />
		<!-- <TransformGroupMenu :elements="elements" /> -->
		<GroupElementColorPicker :group="elements" />
	</div>
</template>
