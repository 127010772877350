<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useZoom } from '@/composables/useZoom';

// Using composables
const {
	ORIGINAL_ZOOM_OPTIONS,
	ZOOM_OPTIONS,
	scaleAsPercentage,
	decreaseZoom,
	fitZoomScale,
	increaseZoom,
	setZoomDirectly,
} = useZoom();

// Data
const showZoomOptions = ref<boolean>(false);
const selector = ref();

const toggleZoomOptions = () => {
	showZoomOptions.value = !showZoomOptions.value;
};

const closeZoomOptions = () => {
	showZoomOptions.value = false;
};

onClickOutside(selector, closeZoomOptions);
// Cambiar ZOOM_OPTIONS por ORIGINAL_ZOOM_OPTIONS
</script>

<template>
	<!-- CHECKPOINT: hablar en la reunión -->
	<div
		class="navigator pointer-events-auto z-10 hidden h-10 items-center justify-center rounded bg-gray-800 text-sm font-bold text-gray-100 lg:flex"
	>
		<button
			data-testid="decrease-zoom"
			class="flex h-10 w-10 items-center justify-center rounded-sm text-gray-100 hover:text-white focus:outline-none"
			:disabled="scaleAsPercentage === ORIGINAL_ZOOM_OPTIONS[0]"
			:class="{
				'cursor-not-allowed opacity-25': scaleAsPercentage === ORIGINAL_ZOOM_OPTIONS[0],
			}"
			@click="decreaseZoom"
		>
			<SvgIcon name="minus" class="h-4 w-4" />
		</button>
		<div
			class="relative flex h-10 w-10 flex-1 cursor-pointer items-center justify-center text-center hover:text-white"
			@click="toggleZoomOptions"
		>
			<div data-testid="current-zoom">{{ scaleAsPercentage }}%</div>
			<div
				v-show="showZoomOptions"
				ref="selector"
				data-testid="show-options"
				class="absolute top-0 flex flex-col overflow-hidden rounded bg-gray-800 lg:top-auto lg:bottom-0 lg:mb-12"
				style="left: 50%; transform: translateX(-50%)"
			>
				<div
					class="h-48 overflow-auto text-gray-800 backdrop-blur scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
				>
					<button
						v-for="(option, i) in ZOOM_OPTIONS"
						:key="`${option}-zoom`"
						:class="{
							'text-gray-300 hover:text-gray-100': true,
							'bg-gray-700 text-gray-100': false,
						}"
						class="w-full py-2 pl-4 pr-1 text-sm"
						@click="setZoomDirectly(option)"
					>
						{{ ORIGINAL_ZOOM_OPTIONS[i].toFixed(0) }}%
					</button>
				</div>
				<button class="rounded-sm bg-gray-700 py-2 text-sm text-gray-100 hover:text-gray-100" @click="fitZoomScale">
					Fit
				</button>
			</div>
		</div>

		<button
			data-testid="increase-zoom"
			class="flex h-10 w-10 items-center justify-center rounded-sm text-gray-100 hover:text-white focus:outline-none"
			:disabled="scaleAsPercentage === ORIGINAL_ZOOM_OPTIONS[ORIGINAL_ZOOM_OPTIONS.length - 1]"
			:class="{
				'cursor-not-allowed opacity-25': scaleAsPercentage === ORIGINAL_ZOOM_OPTIONS[ORIGINAL_ZOOM_OPTIONS.length - 1],
			}"
			@click="increaseZoom"
		>
			<SvgIcon name="plus" class="h-4 w-4" />
		</button>
	</div>
</template>
