<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import { QRCode } from '@/Classes/QRCode';
import { SolidColor } from '@/Classes/SolidColor';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import CopyToolbar from '@/components/selection/toolbars/CopyToolbar.vue';
import FlipMenuToolbar from '@/components/selection/toolbars/FlipMenuToolbar.vue';
import MoreToolsToolbar from '@/components/selection/toolbars/MoreToolsToolbar.vue';
import OrderToolbar from '@/components/selection/toolbars/OrderToolbar.vue';
import RemoveToolbar from '@/components/selection/toolbars/RemoveToolbar.vue';
import { useQRCodeAsSVG } from '@/composables/element/qrcode/useQRCodeAsSVG';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

// Props
const props = defineProps<{ element: QRCode }>();

// Data
const element = toRef(props, 'element');

// Using composables
const { isMobile } = useDeviceInfo();
useQRCodeAsSVG(element);

// Methods
const onUpdateColor = (key: 'front' | 'bg', color: SolidColor) => {
	element.value[`${key}Color`] = color;
};

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.QRCode) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.QRCode;
	Bugsnag.leaveBreadcrumb('Open edit QRcode panel');
};
</script>

<template>
	<div>
		<FlipMenuToolbar :element="element" />

		<div
			class="order-first mx-2 flex gap-2 border-r border-gray-600 py-1 pr-4 lg:order-none lg:mx-0 lg:py-0 lg:pl-1 lg:pr-2"
		>
			<ColorPicker
				parent=".toolbar"
				class="h-8 w-8 lg:h-5 lg:w-5"
				:color="element.frontColor"
				:hide-gradient="true"
				@change="(color) => onUpdateColor('front', color as SolidColor)"
			/>

			<ColorPicker
				parent=".toolbar"
				class="h-8 w-8 lg:h-5 lg:w-5"
				:color="element.bgColor"
				:hide-gradient="true"
				@change="(color) => onUpdateColor('bg', color as SolidColor)"
			/>
		</div>

		<hr class="mx-1 hidden h-5 w-px bg-white opacity-25 lg:block" />

		<!-- Order -->
		<OrderToolbar v-if="isMobile" :element="element" />

		<!-- Copy -->
		<CopyToolbar v-if="isMobile" :element="element" />

		<!-- Remove -->
		<RemoveToolbar :element="element" />

		<MoreToolsToolbar :panel="EditPanels.QRCode" @more-tools="onClickMoreTools" />
	</div>
</template>
