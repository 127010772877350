<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components';
import { ref } from 'vue';

import Popper from '@/components/Common/Popper.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useAuth } from '@/composables/useAuth';
import { useUserImageProvider } from '@/composables/useUserImageProvider';
import { useUsersnap } from '@/composables/useUsersnap';

const { user, isLogged, logout } = useAuth();
const { openReportBug } = useUsersnap();
const { emptyUserUploads } = useUserImageProvider();

const open = ref(false);

const logoutHandler = () => {
	logout();
	emptyUserUploads();
	open.value = false;
};
</script>

<template>
	<OnClickOutside v-if="isLogged" @trigger="open = false">
		<div class="relative">
			<button class="ml-3 flex items-center text-gray-300 hover:text-white" @click="open = !open">
				<img :src="user?.avatar" class="h-8 w-8 rounded-full object-contain" />
				<SvgIcon name="caret" class="ml-1 hidden h-3 w-3 lg:block" />
			</button>
			<Popper v-if="open" placement="bottom" :offsets="{ x: 5, y: 20 }">
				<Transition
					appear
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="transform -translate-y-4 opacity-0"
				>
					<div class="z-10 mr-2 w-64 rounded bg-white py-3 px-4 shadow-xl">
						<div class="mb-2 flex border-b border-gray-100 border-opacity-50 pb-4">
							<img :src="user?.avatar" class="h-14 w-14 rounded-full object-contain" />
							<div class="min-w-0 flex-1 pl-3">
								<p class="truncate text-sm font-bold text-gray-800">{{ user?.name }}</p>
								<p class="mb-2 truncate text-xs text-gray-600">{{ user?.email }}</p>
								<a href="https://www.freepik.com/profile/me" class="text-sm font-bold text-blue-500"> Edit profile </a>
							</div>
						</div>
						<a
							href="/my-creations"
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500"
						>
							<SvgIcon name="grid" class="mr-2 h-4 w-4 fill-current p-px" />
							My creations
						</a>
						<a
							href="https://support.freepik.com/hc/en-us"
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500"
						>
							<SvgIcon name="support" class="mr-2 h-4 w-4 fill-current p-px" />
							Support
						</a>
						<button
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500"
							@click="openReportBug"
						>
							<SvgIcon name="cross" class="mr-2 h-4 w-4 fill-current p-px" />
							Report an error
						</button>
						<button
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500"
							@click="logoutHandler"
						>
							<SvgIcon name="logout" class="mr-2 h-4 w-4 fill-current p-px" />
							Logout
						</button>
					</div>
				</Transition>
			</Popper>
		</div>
	</OnClickOutside>
</template>
