<script lang="ts" setup>
// Vue & Packges
import Bugsnag from '@bugsnag/js';
import { computed, watch } from 'vue';

// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Store
import { useMainStore } from '@/stores/store';
// Types
import { Panels } from '@/Types/types';
const store = useMainStore();

// Using composables
const { isTouch } = useDeviceInfo();

// Props
const props = defineProps<{ disableClose?: boolean; modal?: boolean; title: string }>();

// Emits
const emit = defineEmits(['goBack', 'closeModal']);

// Computeds
const computedActivePanel = computed(() => store.activePanel);

const backButtonVisibility = computed(
	() => !props.modal && (!!store.activeTemplateCategory?.id || !!store.activeElementType)
);

// Watchers
watch(computedActivePanel, (newVal) => {
	if (newVal !== Panels.templates) {
		store.activeTemplateCategory = null;
	}
});

// Methods
const onCloseModal = () => {
	if (props.disableClose) return;
	emit('closeModal');
};

const onClosePanel = () => {
	Bugsnag.leaveBreadcrumb(`Close ${store.activePanel} panel`);
	store.activePanel = null;
};

const onGoBack = () => emit('goBack');
</script>

<template>
	<div class="mb-3 flex items-center justify-between" :class="modal ? 'lg:mb-2' : 'lg:mb-4'">
		<div class="flex min-w-0 items-center">
			<button
				v-if="backButtonVisibility"
				class="mr-1 h-6 w-6 cursor-pointer text-gray-100 hover:text-white"
				@click="onGoBack"
			>
				<SvgIcon name="return" class="h-6 w-6 fill-current" />
			</button>
			<h3
				class="h-6 flex-1 truncate text-xl font-semibold leading-6"
				:class="modal ? 'text-gray-800' : 'text-gray-100'"
			>
				{{ title }}
			</h3>
		</div>

		<template v-if="!disableClose">
			<button
				v-if="modal"
				data-testid="close-btn-img-modal"
				class="flex h-8 w-8 items-center justify-center rounded text-gray-700 hover:bg-gray-100/25 lg:bg-transparent"
				@click="onCloseModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 fill-current" />
			</button>
			<button
				v-else
				data-testid="hide-panel"
				class="absolute top-0 right-0 mt-2 mr-2 flex h-8 w-8 scale-75 items-center justify-center rounded-full bg-gray-700 text-gray-100 hover:text-white lg:relative lg:mr-0 lg:mt-0 lg:h-auto lg:w-auto lg:scale-100 lg:bg-transparent"
				@click="onClosePanel"
			>
				<SvgIcon v-if="isTouch" name="cross" class="h-5 w-5 fill-current" />
				<SvgIcon v-else name="backwards" class="h-5 w-5 fill-current" />
			</button>
		</template>
	</div>
</template>
