// Bugsnag
import Bugsnag from '@bugsnag/js';
import Moveable, { OnDrag, OnResize } from 'moveable';
import { ComputedRef, nextTick, Ref, ref } from 'vue';

import Image from '@/Classes/Image';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { Position, Size } from '@/Types/types';
import ElementTools from '@/utils/ElementTools';
import GAnalytics from '@/utils/GAnalytics';

const ghostMoveable = ref<Moveable | null>();

const initialSize = ref<Size>({ width: 0, height: 0 });
const initialPosition = ref<Position>({ x: 0, y: 0 });
const initialCropSize = ref<Size>({ width: 0, height: 0 });
const initialCropPosition = ref<Position>({ x: 0, y: 0 });
const initialDirections = ref<boolean | string[] | undefined>([]);
const fromApplyCrop = ref(false);

export const useCrop = (image: Ref<Image> | ComputedRef<Image>) => {
	const { moveable, isCropping } = useInteractions();
	const store = useMainStore();
	const temporalSize = ref<Size | null>(null);

	const initCrop = async () => {
		// Guardamos los datos iniciales para restaurarlos en caso de cancelar el crop
		initialSize.value = {
			width: image.value.size.width,
			height: image.value.size.height,
		};

		initialPosition.value = {
			x: image.value.position.x,
			y: image.value.position.y,
		};

		initialCropSize.value = {
			width: image.value.crop.size.width,
			height: image.value.crop.size.height,
		};

		initialCropPosition.value = {
			x: image.value.crop.position.x,
			y: image.value.crop.position.y,
		};

		// Si no tiene crop le seteamos el tamaño de crop al original
		if (!image.value.hasCrop()) {
			image.value.crop.size = {
				width: image.value.size.width,
				height: image.value.size.height,
			};
		}

		await nextTick();
		setMoveableConfigForCrop();
		await nextTick();

		ghostMoveable.value = createGhostMoveable();
		registerGhostMoveableEvents();
		GAnalytics.track('click', 'Button', `crop`, null);
	};

	const stopCrop = () => {
		unsetMoveableConfigForCrop();
		store.croppingId = null;
		ghostMoveable.value?.destroy();
		ghostMoveable.value = null;
	};

	const cancelCrop = () => {
		image.value.size = {
			width: initialSize.value.width,
			height: initialSize.value.height,
		};

		image.value.position = {
			x: initialPosition.value.x,
			y: initialPosition.value.y,
		};

		image.value.crop.size = {
			width: initialCropSize.value.width,
			height: initialCropSize.value.height,
		};

		image.value.crop.position = {
			x: initialCropPosition.value.x,
			y: initialCropPosition.value.y,
		};
		Bugsnag.leaveBreadcrumb(`Cancel crop to ${image.value.id}`);

		stopCrop();
	};

	const applyCrop = async () => {
		if (!image.value) {
			throw new Error('Not image to crop');
		}
		Bugsnag.leaveBreadcrumb(`Apply crop to ${image.value.id}`);

		fromApplyCrop.value = true;

		await nextTick();
		stopCrop();
		const project = useProjectStore();
		project.saveState();
	};

	const setMoveableConfigForCrop = () => {
		if (!moveable.value) {
			throw new Error('Moveable error');
		}

		initialDirections.value = moveable.value.renderDirections;

		moveable.value!.keepRatio = false;
		moveable.value.renderDirections = ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'];
		moveable.value.passDragArea = true;
		moveable.value.draggable = false;
		moveable.value.rotationPosition = 'none';
		moveable.value.snappable = false;
		moveable.value.target = document.querySelector('.main-image') as HTMLElement;
		moveable.value?.updateTarget();
	};

	const createGhostMoveable = () => {
		return new Moveable(document.body, {
			target: document.querySelector('.ghost-image') as HTMLElement,
			renderDirections: ['nw', 'ne', 'sw', 'se'],
			snappable: false,
			draggable: true,
			resizable: true,
			rotatable: false,
			pinchable: false,
			origin: false,
			keepRatio: true,
			edge: false, // Resize, Scale Events at edges.
			rootContainer: document.getElementById('scroll-area'),
			container: document.getElementById('scroll-area'),
			portalContainer: document.getElementById('portalTargetCrop'),
			checkInput: true,
			className: 'moveable-ghost',
			// padding: {
			// 	left: 5,
			// 	top: 5,
			// 	right: 5,
			// 	bottom: 5,
			// },
		});
	};

	const registerGhostMoveableEvents = () => {
		ghostMoveable.value!.on('drag', dragHandler).on('resize', resizeHandler);
	};

	const dragHandler = (ev: OnDrag) => {
		const pos = { x: image.value.crop.position.x + ev.delta[0], y: image.value.crop.position.y + ev.delta[1] };
		image.value.crop.position = pos;
		ev.target.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
	};

	const resizeHandler = (ev: OnResize) => {
		if (!image.value.hasCrop()) return;

		const { target } = ev;
		const { position, size } = getGhostPropsRegardingLimits(ev);

		store.$patch(() => {
			image.value.crop.size = size;
			image.value.crop.position = position;
		});

		// Es necesario para evitar flickeo al redimensionar
		target!.style.width = `${size.width}px`;
		target!.style.height = `${size.height}px`;
		target!.style.transform = `translate(${position.x}px, ${position.y}px)`;
	};

	const unsetMoveableConfigForCrop = async () => {
		const selection = store.selectionId;
		store.clearSelection();

		await nextTick();

		store.selectionId = selection;
	};

	// #3615 | Crop limits
	// const getGhostLimits = (delta: number[]) => {
	// 	const crop = image.value.crop;
	// 	const position = image.value.position;
	// 	const size = image.value.size;
	// 	return {
	// 		top: crop.position.x + delta[1] >= 0,
	// 		right: crop.position.x + crop.size.width + delta[0] <= position.x + size.width,
	// 		bottom: crop.position.y + crop.size.height + delta[1] <= size.height,
	// 		left: crop.position.y + delta[0] >= 0,
	// 	}
	// }

	const getGhostPropsRegardingLimits = (ev: OnResize) => {
		const { direction, drag, width, height } = ev;
		const { translate } = drag;

		const position = {
			x: translate[0],
			y: translate[1],
		};

		const size = { width, height };

		// #3615 | Crop limits
		// const position = {
		// 	x: image.value.flip.x ? Math.max(-translate[0], 0) : Math.min(translate[0], 0),
		// 	y: image.value.flip.y ? Math.max(-translate[1], 0) : Math.min(translate[1], 0),
		// }

		// const isLeftHandler = direction[0] === -1;
		// const isRightHandler = direction[0] === 1;
		// const isTopHandler = direction[1] === -1;
		// const isBottomHandler = direction[1] === 1;

		// const limits = getGhostPropsRegardingLimits(ev);

		// if (isLeftHandler && limits.left && delta[0] >= 0) {
		// 	position.x = maxPositionAndSize.position.x;
		// 	size.width = maxPositionAndSize.size.width;
		// }

		// if (isRightHandler && limits.right) {
		// 	size.width = this.crop.size.width + this.crop.position.x;
		// }

		// if (isTopHandler && limits.top && delta[1] >= 0) {
		// 	position.y = maxPositionAndSize.position.y;
		// 	size.height = maxPositionAndSize.size.height;
		// }

		// if (isBottomHandler && limits.bottom) {
		// 	size.height = this.crop.size.height + this.crop.position.y;
		// }

		return { position, size };
	};

	// Methods | PreCrop
	const preCropHandler = (delta: number[], dir: number[], size: Size) => {
		const isLeftHandler = dir[0] === -1;
		const isRightHandler = dir[0] === 1;
		const isTopHandler = dir[1] === -1;
		const isBottomHandler = dir[1] === 1;

		const gettingBiggerWidth = delta[0] > 0;
		const gettingSmallWidth = delta[0] < 0;
		const gettingBiggerHeight = delta[1] > 0;
		const gettingSmallHeight = delta[1] < 0;

		const horizontalLimitContract = temporalSize.value && temporalSize.value.width < image.value.crop.size.width;
		const verticalLimitContract = temporalSize.value && temporalSize.value.height < image.value.crop.size.height;

		if (!image.value.hasCrop()) {
			image.value.crop.size.width = image.value.size.width;
			image.value.crop.size.height = image.value.size.height;
		}

		if (isRightHandler) {
			const shouldExpandWidth =
				gettingBiggerWidth && image.value.crop.size.width - size.width + image.value.crop.position.x <= 0;
			const shouldContractWidth = gettingSmallWidth && verticalLimitContract && horizontalLimitContract;

			if (!isCropping.value && (shouldExpandWidth || shouldContractWidth)) {
				setWidthAndKeepPosition(delta[0]);
			}
		}

		if (isLeftHandler) {
			const shouldExpandWidth = gettingBiggerWidth && image.value.crop.position.x >= 0;
			const shouldContractWidth = gettingSmallWidth && horizontalLimitContract && verticalLimitContract;

			if (!isCropping.value && (shouldExpandWidth || shouldContractWidth)) {
				setWidthAndKeepPosition(delta[0], true);
			} else {
				image.value.crop.position.x += delta[0];
			}
		}

		if (isBottomHandler) {
			const shouldExpandHeight =
				gettingBiggerHeight && image.value.crop.size.height - size.height + image.value.crop.position.y <= 0;
			const shouldContractHeight = gettingSmallHeight && verticalLimitContract && horizontalLimitContract;

			if (!isCropping.value && (shouldExpandHeight || shouldContractHeight)) {
				setHeightAndKeepPosition(delta[1]);
			}
		}

		if (isTopHandler) {
			const shouldExpandHeight = gettingBiggerHeight && image.value.crop.position.y >= 0;
			const shouldContractHeight = gettingSmallHeight && horizontalLimitContract && verticalLimitContract;

			if (!isCropping.value && (shouldExpandHeight || shouldContractHeight)) {
				setHeightAndKeepPosition(delta[1], true);
			} else {
				image.value.crop.position.y += delta[1];
			}
		}
	};

	const setWidthAndKeepPosition = (deltaX: number, resetTranslate = false) => {
		const prevHeight = image.value.crop.size.height;
		// Actualizamos el size del crop al nuevo width manteniendo el aspect ratio
		image.value.crop.size = ElementTools.getSizeKeepingAspectRatioByWidth(
			image.value.crop.size,
			image.value.crop.size.width < image.value.size.width
				? image.value.size.width + deltaX
				: image.value.crop.size.width + deltaX
		);
		if (deltaX < 0 && image.value.crop.size.height < image.value.size.height) {
			image.value.crop.size = ElementTools.getSizeKeepingAspectRatioByHeight(
				image.value.crop.size,
				image.value.size.height
			);
		}
		const diffHeight = image.value.crop.size.height - prevHeight;
		// Cuando tiras de los handlers top o left y el movimiento es muy brusco se produce un offset en la caja
		// reseteamos el position para evitar este efecto
		if (resetTranslate && image.value.crop.position.x > 0) {
			image.value.crop.position.x = 0;
		}
		image.value.crop.position.y -= diffHeight / 2;
	};

	const setHeightAndKeepPosition = (deltaY: number, resetTranslate = false) => {
		const prevWidth = image.value.crop.size.width;
		// Actualizamos el size del crop al nuevo height manteniendo el aspect ratio
		image.value.crop.size = ElementTools.getSizeKeepingAspectRatioByHeight(
			image.value.crop.size,
			image.value.crop.size.height < image.value.size.height
				? image.value.size.height + deltaY
				: image.value.crop.size.height + deltaY
		);
		if (deltaY < 0 && image.value.crop.size.width < image.value.size.width) {
			image.value.crop.size = ElementTools.getSizeKeepingAspectRatioByWidth(
				image.value.crop.size,
				image.value.size.width
			);
		}
		const diffWidth = image.value.crop.size.width - prevWidth;
		// Cuando tiras de los handlers top o left y el movimiento es muy brusco se produce un offset en la caja
		// reseteamos el position para evitar este efecto
		if (resetTranslate && image.value.crop.position.y > 0) {
			image.value.crop.position.y = 0;
		}
		image.value.crop.position.x -= diffWidth / 2;
	};

	const fitCroppedImageOnResize = (element: Image, size: Size) => {
		if (!element.hasCrop()) return;

		element.crop.size.width *= size.width / element.size.width;
		element.crop.size.height *= size.height / element.size.height;
		element.crop.position.x *= size.width / element.size.width;
		element.crop.position.y *= size.height / element.size.height;
	};

	const resizeCropByCornerHandler = (element: Image, delta: number[], dir: number[]) => {
		if (!element.hasCrop()) return;

		const isLeftTopHandler = dir[0] === -1 && dir[1] === -1;
		const isRightTopHandler = dir[0] === 1 && dir[1] === -1;
		const isLeftBottomHandler = dir[0] === -1 && dir[1] === 1;

		if (isLeftTopHandler) {
			element.crop.position.x += delta[0];
			element.crop.position.y += delta[1];
		}

		if (isLeftBottomHandler) {
			element.crop.position.x += delta[0];
		}

		if (isRightTopHandler) {
			element.crop.position.y += delta[1];
		}
	};

	const resizeCropByMiddleHandler = (element: Image, delta: number[], dir: number[], limits: any) => {
		if (!element.hasCrop()) return;

		const isLeftHandler = dir[0] === -1;
		const isTopHandler = dir[1] === -1;

		if (isLeftHandler) {
			// #3615 | Crop limits
			// element.crop.position.x = limits.left && delta[0] >= 0 ? 0 : element.crop.position.x + delta[0];
			element.crop.position.x += delta[0];
		}

		if (isTopHandler) {
			// #3615 | Crop limits
			// element.crop.position.y = limits.top && delta[1] >= 0 ? 0 : element.crop.position.y + delta[1];
			element.crop.position.y += delta[1];
		}
	};

	return {
		initCrop,
		applyCrop,
		cancelCrop,
		preCropHandler,
		fitCroppedImageOnResize,
		resizeCropByCornerHandler,
		resizeCropByMiddleHandler,
		initialCropPosition,
		initialCropSize,
		initialPosition,
		initialSize,
		fromApplyCrop,
		temporalSize,
	};
};

export const useGhostMoveable = () => ({ ghostMoveable });
