import { cloneDeep } from 'lodash-es';
import { computed, Ref, ref } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
import Line from '@/Classes/Line';
import Page from '@/Classes/Page';
import { QRCode } from '@/Classes/QRCode';
import { Shape } from '@/Classes/Shape';
import { SolidColor } from '@/Classes/SolidColor';
import Storyset from '@/Classes/Storyset';
import { Text } from '@/Classes/Text';
import { useShapeColors } from '@/composables/element/shape/useShapeColors';
import { useTextColors } from '@/composables/element/text/useTextColors';
import { useBugsnag } from '@/composables/useBugsnag';
import { useEditorMode } from '@/composables/useEditorMode';
import { useProjectStore } from '@/stores/project';
import { Color } from '@/Types/colorsTypes';

const { bugsnagMsgWithDebounce } = useBugsnag();

export const useProjectColors = () => {
	const colorSelected = ref<Color>();
	const elementsWithColor = ref<(Element | Page)[]>([]);
	const temporalShape = ref<Shape>(Shape.createDefault());
	const temporalText = ref<Text>(Text.createDefault());

	const project = useProjectStore();
	const { updateColor, colors } = useShapeColors(temporalShape as Ref<Shape>);
	const {
		updateColor: updateTextColor,
		updateOutlineColor,
		colors: textColors,
	} = useTextColors(temporalText as Ref<Text>);
	const { isPhotoMode } = useEditorMode();

	const shapeColors = computed(() => {
		return project.allShapes
			.map((shape) => {
				temporalShape.value = shape;
				return [colors.value];
			})
			.flat(2);
	});

	// Texts have main-color(s), border-color and shadow-color*
	const textsColors = computed(() => {
		return project.allTexts
			.map((text) => {
				temporalText.value = text;
				const all = [textColors.value];
				if (temporalText.value.outline.width) all.push(temporalText.value.outline.color);
				return all;
			})
			.flat(2);
	});

	const linesStrokeColors = computed(() => {
		return project.allLines.map((line) => line.mainColor);
	});

	const projectColors = computed(() => {
		const backgroundColors = isPhotoMode.value ? [] : (project.pages.map((template) => template.background) as Color[]);
		const storysetsColors = project.allStorysets.map((storyset) => storyset.mainColor);
		const qrcodesColors = project.allQRCodes.map((qrcode) => [qrcode.frontColor, qrcode.bgColor]);
		const linesColors = project.allLines.map((line) => {
			const colors = [line.mainColor];

			if (line.markerStart) {
				colors.push(line.markerStart.color);
			}

			if (line.markerEnd) {
				colors.push(line.markerEnd.color);
			}

			return colors;
		});

		// Quitamos repetidos
		const colorElements = [
			...shapeColors.value,
			...textsColors.value,
			...backgroundColors,
			...storysetsColors,
			...qrcodesColors,
			...linesColors,
		];

		return colorElements
			.flatMap((color) => color)
			.filter(
				(color, index, colorList) =>
					index === colorList.findIndex((c) => c.toCssStringWithoutAlpha() === color.toCssStringWithoutAlpha())
			)
			.map((color) => color.withoutAlpha());
	});

	const getElementsWithColorSelected = () => {
		if (!colorSelected.value) {
			elementsWithColor.value = [];
			return;
		}

		const shapes = project.allShapes.filter((shape) => {
			temporalShape.value = shape;
			return colors.value
				.map((c) => c.toCssStringWithoutAlpha())
				.includes((colorSelected.value as Color).toCssStringWithoutAlpha());
		});
		const storysets = project.allStorysets.filter(
			(storyset) =>
				storyset.mainColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha()
		);
		const qrcodes = project.allQRCodes.filter(
			(qrcode) =>
				qrcode.frontColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha() ||
				qrcode.bgColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha()
		);
		const texts = project.allTexts.filter((text) => {
			temporalText.value = text;
			const all = [textColors.value];
			if (temporalText.value.outline.width) all.push(temporalText.value.outline.color);
			return all
				.flat(2)
				.map((c) => c.toCssStringWithoutAlpha())
				.includes((colorSelected.value as Color).toCssStringWithoutAlpha());
		});
		const lines = project.allLines.filter(
			(line) =>
				line.mainColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha() ||
				(line.markerStart &&
					line.markerStart.color.toCssStringWithoutAlpha() ===
						(colorSelected.value as Color).toCssStringWithoutAlpha()) ||
				(line.markerEnd &&
					line.markerEnd.color.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha())
		);
		const pages = project.pages.filter(
			(page) =>
				(page.background as Color).toCssStringWithoutAlpha() ===
				(colorSelected.value as Color).toCssStringWithoutAlpha()
		);

		elementsWithColor.value = [...shapes, ...storysets, ...qrcodes, ...texts, ...lines, ...pages] as Element[] | Page[];
	};

	const getFixedColor = (oldColor: Color, newColor: Color): Color => {
		if (newColor instanceof SolidColor && oldColor instanceof SolidColor) {
			const fixedColor = cloneDeep(newColor);
			fixedColor.a = oldColor.a;
			return fixedColor;
		}

		if (newColor instanceof GradientColor && oldColor instanceof GradientColor) {
			const fixedColor = cloneDeep(newColor);
			fixedColor.stops.forEach((newStop) => {
				oldColor.stops.forEach((oldStop) => {
					if (oldStop.r === newStop.r && oldStop.g === newStop.g && oldStop.b === newStop.b) {
						newStop.a = oldStop.a;
					}
				});
			});
			return fixedColor;
		}

		return newColor;
	};

	const updatePageBgColor = (newColor: Color) => {
		const pages = elementsWithColor.value.filter((e) => e instanceof Page);
		pages.forEach((page: any) => page.updateBackgroundColor(getFixedColor(page.background, newColor)));
	};

	const updateQRCodeColor = (oldColor: Color, newColor: Color) => {
		const qrcodes = elementsWithColor.value.filter((e) => e instanceof QRCode);

		qrcodes.forEach((qrcode: any) => {
			if (qrcode.frontColor.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				const fixedColor = getFixedColor(oldColor, newColor);
				qrcode.updateFrontColor(fixedColor);
			}

			if (qrcode.bgColor.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				const fixedColor = getFixedColor(oldColor, newColor);
				qrcode.updateBgColor(fixedColor);
			}
		});
	};

	const updateLinesColor = (oldColor: Color, newColor: Color) => {
		const lines = elementsWithColor.value.filter((e) => e instanceof Line);

		lines.forEach((line: any) => {
			if (line.mainColor.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				line.updateStrokeColor(getFixedColor(line.mainColor, newColor));
			}

			if (line.markerStart && line.markerStart.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				line.updateMarkerStartColor(getFixedColor(line.markerStart.color, newColor));
			}

			if (line.markerEnd && line.markerEnd.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				line.updateMarkerEndColor(getFixedColor(line.markerEnd.color, newColor));
			}
		});
	};

	const updateShapesColor = (oldColor: Color, newColor: Color) => {
		const shapes = elementsWithColor.value.filter((e) => e instanceof Shape);

		shapes.forEach((shape: any) => {
			temporalShape.value = shape;
			colors.value
				.filter((c) => c.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha())
				.forEach((c) => {
					updateColor(c, getFixedColor(c, newColor));
				});
		});
	};

	const updateStorysetsColor = (oldColor: Color, newColor: Color) => {
		const storysets = elementsWithColor.value.filter((e) => e instanceof Storyset);
		storysets.forEach((storyset: any) => storyset.updateColor(getFixedColor(oldColor, newColor)));
	};

	const updateTextsColor = (oldColor: Color, newColor: Color) => {
		const texts = elementsWithColor.value.filter((e) => e instanceof Text);

		texts.forEach((text: any) => {
			temporalText.value = text;
			const colorsToChange = textColors.value.filter(
				(c) => c.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()
			);
			colorsToChange.forEach((c) => {
				const fixedColor = getFixedColor(c, newColor);
				updateTextColor(c, fixedColor);
			});

			if (
				temporalText.value.outline.width &&
				temporalText.value.outline.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()
			) {
				updateOutlineColor(temporalText.value.outline.color, getFixedColor(temporalText.value.outline.color, newColor));
			}
		});
	};

	const updateTemplateColor = (oldColor: Color, newColor: Color) => {
		const clone = cloneDeep(oldColor);

		updatePageBgColor(newColor);
		updateQRCodeColor(clone, newColor);
		updateLinesColor(clone, newColor);
		updateShapesColor(clone, newColor);
		updateStorysetsColor(clone, newColor);
		updateTextsColor(clone, newColor);

		bugsnagMsgWithDebounce(`Update template colors`);
	};

	return {
		colors: projectColors,
		updateTemplateColor,
		getElementsWithColorSelected,
		colorSelected,
		textsColors,
		linesStrokeColors,
	};
};
