<script lang="ts" setup>
// Classes
// Composables
import { toRef } from 'vue';

import Line from '@/Classes/Line';
// Components
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import { useLineColors } from '@/composables/element/line/useLineColors';

// Props
const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');
const { updateMarkerStartColor, updateMarkerEndColor } = useLineColors(element);
</script>

<template>
	<div class="mb-6">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Markers</h4>

		<div class="grid grid-cols-6 gap-2">
			<ColorPicker
				v-if="element.markerStart"
				class="h-10 w-10"
				:color="element.markerStart.color"
				@change="updateMarkerStartColor"
			/>

			<ColorPicker
				v-if="element.markerEnd"
				class="h-10 w-10"
				:color="element.markerEnd.color"
				@change="updateMarkerEndColor"
			/>
		</div>
	</div>
</template>
