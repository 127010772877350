<script lang="ts" setup>
import Image from '@/Classes/Image';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import ImageFilter from '@/components/selection/toolbars/images/ImageFilter.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';

const store = useMainStore();
const { isMobile } = useDeviceInfo();

defineProps<{ element: Image }>();

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<ImageFilter v-if="isMobile" class="pt-4" :image="element" />

	<template v-else>
		<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
			<h2 class="text-xl font-semibold text-gray-100">Edit filters</h2>

			<button
				data-testid="close-panel"
				class="text-gray-100 hover:text-white focus:outline-none"
				@click="onCloseEditPanel"
			>
				<SvgIcon name="cross" class="h-5 w-5" />
			</button>
		</div>

		<div data-testid="sidebar-panel" class="flex flex-col">
			<ImageFilter :image="element" />
		</div>
	</template>
</template>
