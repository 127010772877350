import { createSharedComposable, useUrlSearchParams } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { useAuth } from '@/composables/useAuth';
import { useMainStore } from '@/stores/store';
import { Panels } from '@/Types/types';

export type EditorMode = 'editor' | 'photo' | 'admin' | 'predefined-text';
export type EditorContext = 'wepik' | 'slidesgo' | 'report' | 'illustrator';

const mode: Ref<EditorMode> = ref('editor');
const context: Ref<EditorContext> = ref('wepik');

const isEditorMode = computed(() => mode.value === 'editor');
const isAdminMode = computed(() => mode.value === 'admin');
const isPhotoMode = computed(() => mode.value === 'photo');
const isPredefinedTextMode = computed(() => mode.value === 'predefined-text');
const isWepikContext = computed(() => context.value === 'wepik');
const inSlidesgoContext = computed(() => context.value === 'slidesgo');
const isIllustratorContext = computed(() => context.value === 'illustrator');
const isReportContext = computed(() => context.value === 'report');
const isRenderingContext = window.renderData !== undefined;

const params = useUrlSearchParams<{
	photo?: string;
	admin?: number;
	illustrator?: number;
	debug?: number;
	convert?: number;
	mode?: string;
	project?: string;
	report?: string;
}>();
const isDebugMode = computed(() => params.debug);
const isDevelopmentEnvironment = import.meta.env.MODE === 'development';

export const useEditorMode = createSharedComposable(() => {
	const store = useMainStore();
	const { user } = useAuth();

	const detectMode = () => {
		if (params.mode === 'photo' || params.photo) {
			mode.value = 'photo';
			store.activePanel = Panels.photoEdit;
		} else if (params.admin && params.mode === 'predefined-text' && user.value?.isAdmin) {
			mode.value = 'predefined-text';
		} else if (params.admin) {
			mode.value = 'admin';
		}
	};

	const detectContext = () => {
		if (params.project === 'slidesgo' || store.userVector?.project === 'slidesgo') {
			context.value = 'slidesgo';
		} else if (params.illustrator == 1) {
			context.value = 'illustrator';
			store.activePanel = Panels.treeEditor;
		}

		if (params.report && params.admin) {
			context.value = 'report';
		}
	};

	const initMode = () => {
		detectMode();
		detectContext();
	};

	return {
		mode,
		detect: initMode,
		isEditorMode,
		isAdminMode,
		isPhotoMode,
		isPredefinedTextMode,
		isDebugMode,
		isWepikContext,
		inSlidesgoContext,
		isIllustratorContext,
		isRenderingContext,
		isReportContext,
		isDevelopmentEnvironment,
	};
});
