+
<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { computed, ref, watch } from 'vue';

// Api
import { getStickersCategories, getStickersElements } from '@/api/DataApiClient';
// Composables
import { getSvg } from '@/api/DataApiClient';
// Classes
import { Shape } from '@/Classes/Shape';
import AutocompleteInput from '@/components/Common/AutocompleteInput.vue';
// Components
import InfiniteLoading from '@/components/Common/InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
// Stores
import { useMainStore } from '@/stores/store';
// Type
import { StickersCategoryApi } from '@/Types/apiClient';
import { ElementCategory, ElementsPanelTypes } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';
const store = useMainStore();

// Props
const props = defineProps<{ activeElementCategory: ElementCategory | null }>();

// Emits
const emit = defineEmits(['setElementCategory', 'elementAdded']);

const temporalRef = ref(Shape.createDefault());
const usingElementOrchestrator = useElementOrchestrator(temporalRef);
const { addElement } = useActivePage();

// Constants
const CATEGORY_URL_CONFIG = {
	ICON_TYPE: 'sticker',
	LIMIT: 20,
	PAGE: 1,
};

const ELEMENT_URL_CONFIG = {
	ICON_TYPE: 'sticker',
	LIMIT: 100,
	PAGE: 1,
};

// Data
const search = ref('');

// Computed
const autocompleteSource = computed(() => `autocomplete/flaticon?search=${search.value}&type=sticker`);
const categoriesSource = computed(
	() =>
		`flaticon/stickers?limit=${CATEGORY_URL_CONFIG.LIMIT}&page=${CATEGORY_URL_CONFIG.PAGE}&q=${search.value}&iconType=${CATEGORY_URL_CONFIG.ICON_TYPE}&tagsId=`
);
const elementsSource = computed(
	() =>
		`flaticon/search?limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}&q=${search.value}&iconType=${ELEMENT_URL_CONFIG.ICON_TYPE}&tagsId=${props.activeElementCategory?.id}`
);
const showCategories = computed(() => !props.activeElementCategory && !search.value);

// Methods
const onClickSticker = async (url: string) => {
	const { data } = await getSvg(url);

	if (!data.value || !store.activePage) return;

	const shape = Shape.fromSvg(data.value);
	temporalRef.value = shape;
	addElement(shape);

	usingElementOrchestrator.value.setupInPage();

	store.setSelection(shape, false);
	GAnalytics.track('click', 'Button', 'add-icon', null);
	Bugsnag.leaveBreadcrumb(`Add Sticker to canvas: ${shape.type}-${shape.id}`);
	emit('elementAdded');
};

const onClickSeeAll = (cat: StickersCategoryApi) => emit('setElementCategory', cat);

const updateSearchValue = (searchValue: string) => {
	search.value = searchValue;

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in Sticker panel: ${searchValue}`);
};
</script>

<template>
	<div class="flex h-full flex-col">
		<AutocompleteInput
			:placeholder="'Search Sticker...'"
			:query="search"
			:autocomplete-source="autocompleteSource"
			@change="updateSearchValue"
		/>

		<!-- Categories -->
		<InfiniteLoading
			v-if="showCategories"
			:source="categoriesSource"
			:fetch="getStickersCategories"
			container-classes="grid gap-8 pt-2"
		>
			<template #item="{ item }">
				<div class="group">
					<div class="mt-0 mb-3 flex items-center justify-between">
						<p class="text-xs font-bold uppercase text-gray-100">{{ item.name }}</p>
						<button
							class="text-xs font-bold text-gray-100 hover:text-white group-hover:opacity-100 lg:opacity-0"
							@click.stop="onClickSeeAll(item)"
						>
							See all
						</button>
					</div>

					<div class="grid w-full grid-cols-3 gap-4">
						<InsertableElement
							v-for="el in (item.previews || []).slice(0, 6)"
							:key="el.id"
							:data="el"
							:type="ElementsPanelTypes.Stickers"
							:draggable="true"
							data-testid="stickers"
							class="flex flex-col"
							@click.stop="onClickSticker(el.svg)"
						>
							<img :src="el.preview" :alt="item.name" class="h-16 w-16 object-contain" />
						</InsertableElement>
					</div>
				</div>
			</template>
		</InfiniteLoading>

		<!-- Elements -->
		<InfiniteLoading
			v-else
			:source="elementsSource"
			:fetch="getStickersElements"
			container-classes="grid gap-4 m-2 grid-cols-2"
		>
			<template #item="{ item }">
				<InsertableElement
					:data="item"
					:type="ElementsPanelTypes.Stickers"
					:draggable="true"
					class="cursor-pointer rounded px-3 py-2 transition-opacity duration-300 hover:opacity-50"
					@click.stop="onClickSticker(item.svg)"
				>
					<img
						:src="item.preview"
						:data-svg="item.svg"
						:alt="item.name"
						draggable="true"
						class="h-24 w-full object-contain"
					/>
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
