<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';

// Classes
import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useFeedback } from '@/composables/interactions/useFeedback';
import { useActivePage } from '@/composables/page/useActivePage';

// Props
const props = defineProps<{ element: Element }>();

const { deleteFeedback } = useFeedback();

const { removeElement } = useActivePage();

const remove = () => {
	deleteFeedback();
	removeElement(props.element);
	Bugsnag.leaveBreadcrumb(`remove ${props.element.type}-${props.element.id}`);
};
</script>

<template>
	<button
		data-testid="remove"
		class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-8 lg:justify-center"
		@click="remove"
	>
		<SvgIcon name="trash" :toolbar="true" class="h-4 w-4 lg:scale-90" />
		<span class="text-2xs lg:hidden">Remove</span>
	</button>
</template>
