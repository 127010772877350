<script lang="ts" setup>
import { toRef } from 'vue';

import { Text } from '@/Classes/Text';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import OrderMenu from '@/components/selection/panels/OrderMenu.vue';
import PositionMenu from '@/components/selection/panels/PositionMenu.vue';
import AlignmentMenu from '@/components/selection/toolbars/texts/AlignmentMenu.vue';
import ListMenu from '@/components/selection/toolbars/texts/ListMenu.vue';
import TextEffectsMenu from '@/components/selection/toolbars/texts/TextEffectsMenu.vue';
import TextStyleMenu from '@/components/selection/toolbars/texts/TextStyleMenu.vue';
import TextTransformMenu from '@/components/selection/toolbars/texts/TextTransformMenu.vue';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import { useGroup } from '@/composables/group/useGroup';
import { useMainStore } from '@/stores/store';

const store = useMainStore();

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const { selectedColor, updateColor, updateTextAlign } = useTextStyles(element);
const { isGrouped } = useGroup(element);

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">Edit text</h2>
		<button
			class="text-gray-100 hover:text-white focus:outline-none"
			data-testid="close-panel"
			@click="onCloseEditPanel"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col" data-keep-text-selection>
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<TextStyleMenu :element="element" />

		<div class="mb-6 grid grid-cols-3 gap-2">
			<AlignmentMenu :element="element" @update="updateTextAlign" />
			<ListMenu :element="element" />
			<TextTransformMenu :element="element" />
		</div>

		<TextEffectsMenu :element="element" />

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Colors</h4>
		<ColorPicker class="h-10 w-10" :color="selectedColor" :hide-gradient="true" @change="updateColor" />
	</div>
</template>
