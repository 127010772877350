<script setup lang="ts">
// Vue & Packages

import Bugsnag from '@bugsnag/js';
import { computed, ref } from 'vue';

// Classes
import { QRCode } from '@/Classes/QRCode';
// Components
import QRCodeMenu from '@/components/selection/panels/QRCodeMenu.vue';
// Composables
import { useQRCodeAsSVG } from '@/composables/element/qrcode/useQRCodeAsSVG';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
// Stores
import { useMainStore } from '@/stores/store';

// Data
const element = ref(QRCode.createDefault());
const elementPrev = ref(QRCode.createDefault());
elementPrev.value.url = 'https://wepik.com';

const qrPreview = useQRCodeAsSVG(elementPrev);
const qr = useQRCodeAsSVG(element);
const { listen } = useOrderedKeyboardListener();

// Computeds
const isReady = computed(() =>
	/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.exec(
		element.value.url
	)
);

// Using composables
const store = useMainStore();
const { addElement } = useActivePage();
const usingElementOrchestrator = useElementOrchestrator(element);

// Methods
const onInsert = () => {
	if (!isReady.value) throw new Error('QRCode can not be inserted!');
	addElement(element.value);
	usingElementOrchestrator.value.setupInPage();
	resetGenerator();

	Bugsnag.leaveBreadcrumb(`Insert ${element.value.type}-${element.value.id}`);
	store.activePanel = null;
};

const resetGenerator = () => {
	element.value = QRCode.createDefault();
};

// Register events
listen('Enter', () => {
	if (!isReady.value) return;
	onInsert();
});
</script>

<template>
	<div data-testid="add-qr-code-panel" class="flex flex-col">
		<div class="mt-2 mb-4 flex w-full items-center justify-center">
			<svg
				v-if="!qr || element.url === QRCode.defaults().url"
				:viewBox="QRCode.getViewBoxFromRawSvg(qrPreview)"
				class="h-20 w-20 rounded"
				preserveAspectRatio="none"
				v-html="QRCode.getInnerSvg(qrPreview)"
			></svg>
			<svg
				v-else
				:viewBox="QRCode.getViewBoxFromRawSvg(qr)"
				class="h-20 w-20 rounded"
				preserveAspectRatio="none"
				v-html="QRCode.getInnerSvg(qr)"
			></svg>
		</div>
		<p class="mb-4 text-center text-sm text-gray-300">
			A QR code will allow people to scan it and send it to the URL of your choice.
		</p>

		<QRCodeMenu :element="element" :editing="false" />

		<button
			class="mb-2 flex h-9 items-center justify-center rounded bg-blue-500 px-3 font-semibold text-white lg:pl-3 lg:pr-4"
			:class="{ 'cursor-not-allowed opacity-50': !isReady }"
			:disabled="!isReady"
			@click="onInsert"
		>
			<span>Insert QR</span>
		</button>
	</div>
</template>
