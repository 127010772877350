<script setup lang="ts">
import { onClickOutside, useEventListener } from '@vueuse/core';
import { onMounted, Ref, ref } from 'vue';

import Image from '@/Classes/Image';
import Page from '@/Classes/Page';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { usePage } from '@/composables/page/usePage';
import { useProject } from '@/composables/project/useProject';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMainStore } from '@/stores/store';

const CONTEXT_MENU_HEIGHT = 206;

// Emits
const emit = defineEmits(['show']);

const contextmenu = ref();
const left = ref(0);
const top = ref(0);
const visible = ref(false);
const temporalRefPage = ref(Page.createDefault());
const temporalRefElement = ref(Image.createDefault());
const { getPageFromDom, getPageFromElement } = useProject();
const { getElementFromDom } = usePage(temporalRefPage as Ref<Page>);
const usingElementOrchestrator = useElementOrchestrator(temporalRefElement);
const { isBackground } = usingElementOrchestrator.value as any;
const { isPhotoMode } = useEditorMode();

const show = async (e: MouseEvent) => {
	e.preventDefault();

	const store = useMainStore();

	const target = e.target as HTMLElement | null;
	const inCanvas = target && target.closest('.canvas') !== null;
	const inMoveable = target && target.closest('.moveable-control-box') !== null;

	if (!inCanvas && !inMoveable && !isPhotoMode.value) return;

	let pageInDom = target.closest('.canvas') as HTMLElement | null;
	const elementInDom = target.closest('.element') as HTMLElement | null;

	// Si no hay página comprobamos si el elemento que está debajo si que está en un canvas por si el moveable está por
	// delante o está haciendo click en moveable pero parte de este está fuera del canvas
	if (!pageInDom) {
		e.target.style.pointerEvents = 'none';
		const targetBehind = document.elementFromPoint(e.clientX, e.clientY);
		e.target.style.pointerEvents = 'auto';

		pageInDom = targetBehind.closest('.canvas') || targetBehind?.querySelector('.canvas');
	}

	// si no hay página, y es un grupo, la página es quien contenga el elemento seleccionado.
	// esto se da cuando hay un grupo y hacemos click derecho
	if (!pageInDom && store.selectionId.length) {
		pageInDom = store.selection[0].domNode()?.closest('.canvas') as HTMLElement;
	}

	// Si hay un elemento bajo el mouse lo seleccionamos
	if (elementInDom) {
		const page = getPageFromDom(pageInDom as HTMLElement) as Page;

		temporalRefPage.value = page;
		const element = getElementFromDom(elementInDom);

		if (element) {
			// Si el elemento es una imagen de fondo no la seleccionamos
			let forceSelection = true;

			if (element.type === 'image') {
				temporalRefElement.value = element as Image;
				forceSelection = !isBackground.value;
			}

			if (forceSelection) {
				store.setSelection(element, false);
			}
		}
	} else if (store.selectionId.length > 0) {
		if (!pageInDom) {
			throw new Error('Cannot found where was the click made');
		}

		const page = getPageFromDom(pageInDom) as Page;

		if (getPageFromElement(store.selection[0])?.id !== page.id) {
			store.clearSelection();
			store.setActivePage(page);
		}
	}

	visible.value = true;

	left.value = e.clientX;
	top.value = e.clientY;

	// Para evitar que el contextmenu se salga de la página
	if (window.innerHeight < e.pageY + CONTEXT_MENU_HEIGHT) {
		top.value = e.pageY - CONTEXT_MENU_HEIGHT;
	}

	emit('show');
};

const hide = () => {
	visible.value = false;
};

onMounted(() => {
	const target = document.querySelector('#scroll-area');

	useEventListener(target, 'contextmenu', show);
});

onClickOutside(contextmenu, hide);

defineExpose({
	hide,
	top,
	left,
});
</script>

<template>
	<ul
		v-if="visible"
		ref="contextmenu"
		class="ContextMenu is-open fixed z-30 m-0 hidden w-32 list-none overflow-hidden rounded p-0 py-1 text-sm shadow-custom outline-none"
		tabindex="-1"
		:style="{
			left: `${left}px`,
			top: `${top}px`,
		}"
	>
		<slot />
	</ul>
</template>

<style lang="scss">
.ContextMenu {
	background-color: rgba(255, 255, 255, 0.98);

	.ContextMenu-item {
		& > * {
			@apply py-1 px-3 text-gray-700;

			&:hover,
			&:focus {
				@apply bg-gray-100 bg-opacity-25;
			}

			&.ContextMenu-item-disable {
				@apply cursor-not-allowed text-gray-100;

				&:hover,
				&:focus {
					@apply bg-transparent;
				}
			}
		}

		&:focus {
			outline: 0;
		}
	}

	.ContextMenu-divider {
		background-color: #e3e9ed;
	}

	&.is-open {
		@apply block;
	}
}

.ContextMenu-item {
	@apply block cursor-pointer overflow-hidden truncate;
}

.ContextMenu-divider {
	@apply my-1 h-px;
}
</style>
