<script setup lang="ts">
import { computed, nextTick, ref, toRef } from 'vue';

import Image from '@/Classes/Image';
import FilterOverlay from '@/components/render/FilterOverlay.vue';
import { useImage } from '@/composables/element/image/useImage';
import { useImageRendering } from '@/composables/element/image/useImageRendering';
import { useMainStore } from '@/stores/store';

const store = useMainStore();

const props = defineProps<{ element: Image; forPreview?: boolean; scale: number; smartSelection?: boolean }>();
const element = toRef(props, 'element');

const image = ref();

const { isBackground } = useImage(element);
const { inRenderingContext, imageUrl, load, imageSize, maskedImage } = useImageRendering(
	element,
	image
);

load();

const browserLoaded = ref(false);

const imagePosition = computed(() => ({
	x: props.element.crop.position.x,
	y: props.element.crop.position.y,
}));

const hasSvgFilter = computed(() => {
	return !!props.element.filter?.blur;
});

const onDoubleClickInitCrop = async () => {
	store.croppingId = element.value.id;
};
</script>

<template>
	<div
		:data-test-crop="store.croppingId"
		:style="{
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
			clipPath: 'inset(0)',
		}"
	>
		<div
			:style="{
				contain: 'strict',
				width: `${element.size.width}px`,
				height: `${element.size.height}px`,
				clipPath: element.mask ? `url(#${element.id}-${element.mask.id})` : null,
				transform: `
        	scaleX(${element.flipHTML.x})
        	scaleY(${element.flipHTML.y})
				`
			} as any"
		>
			<div
				:style="{
					width: `${imageSize.width}px`,
					height: `${imageSize.height}px`,
					transform: `translate(${imagePosition.x}px,${imagePosition.y}px)`,
				}"
			>
				<div v-if="!browserLoaded && !inRenderingContext && !props.forPreview" class="absolute inset-0 bg-slate-100">
					<div class="absolute inset-0 animate-pulse bg-gray-100"></div>
				</div>

				<!-- La versión en baja resolución mientras carga el resto -->
				<img
					v-if="props.element.preview && !inRenderingContext && !props.forPreview && !browserLoaded"
					:src="props.element.preview"
					class="pointer-events-none absolute h-full w-full"
					data-preview
					alt=""
					:style="{
						filter: element.filter?.toCssFilter(),
						transform: `
							scaleX(${element.flipHTML.x})
							scaleY(${element.flipHTML.y})
						`,
					}"
				/>

				<img
					v-if="maskedImage && element.backgroundMode === 'both'"
					data-background
					:src="maskedImage"
					:data-test-isbackground="isBackground"
					class="absolute h-full w-full"
					alt=""
					:style="{
						filter: (['both', 'background'].includes(element.backgroundMode)) ? element.filter?.toCssFilter() : null,
						transform: `
							scaleX(${element.flipHTML.x})
							scaleY(${element.flipHTML.y})
						`,
					}"
				/>

				<FilterOverlay
					v-if="element.filter?.overlay && ['both', 'background'].includes(element.backgroundMode)"
					:image="element"
					:overlay="element.filter.overlay"
				/>

				<img
					v-if="imageUrl"
					ref="image"
					data-final-image
					data-foreground
					:src="imageUrl"
					:data-test-isbackground="isBackground"
					class="absolute h-full w-full"
					alt=""
					:style="{
						filter: element.backgroundMode !== 'both' ? element.filter?.toCssFilter() : null,
						transform: `
							scaleX(${element.flipHTML.x})
							scaleY(${element.flipHTML.y})
						`,
					}"
					@dragstart.prevent
					@dblclick="onDoubleClickInitCrop"
					@load="browserLoaded = true"
				/>

				<FilterOverlay
					v-if="element.filter?.overlay && ['both', 'foreground'].includes(element.backgroundMode) === false"
					:image="element"
					:overlay="element.filter.overlay"
				/>


				<svg v-if="element.mask" width="0" height="0">
					<clipPath
						:id="`${element.id}-${element.mask.id}`"
						clipPathUnits="objectBoundingBox"
						v-html="element.mask.content"
					></clipPath>
				</svg>
			</div>
		</div>
	</div>
</template>
