<script setup lang="ts">
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue
import { ref } from 'vue';

// Api
import { getMasks } from '@/api/DataApiClient';
// Composables
import { getSvg } from '@/api/DataApiClient';
// Classes
import { Shape } from '@/Classes/Shape';
import InfiniteLoading from '@/components/Common/InfiniteLoading.vue';
// Components
import InsertableElement from '@/components/Common/InsertableElement.vue';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
// Store
import { useMainStore } from '@/stores/store';
// Type
import { ElementsPanelTypes } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';

const store = useMainStore();
const { addElement } = useActivePage();
const temporalRef = ref(Shape.createDefault());
const usingElementOrchestrator = useElementOrchestrator(temporalRef);

const emit = defineEmits(['elementAdded']);

// Constants
const ELEMENT_URL_CONFIG = {
	LIMIT: 100,
	PAGE: 1,
};

// Data
const url = ref(`elements/category/1740?limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}`);

// Methods
const onClickBasicShape = async (url: string) => {
	const { data } = await getSvg(url);

	if (!data.value || !store.activePage) return;

	temporalRef.value = Shape.fromSvg(data.value);
	addElement(temporalRef.value);

	usingElementOrchestrator.value.setupInPage();

	store.setSelection(temporalRef.value, false);
	GAnalytics.track('click', 'Button', 'add-icon', null);
	Bugsnag.leaveBreadcrumb(`Add basic shape to canvas: shape-${temporalRef.value.id}`);
	emit('elementAdded');
};
</script>

<template>
	<div data-testid="add-elements-panel" class="flex h-full flex-col">
		<InfiniteLoading
			data-testid="container-items"
			:source="url"
			:fetch="getMasks"
			container-classes="grid grid-cols-5 gap-6"
		>
			<template #item="{ item }">
				<InsertableElement
					:data="item"
					:type="ElementsPanelTypes.BasicShapes"
					:draggable="true"
					class="flex"
					@click="onClickBasicShape(item.svg)"
				>
					<img v-if="item" :src="item.svg" :alt="item.name" class="h-10 w-10 object-contain" />
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
