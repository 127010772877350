<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

import Popper from '@/components/Common/Popper.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import WithAuth from '@/components/Common/WithAuth.vue';
import ShareModal from '@/components/export/ShareModal.vue';
const showModal = ref(false);

const showShareModal = () => {
	showModal.value = true;
	Bugsnag.leaveBreadcrumb(`Open share modal`);
};
</script>

<template>
	<div>
		<button
			data-onboarding="btn-share"
			class="btn-share relative flex h-8 w-8 items-center justify-center text-white opacity-50 hover:opacity-100 focus:opacity-100"
			tooltip="Share"
			tooltip-position="bottom"
			@click="showShareModal"
		>
			<SvgIcon name="share" class="h-4 w-4" />
		</button>

		<WithAuth v-if="showModal" @close="showModal = false">
			<Popper arrow placement="bottom" partent-selector=".btn-share" :offsets="{ x: 0, y: 20 }">
				<Transition
					appear
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="transform -translate-y-4 opacity-0"
				>
					<ShareModal @close="showModal = false" />
				</Transition>
			</Popper>
		</WithAuth>
	</div>
</template>
