<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import { Text } from '@/Classes/Text';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import FontPicker from '@/components/selection/panels/FontPicker.vue';
import CopyToolbar from '@/components/selection/toolbars/CopyToolbar.vue';
import MoreToolsToolbar from '@/components/selection/toolbars/MoreToolsToolbar.vue';
import OrderToolbar from '@/components/selection/toolbars/OrderToolbar.vue';
import RemoveTextToolbar from '@/components/selection/toolbars/RemoveTextToolbar.vue';
import RemoveToolbar from '@/components/selection/toolbars/RemoveToolbar.vue';
import TextBorderButtonToolbar from '@/components/selection/toolbars/texts/TextBorderButtonToolbar.vue';
import TextCurved from '@/components/selection/toolbars/texts/TextCurved.vue';
import TextEffectsButtonToolbar from '@/components/selection/toolbars/texts/TextEffectsButtonToolbar.vue';
import TextLink from '@/components/selection/toolbars/texts/TextLink.vue';
import TextShadowButtonToolbar from '@/components/selection/toolbars/texts/TextShadowButtonToolbar.vue';
import ToolbarFontSize from '@/components/selection/toolbars/ToolbarFontSize.vue';
import { useCircleTypeInfo } from '@/composables/element/text/useCircleTypeInfo';
import { selection, useTextStyles } from '@/composables/element/text/useTextStyles';
import { useBugsnag } from '@/composables/useBugsnag';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';

// Props
const props = defineProps<{ element: Text }>();

// Data
const element = toRef(props, 'element');

// Using composables
const { isMobile } = useDeviceInfo();
const store = useMainStore();
const {
	selectedColor,
	fontSizeScaled,
	finalFontFamily,
	fontWeight,
	italic,
	boldAvailable,
	italicAvailable,
	updateFontSize,
	updateColor,
	updateFontFamily,
	updateFontWeight,
	updateFontStyle,
	updateTextAlign,
	rescaleText,
} = useTextStyles(element);
const { breadScrumbWithDebounce } = useBugsnag(element);
const { isCircleText } = useCircleTypeInfo(element);
// Methods
const updateTextFontSize = (fontSize: number | 'plus' | 'minus') => {
	if (element.value.scale !== 1) {
		rescaleText();
	}

	updateFontSize(fontSize);
	breadScrumbWithDebounce('fontSize');
};

const updateWeight = (val: string | MouseEvent) => {
	if (typeof val === 'string') {
		Bugsnag.leaveBreadcrumb(`Update font weight text-${element.value.id}: ${val}`);
		updateFontWeight(val);
	}
	updateFontWeight();
};

const updateStyle = () => {
	updateFontStyle();
};

const alignClickManager = () => {
	switch (props.element.textAlign) {
		case 'left': {
			updateTextAlign('center');
			break;
		}
		case 'center': {
			updateTextAlign('right');
			break;
		}
		case 'right': {
			updateTextAlign('justify');
			break;
		}
		case 'justify': {
			updateTextAlign('left');
			break;
		}
	}
};

const startEditing = () => {
	if (!store.textEditing && store.selection.length && store.selectionId[0] === props.element.id) {
		store.textEditingId = props.element.id;

		setCursorPosition();
		Bugsnag.leaveBreadcrumb(`Start editing text-${props.element.id}`);
	}
};

const setCursorPosition = (): void => {
	const temporalSelection = window.getSelection();

	if (!temporalSelection) return;

	const range = document.createRange();
	range.selectNodeContents(store.textEditing?.domNode() as HTMLElement);
	temporalSelection.removeAllRanges();
	temporalSelection.addRange(range);

	selection.value = { text: temporalSelection?.toString(), selection: temporalSelection };
};

const trackFontFamily = (font: string) => {
	updateFontFamily(font);
	GAnalytics.track('click', 'Button', `change-font-family`, null);
};

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.Text) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.Text;
	Bugsnag.leaveBreadcrumb('Open edit text panel');
};
</script>

<template>
	<div data-keep-text-selection class="flex-col items-start lg:flex-row">
		<div class="mb-4 flex w-full items-center gap-2 lg:mb-0 lg:gap-0">
			<!-- Edit Mobile Button -->
			<button
				data-testid="edit-text"
				class="flex h-8 w-8 items-center justify-center rounded bg-gray-600 px-7 text-sm text-white lg:hidden"
				@click="startEditing"
			>
				Edit
			</button>

			<!-- Remove -->
			<RemoveTextToolbar v-if="isMobile" :element="element" />

			<!-- Font Family picker -->
			<FontPicker :font="finalFontFamily" @change="trackFontFamily" />

			<!-- FontSize -->
			<ToolbarFontSize :selected="fontSizeScaled" @update="updateTextFontSize" />
		</div>
		<div class="-mx-2 flex w-screen items-center overflow-auto pl-2 lg:m-0 lg:w-full lg:overflow-visible lg:p-0">
			<!-- Bold -->
			<button
				class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-8 lg:justify-center"
				data-title="bold"
				@click="updateWeight"
			>
				<SvgIcon
					name="bold"
					:class="{
						'text-white': fontWeight.length === 1 && fontWeight[0] === 600,
						'cursor-not-allowed text-gray-100 opacity-50': !boldAvailable,
					}"
					class="h-6 w-6 lg:h-5 lg:w-5"
				/>
				<span :class="{ 'text-white': fontWeight.length === 1 && fontWeight[0] === 600 }" class="text-2xs lg:hidden">
					Bold
				</span>
			</button>

			<!-- Italic -->
			<button
				class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-8 lg:justify-center"
				data-title="italic"
				@click="updateStyle"
			>
				<SvgIcon
					name="italic"
					:class="{
						'text-white': italic.length === 1 && italic[0] === 'italic',
						'cursor-not-allowed text-gray-100 opacity-50': !italicAvailable,
					}"
					class="h-6 w-6 lg:h-5 lg:w-5"
				/>
				<p :class="{ 'text-white': italic.length === 1 && italic[0] === 'italic' }" class="text-2xs lg:hidden">
					Italic
				</p>
			</button>

			<!-- Text align -->
			<button
				:disabled="isCircleText"
				:class="isCircleText ? 'cursor-not-allowed opacity-50' : ''"
				class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-white lg:h-6 lg:w-8 lg:justify-center"
				data-title="alignText"
				@click="alignClickManager()"
			>
				<SvgIcon v-if="element.textAlign === 'left'" name="alignleft" class="h-5 w-5 lg:h-4 lg:w-4" />
				<SvgIcon v-if="element.textAlign === 'center'" name="aligncenter" class="h-5 w-5 lg:h-4 lg:w-4" />
				<SvgIcon v-if="element.textAlign === 'right'" name="alignright" class="h-5 w-5 lg:h-4 lg:w-4" />
				<SvgIcon v-if="element.textAlign === 'justify'" name="alignjustify" class="h-5 w-5 lg:h-4 lg:w-4" />
				<p v-if="element.textAlign === 'left'" class="text-2xs lg:hidden">Left</p>
				<p v-if="element.textAlign === 'center'" class="text-2xs lg:hidden">Center</p>
				<p v-if="element.textAlign === 'right'" class="text-2xs lg:hidden">Right</p>
				<p v-if="element.textAlign === 'justify'" class="text-2xs lg:hidden">Justify</p>
			</button>

			<!-- Text shadow -->
			<TextShadowButtonToolbar v-if="isMobile" :element="element" />
			<TextCurved v-if="isMobile" :element="element" />
			<TextBorderButtonToolbar v-if="isMobile" :element="element" />

			<!-- Color picker -->
			<div
				class="order-first mr-2 border-r border-gray-600 pl-1 pr-4 lg:order-none lg:ml-1 lg:h-5 lg:w-5 lg:border-0 lg:px-0"
			>
				<ColorPicker
					parent=".toolbar"
					class="h-8 w-8 lg:h-5 lg:w-5"
					:color="selectedColor"
					:hide-gradient="true"
					@change="updateColor"
				/>
			</div>

			<hr class="mx-1 hidden h-5 w-px bg-white opacity-25 lg:block" />

			<!-- Order -->
			<OrderToolbar v-if="isMobile" :element="element" />

			<!-- Copy -->
			<CopyToolbar v-if="isMobile" :element="element" />

			<!-- Link -->
			<TextLink :element="element" />

			<TextEffectsButtonToolbar :element="element" />

			<!-- Remove desktop toolbar button -->
			<RemoveToolbar v-if="!isMobile" :element="element" />

			<MoreToolsToolbar :panel="EditPanels.Text" @more-tools="onClickMoreTools" />
		</div>
	</div>
</template>
