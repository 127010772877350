<script setup lang="ts">
// Vue
import { computed } from 'vue';

// Image
import Image from '@/Classes/Image';
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import { useCrop } from '@/composables/element/image/useCrop';
import { useCropPhotoMode } from '@/composables/element/image/useCropPhotoMode';
import { usePhotoMode } from '@/composables/element/image/usePhotoMode';
// Stores
import { useMainStore } from '@/stores/store';
const store = useMainStore();

// Computeds
const image = computed(() => store.cropping as Image);

const label = computed(() => ({
	width: parseInt(image.value.size.width.toString()),
	height: parseInt(image.value.size.height.toString()),
}));

// Using composables
const { photoModeImage } = usePhotoMode();
const { applyCrop, cancelCrop } = image.value.id === photoModeImage.value?.id ? useCropPhotoMode() : useCrop(image);
</script>

<template>
	<div class="crop-toolbar -mt-1 h-16 justify-center px-1 lg:h-8">
		<div class="mr-3 flex items-center justify-center border-r border-gray-600">
			<p class="px-3 text-xs text-gray-300">
				<span class="mr-1 text-gray-100">{{ label.width }}</span
				>x<span class="ml-1 text-gray-100">{{ label.height }}</span>
			</p>
		</div>

		<button
			class="flex h-8 items-center justify-center rounded-full bg-blue-500 px-4 text-sm font-bold text-white hover:bg-blue-600 lg:h-6 lg:bg-green-500 lg:pl-2 lg:pr-4 lg:hover:bg-green-600"
			@click="applyCrop"
		>
			<SvgIcon name="check" class="ml-1 mr-2 h-3 w-3 fill-current" />
			Apply
		</button>

		<button
			class="flex h-8 items-center justify-center px-4 text-sm font-semibold text-white opacity-75 hover:opacity-100 lg:h-6 lg:px-3"
			@click="cancelCrop"
		>
			<SvgIcon name="cross" class="mr-1 h-4 w-4 fill-current" />
			Cancel
		</button>
	</div>
</template>
