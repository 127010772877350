import Bugsnag from '@bugsnag/js';
import { until } from '@vueuse/core';
import { cloneDeep, kebabCase } from 'lodash-es';

import Image from '@/Classes/Image';
import Page from '@/Classes/Page';
import { useAuth } from '@/composables/useAuth';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useUsersnap } from '@/composables/useUsersnap';
import { useHistoryStore } from '@/stores/history';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { DownloadFormat, Unit } from '@/Types/types';
import { UserApiClient } from '@/utils/api';
import ImageTools from '@/utils/ImageTools';

interface DownloadRequest {
	format: DownloadFormat;
	width: number;
	height: number;
	unit: Unit;
	pages: string[];
	userVectorId: string | null;
	vectorId: number;
	content: Page[] | null;
	transparentBackground: boolean;
}

const triggerDownload = (downloadUrl: string, fileName: string) => {
	const link = document.createElement('a');

	link.setAttribute('href', downloadUrl);
	link.setAttribute('download', `${fileName}`);

	document.body.appendChild(link);

	link.click();
	document.body.removeChild(link);
};

const preparePagesForDownload = async (sourcePages: Page[]) => {
	const pages = cloneDeep(sourcePages);

	// buscamos las imagenes que esten como blob y las pasamos a base64
	const promises = pages.flatMap((p) => {
		return p.elements.map(async (e) => {
			if (e instanceof Image && e.url.startsWith('blob:')) {
				e.url = await ImageTools.urlToBase64(e.url);
			}

			if (e instanceof Image && e.urlBackgroundRemoved && e.urlBackgroundRemoved.startsWith('blob:')) {
				e.urlBackgroundRemoved = await ImageTools.urlToBase64(e.urlBackgroundRemoved);
			}

			Promise.resolve();
		});
	});

	await Promise.all(promises);

	return pages;
};

export default function () {
	const store = useMainStore();
	const project = useProjectStore();
	const history = useHistoryStore();
	const { isLogged } = useAuth();
	const { loadUserSnap, showUsersnap } = useUsersnap();
	const { isWebview, webviewVersion } = useDeviceInfo();

	const handleDownloadResponse = async (response: Response, selectedPages: Page[], format: DownloadFormat) => {
		let downloadUrl = '';

		if (parseInt(response.headers.get('content-length') || '') > 500) {
			downloadUrl = window.URL.createObjectURL(await response.blob());
		} else {
			downloadUrl = await response.text();
		}

		const date = new Date();
		const fileName = `wepik-${kebabCase(
			project.name || ''
		)}-${date.getFullYear()}${date.getMonth()}${date.getDate()}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
		const extension = selectedPages.length > 1 && format !== 'pdf' ? 'zip' : format;

		triggerDownload(downloadUrl, `${fileName}.${extension}`);
	};

	const getRequestData = async (format: DownloadFormat, pages: Page[]) => {
		const pagesToDownload = await preparePagesForDownload(pages);
		const transparentBackground = format === DownloadFormat.tpng;

		if (transparentBackground) {
			format = DownloadFormat.png;
		}

		const downloadData: DownloadRequest = {
			content: pagesToDownload,
			format,
			pages: pagesToDownload.map((p) => p.id),
			unit: project.unit,
			vectorId: project.sourceVectorId,
			height: project.size.height,
			width: project.size.width,
			userVectorId: null,
			transparentBackground,
		};

		Bugsnag.leaveBreadcrumb('Download request data', downloadData);

		// Si el estado actual ha venido desde movernos en el historial y aúń no se ha hecho ningún nuevo cambio
		// lanzamos un sync con el estado actual para tener esta nueva versión en el server
		if (history.lastChangeFromNavigation) {
			project.performSyncWithStateInUse();
			await until(() => project.syncing).toBe(false);
		}

		if (store.userVector && isLogged.value) {
			downloadData.userVectorId = store.userVector.uuid;
			downloadData.content = null;
		}

		return downloadData;
	};

	const startDownload = async (format: DownloadFormat, pages: Page[]) => {
		const downloadData = await getRequestData(format, pages);

		// Si estamos en el webview de la app enviamos los datos
		// necesarios para que esta haga la petición de descarga
		if (isWebview.value) {
			// La v2 tiene un formato distinto a la hora de enviar mensajes a la app,
			// ya que ahora no solo se envía el mensaje para descargar, también tenemos
			// el mensaje del sync
			const appMessage =
				webviewVersion.value >= 2
					? {
							action: 'download',
							data: {
								downloadData,
								vectorName: project.name,
							},
					  }
					: {
							downloadData,
							vectorName: project.name,
					  };
			window.ReactNativeWebView.postMessage(JSON.stringify(appMessage));
			store.downloading = false;
			return;
		}

		if (import.meta.env.PROD) {
			loadUserSnap();
		}

		let response;

		try {
			response = await UserApiClient.exportProjectPages(downloadData);

			if (import.meta.env.PROD) {
				showUsersnap();
			}
		} catch (error: any) {
			store.downloading = false;
			throw error;
		}

		if (format === DownloadFormat.tpng) {
			format = DownloadFormat.png;
		}

		await handleDownloadResponse(response, pages, format);
		store.downloading = false;
	};

	const prepareDownloadOrShare = async (format: DownloadFormat, selectedPages: string[] = [], share = false) => {
		// Si no hay página seleccionadas, asumimos todas
		const pages: Page[] =
			selectedPages.length > 0
				? (selectedPages.map((id) => project.pages.find((page) => page.id === id)) as Page[]).filter((item) => !!item)
				: (project.pages as Page[]);

		if (share) {
			store.sharing = true;
		} else {
			store.downloading = true;
			store.downloadingFormat = format !== 'pdf' ? format : store.downloadingFormat;
		}

		await until(() => project.pendingSync).toBe(false, { timeout: 15000, throwOnTimeout: true });
		await until(() => project.syncing).toBe(false, { timeout: 15000, throwOnTimeout: true });

		return pages;
	};

	const download = async (format: DownloadFormat, selectedPages: string[] = []) => {
		const pages = await prepareDownloadOrShare(format, selectedPages);
		Bugsnag.leaveBreadcrumb(`Download template: ${format}, ${pages.length} pages`);

		try {
			await until(() => project.pendingSync).toBe(false, { timeout: 15000, throwOnTimeout: true });
			await until(() => project.syncing).toBe(false, { timeout: 15000, throwOnTimeout: true });
			await startDownload(format, pages);
		} catch (error) {
			store.downloading = false;
			throw new Error(error);
		}
	};

	const share = async (socialNetwork: string, options: object = {}, selectedPages: string[] = []) => {
		const pages = await prepareDownloadOrShare(DownloadFormat.png, selectedPages, true);
		Bugsnag.leaveBreadcrumb(`Share template in ${socialNetwork}: ${pages.length} pages`);

		try {
			switch (socialNetwork) {
				case 'facebook':
					await shareInFacebook(pages, options);
					break;

				case 'instagram':
					await shareInInstagram(pages, options);
					break;

				case 'pinterest':
					await shareInPinterest(pages, options);
					break;

				case 'twitter':
					await shareInTwitter(pages, options);
					break;

				case 'email':
					await shareInEmail(pages, options);
					break;

				default:
					break;
			}
		} catch (error) {
			throw new Error(error);
		}
	};

	const shareInFacebook = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		let response;

		try {
			response = await UserApiClient.sendToSocialNetwork('facebook', {
				...downloadData,
				...options,
			});
		} catch (error: any) {
			Bugsnag.notify(error);
			store.sharing = false;
			throw new Error(error);
		}

		store.sharing = false;
	};

	const shareInInstagram = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		let response;

		try {
			response = await UserApiClient.sendToSocialNetwork('instagram', {
				...downloadData,
				...options,
			});
		} catch (error: any) {
			Bugsnag.notify(error);
			store.sharing = false;
			throw new Error(error);
		}

		store.sharing = false;
	};

	const shareInPinterest = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		let response;

		try {
			response = await UserApiClient.sendToSocialNetwork('pinterest', {
				...downloadData,
				...options,
			});
		} catch (error: any) {
			Bugsnag.notify(error);
			store.sharing = false;
			throw new Error(error);
		}

		store.sharing = false;
	};

	const shareInTwitter = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		let response;

		try {
			response = await UserApiClient.sendToSocialNetwork('twitter', {
				...downloadData,
				...options,
			});
		} catch (error: any) {
			Bugsnag.notify(error);
			store.sharing = false;
			throw new Error(error);
		}

		store.sharing = false;
	};

	const shareInEmail = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.png, pages);

		let response;

		try {
			response = await UserApiClient.sendToEmails({
				...downloadData,
				...options,
			});
		} catch (error: any) {
			Bugsnag.notify(error);
			store.sharing = false;
			throw new Error(error);
		}

		store.sharing = false;
	};

	return {
		download,
		share,
		downloading: store.downloading,
	};
}
