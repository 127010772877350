<script setup lang="ts">
// Vue
import { toRef } from 'vue';

// Classes
import { QRCode } from '@/Classes/QRCode';

// Props
const props = defineProps<{ element: QRCode }>();

// Data
const element = toRef(props, 'element');
</script>

<template>
	<svg :viewBox="element.viewbox" width="100%" height="100%" preserveAspectRatio="none" v-html="element.content"></svg>
</template>
