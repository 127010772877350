import { computed, Ref } from 'vue';

import Element from '@/Classes/Element';
import Image from '@/Classes/Image';
import { useCommon } from '@/composables/element/common/useCommon';
import { useImage } from '@/composables/element/image/useImage';

export const useElementOrchestrator = (element: Ref<Element>) => {
	return computed(() => {
		if (element.value instanceof Image) {
			return useImage(element as Ref<Image>);
		}

		return useCommon(element);
	});
};
