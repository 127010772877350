<script lang="ts" setup>
// Vue
import { computed } from 'vue';

// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Composables
import { useEditorMode } from '@/composables/useEditorMode';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { Panels } from '@/Types/types';
const store = useMainStore();

// Using composables
const { isMobile } = useDeviceInfo();
const { isPhotoMode, isIllustratorContext, mode } = useEditorMode();

// Emits
const emit = defineEmits(['setPanel']);

// Computeds
const panelButtons = computed(() =>
	[
		{
			name: 'Templates',
			panel: Panels.templates,
			icon: 'template',
			hide: ['photo', 'admin'].includes(mode.value),
		},
		{
			name: 'Edit',
			panel: Panels.photoEdit,
			icon: 'edit',
			hide: !isPhotoMode.value,
		},
		{
			name: 'Add Text',
			panel: Panels.texts,
			icon: 'create-text',
		},
		{
			name: 'Add Element',
			panel: Panels.elements,
			icon: 'create-element',
		},
		{
			name: 'Add Photo',
			panel: Panels.photos,
			icon: 'photo',
		},
		{
			name: 'SVG Tree Editor',
			panel: Panels.treeEditor,
			icon: 'tree',
			hide: !isIllustratorContext.value,
		},
	].filter((i) => !i.hide)
);

// Methods
const onClickButton = (panel: Panels) => emit('setPanel', panel);
</script>

<template>
	<template v-if="!isMobile">
		<button
			v-for="button in panelButtons"
			:key="button.name"
			:data-onboarding="`btn-panel-${button.panel}`"
			class="flex flex-col items-center justify-center rounded hover:text-white sm:px-6 lg:h-16 lg:w-16 lg:px-0"
			:class="button.panel === store.activePanel ? 'bg-gray-700 text-white' : 'text-gray-100'"
			@click="onClickButton(button.panel)"
		>
			<SvgIcon :name="button.icon" class="mb-1 h-6 w-6 fill-current lg:h-5 lg:w-5" />
			<span class="text-2xs leading-normal">{{ button.name }}</span>
		</button>
	</template>

	<template v-if="isMobile">
		<button
			v-for="button in isPhotoMode ? panelButtons.slice(1) : panelButtons"
			:key="button.name"
			class="flex flex-col items-center justify-center rounded hover:text-white sm:px-6 lg:h-16 lg:w-16 lg:px-0"
			:class="button.panel === store.activePanel ? 'bg-gray-700 text-white' : 'text-gray-100'"
			@click="onClickButton(button.panel)"
		>
			<SvgIcon :name="button.icon" class="mb-1 h-6 w-6 fill-current lg:h-5 lg:w-5" />
			<span class="text-2xs leading-normal">{{ button.name }}</span>
		</button>
	</template>
</template>
