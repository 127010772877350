<script lang="ts" setup>
import { cloneDeep } from 'lodash-es';
import { computed, InputHTMLAttributes } from 'vue';

import { SolidColor } from '@/Classes/SolidColor';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ color: SolidColor }>();
const emit = defineEmits<{ (e: 'change', color: Color): void }>();
const { isMobile } = useDeviceInfo();
const opacity = computed(() => Math.round(props.color.a * 100));

const updateOpacity = (e: Event) => {
	const color = cloneDeep(props.color);
	color.a = (e.target as InputHTMLAttributes).value / 100;
	emit('change', color);
};
</script>

<template>
	<Teleport :to="isMobile ? '.vc-sketch-sliders' : '.vc-sketch-field'">
		<div class="col-span-6 mt-2 flex items-center lg:flex-col lg:items-start">
			<div class="mr-3 text-2xs font-bold uppercase text-gray-300">Opacity</div>
			<div class="flex flex-1 items-center">
				<div class="input-range relative flex w-full">
					<input
						type="range"
						min="0"
						max="100"
						:value="opacity"
						class="input-range h-2 w-full cursor-pointer appearance-none rounded-full bg-gray-900 focus:outline-none"
						style="box-shadow: 0 0 0 3px #2a3742 inset"
						@input="updateOpacity"
					/>
					<span
						:style="`width: ${opacity}%`"
						class="absolute top-[2.5px] left-0 h-[3px] rounded-full bg-blue-500"
					></span>
				</div>
				<div class="ml-2 w-8 text-xs text-gray-100">{{ opacity }}%</div>
			</div>
		</div>
	</Teleport>
</template>
