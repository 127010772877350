import { DragState } from '@vueuse/gesture';

import Element from '@/Classes/Element';
import Image from '@/Classes/Element';
import { SerializableClass } from '@/Classes/SerializableClass';
import {
	BasicShapeApi,
	FlaticonElementApi,
	ImageApi,
	MaskApi,
	StickersElementApi,
	StorysetApi,
} from '@/Types/apiClient';
import { Color } from '@/Types/colorsTypes';

export type SerializedClass<T> = {
	[P in keyof T]?: T[P] extends SerializableClass ? T[P] | object : T[P];
};

export interface Dictionary<T> {
	[index: string]: T;
}

export interface Template {
	name: string;
	id: string;
	background: Color;
	elements: { [id: string]: Element };
}

export interface UserVector {
	uuid: string;
	project: string;
	preview: string;
}

export interface TemplateCategory {
	id: number;
	name: string;
}

export interface ElementCategory {
	id: string | number;
	name: string;
}

export interface ElementType {
	id: string | number;
	name: string;
	elements: {
		id: string | number;
		svg: string;
		multicolor: boolean;
	}[];
}

export interface Font {
	name: string;
	website: 'squirrel' | 'google' | 'custom';
	weights: string[];
	preview: string;
	recommended: number;
}

export interface Artboard {
	name: string;
	height: number;
	width: number;
	unit: Unit;
}

export interface Size {
	width: number;
	height: number;
}
export interface Position {
	x: number;
	y: number;
}
export interface Flip {
	x: boolean;
	y: boolean;
}

export interface ViewBox {
	x: number;
	y: number;
	width: number;
	height: number;
}

export enum HandlerPosition {
	topLeft = 'top-left',
	topRight = 'top-right',
	bottomLeft = 'bottom-left',
	bottomRight = 'bottom-right',
}

export enum DownloadFormat {
	jpg = 'jpeg',
	png = 'png',
	tpng = 'tpng',
	pdf = 'pdf',
	app = 'app',
}

export enum Anchor {
	right = 'right',
	left = 'left',
	top = 'top',
	bottom = 'bottom',
	centerX = 'center-x',
	centerY = 'center-y',
	topLeft = 'top-left',
	topRight = 'top-right',
	bottomLeft = 'bottom-left',
	bottomRight = 'bottom-right',
	center = 'center',
	topCenter = 'top-center',
	bottomCenter = 'bottom-center',
	leftCenter = 'left-center',
	rightCenter = 'right-center',
}

export enum Alignment {
	Right = 'right',
	left = 'left',
	Center = 'center',
	Justify = 'justify',
}

export interface DragStateHandlerPosition extends DragState {
	positionHandler: HandlerPosition;
}

export type Unit = 'px' | 'mm';

export interface User {
	id: string;
	avatar: string;
	email: string;
	isAdmin: boolean;
	name: string;
	interests: string;
	tokens: UserTokens;
}

export interface UserTokens {
	google: Token;
	dropbox: Token;
	facebook: Token;
	pinterest: Token;
	twitter: Token;
}

export interface TemplateSuggestion {
	element: string;
	message: string;
	fix?: () => void;
}

export interface Token {
	status: boolean;
	token: string | null;
}

export interface DraggableItem {
	type: ElementsPanelTypes | Panels;
	data: BasicShapeApi | FlaticonElementApi | ImageApi | MaskApi | StickersElementApi | StorysetApi;
}

export enum EditPanels {
	Filter = 'Filter',
	Group = 'Group',
	Image = 'Image',
	Line = 'Line',
	Mask = 'Mask',
	QRCode = 'QRCode',
	Shape = 'Shape',
	Storyset = 'Storyset',
	Text = 'Text',
	TextEffects = 'TextEffects',
}

export enum Panels {
	templates = 'templates',
	texts = 'texts',
	elements = 'elements',
	photos = 'photos',
	photoEdit = 'photo-edit',
	treeEditor = 'tree-editor',
}

export enum ElementsPanelTypes {
	Flaticon = 'icons',
	Storysets = 'illustrations',
	Stickers = 'stickers',
	BasicShapes = 'basic-shapes',
	ImageMasks = 'image-masks',
	LinesAndArrows = 'lines-and-arrows',
	QRCodes = 'qr-codes',
}

export enum StorysetsStyles {
	Amico = 'amico',
	Bro = 'bro',
	Cuate = 'cuate',
	Pana = 'pana',
	Rafiki = 'rafiki',
}

export type ValidZoomTypes =
	| 10
	| 20
	| 30
	| 40
	| 50
	| 60
	| 70
	| 80
	| 90
	| 100
	| 125
	| 150
	| 175
	| 200
	| 250
	| 300
	| 350
	| 400
	| 450
	| 500
	| 750;

export type InteractionAction = 'drag' | 'resize' | 'rotate' | 'select' | 'idle';

export interface Crop {
	size: Size;
	position: Position;
}

export type Linecap = 'butt' | 'round';

export interface Marker {
	element: string;
	color: Color;
}

export interface ImageHover {
	target: Image;
	data: ImageApi;
}

export interface CurvedProperties {
	arc: number | null;
	minArc: number;
	transformCurve: number;
}

export interface TextShadowProperties {
	angle: number;
	blur: number;
	color: Color;
	distance: number;
	opacity: number;
	unit?: string;
}

export interface TextOutlineProperties {
	color: Color;
	width: number;
	unit?: string;
}

export enum TextEffects {
	None = 'None',
	Outline = 'Outline',
	Curve = 'Curve',
	Shadow = 'Shadow',
	Echo = 'Echo',
	Neon = 'Neon',
	Glitch = 'Glitch',
	Sticker = 'Sticker',
}
