<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { computed } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';

const props = defineProps<{ modelValue: string | null; color: string | null | undefined }>();
const emit = defineEmits<{ (e: 'update:modelValue', value: string | null): void }>();

const options = computed(() => ({
	outline: { value: 'Outline', disable: false },
	fill: { value: 'Filled', disable: false },
	'lineal-color': { value: 'Lineal Color', disable: ['black'].includes(props.color) },
	'hand-drawn': { value: 'Hand Drawn', disable: ['gradient'].includes(props.color) },
}));
const reset = (e) => {
	e.stopPropagation();
	emit('update:modelValue', null);
};
</script>

<template>
	<Menu>
		<MenuButton
			data-testid="filter-style-btn"
			class="flex h-7 items-center justify-center gap-1 rounded-full bg-gray-700 px-3 text-xs text-gray-100 hover:text-white"
		>
			<template v-if="modelValue">
				<SvgIcon :name="`flaticon-${modelValue}`" class="mr-1 h-3 w-3"></SvgIcon>
				<span class="whitespace-nowrap">{{ options[modelValue].value }}</span>
				<button
					data-testid="reset-filter-style-btn"
					class="flex h-4 w-4 items-center justify-center rounded-sm hover:bg-gray-800"
					@click="reset"
				>
					<SvgIcon name="cross" class="h-3 w-3" />
				</button>
			</template>
			<span v-else class="ml-2">All Shapes</span>
			<SvgIcon name="arrow" class="h-4 w-4" />
		</MenuButton>
		<MenuItems
			class="absolute left-0 top-10 z-20 w-auto origin-top-right rounded-md bg-white py-1 shadow focus:outline-none"
		>
			<MenuItem v-for="(option, key) in options" v-slot="{ active }" :key="key" :disabled="option.disable">
				<button
					:data-testid="`flaticon-filter-${key}-btn`"
					class="flex w-full items-center gap-2 whitespace-nowrap px-4 py-1 text-left text-sm hover:bg-gray-100/25 hover:text-gray-800"
					:class="[
						active ? 'bg-gray-100/25 text-gray-800' : 'text-gray-600',
						option.disable ? 'pointer-events-none opacity-25' : '',
					]"
					@click="emit('update:modelValue', key)"
				>
					<SvgIcon :name="`flaticon-${key}`" class="h-4 w-4" />
					{{ option.value }}
				</button>
			</MenuItem>
		</MenuItems>
	</Menu>
</template>

<style scoped></style>
