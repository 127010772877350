import { computed, Ref } from 'vue';

import Element from '@/Classes/Element';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useMainStore } from '@/stores/store';

export const useGroup = (element: Ref<Element>) => {
	const store = useMainStore();

	const usingElementOrchestrator = useElementOrchestrator(element);
	const { page } = usingElementOrchestrator.value;

	const lockedGroup = computed(() => {
		return page.value?.elements.filter((el) => el.group && el.group === element.value.group) || [];
	});
	const isLockGroup = computed(() => lockedGroup.value.length && store.selection.every((el) => el.group));
	const isGrouped = computed(() => group.value.length > 1);
	const group = computed(() => (store.selectionId.includes(element.value.id) ? store.selection : lockedGroup.value));

	const setOpacity = (opacity: number) => group.value.forEach((el) => el.setOpacity(opacity));

	return {
		group,
		isGrouped,
		lockedGroup,
		isLockGroup,
		setOpacity,
	};
};
