// Vue
import { computed, Ref, ref, watch } from 'vue';

// Classes
import Element from '@/Classes/Element';
import Page from '@/Classes/Page';
// Composables
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { useArtboard } from '@/composables/project/useArtboard';
import { useProjectStore } from '@/stores/project';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { Anchor, Position } from '@/Types/types';

export const useCommon = (element: Ref<Element>) => {
	const { selection } = useMainStore();

	const project = useProjectStore();
	const { artboardSizeInPx: sizeInPx } = useArtboard();

	const { adjustTo, align, move, fitElementRegardingFactor } = useElementTransformOrchestrator(element).value;

	const page = computed(
		() => project.pages.find((page) => page.elements.find((el) => el.id === element.value.id)) as Page
	);

	const toggleLocked = () => {
		element.value.locked = !element.value.locked;
	};

	const removeFromGroup = () => {
		// If group has only 2 elements or less delete all group
		if (selection.filter((element) => element.group).length <= 2) {
			selection.forEach((element) => (element.group = null));
		} else {
			element.value.group = null;
		}
	};

	const setupInPage = (mousePosition?: Position) => {
		adjustTo('width', sizeInPx.value.width / 4);

		if (mousePosition) {
			move(mousePosition.x - element.value.size.width / 2, mousePosition.y - element.value.size.height / 2, false);
		} else {
			align(Anchor.center);
		}
	};

	const adjustToNewArtboard = (zeroPosition: Position, centeredPosition: Position, scale: number) => {
		// Para que al calcular la posición del contenido sea 0
		move(-zeroPosition.x, -zeroPosition.y);

		// Aplicamos la escala del canva al elemento
		fitElementRegardingFactor(scale);

		// Centramos el contenido respecto al canva
		move(centeredPosition.x, centeredPosition.y);
	};

	return {
		page,
		toggleLocked,
		removeFromGroup,
		setupInPage,
		adjustToNewArtboard,
	};
};
