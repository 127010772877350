import { useFetch, UseFetchOptions, UseFetchReturn } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { useEditorApiFetch } from '@/composables/api/useEditorApiFetch';
import { useEditorWebFetch } from '@/composables/api/useEditorWebFetch';
import { SaveProjectResponse, ServicesProviders, SyncProjectResponse, UserApi } from '@/Types/apiClient';

export const saveProject = (body: BodyInit | any): UseFetchReturn<SaveProjectResponse> => {
	return useEditorApiFetch('user/vector', {
		headers: {
			Accept: 'application/json',
		},
	})
		.post({ ...body })
		.json();
};

export const syncProject = (body: BodyInit | any): UseFetchReturn<SyncProjectResponse[]> => {
	return useEditorApiFetch(`user/vector/sync`, {
		headers: {
			Accept: 'application/json',
		},
	})
		.post({ ...body })
		.json();
};

export const saveInterests = (
	body: BodyInit | any,
	options: UseFetchOptions = {}
): UseFetchReturn<SyncProjectResponse[]> => {
	return useEditorApiFetch(`user/interests`, options)
		.post({ ...body })
		.json();
};

export const checkProvider = (provider: ServicesProviders, options: UseFetchOptions = {}): UseFetchReturn<any> => {
	return useEditorApiFetch(`provider/check?provider=${provider}`, { immediate: false, ...options })
		.get()
		.json();
};

export const getUserUploads = (data: Ref = ref(), options: UseFetchOptions = {}) => {
	const url = ref('user-uploads');

	// Si estamos haciendo paginación/infinite loading vamos a estar cambiando la url por la que nos da el data
	// esta contiene el path completo nosotros solo queremos la parte del endpoint por lo que si es la url completa
	// le quitamos la parte de la url que no nos interesa
	const endpoint = computed(() => url.value.toString().replace(import.meta.env.VITE_APP_API_PATH, ''));

	const fetch = useEditorApiFetch(endpoint, {
		...options,
		afterFetch: (ctx) => {
			data.value = [...data.value, ...ctx.data.data];

			ctx.data = {
				...ctx.data,
				data: data.value,
			};

			return ctx;
		},
	})
		.get()
		.json();

	return { ...fetch, url };
};

export const getUserGooglePhotos = (page: Ref<string>, options: UseFetchOptions = {}) => {
	const data = ref([]);
	const url = computed(() => {
		return `images/photos?page=${page.value}`;
	});

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => {
			data.value = [...data.value, ...ctx.data.images] as any;

			ctx.data = {
				data: data.value,
				links: {
					next: ctx.data.nextPageId,
				},
			};

			return ctx;
		},
	})
		.get()
		.json();
};

// TODO
export const exportProjectPages = (body: BodyInit | any, options: UseFetchOptions = {}) => {
	return useFetch(`${import.meta.env.VITE_APP_API_PATH}vector/export`, options).post({ ...body });
};

export const getCsrfCookie = async (options: UseFetchOptions = {}): Promise<UseFetchReturn<void>> => {
	return useEditorWebFetch('sanctum/csrf-cookie', options);
};

export const getUserData = async (): Promise<UseFetchReturn<UserApi>> => {
	return useEditorApiFetch('auth/verify', {
		headers: {
			Accept: 'application/json',
		},
	}).json();
};

export const logout = (options: UseFetchOptions = {}): UseFetchReturn<void> => {
	return useEditorWebFetch('auth/logout', options).post() as UseFetchReturn<void>;
};
