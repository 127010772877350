<script lang="ts" setup>
import { computed, toRef } from 'vue';

import EditFilter from '@/components/selection/edit/EditFilter.vue';
import EditGroup from '@/components/selection/edit/EditGroup.vue';
import EditImage from '@/components/selection/edit/EditImage.vue';
import EditLine from '@/components/selection/edit/EditLine.vue';
import EditMask from '@/components/selection/edit/EditMask.vue';
import EditQRCode from '@/components/selection/edit/EditQRCode.vue';
import EditShape from '@/components/selection/edit/EditShape.vue';
import EditStoryset from '@/components/selection/edit/EditStoryset.vue';
import EditText from '@/components/selection/edit/EditText.vue';
import EditTextEffects from '@/components/selection/edit/EditTextEffects.vue';
import CropToolbar from '@/components/selection/toolbars/CropToolbar.vue';
import ImageToolbar from '@/components/selection/toolbars/images/ImageToolbar.vue';
import LineToolbar from '@/components/selection/toolbars/LineToolbar.vue';
import QRCodeToolbar from '@/components/selection/toolbars/QRCodeToolbar.vue';
import ShapeToolbar from '@/components/selection/toolbars/ShapeToolbar.vue';
import StorysetToolbar from '@/components/selection/toolbars/StorysetToolbar.vue';
import TextToolbar from '@/components/selection/toolbars/TextToolbar.vue';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();
const { isKeyboardOpen, isMobile } = useDeviceInfo();
const { isCropping } = useInteractions();

const activePage = toRef(store, 'activePage');
const editPanel = toRef(store, 'editPanel');

const mainComponent = computed(() => {
	if (!editPanel.value) return null;

	if (isMobile.value) {
		if (isCropping.value) return CropToolbar;
		if ([EditPanels.Filter, EditPanels.Image, EditPanels.Mask].includes(editPanel.value)) return ImageToolbar;
		if (editPanel.value === EditPanels.Line) return LineToolbar;
		if (editPanel.value === EditPanels.QRCode) return QRCodeToolbar;
		if (editPanel.value === EditPanels.Shape) return ShapeToolbar;
		if (editPanel.value === EditPanels.Storyset) return StorysetToolbar;
		if ([EditPanels.Text, EditPanels.TextEffects].includes(editPanel.value)) return TextToolbar;
		return null;
	}

	if (editPanel.value === EditPanels.Filter) return EditFilter;
	if (editPanel.value === EditPanels.Group) return EditGroup;
	if (editPanel.value === EditPanels.Image) return EditImage;
	if (editPanel.value === EditPanels.Line) return EditLine;
	if (editPanel.value === EditPanels.Mask) return EditMask;
	if (editPanel.value === EditPanels.QRCode) return EditQRCode;
	if (editPanel.value === EditPanels.Shape) return EditShape;
	if (editPanel.value === EditPanels.Storyset) return EditStoryset;
	if (editPanel.value === EditPanels.Text) return EditText;
	if (editPanel.value === EditPanels.TextEffects) return EditTextEffects;
	return null;
});

const secondaryComponent = computed(() => {
	if (!isMobile.value) return null;
	if (editPanel.value === EditPanels.Filter) return EditFilter;
	if (editPanel.value === EditPanels.Mask) return EditMask;
	if (editPanel.value === EditPanels.TextEffects) return EditTextEffects;
	return null;
});

const selectionCanvas = computed(() => (activePage.value ? store.getSelectionFromCanvas(activePage.value) : []));

const visibility = computed(() => !!mainComponent.value && !!selectionCanvas.value.length);
</script>

<template>
	<div v-if="visibility">
		<div
			v-if="isMobile"
			class="toolbar fixed bottom-0 left-0 z-40 flex w-full flex-col rounded-tl-xl rounded-tr-xl bg-gray-700/90 backdrop-blur transition-transform duration-300"
			:class="{
				'translate-y-32': isKeyboardOpen,
				'pointer-events-none opacity-0': !visibility,
			}"
		>
			<component :is="secondaryComponent" :element="selectionCanvas[0]" />
			<component
				:is="mainComponent"
				class="flex items-center overflow-auto px-2 pt-4 pb-3"
				:element="selectionCanvas[0]"
			/>
		</div>

		<div
			v-else
			id="edit-panel"
			class="fixed right-0 z-20 h-[calc(100vh-56px)] w-[320px] overflow-auto bg-gray-800/95 p-4 text-gray-800 backdrop-blur scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
		>
			<component :is="EditGroup" v-if="editPanel === EditPanels.Group" :elements="selectionCanvas" />
			<component :is="mainComponent" v-else :element="selectionCanvas[0]" />
		</div>
	</div>
</template>
