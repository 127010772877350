<script setup lang="ts">
// Vue
import { ref } from 'vue';

import { getMasks } from '@/api/DataApiClient';
import { getSvg } from '@/api/DataApiClient';
// Classes
import Line from '@/Classes/Line';
import ActivableItem from '@/components/Common/ActivableItem.vue';
import InfiniteLoading from '@/components/Common/InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
// Store
import { useMainStore } from '@/stores/store';
import { ElementsPanelTypes } from '@/Types/types';

const store = useMainStore();
const temporalRef = ref(Line.createDefault());
const usingElementOrchestrator = useElementOrchestrator(temporalRef);
const { addElement } = useActivePage();
const url = ref('elements/category/2328?limit=20&page=1');
const emit = defineEmits(['elementAdded']);

// Methods
const addLine = async (url: string) => {
	const { data } = await getSvg(url);

	if (!data.value || !store.activePage) {
		return;
	}

	const line = Line.fromSvg(data.value);

	addElement(line);
	temporalRef.value = line;
	usingElementOrchestrator.value.setupInPage();

	store.setSelection(line, false);
	emit('elementAdded');
};
</script>

<template>
	<div data-testid="add-elements-panel" class="flex h-full flex-col">
		<button
			class="mb-2 w-full cursor-pointer rounded bg-gray-700 p-2 text-center text-lg text-gray-100 hover:bg-gray-600 hover:text-white focus:outline-none"
			@click="addLine('https://content.wepik.com/statics/4755265/Linea_Mesa-de-trabajo-1.svg')"
		>
			Test without sticky
		</button>

		<button
			class="w-full cursor-pointer rounded bg-gray-700 p-2 text-center text-lg text-gray-100 hover:bg-gray-600 hover:text-white focus:outline-none"
			@click="addLine('https://content.wepik.com/statics/4754074/Flecha10-subirestademomento-01.svg')"
		>
			Test with sticky
		</button>

		<InfiniteLoading
			data-testid="container-items"
			:source="url"
			:fetch="getMasks"
			container-classes="grid grid-cols-5 gap-6"
		>
			<template #item="{ item }">
				<InsertableElement
					:data="item"
					:type="ElementsPanelTypes.LinesAndArrows"
					:draggable="true"
					class="flex"
					@click="addLine(item.svg)"
				>
					<ActivableItem :active="item.active">
						<img v-if="item" :src="item.svg" :alt="item.name" class="h-10 w-10 object-contain" />
					</ActivableItem>
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
