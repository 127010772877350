<script setup lang="ts">
// Classes
// Composables
import { toRef } from 'vue';

import Element from '@/Classes/Element';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { useGroup } from '@/composables/group/useGroup';
import { useGroupTransform } from '@/composables/group/useGroupTransform';
// Types
import { Anchor } from '@/Types/types';

// Props
const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');

const { isGrouped, group } = useGroup(element);

const { align, isAlignLeft, isAlignCenter, isAlignRight, isAlignTop, isAlignBottom, isAlignMiddle } = isGrouped.value
	? useGroupTransform(group)
	: useElementTransformOrchestrator(element).value;
</script>

<template>
	<div>
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Position</h4>

		<div data-testid="position" class="mb-6 flex justify-between gap-2">
			<div class="flex flex-1">
				<div class="flex flex-1 flex-col items-center">
					<button
						class="flex h-8 w-full items-center justify-center rounded-tl rounded-bl bg-gray-700 px-3 text-gray-100"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignLeft,
							'hover:bg-gray-600 hover:text-white': !isAlignLeft,
						}"
						:disabled="element.locked || isAlignLeft"
						@click="align(Anchor.left)"
					>
						<SvgIcon name="left" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100">Left</label>
				</div>

				<div class="mx-px flex flex-1 flex-col items-center">
					<button
						class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignCenter,
							'hover:bg-gray-600 hover:text-white': !isAlignCenter,
						}"
						:disabled="element.locked || isAlignCenter"
						@click="align(Anchor.centerX)"
					>
						<SvgIcon name="center" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100">Center</label>
				</div>

				<div class="flex flex-1 flex-col items-center">
					<button
						class="flex h-8 w-full items-center justify-center rounded-tr rounded-br bg-gray-700 px-3 text-gray-100"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignRight,
							'hover:bg-gray-600 hover:text-white': !isAlignRight,
						}"
						:disabled="element.locked || isAlignRight"
						@click="align(Anchor.right)"
					>
						<SvgIcon name="right" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100">Right</label>
				</div>
			</div>

			<div class="flex flex-1">
				<div class="flex flex-1 flex-col items-center">
					<button
						class="flex h-8 w-full items-center justify-center rounded-tl rounded-bl bg-gray-700 px-3 text-gray-100"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignTop,
							'hover:bg-gray-600 hover:text-white': !isAlignTop,
						}"
						:disabled="element.locked || isAlignTop"
						@click="align(Anchor.top)"
					>
						<SvgIcon name="top" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100">Top</label>
				</div>

				<div class="mx-px flex flex-1 flex-col items-center">
					<button
						class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignMiddle,
							'hover:bg-gray-600 hover:text-white': !isAlignMiddle,
						}"
						:disabled="element.locked || isAlignMiddle"
						@click="align(Anchor.centerY)"
					>
						<SvgIcon name="middle" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100">Middle</label>
				</div>

				<div class="flex flex-1 flex-col items-center">
					<button
						class="flex h-8 w-full items-center justify-center rounded-tr rounded-br bg-gray-700 px-3 text-gray-100"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignBottom,
							'hover:bg-gray-600 hover:text-white': !isAlignBottom,
						}"
						:disabled="element.locked || isAlignBottom"
						@click="align(Anchor.bottom)"
					>
						<SvgIcon name="bottom" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100">Bottom</label>
				</div>
			</div>
		</div>
	</div>
</template>
