<script lang="ts" setup>
import {
	Combobox,
	ComboboxButton,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
	TransitionRoot,
} from '@headlessui/vue';
import { useDebounceFn } from '@vueuse/shared';
import { nextTick, ref } from 'vue';

import { getAutocompletedWords } from '@/api/DataApiClient';
import SvgIcon from '@/components/Common/SvgIcon.vue';

interface CollectionElementInterface {
	id: number;
	name: string;
}

const props = defineProps<{ placeholder?: string; query: string; autocompleteSource: string; modal?: boolean }>();
const emits = defineEmits(['change']);

const selectedElement = ref<string | CollectionElementInterface>('');
const collection = ref<string[] | CollectionElementInterface[]>([]);
const input = ref(props.query);

// Obtenemos la collección
const getCollection = async () => {
	const { data: words } = await getAutocompletedWords(props.autocompleteSource);

	collection.value = words.value;
};

// Actualizamos el input
const updateInput = useDebounceFn(async (e) => {
	const target = e.target;

	selectedElement.value = '';
	input.value = target.value;

	if (target) {
		emits('change', target?.value);

		// Si hemos escrito al menos dos carácteres obtenemos la nueva colección
		if (target?.value.length > 2) {
			await nextTick();
			await getCollection();
		} else {
			collection.value = [];
		}
	}
}, 500);

// Clickamos en un elemento de la lista
const onClick = (e) => {
	const parentLi = e.target.closest('[id^="headlessui-combobox-option"]');

	if (parentLi) {
		const elementId = parentLi.getAttribute('data-element-id');
		const elementName = parentLi.getAttribute('data-element-name');

		selectedElement.value =
			elementId && elementName
				? {
						id: parseInt(elementId),
						name: elementName,
				  }
				: elementName;

		emits('change', selectedElement.value);
	}
};

const preventEnter = async () => {
	const liList = document.querySelectorAll('li[id^="headlessui-combobox-option-"]');
	const validLi = Array.from(liList).filter((li) => li.children.length > 1)[0];

	if (validLi) {
		const elementId = validLi.getAttribute('data-element-id');
		const elementName = validLi.getAttribute('data-element-name');

		if (elementId && elementName) {
			selectedElement.value = {
				id: parseInt(elementId),
				name: elementName,
			};

			emits('change', selectedElement.value);
		}
	}
};
</script>
<template>
	<div data-testid="autocomplete-container" class="z-20 mb-2 w-full">
		<Combobox v-model="input">
			<div
				class="relative w-full cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
			>
				<ComboboxInput
					class="flex h-10 w-full appearance-none rounded-full border border-transparent pl-4 pr-10 text-sm shadow-none focus:outline-none lg:rounded"
					:class="
						modal
							? 'bg-white text-gray-800 placeholder-gray-600/50 focus:border-gray-100'
							: 'bg-gray-900 text-gray-100 placeholder-gray-300/50 focus:border-gray-700'
					"
					:placeholder="placeholder || 'Search something...'"
					@change="updateInput"
				/>
				<ComboboxButton class="absolute inset-y-0 right-0 flex w-10 items-center justify-center">
					<SvgIcon name="search" class="h-4 w-4 fill-current text-gray-300" />
				</ComboboxButton>
			</div>
			<TransitionRoot
				data-testid="related-results-container"
				leave="transition ease-in duration-100"
				leave-from="opacity-100"
				leave-to="opacity-0"
				@after-leave="preventEnter"
			>
				<ComboboxOptions
					v-if="collection.length > 0"
					class="absolute left-4 right-4 mt-1 max-h-60 overflow-auto rounded-md py-2 text-base shadow-lg ring-1 ring-opacity-5 backdrop-blur focus:outline-none sm:text-sm"
					:class="modal ? 'bg-white/95 ring-white' : 'bg-gray-900/95 ring-black'"
				>
					<ComboboxOption
						v-for="(element, index) in collection"
						:key="`${element}-${index}`"
						v-slot="{ selected, active }"
						as="template"
						:value="element"
					>
						<li
							class="relative mx-2 flex cursor-pointer select-none rounded py-2 px-4"
							:class="
								modal
									? {
											'bg-gray-100/25 font-semibold text-gray-800': active,
											'text-gray-800/50': !active,
									  }
									: {
											'bg-gray-800/75 font-semibold text-white': active,
											'text-gray-100': !active,
									  }
							"
							:data-element-id="element.id ? element.id : null"
							:data-element-name="element.id ? element.name : element"
							@click="onClick"
						>
							<span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
								{{ element.id ? element.name : element }}
							</span>
							<span v-if="selected" class="-order-1 mr-2 flex items-center text-green-500">
								<SvgIcon data-test-id="check-export-modal" name="check" class="h-3 w-3 fill-current" />
							</span>
						</li>
					</ComboboxOption>
				</ComboboxOptions>
			</TransitionRoot>
		</Combobox>
	</div>
</template>
