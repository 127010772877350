<script lang="ts" setup>
import '@he-tree/vue3/dist/he-tree-vue3.css';
import { nextTick, onMounted, ref, watch } from 'vue';
import { Draggable, Node } from '@he-tree/vue3';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import PanelHeader from '@/components/panels/PanelHeader.vue';
import { useMainStore } from '@/stores/store';
import { useProjectStore } from '@/stores/project';
import { Illustrator } from '@/Classes/Illustrator';
import { Dom, Element } from '@svgdotjs/svg.js';
import { useIllustrator } from '@/composables/element/illustrator/useIllustrator';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
import { useHistoryStore } from '@/stores/history';

const history = useHistoryStore();
const store = useMainStore();
const project = useProjectStore();
const illustratorElement = ref(project.pages[0].elements[0] as Illustrator);
const { moveTexts, moveShapes } = useIllustrator(illustratorElement);
const { listen } = useOrderedKeyboardListener();
const container = ref();
const tree = ref();
const treeData = ref([]);

const onGoBack = () => {
	store.activeTemplateCategory = null;
};

const walkDOM = (el: SVGElement) => {
	const loop = (main: SVGElement) => {
		const childrens: any = [];

		do {
			const elem = {
				$droppable: main.nodeName === 'g',
				$draggable: main.nodeName !== 'g',
				text: main.nodeName,
				children: [],
				domId: main.nodeName === 'g' ? main.dataset?.groupLink : main.dataset?.illustratorLink,
				nodeName: main.nodeName,
			};
			const isText = main.nodeName === 'text';
			const isDefs = main.nodeName === 'defs';
			const isImage = main.nodeName === 'image';
			const isPlainText = main.nodeName === '#text';

			if (main.id && main.nodeName !== 'g') {
				elem.text += ` - #${main.id}`;
			}

			if (main.hasChildNodes() && !isText && !isPlainText && !isImage) {
				elem.children = loop(main.firstChild as SVGElement);
			}

			if (isText) {
				elem.text = `Text - ${(main.firstElementChild as HTMLElement).textContent}`;
			}

			if (!isDefs && !isPlainText) {
				childrens.push(elem);
			}
		} while ((main = main.nextSibling as SVGElement));

		return childrens;
	};

	return loop(el);
};

const clearTreeSelection = () => {
	store.illustratorSelection.clear();
};

const moveElements = () => {
	const svg = illustratorElement.value.contentSvg;

	const textsId: string[] = [];
	const shapesId: string[] = [];

	// Categorizamos los elementos, ya que los textos y los shapes se crean de forma diferente,
	// los shapes se unificarán, mientras que los textos son individuales
	Array.from(store.illustratorSelection).forEach((id) => {
		const type = (svg.findOne(`[data-illustrator-link="${id}"]`) as Dom).type;

		switch (type) {
			case 'text': {
				textsId.push(id);
				break;
			}
			default: {
				shapesId.push(id);
				break;
			}
		}
	});

	moveTexts(textsId);
	moveShapes(shapesId);

	clearTreeSelection();
};

const removeElements = () => {
	const svg = illustratorElement.value.contentSvg;
	const elements = Array.from(store.illustratorSelection)
		.map((id) => `[data-illustrator-link="${id}"]`)
		.join(', ');

	// Eliminamos los elementos seleccionados y los grupos vacíos que han podido quedar
	svg.find(elements).forEach((el) => el.remove());
	svg.find('g').forEach((el) => {
		if (!el.node.childElementCount) {
			el.remove();
		}
	});

	illustratorElement.value.content = svg.node.innerHTML.toString();

	clearTreeSelection();
};

const drawTree = () => {
	const content =
		illustratorElement.value.contentSvg.findOne('#illustrator-container') || illustratorElement.value.contentSvg;
	treeData.value = walkDOM(content.node as SVGElement);
};

onMounted(async () => {
	container.value.style.height = `${container.value.getBoundingClientRect().height}px`;
	container.value.classList.remove('h-full');

	drawTree();

	await nextTick();

	applySelection(store.illustratorSelection);
});

watch(store.illustratorSelection, (newValue) => applySelection(newValue));
watch(store.illustratorElementsMoved, () => applySelection(store.illustratorSelection));
watch(illustratorElement.value, () => drawTree());
watch(history, () => {
	clearTreeSelection();

	const currentElementsMoved = Array.from(store.illustratorElementsMoved);
	store.illustratorElementsMoved.clear();

	project.pages[1].elements
		.flatMap((el) => el.metadata.illustratorLinks)
		.forEach((id) => store.illustratorElementsMoved.add(id));

	const newElementsMoved = Array.from(store.illustratorElementsMoved);
	const allElementsMovedInCurrentList = newElementsMoved.every((id) => currentElementsMoved.includes(id));

	if (currentElementsMoved.length === newElementsMoved.length && allElementsMovedInCurrentList) {
		return;
	}

	drawTree();
});

const applySelection = (selection: Set<string>) => {
	// Eliminamos los nodos que se han movido a la página final
	tree.value.nodes
		.filter((node: Node) => node.domId && store.illustratorElementsMoved.has(node.domId as string))
		.forEach((node: Node) => tree.value.removeNode(node));

	const checkedNodes = tree.value.getAllCheckedNodes();
	const selectionNodes = Array.from(selection).map((id) => tree.value.nodes.find((node: Node) => node.domId === id));

	// Nodos a los que quitaremos la selección, son los que tienen selección
	// en el árbol pero no aparecen en la selección de la store
	const unselect = checkedNodes.filter(
		(oldNode: Node) => !selectionNodes.find((node: Node) => node.domId === oldNode.domId)
	);

	// Nodos que seleccionaremos
	const select = selectionNodes.filter(
		(newNode: Node) => !checkedNodes.find((node: Node) => node.domId === newNode.domId)
	);

	[...unselect, ...select].forEach((node: Node) => (node.$checked = !node.$checked));

	// Marcamos los grupos si es necesario
	tree.value.nodes
		.filter((node: Node) => node.text === 'g')
		.sort((a: Node, b: Node) => b.$level - a.$level)
		.forEach((node: Node) => {
			const allChecked = node.$children.every((node: Node) => node.$checked);
			node.$checked = allChecked;
		});
};

const updateChecked = (tree: any, node: Node) => {
	tree.updateChecked(node);

	// Marcamos los grupos si es necesario
	tree.nodes
		.filter((treeNode: Node) => treeNode.text === 'g' && node.$id !== node.$id)
		.sort((a: Node, b: Node) => b.$level - a.$level)
		.forEach((treeNode: Node) => {
			const allChecked = treeNode.$children.every((childNode: Node) => childNode.$checked);
			treeNode.$checked = allChecked;
		});

	tree.nodes
		.filter((treeNode: Node) => treeNode.text !== 'g')
		.forEach((treeNode: Node) => {
			store.illustratorSelection[treeNode.$checked ? 'add' : 'delete'](treeNode.domId as string);
		});
};

const addHover = (id: string) => {
	document.querySelector(`[data-illustrator-link="${id}"]`)?.classList.add('tree-element-selection');
};

const removeHover = (id: string) => {
	document.querySelector(`[data-illustrator-link="${id}"]`)?.classList.remove('tree-element-selection');
};

const dragEnd = (ev) => {
	const svg = illustratorElement.value.contentSvg;

	const prevNode = ev.placeholderPrevNode;
	const currentNode = ev.draggingNode;

	const currentElement = svg.findOne(`[data-illustrator-link="${currentNode.domId}"]`) as Element;

	// Si el placeholder del drag and drop es un g y está desplegado lo movemos dentro de este
	// Si no e sun g o está sin desplegar lo movemos para que esté tras el placeholder
	if (prevNode.nodeName === 'g' && !prevNode.$folded) {
		const parentEl = svg.findOne(`[data-group-link="${prevNode.domId}"]`) as Element;
		currentElement.addTo(parentEl).back();
	} else {
		const dataName = prevNode.nodeName === 'g' ? 'data-group-link' : 'data-illustrator-link';
		const prevEl = svg.findOne(`[${dataName}="${prevNode.domId}"]`) as Element;
		prevEl.before(currentElement);
	}

	illustratorElement.value.content = svg.node.innerHTML.toString();
};

listen('Enter', () => {
	if (project.pages.length !== 2) return;

	moveElements();
});

listen('Delete', () => {
	if (project.pages.length !== 2) return;

	removeElements();
});
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader title="SVG Tree Editor" @goBack="onGoBack" />

		<div class="mb-4 grid grid-cols-4">
			<button
				tooltip="Clear selection"
				tooltip-position="bottom"
				class="tooltip tooltip-bottom-left mr-2 inline-block h-10 rounded bg-gray-700 py-2 text-sm font-semibold text-gray-200 hover:bg-gray-600"
				@click="clearTreeSelection"
			>
				<SvgIcon name="clean" class="mx-auto h-5 w-5 fill-current" />
			</button>

			<button
				tooltip="Move elements"
				tooltip-position="bottom"
				class="tooltip mr-2 inline-block h-10 rounded bg-gray-700 py-2 text-sm font-semibold text-gray-200 hover:bg-gray-600"
				@click="moveElements"
			>
				<SvgIcon name="blank" class="mx-auto h-5 w-5 fill-current" />
			</button>

			<button
				tooltip="Remove elements"
				tooltip-position="bottom"
				class="tooltip inline-block h-10 rounded bg-gray-700 py-2 text-sm font-semibold text-gray-200 hover:bg-gray-600"
				@click="removeElements"
			>
				<SvgIcon name="trash" class="mx-auto h-5 w-5 fill-current" />
			</button>
		</div>

		<div
			class="h-full overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
			ref="container"
		>
			<Draggable ref="tree" :treeData="treeData" @drop="dragEnd" :rootDraggable="false" :rootDroppable="false">
				<template v-slot="{ node, index, tree }">
					<div class="mb-1 flex items-center">
						<button
							v-if="node.$children.length"
							@click="node.$folded = !node.$folded"
							class="mr-2 rounded-sm bg-black bg-opacity-60 px-2 font-bold"
						>
							{{ node.$folded ? '>' : 'v' }}
						</button>
						<input
							class="mr-2 h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
							:class="{
								'ml-8': !node.$children.length,
							}"
							type="checkbox"
							v-model="node.$checked"
							@change="() => updateChecked(tree, node)"
						/>
						<span @mouseenter="() => addHover(node.domId)" @mouseleave="() => removeHover(node.domId)">
							{{ node.text }}
						</span>
					</div>
				</template>
			</Draggable>
		</div>
	</div>
</template>
