<script lang="ts" setup>
// Vue
import { computed, onMounted, ref } from 'vue';

//Classes
import Page from '@/Classes/Page';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import DownloadAndShareButtons from '@/components/export/DownloadAndShareButtons.vue';
import DownloadPreview from '@/components/export/DownloadPreview.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useAuth } from '@/composables/useAuth';
import { useEditorMode } from '@/composables/useEditorMode';
import { useHistoryStore } from '@/stores/history';
// Store
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';

// Using composables
const emits = defineEmits(['close', 'error', 'share']);

const props = defineProps<{ selection: string[]; templateDistribution?: string }>();

const { inSlidesgoContext, isPhotoMode } = useEditorMode();
const { isLogged } = useAuth();
const store = useMainStore();
const project = useProjectStore();
const history = useHistoryStore();

// Data
const isViewer = computed(() => location.href.includes('/share/'));

// Methods
const onClose = () => {
	emits('close');
};
const onError = () => {
	emits('error');
};
const share = (socialNetwork: string) => {
	emits('share', { socialNetwork, pages: project.pages });
};

onMounted(() => {
	if (!isPhotoMode.value) {
		if (!store.userVector && !isViewer.value && isLogged.value) {
			project.triggerSync();
		}

		if (history.lastChangeFromNavigation && !isViewer.value && isLogged.value) {
			project.performSyncWithStateInUse();
		}
	}
});

const activeTemplate = computed(() =>
	store.downloadTemplateCategory?.name ? store.downloadTemplateCategory.name : 'default'
);

const templateDistribution = computed(() => {
	// Slidego Presentation
	if (inSlidesgoContext.value) {
		return 'present';
	}

	// Multipage
	if (props.selection && props.selection.length > 1) {
		return 'multipages';
	}

	// RRSS publication
	if (
		activeTemplate.value.toLowerCase().includes('facebook') ||
		activeTemplate.value.toLowerCase().includes('instagram') ||
		activeTemplate.value.toLowerCase().includes('twitter') ||
		activeTemplate.value.toLowerCase().includes('youtube') ||
		activeTemplate.value.toLowerCase().includes('zoom')
	) {
		return 'publish';
	}

	return 'default';
});
</script>

<template>
	<template v-if="(store.userVector && project.allChangesSaved) || isViewer || !isLogged || isPhotoMode">
		<div class="fixed inset-0 z-50 flex flex-col items-center justify-center bg-gray-900/75 p-6 sm:p-32">
			<div class="relative flex w-full max-w-4xl flex-col rounded-lg bg-white lg:flex-row">
				<button
					class="absolute top-0 right-0 mt-2 mr-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
					@click="onClose()"
				>
					<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
				</button>
				<!-- Download Preview -->
				<div
					class="relative hidden h-full w-96 items-center justify-center overflow-hidden rounded-tl-lg rounded-bl-lg bg-gray-100 p-4 lg:flex"
				>
					<DownloadPreview
						:selection="selection"
						:active-template="activeTemplate"
						:template-distribution="templateDistribution"
					/>
					<!-- Blurred image -->
					<div class="absolute inset-0 flex scale-150 items-center justify-center opacity-75 blur-xl">
						<Canvas preview :page="(project.pages[0] as Page)" />
					</div>
				</div>
				<!-- Download buttons -->
				<DownloadAndShareButtons
					:selection="selection"
					:template-distribution="templateDistribution"
					@error="onError()"
					@share="share"
				/>
			</div>
		</div>
	</template>
	<template v-else>
		<div
			class="absolute top-10 right-0 flex w-auto items-center justify-center rounded bg-white px-4 py-3 text-gray-700 lg:top-14"
		>
			<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin text-blue-500" />
			<span class="whitespace-nowrap text-sm font-semibold">Saving your template...</span>
		</div>
	</template>
</template>
