<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { ref, watch } from 'vue';

const props = defineProps<{
	modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const enabled = ref(props.modelValue);
const externalChange = ref(false);
watch(
	() => props.modelValue,
	(newValue) => {
		externalChange.value = newValue !== enabled.value;
		return (enabled.value = props.modelValue);
	}
);
watch(enabled, () => {
	if (externalChange.value) {
		externalChange.value = false;
		return;
	}
	emit('update:modelValue', enabled.value);
});
</script>

<template>
	<SwitchGroup>
		<div class="flex items-center">
			<Switch
				v-model="enabled"
				:class="enabled ? 'bg-blue-500' : 'bg-gray-200'"
				class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none"
			>
				<span
					:class="enabled ? 'translate-x-6' : 'translate-x-1'"
					class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
				/>
			</Switch>
			<SwitchLabel class="ml-3 text-sm font-semibold text-gray-600 lg:text-base"><slot /></SwitchLabel>
		</div>
	</SwitchGroup>
</template>
