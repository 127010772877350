<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { until } from '@vueuse/core';

import GridImage from '@/assets/grid-login-templates.png';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useAuth } from '@/composables/useAuth';

const emit = defineEmits(['close']);

const close = () => emit('close');

const { isLogged } = useAuth();

const login = async () => {
	const loginWindow = window.open('/login?editor=1');

	if (!loginWindow) {
		return;
	}

	loginWindow.opener = null;

	await until(isLogged).toBe(true);
	Bugsnag.leaveBreadcrumb(`Login`);
	loginWindow.close();
};
</script>

<template>
	<Modal open @close="close()">
		<div class="relative flex h-64 w-[320px] overflow-hidden rounded-md bg-white lg:w-[600px]">
			<div class="flex w-2/3 flex-col p-8 lg:w-1/2">
				<span class="mb-2 text-2xl font-bold text-gray-800">Join now</span>
				<p class="text-gray-600">Store your work in your account and edit it anywhere, anytime</p>

				<div class="mt-6 flex gap-4">
					<button class="h-12 rounded bg-blue-500 px-8 font-semibold text-white hover:bg-blue-600" @click="login()">
						Login
					</button>
				</div>
			</div>
			<img
				:src="GridImage"
				class="absolute top-0 right-0 h-full w-3/5 rounded-tr-md rounded-br-md object-cover object-left lg:w-3/5"
			/>
			<button
				class="absolute top-2 right-2 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-gray-800/75 font-semibold text-blue-500 backdrop-filter hover:bg-opacity-100 hover:text-white"
				@click="close()"
			>
				<SvgIcon name="cross" class="h-4 w-4 text-white" />
			</button>
		</div>
	</Modal>
</template>
