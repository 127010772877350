import { computed, Ref } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
import Line from '@/Classes/Line';
import { Color } from '@/Types/colorsTypes';

export const useLineColors = (lineRef: Ref<Line>) => {
	const gradientColors = computed(() => {
		const gradients = [];

		if (lineRef.value.mainColor instanceof GradientColor) {
			gradients.push(lineRef.value.mainColor);
		}

		if (lineRef.value.markerStart?.color instanceof GradientColor) {
			gradients.push(lineRef.value.markerStart.color);
		}

		if (lineRef.value.markerEnd?.color instanceof GradientColor) {
			gradients.push(lineRef.value.markerEnd.color);
		}

		return gradients;
	});

	const updateStrokeColor = (newColor: Color): Line => {
		lineRef.value.mainColor = newColor;

		return lineRef.value;
	};

	const updateMarkerStartColor = (newColor: Color): Line => {
		if (lineRef.value.markerStart) {
			lineRef.value.markerStart.color = newColor;
		}

		return lineRef.value;
	};

	const updateMarkerEndColor = (newColor: Color): Line => {
		if (lineRef.value.markerEnd) {
			lineRef.value.markerEnd.color = newColor;
		}

		return lineRef.value;
	};

	return {
		gradientColors,
		updateColor: updateStrokeColor,
		updateMarkerStartColor,
		updateMarkerEndColor,
	};
};
