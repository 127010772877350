<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

import Image from '@/Classes/Image';
import ReserveHeight from '@/components/Common/ReserveHeight.vue';
import PanelHeader from '@/components/panels/PanelHeader.vue';
import CropImageButton from '@/components/selection/panels/CropImageButton.vue';
import FlipMenu from '@/components/selection/panels/FlipMenu.vue';
import RemoveBgButtonToolbar from '@/components/selection/panels/RemoveBgButton.vue';
import ReplaceImageButton from '@/components/selection/panels/ReplaceImageButton.vue';
import RotateMenu from '@/components/selection/panels/RotateMenu.vue';
import ImageFilter from '@/components/selection/toolbars/images/ImageFilter.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useHistoryStore } from '@/stores/history';
import { useProjectStore } from '@/stores/project';

const { isIOS } = useDeviceInfo();
const project = useProjectStore();
const history = useHistoryStore();

const pages = computed(() => project.pages);

const image = ref<Image>();

watch(
	pages,
	(newVal) => {
		const page = newVal[0];
		if (!page?.backgroundImageId) return;
		image.value = page.elements.find((el) => el.id === page.backgroundImageId) as Image;
	},
	{ deep: true, immediate: true }
);
</script>

<template>
	<div v-if="image" class="flex h-full flex-col" data-test-photomode>
		<PanelHeader :title="'Edit image'" />

		<ReserveHeight>
			<div class="mb-6">
				<div class="mb-3 grid grid-cols-2 gap-3">
					<CropImageButton :label-position="'right'" :element="image" />
					<ReplaceImageButton :label-position="'right'" :element="image" />
				</div>
				<RemoveBgButtonToolbar :element="image" />
			</div>
			<p class="mb-2 hidden text-sm font-bold uppercase text-gray-100 lg:flex lg:opacity-75">Flip & Rotation</p>
			<div class="mb-3 flex gap-3">
				<div class="w-1/2">
					<FlipMenu :inline-label="true" :element="image" />
				</div>

				<div class="w-1/2">
					<RotateMenu :inline-label="true" :element="image" />
				</div>
			</div>

			<ImageFilter v-if="!isIOS" :key="history.key" :image="image" :grid-filters="true" />
		</ReserveHeight>
	</div>
</template>
