<script lang="ts" setup>
import { requestAnimationFrame } from '@daybrush/utils';
import { useEventListener, useMouse, useMutationObserver } from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, ref, toRef } from 'vue';

import { Text } from '@/Classes/Text';
import { useElementRenderStyles } from '@/composables/canvas/useElementRenderStyles';
import { useText } from '@/composables/element/text/useText';
import { getTextStyles, previousInputSelection, selection } from '@/composables/element/text/useTextStyles';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { usePage } from '@/composables/page/usePage';
import { useBugsnag } from '@/composables/useBugsnag';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorClipboard } from '@/composables/useEditorClipboard';
import { useOrderedKeyboardListener } from '@/composables/useOrderedKeyboardListener';
import { useMainStore } from '@/stores/store';
import TextTools from '@/utils/TextTools';

const props = defineProps<{ text: Text }>();
const text = toRef(props, 'text');
const store = useMainStore();
const { hideContentClipboard, restoreContentClipboard, pasteSelection } = useEditorClipboard();
const usingElementOrchestrator = useElementOrchestrator(text);
const { page } = usingElementOrchestrator.value;
const { removeElement } = usePage(page.value);
const { bugsnagMsgWithDebounce } = useBugsnag();
const { stopPropagation } = useOrderedKeyboardListener();

stopPropagation('Delete');
stopPropagation('Backspace');
stopPropagation(['z', 'Z']);
stopPropagation(['y', 'Y']);
stopPropagation(['c', 'C']);
stopPropagation(['v', 'V']);

const { isMobile, isAndroid } = useDeviceInfo();

const updateTextContent = () => {
	const target = editable.value;

	if (!props.text || !target) return;

	props.text.content = target.innerHTML;
};

// ponemos el foco en el texto en cuando entremos al modo edición
const editable = ref();

const initialScroll = ref({ x: 0, y: 0 });
const scrollArea = ref();
onMounted(() => {
	scrollArea.value = document.querySelector('#scroll-area');
	initialScroll.value = { x: scrollArea.value?.scrollLeft || 0, y: scrollArea.value?.scrollTop || 0 };
	editable.value.focus();
	setCursorPosition();

	if (isMobile.value) {
		setTimeout(() => scrollIntoView(), 500);
	}

	// Desactivamos el area del moveable para permitir clicks sobre el texto
	document.querySelector('.moveable-area')?.classList.add('pointer-events-none');

	// Vaciamos el clipboard por si tiene un elemento copiado no exponer toda la estructura de datos en caso de pegar
	hideContentClipboard();

	// Mutation para actualizar el elemento outlined
	useMutationObserver(
		editable,
		() => {
			const el = document.querySelector('.editable-outlined-text');

			if (el) {
				el.innerHTML = editable.value.innerHTML;
				updateBox();
			}
		},
		{
			childList: true,
			subtree: true,
			characterData: true,
		}
	);

	// useEventListener(editable.value, 'copy', copyEvent);
	useEventListener(editable.value, 'copy', async (e) => {
		const text = document.getSelection()?.getRangeAt(0).toString();

		if (text?.length) {
			await window.navigator.clipboard.writeText(text);
		}
	});

	useEventListener(editable.value, 'paste', async (e) => {
		e.preventDefault();
		const dataList = e.clipboardData?.items;

		const whiteListHtml = [
			'article',
			'aside',
			'footer',
			'header',
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
			'main',
			'nav',
			'section',
			'blockquote',
			'dd',
			'div',
			'dl',
			'dt',
			'figcaption',
			'li',
			'menu',
			'ol',
			'p',
			'ul',
			'a',
			'b',
			'cite',
			'em',
			'small',
			'span',
			'strong',
			'span',
		];

		// Si tenemos uno o varios tipos de texto, damos prioridad al HTML
		if (dataList && dataList.length > 1 && Array.from(dataList).find((el) => el.type === 'text/html')) {
			const el = Array.from(dataList).find((el) => el.type === 'text/html');

			if (el) {
				await el.getAsString((str) => {
					if (str.length) {
						const div = document.createElement('div');
						div.innerHTML = str;

						div.querySelectorAll(`:not(${whiteListHtml.join(', ')})`).forEach((el) => el.remove());
						// Reemplazamos las etiquetas pre por spans para que no mantenga el word-break y el white-space por defecto
						const finalString = div.innerHTML.replaceAll('<pre', '<span').replaceAll('</pre', '</span');

						if (finalString.length) {
							document.execCommand('insertHTML', false, finalString);
							// Reset Text childNodes
							if (editable.value) {
								TextTools.removeTextsStyles(editable.value);
							}
						}
					}
				});
			}
			// Si no tenemos HTML, buscamos el texto plano
		} else if (dataList && Array.from(dataList).find((el) => el.type === 'text/plain')) {
			const el = Array.from(dataList).find((el) => el.type === 'text/plain');

			if (el) {
				await el.getAsString((str) => {
					if (str.length && !str.startsWith('wepik|')) {
						document.execCommand('insertHTML', false, str);
					}
				});
			}
		}

		// Reajustamos altura del editable
		fitHeight(editable.value);
	});
});

onBeforeUnmount(() => {
	document.querySelector('.moveable-area')?.classList.remove('pointer-events-none');

	// Restauramos el clipboard
	restoreContentClipboard();

	// Ejecutamos la función handleBlur aquí también porque en firefox
	// se destruye el componente antes de hacer el blur al hacer clearSelection
	handleBlur();

	if (isAndroid)
		setTimeout(() => {
			scrollArea.value.scrollTo(initialScroll.value.x, initialScroll.value.y);
		}, 300);
});

const handleBlur = () => {
	if (store.textEditingId === null) {
		return;
	}

	const elementUnderMouse = document.elementFromPoint(mouse.x.value, mouse.y.value);

	// Si es un elemento input o fontPicker guardamos la selección previa
	if (elementUnderMouse?.closest('[data-font-picker]') || elementUnderMouse?.closest('[data-text-input]')) {
		// @ts-ignore
		const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = document.getSelection();

		if (anchorNode && focusNode && anchorNode.parentElement?.closest(`[id$='${props.text.id}']`)) {
			previousInputSelection.value = { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed };
		}
		return;
	}

	if (
		!elementUnderMouse?.closest('[data-text-input]') &&
		!elementUnderMouse?.closest('[data-font-picker]') &&
		elementUnderMouse?.closest('[data-keep-text-selection]')
	) {
		// si el blur se va a un componente que permite mantener la seleccion
		// guardamos la seleccion y dejamos en modo edicion
		if (store.textEditingId) {
			const editingText = document.querySelector(`#editable-${store.textEditingId}`) as HTMLElement;

			editingText.focus();
		}
		return;
	}

	store.textEditingId = null;
	previousInputSelection.value = null;
	updateTextContent();

	if (!props.text.content) {
		removeElement(props.text);
	}
};

const mouse = useMouse();

const setCursorPosition = (): void => {
	const temporalSelection = window.getSelection();

	if (!temporalSelection) return;

	const range = document.createRange();
	range.selectNodeContents(editable.value);
	temporalSelection.removeAllRanges();
	temporalSelection.addRange(range);

	selection.value = { text: temporalSelection?.toString(), selection: temporalSelection };
};

const textStyles = getTextStyles(text);

const { styles } = useElementRenderStyles(text);

const { fitHeight } = useText(text);

const updateBox = () => {
	bugsnagMsgWithDebounce(`Typing in ${editable.value.id}: ${editable.value.textContent}`);
	requestAnimationFrame(() => {
		fitHeight(editable.value);
	});
};
const fixScroll = () => {
	// los navegadores tratan de hacer scroll para que sea visible el texto que editamos...
	// asi que tras actualizar la caja, forzamos el scroll hacia arriba del navegador
	const node = page.value?.domNode();
	if (node) {
		const itemsContainer = node.querySelector('[data-elements-container]') as HTMLElement;

		node.scrollTop = 0;
		itemsContainer.scrollTop = 0;
		requestAnimationFrame(() => {
			node.scrollTop = 0;
			itemsContainer.scrollTop = 0;
		});
	}
};

const outlinedTextStyles = computed(() => TextTools.getOutlinedTextStyles(textStyles.value));
const isOutlinedText = computed(() => TextTools.haveOutlinedText(textStyles.value));

const scrollIntoView = () => {
	if (editable.value && editable.value.getBoundingClientRect().y) {
		const screenY = editable.value.getBoundingClientRect().y;
		const scrollArea = document.querySelector('#scroll-area') as HTMLElement;

		const windowHeight = window.visualViewport.height;
		const targetY = screenY + scrollArea.scrollTop - windowHeight / 4;
		scrollArea.scrollTo(scrollArea.scrollLeft, targetY);
	}
};
</script>

<template>
	<div class="notranslate absolute top-0 left-0" translate="no" :style="styles">
		<div
			v-if="isOutlinedText"
			ref="outlinedEditable"
			:style="textStyles"
			class="editable-outlined-text absolute"
			v-html="text ? text.content : ''"
		></div>
		<div
			:id="`editable-${text ? text.id : undefined}`"
			ref="editable"
			:style="isOutlinedText ? outlinedTextStyles : textStyles"
			contenteditable
			class="user-select-all text-element-final z-1 absolute w-full break-normal outline-none"
			@blur="handleBlur"
			@keyup="updateBox"
			@input="fixScroll"
			@dragstart.prevent
			v-html="text ? text.content : ''"
		></div>
	</div>
</template>

<style>
[contenteditable] {
	-webkit-user-select: text;
	user-select: text;
}
html:not(.color-picking) [contenteditable]::selection,
html:not(.color-picking) [contenteditable] *::selection {
	@apply text-black;
}
.color-picking [contenteditable],
.color-picking [contenteditable] * {
	@apply selection:bg-black/10 !important;
}
</style>
