// Vue & Packages
import Bugsnag from '@bugsnag/js';
import { useClipboard } from '@vueuse/core';
import { cloneDeep, minBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { computed, Ref, ref } from 'vue';

// Classes
import Element from '@/Classes/Element';
import { QRCode } from '@/Classes/QRCode';
import { Shape } from '@/Classes/Shape';
import Storyset from '@/Classes/Storyset';
import { useCommon } from '@/composables/element/common/useCommon';
// Composables
import { useShapeColors } from '@/composables/element/shape/useShapeColors';
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
import { usePage } from '@/composables/page/usePage';
import { useArtboard } from '@/composables/project/useArtboard';
import { useToast } from '@/composables/useToast';
// Stores
import { useMainStore } from '@/stores/store';
// Types
import { Position } from '@/Types/types';
// Utils
import TemplateLoader from '@/utils/TemplateLoader';

// Using composables
const { text, copy, isSupported } = useClipboard();

// Data
const temporalCopy = ref();
// Ref usada para versiones de navegador que no soporten la api de clipboard
const mobileCopiedElement = ref();

// Constants
const PASTE_OFFSET = 12;
const lastPaste = ref<any[]>([]);

export const useEditorClipboard = () => {
	const { addElement } = useActivePage();
	const { artboardSizeInPx } = useArtboard();
	const store = useMainStore();
	const toast = useToast();

	const temporalRef = ref<Element | null>(null);
	const focused = computed(() => temporalRef.value || store.selection[0] || Shape.createDefault());

	const { updateColor } = useShapeColors(temporalRef as Ref<Shape>);
	const usingElementTransform = useElementTransformOrchestrator(focused);
	const { page } = useCommon(focused);

	const { getElementIndex } = usePage(page);

	const canBeCopied = computed(() => store.selection.length > 0 && focused.value.id !== page.value?.backgroundImageId);
	const canBePasted = computed(
		() => (hasContent.value && !!store.activePage) || !!store.textEditingId || mobileCopiedElement.value?.length
	);
	const hasContent = computed(() => text.value?.startsWith('wepik|'));
	const selectionType = computed(() =>
		store.selection.length > 1 && store.selection[1].group ? 'group' : store.selection.length > 1 ? 'selection' : ''
	);

	const clone = (element: Element): Element => {
		const cloned = TemplateLoader.unserializeElement(cloneDeep(element));

		cloned.id = uuidv4();

		if (cloned instanceof Storyset) {
			// Modificamos el id al color de la copia
			cloned.mainColor.id = 'color-' + uuidv4();
		}

		if (cloned instanceof QRCode) {
			// Modificamos el id al color de la copia
			cloned.bgColor.id = 'color-' + uuidv4();
			cloned.frontColor.id = 'color-' + uuidv4();
		}

		if (cloned instanceof Shape) {
			const colorsOriginal = cloned.colors.map((color) => cloneDeep(color));

			// Modificamos los id a los colores de la copia
			cloned.colors.forEach((color) => {
				color.id = 'color-' + uuidv4();
			});

			temporalRef.value = cloned;

			// Actualizamos las referencias de los colores originales a los de la copia
			colorsOriginal.forEach((color, i) => {
				updateColor(color, cloned.colors[i]);
				cloned.content = cloned.content.replaceAll(color.id, cloned.colors[i].id);
			});
		}

		return cloned;
	};

	/**
	 * Control + c copia las ids de los elementos seleccionados, con un prefijo en el portapapeles para
	 * poder identificarlo al pegar.
	 */
	const copySelection = async () => {
		if (store.textEditingId?.length) {
			// TODO
			return;
		}

		// Copy Element
		temporalRef.value = store.selection[0] as Element;

		// Cancel copy in photo mode image
		if (!canBeCopied.value) return;

		const elements = [...store.selection];
		const sortedSelection = [...elements.sort((a, b) => (getElementIndex(a) < getElementIndex(b) ? 1 : -1))];
		sortedSelection.reverse();
		lastPaste.value = sortedSelection.map((element) => ({
			id: element.id,
			x: element.position.x,
			y: element.position.y,
		}));

		// Si el navegador soporta clipboard usamos el clipboard nativo, en caso contrario sobreescribimos mobileCopiedElement
		const element = `wepik|${JSON.stringify(sortedSelection)}`;

		if (isSupported) {
			await copy(element);
		} else {
			mobileCopiedElement.value = element;
		}

		toast.info(`${sortedSelection.length > 1 ? 'Items' : 'Item'} added to clipboard`);

		Bugsnag.leaveBreadcrumb(`Copy ${selectionType.value}: ${sortedSelection.map((el) => el.type + '-' + el.id)}`);

		temporalRef.value = null;
	};

	/**
	 * Al pegar comprobamos de si lo que pegamos es una selección del editor
	 * @param fromMouse
	 */
	const pasteSelection = (pastePos?: Position) => {
		if (!canBePasted.value) return;

		if (store.textEditingId?.length) {
			// TODO
			return;
		}

		const pastedElements = JSON.parse((isSupported ? text.value : mobileCopiedElement.value).substring(6));
		store.clearSelection();

		let offsetX = 0;
		let offsetY = 0;

		// Si es un un grupo buscamos el elemento que este más arriba a la izquierda
		if (pastedElements.length > 1) {
			const topXElement = minBy(pastedElements, 'position.x') as any;
			const topYElement = minBy(pastedElements, 'position.y') as any;

			offsetX = -topXElement.position.x;
			offsetY = -topYElement.position.y;
		}

		const newGroupId = uuidv4();

		const cloneds: Element[] = pastedElements.map((element: Element) => {
			const cloned = clone(TemplateLoader.unserializeElement(element));

			if (element.group) {
				cloned.group = newGroupId;
			}

			if (element.locked) {
				cloned.setLocked(false);
			}

			temporalRef.value = cloned as Element;

			if (pastePos) {
				// With mouse
				const positionX = pastedElements.length > 1 ? cloned.position.x : 0;
				const positionY = pastedElements.length > 1 ? cloned.position.y : 0;

				usingElementTransform.value.move(positionX + offsetX + pastePos.x, positionY + offsetY + pastePos.y, false);
			} else {
				// With keyboard
				const found = lastPaste.value.find((e: Element) => e.id === element.id);
				if (!found) {
					addElement(cloned);
					usingElementTransform.value.move(PASTE_OFFSET, PASTE_OFFSET);
					return cloned;
				}

				const moveTo: Position = {
					x:
						found.x + PASTE_OFFSET > artboardSizeInPx.value.width - 20
							? found.x - PASTE_OFFSET
							: found.x + PASTE_OFFSET,
					y:
						found.y + PASTE_OFFSET > artboardSizeInPx.value.height - 20
							? found.y - PASTE_OFFSET
							: found.y + PASTE_OFFSET,
				};
				usingElementTransform.value.move(moveTo.x, moveTo.y, false);
				found.x = cloned.position.x;
				found.y = cloned.position.y;
			}

			addElement(cloned);

			return cloned;
		});

		cloneds.forEach((clone) => store.setSelection(clone, true));

		temporalRef.value = null;

		Bugsnag.leaveBreadcrumb(`Paste ${selectionType.value}: ${store.selection.map((el) => el.type + '-' + el.id)}`);
	};

	const hideContentClipboard = () => {
		if (!hasContent.value) return;

		if (!text.value?.startsWith('wepik|')) {
			temporalCopy.value = text.value;
			copy('');
		}
	};

	const restoreContentClipboard = () => {
		if (!temporalCopy.value) return;

		if (!text.value?.startsWith('wepik|')) {
			copy(temporalCopy.value);
		}
	};

	return {
		canBeCopied,
		canBePasted,
		hasContent,
		pasteSelection,
		copySelection,
		clone,
		hideContentClipboard,
		restoreContentClipboard,
	};
};
