<script lang="ts" setup>
// Vue
import { toRef } from 'vue';

// Classes
import Image from '@/Classes/Image';
// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useImageBackground } from '@/composables/element/image/useImageBackground';
// Composables
import { useEditorMode } from '@/composables/useEditorMode';
import { useToast } from '@/composables/useToast';
import EventTools from '@/utils/EventTools';

// Props
const props = defineProps<{ element: Image }>();

// Data
const element = toRef(props, 'element');
const toast = useToast();
// Using composables
const { removeImageAsBackground } = useImageBackground(element);
const { isPhotoMode } = useEditorMode();
const { canBeSetAsBackground, setImageAsBackground } = useImageBackground(element);

const setAsBackground = () => {
	try {
		setImageAsBackground();
	} catch (e) {
		const message = EventTools.getErrorMessage(e);
		toast.error(message);
	}
};
</script>

<template>
	<button
		v-if="element.locked"
		data-testid="to-background"
		class="relative flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
		@click="removeImageAsBackground"
	>
		<span
			class="absolute -top-2 right-2 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-400"
		>
			<SvgIcon name="lock" class="h-2 w-2" />
		</span>
		<SvgIcon name="background" class="h-4 w-4" />
		<label class="block whitespace-nowrap text-center text-2xs text-gray-100 lg:hidden">Background</label>
	</button>
	<button
		v-else
		data-testid="to-background"
		class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
		:class="{
			'disabled cursor-not-allowed opacity-50': !canBeSetAsBackground || isPhotoMode,
			'hover:bg-gray-600 hover:text-white': canBeSetAsBackground && !isPhotoMode,
		}"
		:disabled="!canBeSetAsBackground || isPhotoMode"
		@click="setAsBackground"
	>
		<SvgIcon name="background" class="h-4 w-4" />
		<label class="block whitespace-nowrap text-center text-2xs text-gray-100 lg:hidden">Background</label>
	</button>
	<label class="mt-1 hidden text-center text-2xs leading-snug text-gray-100 lg:block">Image to background</label>
</template>
