<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, onMounted, ref, watch } from 'vue';

import { getUserUploads } from '@/api/UserApiClient';
import InfiniteLoading from '@/components/Common/_InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import DeleteImageModal from '@/components/panels/photos/DeleteImageModal.vue';
import GooglePhotoImages from '@/components/panels/photos/GooglePhotoImages.vue';
import ListImage from '@/components/panels/photos/ListImage.vue';
import { useAuth } from '@/composables/useAuth';
import { useCustomImagesActions } from '@/composables/useUploadImagesActions';
import { useUserImageProvider } from '@/composables/useUserImageProvider';
import { useProjectStore } from '@/stores/project';
import { ImageApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';

const emit = defineEmits<{
	(e: 'select', value: ImageApi): void;
}>();

const projectStore = useProjectStore();

const { isLogged } = useAuth();

// Al montarse vacíamos el userUploads ya que le fetch nos va a traer los datos actualizados y si ha subido desde
// drag & drop tendríamos estas imágenes repetidas
onMounted(() => (userUploads.value = []));

const {
	uploadFromLocal,
	selectFromLocal,
	selectFromDropbox,
	selectFromDrive,
	selectFromPhotos,
	authenticatedInDropbox,
	authenticatedInGoogle,
	userUploads,
} = useUserImageProvider();

const { deleteImage } = useCustomImagesActions();

// Le pasamos al fetch una ref para poder manipular el data que nos devuelve (añadir/quitar elementos)
// si usamos la interna no se actualizaría con estos cambios
const { data, isFetching, url } = getUserUploads(userUploads, { refetch: true });
const { data: dataWatcher, execute } = getUserUploads(userUploads, { immediate: false });
watch(isLogged, async (val) => {
	if (!val) return;
	await execute();
});

const links = computed(() => data.value?.links || dataWatcher.value?.links);
const googlePhotoPanel = ref(false);
const changeToGooglePhotos = async () => {
	if (await selectFromPhotos()) {
		googlePhotoPanel.value = true;
	}
};

const props = defineProps<{ modal?: boolean }>();

const loadMore = () => {
	if (isFetching.value || !links.value.next) return;
	url.value = links.value.next;
};

const imgToDelete = ref<ImageApi | null>(null);
const deleteUpload = () => {
	if (!imgToDelete.value) return;

	// Borramos imagen en server
	deleteImage(imgToDelete.value.id);

	// La quitamos del array de subidas ( panel )
	userUploads.value = userUploads.value.filter((el) => el.id !== imgToDelete.value?.id);

	// Buscamos si se está usando en el proyecto y la sustituimos por not-found
	const urlToSearch = imgToDelete.value.url.split('?')[0];
	const imgsUsed = projectStore.allImages.filter((img) => img.url.includes(urlToSearch));

	imgsUsed.forEach((img) => {
		img.url = '/svg/image-not-found.svg';
		img.backgroundMode = 'original';
		img.urlBackgroundRemoved = null;
	});
	Bugsnag.leaveBreadcrumb(`Delete ${imgToDelete.value.type} uploaded`);
	imgToDelete.value = null;
};

const uploadImages = (e: Event) => {
	uploadFromLocal(e);
	GAnalytics.track('click', 'Button', 'upload-image', null);
};
</script>

<template>
	<div class="flex h-full flex-col">
		<KeepAlive>
			<GooglePhotoImages v-if="googlePhotoPanel" @select="emit('select', $event)" @close="googlePhotoPanel = false" />

			<template v-else>
				<div class="flex flex-col">
					<!-- Drag zone -->
					<input
						id="upload-img"
						class="hidden"
						type="file"
						accept="image/png, image/jpeg, image/jpg, image/svg+xml"
						multiple
						@change="uploadImages"
					/>

					<div class="mt-2">
						<div class="grid grid-cols-4 gap-4" :class="modal ? 'mx-auto w-5/6 lg:gap-6' : ' lg:gap-2'">
							<!-- Main button -->
							<div :disabled="googlePhotoPanel" :class="googlePhotoPanel ? 'pointer-events-none opacity-50' : ''">
								<label
									class="group relative mb-2 flex w-full cursor-pointer justify-center rounded pb-full text-center font-bold focus:outline-none"
									:class="modal ? 'bg-gray-100/50 text-gray-900' : ' bg-gray-700 text-white'"
									@click="selectFromLocal"
								>
									<SvgIcon
										name="upload-image"
										class="absolute inset-0 h-full w-full p-5 opacity-75 group-hover:opacity-100"
									/>
								</label>
								<p class="text-center text-xs" :class="modal ? 'text-gray-800' : 'text-gray-100'">Device</p>
							</div>
							<button
								:disabled="googlePhotoPanel"
								:class="googlePhotoPanel ? 'pointer-events-none opacity-50' : ''"
								@click="selectFromDropbox"
							>
								<label
									class="group relative mb-2 flex w-full cursor-pointer items-center justify-center rounded bg-dropbox-500 pb-full text-center font-bold text-white focus:outline-none"
								>
									<SvgIcon
										name="integrations-dropbox"
										class="absolute inset-0 h-full w-full fill-current p-4 text-dropbox-100 group-hover:text-white"
									/>
									<span
										v-if="authenticatedInDropbox"
										class="absolute top-0 right-0 m-1 flex h-4 w-4 items-center justify-center rounded-full bg-green-600 text-xs font-bold text-white"
									>
										<SvgIcon name="link" class="h-2 w-2 fill-current text-white"></SvgIcon>
									</span>
								</label>
								<p class="text-center text-xs" :class="modal ? 'text-gray-800' : 'text-gray-100'">Dropbox</p>
							</button>

							<!--					Google Drive button-->
							<button
								:disabled="googlePhotoPanel"
								:class="googlePhotoPanel ? 'pointer-events-none opacity-50' : ''"
								@click="selectFromDrive"
							>
								<label
									class="group relative mb-2 flex w-full w-full cursor-pointer items-center justify-center rounded bg-white pb-full text-center font-bold text-white focus:outline-none"
								>
									<SvgIcon
										name="integrations-drive"
										class="absolute inset-0 h-full w-full fill-current p-4 text-dropbox-100 group-hover:text-white"
									/>
									<span
										v-if="authenticatedInGoogle"
										class="absolute top-0 right-0 m-1 flex h-4 w-4 items-center justify-center rounded-full bg-green-600 text-xs font-bold text-white"
									>
										<SvgIcon name="link" class="h-2 w-2 fill-current text-white"></SvgIcon>
									</span>
								</label>
								<p class="text-center text-xs" :class="modal ? 'text-gray-800' : 'text-gray-100'">Drive</p>
							</button>
							<!--					Google Photos button-->
							<button @click="changeToGooglePhotos">
								<label
									class="group relative mb-2 flex w-full w-full cursor-pointer items-center justify-center rounded bg-white pb-full text-center font-bold text-white focus:outline-none"
								>
									<SvgIcon
										name="integrations-photos"
										class="absolute inset-0 h-full w-full fill-current p-4 text-dropbox-100 group-hover:text-white"
									/>
									<span
										v-if="authenticatedInGoogle"
										class="absolute top-0 right-0 m-1 flex h-4 w-4 items-center justify-center rounded-full bg-green-600 text-xs font-bold text-white"
									>
										<SvgIcon name="link" class="h-2 w-2 fill-current text-white"></SvgIcon>
									</span>
								</label>
								<p class="text-center text-xs" :class="modal ? 'text-gray-800' : 'text-gray-100'">Photos</p>
							</button>
						</div>
					</div>
				</div>
				<div v-if="!modal" class="mt-4 mb-2 flex items-center text-center text-xs font-light text-gray-100">
					<hr class="flex-1 border-gray-700" />
					<span class="mx-4">or do drag & drop in the template</span>
					<hr class="flex-1 border-gray-700" />
				</div>
				<DeleteImageModal :open="!!imgToDelete" @close="imgToDelete = null" @confirm="deleteUpload" />
				<KeepAlive>
					<InfiniteLoading
						:data="(userUploads as any)"
						:is-fetching="isFetching"
						:modal="modal"
						class="flex flex-col"
						masonry
						:masonry-cols="2"
						@load="loadMore"
					>
						<template #item="{ item }">
							<InsertableElement
								:data="item"
								:type="Panels.photos"
								:draggable="!props.modal"
								class="mb-2"
								@click="emit('select', item)"
							>
								<ListImage
									:use-background-removed-if-available="true"
									:image="item"
									:deletable="true"
									@delete="imgToDelete = item"
								/>
							</InsertableElement>
						</template>
					</InfiniteLoading>
				</KeepAlive>
			</template>
		</KeepAlive>
	</div>
</template>

<style scoped></style>
