<script lang="ts" setup>
// Bugsnag
import Bugsnag from '@bugsnag/js';
// Vue & packages
import { computed, ref } from 'vue';

// Api
import { getFlaticon, getSvg } from '@/api/DataApiClient';
// Classes
import { Shape } from '@/Classes/Shape';
// Components
import InfiniteLoading from '@/components/Common/InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import ColorFilterFlaticon from '@/components/panels/elements/ColorFilterFlaticon.vue';
import StyleFilterFlaticon from '@/components/panels/elements/StyleFilterFlaticon.vue';
import { useElementOrchestrator } from '@/composables/element/useElementOrchestrator';
import { useActivePage } from '@/composables/page/useActivePage';
import { useProjectStore } from '@/stores/project';
// Stores
import { useMainStore } from '@/stores/store';
// Type
import { ElementCategory, ElementsPanelTypes } from '@/Types/types';
import GAnalytics from '@/utils/GAnalytics';

import AutocompleteInput from '../../Common/AutocompleteInput.vue';
const store = useMainStore();
const project = useProjectStore();

// Props
defineProps<{ activeElementCategory: ElementCategory | null | undefined }>();

// Emits
const emit = defineEmits(['setElementCategory', 'elementAdded']);
const temporalRef = ref(Shape.createDefault());
const usingElementOrchestrator = useElementOrchestrator(temporalRef);
const { addElement } = useActivePage();

// Constants
const ELEMENT_URL_CONFIG = {
	LIMIT: 100,
	PAGE: 1,
};

// Data
const search = ref('');

const color = ref(null);
const style = ref(null);

// Computed
const autocompleteSource = computed(() => `autocomplete/flaticon?search=${search.value}&type=icon`);

const source = computed(
	() =>
		`flaticon/search?q=${search.value || project.flaticonSearch || ''}&limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${
			ELEMENT_URL_CONFIG.PAGE
		}${color.value ? `&color=${color.value}` : ''}${style.value ? `&style=${style.value}` : ''}`
);

// Methods
const onClickIcon = async (url: string) => {
	const { data } = await getSvg(url);
	if (!data.value || !store.activePage) return;

	const shape = Shape.fromSvg(data.value);

	temporalRef.value = shape;
	addElement(shape);

	usingElementOrchestrator.value.setupInPage();

	store.setSelection(shape, false);

	GAnalytics.track('click', 'Button', 'add-icon', null);
	Bugsnag.leaveBreadcrumb(`Add flaticon to canvas; ${shape.type}-${shape.id}`);
	emit('elementAdded');
};

const updateSearchValue = (searchValue: string) => {
	search.value = searchValue;

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in Flaticon panel: ${searchValue}`);
};

// TODO Si cargas mas de una página y cambias de filtros se quedan nodos fuera de la reactividad de vue (wtf), refrescamos
// el componente para solucionar esto
</script>

<template>
	<AutocompleteInput
		:placeholder="'Search Icon...'"
		:query="search"
		:autocomplete-source="autocompleteSource"
		@change="updateSearchValue"
	/>

	<div class="flex gap-3 py-1">
		<div class="relative">
			<ColorFilterFlaticon v-model="color" :style="style" />
		</div>
		<div class="relative">
			<StyleFilterFlaticon v-model="style" :color="color" />
		</div>
	</div>
	<InfiniteLoading :source="source" :fetch="getFlaticon" :container-classes="`grid gap-x-10 gap-y-6 pt-2 grid-cols-4`">
		<template #item="{ item }">
			<!-- Elements -->
			<InsertableElement
				v-show="item.svg"
				data-testid="flaticon-icon"
				:data="item"
				:draggable="true"
				:type="ElementsPanelTypes.Flaticon"
				class="flex cursor-pointer rounded transition-opacity duration-300 hover:opacity-50"
				@click.stop="onClickIcon(item.svg)"
			>
				<img
					:src="item.preview"
					:data-svg="item.svg"
					:alt="item.name"
					draggable="true"
					loading="lazy"
					class="aspect-ratio w-full object-contain"
					:class="{ 'opacity-50 invert': !item.multicolor && !item.isCustom }"
				/>
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
