// Bugsnag
import Bugsnag from '@bugsnag/js';
import { useDebounceFn } from '@vueuse/core';
import { Ref } from 'vue';

import Element from '@/Classes/Element';
import { GradientColor } from '@/Classes/GradientColor';
import { StopGradient } from '@/Types/colorsTypes';
type elementAttrs = 'size' | 'rotation';
export const useBugsnag = (element?: Ref<Element>) => {
	const breadScrumbWithDebounce = useDebounceFn(
		(attrKey: elementAttrs | GradientColor | StopGradient | string, msg?: string) => {
			if (element) {
				Bugsnag.leaveBreadcrumb(
					`${msg || ''} ${element.value.type}-${element.value.id} ${attrKey} values: ${getValues(
						attrKey as elementAttrs
					)}`
				);
				return;
			}
			if (attrKey.constructor.name === 'GradientColor') {
				const gradientColor = attrKey as GradientColor;
				const toCss = gradientColor.toCssString();
				Bugsnag.leaveBreadcrumb(`Apply ${toCss} `);
				return;
			}
			Bugsnag.leaveBreadcrumb(`Set ${attrKey}`);
		},
		1000
	);

	const bugsnagMsgWithDebounce = useDebounceFn((msg: string) => {
		Bugsnag.leaveBreadcrumb(msg);
	}, 500);

	const getValues = (attrKey: elementAttrs) => {
		if (element) {
			return typeof element.value[attrKey] === 'object'
				? Object.values(element.value[attrKey]).map((val) => val)
				: element.value[attrKey];
		}
	};
	return {
		breadScrumbWithDebounce,
		bugsnagMsgWithDebounce,
	};
};
