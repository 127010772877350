// Bugsnag
import Bugsnag from '@bugsnag/js';
import { useElementBounding, useMutationObserver, watchOnce } from '@vueuse/core';
import { computed, nextTick, Ref, ref } from 'vue';

import Element from '@/Classes/Element';
import { useMoveable } from '@/composables/interactions/useInteractiveElements';
import { useArtboard } from '@/composables/project/useArtboard';
import { useMountedState } from '@/composables/useMountedState';
import { useMainStore } from '@/stores/store';
import { Anchor } from '@/Types/types';
import MathTools from '@/utils/MathTools';

// Vamos a mover el grupo usando directamente el moveable para evitar cálculos en rotaciones, además la caja del grupo
// rota cuando rotas el grupo pero una vez la vuelvas a seleccionar ya no está rotada por lo que complica hacerlo con datos
// para el moveable el 0,0 es el del scroll area, no el del canvas como nuestros elements.

const getSizeFromStyleMoveableArea = () => {
	widthMoveable.value = parseFloat(moveableArea.value?.style.width);
	heightMoveable.value = parseFloat(moveableArea.value?.style.height);
};
const getRotateFromStyleMoveableArea = () =>
	(rotation.value = parseInt(moveableArea.value?.style.transform.split('rotate(')[1]?.split(')')[0]));

const getXFromStyleMoveablePortal = () =>
	parseFloat(moveablePortal.value?.style.transform?.split('(')[1]?.split(',')[0]);
const getYFromStyleMoveablePortal = () => parseFloat(moveablePortal.value?.style.transform?.split(',')[1]);

const getPositionFromStyle = () => {
	leftMoveable.value = getXFromStyleMoveablePortal();
	topMoveable.value = getYFromStyleMoveablePortal();
};

const updateInfo = () => {
	// useElementBounding no se entera si cambia el transform de los elementos lo actualizamos nosotros
	updateCanvasInfo();

	getSizeFromStyleMoveableArea();
	getRotateFromStyleMoveableArea();
	getPositionFromStyle();
};

// Moveable portal (#portalTarget) contiene el translate del moveable area
const moveablePortal = ref();
// Moveable area (.moveable-area) contiene el width / height del moveable area
const moveableArea = ref();
// El moveable se mueve dentro del scroll area por lo que su 0,0 es esquina superior izq del scroll area
const scrollArea = ref();
// Como nos queremos mover realmente respecto al canvas necesitamos también sus datos para transformar el 0,0
const canvasArea = ref();

// Instanciamos fuera del hook para usar las mismas instancias en todas las llamadas al hook
const { left: leftScrollArea, top: topScrollArea } = useElementBounding(scrollArea);
const { left: leftCanvas, top: topCanvas, update: updateCanvasInfo } = useElementBounding(canvasArea);
const widthMoveable = ref(0);
const heightMoveable = ref(0);
const leftMoveable = ref(getXFromStyleMoveablePortal());
const topMoveable = ref(getYFromStyleMoveablePortal());
const rotation = ref(0);

// Cuando movemos el moveable-area realmente cambia el transalte del moveable portal, observamos sus cambios para actualizar la info
useMutationObserver(
	moveablePortal,
	() => {
		updateInfo();
	},
	{
		attributeFilter: ['style'],
	}
);

// Observamos el moveable area para sacar su tamaño y rotación
useMutationObserver(
	moveableArea,
	() => {
		updateInfo();
	},
	{
		attributeFilter: ['style'],
	}
);

export const useGroupTransform = (group: Ref<Element[]>) => {
	const store = useMainStore();
	const ready = useMountedState();
	const { moveable } = useMoveable();
	const { artboardSizeInPx: sizeInPx } = useArtboard();

	const rotationInRadians = computed(() => MathTools.angleToRadians(rotation.value));
	const widthWithRotation = computed(
		() =>
			widthMoveable.value * Math.abs(Math.cos(rotationInRadians.value)) +
			heightMoveable.value * Math.abs(Math.sin(rotationInRadians.value))
	);
	const heightWithRotation = computed(
		() =>
			heightMoveable.value * Math.abs(Math.cos(rotationInRadians.value)) +
			widthMoveable.value * Math.abs(Math.sin(rotationInRadians.value))
	);

	// Todos estos datos corresponden a la posición en px tomando como 0,0 el scroll-area
	const left = computed(() => boundingCanvasInScrollAreaContext.value.left + scrollArea.value?.scrollLeft);
	const top = computed(() => boundingCanvasInScrollAreaContext.value.top + scrollArea.value?.scrollTop);
	const centerX = computed(() => (sizeInPx.value.width * store.scale) / 2 - widthWithRotation.value / 2 + left.value);
	const centerY = computed(() => (sizeInPx.value.height * store.scale) / 2 - heightWithRotation.value / 2 + top.value);
	const right = computed(() => left.value + sizeInPx.value.width * store.scale - widthWithRotation.value);
	const bottom = computed(() => top.value + sizeInPx.value.height * store.scale - heightWithRotation.value);

	const boundingCanvasInScrollAreaContext = computed(() => {
		return {
			left: leftCanvas.value - leftScrollArea.value,
			top: topCanvas.value - topScrollArea.value,
		};
	});

	// Al montarse el hook iniciamos los datos
	watchOnce(ready, async () => {
		if (group.value.length < 2) {
			return;
		}
		await nextTick();
		updateTargets();
	});

	const updateTargets = () => {
		moveablePortal.value = document.querySelector('#portalTarget');
		moveableArea.value = document.querySelector('.moveable-area');
		scrollArea.value = document.querySelector('#scroll-area');
		canvasArea.value = store.activePage?.domNode();

		updateInfo();
	};

	// Posición sin tener en cuenta la rotación! (mismo contexto que en los elements)
	const position = computed(() => {
		const groupsPositionsX = group.value.map((el) => el.position.x);
		const groupsPositionsY = group.value.map((el) => el.position.y);
		const x = Math.min(...groupsPositionsX);
		const y = Math.min(...groupsPositionsY);

		return {
			x,
			y,
		};
	});

	// Tamaño en el contexto del Canvas(Page)
	const size = computed(() => {
		return {
			width: widthMoveable.value / store.scale,
			height: heightMoveable.value / store.scale,
		};
	});

	const isAlignLeft = computed(() => Math.abs(leftMoveable.value - left.value) < 1);
	const isAlignCenter = computed(() => Math.abs(leftMoveable.value - centerX.value) < 1);
	const isAlignRight = computed(() => Math.abs(leftMoveable.value - right.value) < 1);

	const isAlignTop = computed(() => Math.abs(topMoveable.value - top.value) < 1);
	const isAlignMiddle = computed(() => Math.abs(topMoveable.value - centerY.value) < 1);
	const isAlignBottom = computed(() => Math.abs(topMoveable.value - bottom.value) < 1);

	const isOutsidePage = computed(() => {
		const isOutsideLeft = leftMoveable.value + widthWithRotation.value < left.value;
		const isOutsideRight = leftMoveable.value - widthWithRotation.value > right.value;
		const isOutsideX = isOutsideLeft || isOutsideRight;

		const isOutsideTop = topMoveable.value + heightWithRotation.value < top.value;
		const isOutsideBottom = topMoveable.value - heightWithRotation.value > bottom.value;
		const isOutsideY = isOutsideTop || isOutsideBottom;

		return isOutsideX || isOutsideY;
	});

	const align = (anchor: Anchor) => {
		if (!moveableArea.value) {
			updateTargets();
			getSizeFromStyleMoveableArea();
			getRotateFromStyleMoveableArea();
			leftMoveable.value = getXFromStyleMoveablePortal();
			topMoveable.value = getYFromStyleMoveablePortal();
		}
		// POSITION X
		if ([Anchor.left, Anchor.topLeft, Anchor.leftCenter, Anchor.bottomLeft].includes(anchor)) {
			moveable.value?.request('draggable', { x: left.value, y: topMoveable.value }, true);
		}
		if ([Anchor.centerX, Anchor.topCenter, Anchor.bottomCenter].includes(anchor)) {
			moveable.value?.request('draggable', { x: centerX.value, y: topMoveable.value }, true);
		}
		if ([Anchor.right, Anchor.topRight, Anchor.rightCenter, Anchor.bottomRight].includes(anchor)) {
			moveable.value?.request('draggable', { x: right.value, y: topMoveable.value }, true);
		}

		// POSITION Y
		if ([Anchor.bottom, Anchor.bottomLeft, Anchor.bottomCenter, Anchor.bottomRight].includes(anchor)) {
			moveable.value?.request('draggable', { x: leftMoveable.value, y: bottom.value }, true);
		}

		if ([Anchor.centerY, Anchor.leftCenter, Anchor.topCenter].includes(anchor)) {
			moveable.value?.request('draggable', { x: leftMoveable.value, y: centerY.value }, true);
		}

		if ([Anchor.top, Anchor.topLeft, Anchor.topCenter, Anchor.topRight].includes(anchor)) {
			moveable.value?.request('draggable', { x: leftMoveable.value, y: top.value }, true);
		}

		if ([Anchor.center].includes(anchor)) {
			moveable.value?.request('draggable', { x: centerX.value, y: centerY.value }, true);
		}

		moveable.value?.updateTarget();
		Bugsnag.leaveBreadcrumb(`Align group to ${anchor}: ${group.value.map((el) => ` ${el.type}-${el.id}`)}`);
	};

	const resize = (width: number, height: number) => {
		moveable.value?.request(
			'resizable',
			{ offsetWidth: width * store.scale, offsetHeight: height * store.scale },
			true
		);
	};

	const rotate = (rotation: number) => {
		moveable.value?.request('rotatable', { rotate: rotation }, true);
	};

	return {
		position,
		size,
		rotation,
		left,
		isAlignLeft,
		isAlignCenter,
		isAlignRight,
		isAlignTop,
		isAlignMiddle,
		isAlignBottom,
		isOutsidePage,
		align,
		resize,
		rotate,
		updateTargets,
	};
};
