import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

import { HistoryState } from '@/Classes/HistoryState';
import { useNamedDebounce } from '@/composables/useNamedDebounce';
import { useZoom } from '@/composables/useZoom';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';

export interface HistoryStore {
	states: HistoryState[];
	activeState: HistoryState | null;
	key: string;
	lastChangeFromNavigation: boolean;
}

export const useHistoryStore = defineStore('history', {
	state: (): HistoryStore => ({
		states: [],
		activeState: null,
		key: 'history-key',
		lastChangeFromNavigation: false,
	}),

	actions: {
		forward() {
			if (this.activeState === this.states[this.states.length - 1]) {
				return;
			}

			const { fitZoomScale } = useZoom();

			useNamedDebounce('hforward', 100, () => {
				const newState = this.states[(this.activeState as HistoryState).index + 1] as HistoryState;
				const artboardChange =
					this.activeState?.height !== newState.height ||
					this.activeState?.width !== newState.width ||
					this.activeState.unit !== newState.unit;

				this.setState(newState);

				if (artboardChange) {
					fitZoomScale();
				}
			});
		},

		rollback() {
			if (this.activeState?.index === 0) {
				return;
			}

			const { fitZoomScale } = useZoom();

			useNamedDebounce('wrollback', 100, () => {
				const newState = this.states[(this.activeState as HistoryState).index - 1] as HistoryState;
				const artboardChange =
					this.activeState?.height !== newState.height ||
					this.activeState?.width !== newState.width ||
					this.activeState.unit !== newState.unit;

				this.setState(newState);

				if (artboardChange) {
					fitZoomScale();
				}
			});
		},

		setInitialState(forceSync = false) {
			const store = useProjectStore();
			// @ts-ignore
			const h = new HistoryState(store, Object.keys(this.states).length);
			this.activeState = h;
			this.states.push(h);

			store.initSync(forceSync);
		},

		setState(state: HistoryState) {
			const store = useMainStore();
			const project = useProjectStore();

			// @ts-ignore
			window.moving = true;
			this.activeState = state;

			project.$patch(() => {
				project.size.width = state.width;
				project.size.height = state.height;
				project.unit = state.unit;
				project.pages = cloneDeep(state.pages);
			});

			store.$patch(() => {
				store.textEditingId = store.croppingId = null;
				store.selectionId = [];
			});

			// @ts-ignore
			window.moving = false;

			this.key = uuidv4();
			this.lastChangeFromNavigation = true;
		},
	},
});
