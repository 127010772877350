<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { computed, ref } from 'vue';

import { GradientColor } from '@/Classes/GradientColor';
import { SolidColor } from '@/Classes/SolidColor';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import Popper from '@/components/Common/Popper.vue';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{
	colors: Color[];
	max: number;
	pickerClass?: string;
	pickerParent?: string;
	textsColors?: Color[];
	linesStrokeColors?: Color[];
	hideAlpha: boolean;
}>();

const emit = defineEmits<{
	(e: 'change', data: { color: Color; newColor: Color }): void;
	(e: 'select', color: SolidColor | GradientColor): void;
}>();
const visibleColors = computed(() => {
	if (props.colors.length === props.max + 1) {
		return props.colors;
	}
	return props.colors.slice(0, props.max);
});
const invisibleColors = computed(() => {
	return props.colors.slice(props.max);
});
const allVisible = computed(() => visibleColors.value.length >= props.colors.length);
const showAll = ref(false);
const container = ref();

onClickOutside(container, (ev) => {
	if (!showAll.value) return;

	const target = ev.target as HTMLElement | null;

	if (target?.closest('.color-picker')) return;

	return (showAll.value = false);
});

const emitColor = (color: Color, newColor: Color) => {
	emit('change', { color: cloneDeep(color), newColor });
};

const isTextColor = (color: Color): boolean => {
	if (!props.textsColors) return false;

	return !!props.textsColors?.find((c) => c.toCssString() === color.toCssString());
};

const isLineStrokeColor = (color: Color): boolean => {
	if (!props.linesStrokeColors) return false;

	return !!props.linesStrokeColors?.find((c) => c.toCssString() === color.toCssString());
};
</script>

<template>
	<div
		ref="container"
		data-testid="colorpicker-group"
		class="color-group flex items-center gap-2"
		:class="props.colors.length > 5 ? 'lg:gap-0' : 'lg:gap-2'"
	>
		<ColorPicker
			v-for="color in visibleColors"
			:key="color.id"
			:color="color"
			class="h-full transition-all"
			:class="{
				[pickerClass || '']: true,
				'last:mr-0 lg:-mr-2': props.colors.length > 5,
			}"
			:parent="pickerParent"
			:hide-gradient="isTextColor(color) || isLineStrokeColor(color)"
			:hide-alpha="hideAlpha"
			@change="emitColor(color, $event)"
			@select="($event) => emit('select', $event as SolidColor | GradientColor)"
		/>
		<button
			v-if="!allVisible"
			:class="pickerClass"
			class="all-colors relative h-full shrink-0 overflow-hidden rounded-full text-white transition-all duration-300"
			@click="showAll = !showAll"
		>
			<span class="flex h-full w-full items-center justify-center bg-gray-900/25 text-xs font-bold"
				>+{{ invisibleColors.length }}</span
			>
		</button>

		<Popper v-if="showAll" data-testid="all-global-colors" placement="bottom" class="z-10">
			<div
				class="mt-1 flex w-full max-w-[332px] grid-cols-6 flex-wrap items-center gap-2 whitespace-nowrap rounded bg-gray-800/90 py-2 px-2 backdrop-blur lg:m-0 lg:mt-2 lg:max-w-[188px]"
			>
				<ColorPicker
					v-for="color in invisibleColors"
					:key="color.id"
					:hide-gradient="isTextColor(color)"
					class="aspect-square"
					style="margin-right: 0px"
					:class="pickerClass"
					:color="color"
					:hide-alpha="hideAlpha"
					@change="(newColor) => emit('change', { color, newColor })"
					@select="($event) => emit('select', $event as SolidColor | GradientColor)"
				/>
			</div>
		</Popper>
	</div>
</template>
<style lang="scss">
.all-colors {
	background: conic-gradient(#f44336, #ffeb3b, #8bc34a, #00bcd4, #3f51b5, #9c27b0, #f44336);
}
</style>
