import { ElementType } from '@/Types/types';

export const ElementTypes: ElementType[] = [
	// Flaticon
	{
		id: 'flaticon',
		name: 'Icons',
		elements: [
			{
				id: 1,
				svg: 'elementsPanel/flaticon/1.svg',
				multicolor: false,
			},
			{
				id: 2,
				svg: 'elementsPanel/flaticon/2.svg',
				multicolor: false,
			},
			{
				id: 3,
				svg: 'elementsPanel/flaticon/3.svg',
				multicolor: false,
			},
			{
				id: 4,
				svg: 'elementsPanel/flaticon/4.svg',
				multicolor: false,
			},
			{
				id: 5,
				svg: 'elementsPanel/flaticon/5.svg',
				multicolor: false,
			},
			{
				id: 6,
				svg: 'elementsPanel/flaticon/6.svg',
				multicolor: false,
			},
			{
				id: 7,
				svg: 'elementsPanel/flaticon/7.svg',
				multicolor: false,
			},
			{
				id: 8,
				svg: 'elementsPanel/flaticon/8.svg',
				multicolor: false,
			},
			{
				id: 9,
				svg: 'elementsPanel/flaticon/9.svg',
				multicolor: false,
			},
			{
				id: 10,
				svg: 'elementsPanel/flaticon/10.svg',
				multicolor: false,
			},
		],
	},
	// Stories
	{
		id: 'storyset',
		name: 'Illustrations',
		elements: [
			{
				id: 1,
				svg: 'elementsPanel/stories/1.svg',
				multicolor: true,
			},
			{
				id: 2,
				svg: 'elementsPanel/stories/2.svg',
				multicolor: true,
			},
			{
				id: 3,
				svg: 'elementsPanel/stories/3.svg',
				multicolor: true,
			},
			{
				id: 4,
				svg: 'elementsPanel/stories/4.svg',
				multicolor: true,
			},
			{
				id: 5,
				svg: 'elementsPanel/stories/5.svg',
				multicolor: true,
			},
			{
				id: 6,
				svg: 'elementsPanel/stories/6.svg',
				multicolor: true,
			},
		],
	},
	// Stickers
	{
		id: 'stickers',
		name: 'Stickers',
		elements: [
			{
				id: 1,
				svg: 'elementsPanel/stickers/1.svg',
				multicolor: true,
			},
			{
				id: 2,
				svg: 'elementsPanel/stickers/2.svg',
				multicolor: true,
			},
			{
				id: 3,
				svg: 'elementsPanel/stickers/3.svg',
				multicolor: true,
			},
			{
				id: 4,
				svg: 'elementsPanel/stickers/4.svg',
				multicolor: true,
			},
			{
				id: 5,
				svg: 'elementsPanel/stickers/5.svg',
				multicolor: true,
			},
			{
				id: 6,
				svg: 'elementsPanel/stickers/6.svg',
				multicolor: true,
			},
		],
	},
	// Basic forms
	{
		id: 1740, // prod: 1740 | testing: 1734
		name: 'Basic shapes',
		elements: [
			{
				id: 1,
				svg: 'elementsPanel/basic_forms/1.svg',
				multicolor: false,
			},
			{
				id: 2,
				svg: 'elementsPanel/basic_forms/2.svg',
				multicolor: false,
			},
			{
				id: 3,
				svg: 'elementsPanel/basic_forms/3.svg',
				multicolor: false,
			},
			{
				id: 4,
				svg: 'elementsPanel/basic_forms/4.svg',
				multicolor: false,
			},
			{
				id: 5,
				svg: 'elementsPanel/basic_forms/5.svg',
				multicolor: false,
			},
			{
				id: 6,
				svg: 'elementsPanel/basic_forms/6.svg',
				multicolor: false,
			},
			{
				id: 7,
				svg: 'elementsPanel/basic_forms/7.svg',
				multicolor: false,
			},
			{
				id: 8,
				svg: 'elementsPanel/basic_forms/8.svg',
				multicolor: false,
			},
			{
				id: 9,
				svg: 'elementsPanel/basic_forms/9.svg',
				multicolor: false,
			},
			{
				id: 10,
				svg: 'elementsPanel/basic_forms/10.svg',
				multicolor: false,
			},
		],
	},
	// Image mask
	{
		id: 1741, // prod: 1741 | testing: 1735
		name: 'Images mask',
		elements: [
			{
				id: 37507,
				svg: 'elementsPanel/image_mask/preview-element-37507.png',
				multicolor: false,
			},
			{
				id: 37508,
				svg: 'elementsPanel/image_mask/preview-element-37508.png',
				multicolor: false,
			},
			{
				id: 37509,
				svg: 'elementsPanel/image_mask/preview-element-37509.png',
				multicolor: false,
			},
			{
				id: 37510,
				svg: 'elementsPanel/image_mask/preview-element-37510.png',
				multicolor: false,
			},
			{
				id: 37511,
				svg: 'elementsPanel/image_mask/preview-element-37511.png',
				multicolor: false,
			},
			{
				id: 37512,
				svg: 'elementsPanel/image_mask/preview-element-37512.png',
				multicolor: false,
			},
			{
				id: 37513,
				svg: 'elementsPanel/image_mask/preview-element-37513.png',
				multicolor: false,
			},
			{
				id: 37514,
				svg: 'elementsPanel/image_mask/preview-element-37514.png',
				multicolor: false,
			},
			{
				id: 37515,
				svg: 'elementsPanel/image_mask/preview-element-37515.png',
				multicolor: false,
			},
			{
				id: 37518,
				svg: 'elementsPanel/image_mask/preview-element-37518.png',
				multicolor: false,
			},
		],
	},
	// Lines and Arrows
	{
		id: 2328,
		name: 'Lines and Arrows',
		elements: [
			{
				id: 37555,
				svg: 'elementsPanel/lines_and_arrows/1.svg',
				multicolor: true,
			},
		],
	},
];
