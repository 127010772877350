// Vue
import { computed } from 'vue';

// Classes
import Image from '@/Classes/Image';
import Page from '@/Classes/Page';
// Composables
import { useEditorMode } from '@/composables/useEditorMode';
// Stores
import { useProjectStore } from '@/stores/project';

export const usePhotoMode = () => {
	const project = useProjectStore();
	const { isPhotoMode } = useEditorMode();

	const photoModeImage = computed(() => {
		if (!isPhotoMode.value) return undefined;
		const page = project.pages[0] as Page;
		return page.elements.find((el) => el.id === page.backgroundImageId) as Image;
	});

	return {
		photoModeImage,
	};
};
