import { Dom, Element as SvgElement, LinkedHTMLElement } from '@svgdotjs/svg.js';
import { v4 as uuidv4 } from 'uuid';

import ElementTools from '@/utils/ElementTools';

import TextTools from './TextTools';

class IllustratorTools {
	static removeUnnecessaryClipPaths(svg: SvgElement) {
		// Buscamos los clip-path del svg, ignoramos los de las imágenes y el principal,
		// ya que estos los creamos
		const defsClipPath: string[] = [];

		svg.find('[clip-path], [style*="clip-path"]:not([style*="clip-path-page"]):not(.main-image)').forEach((element) => {
			const clipPathId = `clipPath${(element.attr('clip-path') || element.css('clip-path'))
				.replace('url("', '')
				.replace('")', '')
				.replace('url(', '')
				.replace(')', '')}`;

			defsClipPath.push(clipPathId);

			element.css('clipPath', '');
			element.attr('clip-path', null);
		});

		if (defsClipPath.length) {
			svg.find(defsClipPath.join(', ')).forEach((element) => {
				element.remove();
			});
		}

		// Eliminamos los gradientTransform
		svg.find('[gradientTransform]').forEach((element) => {
			element.attr('gradientTransform', null);
		});
	}

	static removeGroupTransforms(svg: SvgElement) {
		svg.find('*:not(g)').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			let parent = elChild.parent() as SvgElement;

			// Vamos aplicando los transforms según buscamos el g base que contiene el
			// clip-path container
			while (parent !== null && parent.type !== 'svg') {
				if (elChild.type === 'DIV' || elChild.type === 'metadata') {
					return;
				}
				elChild.transform(parent.transform(), true);

				parent = parent.parent() as SvgElement;
			}

			// Eliminamos los data-* de los hijos
			Object.keys(elChild.node.dataset).forEach((dataKey) => delete elChild.node.dataset[dataKey]);
		});

		// Aplicamos la lógica anterior a los clipPath del elemento
		svg.find('[style*="clip-path"]').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			let parent = elChild.parent() as SvgElement;
			const clipPathId = ElementTools.getIdFromUrl(elChild.css('clip-path').toString());
			const clipPathElement = svg.defs().findOne(clipPathId)?.first() as SvgElement;

			while (parent !== null && parent.type !== 'svg') {
				clipPathElement.transform(parent.transform(), true);

				parent = parent.parent() as SvgElement;
			}
		});

		// Los transform ya no valen para nada, además eliminamos el resto de attrs
		svg.find('g').forEach((elChild) => {
			const attrs = elChild.attr();

			Object.keys(attrs).forEach((attr: any) => {
				if (!attrs[attr].toString().includes('clip-path')) {
					elChild.attr(attr, null);
				}
			});
		});
	}

	static simplifyTreeOfElements(svg: SvgElement) {
		// Eliminamos los elementos que no sean necesarios
		svg.find('metadata').forEach((element) => {
			element.remove();
		});

		// Quitamos el id al defs
		svg.find('defs').forEach((element) => {
			element.id('');
		});

		// Eliminamos grupos vacíos y <g> innecesarios
		svg
			.find('g')
			.filter((g) => !g.node.hasChildNodes())
			.forEach((g) => g.remove());

		let invalidG = svg.find('g').filter((g) => g.children().length === 1);

		while (invalidG.length) {
			invalidG.forEach((g) => {
				((g.node.firstElementChild as LinkedHTMLElement).instance as SvgElement).toParent(g.parent() as Dom);
				g.remove();
			});

			invalidG = svg.find('g').filter((g) => g.children().length === 1);
		}

		// Movemos los textos lo más arriba posible
		svg.find('text').forEach((el) => el.front());

		// Asignamos un data a los elementos para poder trabajar con ellos
		svg.find('*:not(tspan):not(defs)').forEach((el) => {
			if (el.node.closest('defs')) {
				return;
			}

			const dataName = el.type === 'g' ? 'group-link' : 'illustrator-link';

			el.data(dataName, uuidv4());
		});
	}

	static fixTexts(svg: SvgElement) {
		svg.find('[style*="font-family"]').forEach((text) => {
			const fontFamilySplit = text.css('font-family').toString().split(', ');
			const fontFamily = TextTools.getFontByName(
				fontFamilySplit[fontFamilySplit.length - 1]?.replaceAll('"', '') || 'Montserrat'
			);

			text.css('font-family', fontFamily);
		});
	}
}

export default IllustratorTools;
