<script lang="ts" setup>
import { Text } from '@/Classes/Text';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import TextEffectsMenu from '@/components/selection/toolbars/texts/TextEffectsMenu.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';

const store = useMainStore();
const { isMobile } = useDeviceInfo();

defineProps<{ element: Text }>();

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<TextEffectsMenu v-if="isMobile" class="pt-4" :element="element" :show-full="true" />

	<template v-else>
		<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
			<h2 class="text-xl font-semibold text-gray-100">Edit text effects</h2>

			<button
				class="text-gray-100 hover:text-white focus:outline-none"
				data-testid="close-panel"
				@click="onCloseEditPanel"
			>
				<SvgIcon name="cross" class="h-5 w-5" />
			</button>
		</div>

		<div data-testid="sidebar-panel" class="flex flex-col" data-keep-text-selection>
			<TextEffectsMenu :element="element" :show-full="true" />
		</div>
	</template>
</template>
