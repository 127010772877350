<script lang="ts" setup>
// Vue
import { computed } from 'vue';

// Components
import Dropdown from '@/components/Common/Dropdown.vue';

// Props
const props = defineProps<{
	selected: string | string[];
	options: {
		family: string;
		weight: string[];
	}[];
}>();

// Const
const WEIGHT_DICCIONARY = {
	'100': 'Thin',
	'100i': 'Thin italic',
	'200': 'Extralight',
	'200i': 'Extralight italic',
	'300': 'Light',
	'300i': 'Light italic',
	'400': 'Regular',
	'400i': 'Regular italic',
	'500': 'Medium',
	'500i': 'Medium italic',
	'600': 'Semibold',
	'600i': 'Semibold italic',
	'700': 'Bold',
	'700i': 'Bold italic',
	'800': 'Extrabold',
	'800i': 'Extrabold italic',
	'900': 'Black',
	'900i': 'Black italic',
};

// Emits
const emit = defineEmits(['update']);

// Computeds vars
const computedOptions = computed(() => {
	const result: { label: string; value: string }[] = [];

	if (Array.isArray(props.options) && props.options.length === 1) {
		//@ts-ignore
		props.options[0].weight.forEach((w) => result.push({ label: WEIGHT_DICCIONARY[w], value: w }));
	}

	return result;
});

// @ts-ignore
const computedSelected = computed(() => WEIGHT_DICCIONARY[props.selected]);

// Methods
const onUpdate = (val: string) => emit('update', val);
</script>

<template>
	<Dropdown
		class="flex w-full items-center justify-center"
		:selected="computedSelected"
		:options="computedOptions"
		@update="onUpdate"
	/>
</template>
