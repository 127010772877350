<script lang="ts" setup>
import { computed, toRef } from 'vue';

import Line from '@/Classes/Line';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useLine } from '@/composables/element/line/useLine';

const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');
const { updateDasharray } = useLine(element);

const dashArrayList = Line.defaultDasharrays();
const currentDashArrayListIndex = computed(() =>
	dashArrayList.findIndex((dashArray) => dashArray.toString() === element.value.dasharray.toString())
);

const toggleDasharray = () => {
	const nextDashArrayIndex =
		dashArrayList[currentDashArrayListIndex.value + 1] !== undefined ? currentDashArrayListIndex.value + 1 : 0;

	updateDasharray(dashArrayList[nextDashArrayIndex]);
};
</script>

<template>
	<button
		class="flex h-11 w-14 shrink-0 flex-col items-center justify-between text-white lg:h-8 lg:w-8 lg:justify-center"
		tooltip="Border style"
		tooltip-position="top"
		@click="toggleDasharray"
	>
		<SvgIcon :name="'border-dasharray-' + currentDashArrayListIndex" class="h-6 w-6 lg:h-5 lg:w-5" />
		<p class="text-2xs lg:hidden">Style</p>
	</button>
</template>
