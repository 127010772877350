import { useElementBounding, useEventListener } from '@vueuse/core';
import { computed, ComputedRef, watch } from 'vue';

import { useArtboard } from '@/composables/project/useArtboard';
import { useProject } from '@/composables/project/useProject';
import { useMainStore } from '@/stores/store';

export const useCanvasSize = (canvas: any, canvasTool: any, canvasContainer: any) => {
	const store = useMainStore();
	const { artboardSizeInPx } = useArtboard();
	const canvasWidthScaled = computed(() => artboardSizeInPx.value.width * store.scale);
	const canvasHeightScaled = computed(() => artboardSizeInPx.value.height * store.scale);
	const width = computed(() => artboardSizeInPx.value.width);
	const height = computed(() => artboardSizeInPx.value.height);

	// Diferencia en altura del container y canvas (container = canvastool + canvas)

	// Canvas
	const { height: canvasHeight } = useElementBounding(canvas);
	// CanvasTool
	const { height: canvasToolHeight } = useElementBounding(canvasTool);
	// CanvasTool + Canvas
	const { height: canvasContainerHeight } = useElementBounding(canvasContainer);

	return {
		width,
		height,
		canvasWidthScaled,
		canvasHeightScaled,
		canvasHeight,
		canvasToolHeight,
		canvasContainerHeight,
		scale: store.scale,
	};
};

export const useCanvasSizeContainer = (canvas: any, fitTo: 'width' | 'height') => {
	const { artboardSizeInPx } = useProject();
	const { width: widthBounding } = useElementBounding(canvas);
	const width = computed(() => artboardSizeInPx.value.width);
	const height = computed(() => artboardSizeInPx.value.height);

	// Ya que evitamos recalcular la scala si ya estaba calculada,
	// debemos reiniciar el valor del tamaño
	// en el caso de cambiar el artboard
	watch(width, () => {
		widthBounding.value = 0;
	});
	watch(height, () => {
		widthBounding.value = 0;
	});
	useEventListener('fullscreenchange', async () => {
		setTimeout(() => {
			widthBounding.value = 0;
		}, 100);
	});

	const scale: ComputedRef<number> = computed<number>(() => {
		// Si ya estaba calculado la escala respecto a un bounding final
		// retornamos el valor existente
		if (widthBounding.value && scale.value) {
			return scale.value;
		} else if (canvas.value) {
			canvas.value.style.width = null;
			canvas.value.style.height = null;

			const bounding = canvas.value.getBoundingClientRect();
			const boundingProportion = bounding.width / bounding.height;
			const artboardProportion = artboardSizeInPx.value.width / artboardSizeInPx.value.height;

			if (fitTo === 'height' && boundingProportion < artboardProportion) {
				return bounding['width'] / artboardSizeInPx.value['width'];
			}

			return bounding[fitTo] / artboardSizeInPx.value[fitTo];
		}

		return (widthBounding.value || canvas.value?.parentElement.clientWidth) / artboardSizeInPx.value[fitTo];
	});

	const canvasWidthScaled = computed(() => artboardSizeInPx.value.width * scale.value);
	const canvasHeightScaled = computed(() => artboardSizeInPx.value.height * scale.value);

	return {
		width,
		height,
		canvasWidthScaled,
		canvasHeightScaled,
		scale,
	};
};
