<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { until } from '@vueuse/core';
import sysend from 'sysend';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';

import Page from '@/Classes/Page';
import Modal from '@/components/Common/modals/Modal.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import PinterestBoardsInput from '@/components/export/share/PinterestBoardsInput.vue';
import Canvas from '@/components/render/Canvas.vue';
import { useArtboard } from '@/composables/project/useArtboard';
import { useAuth } from '@/composables/useAuth';
import useDownloadsProject from '@/composables/useDownloadsProject';
import { useShare } from '@/composables/useShare';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import MathTools from '@/utils/MathTools';
import TextTools from '@/utils/TextTools';

const props = defineProps<{ pages: Page[] }>();
const emits = defineEmits(['close', 'goBack']);

const store = useMainStore();
const project = useProjectStore();
const toast = useToast();
const { artboardSizeInPx } = useArtboard();
const { share } = useDownloadsProject();
const { requireAuth, isLogged, user } = useAuth();
const { checkPinterest, executeCheckPinterest, authenticatedInPinterest, hasPinterestBoards, logoutPinterest } =
	useShare();

const hasBoards = ref(0);
const selectedTemplatePage = ref<any>(null);
const selectedPinterestBoard = ref<any>(null);
const title = ref('');
const message = ref('');
const website = ref('');
const errors = ref<string[]>([]);
const oauthWindow = ref<Window | null>(null);
const authenticated = ref(false);

const dropdownTemplatePages = computed(() => {
	return props.pages.map((page: Page) => {
		const index = project.pages.findIndex((p) => p.id === page.id);

		return {
			label: `Page ${index + 1}`,
			value: project.pages[index].id,
		};
	});
});

const closeModal = () => {
	if (store.sharing) return;
	emits('close');
};

const goBack = () => {
	if (store.sharing) return;
	emits('goBack');
};

const initPinterest = async () => {
	if (!isLogged.value) {
		requireAuth();
		await until(isLogged).toBeTruthy();
	}

	sysend.on('oauth:pinterest', async () => {
		oauthWindow.value?.close();
		await checkAuthentication();

		if (authenticated.value) {
			sysend.off('oauth:pinterest');

			toast.success('Pinterest account linked successfully!');
		}
	});

	oauthWindow.value = window.open(
		`${import.meta.env.VITE_APP_BASE}auth/provider?provider=pinterest`,
		`Login in Pinterest`,
		'height=800,width=700'
	);
};

onBeforeUnmount(() => {
	sysend.off('oauth:pinterest');
});

const checkAuthentication = async () => {
	if (!isLogged.value) {
		return;
	}

	if (authenticatedInPinterest.value) {
		authenticated.value = true;
	}

	if (!authenticated.value) {
		await executeCheckPinterest();

		if (checkPinterest.value.status && user.value) {
			authenticated.value = true;
			user.value.tokens.pinterest = {
				status: true,
				token: checkPinterest.value.token,
			};
		}
	}

	const checkIfHasBoards = await hasPinterestBoards();
	// 0 -> Loading
	// 1 -> Has boards
	// 2 -> No boards
	hasBoards.value = checkIfHasBoards ? 1 : 2;
};

const shareInPinterest = async () => {
	if (store.sharing) return;

	errors.value = [];

	if (!selectedPinterestBoard.value) {
		errors.value.push('Please select a pinterest board');
	}

	if (!selectedTemplatePage.value) {
		errors.value.push('Please select at least one page');
	}

	if (website.value.length > 0 && !TextTools.isValidUrl(website.value)) {
		errors.value.push('Please enter a valid website');
	}

	if (errors.value.length) {
		return;
	}

	const params = {
		pinterest_board_id: selectedPinterestBoard.value,
		pinterest_title: title.value,
		pinterest_url: website.value,
		pinterest_description: message.value,
	};

	try {
		await share('pinterest', params, [selectedTemplatePage.value]);

		toast.success('Your design has been published on Pinterest');
		closeModal();
	} catch (error) {
		toast.error('Your design has not been uploaded on Pinterest');
	}
};

const logout = async () => {
	await logoutPinterest();
	authenticated.value = false;
};

onMounted(() => {
	checkAuthentication();
	selectedTemplatePage.value = dropdownTemplatePages.value[0].value;
});
</script>
<template>
	<Modal open content-classes="w-full max-w-lg" @close="closeModal">
		<div class="relative max-h-full w-full overflow-auto rounded-lg bg-white py-4 px-4 lg:px-8 lg:pt-8 lg:pb-8">
			<button class="absolute top-2 left-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25" @click="goBack">
				<SvgIcon name="right-small" class="h-5 w-5 rotate-180 text-gray-700" />
			</button>
			<button
				class="absolute top-2 right-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
				@click="closeModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
			</button>

			<div v-if="!authenticated" class="flex flex-col">
				<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800">Publish directly on Pinterest!</h3>
				<p class="px-6 text-center text-gray-600 lg:px-12">
					Connect your social media to share content in the blink of an eye!
				</p>
				<div class="flex items-center justify-center gap-6 py-10">
					<SvgIcon name="iso" class="h-16 w-16 text-blue-500" />
					<SvgIcon name="left-right" class="h-10 w-10 text-gray-100" />
					<div class="flex h-16 w-16 items-center justify-center rounded-full bg-[#E60023]">
						<SvgIcon name="pinterest" class="h-8 w-8 text-white" />
					</div>
				</div>
				<button class="rounded bg-blue-500 py-3 px-6 font-semibold text-white hover:bg-blue-600" @click="initPinterest">
					<span v-if="!loading">Connect with Pinterest</span>
					<SvgIcon v-if="loading" name="spinner" class="mx-auto h-7 w-7 animate-spin" />
				</button>
			</div>

			<div v-if="authenticated && hasBoards === 0" class="flex h-32 flex-col items-center justify-center gap-4">
				<div class="flex h-12 w-12 items-center justify-center rounded-full bg-[#E60023]">
					<SvgIcon name="pinterest" class="h-6 w-6 text-white" />
				</div>
				<p class="text-lg font-semibold text-gray-800">Loading boards...</p>
			</div>
			<div v-if="authenticated && hasBoards === 2" class="flex flex-col items-center justify-center gap-4">
				<div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-[#E60023]">
					<SvgIcon name="pinterest" class="h-6 w-6 text-white" />
				</div>
				<div class="text-center">
					<p class="text-lg font-semibold text-gray-800">Ups! you don't have any board</p>
					<a
						href="https://help.pinterest.com/article/create-a-board"
						target="_blank"
						class="font-semibold text-blue-500 hover:underline"
					>
						How to create a board?
					</a>
				</div>
				<button
					class="rounded border-2 border-blue-500 py-3 px-6 font-semibold text-blue-500 hover:bg-blue-500 hover:text-white"
					@click="logout"
				>
					Logout
				</button>
			</div>

			<div v-if="authenticated && hasBoards === 1" class="flex flex-col">
				<h3 class="mb-4 flex items-center justify-center text-xl font-semibold text-gray-800">
					<span class="mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-[#E60023]">
						<SvgIcon name="pinterest" class="h-4 w-4 text-white" />
					</span>
					Pinterest
				</h3>
				<div class="mb-4 flex flex-col gap-4 lg:flex-row">
					<div class="w-full">
						<label class="mb-2 block font-semibold text-gray-600">Pinterest board</label>
						<PinterestBoardsInput @select="selectedPinterestBoard = $event" />
					</div>
					<div class="w-full">
						<label class="mb-2 block font-semibold text-gray-600">Page selected</label>
						<Multiselect
							v-model="selectedTemplatePage"
							mode="single"
							placeholder="Template pages"
							:classes="{
								container: 'multiselect text-gray-700',
								containerActive: 'ring ring-blue-500 ring-opacity-30',
								tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
								groupLabelSelected: 'bg-blue-600 text-white',
								groupLabelSelectedPointed: 'bg-blue-600 text-white opacity-90',
								groupLabelSelectedDisabled: 'text-blue-100 bg-blue-600 bg-opacity-50 cursor-not-allowed',
								optionSelected: 'text-blue-500 font-semibold opacity-100 bg-gray-100/50',
								optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
								optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
							}"
							:hide-selected="false"
							:can-clear="false"
							:options="dropdownTemplatePages"
						>
							<template #option="{ option }">
								<div class="flex w-full items-center justify-between text-sm">
									<div>{{ option.label }}</div>
									<div class="flex h-6 w-12 items-center">
										<Canvas
											preview
											:page="project.pages.find((p) => p.id === option.value)"
											:use-parent-size="{
												height: artboardSizeInPx.height,
												width: artboardSizeInPx.width,
												scale: MathTools.ruleOfThree(artboardSizeInPx.height, 1, 30),
											}"
										/>
									</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div class="relative mb-4">
					<label class="mb-1 block font-semibold text-gray-600">
						Title
						<span class="text-sm text-gray-300">(optional)</span>
					</label>
					<input
						v-model="title"
						type="text"
						placeholder="Title"
						class="texts-gray-700 h-10 w-full resize-none rounded border border-gray-200 px-4"
					/>
				</div>
				<label class="mb-1 block font-semibold text-gray-600">
					Write a message
					<span class="text-sm text-gray-300">(optional)</span>
				</label>
				<div class="relative mb-4">
					<textarea
						v-model="message"
						maxlength="500"
						class="texts-gray-700 h-52 w-full resize-none rounded border border-gray-200 px-4 pt-4 pb-6"
					></textarea>
					<span v-if="message.length" class="absolute bottom-2 right-1 bg-white px-2 py-1 text-xs text-gray-100"
						>({{ message.length }}/500)
					</span>
				</div>
				<div class="relative mb-4">
					<label class="mb-1 block font-semibold text-gray-600">
						Website url
						<span class="text-sm text-gray-300">(optional)</span>
					</label>
					<input
						v-model="website"
						type="text"
						placeholder="Website url (example: https://wepik.com)"
						class="texts-gray-700 h-10 w-full resize-none rounded border border-gray-200 px-4"
					/>
				</div>

				<div v-for="error in errors" class="mb-4">
					<p class="text-sm font-semibold text-red-500">- {{ error }}</p>
				</div>

				<button
					class="rounded py-3 px-6 font-semibold text-white"
					:class="{
						'bg-blue-500 hover:bg-blue-600': !store.sharing,
						'pointer-events-none bg-blue-600': store.sharing,
					}"
					@click="shareInPinterest"
				>
					<span v-if="!store.sharing">Publish</span>
					<span v-else class="flex items-center justify-center">
						<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin" />
						Publishing
					</span>
				</button>
			</div>
		</div>
	</Modal>
</template>
