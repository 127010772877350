<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import RoundSlider from 'vue3-slider';

import { GradientColor } from '@/Classes/GradientColor';
import NumberInput from '@/components/Common/NumberInput.vue';
import GAnalytics from '@/utils/GAnalytics';

const props = defineProps<{ gradient: GradientColor }>();
const updateAngle = (angle: number) => {
	props.gradient.rotation = angle;
};

const trackTypingEvent = (category: string, value: string) => {
	Bugsnag.leaveBreadcrumb(`type ${value} in ${category}`);

	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<div v-if="gradient.type === 'linear'" class="flex">
		<RoundSlider
			v-model="gradient.rotation"
			:height="3"
			orientation="circular"
			:max="359"
			:min="0"
			:step="1"
			:repeat="true"
			track-color="#485D6D"
			color="#485D6D"
			class="shadow-round-slider w-full"
		/>
		<!--<input v-model="gradient.rotation" type="number" min="0" max="359" />-->
		<NumberInput
			:max="359"
			:min="1"
			:value="gradient.rotation"
			class="h-6 w-20 text-xs"
			@trackTyping="(value) => trackTypingEvent('rotation', value)"
			@trackStep="(value) => trackStepEvent('rotation')"
			@update="(angle) => updateAngle(angle)"
		/>
	</div>
</template>
<style lang="sass">
.vue3-slider.circular .handle-container .handle
	@apply bg-gray-100 w-3 h-3 rounded-full border-2 border-gray-800 -m-1 #{!important}
</style>
