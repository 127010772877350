<script setup lang="ts">
// Vue & Packages
import { useVibrate } from '@vueuse/core';
import { intersection } from 'lodash-es';
import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';

// Classes
import Element from '@/Classes/Element';
import Page from '@/Classes/Page';
import { Shape } from '@/Classes/Shape';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import IllustratorComponent from '@/components/render/IllustratorComponent.vue';
// Components
import ImageComponent from '@/components/render/ImageComponent.vue';
import LineComponent from '@/components/render/LineComponent.vue';
import QRCodeComponent from '@/components/render/QRCodeComponent.vue';
import ShapeComponent from '@/components/render/ShapeComponent.vue';
import StorysetComponent from '@/components/render/StorysetComponent.vue';
import TextComponent from '@/components/render/TextComponent.vue';
import { useCommon } from '@/composables/element/common/useCommon';
// Composables
import { usePhotoMode } from '@/composables/element/image/usePhotoMode';
import { useElementTransformOrchestrator } from '@/composables/element/useElementTransformOrchestrator';
import { useSmartSelection } from '@/composables/element/useSmartSelection';
import { useGroup } from '@/composables/group/useGroup';
import { useGroupTransform } from '@/composables/group/useGroupTransform';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useInteractiveElements } from '@/composables/interactions/useInteractiveElements';
// Stores
import { useMainStore } from '@/stores/store';
// Utils
import MathTools from '@/utils/MathTools';

const store = useMainStore();

// Props
const props = defineProps<{ element: Element; forPreview?: boolean; page?: Page; scale: number }>();

// Data
const el = ref();
const element = toRef(props, 'element');
const temporalRef = ref<Element>(Shape.createDefault());
// Using composables
const usingElementTransform = useElementTransformOrchestrator(temporalRef);
const { isOutsidePage, isInOtherPage } = useElementTransformOrchestrator(element).value;
const { group, isGrouped, isLockGroup } = useGroup(element);
const { isSelecting, isDragging, isDragMove, isDragRemove } = useInteractions();
const { photoModeImage } = usePhotoMode();
const { smartSelection } = useSmartSelection(element);
const { isOutsidePage: groupIsOutside, updateTargets: updateGroupTargets } = useGroupTransform(group);

// Computeds
const heightElement = computed(() => props.element.size.height);
const isEditable = computed(
	() => !props.forPreview && !isSelected.value && !isPhotoModeImage.value && element.value.type !== 'illustrator'
);
const isFirstSelection = computed(() => !!store.selectionId.length && store.selectionId[0] === props.element.id);
const isInActivePage = computed(() => store.activePage?.id === props.page?.id);
const isPhotoModeImage = computed(() => photoModeImage.value?.id === element.value.id);
const isRuler = computed(() => isInActivePage.value && !props.forPreview && !isSelected.value);
const isSelected = computed(
	() => !isPhotoModeImage.value && !!store.selection.length && store.selection.some((e) => e.id === props.element.id)
);
const isTarget = computed(
	() =>
		isInActivePage.value &&
		!props.forPreview &&
		isSelected.value &&
		!isPhotoModeImage.value &&
		element.value.type !== 'illustrator'
);

const sizeRemove = computed(() => {
	const size = {
		width: element.value.size.width * store.scale,
		height: element.value.size.height * store.scale,
	};
	if (!isGrouped.value || !isFirstSelection.value) {
		return size;
	}

	const moveableArea = document.querySelector('.moveable-area') as HTMLElement | null;

	if (!moveableArea) {
		return size;
	}

	return {
		width: parseFloat(moveableArea.style.width),
		height: parseFloat(moveableArea.style.height),
	};
});

watch([isGrouped], async () => {
	if (!isSelected.value) return;
	if (isGrouped.value) {
		await nextTick();
		updateGroupTargets();
	}
});
// Observamos la altura del elemento para que al estar agrupado
// se empuje hacia abajo los elementos que correspondan al editar
// un texto
watch(
	heightElement,
	async (newHeight, oldHeight) => {
		if (props.forPreview || !group.value.length || newHeight === oldHeight || !store.textEditing) {
			return;
		}

		// Asignamos el elemento
		temporalRef.value = element.value;

		// Obtenemos el ancho y la posición X con la rotación aplicada
		const { widthWithRotation: elementWidthWithRotation, topLeftWithRotation: elementTopLeftWithRotation } =
			usingElementTransform.value;

		const toMove = newHeight - oldHeight;
		const xElementEnd = elementTopLeftWithRotation.value.x + elementWidthWithRotation.value;
		const rangePositionsElement = MathTools.range(elementTopLeftWithRotation.value.x, xElementEnd);
		group.value
			.filter((el) => el.id !== props.element.id)
			// Solo elementos por debajo
			.filter((el) => el.position.y > props.element.position.y)
			// Solo elementos que estén dentro de su x - x + width
			.filter((el) => {
				// Asignamos el elemento filtrado
				temporalRef.value = el;

				// Obtenemos el ancho y la posición X con la rotación aplicada
				const { widthWithRotation, topLeftWithRotation } = usingElementTransform.value;

				const rangePositionEl = MathTools.range(
					topLeftWithRotation.value.x,
					topLeftWithRotation.value.x + widthWithRotation.value
				);
				const intersect = intersection(rangePositionsElement, rangePositionEl);

				return intersect.length;
			})
			.forEach((el) => {
				temporalRef.value = el;
				usingElementTransform.value.move(0, toMove);
			});
	},
	{ deep: true }
);

// Methods
const componentType = (element: Element) => {
	switch (element.type) {
		case 'image': {
			return ImageComponent;
		}
		case 'shape': {
			return ShapeComponent;
		}
		case 'storyset': {
			return StorysetComponent;
		}
		case 'text': {
			return TextComponent;
		}
		case 'line': {
			return LineComponent;
		}
		case 'illustrator': {
			return IllustratorComponent;
		}
		case 'qrcode': {
			return QRCodeComponent;
		}
	}
};

const { vibrate, stop: stopVibration } = useVibrate({
	pattern: [50, 200, 50, 200, 50, 200, 50, 200, 50, 200, 50, 200, 50, 200, 50, 200],
});

watch(isOutsidePage, () => {
	if (!isSelected.value || !isFirstSelection.value) {
		return;
	}
	if (!isOutsidePage.value || isInOtherPage.value.status) {
		stopVibration();
		return;
	}

	vibrate();
});
</script>

<template>
	<div
		:id="`element-${element.id}`"
		ref="el"
		:data-source-hash="`hash-${element.metadata?.sourceTemplateHash}`"
		:data-test-group="element.group"
		:class="{
			// Solo queremos esta clase si está en el activeCanvas y no es la preview, para evitar líneas mágneticas fuera de
			// su propia Page
			target: isTarget,
			editable: isEditable,
			ruler: isRuler,
			'ring-blue-500': !forPreview && !isSelected,
			'ring-custom': !isSelecting && !isSelected,
			// Se muestra cuando es una selección temporal (grupo no bloqueado) o cuando es grupo bloqueado y es el primer elemento
			'ring-custom-select':
				!forPreview && isSelected && ((isGrouped && !isLockGroup) || (isLockGroup && isFirstSelection)),
			'main-image': store.croppingId === element.id,
			'pointer-events-none': smartSelection && !isSelected,
		}"
		class="element absolute top-0 left-0"
		:style="{
			transform: `
        translate(${element.position.x}px,${element.position.y}px)
        rotate(${element.rotation}deg)
      `,
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
		}"
	>
		<div
			class="h-full w-full"
			:style="{
				transform: `
        scaleX(${element.flipHTML.x})
        scaleY(${element.flipHTML.y})
      `,
				opacity: element.opacity,
			}"
		>
			<component
				:is="componentType(element)"
				:key="element.id"
				:smart-selection="smartSelection"
				:element="element"
				:for-preview="props.forPreview"
				:scale="props.scale"
			/>
		</div>
		<Teleport
			v-if="
				((isGrouped ? groupIsOutside : isOutsidePage) || (isInOtherPage && isInOtherPage.status && isDragging)) &&
				isSelected &&
				isFirstSelection &&
				!forPreview
			"
			to="#portalTarget"
		>
			<div
				class="flex items-center justify-center"
				:style="{
					width: `${sizeRemove.width}px`,
					height: `${sizeRemove.height}px`,
				}"
			>
				<div
					v-if="!element.locked"
					:data-test-svg-icon="isInOtherPage.status || isDragMove ? 'replace' : 'trash'"
					class="outside vibrate flex h-7 w-7 items-center justify-center rounded-full text-white"
					:class="{
						'bg-red-500': (isOutsidePage && !(isInOtherPage.status || isDragMove)) || isDragRemove,
						'bg-blue-500': (isInOtherPage.status || isDragMove) && !isDragRemove,
					}"
				>
					<SvgIcon
						:name="(isInOtherPage.status || isDragMove) && !isDragRemove ? 'replace' : 'trash'"
						class="h-2/4 w-2/4"
					/>
				</div>
			</div>
		</Teleport>
	</div>
</template>
<style lang="css">
.ring-custom-select {
	box-shadow: 0 0 0 var(--zoom-ring) rgb(18 115 235);
}

.tree-element-selection {
	@apply stroke-current text-blue-500 !important;
}

@media screen(lg) {
	.ring-custom:hover {
		box-shadow: 0 0 0 var(--zoom-ring) rgb(18 115 235);
	}
}

.vibrate {
	animation: vibrate 1.5s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-22 10:16:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@keyframes vibrate {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

.delete {
	animation: delete 0.4s ease-in both;
}

@keyframes delete {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 #f99696;
		opacity: 1;
	}
	100% {
		transform: scale(1.3);
		box-shadow: 0 0 0 8px #f99696;
		opacity: 0;
	}
}

.move {
	animation: move 0.4s ease-in both;
}

@keyframes move {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 #96a5f9;
		opacity: 1;
	}
	100% {
		transform: scale(1.3);
		box-shadow: 0 0 0 8px #96a5f9;
		opacity: 0;
	}
}
</style>
