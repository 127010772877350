import { useMutationObserver, useUrlSearchParams } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';
import { computed, onMounted, ref, watch } from 'vue';

import { useEditorApiFetch } from '@/composables/api/useEditorApiFetch';
import { useText } from '@/composables/element/text/useText';
import { useEditorMode } from '@/composables/useEditorMode';
import { useHistoryStore } from '@/stores/history';
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
import { ApiClient } from '@/utils/api';
import Reports, { Report } from '@/utils/Reports';
import StringTools from '@/utils/StringTools';

export const useAdminActions = () => {
	const project = useProjectStore();
	const history = useHistoryStore();
	const store = useMainStore();
	const { isAdminMode } = useEditorMode();
	const textRef = ref();
	const { fitHeight } = useText(textRef);

	const params = useUrlSearchParams<{
		convert?: number;
		crawl?: number;
		stop?: number;
		report_id?: string;
		report_type?: string;
		report_data?: string;
	}>();

	const autoConvert = async () => {
		if (!params.convert) return;
		await updateTemplate('Auto convert', true);

		window.location.href = '/admin/autoconverter';
	};

	const crawl = async () => {
		if (!params.crawl) return;

		window.location.href = `/admin/crawler?previous=${params.crawl}&stop=${params.stop}`;
	};

	const showReports = async () => {
		if (!params.report_type) return;

		if (params.report_type === 'shape-over-text') {
			const data = JSON.parse(params.report_data as string) as any;

			data.shapes.forEach((shape: any) => {
				document
					.querySelector(`#element-${shape.id}, [data-source-hash="hash-${shape.hash || -1}"]`)
					?.classList.add('report-error');
			});
		}
	};

	const showTemplateSuggestions = async () => {
		if (!isAdminMode.value) return;

		const performChecks = () => {
			store.templateSuggestions = [];

			Reports.findOverlapingTexts().forEach((report) => {
				// @ts-ignore
				report.data.shapes.forEach((shape: any) => {
					store.templateSuggestions.push({
						element: shape.id,
						message: 'A text is overlapping with a shape',
					});
				});
			});

			Reports.findContentOutside().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'A element is outside of the template',
				});
			});

			Reports.findWrongTextHeight().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'The text has wrong sizing',
					fix: () => {
						textRef.value = report.element;
						fitHeight();
					},
				});
			});

			Reports.findBlendModes().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'A element is using a blend mode',
				});
			});
		};
		watch(() => history.activeState, performChecks);

		setTimeout(() => performChecks(), 1000);
	};

	const createPredefinedText = () => {
		const { onFetchResponse, onFetchError, isFetching, post } = useEditorApiFetch('predefined-text', {
			immediate: false,
		}).json();

		const createText = () =>
			post({
				page: project.pages[0],
				size: { ...project.size, unit: project.unit },
			}).execute();

		return { onFetchResponse, onFetchError, isFetching, createText };
	};

	const nodesLength = ref(document.querySelectorAll('[id*="element-"] svg:first-child *:not(g)').length);
	const documentSize = ref(
		StringTools.getSize(
			Array.from(document.querySelectorAll('[id*="canvas-"]'))
				.map((el) => el.outerHTML)
				.join('')
		)
	);

	onMounted(() => {
		useMutationObserver(
			document.querySelector('#scroll-area') as HTMLElement,
			(mutations) => {
				// Solo actualizamos cuando eliminamos o añadimos un elemento
				const check = mutations.some((mutation) => {
					const added = Array.from(mutation.addedNodes).find((el) => {
						if (el.nodeType === 1) {
							return (el as HTMLElement).id.includes('element-');
						}

						return false;
					});

					const removed = Array.from(mutation.removedNodes).find((el) => {
						if (el.nodeType === 1) {
							return (el as HTMLElement).id.includes('element-');
						}

						return false;
					});

					return added || removed;
				});

				if (check) {
					nodesLength.value = document.querySelectorAll('[id*="element-"] svg:first-child *:not(g)').length;
					documentSize.value = StringTools.getSize(
						Array.from(document.querySelectorAll('[id*="canvas-"]'))
							.map((el) => el.outerHTML)
							.join('')
					);
				}
			},
			{
				childList: true,
				subtree: true,
			}
		);
	});

	const projectData = computed(() => {
		return {
			nodesLength: nodesLength.value,
			documentSize: documentSize.value,
		};
	});

	const updateTemplate = async (reason: string, convert = false) => {
		const body = {
			pages: project.pages,
			comment: reason,
			report_type: params.report_type,
			properties: {
				height: project.size.height,
				width: project.size.width,
				unit: project.unit,
			},
			convert,
		};

		// TODO: Migrar al hook
		await ApiClient.request(`${import.meta.env.VITE_APP_API_PATH}vectors/${project.sourceVectorId}?uuid=${uuidv4()}`, {
			method: 'PATCH',
			// @ts-ignore
			body,
		});

		if (params.report_id) {
			window.location.href = `/admin/next-crawl-report?previous=${params.report_id}`;
		}
	};

	const deleteReport = async () => {
		await ApiClient.request(`${import.meta.env.VITE_APP_API_PATH}vectors/reports/${params.report_id}`, {
			method: 'DELETE',
		});

		window.location.href = `/admin/next-crawl-report?previous=${params.report_id}`;
	};

	return {
		createPredefinedText,
		updateTemplate,
		deleteReport,
		projectData,
		autoConvert,
		crawl,
		showReports,
		showTemplateSuggestions,
	};
};
