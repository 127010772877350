<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { useElementSize } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

import Image from '@/Classes/Image';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import CopyToolbar from '@/components/selection/toolbars/CopyToolbar.vue';
import FlipMenuToolbar from '@/components/selection/toolbars/FlipMenuToolbar.vue';
import CropButtonToolbar from '@/components/selection/toolbars/images/CropButtonToolbar.vue';
import FilterButtonToolbar from '@/components/selection/toolbars/images/FilterButtonToolbar.vue';
import MaskButtonToolbar from '@/components/selection/toolbars/images/MaskButtonToolbar.vue';
import RemoveBgButtonToolbar from '@/components/selection/toolbars/images/RemoveBgButtonToolbar.vue';
import ReplaceImageButtonToolbar from '@/components/selection/toolbars/images/ReplaceImageButtonToolbar.vue';
import MoreToolsToolbar from '@/components/selection/toolbars/MoreToolsToolbar.vue';
import OrderToolbar from '@/components/selection/toolbars/OrderToolbar.vue';
import RemoveToolbar from '@/components/selection/toolbars/RemoveToolbar.vue';
import ToBackgroundToolbar from '@/components/selection/toolbars/ToBackgroundToolbar.vue';
import { useImage } from '@/composables/element/image/useImage';
import { useImageBackground } from '@/composables/element/image/useImageBackground';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMainStore } from '@/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

// Props
const props = defineProps<{ element: Image }>();
const element = toRef(props, 'element');

// Using composables
const { removeImageAsBackground } = useImageBackground(element);
const { isPlaceholder, isBase64 } = useImage(element);

const { isPhotoMode } = useEditorMode();
const { isMobile, isIOS } = useDeviceInfo();

const { width } = useElementSize(element.value.domNode());
const scale = computed(() => store.scale);

const toolbarClass = computed(() => {
	const size = width.value * scale.value;
	if (size < 250) {
		return 'really-small-image';
	}
	if (size < 400) {
		return 'small-image';
	}

	return 'normal-image';
});
const toolbarLocked = ref(false);

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.Image) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.Image;
	Bugsnag.leaveBreadcrumb('Open edit image panel');

};
</script>

<template>
	<template v-if="!isPlaceholder">
		<div :class="toolbarClass">
			<!-- Unlock image as background -->
			<button
				v-if="element.locked && !isPhotoMode && !isMobile"
				data-testid="locked-img"
				class="mr-1 flex h-full items-center justify-center"
				tooltip="Unlock background"
				tooltip-position="top"
				@click="removeImageAsBackground"
			>
				<span class="flex h-5 w-5 items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-400">
					<SvgIcon name="lock" class="h-3 w-3" />
				</span>
			</button>

			<RemoveBgButtonToolbar
				v-if="!isBase64"
				:element="element"
				@loading="toolbarLocked = true"
				@endLoading="toolbarLocked = false"
			/>
			<ReplaceImageButtonToolbar :element="element" />
			<MaskButtonToolbar :element="element" />
			<FilterButtonToolbar v-if="!isIOS" :element="element" />
			<CropButtonToolbar :element="element" />

			<template v-if="isMobile">
				<ToBackgroundToolbar :element="element" />
				<FlipMenuToolbar :element="element" />
				<OrderToolbar :element="element" />
				<CopyToolbar :element="element" />
				<RemoveToolbar :element="element" />
			</template>

			<template v-else>
				<hr class="mx-1 hidden h-5 w-px bg-white opacity-25 lg:block" />
				<RemoveToolbar :element="element" />

				<MoreToolsToolbar :panel="EditPanels.Group" @more-tools="onClickMoreTools" />
			</template>

			<div
				v-if="toolbarLocked"
				class="absolute inset-0 z-30 cursor-wait rounded-tl-xl rounded-tr-xl bg-black bg-opacity-60 lg:rounded-full"
			></div>
		</div>
	</template>

	<template v-else>
		<div>
			<ReplaceImageButtonToolbar :element="element" />
			<RemoveToolbar :element="element" />
		</div>
	</template>
</template>

<style scoped lang="scss">
@media (min-width: 1024px) {
	.normal-image ::v-deep *[tooltip]:before {
		display: none;
	}

	.small-image ::v-deep {
		[tooltip][data-button-importance='important']:before {
			display: none !important;
		}
		[data-button-importance='normal'] [data-button-label] {
			display: none !important;
		}
	}

	.really-small-image ::v-deep {
		[data-button-importance] [data-button-label] {
			display: none !important;
		}
	}
}
</style>
