import { event } from 'vue-gtag';

class GAnalytics {
	static track(action: string, category: string, label: string, value: string | null) {
		event(action, {
			event_category: category,
			event_label: label,
			value: value,
		});
	}
}

export default GAnalytics;
