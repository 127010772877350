<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, onBeforeMount, toRef } from 'vue';
import RoundSlider from 'vue3-slider';

import { SolidColor } from '@/Classes/SolidColor';
import { Text } from '@/Classes/Text';
import ColorPicker from '@/components/Common/Color/ColorPicker.vue';
import { useTextEffects } from '@/composables/element/text/useTextEffects';
import { useTextStyles } from '@/composables/element/text/useTextStyles';
import { Color } from '@/Types/colorsTypes';
import GAnalytics from '@/utils/GAnalytics';

const MAX_VALUES = {
	BLUR: 10,
	DISTANCE: 25,
	OPACITY: 100,
};

const props = defineProps<{ element: Text }>();

const percentageBetween = (min: number, max: number, value: number) => ((value - min) / (max - min)) * 100;

const element = toRef(props, 'element');

const { hasShadow, isTextShadowEm } = useTextEffects(element);

const { updateShadowAngle, updateShadowColor, updateShadowOpacity, updateShadowDistance, updateShadowBlur } =
	useTextStyles(element);

// For now, only the first stop is supported but multi-text-shadow will be supported in the future
const computedTextShadow = computed(() => element.value.textShadow.slice(0, 1));

onBeforeMount(() => {
	if (hasShadow.value && element.value.textShadow.length === 1) {
		if (isTextShadowEm.value) convertFromEmToPx();

		if (element.value.textShadow[0].blur > MAX_VALUES.BLUR) {
			element.value.textShadow[0].blur = MAX_VALUES.BLUR;
		}

		if (element.value.textShadow[0].distance > MAX_VALUES.DISTANCE) {
			element.value.textShadow[0].distance = MAX_VALUES.DISTANCE;
		}

		if (element.value.textShadow[0].opacity > MAX_VALUES.OPACITY) {
			element.value.textShadow[0].opacity = MAX_VALUES.OPACITY;
		}
	}

	if (!hasShadow.value || element.value.textShadow.length > 1) {
		element.value.textShadow = [{ angle: 45, blur: 2, color: SolidColor.black(), distance: 2, opacity: 0.5 }];
		Bugsnag.leaveBreadcrumb(`Apply default text shadow values to text-${element.value.id}`);
	}

	GAnalytics.track('click', 'Button', 'text-shadow', null);
});

const convertFromEmToPx = () => {
	const factor = element.value.fontSize / 16;
	element.value.textShadow.forEach((ts) => {
		if (ts.unit) delete ts.unit;
		ts.blur *= 16 * factor;
		ts.distance *= 16 * factor;
	});
};

const onUpdateBlur = (index: number, e: Event) => {
	const value = parseFloat(e.target?.value) || 0;
	updateShadowBlur(index, value);
};

const onUpdateColor = (index: number, value: Color) => {
	updateShadowColor(index, value);
};

const onUpdateDistance = (index: number, e: Event) => {
	const value = parseFloat(e.target?.value) || 0;
	updateShadowDistance(index, value);
};

const onUpdateOpacity = (index: number, e: Event) => {
	const value = parseFloat(e.target?.value) || 1;
	updateShadowOpacity(index, value * 0.01);
};

const onUpdateAngle = (index: number, angle: number) => {
	updateShadowAngle(index, angle);
};
</script>

<template>
	<!-- Warning: check if :key(s) should be uuids if we support multi-shadow in the future -->
	<div v-for="(ts, i) in computedTextShadow" :key="i" class="text-shadow-panel flex gap-4 sm:pt-4">
		<div class="flex w-2/6 items-start gap-4">
			<div class="flex flex-col items-center gap-1">
				<ColorPicker
					class="h-10 w-10"
					data-testid="testShadowColor"
					:color="ts.color"
					hide-alpha
					hide-gradient
					@change="(color) => onUpdateColor(i, color)"
				/>
				<label class="text-xs text-gray-100">Color</label>
			</div>

			<div class="flex flex-col items-center gap-1">
				<div class="input-range relative flex h-10 w-9 items-center" data-testid="testOpacity">
					<RoundSlider
						:value="ts.angle"
						:height="4"
						orientation="circular"
						:max="359"
						:min="0"
						:step="1"
						:circle-offset="90"
						:repeat="true"
						track-color="#485D6D"
						color="#485D6D"
						class="shadow-round-slider w-full"
						@change="(angle) => onUpdateAngle(i, angle)"
					/>
				</div>
				<label class="text-xs text-gray-100">{{ Math.round(ts.angle) }}º</label>
			</div>
		</div>

		<div class="w-4/6">
			<div class="mb-1">
				<p class="text-xs text-gray-200">Opacity</p>
				<div class="flex items-center">
					<div class="input-range relative mr-4 flex w-full">
						<input
							:value="ts.opacity * 100"
							type="range"
							class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
							min="0"
							:max="MAX_VALUES.OPACITY"
							step="5"
							@input="(e) => onUpdateOpacity(i, e)"
							@change="(e) => onUpdateOpacity(i, e)"
						/>
						<span
							class="absolute top-0 left-0 h-[2px] rounded-full bg-blue-500"
							:style="`width:  ${ts.opacity * 100}%;`"
						></span>
					</div>
					<span
						class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100"
					>
						{{ Math.round(ts.opacity * 100) }} <small>%</small>
					</span>
				</div>
			</div>

			<div class="mb-1">
				<p class="text-xs text-gray-200">Distance</p>
				<div class="flex items-center">
					<div class="input-range relative mr-4 flex w-full">
						<input
							:value="ts.distance"
							type="range"
							class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
							:min="-MAX_VALUES.DISTANCE"
							:max="MAX_VALUES.DISTANCE"
							step="1"
							@input="(e) => onUpdateDistance(i, e)"
							@change="(e) => onUpdateDistance(i, e)"
						/>
						<span
							class="absolute top-0 left-0 h-[2px] rounded-full bg-blue-500"
							:style="`width:  ${percentageBetween(-25, 25, ts.distance)}%;`"
						></span>
					</div>
					<span
						class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100"
					>
						{{ ts.distance.toFixed(2) }}
					</span>
				</div>
			</div>

			<div>
				<p class="text-xs text-gray-200">Blur</p>
				<div class="flex items-center">
					<div class="input-range relative mr-4 flex w-full">
						<input
							:value="ts.blur"
							type="range"
							class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
							min="0"
							:max="MAX_VALUES.BLUR"
							step="1"
							@input="(e) => onUpdateBlur(i, e)"
							@change="(e) => onUpdateBlur(i, e)"
						/>
						<span
							class="absolute top-0 left-0 h-[2px] rounded-full bg-blue-500"
							:style="`width:  ${percentageBetween(0, 10, ts.blur)}%;`"
						></span>
					</div>
					<span
						class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100"
					>
						{{ ts.blur.toFixed(2) }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.text-shadow-panel .rs-control {
	.rs-tooltip,
	.rs-tooltip.rs-edit {
		@apply flex h-5 w-7 items-center justify-center rounded bg-black p-0 text-2xs text-gray-200;
		line-height: 0;
		margin-left: 40px !important;
		margin-top: 0 !important;
		transform: translate(-50%, -50%) !important;

		&.rs-hover,
		&:hover {
			@apply border-gray-700;
		}

		.rs-tooltip-text {
			@apply text-2xs;
		}
	}
}
.shadow-round-slider.vue3-slider.circular .handle-container {
	/* Handler de round slider */
	.handle {
		@apply border-2 border-gray-700 bg-gray-200 shadow-none;
		border-color: #2a3742 !important;
		margin: 0 !important;
		transform: scale(1.2);
	}
	/* Punto final de recorrido */
	.handle.round-end {
		@apply hidden;
	}
}
</style>
