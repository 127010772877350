<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
// Vue
import { computed, nextTick, ref } from 'vue';

// Components
import SvgIcon from '@/components/Common/SvgIcon.vue';
// Composables
import useDownloadsProject from '@/composables/useDownloadsProject';
import { useEditorMode } from '@/composables/useEditorMode';
// Store
import { useProjectStore } from '@/stores/project';
import { useMainStore } from '@/stores/store';
// Types
import { DownloadFormat } from '@/Types/types';
// Utils
import GAnalytics from '@/utils/GAnalytics';

const emits = defineEmits(['error']);
const props = defineProps<{ selection: string[]; templateDistribution: string }>();

const { download } = useDownloadsProject();
const { isPhotoMode } = useEditorMode();
const project = useProjectStore();
const store = useMainStore();

const openDropdownFormat = ref(false);

const downloadJpg = () => {
	GAnalytics.track('download-jpg', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.jpg);
};

const downloadPng = () => {
	GAnalytics.track('download-png', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.png);
};

const downloadTpng = () => {
	GAnalytics.track(
		'download-png-transparent',
		'Template',
		project.name?.length ? project.name : 'custom-artboard',
		null
	);
	downloadProject(DownloadFormat.tpng);
};

const downloadPdf = () => {
	GAnalytics.track('download-pdf', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.pdf);
};

const downloadProject = async (format: DownloadFormat) => {
	try {
		await download(format, props.selection);
	} catch (er) {
		emits('error');
	}
};

const openDropdown = () => {
	openDropdownFormat.value = !openDropdownFormat.value;
};

const downloadImage = () => {
	switch (store.downloadingFormat) {
		case DownloadFormat.jpg:
			downloadJpg();
			break;
		case DownloadFormat.png:
			downloadPng();
			break;
		case DownloadFormat.tpng:
			downloadTpng();
			break;
		default:
			break;
	}
};

const setDropdownFormat = (format: DownloadFormat) => {
	store.downloadingFormat = format;
};
</script>
<template>
	<div>
		<p class="mb-4 text-lg font-semibold text-gray-700">Download your project</p>
		<div class="flex flex-col gap-4 lg:flex-row">
			<div class="flex flex-col lg:w-1/2" :class="{ 'order-last': templateDistribution === 'present' }">
				<div class="space-between relative flex w-full">
					<button
						class="mb-1 flex h-14 w-full items-center justify-center rounded-l font-bold"
						:class="{
							'border-2 border-r-0 border-green-500 text-green-600 hover:bg-green-100/25':
								templateDistribution === 'present',
							'bg-green-500 text-white hover:bg-green-600': templateDistribution !== 'present',
						}"
						@click="downloadImage"
					>
						<SvgIcon name="photo" class="mr-2 h-4 w-4" />
						Image
					</button>
					<Menu>
						<MenuButton
							class="relative flex h-14 shrink-0 items-center justify-center rounded-r p-1 text-xs font-semibold group-hover:opacity-100"
							:class="{
								'border-2 border-l-0 border-green-500 bg-green-100/25 text-green-600':
									templateDistribution === 'present',
								'bg-green-600 text-white outline-1 hover:bg-green-600': templateDistribution !== 'present',
							}"
							@click="openDropdown"
						>
							<span v-show="store.downloadingFormat !== 'tpng'" class="w-10">{{
								store.downloadingFormat.toUpperCase()
							}}</span>
							<span v-show="store.downloadingFormat === 'tpng'" class="w-14 leading-none">
								PNG
								<span class="absolute left-0 bottom-1 block w-full text-2xs font-normal leading-none opacity-90">
									Transparent
								</span>
							</span>
							<SvgIcon name="caret" class="h-4 w-4 shrink-0" />
						</MenuButton>
						<MenuItems
							class="absolute top-[3.4rem] right-0 z-20 w-24 origin-top-right rounded-md bg-white py-1 shadow focus:outline-none group-hover:opacity-100"
							:class="{ 'opacity-1': openDropdownFormat }"
						>
							<MenuItem>
								<button
									class="block w-full px-4 py-1 text-xs text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
									@click="() => setDropdownFormat(DownloadFormat.png)"
								>
									PNG
								</button>
							</MenuItem>
							<MenuItem v-if="!isPhotoMode">
								<button
									class="block w-full whitespace-nowrap px-4 py-1 text-xs text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
									@click="() => setDropdownFormat(DownloadFormat.tpng)"
								>
									PNG
									<p class="text-2xs leading-none text-gray-300 opacity-75">
										Transparent <br />
										Background
									</p>
								</button>
							</MenuItem>
							<MenuItem>
								<button
									class="block w-full px-4 py-1 text-xs text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
									@click="() => setDropdownFormat(DownloadFormat.jpg)"
								>
									JPG
								</button>
							</MenuItem>
						</MenuItems>
					</Menu>
				</div>
				<div class="w-full">
					<p class="w-full text-xs text-gray-600">For digital use, social networks, web, etc.</p>
				</div>
			</div>
			<div class="lg:w-1/2">
				<button
					class="mb-1 flex h-14 w-full items-center justify-center rounded font-semibold"
					:class="{
						'border-2 border-green-500 text-green-600 hover:bg-green-100/25': templateDistribution !== 'present',
						'bg-green-500 text-white hover:bg-green-600': templateDistribution === 'present',
					}"
					@click="downloadPdf"
				>
					<SvgIcon name="grid" class="mr-2 h-4 w-4" />
					PDF
				</button>
				<p class="text-xs text-gray-600">Ideal for prints</p>
			</div>
		</div>
	</div>
</template>
