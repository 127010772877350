<script lang="ts" setup>
import { useStorage, watchOnce } from '@vueuse/core';
import { useVOnboarding, VOnboardingStep, VOnboardingWrapper } from 'v-onboarding';
import { computed, ref } from 'vue';

import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useMountedState } from '@/composables/useMountedState';
import steps from '@/data/onBoardingSteps.ts';

const wrapper = ref();

const { inSlidesgoContext, isPhotoMode } = useEditorMode();
const ready = useMountedState();
const { isMobile } = useDeviceInfo();
const { start } = useVOnboarding(wrapper);
const state = useStorage('on-boarding', {
	wepik: false,
	photo: false,
	slidesgo: false,
	freepik: false,
});

const onboarding = computed(() => {
	if (isPhotoMode.value) return 'photo';
	if (inSlidesgoContext.value) return 'slidesgo';

	return 'wepik';
});

watchOnce(ready, async () => {
	if (state.value[onboarding.value] || isMobile.value) {
		return;
	}
	start();
});
</script>

<template>
	<div id="onboarding"></div>

	<VOnboardingWrapper ref="wrapper" class="z-50" :steps="steps[onboarding]">
		<template #default="{ next, exit, step, isFirst, isLast }">
			<VOnboardingStep>
				<template v-if="isFirst">
					<div class="fixed inset-0 z-50 -mt-16 flex h-screen w-full items-center justify-center">
						<div class="rounded-xl bg-white">
							<div class="flex w-96 max-w-sm flex-col items-center p-6 text-center leading-normal text-darkblue-500">
								<img :src="step.content.image" class="mb-6 h-48 w-full rounded-xl object-cover" />
								<div class="text-gray-600">
									<h3 class="mb-2 text-xl font-semibold text-gray-800">👋 {{ step.content.title }}</h3>
									<p class="text-gray-500">
										{{ step.content.description }}
									</p>
								</div>
							</div>
							<div class="flex justify-center pb-6">
								<button
									class="rounded bg-blue-500 py-3 px-6 font-semibold text-white hover:bg-blue-600"
									@click="
										next();
										state[onboarding] = true;
									"
								>
									Let's go
								</button>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="relative w-[300px] rounded bg-blue-500 p-4 text-white">
						<button class="absolute top-4 right-4 text-white" @click="exit">
							<SvgIcon name="cross" class="h-4 w-4" />
						</button>
						<h3 class="mb-2 font-bold">{{ step.content.title }}</h3>
						<p v-if="step.content.description" class="mb-4 text-sm leading-normal">{{ step.content.description }}</p>
						<div class="mt-4 flex w-full items-center justify-between">
							<button v-if="!isFirst" class="flex items-center text-sm font-bold hover:opacity-75" @click="exit">
								Skip
							</button>
							<button v-if="!isLast" class="flex items-center text-sm font-bold hover:opacity-75" @click="next">
								Next
								<SvgIcon name="right-small" class="ml-2 h-4 w-4" />
							</button>
							<button v-else class="flex items-center text-sm font-bold hover:opacity-75" @click="next">
								<SvgIcon name="check" class="mr-2 h-3 w-3" />
								Finish
							</button>
						</div>
					</div>
				</template>
			</VOnboardingStep>
		</template>
	</VOnboardingWrapper>
</template>
<style lang="sass">
.v-onboarding-overlay
	@apply fixed inset-0 fill-gray-900 opacity-75 w-full h-full pointer-events-none
</style>
