import { SVG } from '@svgdotjs/svg.js';

import Element from '@/Classes/Element';
import { Flip, Position, SerializedClass, Size, ViewBox } from '@/Types/types';

export class Illustrator extends Element {
	type: 'illustrator' = 'illustrator';
	viewbox: string;
	content: string;

	protected constructor(
		// Element
		metadata: object,
		size: Size,
		position: Position,
		rotation: number,
		flip: Flip,
		group: string | null,
		locked: boolean,
		keepProportions: boolean,
		opacity: number,
		// Illustrator
		viewbox: string,
		content: string
	) {
		super(metadata, size, position, rotation, flip, group, locked, keepProportions, opacity);

		this.viewbox = viewbox;
		this.content = content;
	}

	public get viewboxObject(): ViewBox {
		const viewboxData = this.viewbox.split(' ').map(Number);

		return {
			x: viewboxData[0],
			y: viewboxData[1],
			width: viewboxData[2],
			height: viewboxData[3],
		};
	}

	public get contentSvg() {
		const tempContent = `<svg viewBox="${this.viewbox}">${this.content}</svg>`;

		return SVG(tempContent);
	}

	static defaults() {
		return {
			// Element
			metadata: {},
			size: { height: 0, width: 0 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: true,
			opacity: 1,
			// Illustrator
			viewbox: '0 0 100 100',
			content: '',
		};
	}

	static create(viewBox: string, content: string, config?: Partial<Illustrator>): Illustrator {
		const defaults = Illustrator.defaults();

		return new Illustrator(
			// Element
			config?.metadata || defaults.metadata,
			config?.size || defaults.size,
			config?.position || defaults.position,
			config?.rotation || defaults.rotation,
			config?.flip || defaults.flip,
			config?.group || defaults.group,
			config?.locked || defaults.locked,
			config?.keepProportions || defaults.keepProportions,
			config?.opacity || defaults.opacity,
			// Illustrator
			viewBox,
			content
		);
	}

	static unserialize(data: SerializedClass<Illustrator>): Illustrator {
		const defaults = Illustrator.defaults();

		const {
			// Element
			metadata,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			// Illustrator
			viewbox,
			content,
		} = data;

		return new Illustrator(
			// Element
			metadata || defaults.metadata,
			size || defaults.size,
			position || defaults.position,
			rotation !== undefined ? rotation : defaults.rotation,
			flip || defaults.flip,
			group || defaults.group,
			locked !== undefined ? locked : defaults.locked,
			keepProportions !== undefined ? keepProportions : defaults.keepProportions,
			opacity !== undefined ? opacity : defaults.opacity,
			// Illustrator
			viewbox || defaults.viewbox,
			content || defaults.content
		);
	}

	static createDefault() {
		const defaults = Illustrator.defaults();
		return new Illustrator(
			// Element
			defaults.metadata,
			defaults.size,
			defaults.position,
			defaults.rotation,
			defaults.flip,
			defaults.group,
			defaults.locked,
			defaults.keepProportions,
			defaults.opacity,
			// Illustrator
			defaults.viewbox,
			defaults.content
		);
	}
}
