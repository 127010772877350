<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { ref, toRef } from 'vue';

import { _getMasks } from '@/api/DataApiClient';
import Image from '@/Classes/Image';
import Mask from '@/Classes/Mask';
import InfiniteLoading from '@/components/Common/_InfiniteLoading.vue';
import InsertableElement from '@/components/Common/InsertableElement.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useInteractions } from '@/composables/interactions/useInteractions';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useMainStore } from '@/stores/store';
import { MaskApi } from '@/Types/apiClient';
import { ElementsPanelTypes } from '@/Types/types';

const store = useMainStore();
const { isMobile } = useDeviceInfo();
const { toggleMiddleHandlers } = useInteractions();

const props = defineProps<{ element: Image }>();

const element = toRef(props, 'element');

const url = ref(`elements/category/1741?limit=50`);

const { data: masks, isFetching } = _getMasks(url, { refetch: true });

const onApplyMask = async (maskApi: MaskApi | null = null) => {
	if (!maskApi) {
		props.element.mask = null;
		return;
	}

	const mask = await Mask.fromApi(maskApi);
	if (!mask) {
		return;
	}

	if (!props.element.hasCrop()) {
		props.element.crop.size = {
			width: props.element.size.width,
			height: props.element.size.height,
		};
	}

	const setToWidth = props.element.size.width < props.element.size.height;
	const newSize = setToWidth ? props.element.size.width : props.element.size.height;
	const diff = setToWidth
		? props.element.size.height - props.element.size.width
		: props.element.size.width - props.element.size.height;

	props.element.size = {
		width: newSize,
		height: newSize,
	};

	if (setToWidth) {
		props.element.crop.position.y -= diff / 2;
		props.element.position.y += diff / 2;
	} else {
		props.element.crop.position.x -= diff / 2;
		props.element.position.x += diff / 2;
	}
	if (!props.element.mask) {
		toggleMiddleHandlers();
	}
	props.element.setMask(mask);
	Bugsnag.leaveBreadcrumb(`Apply mask to image-${props.element.id}: ${mask.name} - ${mask.id}`);
};

const onCloseEditPanel = () => (store.editPanel = null);

const onLoadMore = async () => {
	if (isFetching.value || !masks.value?.links?.next) return;
	url.value = masks.value.links.next;
};

const removeMask = () => {
	onApplyMask(null);
	Bugsnag.leaveBreadcrumb(`Remove mask to ${props.element.id}`);
};

const toggleKeepRatioMask = () => {
	props.element.mask?.toggleRatio();
	toggleMiddleHandlers();
};
</script>

<template>
	<template v-if="isMobile">
		<div class="flex justify-between px-4 py-3">
			<span class="text-sm font-bold uppercase text-gray-100">Masks</span>
			<button
				v-if="element.mask"
				data-test-id="toggle-proportion-mask"
				class="flex h-6 w-6 items-center justify-center rounded-full text-xs text-white"
				:class="{
					'bg-blue-500': element.mask.keepRatio,
					'bg-gray-600': !element.mask.keepRatio,
				}"
				@click="toggleKeepRatioMask"
			>
				<SvgIcon v-show="element.mask.keepRatio" name="chain" class="h-3 w-3" />
				<SvgIcon v-show="!element.mask.keepRatio" name="chain-open" class="h-3 w-3" />
			</button>
		</div>
		<InfiniteLoading
			data-testid="container-items"
			horizontal
			class="mb-2 pb-0 text-gray-700"
			container-classes="flex items-center h-12 w-full gap-4"
			:data="(masks && masks.data) || []"
			:is-fetching="isFetching"
			@load="onLoadMore"
		>
			<template #default-item>
				<div>
					<button
						class="ml-4 flex h-12 w-12 items-center justify-center rounded bg-gray-600"
						:class="!element.mask ? 'border-2 border-blue-500 text-white' : 'text-gray-100'"
						@click="onApplyMask()"
					>
						<SvgIcon name="empty" class="h-5 w-5" />
					</button>
				</div>
			</template>

			<template #item="{ item }">
				<InsertableElement
					:data="item"
					:type="ElementsPanelTypes.ImageMasks"
					:draggable="true"
					class="h-10 w-10"
					@click="onApplyMask(item)"
				>
					<img
						:src="item.mask.element"
						class="h-full w-full cursor-pointer object-contain"
						:style="element.mask?.id === item.id ? 'filter: hue-rotate(15deg) saturate(5);' : ''"
					/>
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</template>

	<template v-else>
		<div class="flex h-full flex-col">
			<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
				<h2 class="text-xl font-semibold text-gray-100">Masks</h2>
				<div class="flex gap-4">
					<button
						v-if="element.mask"
						data-test-id="toggle-proportion-mask"
						class="text-gray-100 hover:text-white focus:outline-none"
						@click="toggleKeepRatioMask"
					>
						<span
							v-show="element.mask.keepRatio"
							tooltip="Unlock"
							tooltip-position="left"
							class="flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white"
						>
							<SvgIcon name="chain" class="h-3 w-3" />
						</span>
						<span
							v-show="!element.mask.keepRatio"
							tooltip="Lock"
							tooltip-position="left"
							class="rounded-ful flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-sm text-gray-100"
						>
							<SvgIcon name="chain-open" class="h-3 w-3" />
						</span>
					</button>
					<button
						data-testid="close-panel"
						class="text-gray-100 hover:text-white focus:outline-none"
						@click="onCloseEditPanel"
					>
						<SvgIcon name="cross" class="h-5 w-5" />
					</button>
				</div>
			</div>

			<InfiniteLoading
				:data="(masks && masks.data) || []"
				:is-fetching="isFetching"
				:container-classes="'grid grid-cols-4 gap-4'"
				@load="onLoadMore"
			>
				<template #default-item>
					<div>
						<button
							class="flex aspect-square w-full items-center justify-center rounded bg-gray-700"
							:class="!element.mask ? 'border-2 border-blue-500 text-white' : 'text-gray-100'"
							@click="onApplyMask()"
						>
							<SvgIcon name="empty" class="h-6 w-6" />
						</button>
					</div>
				</template>

				<template #item="{ item }">
					<InsertableElement
						:data="item"
						:type="ElementsPanelTypes.ImageMasks"
						:draggable="true"
						class="flex flex-col"
						@click="onApplyMask(item)"
					>
						<ActivableItem :active="item.active">
							<img
								v-if="item"
								:src="item.mask.element"
								class="w-full cursor-pointer rounded object-contain p-2"
								:style="element.mask?.id === item.id ? 'filter: hue-rotate(15deg) saturate(5);' : ''"
							/>
						</ActivableItem>
					</InsertableElement>
				</template>
			</InfiniteLoading>
		</div>
	</template>
</template>
