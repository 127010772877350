<script lang="ts" setup>
// Vue
import { computed, ref, toRef } from 'vue';

import { FilterPresetEmpty } from '@/Classes/FilterPresets';
// Classes
import Image from '@/Classes/Image';
import CanvasTeleport from '@/components/Common/CanvasTeleport.vue';
// Types
import Dropdown from '@/components/Common/Dropdown.vue';
import Popper from '@/components/Common/Popper.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import ImageFilter from '@/components/selection/toolbars/images/ImageFilter.vue';
import { useElementRenderStyles } from '@/composables/canvas/useElementRenderStyles';
// Composables
import { useImage } from '@/composables/element/image/useImage';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useEditorMode } from '@/composables/useEditorMode';
import { useToast } from '@/composables/useToast';

// Props
const props = defineProps<{ element: Image }>();
const emits = defineEmits(['loading', 'endLoading']);
const element = toRef(props, 'element');
const { replace, removeBackground } = useImage(element);
const { isMobile } = useDeviceInfo();
const toast = useToast();

// Data
const removingBackground = ref(false);
const showFilters = ref(false);

const { isPhotoMode } = useEditorMode();

const setBackgroundMode = async (value: string) => {
	if (value === 'both') {
		element.value.filter = new FilterPresetEmpty();
		element.value.filter.blur = 15;

		if (!isPhotoMode.value) {
			showFilters.value = true;
		}
	}

	if (value === 'original') {
		element.value.filter = new FilterPresetEmpty();
		element.value.filter.blur = 0;
	}

	element.value.backgroundMode = value;

	if (value !== 'original') {
		await requestBackgroundRemoval();
	}
};

const requestBackgroundRemoval = async () => {
	if (removingBackground.value) return;

	removingBackground.value = true;
	emits('loading');

	try {
		const remainingRemovals = await removeBackground();

		if (remainingRemovals > 0) {
			toast.success(`${remainingRemovals} more background removals available for today`);
		} else if (remainingRemovals === 0) {
			toast.warning(`You've reached background removals for today`);
		}
	} catch (error: any) {
		toast.error(error.message);
	} finally {
		emits('endLoading');
		removingBackground.value = false;
	}
};
const { styles } = useElementRenderStyles(props.element);
const options = computed(() =>
	[
		{ label: 'Original', value: 'original' },
		{ label: 'Remove background', value: 'foreground' },
		{ label: 'Remove object', value: 'background' },
		!isMobile.value && { label: 'Stylize the background', value: 'both' },
	].filter((i) => !!i)
);
</script>

<template>
	<div class="flex items-center lg:gap-px">
		<button
			data-button-importance="important"
			class="flex h-9 w-9 items-center justify-center rounded-tl rounded-bl text-gray-100 hover:text-white focus:outline-none lg:w-full lg:bg-gray-700 lg:pl-3 lg:hover:bg-gray-600"
			@click="element.backgroundMode === 'original' && setBackgroundMode('foreground')"
		>
			<div class="flex w-full items-center justify-center">
				<SvgIcon name="remove-bg" class="h-5 w-5 shrink-0 scale-90 lg:h-4 lg:w-4" />
				<span class="mt-0 ml-2 hidden text-sm lg:block" data-button-label>Remove Background</span>
				<span
					class="font-sm absolute -top-1 right-0 flex h-4 shrink-0 scale-75 items-center justify-center rounded bg-blue-500 px-1 text-2xs font-bold text-white lg:relative lg:top-auto lg:right-auto lg:-mr-1 lg:ml-2 lg:scale-100"
				>
					<p class="mt-px">NEW</p>
				</span>
			</div>
		</button>
		<Dropdown :options="options" :selected="element.backgroundMode" @update="setBackgroundMode">
			<template #title>
				<span class="flex p-2 text-center font-bold">Background removal options</span>
			</template>
			<template #default="{ onToggleVisibility }">
				<button
					class="flex h-9 w-6 items-center justify-center rounded-tr rounded-br text-gray-100 hover:bg-gray-600 hover:text-white lg:h-9 lg:w-9 lg:bg-gray-700"
					@click="onToggleVisibility"
				>
					<SvgIcon name="caret" class="h-3 w-3 lg:h-4 lg:w-4" />
				</button>
			</template>
		</Dropdown>
	</div>
	<CanvasTeleport v-if="removingBackground" :element="element">
		<div :style="styles" class="pointer-events-none flex items-center justify-center bg-gray-900/60">
			<SvgIcon name="spinner" class="h-1/6 w-1/6 animate-spin text-white" />
		</div>
	</CanvasTeleport>

	<!-- Filter panel Desktop -->
	<Popper v-if="showFilters && !isMobile" parent-selector=".toolbar" placement="right-start" :offsets="{ x: 0, y: 5 }">
		<div class="h-auto w-48 rounded bg-gray-800 p-2 text-white">
			<ImageFilter :image="element" />
		</div>
	</Popper>
</template>
