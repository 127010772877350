// Vue & Packages
import { defineStore } from 'pinia';

// Classes
import Element from '@/Classes/Element';
import Image from '@/Classes/Image';
import Page from '@/Classes/Page';
import { useActivePage } from '@/composables/page/useActivePage';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
// Composables
import { useEditorMode } from '@/composables/useEditorMode';
// Stores
import { useProjectStore } from '@/stores/project';
// Types
import {
	DownloadFormat,
	DraggableItem,
	EditPanels,
	ElementsPanelTypes,
	Panels,
	TemplateCategory,
	TemplateSuggestion,
	User,
	UserVector,
} from '@/Types/types';
export interface MainState {
	downloading: boolean;
	downloadingFormat: DownloadFormat;
	sharing: boolean;
	scale: number;
	user: User | null;
	selectionId: string[];
	activePanel: Panels | null;
	textEditingId: string | null;
	croppingId: string | null;
	activePageId: string | null;
	draggingItem: DraggableItem | null;
	activeElementType: ElementsPanelTypes | null;
	activeTemplateCategory: TemplateCategory | null;
	downloadTemplateCategory: TemplateCategory | null;
	userVector: UserVector | null;
	finishedLoading: boolean;
	customImagesId: string[];
	illustratorSelection: Set<string>;
	illustratorElementsMoved: Set<string>;
	templateSuggestions: TemplateSuggestion[];
	editPanel: EditPanels | null;
}

export const useMainStore = defineStore('main', {
	state: (): MainState => ({
		downloading: false,
		downloadingFormat: DownloadFormat.png,
		sharing: false,
		scale: 0.5,
		user: null,
		userVector: null,
		selectionId: [],
		activePanel: null,
		textEditingId: null,
		croppingId: null,
		activePageId: null,
		draggingItem: null,
		activeElementType: null,
		activeTemplateCategory: null,
		downloadTemplateCategory: null,
		finishedLoading: false,
		customImagesId: [],
		illustratorSelection: new Set(),
		illustratorElementsMoved: new Set(),
		templateSuggestions: [],
		editPanel: null,
	}),
	getters: {
		getSelectionFromCanvas() {
			return (page: Page): Element[] => {
				return this.selection.filter((selection: Element) =>
					page.elements.find((el: Element) => selection.id === el.id)
				);
			};
		},
		textEditing(state): Element | null {
			const project = useProjectStore();
			return project.getElementById(state.textEditingId);
		},
		cropping(state): Image | null {
			const project = useProjectStore();
			return project.getElementById(state.croppingId) as Image;
		},
		selection(state): Element[] {
			const project = useProjectStore();
			return state.selectionId.map((id) => project.getElementById(id)).filter((el) => el) as Element[];
		},
		selectionOrdered(state): Element[] {
			const { getElementIndex } = useActivePage();
			return [...this.selection].sort((a, b) => getElementIndex(a) - getElementIndex(b));
		},
		activePage(state): Page | null {
			const project = useProjectStore();
			return (project.pages as Page[]).find((page) => page.id === state.activePageId) || null;
		},
	},

	actions: {
		setScale(scale: number) {
			this.scale = scale;
		},

		async setSelection(element: Element, group = false) {
			// Si ya está en la selección no hacemos nada
			const isInSelection = this.selectionId.some((id) => id === element.id);
			if (isInSelection && !element.group) return;

			this.editPanel = null;

			// Comprobamos de que el elemento a seleccionar esta en la misma página que el primero seleccionado
			if (this.selectionId.length > 0) {
				const project = useProjectStore();
				const pageNew = project.pages.find((page) => page.elements.find((el) => el.id === element.id));
				const pageFirst = project.pages.find((page) => page.elements.find((el) => el.id === this.selection[0].id));

				if (pageNew?.id !== pageFirst?.id) {
					this.clearSelection();
				}
			}

			// Si se intenta hacer grupo y la selección o el elemento a añadir a la selección es background no hacemos nada
			if (group) {
				const selectionIsBg = this.selection.find((el) => {
					if (!(el instanceof Image)) return false;

					return this.activePage?.backgroundImageId === el.id;
				});

				if (selectionIsBg) this.selectionId = this.selectionId.filter((id) => selectionIsBg.id !== id);

				if (element instanceof Image) {
					if (this.activePage?.backgroundImageId === element.id) return;
				}
			}

			if (element.group && this.activePage) {
				const elementsGroup = Object.values(this.activePage.elements)
					.filter((el) => el.group === element.group && el.id !== element.id)
					.map((el) => el.id);
				if (!elementsGroup.length) {
					element.group = null;
				}

				// Al usar selecto sobre grupos se duplican los elementos en la selección, borramos los ids duplicados para evitarlo
				this.selectionId = group ? [...new Set([element.id, ...this.selectionId, ...elementsGroup])] : [element.id, ...elementsGroup];
				this.setEditPanelMobile(element);
				return;
			}

			this.setEditPanelMobile(element);
			this.selectionId = group ? [element.id, ...this.selectionId] : [element.id];
		},

		setEditPanelMobile(element: Element) {
			const { isMobile } = useDeviceInfo();
			if (!isMobile.value) return;

			if (element.type === 'image') this.editPanel = EditPanels.Image;
			if (element.type === 'shape') this.editPanel = EditPanels.Shape;
			if (element.type === 'storyset') this.editPanel = EditPanels.Storyset;
			if (element.type === 'text') this.editPanel = EditPanels.Text;
			if (element.type === 'line') this.editPanel = EditPanels.Line;
			if (element.type === 'qrcode') this.editPanel = EditPanels.QRCode;
		},

		removeFromSelection(element: Element) {
			const isInSelection = this.selection.some((el) => el.id === element.id);
			if (!isInSelection) {
				return;
			}

			this.selectionId = this.selectionId.filter((id) => id !== element.id);
		},

		clearSelection() {
			this.editPanel = null;

			const { isPhotoMode } = useEditorMode();

			// En el modo photo la propia foto es la selección por defecto
			if (isPhotoMode.value) {
				const photoModeImage = this.activePage?.elements.find((el) => el.id === this.activePage?.backgroundImageId);

				if (!photoModeImage) {
					this.selectionId = [];
					return;
				}

				this.selectionId = [photoModeImage.id];
				return;
			}

			this.selectionId = [];
		},

		setActivePage(page: Page, force = false) {
			if (this.selectionId.length && !force) return;
			if (this.activePageId === page.id) return;
			this.activePageId = page.id;
		},
	},
});
