import { createSharedComposable } from '@vueuse/core';
import { breakpointsTailwind, useBreakpoints, useEventListener } from '@vueuse/core';
import { computed, ref } from 'vue';

export const useDeviceInfo = createSharedComposable(() => {
	let watchingKeyboard = false;
	const initialHeight = ref(window.visualViewport.height);
	const currentHeight = ref(window.visualViewport.height);

	const breakpoints = useBreakpoints(breakpointsTailwind);

	const isMobile = breakpoints.smaller('lg');
	const isTouch = computed(() => 'ontouchstart' in window || navigator.msMaxTouchPoints);
	const isXL = computed(() => window.innerWidth >= 1920);
	const isIOS = computed(
		() =>
			['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
			(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
	const isSafari = computed(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent));
	const isMac = computed(() => navigator.appVersion.toLowerCase().indexOf('mac') !== -1);
	const isWebview = computed(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const { userAgent } = window.navigator;

		return searchParams.get('webview') !== null || userAgent.includes('WepikApp/Webview');
	});
	const webviewVersion = computed(() => {
		const { userAgent } = window.navigator;
		const version =
			userAgent
				.split(' ')
				.find((word: string) => word.includes('WepikApp'))
				?.replace('WepikApp/Webview', '')
				.replace('-', '') || '0';

		return Number(version);
	});
	const isOldApp = computed(() => {
		return isWebview.value && webviewVersion.value === 0;
	});
	const isFirefox = navigator.userAgent.indexOf('Firefox') > 0;
	const isAndroid = navigator.userAgent.indexOf('Android') > 0;

	const runOnMobile = (callback: () => void) => {
		if (!isMobile.value) return;

		callback();
	};

	const isKeyboardOpen = computed(() => {
		if (!isMobile.value) {
			return false;
		}

		if (!watchingKeyboard) {
			watchingKeyboard = true;

			window.visualViewport.addEventListener('resize', function () {
				currentHeight.value = window.visualViewport.height;
			});

			useEventListener('resize', () => {
				initialHeight.value = window.visualViewport.height;
				currentHeight.value = window.visualViewport.height;
			});
		}

		return initialHeight.value !== currentHeight.value;
	});

	const keyboardHeight = computed(() => {
		if (!isKeyboardOpen.value) return 0;

		return Math.abs(initialHeight.value - currentHeight.value - window.visualViewport.offsetTop);
	});

	const heightWithoutKeyboard = computed(() => {
		return currentHeight.value;
	});

	return {
		isMobile,
		isTouch,
		isXL,
		isIOS,
		isWebview,
		webviewVersion,
		isOldApp,
		isFirefox,
		runOnMobile,
		isKeyboardOpen,
		keyboardHeight,
		heightWithoutKeyboard,
		isSafari,
		isAndroid,
		isMac,
	};
});
