<script lang="ts" setup>
import { debouncedRef, useVirtualList } from '@vueuse/core';
import { computed, nextTick, ref } from 'vue';

import Modal from '@/components/Common/modals/Modal.vue';
import Popper from '@/components/Common/Popper.vue';
import SvgIcon from '@/components/Common/SvgIcon.vue';
import { useDeviceInfo } from '@/composables/useDeviceInfo';
import { useFonts } from '@/composables/useFonts';
import GAnalytics from '@/utils/GAnalytics';

const props = defineProps<{ font: string }>();
const emits = defineEmits(['change']);

const picking = ref(false);
const filter = ref('');
const filterDebounced = debouncedRef(filter, 300);

const { inUseFonts, recommendedFonts, sortedFonts } = useFonts();

const fontList = computed<(string | any)[]>(() => {
	if (filterDebounced.value.length > 0) {
		return sortedFonts.value.filter((font) => font.name.toLowerCase().includes(filterDebounced.value.toLowerCase()));
	}

	return [
		'Fonts in use',
		...inUseFonts.value,
		'Recommended',
		...recommendedFonts.value,
		'All fonts',
		...sortedFonts.value,
	];
});

const { list, containerProps, wrapperProps } = useVirtualList(fontList, {
	itemHeight: 31,
});

interface Font {
	name: string;
	preview: string;
	website: string;
	weights: string[];
}

const updateFont = (font: Font) => {
	emits('change', font.name);
	GAnalytics.track('click', 'Button', `change-font-family`, null);

	picking.value = false;
};

const { isMobile } = useDeviceInfo();

const toggle = () => {
	picking.value = !picking.value;

	nextTick(() => {
		if (picking.value && !isMobile.value) search.value.focus();
	});
};

const search = ref();
</script>

<template>
	<div class="w-full" data-font-picker>
		<slot :font-family="props.font" :toggle="toggle">
			<button
				data-testid="font"
				class="relative flex h-8 w-full items-center rounded bg-gray-600 pl-2 pr-1 text-white hover:text-white focus:text-white lg:h-full lg:w-32 lg:bg-transparent lg:pl-2 lg:text-gray-100"
				@click="toggle"
			>
				<span
					:style="{ fontFamily: props.font }"
					class="w-16 flex-1 truncate text-left text-sm lg:w-auto"
					v-text="props.font"
				/>
				<SvgIcon name="arrow" class="ml-4 h-4 w-4 fill-current" />
			</button>
		</slot>

		<Popper v-if="picking && !isMobile" placement="bottom-start">
			<div
				class="mt-1 h-64 w-36 overflow-y-scroll rounded bg-white pl-2 pt-2 pb-2 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100"
				v-bind="(containerProps as any)"
			>
				<input
					ref="search"
					v-model="filter"
					type="search"
					class="mb-3 h-6 w-full rounded bg-gray-100/25 px-2 text-xs text-gray-700 placeholder-gray-600 focus:outline-none"
					placeholder="Search a font..."
				/>
				<div data-testid="font-families" v-bind="wrapperProps" class="flex flex-col">
					<div
						v-for="fontEl in list"
						:key="typeof fontEl.data === 'string' ? fontEl.data : fontEl.data.name"
						class="first:-mt-2"
					>
						<p
							v-if="typeof fontEl.data === 'string'"
							class="mb-1 mt-2 pb-1 text-2xs font-bold uppercase text-gray-500"
							v-text="fontEl.data"
						></p>
						<button v-else class="w-full py-2 opacity-80 hover:opacity-100" @click="updateFont(fontEl.data)">
							<img
								:data-test-font="fontEl.data.name"
								:src="fontEl.data.preview"
								alt=""
								class="h-4 object-contain"
								loading="lazy"
							/>
						</button>
					</div>
				</div>
			</div>
		</Popper>
		<Modal v-if="picking && isMobile" to="body" open content-classes="py-8" @close="picking = false">
			<div
				data-testid="font-family-res"
				class="flex w-64 flex-col items-center rounded-lg bg-gray-800 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
				v-bind="(containerProps as any)"
			>
				<p class="flex px-3 pt-4 pb-1 text-center font-bold text-white">Change font family</p>
				<div class="sticky top-0 z-10 w-full bg-gray-800/90 py-2 pl-4 pr-2 backdrop-blur">
					<input
						ref="search"
						v-model="filter"
						type="search"
						class="h-8 w-full appearance-none rounded bg-gray-900 px-3 text-sm text-gray-100 placeholder-gray-300 focus:outline-none"
						placeholder="Search a font..."
					/>
				</div>
				<div class="w-full pl-4 pr-2 pt-2">
					<div v-bind="wrapperProps" class="flex flex-col">
						<div
							v-for="fontEl in list"
							:key="typeof fontEl.data === 'string' ? fontEl.data : fontEl.data.name"
							class="first:-mt-2"
						>
							<p
								v-if="typeof fontEl.data === 'string'"
								class="mb-1 mt-2 pb-1 text-xs font-bold uppercase text-gray-300"
								v-text="fontEl.data"
							></p>
							<button v-else class="w-full py-2 opacity-80 hover:opacity-100" @click="updateFont(fontEl.data)">
								<img
									:data-test-font="fontEl.data.name"
									:src="fontEl.data.preview"
									alt=""
									class="h-6 object-contain invert"
									loading="lazy"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>
